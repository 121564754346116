import React, { Component } from "react";
import DownloadOption from "../../../commons/AllOptions/Download";
import Apis from "src/js/apis/api";
import SubsetApis from "src/js/apis/subset";
import Utils from "src/js/utils";

import "./index.css";


import { Form, Button ,Spin ,Modal} from "antd";

class DownloadMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downLoadFileLoading: false,
      allChildError: {},
      selectDuration: { erathem_max: [], period_max: [], epoch_max: [], early_interval: [] },
    };
  }

  downloadFile = file => {

    let href = "/api" + file;
    let idx = file.lastIndexOf("/");

    let name = file.substring(idx + 1, file.length);
    var a = document.createElement("a");
    a.setAttribute("href", href);
    a.setAttribute("download", name);
    a.setAttribute("id", "downloadFileA");
    a.setAttribute("name", "name");
    // 防止反复添加
    if (document.getElementById("downloadFileA")) {
      document.body.removeChild(document.getElementById("downloadFileA"));
    }
    document.body.appendChild(a);

    a.click();
  };

  download = () => {
    this.refs.download.validateFieldsAndScroll(async (err, values) => {
      // let { allChildError } = this.state;
      // if (JSON.stringify(allChildError) !== "{}") {
      //  Modal.warn({
      //     content: "Please check the query form"
      //   });
      //   return;
      // }
      if (!err) {

        this.setState(
          {
            downLoadFileLoading: true
          });

        let searchResults = Utils.filterParams(values);

        let queryCondition = Utils.filterCondition(searchResults);
        //console.log("param:"+queryCondition.downloadType);
       
       
        if (queryCondition) {
          // let queryParam = {
          //   searchParams: queryCondition,
          //   type: "download",
          //   searchAllValues: values,
          //   selectDuration:this.state.selectDuration
          // };
         
            //let downBack = {};
            let downBack = await Apis.download(queryCondition);
            // console.log("result::"+downBack.result);
            if (downBack.result) {
              let url="";
              let successFlag=true;
              if(downBack.sinocorFlag){
                let params = {
                  sectionIds: downBack.result
                };
                let sinocorBack = await SubsetApis.exportSinocor(params);
                if(sinocorBack.result && sinocorBack.result!=""){
                  url=sinocorBack.result;
                }else{
                  successFlag=false;
                }
              }else{
                url=downBack.result;
              }
              if(successFlag){
                this.setState(
                  {
                    conopUrl: url,
                    downLoadFileLoading: false,
                  },
                  () => {
                    this.downloadFile(url);
                  }
                );
              }else{
                this.setState(
                  {
                    downLoadFileLoading: false,
                  })
                Modal.error({
                  content: "download failed",
                });
              }
            } else {
              this.setState(
                {
                  downLoadFileLoading: false,
                })
              Modal.error({
                content: "download failed",
              });
            }
          
          // let url = "/searchs/result/section/base";
          //this.props.history.push({ pathname: url, query: queryParam });
        }
      }
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  changeDuration= selectDuration => {
   
    this.setState({
      selectDuration
    },()=>{
    
   
    });
  };
  render() {
    return (
      <div id="searchSection">
        <div className="searchSection-main  reference-search-form search-common-form">
          <div className="search-common-title form-common-title">
             Download Data
          </div>
          <p className="introduce">Here you can download occurrences, collections, and sections and other relevant types of data from the Geobiodiversity Database. 
            Use fields and selectors to specify what kind of information you need. Use question buttons for more information anytime you feel unclear about the part you are in. 
            For the integrity of community, this download form keep consistent with what one can find in the Paleobiology Database. 
            The age collation of part of data comes from the Paleobiology Database provided by technical team from University of Erlangen-Nuremberg.
          </p>
          <DownloadOption
            showBase={true}
            childError={this.childError}
            ref="download"
            changeDuration={this.changeDuration}
          ></DownloadOption>

          <div className="referenceSearch-btns">
            <div>
              <Button
                type="gbdb"
                onClick={() => {
                  this.download();
                }}
              >
              {
                   <p> Download</p>
                  }
                 
              </Button>
            </div>
          </div>

          {//   this.state.downLoadFileLoading
              this.state.downLoadFileLoading && (
                <div className="download-loading file-downloading">
                  <Spin />
                  <div className="download-tips">
                    File downloading, please wait...
                </div>
                  <div className="download-tips-blocked">
                    Please confirm that the file download function is not blocked
                </div>
                </div>
              )}
        </div>
      </div>
    );
  }
}

DownloadMain = Form.create({})(DownloadMain);
export default DownloadMain;
