import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import BaseUpload from "commons/BaseUpload/index";
import YearPicker from "commons/YearPicker/index";
import moment from "moment";
import Utils from "src/js/utils";
import Apis from "src/js/apis/api";
import groupApis from "src/js/apis/group";
import Storage from "src/js/storage";
import "src/js/global.js";
import "./index.css";
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Modal,
  InputNumber
} from "antd";
import Users from "src/js/users";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
const { confirm } = Modal;
const languages = [
  "Arabic",
  "Arabic with English summary",
  "Arabic with English abstract",
  "Chinese",
  "Chinese with English summary",
  "Chinese with English abstract",
  "English",
  "French",
  "French with English summary",
  "French with English abstract",
  "German",
  "German with English summary",
  "German with English abstract",
  "Japanese",
  "Japanese with English summary",
  "Japanese with English abstract",
  "Portuguese",
  "Portuguese with English summary",
  "Portuguese with English abstract",
  "Russian",
  "Russian with English summary",
  "Russian with English abstract",
  "Spanish",
  "Spanish with English summary",
  "Spanish with English abstract",
  "Other languages"
];

class ReferenceAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorDatas: [
        {
          key: 0,
          name: "First"
        }
      ],
      editorsDatas: [
        {
          key: 0,
          name: "First"
        }
      ],
      uploadFiles: [],
      isGroup: false,
      groupList: [],
      checkerName: Storage.getItem("checkerName") || "",
      allChildError: {},
      yearValue: null
    };
  }

  isGbdbUser = async () => {
    let Inputerid=false
    let token = Storage.getItem("token");
     Inputerid = await Users.isGbdbInputer();
  
    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    } else {
      if (Inputerid == false) {
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title: "Sorry!!",
            content: "You do not have permission to add data"
          });
          this.props.history.push("/home");
        }
      }
    }
  };
  componentDidMount = () => {
    this.isGbdbUser();
  };
  onChangeFile = file => {
    let { uploadFiles } = this.state;

    let newest = file;

    this.setState(
      {
        uploadFiles: [...uploadFiles, newest]
      },
      () => {}
    );
  };
  removeFile = (file, index) => {
    let { uploadFiles } = this.state;
    uploadFiles.splice(index, 1);
    this.setState({
      uploadFiles
    });
  };
  dealName = (addReferenceData, data, type) => {
    let allName = "";
    let firstValue = "";
    let otherValue = "";

    data.map((item, index) => {
      // 姓
      let LastName = `ref_${type}_last_${item.key}`;
      // 名
      let FirstName = `ref_${type}_first_${item.key}`;

      // else {
      // if (addReferenceData[LastName] && addReferenceData[FirstName]) {
      if (item.key === 0) {
        firstValue = `${
          addReferenceData[LastName] ? addReferenceData[LastName] : ""
        }${addReferenceData[FirstName] ? addReferenceData[FirstName] : ""}`;
      }
      let name = `${
        addReferenceData[LastName] ? addReferenceData[LastName] : ""
      }${addReferenceData[FirstName] ? addReferenceData[FirstName] : ""}`;
      allName += name + ",";
      otherValue = allName;
      // }
      delete addReferenceData[`ref_${type}_last_${item.key}`];
      delete addReferenceData[`ref_${type}_first_${item.key}`];
      // }
    });

    if (firstValue) {
      addReferenceData[`ref_first_${type}`] = firstValue;
    }

    if (otherValue) {
      otherValue = otherValue.slice(0, otherValue.length - 1);
      addReferenceData[`ref_${type}s`] = otherValue;
    }

    return addReferenceData;
  };
  referenceGoSection = type => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (this.state.errorpage) {
        Modal.error({
          title: "Form filling error",
          content: "Please check the form fields"
        });
      }

      if (!err) {
        let addReferenceData = Utils.filterParams(values);

        addReferenceData = Utils.filterCondition(addReferenceData);
        addReferenceData = Utils.addEnterer(addReferenceData);
        addReferenceData = Utils.addAudit(addReferenceData, {});
        let authorDatas = this.state.authorDatas;
        let editorsDatas = this.state.editorsDatas;

        // 作者处理author
        this.dealName(addReferenceData, authorDatas, "author");
        this.dealName(addReferenceData, editorsDatas, "editor");

        const formData = new FormData();
        for (let [key, value] of Object.entries(addReferenceData)) {
          formData.append(key, value);
        }
        let { uploadFiles } = this.state;

        if (uploadFiles.length > 0) {
          uploadFiles.map((item, index) => {
            formData.append("files", item);
          });
        }

        let addRes = await Apis.createReference(formData);
        if (addRes.error) {
          Modal.error({
            title: "This is an error message",
            content: addRes.error
          });
        } else if (addRes.result) {
          if (type === "continue") {
            let url = "/section/baseinfo?";
            let refId = "refId=" + addRes.result[0].ref_id;
            let lastUrl = `${url}${refId}`;
            this.props.history.push(lastUrl);
          } else {
            this.props.form.resetFields();
            this.setState({
              yearValue: null,
              uploadFiles: [],
              authorDatas: [
                {
                  key: 0,
                  name: "First"
                }
              ],
              editorsDatas: [
                {
                  key: 0,
                  name: "First"
                }
              ]
            });
            // let url = "/references/search/reference";
            // this.props.history.push(url);
            Modal.success({
              content: "Successfully added "
            });
          }
        }
      }
    });
  };

  addAuthor = (e, type) => {
    let addDatas;
    if (type === "editorsDatas") {
      addDatas = this.state.editorsDatas;
    } else {
      addDatas = this.state.authorDatas;
    }

    let lastKey = addDatas[addDatas.length - 1].key;

    if (lastKey >= 9) {
      const modal = Modal.warn({
        title: "This is a notification message",
        content: `Add up to 10 users`
      });
      setTimeout(() => {
        modal.destroy();
      }, 3000);

      return;
    } else {
      let nAuth = [
        "First",
        "Second",
        "Third",
        "Fourth",
        "Fifth",
        "Sixth",
        "Seventh",
        "Eighth",
        "Ninth",
        "Tenth"
      ];
      addDatas.push({
        key: ++lastKey,
        name: nAuth[lastKey]
      });

      this.setState(
        {
          [type]: addDatas
        },
        () => {}
      );
    }
  };

  deleteAuthor = (e, dataType) => {
    let that = this;
    confirm({
      content: "Do you Want to delete last one",
      onOk() {
        let deleteData = [];
        if (dataType === "authorDatas") {
          deleteData = that.state.authorDatas;
        } else {
          deleteData = that.state.editorsDatas;
        }

        if (deleteData.length > 1) {
          deleteData.splice(-1, 1);
          that.setState(
            {
              [dataType]: deleteData
            },
            () => {}
          );
        } else {
          Modal.warn({
            title: "This is a notification message",
            content: `Keep at least one`
          });
        }
      },
      onCancel() {}
    });
  };

  publishYear = value => {
    this.setState({
      yearValue: value
    });
  };

  changeAccess = async value => {
    if (value === "2") {
      let uid = Storage.getItem("gbdbid");
      let params = {
        uid
      };
      let myGroups = await groupApis.getGroups(params);

      if (myGroups.error) {
        const modal = Modal.error({
          title: "This is an error message",
          content: myGroups.error
        });
        setTimeout(() => {
          modal.destroy();
        }, 3000);
      } else {
        if (myGroups.result.length === 0) {
          this.props.form.setFieldsValue({
            accessibility: "1"
          });
        }
        this.setState({
          groupList: myGroups.result,
          isGroup: true
        });
      }
    } else {
      this.setState({
        isGroup: false,
        groupList: []
      });
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let { authorDatas, editorsDatas } = this.state;

    return (
      <div id="referenceAdd">
        <div className="searchBase-title">
          <div className="reference-search-operate">
            <p>Add Reference</p>
          </div>
          <div className="common-back">
            <NavLink to="/references/search/reference">
              <span className="iconfont icon-back"></span>
            </NavLink>
          </div>
        </div>
        <div className="reference-add-main">
          <div className="reference-add-form">
            <Form>
              <div className="referenceAdd-title">
                <Form.Item label="Title">
                  {getFieldDecorator("ref_title", {
                    rules: [
                      {
                        required: true,
                        message: "Please input the title"
                      }
                    ],
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-authours">
                <div>
                  {/* 作者开始 */}
                  {authorDatas.map((item, index) => {
                    return (
                      <div className="referenceAdd-author" key={item.key}>
                        {index === 0 ? (
                          <div className="referenceAdd-author-title">
                            *Authour(s)
                          </div>
                        ) : null}
                        <span>
                          <FormItem label="" className="lastname-input">
                            {getFieldDecorator(`ref_author_last_${item.key}`, {
                              rules: [
                                {
                                  required: true,
                                  message: "Please input the Last Name!"
                                }
                              ],

                              initialValue: ""
                            })(<Input placeholder="Last Name"></Input>)}
                          </FormItem>
                        </span>
                        <span className="lastname-input">
                          <FormItem label="" className="">
                            {getFieldDecorator(`ref_author_first_${item.key}`, {
                              rules: [
                                {
                                  required: true,
                                  message: "Please input the First Name!"
                                }
                              ],

                              initialValue: ""
                            })(<Input placeholder="First Name"></Input>)}
                          </FormItem>
                        </span>

                        <span className="pc-first-author">
                          {item.name} Author
                        </span>
                      </div>
                    );
                  })}

                  <div className="referenceAdd-author-btn">
                    <Button
                      type="gbdb reference-btn-before"
                      onClick={e => this.addAuthor(e, "authorDatas")}
                    >
                      Add
                    </Button>
                    <Button
                      type="gbdb"
                      onClick={e => this.deleteAuthor(e, "authorDatas")}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>

              <div className="referenceAdd-publication">
                <Form.Item label="Publication years">
                  {getFieldDecorator("ref_publication_year", {
                    rules: [
                      // {
                      //   type: "object"
                      // }
                    ],
                    initialValue: ""
                  })(
                    <YearPicker
                      time={this.state.yearValue}
                      onChange={value => this.publishYear(value)}
                    ></YearPicker>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-type">
                <Form.Item label="Reference type">
                  {getFieldDecorator("ref_publication_type", {
                    rules: [{}],
                    initialValue: "Journal article"
                  })(
                    <Select placeholder="Journal article">
                      <Option value="Journal article">Journal article</Option>
                      <Option value="book">book</Option>
                      <Option value="edit book">edit book</Option>
                      <Option value="book chapter">book chapter</Option>
                      <Option value="Ph .D .dissertation">
                        Ph .D .dissertation
                      </Option>
                      <Option value="master thesis">master thesis</Option>
                      <Option value="paper presented at meeting">
                        paper presented at meeting
                      </Option>
                      <Option value="abstract">abstract</Option>
                      <Option value="technical report">technical report</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              {/* 和publish type重复 */}
              {/* <div className="referenceAdd-projectType">
                <Form.Item label="Project type" >
                  {getFieldDecorator("ref_publication_type", {
                    rules: [{}],
                    initialValue: "Fossil record"
                  })(
                    <Select placeholder="Fossil record">
                      {projectType.map((item, index) => {
                        return <Option value={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div> */}
              <div className="referenceAdd-language">
                <Form.Item label="Language">
                  {getFieldDecorator("ref_journal_language", {
                    rules: [
                      {
                        required: true,
                        message: "Please select the Language"
                      }
                    ],
                    initialValue: "English"
                  })(
                    <Select placeholder="English">
                      {languages.map((item, index) => {
                        return (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-book">
                <Form.Item label="Book/Report">
                  {getFieldDecorator("ref_journal_booktitle", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-book">
                <Form.Item label="Publisher">
                  {getFieldDecorator("ref_publisher", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-journal">
                <Form.Item label="Journal">
                  {getFieldDecorator("ref_journal", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-authours referenceAdd-editors">
                <div>
                  {editorsDatas.map((item, index) => {
                    return (
                      <div className="referenceAdd-author" key={item.key}>
                        {index === 0 ? (
                          <div className="referenceAdd-author-title">
                            Book editors
                          </div>
                        ) : null}
                        <span>
                          <FormItem label="" className="">
                            {getFieldDecorator(`ref_editor_last_${item.key}`, {
                              rules: [{}],
                              initialValue: ""
                            })(<Input placeholder="Last Name"></Input>)}
                          </FormItem>
                        </span>
                        <span className="lastname-input">
                          <FormItem label="" className="">
                            {getFieldDecorator(`ref_editor_first_${item.key}`, {
                              rules: [{}],
                              initialValue: ""
                            })(<Input placeholder="First Name"></Input>)}
                          </FormItem>
                        </span>

                        <span className="pc-first-author">
                          {item.name} Editor
                        </span>
                      </div>
                    );
                  })}

                  <div className="referenceAdd-author-btn">
                    <Button
                      type="gbdb reference-btn-before"
                      onClick={e => this.addAuthor(e, "editorsDatas")}
                    >
                      Add
                    </Button>
                    <Button
                      type="gbdb"
                      onClick={e => this.deleteAuthor(e, "editorsDatas")}
                    >
                      delete
                    </Button>
                  </div>
                </div>
              </div>

              <div className="referenceAdd-city">
                <Form.Item label="City">
                  {getFieldDecorator("ref_city", {
                    rules: [{}],
                    initialValue: ""
                  })(
                    // <Select placeholder="City">
                    //   <Option value="type1">type1</Option>
                    //   <Option value="type2">type2</Option>
                    // </Select>
                    <Input placeholder="Please input the city"></Input>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Volume">
                    {getFieldDecorator("ref_Journal_volume", {
                      rules: [{}],
                      initialValue: ""
                    })(<Input />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="No.">
                    {getFieldDecorator("ref_volume_no", {
                      // rules: [{ type: "number" }],
                      initialValue: ""
                    })(<Input min={0} placeholder=""></Input>)}
                  </Form.Item>
                </div>
              </div>
              <div className="referenceAdd-pageNumber">
                <div>
                  <div className="referenceAdd-author-title">Page number</div>
                  <div className="referenceAdd-author referenceAdd-hasline">
                    <span>
                      <FormItem
                        label=""
                        help={this.state.helppage}
                        validateStatus={
                          this.state.errorpage ? "error" : "success"
                        }
                      >
                        {getFieldDecorator("ref_page_number_begin", {
                          initialValue: ""
                        })(
                          // <InputNumber
                          // name="pagefrom"
                          // min={0}
                          // onChange={value =>
                          //   this.sectionStartChange(value, "page", "from")
                          // }
                          // ></InputNumber>
                          <Input placeholder="begin"></Input>
                        )}
                      </FormItem>
                    </span>
                    <span className="referenceAdd-pageNumber-line">
                      <span className="iconfont icon-line1"></span>
                    </span>
                    <span>
                      <FormItem
                        label=""
                        help={this.state.helppage}
                        validateStatus={
                          this.state.errorpage ? "error" : "success"
                        }
                      >
                        {getFieldDecorator("ref_page_number_end", {
                          initialValue: ""
                        })(
                          // <InputNumber
                          //   name="pageto"
                          //   onChange={value =>
                          //     this.sectionEndChange(value, "page", "to")
                          //   }
                          // min={1}></InputNumber>
                          <Input placeholder="end"></Input>
                        )}
                      </FormItem>
                    </span>
                  </div>
                </div>
              </div>

              <div className="referenceAdd-doi">
                <Form.Item label="DOI">
                  {getFieldDecorator("ref_doi", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-comments">
                <Form.Item label="Comments" className="long-label">
                  {getFieldDecorator("ref_comments", {
                    rules: [{}],
                    initialValue: ""
                  })(<TextArea rows={6}></TextArea>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-upload">
                <Form.Item label="Upload Files" extra="" className="long-label">
                  {getFieldDecorator("ref_file_path", {
                    // initialValue: this.state.uploadFiles
                  })(
                    <BaseUpload
                      // defaultImg={}
                      fileList={this.state.uploadFiles}
                      showImg={false}
                      listType="text"
                      showUploadList={true}
                      onChangeFile={file => this.onChangeFile(file)}
                      removeFile={(file, index) => this.removeFile(file, index)}
                    ></BaseUpload>
                  )}
                </Form.Item>
              </div>
              {/* <div className="referenceAdd-authorizer">
                <Form.Item label="Authorizer">
                  {getFieldDecorator("authorizer_id", {
                    rules: [{}],
                    initialValue: ''
                  })(<Input disabled placeholder={this.state.checkerName}></Input>)}
                </Form.Item>
              </div> */}
              <div className="referenceAdd-Accessibility">
                <Form.Item label="Accessibility">
                  {getFieldDecorator("accessibility", {
                    rules: [
                      {
                        required: true,
                        message: "Please select the Accessibility"
                      }
                    ],
                    initialValue: "1"
                  })(
                    <Select onChange={this.changeAccess}>
                      <Option value="0">Open</Option>
                      <Option value="1">Private</Option>
                      {/* <Option value="2">
                        <span>Group</span>
                      </Option> */}
                    </Select>
                  )}
                </Form.Item>
              </div>
              {/* {isGroup && groupList.length > 0 ? (
                <div className="referenceAdd-Accessibility">
                  <Form.Item label="Group">
                    {getFieldDecorator("group_id", {
                      rules: [
                        // {
                        //   required: true,
                        //   message: "Please select the Group ID"
                        // }
                      ]
                      // initialValue: ""
                    })(
                      <Select mode="multiple">
                        {groupList.map((item, index) => {
                          return <Option value={item.id}>{item.name}</Option>;
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              ) : isGroup && groupList.length <= 0 ? (
                <div className="no-group">
                  <span>
                    No group was found,You cannot select the 'group' option
                  </span>
                </div>
              ) : null} */}

              <div className="referenceAdd-publication last-formItem">
                <Form.Item label="Release date">
                  {getFieldDecorator("release_date", {
                    rules: [
                      {
                        required: true,
                        message: "Please select the Release date"
                      }
                    ],
                    initialValue: moment()
                  })(
                    <DatePicker
                      format={"YYYY-MM-DD"}
                      allowClear={false}
                      disabledDate={Utils.disabledDate}
                    ></DatePicker>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-btns">
                <div>
                  {/* onClick={()=>this.referenceGoSection()}  */}
                  <Button
                    type="gbdb"
                    onClick={() => this.referenceGoSection("continue")}
                  >
                    <p> Continue to</p>
                    <p>add section</p>
                  </Button>
                </div>
                <div>
                  <Button
                    type="gbdb"
                    className="referenceAdd-complete-btn"
                    onClick={() => this.referenceGoSection("complete")}
                  >
                    Complete
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
ReferenceAdd = Form.create({})(ReferenceAdd);
export default ReferenceAdd;
