import React, { Component } from "react";
import ReferenceOption from "commons/AllOptions/Reference/index.js";
import "src/js/global.js";

import Utils from "src/js/utils";
import "./index.css";
import { Form, Button, Modal } from "antd";

class SearchReference extends Component {
  constructor(props) {
    super(props);
    this.state = {
   
      allChildError:{}
    };
  }
 
  referenceSearch = () => {

    this.refs.reference.validateFieldsAndScroll(async (err, values) => {
    //  let {allChildError}=this.state
    //  if(JSON.stringify(allChildError)!=='{}'){
    //    const modal=Modal.warn({
    //      content:'Please check the query form'
    //    })
    //    return
    //  }
      if (!err) {
        let searchResults = Utils.filterParams(values);
        let searchValues = Utils.filterCondition(searchResults);
        this.setState({
          searchAllValues: values
        })
        let queryParam = {
          searchParams: searchValues,
          type: "reference",
          searchAllValues: values
        };
        let url = "/searchs/result/reference";
        this.props.history.push({ pathname: url, query: queryParam });
      }
    });
  };
  childError=(allChildError)=>{
  this.setState({
    allChildError
  })
  }
  render() {
    return (
      <div id="searchReference">
         {/* search-common-form */}
        <div className="searchReference-main reference-search-form">
          <div className="search-common-title form-common-title">
            Reference search
          </div>
          <ReferenceOption 
          childError={this.childError}
          
          ref="reference"
          ></ReferenceOption>

          <div className="referenceSearch-btns">
            <div>
              <Button
                type="gbdb"
                onClick={() => {
                  this.referenceSearch();
                }}
              >
                <p> Search</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SearchReference = Form.create({})(SearchReference);

export default SearchReference;
