import React from 'react'
import {Form,Button,message,Modal,Input} from 'antd'
import axios from 'axios'
import moment from 'moment'


class AntdForm extends React.Component{
    constructor(props){
        super(props)
        this.state={
           parentData:{},
           childData:{},
        }
    }
    componentDidMount(){
        const {modalData}=this.props
        if(modalData.parent_id){
            axios.get(`/api/admin/searchAssets?noState=1&assetstype=8&id=${modalData.parent_id}`).then(res=>{
                if(res.data.error===null && res.data.result.length>0){
                    this.setState({
                        parentData:res.data.result[0]
                    })
                }
            }).catch(err=>{
                message.error('出错啦：',err)
            })
        }
        if(modalData.child_id){
            axios.get(`/api/admin/searchAssets?noState=1&assetstype=8&id=${modalData.child_id}`).then(res=>{
                if(res.data.error===null && res.data.result.length>0){
                    this.setState({
                        childData:res.data.result[0]
                    })
                }
            }).catch(err=>{
                message.error('出错啦：',err)
            })
        }
    }
    handlePass(){
        Modal.confirm({
            content:`点击“确定”后，本条数据将通过审核!`,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                //请求通过
                var sendData={
                    assetstype:10,
                    id:this.props.modalData.id,
                    authState:1,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                }

                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已通过!')
                        this.props.cancelShowInfo();
                        this.props.refreshList()
                    }
                })
              
            }
        })
        //通过
       
    }
    handleReject(){
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                var sendData={
                    id:this.props.modalData.id,
                    assetstype:10,
                    authState:2,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                    auth_comment:str
                }
                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已驳回！')
                        this.props.cancelShowInfo()
                        this.props.refreshList()
                    }
                })
               
            },
          
        })
        //拒绝
    }
   
    render(){
        const {modalData}=this.props;
        const {parentData,childData}=this.state;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 },
            },
          };
      
        return (
            <div>
                <div>
                    <div className='modalTitle'>Taxarelation</div>
                    <div className='form'>
                        <Form {...formItemLayout}>

                            <Form.Item label='Reference'>
                                    <span>{modalData.ref_id}</span>
                            </Form.Item>
                        
                            <Form.Item label='Collect from treatise'>
                                <span>{modalData.from_treatise}</span>
                            </Form.Item>
                            <Form.Item label='Notes'>
                                <span>{modalData.notes}</span>
                            </Form.Item>

                            {
                                modalData.parent_id?(
                                    <Form.Item label='Parent'>
                                    <Form {...formItemLayout} style={{marginBottom:'30px'}}>
                           
                                        <Form.Item label='Fossil group'>
                                            <span>{parentData.fossil_group}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Taxon type'>
                                                <span>{parentData.taxon_type}</span>
                                        </Form.Item>
                                    
                                        <Form.Item label='Taxon name1'>
                                            <span>{parentData.taxon_name1}</span>
                                        </Form.Item>
                                        <Form.Item label='Taxon name2'>
                                            <span>{parentData.taxon_name2}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Nomenclator'>
                                            <span>{parentData.nomenclator}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Year'>
                                            <span>{parentData.year}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Collect form Treatise'>
                                            <span>{parentData.from_treatise}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Reference'>
                                            <span>{parentData.ref_id}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Notes'>
                                            <span>{parentData.notes}</span>
                                        </Form.Item>
            
                                    </Form>
                                    </Form.Item>
                                ):null
                            }

                            {
                                modalData.child_id?(
                                    <Form.Item label='child'>
                                    <Form {...formItemLayout}>
                           
                                        <Form.Item label='Fossil group'>
                                            <span>{childData.fossil_group}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Taxon type'>
                                                <span>{childData.taxon_type}</span>
                                        </Form.Item>
                                    
                                        <Form.Item label='Taxon name1'>
                                            <span>{childData.taxon_name1}</span>
                                        </Form.Item>
                                        <Form.Item label='Taxon name2'>
                                            <span>{childData.taxon_name2}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Nomenclator'>
                                            <span>{childData.nomenclator}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Year'>
                                            <span>{childData.year}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Collect form Treatise'>
                                            <span>{childData.from_treatise}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Reference'>
                                            <span>{childData.ref_id}</span>
                                        </Form.Item>
            
                                        <Form.Item label='Notes'>
                                            <span>{childData.notes}</span>
                                        </Form.Item>
            
                                    </Form>
                                    </Form.Item>
                                ):null
                            }

                        </Form>

                        <div className='foot'>
                            <Button  type='agbdb1' onClick={()=>{this.handlePass()}}>通过</Button>
                            <Button  type='agbdb2' onClick={()=>{this.handleReject()}}>拒绝</Button>
                        </div>
                    </div>
                </div>
        </div>
        )
      
    }
}
const Taxarelation = Form.create()(AntdForm);
export default Taxarelation