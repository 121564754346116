import React from 'react'
import { Modal, Form, Pagination, Table, message, Button } from 'antd'
import axios from 'axios'
import './infoModal.scss'
import CollectionInfoModal from './CollectionInfoModal'


export default class InfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            page: 1,
            pageSize: 5,
            total: 0,
            isShowCollection: false,
            formation_id:props.formation_id,
            unitLists: []
        }
    }
    

    getColumns = () => {
        return [
            {
                title: "index",
                dataIndex: "updatedAt",
                key: "a-index",
                render: (text, record, index) => {
                    return index + 1;
                }
            },
            {
                title: "Unit ID",
                dataIndex: "unit_id",
                key: "a-unit_id"
            },
            {
                title: "Unit No",
                dataIndex: "unit_no",
                key: "a-unit_no"
            },
            {
                title: "Unit Name",
                dataIndex: "unit_name",
                key: "a-unit_name"
            },
            {
                title: "Unit Thickness",
                dataIndex: "unit_thickness",
                key: "a-unit_thickness",
                render: (text, record, index) => {
                    return record.unit_thickness_sign +" "+ record.unit_thickness +record.unit_thickness_unit;
                }
            },
            {
                title: "Main Lithology",
                dataIndex: "unit_main_lithogya",
                key: "a-unit_main_lithogya"
            },
            {
                title: "Early Age",
                dataIndex: "early_age",
                key: "a-early_age",
                render: (text, record, index) => {
                    return text +" Ma";
                }
            },
            {
                title: "Late Age",
                dataIndex: "late_age",
                key: "a-late_age",
                render: (text, record, index) => {
                    return text +" Ma";
                }
            },
            {
                title: "",
                dataIndex: "unit_id",
                key: "a-edit",
                render: (text, record, index) => {
                    return  <Button type='agbdb1' onClick={this.showCollection.bind(this, text)}>Collection</Button>
                }
            },
        ]
    }

    getUnits = (page) => {
        // var id = window.formation_id;
        var id = this.state.formation_id;
        page = page || this.state.page;

        axios.get(`/api/search/map/units?formation_id=${id}&page=${page}&pageSize=${this.state.pageSize}`).then(res => {
            if (res.data.error === null) {
                let {result,page,total} = res.data;
                this.setState({
                    unitLists: result,page:Number(page),total:Number(total)
                })
            }
        }).catch(err => {
            message.error('出错啦：', err)
        })

    }

    componentDidMount() {
        var id = this.props.formation_id || window.formation_id;
        axios.get(`/api/search/map/section?formation_id=${id}`)
            .then(res => {
                if (res.data.error === null) {
                    this.setState({
                        info: res.data.result[0]
                    })
                    this.getUnits(1)
                }
            }).catch(err => {
                message.error('出错啦：', err)
            })
    }
    showCollection=(id)=> {
        console.log("ForId:"+this.state.info.formation_id);
        this.setState({
            params: {unit_id:id,formation_id:this.state.info.formation_id},
        },()=>{
            this.setState({ isShowCollection: true })
        })
      
    }
    cancelShowCollection=()=> {
        this.setState({
            isShowCollection: false
        })
    }

    changePage = (page) => {
      
        this.getUnits(page);
    }

    render() {
        const formItemLayout = {
            // layout:"inline",
            /* labelCol: {
              xs: { span: 24 },
              sm: { span: 4 },
              md: { span: 24 },
              lg: { span: 6 },
            }, */
            wrapperCol: {
                xs: { span: 15 },
                sm: { span: 15 },
                md: { span: 15 },
                lg: { span: 16 },
            },
        };
        const { info, isShowCollection } = this.state

        let paginationInfo = {
            current: Number(this.state.page),
            defaultCurrent: Number(this.state.page),
            pageSize: Number(this.state.pageSize),
            total: Number(this.state.total),
            onChange: this.changePage
        };

        return (
            <Modal
                width='1100px'
                className='AModal infoModal infoModal-inline'
                visible={this.props.visible}
                onCancel={this.props.cancelShowInfoModal}
                footer={null}
            >
                <div>
                    <Form {...formItemLayout}>
                        <div className='tip1'>Basic info</div>
                        <Form.Item label='section id'>
                            <span>{info.section_basic_id}</span>
                        </Form.Item>
                        <Form.Item label='section name'>
                            <span>{info.section_name}</span>
                        </Form.Item>
                        <Form.Item label='geology id'>
                            <span>{info.geology_id}</span>
                        </Form.Item>
                        <Form.Item label='geology locality'>
                            <span>{info.geology_locality}</span>
                        </Form.Item>
                        <Form.Item label='geology latitude'>
                            <span>{info.geology_latitude_decimal}</span>
                        </Form.Item>
                        <Form.Item label='geology longitude'>
                            <span>{info.geology_longitude_decimal}</span>
                        </Form.Item>
                        <div className='tip1'>Formation</div>
                        <Form.Item label='formation id'>
                            <span>{info.formation_id}</span>
                        </Form.Item>
                        <Form.Item label='formation no'>
                            <span>{info.formation_no}</span>
                        </Form.Item>
                        <Form.Item label='formation name'>
                            <span>{info.formation_name}</span>
                        </Form.Item>
                        <Form.Item label='formation thick'>
                            <span>{info.formation_thick ? (info.formation_thick_sign + info.formation_thick + info.formation_thick_unit) : ''}</span>
                        </Form.Item>
                        <Form.Item label='early age'>
                            <span>{info.early_age}</span>
                        </Form.Item>
                        <Form.Item label='late age'>
                            <span>{info.late_age}</span>
                        </Form.Item>

                    </Form>
                    { 1?(<div className="table-list modalTable">
                        <Table
                            dataSource={this.state.unitLists}
                            columns={this.getColumns()}
                            rowKey="id"
                            pagination={false}
                        />
                        {this.state.unitLists.length > 0 ? (
                            <div className="pagination-wrap unit-pages clearfix">
                                <Pagination {...paginationInfo}></Pagination>
                            </div>
                        ) : null}
                    </div>):<p className="no-units">No units</p>}
                    <div className='foot' style={{display:"none"}}>
                        <Button type='agbdb1' onClick={this.showCollection.bind(this)}>Collection</Button>
                        <Button type='agbdb2' onClick={this.props.cancelShowInfoModal}>Close</Button>
                    </div>
                    {
                        isShowCollection ? (
                            <CollectionInfoModal
                                params={this.state.params}
                                visible={isShowCollection}
                                cancelShowCollection={this.cancelShowCollection.bind(this)}
                            >
                            </CollectionInfoModal>
                        ) : null
                    }
                </div>

            </Modal>
        )
    }
}