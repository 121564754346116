import React, { Component } from "react";
import { Form, Input, Button, DatePicker, Modal, message } from "antd";

import "./index.css";
import BaseUpload from "commons/BaseUpload/index";
import userApis from "src/js/apis/user";
import Storage from "src/js/storage";
import moment from "moment";
import Utils from "src/js/utils";
import "src/js/global.js";

let showExpertData = (userType, authState) => {
  let showUser = false;
  if (authState === 2) {
    showUser = true;
  } else if (authState === 3 && userType === 2) {
    showUser = true;
  } else if (authState === 5 && userType !==1) {
    showUser = true;
  } else if (authState === 4&& userType==2) {
    showUser = true;
  }

  return showUser;
};
let userIdentity = (userType, authState) => {
  let Identity = "";
  // 认证中
  if (authState === 0) {
    Identity = "Not veryfied";
  } else if (authState === 1&& userType==2) {
    Identity = "Expert";
  } else if ( authState === 2) {
    Identity = "Veryfying";
  } else if (authState === 3) {
    switch (userType) {
      case 0:
        Identity = "Not veryfied";
        break;
      case 1:
        Identity = "Data compilier";
        break;
        case 2:
          Identity = "Expert";
          break;
      default:
        Identity = "";
    }
  }else if(authState === 5){
    Identity = "Failed";
  }else if(authState === 4&& userType==2){
    Identity = "Expert";
  }
  return Identity;
};
class Expert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadFiles: {},
      userData: {},
      filePath: process.env.PUBLIC_URL + "/imgs/uploadUser.png",
      otherPddbs: [],
      paperList: []
    };
  }
  isGbdbUser = () => {
    let token = Storage.getItem("token");

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    }
  };
  async componentDidMount() {
    this.isGbdbUser();
    let allUserData = {};
    let token = Storage.getItem("token");
    let params = {
      token,
      loginby: 1
    };
    let fossilBack = await userApis.initUser(params);
    if (fossilBack.result) {
      let updateUser = {
        userid: fossilBack.result.id,
        loginby: 1
      };
      let gbdbUserBack = await userApis.gbdbUser(updateUser);
      if (gbdbUserBack.result) {
        let gbdbData = gbdbUserBack.result;
        if (gbdbData) {
          let { userType, authState } = gbdbData;
          // let isExpert = showExpertData(userType, authState);
          // if (isExpert === false) {
          //   let userData = {};
          //   this.setState({
          //     userData: Object.assign(userData, fossilBack.result,gbdbData, {
          //       id: gbdbData.id,
          //       authState: authState,
          //       userType: userType,
          //     })
          //   });

          //   return;
          // }
        }
        let baseData = fossilBack.result;
        Object.assign(allUserData, gbdbData, baseData);
        // 设置默认图片
        this.setState(
          {
            filePath: "/api" + gbdbData.filePath
          },
          () => {}
        );
        // 设置标签
        let otherpddb = gbdbData.otherpddb;
        if (otherpddb !== "" && otherpddb.indexOf(",") !== -1) {
          let otherPddbs = otherpddb.split(",");
          this.setState({
            otherPddbs: otherPddbs
          });
        } else if (otherpddb !== "" && otherpddb.indexOf(",") === -1) {
          let otherPddbs = [otherpddb];

          this.setState({
            otherPddbs
          });
        }

        let paperList = gbdbData.paperList;
        if (paperList !== "" && paperList.indexOf(",") !== -1) {
          paperList = paperList.split(",");
          this.setState({
            paperList
          });
        } else if (paperList !== "" && paperList.indexOf(",") === -1) {
          this.setState({
            paperList: [paperList]
          });
        }

        let showCheckInput = gbdbData.checker;
        if (showCheckInput) {
          this.setState({
            showCheckInput: false
          });
        } else {
          this.setState({
            showCheckInput: true
          });
        }
      } else {
        this.props.history.push("/home");
      }

      this.setState({
        userData: allUserData
      });
    } else {
      if (fossilBack.state === 204) {
        Storage.removeItem("token");
        Storage.removeItem("fossilid");
        Storage.removeItem("gbdbid");
        Storage.removeItem("checkerid");
        Storage.removeItem("checkerName");
        Storage.removeItem("gbdblevel");
        Storage.removeItem("Inputerid");
        return;
      }
      Modal.error({
        title: "This is an error message",
        content: fossilBack.error
      });
      this.props.history.push("/home");
    }
  }
  changeExpert = () => {
    let that=this
    that.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let defaultPath='/api/assets/user/default/upload.png'
            let { filePath, uploadFiles } = that.state;
            if (filePath == defaultPath && JSON.stringify(uploadFiles) === "{}") {
              message.error("Please upload proof pictures");
              return;
            }
        Modal.confirm({
          title: 'Make sure  to be an expert?',
          content: "the application data will overwrite the data compiler's data",
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          async  onOk() {
           
            let pbdbStr = "";
            let paperListStr = "";
            // let expertData = Utils.filterParams(values);
            let expertData = values;
            let { otherPddbs,paperList } = that.state;
           
            if (otherPddbs.length > 0) {
              pbdbStr = otherPddbs.join(",");
              expertData.otherpddb = pbdbStr;
            }
            if (paperList.length > 0) {
              paperListStr = paperList.join(",");
              expertData.paperList = paperListStr;
            }
            if (expertData.study_time_start) {
              expertData.study_time_start = moment(
                expertData.study_time_start
              ).format("YYYY-MM-DD");
            }
            if (expertData.study_time_end) {
              expertData.study_time_end = moment(expertData.study_time_end).format(
                "YYYY-MM-DD"
              );
            }
            const formData = new FormData();
            let { userData } = that.state;
    
            let id = userData.id;
            formData.append("id", id);
            let files = that.state.uploadFiles;
            for (let [key, value] of Object.entries(expertData)) {
              formData.append(key, value);
            }
            formData.append("files", files);
    
            let compilerUserBack = await userApis.userAuthExpert(formData);
            if (compilerUserBack.error) {
              Modal.error({
                title: "This is an error message",
                content: compilerUserBack.error
              });
            } else {
              Modal.success({
                title: "This is a successful message",
                content: "Modify successfully"
              });
            }
          },
          onCancel() {
        
          },
        });
      
      }
    });
   
    
  };
  onChangeFile = file => {
    let newest = file;
    this.setState(
      {
        uploadFiles: newest
      },
      () => {}
    );
  };
  cancelExpert = () => {
    this.props.form.resetFields();

    Modal.info({
      title: "This is a notification message",
      content: "The modification message has been reset"
    });
  };
  addotherPbdb = () => {
    let { otherPddbs } = this.state;
    let pbdbValue = this.props.form.getFieldValue("otherpddb");
    if (pbdbValue !== "") {
      let hasIndex = otherPddbs.findIndex(function(value, index, arr) {
        return value === pbdbValue;
      });
      if (hasIndex === -1) {
        otherPddbs = [pbdbValue, ...otherPddbs];
        this.setState({
          otherPddbs
        });
        this.props.form.setFieldsValue({
          otherpddb: ""
        });
      } else {
        Modal.error({
          title: "This is an error message",
          content: "name repetition"
        });
      }
    }
  };
  deletePddbs = (item, index) => {
    let { otherPddbs } = this.state;
    otherPddbs.splice(index, 1);
    this.setState({
      otherPddbs
    });
  };

  addpaperList = () => {
    let { paperList } = this.state;
    let paperValue = this.props.form.getFieldValue("paperList");
    if (paperValue !== "") {
      let hasIndex = paperList.findIndex(function(value, index, arr) {
        return value === paperValue;
      });
      if (hasIndex === -1) {
        paperList = [paperValue, ...paperList];
        this.setState({
          paperList
        });
        this.props.form.setFieldsValue({
          paperList: ""
        });
      } else {
        Modal.error({
          title: "This is an error message",
          content: "name repetition"
        });
      }
    }
  };
  deletePaper = (item, index) => {
    let { paperList } = this.state;
    paperList.splice(index, 1);
    this.setState({
      paperList
    });
  };
  render() {
    let { getFieldDecorator } = this.props.form;
    let {
      filePath,
      userData,
      otherPddbs,

      paperList
    } = this.state;

    return (
      <div id="compiler_main" className="">
        <div className="form-mian-content profile-basic">
          <div className="profile-basic-title compiler-title">Expert</div>
          <div className="profile-basic-content clearfix compiler-content">
            <div className="profile-basic-form">
              <Form layout="vertical">
                <div className="compiler-realname">
                  <Form.Item label="First Name">
                    {getFieldDecorator("firstname", {
                      initialValue: userData.firstname || "",
                      rules: [
                        {
                          required: true,
                        }
                      ],
                      
                    })(<Input placeholder="" />)}
                  </Form.Item>
                  <Form.Item label="Last Name">
                    {getFieldDecorator("lastname", {
                      initialValue: userData.lastname || "",
                      rules: [
                        {
                          required: true,
                        }
                      ],
                    })(<Input placeholder="" />)}
                  </Form.Item>
                </div>
                <Form.Item label="Degree">
                  {getFieldDecorator("degree", {
                    initialValue: userData.degree || ""
                  })(<Input placeholder="" />)}
                </Form.Item>
                <Form.Item label="Graduate from">
                  {getFieldDecorator("graduate", {
                    initialValue: userData.graduate || ""
                  })(<Input placeholder="" />)}
                </Form.Item>
                <div className="compiler-realname compiler-study-time">
                  <Form.Item label="Study Time">
                    {getFieldDecorator("study_time_start", {
                      rules: [
                        {
                          type: "object"
                        }
                      ],
                      initialValue: userData.study_time_start
                        ? moment(userData.study_time_start)
                        : null
                    })(<DatePicker format="YYYY-MM-DD"></DatePicker>)}
                  </Form.Item>
                  <Form.Item label="">
                    {getFieldDecorator("study_time_end", {
                      rules: [
                        {
                          type: "object"
                        }
                      ],
                      initialValue: userData.study_time_start
                        ? moment(userData.study_time_end)
                        : null
                    })(<DatePicker format="YYYY-MM-DD"></DatePicker>)}
                  </Form.Item>
                </div>
                <Form.Item label="Present Institute">
                  {getFieldDecorator("preInstitute", {
                    initialValue: userData.preInstitute || ""
                  })(<Input placeholder="" />)}
                </Form.Item>
                <Form.Item label="Major in">
                  {getFieldDecorator("major", {
                    initialValue: userData.major || ""
                  })(<Input placeholder="" />)}
                </Form.Item>
                <Form.Item label="Boss">
                  {getFieldDecorator("boss", {
                    initialValue: userData.boss || ""
                  })(<Input placeholder="" />)}
                </Form.Item>
                <Form.Item label="Other Palaeobiogeography Database">
                  {getFieldDecorator("otherpddb", {
                    initialValue: ""
                  })(<Input placeholder="" />)}
                  <Button
                    type="gbdb"
                    className="profile-change-password compiler-code-btn"
                    onClick={this.addotherPbdb}
                  >
                    Add
                  </Button>
                </Form.Item>
                {otherPddbs.length > 0 ? (
                  <div className="otherpbdb-datebase clearfix">
                    {otherPddbs.map((item, index) => {
                      return (
                        <span
                          className="otherpbdb-datebase-item"
                          key={item}
                          title={item}
                        >
                          {item}
                          <span
                            className="iconfont icon-baseline-close-px"
                            onClick={() => this.deletePddbs(item, index)}
                          ></span>
                        </span>
                      );
                    })}
                  </div>
                ) : null}
                <Form.Item label="Paper list">
                  {getFieldDecorator("paperList", {
                    initialValue:  ""
                  })(<Input placeholder="" />)}
                  <Button
                    type="gbdb"
                    className="profile-change-password compiler-code-btn"
                    onClick={this.addpaperList}
                  >
                    Add
                  </Button>
                </Form.Item>
                {paperList.length > 0 ? (
                  <div className="otherpbdb-datebase clearfix">
                    {paperList.map((item, index) => {
                      return (
                        <span
                          className="otherpbdb-datebase-item"
                          key={item}
                          title={item}
                        >
                          {item}
                          <span
                            className="iconfont icon-baseline-close-px"
                            onClick={() => this.deletePaper(item, index)}
                          ></span>
                        </span>
                      );
                    })}
                  </div>
                ) : null}
                <Form.Item label="Other information">
                  {getFieldDecorator("otherInfo", {
                    initialValue: userData.otherInfo || ""
                  })(<Input placeholder="" />)}
                </Form.Item>
              </Form>
            </div>

            <div className="profile-basic-photo">
              <div className="basic-photo-show">
                {/* <img src={require("src/assets/images/profile/upload.png")} /> */}
                <BaseUpload
                  showImg={true}
                  defaultImg={filePath}
                  listType="picture-card"
                  showUploadList={false}
                  onChangeFile={file => this.onChangeFile(file)}
                ></BaseUpload>
              </div>

              {/* <div className="basic-upload-confirm">
                <Button type="gbdb" className="profile-change-password">
                  Confirm
                </Button>
              </div> */}
              <div className="user-indentity-result">
                {userIdentity(userData.userType, userData.authState)}
              </div>
            </div>
          </div>

          <div className="profile-basic-btns">
            <Button
              type="gbdb"
              className="profile-change-password"
              onClick={this.changeExpert}
            >
              Submit
            </Button>
            {/* <Button
              type="gbdb"
              className="profile-change-password"
              onClick={this.cancelExpert}
            >
              Cancel
            </Button> */}
          </div>
          <div className="identity-tip">
            <div>Tips:</div>
            <div>
              Expert and data compiler identity mutually exclusive, both can
              only choose one
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Expert = Form.create({})(Expert);
export default Expert;
