import React, { Component } from "react";
import SectionOption from "commons/AllOptions/Section/index.js";

import Utils from "src/js/utils";

import "./index.css";


import { Form, Button ,Modal} from "antd";

class SearchSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allChildError: {},
      selectDuration: { erathem_max: [], period_max: [], epoch_max: [], early_interval: [] },
    };
  }

  sectionSearch = () => {
    this.refs.section.validateFieldsAndScroll(async (err, values) => {
      // let { allChildError } = this.state;
      // if (JSON.stringify(allChildError) !== "{}") {
      //  Modal.warn({
      //     content: "Please check the query form"
      //   });
      //   return;
      // }
      if (!err) {
        let searchResults = Utils.filterParams(values);

        let queryCondition = Utils.filterCondition(searchResults);
        let {selectDuration}=this.state
       
        if(selectDuration.erathem_max.length>0){
          queryCondition.erathem_max=selectDuration.erathem_max
        }
        if(selectDuration.period_max.length>0){
          queryCondition.period_max=selectDuration.period_max
        }
        if(selectDuration.epoch_max.length>0){
          queryCondition.epoch_max=selectDuration.epoch_max
        }
        if(selectDuration.early_interval.length>0){
          queryCondition.early_interval=selectDuration.early_interval
        }

        // 注释
       
        if (queryCondition) {
          let queryParam = {
            searchParams: queryCondition,
            type: "section",
            searchAllValues: values,
            selectDuration:this.state.selectDuration
          };
          let url = "/searchs/result/section/base";
          this.props.history.push({ pathname: url, query: queryParam });
        }
      }
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  changeDuration= selectDuration => {
   
    this.setState({
      selectDuration
    },()=>{
    
   
    });
  };
  render() {
    return (
      <div id="searchSection">
        <div className="searchSection-main  reference-search-form search-common-form">
          <div className="search-common-title form-common-title">
            Section search
          </div>
          <SectionOption
            showBase={true}
            showGeography={true}
            showUnit={true}
            showFomation={false}
            showOccurrence={true}
            childError={this.childError}
            showAlloption={true}
            ref="section"
            changeDuration={this.changeDuration}
          ></SectionOption>

          <div className="referenceSearch-btns">
            <div>
              <Button
                type="gbdb"
                onClick={() => {
                  this.sectionSearch();
                }}
              >
              {
                   <p> Search</p>
                  }
                 
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SearchSection = Form.create({})(SearchSection);
export default SearchSection;
