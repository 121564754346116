import React from "react";

import { Form, Input, Select, Modal, Button, InputNumber, message } from "antd";
import Apis from "src/js/apis/api";
import Utils from "src/js/utils";
import "./index.css";
import Storage from "src/js/storage";
import { withRouter } from "react-router-dom";
import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";

import SectionOption from "commons/AllOptions/Section/index.js";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";
import BaseTableResult from "commons/AllTableList/Section/Base/index.js";
import FormationTableResult from "commons/AllTableList/Section/Formation/index.js";
import FormationDetailForm from "commons/AllDetails/Section/Formation/index.js";

import centerApis from "src/js/apis/center";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
@withRouter
class UnitDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      // reference , taxonomy , section,occurence,formation
      detailData: props.detailData || {},
      sectionData: {},
      seeOnly: props.seeOnly === true ? true : false,
      unitId: "",

      inheritRefIds: "",
      formationId: "",
      formationInfo: {},
      baseInfo: {},
      isSearch: false,

      baseId: "",
      searchType: "",
      conditionType: "geology",

      refCondition: false,
      geographySearch: false,
      sectionOptionVisible: false,
      sectionType: "baseinfo",
      allChildError: {},
      isCenterUser: props.isCenterUser === true ? true : false,
    };
  }
  async componentDidMount() {
    let unitId = "";
    let UnitsBack;
    let { detailData } = this.state;
    let { isCenterUser } = this.state;
    // let isCenter=(this.props.location.pathname==='/center')
    if (detailData && detailData.unit_id !== undefined) {
      unitId = detailData.unit_id;
    }
    this.setState(
      {
        unitId,
      },
      async () => {
        let { unitId } = this.state;
        if (unitId !== "") {
          if (!isCenterUser) {
            let params = {
              unit_id: unitId,
            };

            UnitsBack = await Apis.getUnits(params);
          } else {
            let params = {
              assetstype: 12,
              enterer: Storage.getItem("gbdbid"),
              unit_id: unitId,
            };
            UnitsBack = await centerApis.searchAssets(params);
          }

          if (UnitsBack.result && UnitsBack.result.length > 0) {
            this.setState({
              detailData: UnitsBack.result[0],
            });
          } else {
            this.setState({
              detailData: {},
            });
          }
        }
      }
    );
  }
  Calculate = async (e) => {
    let earlyValue = this.props.form.getFieldValue("early_age");
    let lateValue = this.props.form.getFieldValue("late_age");

    if (!isNaN(earlyValue) && !isNaN(lateValue)) {
      if (parseFloat(earlyValue) < parseFloat(lateValue)) {
        message.error("early_age must be greater than late_age");

        return;
      }
    }
  };
  showSearchRef = (e) => {
    e.target.blur();
    this.setState({
      refCondition: true,
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false,
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false,
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false,
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false,
    });
  };
  clickRefId = (value) => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value,
    });
  };
  onRef = (ref) => {
    this.child = ref;
  };

  getDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true,
    });

    //点击 detail 按扭执行代码
  };

  checkRefOne = (value) => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value,
    });
  };
  childError = (allChildError) => {
    this.setState({
      allChildError,
    });
  };
  checkAgain = (optionType) => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    let showType = "refSearch";

    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (optionType === "reference") {
        showType = "refSearch";
      } else {
        showType = "geographySearch";
      }

      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            sectionOptionVisible: false,
            [showType]: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType,
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues, sectionType } = this.state;

              if (optionType === "reference") {
                this.child.searchReferenceDatas(searchParams, searchValues);
              } else {
                this.baseChild.searchSectionDatas(searchParams, searchValues, sectionType);
              }
            }, 20);
          }
        );
      }
    });
  };

  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false,
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false,
    });
  };

  // section
  onBase = (ref) => {
    this.baseChild = ref;
  };
  geographyDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showSectionDetailForm: true,
    });
  };
  handFormOk = () => {
    this.setState({
      sectionOptionVisible: false,
    });
  };
  handleFormCancel = () => {
    this.setState({
      sectionOptionVisible: false,
    });
    Modal.destroyAll();
  };

  showSearchGeography = (type, e) => {
    e.target.blur();
    this.setState(
      {
        sectionType: type,
      },
      () => {
        this.setState({
          sectionOptionVisible: true,
        });
      }
    );
  };
  handleGeographyOk = () => {
    this.setState({
      geographySearch: false,
    });
  };
  handleGeographyCancel = () => {
    this.setState({
      geographySearch: false,
    });
  };
  handleFormChange = (changedFields) => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields },
    }));
  };

  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType: "geology",
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType: "baseinfo",
    });
  };
  detailSectionFormOk = () => {
    this.setState({
      showSectionDetailForm: false,
      // sectionType: "baseinfo"
    });
  };
  detailSectionFormCancel = () => {
    this.setState({
      showSectionDetailForm: false,
      // sectionType: "baseinfo"
    });
  };

  checkBaseOne = (value) => {
    this.props.form.setFieldsValue({
      section_basic_id: value.section_id || "",
      ref_id: value.ref_id || "",
      geology_id: value.geology_id || "",
      formation_id: value.formation_id || null,
      early_age: null,
      late_age: null,
    });
    this.setState({
      inheritRefIds: value.ref_id,
      baseId: value.section_id,
      geographyId: value.geology_id,
      formationId: value.formation_id,
      geographySearch: false,
      baseInfo: value,
    });
  };
  checkFormationOne = (value) => {
    this.props.form.setFieldsValue({
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || "",
      geology_id: value.geology_id || "",
      formation_id: value.formation_id || null,
      early_age: value.early_age || null,
      late_age: value.late_age || null,
    });
    this.setState({
      inheritRefIds: value.ref_id,
      baseId: value.section_id,
      geographyId: value.geology_id,

      geographySearch: false,
      formationInfo: value,
    });
  };
  modifyUnit = () => {
    let { refInfo, detailData } = this.state;
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let earlyValue = this.props.form.getFieldValue("early_age");
        let lateValue = this.props.form.getFieldValue("late_age");

        if (!isNaN(earlyValue) && !isNaN(lateValue)) {
          if (parseFloat(earlyValue) < parseFloat(lateValue)) {
            message.error("early_age must be greater than late_age");

            return;
          }
        }

        if (values.unit_thickness !== "" && values.unit_thickness !== null && values.unit_thickness !== undefined) {
          if (values.unit_thickness_unit === "cm") {
            let value = parseFloat(values.unit_thickness) / 100;
            values.unit_thickness = parseFloat(value.toFixed(4));
            values.unit_thickness_unit = "meters";
          } else if (values.unit_thickness_unit === "feet") {
            let value = parseFloat(values.unit_thickness) * 0.3048;
            values.unit_thickness = parseFloat(value.toFixed(4));
            values.unit_thickness_unit = "meters";
          }
        }
        // let unitInfo = Utils.filterParams(values);
        let unitInfo = Utils.filterCondition(values);
        unitInfo = Utils.addEnterer(unitInfo);
        let sectionData = {};
        let searchParams = {};
        if (values.formation_id !== null) {
          searchParams = {
            formation_id: values.formation_id,
          };
          let FormationsBack = await Apis.getFormations(searchParams);
          if (FormationsBack.result && FormationsBack.result.length > 0) {
            sectionData = FormationsBack.result[0];
          }
        } else {
          searchParams = {
            section_id: values.section_basic_id,
          };
          let sectionBase = await Apis.sectionBase(searchParams);

          if (sectionBase.result && sectionBase.result.length > 0) {
            sectionData = sectionBase.result[0];
          }
        }

        unitInfo = Utils.addAudit(unitInfo, sectionData);

        unitInfo.id = detailData.unit_id;
        unitInfo.authState = 0;
        // 服务器注释

        let isCalculate = true;
        if ((earlyValue === "" || earlyValue === null || earlyValue === undefined)
         && (lateValue === "" || lateValue === null || lateValue === undefined)) {
          isCalculate = false;
        }

        if (isCalculate) {
          let params = {
            early_age: earlyValue,
            late_age: lateValue,
          };
          let TimeLinesById = await Apis.getEarlyInterval(params);
          if (TimeLinesById && TimeLinesById.result) {
            let TimeLines = TimeLinesById.result;

            if (TimeLines) {
              let erathem_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.erathem_max ? TimeLines.ages.erathem_max.name : "";
              let period_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.period_max ? TimeLines.ages.period_max.name : "";
              let epoch_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.epoch_max ? TimeLines.ages.epoch_max.name : "";
              let early_interval = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.early_interval ? TimeLines.ages.early_interval.name : "";

              if (erathem_max !== "") {
                unitInfo.erathem_max = erathem_max;
              }
              if (period_max !== "") {
                unitInfo.period_max = period_max;
              }
              if (epoch_max !== "") {
                unitInfo.epoch_max = epoch_max;
              }
              if (early_interval !== "") {
                unitInfo.early_interval = early_interval;
              }
            }
          } else {
            if (TimeLinesById.error) {
              Modal.error({
                content: TimeLinesById.error,
              });
            }
          }
        }

        let changeunit = await Apis.changeUnitById(unitInfo);
        if (changeunit.error) {
          Modal.error({
            title: "This is an error message",
            content: changeunit.error,
          });
        } else if (changeunit.result) {
          // Modal.success({
          //   title: "This is a success message",
          //   content: 'modify success'
          // });
          if (this.props.modifyunit) {
            this.props.modifyunit(changeunit.result);
          }
        }
      }
    });
  };
  convertUnit = (e) => {
    const { getFieldValue } = this.props.form;
    let resultAltitude, resultUnit;
    let altitude = getFieldValue("unit_thickness");
    let unit = getFieldValue("unit_thickness_unit");
    if (altitude !== "" && altitude !== null && altitude !== undefined) {
      if (unit == "meters") {
        let altitudess = parseFloat(altitude) * 3.2808399;
        resultAltitude = parseFloat(altitudess.toFixed(4));
        resultUnit = "feet";
      } else if (unit == "feet") {
        let altitudess = parseFloat(altitude) * 0.3048;
        resultAltitude = parseFloat(altitudess.toFixed(4));
        resultUnit = "meters";
      }
      this.props.form.setFieldsValue({
        unit_thickness: resultAltitude,
        unit_thickness_unit: resultUnit,
      });
    }
  };
  render() {
    let { getFieldDecorator } = this.props.form;

    let {
      formationId,
      inheritRefIds,

      baseId,

      searchParams,
      sectionOptionVisible,
      sectionType,
      searchValues,
      detailData,
      seeOnly,
    } = this.state;

    let { enterer } = detailData;
    let userid = Storage.getItem("gbdbid");
    let isClick = false;
    let isCenter = this.props.location.pathname === "/center";
    if (enterer && userid && enterer === userid && isCenter) {
      isClick = true;
    }
    if (isClick === false) {
      getFieldDecorator = (...rest) => {
        return (element) => {
          let NewElement = React.cloneElement(element, {
            disabled: true,
          });
          return this.props.form.getFieldDecorator(...rest)(NewElement);
        };
      };
    }

    return (
      <div id="detail_form">
        <div className="">
          <div className="detailform-title">
            <span className="detailform-name">Unit</span>
          </div>
          <div className="sectionUnit-form" id="main">
            <Form>
              <div className="referenceAdd-title reference-inherit">
                {
                  <span className="geography-ref-item">
                    <Form.Item label="Reference ID">
                      {getFieldDecorator("ref_id", {
                        rules: [
                          {
                            type: "number",
                            required: true,
                            message: "Please input the Reference",
                          },
                        ],
                        initialValue: detailData.ref_id || "",
                      })(<InputNumber disabled onFocus={this.showSearchRef}></InputNumber>)}
                    </Form.Item>
                  </span>
                }
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Section ID">
                  {getFieldDecorator("section_basic_id", {
                    rules: [
                      {
                        type: "number",
                        required: true,
                        message: "Please input the Section",
                      },
                    ],
                    initialValue: detailData.section_basic_id || "",
                  })(<InputNumber disabled onFocus={(e) => this.showSearchGeography("baseinfo", e)}></InputNumber>)}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label="Formation ID">
                  {getFieldDecorator("formation_id", {
                    rules: [
                      {
                        type: "number",
                      },
                      // {
                      //   required: true,
                      //   message: "Please input the Formation ID"
                      // }
                    ],
                    initialValue: detailData.formation_id || null,
                  })(<InputNumber disabled onFocus={(e) => this.showSearchGeography("formation", e)}></InputNumber>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Unit Name">
                  {getFieldDecorator("unit_name", {
                    rules: [{ required: true }],
                    initialValue: detailData.unit_name || "",
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              {/* <div className="referenceAdd-title">
                <Form.Item label="Unit ID">
                  {getFieldDecorator("unit_id", {
                    rules: [
                      {
                        required: true,
                        message: "Please input the Unit ID "
                      }
                    ],
                    initialValue: ""
                  })(<Input></Input>)}
                </Form.Item>
              </div> */}

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Early age">
                    {getFieldDecorator("early_age", {
                      rules: [
                        {
                          // required: true,
                          // message: "error early age"
                          type: "number",
                        },
                      ],
                      initialValue: detailData.early_age !== null ? parseFloat(detailData.early_age) : null,
                    })(
                      <InputNumber
                        className="age-format-unit"
                        // formatter={value => value ? `${value}Ma` : ""}
                        // parser={value => value.replace('Ma', '')}
                        step={0.01}
                        min={0}
                        onBlur={(e) => this.Calculate(e)}
                        name="early_age"
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Late age">
                    {getFieldDecorator("late_age", {
                      rules: [
                        {
                          // required: true,
                          // message: "error late age"
                          type: "number",
                        },
                      ],
                      initialValue: detailData.late_age !== null ? parseFloat(detailData.late_age) : null,
                    })(
                      <InputNumber
                        className="age-format-unit"
                        // formatter={value => value ? `${value}Ma` : ""}
                        // parser={value => value.replace('Ma', '')}
                        step={0.01}
                        min={0}
                        onBlur={(e) => this.Calculate(e)}
                        name="late_age"
                      />
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="sectionGeography-map">
                <div className="sectionGeography-map-border"></div>
                <div className="sectionGeography-tutude sectionGeography-tutude-title">
                  <span>Color1</span>
                  <span>Color2</span>
                  <span>Lithology1</span>
                  <span>Lithology2</span>
                  <span>Main Lithology*</span>
                  <span>Relation</span>
                </div>

                <div className="sectionGeography-tutude">
                  <span>
                    <FormItem label={<p className="h5-map-title">Color1：</p>}>
                      {getFieldDecorator("unit_color1a", {
                        initialValue: detailData ? detailData.unit_color1a : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Color2：</p>}>
                      {getFieldDecorator("unit_color2a", {
                        initialValue: detailData ? detailData.unit_color2a : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Lithology1：</p>}>
                      {getFieldDecorator("unit_lithology1a", {
                        initialValue: detailData ? detailData.unit_lithology1a : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Lithology2：</p>}>
                      {getFieldDecorator("unit_lithology2a", {
                        initialValue: detailData ? detailData.unit_lithology2a : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem className="require-aLithology" label={<p className="h5-map-title">Main Lithology*</p>}>
                      {getFieldDecorator("unit_main_lithogya", {
                        initialValue: detailData ? detailData.unit_main_lithogya : "",
                        rules: [
                          {
                            required: true,
                          },
                        ],
                      })(<Input placeholder="MainLithogya"></Input>)}
                    </FormItem>
                  </span>

                  <span>
                    <FormItem label={<p className="h5-map-title">Relation：</p>}>
                      {getFieldDecorator("unit_relationa", {
                        initialValue: detailData ? detailData.unit_relationa : "",
                      })(
                        <Select>
                          <Option value="and" title="And">
                            And
                          </Option>
                          <Option value="interbedded by with some" title="Interbedded by with some">
                            Interbedded by with some
                          </Option>
                          <Option value="with on the top" title="With on the top">
                            With on the top
                          </Option>
                          <Option value="with in the middle" title="With in the middle">
                            With in the middle
                          </Option>
                          <Option value="with at the base" title="With at the base">
                            With at the base
                          </Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>

                <div className="sectionGeography-tutude">
                  <span>
                    <FormItem label={<p className="h5-map-title">Color1：</p>}>
                      {getFieldDecorator("unit_color1b", {
                        initialValue: detailData ? detailData.unit_color1b : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Color2：</p>}>
                      {getFieldDecorator("unit_color2b", {
                        initialValue: detailData ? detailData.unit_color2b : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Lithology1：</p>}>
                      {getFieldDecorator("unit_lithology1b", {
                        initialValue: detailData ? detailData.unit_lithology1b : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Lithology2：</p>}>
                      {getFieldDecorator("unit_lithology2b", {
                        initialValue: detailData ? detailData.unit_lithology2b : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Main Lithology</p>}>
                      {getFieldDecorator("unit_main_lithogyb", {
                        initialValue: detailData ? detailData.unit_main_lithogyb : "",
                        rules: [
                          {
                            // required: true,
                          },
                        ],
                      })(<Input placeholder="MainLithogyb"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Relation：</p>}>
                      {getFieldDecorator("unit_relationb", {
                        initialValue: detailData ? detailData.unit_relationb : "",
                      })(
                        <Select>
                          <Option value="and">And</Option>
                          <Option value="interbedded by with some">Interbedded by with some</Option>
                          <Option value="with on the top">With on the top</Option>
                          <Option value="with in the middle">With in the middle</Option>
                          <Option value="with at the base">With at the base</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>

                <div className="sectionGeography-tutude">
                  <span>
                    <FormItem label={<p className="h5-map-title">Color1：</p>}>
                      {getFieldDecorator("unit_color1c", {
                        initialValue: detailData ? detailData.unit_color1c : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Color2：</p>}>
                      {getFieldDecorator("unit_color2c", {
                        initialValue: detailData ? detailData.unit_color2c : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Lithology1：</p>}>
                      {getFieldDecorator("unit_lithology1c", {
                        initialValue: detailData ? detailData.unit_lithology1c : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Lithology2：</p>}>
                      {getFieldDecorator("unit_lithology2c", {
                        initialValue: detailData ? detailData.unit_lithology2c : "",
                      })(<Input></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Main Lithology</p>}>
                      {getFieldDecorator("unit_main_lithogyc", {
                        initialValue: detailData ? detailData.unit_main_lithogyc : "",
                        rules: [
                          {
                            // required: true,
                          },
                        ],
                      })(<Input placeholder="MainLithogyc"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Relation：</p>}>
                      {getFieldDecorator("unit_relationc", {
                        initialValue: detailData ? detailData.unit_relationc : "",
                      })(
                        <Select>
                          <Option value="and">And</Option>
                          <Option value="interbedded by with some">Interbedded by with some</Option>
                          <Option value="with on the top">With on the top</Option>
                          <Option value="with in the middle">With in the middle</Option>
                          <Option value="with at the base">With at the base</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>
              </div>

              <div className="sectionGeography-map sectionGeography-map-nopadding">
                <div className="sectionGeography-tutude">
                  <span className="sectionFormation-thickness">
                    <FormItem label="Thickness">
                      {getFieldDecorator("unit_thickness_sign", {
                        initialValue: detailData ? detailData.unit_thickness_sign : "",
                      })(
                        <Select>
                          <Option value="=">=</Option>
                          <Option value="<">&lt;</Option>
                          <Option value=">">&gt;</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>

                  <span>
                    <FormItem label="">
                      {getFieldDecorator("unit_thickness", {
                        initialValue: detailData ? detailData.unit_thickness : "",
                      })(<InputNumber placeholder=""></InputNumber>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label="">
                      {getFieldDecorator("unit_thickness_unit", {
                        initialValue: detailData ? detailData.unit_thickness_unit : "meters",
                      })(
                        <Select>
                          <Option value="meters">Meter</Option>
                          <Option value="cm">Cm</Option>
                          <Option value="feet">Feet</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                  <span className="iconfont icon-shuaxin result-convert-unit" title="convert unit" onClick={(e) => this.convertUnit(e)}></span>
                </div>
              </div>

              {/* <div className="referenceAdd-journal">
                <Form.Item label={<p>Altitude</p>}>
                  {getFieldDecorator("unit_altitude", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div> */}
              <div className="referenceAdd-journal">
                <Form.Item label={<p>Contact base</p>}>
                  {getFieldDecorator("unit_con_base", {
                    rules: [{}],
                    initialValue: detailData ? detailData.unit_con_base : "",
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-journal">
                <Form.Item label={<p>Sediment</p>}>
                  {getFieldDecorator("unit_sediment", {
                    rules: [{}],
                    initialValue: detailData ? detailData.unit_sediment : "",
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item label={<p>Facies</p>}>
                  {getFieldDecorator("unit_facies", {
                    rules: [{}],
                    initialValue: detailData ? detailData.unit_facies : "",
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-comments last-formItem">
                <Form.Item label="Paleoenvironment" className="long-label">
                  {getFieldDecorator("unit_paleoenvironment", {
                    rules: [{}],
                    initialValue: detailData ? detailData.unit_paleoenvironment : "",
                  })(<TextArea rows={6}></TextArea>)}
                </Form.Item>
              </div>
              {detailData.auth_time !== null ? (
                <div className="referenceAdd-authours referenceAdd-editors">
                  <div className="authcomment-details">
                    {detailData.authState === 2 ? (
                      <div className="referenceAdd-author authcomment-detail-item">
                        <div className="referenceAdd-author-title authcomment-title">Auth Comment</div>
                        <div className="authcomment-content">{detailData.auth_comment !== "" ? detailData.auth_comment : "No comment"}</div>
                      </div>
                    ) : null}
                    {detailData.authState !== 0 ? (
                      <div className="referenceAdd-author authcomment-detail-item">
                        <div className="referenceAdd-author-title authcomment-title">Auth Time</div>
                        <div className="authcomment-content">{detailData.auth_time ? detailData.auth_time.split("T")[0] : ""}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Form>
            <div className="referenceSearch-btns sectionBase-btns">
              {isClick && seeOnly === false ? (
                <Button type="gbdb" onClick={() => this.modifyUnit()}>
                  <div>
                    <p>Save modify</p>
                  </div>
                </Button>
              ) : null}
            </div>
          </div>
        </div>

        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}
          <ReferenceOption childError={this.childError} conditions={{}} ref="reference"></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}

          <ReferenceTableResult
            searchParams={searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            onlySeeRef={true}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showRefDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailRefFormOk}
          onCancel={this.detailRefFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
            // searchSection={this.searchSection}
          ></ReferenceDetailForm>
        </Modal>

        {/* section */}

        <Modal
          destroyOnClose
          className="searchResultModal"
          width="76%"
          visible={sectionOptionVisible}
          footer=""
          // closable={false}
          onOk={this.handFormOk}
          onCancel={this.handleFormCancel}
        >
          <SectionOption
            // conditions={conditions}
            showGeography={sectionType === "geology" ? true : false}
            showUnit={false}
            showBase={sectionType === "baseinfo" ? true : false}
            showFomation={sectionType === "formation" ? true : false}
            ref={sectionType}
            childError={this.childError}
          ></SectionOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain(sectionType);
                  }}
                >
                  {<p> Search</p>}
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.geographySearch}
          className="searchResultModal taxResultModal noBottomPadding tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleGeographyOk}
          onCancel={this.handleGeographyCancel}
        >
          {sectionType === "baseinfo" || sectionType === "geology" ? (
            <BaseTableResult
              searchParams={searchParams}
              searchValues={searchValues}
              onBase={this.onBase}
              changeCheckSelected={this.changeCheckSelected}
              getDetail={this.geographyDetail}
              checkOne={true}
              checkBaseOne={this.checkBaseOne}
            ></BaseTableResult>
          ) : sectionType === "formation" ? (
            <FormationTableResult
              searchParams={searchParams}
              searchValues={searchValues}
              onBase={this.onBase}
              changeCheckSelected={this.changeCheckSelected}
              getDetail={this.geographyDetail}
              checkOne={true}
              checkFormationOne={this.checkFormationOne}
            ></FormationTableResult>
          ) : null}
        </Modal>
        <Modal
          title=""
          destroyOnClose
          visible={this.state.showSectionDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76%"
          footer={null}
          // header={null}
          onOk={this.detailSectionFormOk}
          onCancel={this.detailSectionFormCancel}
        >
          {sectionType === "baseinfo" ? (
            <BaseDetailForm detailData={this.state.detailData} viewGeography={this.viewGeography} seeOnly={true}></BaseDetailForm>
          ) : sectionType === "geology" ? (
            <GeographyDetailForm seeOnly={true} detailData={this.state.detailData} viewBase={this.viewBase}></GeographyDetailForm>
          ) : sectionType === "formation" ? (
            <FormationDetailForm seeOnly={true} detailData={this.state.detailData}></FormationDetailForm>
          ) : null}
        </Modal>
      </div>
    );
  }
}
UnitDetailForm = Form.create({})(UnitDetailForm);
export default UnitDetailForm;
