import AMap from 'AMap';
import axios from 'axios';
import { Divider } from 'antd';
import subsetApis from "src/js/apis/subset";
import Utils from "src/js/utils";
// import testPointData from './summary'
// import capitals from './data/markers.json';
// var capitals=[];
var timer;
let AMapUI = window.AMapUI;

let AmapManager = (function(){

    let center = [118,32]//南京
    let map = {};

    // 所有标记点
    let markers = [];

    return {
        init: function (callback,searchName) { 
            if (!AMapUI)
            {
                AMapUI = window.AMapUI;
            }

            map = new AMap.Map('svgMap', {
                resizeEnable: true,
                center: [116.205467, 39.907761],//地图偏移避免显示不完整
                zoom: 4,
                // lang: 'en',
                mapStyle: "amap://styles/b64855a810bd38b3ed1ba0e838ffac75",
                features: ['bg','road']
            })
            this.drawMarkers(callback,searchName);
        },

        subsetInit: function (callback,sectionIds) { 
            if (!AMapUI)
            {
                AMapUI = window.AMapUI;
            }

            map = new AMap.Map('svgMap', {
                resizeEnable: true,
                center: [116.205467, 39.907761],//地图偏移避免显示不完整
                zoom: 4,
                // lang: 'en',
                mapStyle: "amap://styles/b64855a810bd38b3ed1ba0e838ffac75",
                features: ['bg','road']
            })
            this.subsetDrawMarkers(callback,sectionIds);
        },

         subsetDrawMarkers(callback,sectionIds){
                let searchParams=Utils.filterCondition(sectionIds);
                let params = {sectionIds:searchParams};
                // let downBack =  subsetApis.getSubsetPoint(params);
            subsetApis.getSubsetPoint(params).then(res=>{
                if(res.result.length!=0){
                    console.log("result:"+res.result);
                    var capitals=""
                    capitals=res.result;
                    this.markerPoints(capitals, callback);
                }else{
                    alert("Incomplete data of selected section")
                    if(callback) callback("Incomplete data of selected section")
                }
            })
            .catch((error)=>{
                alert("No data find!")
                console.error(error)
                if(callback) callback(error)
            })
            
        },

        // 绘制标记点
        drawMarkers(callback,searchName,age_from,age_to,time_name){
            var color=(c)=>{
                c=c.split('/').join(',');
                return `rgb(${c})`
            }
            var makeData=function(data){ 
                return data
                /*.filter(item=>{
                    return item.geology_latitude_decimal && item.geology_longitude_decimal
                })*/
                .map(item=>{
                    return {
                        name:`section No(${item.section_basic_id}) ${item.section_name?item.section_name:''}`,
                        value:[item.geology_longitude_decimal,item.geology_latitude_decimal],
                        color:color(item.age_color),
                        formation_id:item.formation_id,
                        collection_count:item.collection_count,
                        fossilCount:item.fossilCount
                    }
                })
            }
            if(age_from && age_to){
                var sendData={
                    age_from,
                    age_to
                }
                // axios.get('/api/search/geologyPoints/now',{
                // axios.get('/api/assets/map/points/'+time_name+'.json',{
                axios.get('/api/assets/map/points/modern/'+time_name+'.json',{
                    params:sendData
                }).then(res=>{
                    // if(res.data.error===null){
                    // if(res.data.error===null){
                    if(res.data){
                        // 数据进行了二次加工后，不再需要makeData处理
                        // var capitals=makeData(res.data.result)

                        // var capitals=res.data;

                        var capitals=[];
                        if(searchName){
                            // res.data.map(item=>{
                            //     if(item.name.match(searchName)){
                            //         capitals.push(item);
                            //     }
                            // })
                            var searchNames=searchName.split(',')
                            res.data.map(item=>{
                                var dataFlag=false;
                                searchNames.map(searchName=>{
                                    if(item.name.match(searchName)){
                                        dataFlag=true;
                                    }
                                })
                                if(dataFlag){
                                    capitals.push(item);
                                }
                            })
                        }else{
                            capitals=res.data;
                        }

                        //console.log("data:"+capitals);
                        this.markerPoints(capitals, callback)

                        // this.makeMarkers(capitals)
                        // if(callback) callback()
                        
                        // paleo_nav.hideLoading();
                    }
                })
                .catch((error)=>{
                    alert("No data find!")
                    console.error(error)
                    if(callback) callback(error)
                })
            }else{
                // axios.get('/api/search/geologyPoints/now').then(res=>{
                // axios.get('/api/assets/map/points/Phanerozoic.json').then(res=>{
                axios.get('/api/assets/map/points/modern/Phanerozoic.json').then(res=>{
                    // if(res.data.error===null){
                    if(res){
                        var capitals=[];
                        if(searchName){
                            var searchNames=searchName.split(',')
                            res.data.map(item=>{
                                var dataFlag=false;
                                searchNames.map(searchName=>{
                                    if(item.name.match(searchName)){
                                        dataFlag=true;
                                    }
                                })
                                if(dataFlag){
                                    capitals.push(item);
                                }
                            })
                        }else{
                            capitals=res.data;
                        }

                        this.markerPoints(capitals, callback)
                        // if(callback) callback()

                        // paleo_nav.hideLoading();
                      
                    }
                })
                .catch((error)=>{
                    alert("No data find!")
                    console.error(error)
                    if(callback) callback(error)
                })
            }
        },
        makeMarkers: async function (capitals) { 

            for(var i=0;i<capitals.length;i+=1) {
                let {value,name,color,formation_id,collection_count,fossilCount} =  capitals[i];
                let center = value;
                let title=name;
                var circleMarker = new AMap.CircleMarker({
                    center:center,
                    radius:5,//3D视图下，CircleMarker半径不要超过64px
                    strokeColor:color,
                    strokeWeight:2,
                    strokeOpacity:0.5,
                    fillColor:color,
                    fillOpacity:0.5,
                    zIndex:10,
                    bubble:true,
                    cursor:'pointer',
                    formation_id,
                    clickable: true,
                  })
            
                  circleMarker.on('click',(e)=>{
                      //获取到当前点击标记的formation_id
                      if(e.target.w){
                        window.formation_id=e.target.w.formation_id
                        //调用Home组件中的方法，显示Modal
                        window.showInfoModal()
                      
                      }
                    
                  })
                  circleMarker.on('mouseover',(e)=>{

                    window.setTotal(collection_count,fossilCount)
                      clearTimeout(timer)
                    var noMark=document.querySelector('#noMark')
                    if(noMark){
                        document.body.removeChild(noMark)
                    }

                    noMark=document.createElement('span');
                    noMark.id='noMark';
                    noMark.innerHTML=title;
                    noMark.style.position='absolute';
                    noMark.style.top=e.pixel.y+30+'px';
                    noMark.style.left=e.pixel.x+'px';
                    // noMark.style.background='#ccc'
                    document.body.appendChild(noMark)
                  })
                  circleMarker.on('mouseout',()=>{
                    window.clearTotal()
                        var noMark=document.querySelector('#noMark');
                        document.body.removeChild(noMark)
                  })
                  circleMarker.setMap(map);
                  //存储
                  markers.push(circleMarker);
            }
        },

        markerPoints: function (points,callback) {

            if (window.pathSimplifierIns) {
                //通过该方法清空上次传入的轨迹
                window.pathSimplifierIns.setData([]);
                window.pathSimplifierIns.setData([]);
            }

            AMapUI.load(['ui/misc/PointSimplifier', 'lib/$'], function (PointSimplifier, $) {

                if (!PointSimplifier.supportCanvas) {
                    alert('当前环境不支持 Canvas！');
                    return;
                }

                var pointSimplifierIns = new PointSimplifier({
                    zIndex: 115,
                    autoSetFitView: false,
                    map: map, //所属的地图实例

                    getPosition: function (item) {
                        if (!item) {
                            return null;
                        }
                        
                        return item.value;

                    },
                    getHoverTitle: function (dataItem, idx) {
                        return dataItem.name;
                    },
                    //使用GroupStyleRender
                    renderConstructor: PointSimplifier.Render.Canvas.GroupStyleRender,
                    renderOptions: {
                        //点的样式
                        pointStyle: {
                            fillStyle: 'red',
                            width: 12,
                            height: 12
                        },
                       
                        topNAreaStyle:{
                            content:'none'
                        },
                        getGroupId: function (item, idx) {

                            
                            return item.color2+"_"+ item.count;
                            // return item;
                        },
                        groupStyleOptions: function (gid) {

                            let colorItems = gid.split("_");
                            let color = colorItems[0];
                            let count = Number(colorItems[1]);
                            var size = 10+(count-1)*2;
                            let color2 = color.replace(",0.7",",1")
                            if (map.getZoom()<5)
                            {
                                size = 10;
                            }

                            return {
                                pointStyle: {
                                    content: "circle",
                                    strokeStyle:"rgba(150,150,150,0)",
                                    lineWidth:0.5,
                                    fillStyle: color,
                                    width: size,
                                    height: size
                                },
                                pointHoverStyle:{
                                    strokeStyle:"rgba(0,0,0,.5)",
                                    lineWidth:0.4,
                                    fillStyle: color2,
                                    width: size*3/2,
                                    height: size*3/2
                                },
                                pointHardcoreStyle: {
                                    width: 1,
                                    height: 1
                                }
                            };
                        }

                    }
                });


                pointSimplifierIns.setData(points);

                window.pathSimplifierIns = pointSimplifierIns;

                //监听事件
                pointSimplifierIns.on('pointClick', function(e, record) {
                    if(record.data){
                        window.formation_id=record.data.formation_id
                        //调用Home组件中的方法，显示Modal
                        window.showInfoModal(record.data)
                      
                      }
                });
                pointSimplifierIns.on('pointMouseover ', function(e, record) {
                    if(record.data){
                        window.setTotal(record.data.collection_count,record.data.fossilCount)
                      }
                });
                pointSimplifierIns.on('pointMouseout ', function(e, record) {
                    if(window.clearTotal){
                         window.clearTotal()
                      }
                });
                pointSimplifierIns.render();
                if(callback) callback()
            });
        },

        // 清除标记点
        clearMarkers: ()=>{
            if(markers.length>0){
                map.remove(markers);
                markers = [];
            }
        },

        // 更新显示的点集
        update: ( searchName, data , callback)=>{
            
            
            AmapManager.clearMarkers();
            
            AmapManager.drawMarkers(callback,searchName,data.age_from,data.age_to, data.name);
        },

        map:map,
    }
})()

window.AmapManager = AmapManager;

export default  AmapManager;