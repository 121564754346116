import React from "react";

import { Form, Input, Select, Button, Modal, DatePicker } from "antd";
import BaseUpload from "commons/BaseUpload/index";
import YearPicker from "commons/YearPicker/index";
import moment from "moment";
import datas from "commons/data.js";
import Utils from "src/js/utils";

import Storage from "src/js/storage";
import "./index.css";
import { withRouter } from "react-router-dom";
import Apis from "src/js/apis/api";
import centerApis from "src/js/apis/center";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
const { confirm } = Modal;
let nAuth = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth"
];
@withRouter
class ReferenceDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      // reference , taxonomy , section,occurence,formation
      detailData: props.detailData || {},

      defaultFileLists: [],
      seeSection: props.seeSection === true ? true : false,
      seeOccurrences: props.seeOccurrences === true ? true : false,
      onlySeeRef: props.onlySeeRef === false ? false : true,
      newauthorDatas: [],
      neweditorsDatas: [],
      isCenterUser: props.isCenterUser === true ? true : false,
      // 详情修改
      authorDatas: [
      
      ],
      editorsDatas: [
      
      ],
      uploadFiles: [],
      defaultFiles:[],
      allFiles:[],
      ref_authors: "",
      ref_editors: "",
      yearValue: null
    };
  }

  async componentDidMount() {
    let ref_id = this.state.detailData.ref_id;
    let refresResult;

    let { isCenterUser } = this.state;
    // let isCenter = this.props.location.pathname === "/center";
    if (!isCenterUser) {
      let params = {
        ref_id
      };
      refresResult = await Apis.searchReference(params);
    } else {
      let params = {
        assetstype: 1,
        enterer: Storage.getItem("gbdbid"),
        ref_id
      };
      refresResult = await centerApis.searchAssets(params);
    }

    if (refresResult.result) {
      this.setState(
        {
          detailData: refresResult.result[0] || {}
        },
        () => {
          let { detailData } = this.state;

          if (detailData.ref_authors !== "") {
            this.setState(
              {
                authorDatas: Utils.authors(detailData.ref_authors),

                ref_authors: detailData.ref_authors
              },
              () => {}
            );
          }
          if (detailData.ref_editors !== "") {
            this.setState({
              editorsDatas: Utils.authors(detailData.ref_editors),
              ref_editors: detailData.ref_editors
            });
          }
        }
      );
    } else {
      Modal.error({
        content: refresResult.error
      });
    }
    let params = {
      ref_id
    };
    let getFiles = await Apis.searchFiles(params);

    if (getFiles.result) {
      this.setState(
        {
          defaultFileLists: getFiles.result,
        
        },
        () => {
          if(getFiles.result&&getFiles.result.length>0){
            let {defaultFileLists}=this.state
            let defaultFile = {};
            defaultFileLists.map((item, index) => {
              defaultFile[item.id] = {
                // 文件唯一标识，防止和内部产生的 id 冲突
                uid: item.id,
                name: item.filename,
                status: "done",
                url: `/api` + item.filePath,
                data: item,
                linkProps: {"download":  ''}
              };
            });
            this.setState({
              // defaultFiles:Object.values(defaultFile),
              allFiles:Object.values(defaultFile),
            })
          }
        }
      );
    } else {
      Modal.error({
        content: getFiles.error
      });
    }
  }
  viewSection = () => {
    let { detailData } = this.state;

    if (this.props.searchSection) {
      let searchParams = {
        ref_id: detailData.ref_id
      };
      
      this.props.searchSection(searchParams);
    }
  };
  viewOccurrences = () => {
    let { detailData } = this.state;

    if (this.props.searchOccurenceDatas) {
      let searchParams = {
        ref_id: detailData.ref_id
      };
      this.props.searchOccurenceDatas(searchParams);
    }
  };
  publishYear = value => {
    this.setState({
      yearValue: value
    });
    this.props.form.setFieldsValue({
      ref_publication_year: value
    });
  };

  // 详情修改
  addAuthor = (e, type) => {
    let addDatas = [];
    if (type === "neweditorsDatas") {
      addDatas = this.state.neweditorsDatas;
    } else if (type === "newauthorDatas") {
      addDatas = this.state.newauthorDatas;
    }
    let lastKey = 0;
    if (addDatas.length !== 0) {
      lastKey = addDatas[addDatas.length - 1].key;
    }

    if (lastKey >= 9) {
      const modal = Modal.warn({
        title: "This is a notification message",
        content: `Add up to 10 users`
      });
      setTimeout(() => {
        modal.destroy();
      }, 2000);

      return;
    } else {
      addDatas.push({
        key: ++lastKey,
        name: nAuth[lastKey - 1]
      });

      this.setState(
        {
          [type]: addDatas
        },
        () => {}
      );
    }
  };

  deleteAuthor = (e, dataType, index) => {
    let that = this;
    confirm({
      content: "Do you Want to delete the one",
      onOk() {
        let deleteData = that.state[dataType];
        let deleteName = "";
        let dealName = "";
        if (dataType === "authorDatas") {
          deleteName = that.state.ref_authors;
          dealName = "ref_authors";
        } else if (dataType === "newauthorDatas") {
          deleteName = that.state.ref_authors;
        } else if (dataType === "neweditorsDatas") {
          deleteName = that.state.ref_editors;
        } else if (dataType === "editorsDatas") {
          deleteName = that.state.ref_editors;
          dealName = "ref_editors";
        }

        if (deleteName !== "" && dealName !== "") {
          let array = deleteName.split(",");
          array.splice(index, 1);

          deleteName = array.join(",");

          that.setState({
            [dealName]: deleteName
          });
        }

        if (deleteData.length >= 1) {
          deleteData.splice(index, 1);
          that.setState(
            {
              [dataType]: deleteData
            },
            () => {}
          );
        } else {
          // Modal.warn({
          //   title: "This is a notification message",
          //   content: `Keep at least one`
          // });
        }
      },
      onCancel() {}
    });
  };
  onChangeFile = file => {
    let { uploadFiles } = this.state;
   
    let newest = file;
    
    this.setState(
      {
        uploadFiles: [...uploadFiles, newest],
        
      },
      () => {
      
        this.setState({
          allFiles:[...this.state.allFiles,newest]
        })
      }
    );
  };
  removeFile = async (file, index) => {
    let that=this
    Modal.confirm({
      title: "Do you want to delete the file?",
      content: "Unrecoverable after deletion!!!",
      async onOk() {
        if (file.data) {
          let params = {
            id: file.data.id,
            ref_id: file.data.ref_id
          };
          let deleteFileBack = await Apis.deleteFileById(params);
    
          if (deleteFileBack.result) {
            let { allFiles } = that.state;
    
            allFiles.splice(index, 1);
            that.setState({
              allFiles
            });
          }
        } else {
          let { allFiles ,uploadFiles} = that.state;
    
          allFiles.splice(index, 1);
          uploadFiles.splice(index, 1);
          that.setState({
            allFiles,
            uploadFiles
          });
        }
      },
      onCancel() {}
    });
  
    
  };
  dealName = (addReferenceData, data, type) => {
    let allName = "";
    let firstValue = "";
    let otherValue = "";

    data.map((item, index) => {
      // 姓
      let LastName = `ref_${type}_last_${item.key}`;
      // 名
      let FirstName = `ref_${type}_first_${item.key}`;

      // else {
      // if (addReferenceData[LastName] && addReferenceData[FirstName]) {
      if (item.key === 0) {
        firstValue = `${
          addReferenceData[LastName] ? addReferenceData[LastName] : ""
        }${addReferenceData[FirstName] ? addReferenceData[FirstName] : ""}`;
      }
      let name = `${
        addReferenceData[LastName] ? addReferenceData[LastName] : ""
      }${addReferenceData[FirstName] ? addReferenceData[FirstName] : ""}`;
      if (name !== "") {
        allName += name + ",";
      }

      otherValue = allName;
      // }
      delete addReferenceData[`ref_${type}_last_${item.key}`];
      delete addReferenceData[`ref_${type}_first_${item.key}`];
      // }
    });

    if (otherValue) {
      otherValue = otherValue.slice(0, otherValue.length - 1);
    }
    return otherValue;
  };
  modifyReference = () => {
    let { detailData } = this.state;

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        // let addReferenceData = Utils.filterParams(values);

        let addReferenceData = Utils.filterCondition(values);
        addReferenceData = Utils.addEnterer(addReferenceData);
        addReferenceData = Utils.addAudit(addReferenceData, {});
        // addReferenceData.authState = 0;

        let newauthorDatas = this.state.newauthorDatas;
        let neweditorsDatas = this.state.neweditorsDatas;

        let authors = [newauthorDatas];
        // 作者处理author
        let lastAuthor = "";
        let lastEdiotr = "";

        let dealAuthor = this.dealName(
          addReferenceData,
          newauthorDatas,
          "author"
        );
        let dealEditor = this.dealName(
          addReferenceData,
          neweditorsDatas,
          "editor"
        );

        if (this.state.ref_authors !== "" && dealAuthor !== "") {
          lastAuthor = this.state.ref_authors + "," + dealAuthor;
        } else if (this.state.ref_authors == "" && dealAuthor !== "") {
          lastAuthor = dealAuthor;
        } else {
          lastAuthor = this.state.ref_authors;
        }

        if (this.state.ref_editors !== "" && dealEditor !== "") {
          lastEdiotr = this.state.ref_editors + "," + dealEditor;
        } else if (this.state.ref_editors == "" && dealEditor !== "") {
          lastEdiotr = dealEditor;
        } else {
          lastEdiotr = this.state.ref_editors;
        }

        if (lastAuthor == "") {
          Modal.error({
            title: "error",
            content: "Please enter the first author"
          });
          return;
        }

        addReferenceData.ref_authors = lastAuthor;
        addReferenceData.ref_editors = lastEdiotr;

        if (lastAuthor !== "") {
          addReferenceData.ref_first_author = lastAuthor.split(",")[0];
        } else {
          addReferenceData.ref_first_author = "";
        }
        if (lastEdiotr !== "") {
          addReferenceData.ref_first_editor = lastEdiotr.split(",")[0];
        } else {
          addReferenceData.ref_first_editor = "";
        }
        const formData = new FormData();
        for (let [key, value] of Object.entries(addReferenceData)) {
          formData.append(key, value);
        }
        let { uploadFiles } = this.state;

        if (uploadFiles.length > 0) {
          uploadFiles.map((item, index) => {
            formData.append("files", item);
          });
        }
        formData.append("id", detailData.ref_id);

        let changeReference = await Apis.changeById(formData);

        if (changeReference.error) {
          Modal.error({
            title: "This is a err message",
            content: changeReference.error
          });
        } else if (changeReference.result) {
          if (this.props.modifyReference) {
            this.props.modifyReference(changeReference.result);
          }
        }
      }
    });
  };
  render() {
    let {
      detailData,
      onlySeeRef,
      defaultFileLists,
      editorsDatas,
      authorDatas
    } = this.state;
    let { getFieldDecorator } = this.props.form;
    let userid = Storage.getItem("gbdbid");

    let { enterer } = detailData;

    let isClick = false;
    let isCenter = this.props.location.pathname === "/center";
    if (enterer && userid && enterer === userid && isCenter) {
      isClick = true;
    }
    if (isClick === false) {
      getFieldDecorator = (...rest) => {
        return element => {
          let NewElement = React.cloneElement(element, {
            disabled: true
          });
          return this.props.form.getFieldDecorator(...rest)(NewElement);
        };
      };
    }
   
    let { languages } = datas;

    return (
      <div id="detail_form">
        <div className="">
          <div className="detailform-title">
            <span className="detailform-name">Reference</span>
          </div>

          <div className="reference-add-form " id="main">
            <Form>
              {isClick === true ? (
                <div className="referenceAdd-title">
                  <Form.Item label="Title">
                    {getFieldDecorator("ref_title", {
                      rules: [
                        {
                          required: true,
                          message: "Please input the title"
                        }
                      ],
                      initialValue: detailData.ref_title || ""
                    })(<Input />)}
                  </Form.Item>
                </div>
              ) : (
                <div className="referenceAdd-title referenceAdd-showall-title">
                 
                  <div className="referenceAdd-author-title referenceAdd-show-title">Title</div>
                  <span className="referenceAdd-title-detail">{detailData.ref_title || ""}</span>
                </div>
              )}

              <div className="referenceAdd-authours">
                <div>
                  {/* 作者开始 */}
                  {authorDatas.length > 0 ? (
                    authorDatas.map((item, index) => {
                      // authorDatas处理
                      return (
                        <div
                          className="referenceAdd-author reference-writing-name"
                          key={"author" + index}
                        >
                          {index === 0 ? (
                            <div className="referenceAdd-author-title">
                              *Authour(s)
                            </div>
                          ) : null}
                          <span>{item.value}</span>
                          {isClick === true ? (
                            <span
                              onClick={e =>
                                this.deleteAuthor(e, "authorDatas", index)
                              }
                              className="iconfont icon-delete reference-writing-del"
                            ></span>
                          ) : null}
                        </div>
                      );
                    })
                  ) : authorDatas.length === 0 &&
                    this.state.newauthorDatas.length === 0 ? (
                    <div className="referenceAdd-author ">
                      <div className="referenceAdd-author-title">
                        *Authour(s)
                      </div>

                      <span>none</span>
                    </div>
                  ) : (
                    <div className="referenceAdd-author ">
                      <div className="referenceAdd-author-title">
                        *Authour(s)
                      </div>
                    </div>
                  )}
                  {this.state.newauthorDatas.map((item, index) => {
                    return (
                      <div
                        className="referenceAdd-author"
                        key={"author" + index}
                      >
                        <span>
                          <FormItem label="">
                            {getFieldDecorator(`ref_author_last_${item.key}`, {
                              rules: [
                                {
                                  message: "Please input the Last Name!"
                                }
                              ]
                            })(<Input placeholder="Last Name"></Input>)}
                          </FormItem>
                        </span>
                        <span className="lastname-input">
                          <FormItem label="">
                            {getFieldDecorator(`ref_author_first_${item.key}`, {
                              rules: [
                                {
                                  message: "Please input the First Name!"
                                }
                              ]
                            })(<Input placeholder="First Name"></Input>)}
                          </FormItem>
                        </span>
                        {isClick === true ? (
                          <span
                            onClick={e =>
                              this.deleteAuthor(e, "newauthorDatas", index)
                            }
                            className="iconfont icon-delete"
                          ></span>
                        ) : null}
                      </div>
                    );
                  })}

                  {isClick === true || onlySeeRef === false ? (
                    <div className="referenceAdd-author-btn">
                      <Button
                        type="gbdb reference-btn-before"
                        onClick={e => this.addAuthor(e, "newauthorDatas")}
                      >
                        Add Author
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="referenceAdd-publication">
                <Form.Item label="Publication years">
                  {getFieldDecorator("ref_publication_year", {
                    initialValue: detailData.ref_publication_year || ""
                  })(
                    <YearPicker
                      disabled={isClick ? false : true}
                      time={
                        detailData.ref_publication_year || this.state.yearValue
                      }
                      onChange={value => this.publishYear(value)}
                    ></YearPicker>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-type">
                <Form.Item label="Reference type">
                  {getFieldDecorator("ref_publication_type", {
                    rules: [{}],
                    initialValue:
                      detailData.ref_publication_type || "Journal article"
                  })(
                    <Select placeholder="Journal article">
                      <Option value="Journal article">Journal article</Option>
                      <Option value="book">book</Option>
                      <Option value="edit book">edit book</Option>
                      <Option value="book chapter">book chapter</Option>
                      <Option value="Ph .D .dissertation">
                        Ph .D .dissertation
                      </Option>
                      <Option value="master thesis">master thesis</Option>
                      <Option value="paper presented at meeting">
                        paper presented at meeting
                      </Option>
                      <Option value="abstract">abstract</Option>
                      <Option value="technical report">technical report</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-language">
                <Form.Item label="Language">
                  {getFieldDecorator("ref_journal_language", {
                    rules: [
                      {
                        required: true,
                        message: "Please select the Language"
                      }
                    ],

                    initialValue: detailData.ref_journal_language || "English"
                  })(
                    <Select placeholder="English">
                      {languages.map((item, index) => {
                        return (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-book">
                <Form.Item label="Book/Report">
                  {getFieldDecorator("ref_journal_booktitle", {
                    rules: [{}],
                    initialValue: detailData.ref_journal_booktitle || ""
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-journal">
                <Form.Item label="Journal">
                  {getFieldDecorator("ref_journal", {
                    rules: [{}],
                    initialValue: detailData.ref_journal || ""
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-book">
                <Form.Item label="Publisher">
                  {getFieldDecorator("ref_publisher", {
                    rules: [{}],
                    initialValue: detailData.ref_publisher || ""
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-authours referenceAdd-editors">
                <div>
                  {editorsDatas.length > 0 ? (
                    editorsDatas.map((item, index) => {
                      return (
                        <div
                          className="referenceAdd-author reference-writing-name"
                          key={"editor" + index}
                        >
                          {index === 0 ? (
                            <div className="referenceAdd-author-title">
                              Book editors
                            </div>
                          ) : null}
                          <span>{item.value}</span>
                          {isClick === true ? (
                            <span
                              onClick={e =>
                                this.deleteAuthor(e, "editorsDatas", index)
                              }
                              className="iconfont icon-delete reference-writing-del"
                            ></span>
                          ) : null}
                        </div>
                      );
                    })
                  ) : editorsDatas.length === 0 &&
                    this.state.neweditorsDatas.length === 0 ? (
                    <div className="referenceAdd-author ">
                      <div className="referenceAdd-author-title">
                        Book editors
                      </div>

                      <span>none</span>
                    </div>
                  ) : (
                    <div className="referenceAdd-author ">
                      <div className="referenceAdd-author-title">
                        Book editors
                      </div>
                    </div>
                  )}
                  {this.state.neweditorsDatas.map((item, index) => {
                    return (
                      <div
                        className="referenceAdd-author"
                        key={"author" + index}
                      >
                        <span>
                          <FormItem label="">
                            {getFieldDecorator(`ref_editor_last_${item.key}`, {
                              rules: [{}]
                            })(<Input placeholder="Last Name"></Input>)}
                          </FormItem>
                        </span>
                        <span className="lastname-input">
                          <FormItem label="">
                            {getFieldDecorator(`ref_editor_first_${item.key}`, {
                              rules: [{}]
                            })(<Input placeholder="First Name"></Input>)}
                          </FormItem>
                        </span>
                        {/* <span className="pc-first-author">
                          {item.name} Editor
                        </span> */}
                        {isClick === true ? (
                          <span
                            onClick={e =>
                              this.deleteAuthor(e, "neweditorsDatas", index)
                            }
                            className="iconfont icon-delete"
                          ></span>
                        ) : null}
                      </div>
                    );
                  })}
                  {isClick === true || onlySeeRef === false ? (
                    <div className="referenceAdd-author-btn">
                      <Button
                        type="gbdb reference-btn-before"
                        onClick={e => this.addAuthor(e, "neweditorsDatas")}
                      >
                        Add Editors
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="referenceAdd-city">
                <Form.Item label="City">
                  {getFieldDecorator("ref_city", {
                    rules: [{}],
                    initialValue: detailData.ref_city || ""
                  })(
                    // <Select placeholder="City">
                    //   <Option value="type1">type1</Option>
                    //   <Option value="type2">type2</Option>
                    // </Select>
                    <Input placeholder=""></Input>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Volume">
                    {getFieldDecorator("ref_Journal_volume", {
                      rules: [{}],
                      initialValue: detailData.ref_Journal_volume || ""
                    })(<Input />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="No.">
                    {getFieldDecorator("ref_volume_no", {
                      rules: [{}],
                      initialValue: detailData.ref_volume_no || ""
                    })(<Input placeholder=""></Input>)}
                  </Form.Item>
                </div>
              </div>
              <div className="referenceAdd-pageNumber">
                <div>
                  <div className="referenceAdd-author-title">Page number</div>
                  <div className="referenceAdd-author referenceAdd-hasline">
                    <span>
                      <FormItem label="">
                        {getFieldDecorator("ref_page_number_begin", {
                          rules: [
                            {
                              // required: true,
                              message: "Please enter number"
                              // type: 'number'
                            }
                          ],
                          initialValue: detailData.ref_page_number_begin || ""
                        })(<Input placeholder="begin"></Input>)}
                      </FormItem>
                    </span>
                    <span className="referenceAdd-pageNumber-line">
                      <span className="iconfont icon-line1"></span>
                    </span>
                    <span>
                      <FormItem label="">
                        {getFieldDecorator("ref_page_number_end", {
                          rules: [
                            {
                              // required: true,
                              message: "Please enter number"
                              // type: 'number'
                            }
                          ],
                          initialValue: detailData.ref_page_number_end || ""
                        })(<Input placeholder="end"></Input>)}
                      </FormItem>
                    </span>
                  </div>
                </div>
              </div>

              <div className="referenceAdd-doi">
                <Form.Item label="DOI">
                  {getFieldDecorator("ref_doi", {
                    rules: [{}],
                    initialValue: detailData.ref_doi || ""
                  })(<Input></Input>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-comments">
                <Form.Item label="Comments" className="long-label">
                  {getFieldDecorator("ref_comments", {
                    rules: [{}],
                    initialValue: detailData.ref_comments || ""
                  })(<TextArea rows={6}></TextArea>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-upload">
                {isClick ? (
                  <Form.Item
                    label="Upload Files"
                    extra=""
                    className="long-label"
                  >
                    {getFieldDecorator("ref_file_path", {
                      // initialValue: this.state.allFiles
                    })(
                      <div>
                        <BaseUpload
                          // defaultImg={}
                          showImg={false}
                          listType="text"
                          showUploadList={true}
                          onChangeFile={file => this.onChangeFile(file)}
                          removeFile={(file, index) =>
                            this.removeFile(file, index)
                          }
                          multiple={true}
                          uploadFiles={this.state.uploadFiles}
                          fileList={this.state.allFiles}
                        ></BaseUpload>
                      </div>
                    )}
                  </Form.Item>
                ) : defaultFileLists.length > 0 ? (
                  <Form.Item
                    label="Upload Files"
                    extra=""
                    className="long-label hasupload-file"
                  >
                    {Object.values(this.state.allFiles).map((item, index) => {
                      return (
                        <div
                          className="detail-content-item"
                          key={item.name}
                          title="click to download"
                        >
                          <a href={item.url} download={item.name}>
                            {item.name}
                          </a>
                        </div>
                      );
                    })}
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Upload Files"
                    extra=""
                    className="long-label noupload-file"
                  >
                    <span className="detail-content-item">no file</span>
                  </Form.Item>
                )}
              </div>

              <div className="referenceAdd-Accessibility">
                <Form.Item label="Accessibility">
                  {getFieldDecorator("accessibility", {
                    rules: [
                      {
                        required: true,
                        message: "Please select the Accessibility"
                      }
                    ],
                    initialValue:
                      detailData && detailData.accessibility !== undefined
                        ? detailData.accessibility.toString()
                        : "1"
                  })(
                    <Select>
                      <Option value="0">Open</Option>
                      <Option value="1">Private</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-publication">
                {isClick ? (
                  <Form.Item label="Release date">
                    {getFieldDecorator("release_date", {
                      rules: [
                        {
                          required: true,
                          message: "Please select the Release date"
                        }
                      ],

                      initialValue: detailData.release_date
                        ? moment(detailData.release_date)
                        : moment(Date.now())
                    })(
                      <DatePicker
                      allowClear={false}
                        format={"YYYY-MM-DD"}
                        disabledDate={Utils.disabledDate}
                      ></DatePicker>
                    )}
                  </Form.Item>
                ) : (
                  <Form.Item label="Release date">
                    {getFieldDecorator("release_date", {
                      rules: [
                        {
                          required: true,
                          message: "Please select the Release date"
                        }
                      ],
                      initialValue:
                        detailData && detailData.release_date !== ""
                          ? moment(detailData.release_date).format("YYYY-MM-DD")
                          : null
                    })(
                      <span className="detail-content-item">
                        {detailData && detailData.release_date !== ""
                          ? moment(detailData.release_date).format("YYYY-MM-DD")
                          : null}
                      </span>
                    )}
                  </Form.Item>
                )}
              </div>
            </Form>
          </div>
        </div>

        <div className="referenceSearch-btns sectionBase-btns">
          <div className="formation-complete-btns">
            {this.props.seeSection ? (
              <Button type="gbdb" onClick={() => this.viewSection()} title="Inside sections">
                <p>
                Inside
                </p>
                <p> sections</p>
              </Button>
            ) : null}
            {this.props.seeOccurrences ? (
              <Button type="gbdb" onClick={() => this.viewOccurrences()}title="Inside occurrences">
                <p >Inside </p>
                <p>occurrences</p>
              </Button>
            ) : null}

            {isClick && onlySeeRef === false ? (
              <Button type="gbdb" onClick={this.modifyReference}>
                <p title="Save modify">Save modify</p>
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
ReferenceDetailForm = Form.create({})(ReferenceDetailForm);
export default ReferenceDetailForm;
