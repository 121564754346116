import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";
import FormationDetailForm from "commons/AllDetails/Section/Formation/index.js";
import UnitDetailForm from "commons/AllDetails/Section/Unit/index.js";
import CollectionDetailForm from "commons/AllDetails/Collection/index.js";
import { Button, Spin, Modal, Input, Form, message, Collapse, Icon } from "antd";
import Utils from "src/js/utils";
import Users from "src/js/users";
import Apis from "src/js/apis/api";
import SubsetApis from "src/js/apis/subset";
import axios from "axios";
import userApis from "src/js/apis/user";
import "./index.css";
import "src/js/global.js";
import Storage from "src/js/storage";
import TaxonViewPort from "commons/AllTaxonViewport/index.js";
import TaxonomyTableResult from "commons/AllTableList/Taxonomy/index.js";
import TaxonomyDetailForm from "commons/AllDetails/Taxonomy/index.js";
import InfiniteScroll from "react-infinite-scroller";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
const { Panel } = Collapse;
const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: "hidden",
};
let formationHasShow = (formationThis, sectionItem) => {
  let that = formationThis;
  let { formationDataObj } = that.state;
  let formationdatas = formationDataObj[sectionItem.section_basic_id].children;
  return (
    <div className="">
      <Collapse
        defaultActiveKey={formationDataObj[sectionItem.section_basic_id].defaultActiveKey}
        bordered={false}
        expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
      >
        {formationdatas.map((fitem, findex) => {
          let gdegreeLat = 0;
          let gminuteLat = 0;
          let gsecondLat = 0;
          let gdegreeLng = 0;
          let gminuteLng = 0;
          let gsecondLng = 0;

          if (Utils.isNumber(fitem.geo_latitude_decimal)) {
            let a = fitem.geo_latitude_decimal;
            gdegreeLat = parseInt(a);
            gminuteLat = parseInt((a - gdegreeLat) * 60);
            gsecondLat = parseFloat((a - gdegreeLat) * 3600 - gminuteLat * 60).toFixed(0);
          }
          if (Utils.isNumber(fitem.geo_longitude_decimal)) {
            let a = fitem.geo_longitude_decimal;
            gdegreeLng = parseInt(a);
            gminuteLng = parseInt((a - gdegreeLng) * 60);
            gsecondLng = parseFloat((a - gdegreeLng) * 3600 - gminuteLng * 60).toFixed(0);
          }

          return (
            <Panel
              className="results-formation-part"
              key={fitem.formation_id + "formation"}
              style={customPanelStyle}
              header={
                <div>
                  <span className="search-author">
                    <span className="search-item-property search-all-property search-formation-property">{formationdatas.length-findex}. Formation ID:</span>
                  </span>
                  <span>{fitem.formation_id}</span>
                  <span className="">{fitem.formation_name ? `${fitem.formation_name}:` : ""}</span>
                  <span className="search-formation-thicksign">{fitem.formation_thick_sign}</span>
                  <span className="search-formation-thick">{fitem.formation_thick}</span>
                  <span className="search-formation-thickunit">{fitem.formation_thick_unit}</span>
                  {/* <span className="search-formation-thickunit">{Utils.isNumber(fitem.geo_latitude_decimal) ? `${gdegreeLat}°${Math.abs(gminuteLat)}'${Math.abs(gsecondLat)}"` : null}</span>
                  <span className="search-formation-thickunit">{Utils.isNumber(fitem.geo_longitude_decimal) ? `${gdegreeLng}°${Math.abs(gminuteLng)}'${Math.abs(gsecondLng)}"` : null}</span> */}
                  <span title="view details" className="iconfont icon-xiangqing" onClick={(e) => that.showFormationDetail(fitem, e)}></span>
                  <span
                    title={that.state.isInputer === true ? "add new formation" : "sorry!!You do not have permission to add data"}
                    className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                    data-sectionid={fitem.section_basic_id}
                    data-geologyid={fitem.geology_id}
                    onClick={(e) => that.addNewFormation(sectionItem, e)}
                  ></span>
                </div>
              }
            >
              {fitem && fitem.children && Array.isArray(fitem.children) && fitem.children.length > 0 ? (
                <div className="search-units">
                  <Collapse
                    defaultActiveKey={fitem.defaultActiveKey}
                    bordered={false}
                    onChange={that.unitCallbak}
                    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                  >
                    {fitem.children.map((unitItem, index) => {
                      return (
                        <Panel
                          key={unitItem.unit_id + "unit"}
                          style={customPanelStyle}
                          header={
                            <div>
                              <span className="search-author">
                                <span className="search-item-property search-all-property">Unit ID:</span>
                                {unitItem.unit_id}
                              </span>

                              <span>{unitItem.unit_color1a}</span>
                              <span>{unitItem.unit_lithology1a}</span>

                              <span className="search-formation-thicksign">{unitItem.unit_thickness_sign}</span>
                              <span className="search-formation-thick">{unitItem.unit_thickness}</span>
                              <span className="search-formation-thickunit">{unitItem.unit_thickness_unit}</span>
                              <span title="view details" className="iconfont icon-xiangqing" onClick={(e) => that.showUnitDetail(unitItem, e)}></span>
                              <span
                                title={that.state.isInputer === true ? "add new unit" : "sorry!!You do not have permission to add data"}
                                className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                data-formationid={fitem.formation_id}
                                data-sectionid={fitem.section_basic_id}
                                data-geologyid={fitem.geology_id}
                                onClick={(e) => that.addNewUnit(unitItem, e)}
                              ></span>
                            </div>
                          }
                        >
                          {unitItem && unitItem.children && Array.isArray(unitItem.children) && unitItem.children.length > 0 ? (
                            <div className="search-units">
                              <Collapse
                                defaultActiveKey={unitItem.defaultActiveKey}
                                bordered={false}
                                onChange={that.CollectionCallback}
                                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                              >
                                {unitItem.children.map((cItem, index) => {
                                  return (
                                    <Panel
                                      // key="coll"
                                      key={cItem.coll_id + "coll"}
                                      // key={
                                      //   cItem.coll_id
                                      // }
                                      style={customPanelStyle}
                                      header={
                                        <div>
                                          <span className="search-author">
                                            <span className="search-item-property search-all-property">Collection ID:</span>
                                            <span>{cItem.coll_id}</span>
                                            <span>{cItem.coll_no}</span>
                                          </span>

                                          <span title="view details" className="iconfont icon-xiangqing" onClick={(e) => that.showCollectionDetail(cItem, e)}></span>
                                          <span
                                            title={that.state.isInputer === true ? "add new collection" : "sorry!!You do not have permission to add data"}
                                            className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                            data-unitid={unitItem.unit_id}
                                            onClick={(e) => that.addNewCollection(cItem, e)}
                                          ></span>
                                          {Storage.getItem("gbdbid") ? (
                                            <span
                                              title={that.state.isInputer === true ? "add to subset" : "sorry!!You do not have permission to collect"}
                                              className={`iconfont icon-shoucang ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                              onClick={(e) => that.addcollectionSubset(cItem, e)}
                                            ></span>
                                          ) : null}
                                        </div>
                                      }
                                    >
                                      {cItem && cItem.children && Array.isArray(cItem.children) && cItem.children.length > 0 ? (
                                        <div className="">
                                          <Collapse
                                            defaultActiveKey={cItem.defaultActiveKey}
                                            bordered={false}
                                            onChange={that.FossilCallback}
                                            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                                          >
                                            {cItem.children.map((fossilItem, index) => {
                                              let taxonName1 = fossilItem.taxon_name1;
                                              let taxonName2 = fossilItem.taxon_name2;
                                              let genusPrefix = fossilItem.genus_prefix;
                                              let spPrefix1 = fossilItem.sp_prefix1;
                                              let spPrefix2 = fossilItem.sp_prefix2;
                                              let spNameDict = fossilItem.sp_name_dict;
                                              let spSuffix = fossilItem.sp_suffix;
                                              let Name = Utils.fullName(taxonName1, taxonName2, genusPrefix, spPrefix1, spPrefix2, spNameDict, spSuffix);

                                              return (
                                                <Panel
                                                  // key="fossil"
                                                  key={fossilItem.id + "fossil"}
                                                  // key={
                                                  //   fossilItem.id
                                                  // }
                                                  style={customPanelStyle}
                                                  header={
                                                    <div>
                                                      <span className="search-author">
                                                        <span className="search-item-property search-all-property">Fossil:</span>
                                                        <span>{fossilItem.id}</span>
                                                        {/* <span onClick={(e) => that.showFossilRef(fossilItem.ref_id, e)}>{fossilItem.ref_id ? `(ref:${fossilItem.ref_id})` : ""}</span> */}
                                                        <span className="search-fossils-title">{fossilItem.informal_name ? fossilItem.informal_name : Name}</span>
                                                        {/* <span className="search-fossils-title last-show-comment">{fossilItem.fossil_group ? `[${fossilItem.fossil_group}]` : ""}</span> */}
                                                        <span className="search-fossils-title last-show-comment" title={fossilItem.comment}>
                                                          {fossilItem.comment ? `(${fossilItem.comment})` : ""}
                                                        </span>
                                                      </span>
                                                      <span
                                                        title={that.state.isInputer === true ? "add new fossil" : "sorry!!You do not have permission to add data"}
                                                        data-collid={cItem.coll_id}
                                                        data-unitid={cItem.unit_id}
                                                        className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                                        onClick={(e) => that.addNewFossil(e)}
                                                      ></span>
                                                      <span
                                                        title={that.state.isInputer === true ? "add new fossilOption" : "sorry!!You do not have permission to add data"}
                                                        className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                                        onClick={(e) => that.showOptionModal(fossilItem, e)}
                                                      ></span>
                                                      <span title="view taxonomy" className="iconfont icon-xiangqing" onClick={(e) => that.showTaxonomyDetail(fossilItem.taxonmixed_id, e)}></span>
                                                      <span
                                                        title={`view taxonomy parent ${fossilItem.parentCount === 0 ? "(no data)" : ""}`}
                                                        className={`iconfont icon-parent-fill ${fossilItem.parentCount === 0 ? "parent-noresource" : ""}`}
                                                        onClick={(e) => that.showTaxparentDetail(fossilItem.taxonmixed_id, fossilItem.parentCount, e)}
                                                      ></span>
                                                      <span
                                                        title={`view taxonomy siblings ${fossilItem.parentCount === 0 ? "(no data)" : ""}`}
                                                        className={`iconfont icon-list-alt ${fossilItem.parentCount === 0 ? "parent-noresource" : ""}`}
                                                        onClick={(e) => that.showTaxonomySiblings(fossilItem.taxonmixed_id, fossilItem.parentCount, e)}
                                                      ></span>
                                                    </div>
                                                  }
                                                >
                                                  {fossilItem && fossilItem.children && Array.isArray(fossilItem.children) && fossilItem.children.length > 0 ? (
                                                    <div className="fossilItem-search-results">
                                                      {fossilItem.children.map((fossilOptionItem, index) => {
                                                        return (
                                                          <Panel
                                                            key={fossilOptionItem.id + "fossilOption"}
                                                            style={customPanelStyle}
                                                            header={
                                                              <div>
                                                                <span className="search-author">
                                                                  <span className="search-item-property search-all-property search-item-option">Opinion{`${index + 1}:`}</span>
                                                                  <span className="option-refid">{fossilOptionItem.id ? fossilOptionItem.id : ""}</span>
                                                                  {/* <span className="option-refid" onClick={(e) => that.showFossilRef(fossilOptionItem.ref_id, e)}>
                                                                    {fossilOptionItem.ref_id ? `(ref:${fossilOptionItem.ref_id})` : ""}
                                                                  </span> */}

                                                                  <span className="search-fossils-title last-show-comment">
                                                                    {fossilOptionItem.fullfossil_name !== null ? fossilOptionItem.fullfossil_name : ""}
                                                                    {fossilOptionItem.informal_name !== null ? `${fossilOptionItem.informal_name}(informal name)` : ""}
                                                                  </span>
                                                                  <span className="search-fossils-title  last-show-comment" title={fossilOptionItem.comment}>
                                                                    {fossilOptionItem.comment !== null && fossilOptionItem.comment !== "" ? `(${fossilOptionItem.comment})` : ""}
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            }
                                                          ></Panel>
                                                        );
                                                      })}

                                                      {/* </Collapse> */}
                                                    </div>
                                                  ) : fossilItem && fossilItem.children && Array.isArray(fossilItem.children) && fossilItem.children.length == 0 ? (
                                                    <div className="search-no-resource">
                                                      <span className="search-item-property search-all-property">Opinion:null</span>
                                                    </div>
                                                  ) : null}
                                                </Panel>
                                              );
                                            })}
                                          </Collapse>
                                        </div>
                                      ) : cItem && cItem.children && Array.isArray(cItem.children) && cItem.children.length == 0 ? (
                                        <div className="search-no-resource">
                                          <span className="search-item-property search-all-property">Fossil:null</span>
                                          <span
                                            title={that.state.isInputer === true ? "add new fossil" : "sorry!!You do not have permission to add data"}
                                            data-collid={cItem.coll_id}
                                            data-unitid={cItem.unit_id}
                                            className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                            onClick={(e) => that.addNewFossil(e)}
                                          ></span>
                                        </div>
                                      ) : null}
                                    </Panel>
                                  );
                                })}
                              </Collapse>
                            </div>
                          ) : unitItem && unitItem.children && Array.isArray(unitItem.children) && unitItem.children.length == 0 ? (
                            <div className="search-no-resource">
                              <span className="search-item-property search-all-property">Collection:null</span>
                              <span
                                title={that.state.isInputer === true ? "add new collection" : "sorry!!You do not have permission to add data"}
                                data-unitid={unitItem.unit_id}
                                className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                onClick={(e) => that.addNewCollection(unitItem.unit_id, e)}
                              ></span>
                            </div>
                          ) : null}
                        </Panel>
                      );
                    })}
                  </Collapse>
                </div>
              ) : fitem && fitem.children && Array.isArray(fitem.children) && fitem.children.length == 0 ? (
                <div className="search-no-resource">
                  <span className="search-item-property search-all-property">Unit:null</span>
                  <span
                    title={that.state.isInputer === true ? "add new unit" : "sorry!!You do not have permission to add data"}
                    data-formationid={fitem.formation_id}
                    data-sectionid={fitem.section_basic_id}
                    data-geologyid={fitem.geology_id}
                    className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                    onClick={(e) => that.addNewUnit(fitem.formation_id, e)}
                  ></span>
                </div>
              ) : null}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};
let sectionUnitHasShow = (unitThis, sectionItem) => {
  let that = unitThis;
  let { showUnitObj } = that.state;

  let unitdatas = showUnitObj[sectionItem.section_basic_id].children;
  return (
    <div className="">
      {unitdatas && Array.isArray(unitdatas) && unitdatas.length > 0 ? (
        <div className="search-units">
          <Collapse
            defaultActiveKey={showUnitObj[sectionItem.section_basic_id].defaultActiveKey}
            bordered={false}
            onChange={that.unitCallbak}
            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
          >
            {unitdatas.map((unitItem, index) => {
              return (
                <Panel
                  key={unitItem.unit_id + "unit"}
                  style={customPanelStyle}
                  header={
                    <div>
                      <span className="search-author">
                        <span className="search-item-property search-all-property">Unit ID:</span>
                        {unitItem.unit_id}
                      </span>

                      <span>{unitItem.unit_color1a}</span>
                      <span>{unitItem.unit_lithology1a}</span>

                      <span className="search-formation-thicksign">{unitItem.unit_thickness_sign}</span>
                      <span className="search-formation-thick">{unitItem.unit_thickness}</span>
                      <span className="search-formation-thickunit">{unitItem.unit_thickness_unit}</span>
                      <span title="view details" className="iconfont icon-xiangqing" onClick={(e) => that.showUnitDetail(unitItem, e)}></span>
                      <span
                        title={that.state.isInputer === true ? "add new unit" : "sorry!!You do not have permission to add data"}
                        className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                        data-formationid={sectionItem.formation_id}
                        data-sectionid={sectionItem.section_basic_id}
                        data-geologyid={sectionItem.geology_id}
                        onClick={(e) => that.addNewUnit(unitItem, e)}
                      ></span>
                    </div>
                  }
                >
                  {unitItem && unitItem.children && Array.isArray(unitItem.children) && unitItem.children.length > 0 ? (
                    <div className="search-units">
                      <Collapse
                        defaultActiveKey={unitItem.defaultActiveKey}
                        bordered={false}
                        onChange={that.CollectionCallback}
                        expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                      >
                        {unitItem.children.map((cItem, index) => {
                          return (
                            <Panel
                              // key="coll"
                              key={cItem.coll_id + "coll"}
                              // key={
                              //   cItem.coll_id
                              // }
                              style={customPanelStyle}
                              header={
                                <div>
                                  <span className="search-author">
                                    <span className="search-item-property search-all-property">Collection ID:</span>
                                    <span>{cItem.coll_id}</span>
                                    <span>{cItem.coll_no}</span>
                                  </span>

                                  <span title="view details" className="iconfont icon-xiangqing" onClick={(e) => that.showCollectionDetail(cItem, e)}></span>
                                  <span
                                    title={that.state.isInputer === true ? "add new collection" : "sorry!!You do not have permission to add data"}
                                    className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                    data-unitid={unitItem.unit_id}
                                    onClick={(e) => that.addNewCollection(cItem, e)}
                                  ></span>
                                  {Storage.getItem("gbdbid") ? (
                                    <span
                                      title={that.state.isInputer === true ? "add to subset" : "sorry!!You do not have permission to collect"}
                                      className={`iconfont icon-shoucang ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                      onClick={(e) => that.addcollectionSubset(cItem, e)}
                                    ></span>
                                  ) : null}
                                </div>
                              }
                            >
                              {cItem && cItem.children && Array.isArray(cItem.children) && cItem.children.length > 0 ? (
                                <div className="">
                                  <Collapse
                                    defaultActiveKey={cItem.defaultActiveKey}
                                    bordered={false}
                                    onChange={that.FossilCallback}
                                    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                                  >
                                    {cItem.children.map((fossilItem, index) => {
                                      let taxonName1 = fossilItem.taxon_name1;
                                      let taxonName2 = fossilItem.taxon_name2;
                                      let genusPrefix = fossilItem.genus_prefix;
                                      let spPrefix1 = fossilItem.sp_prefix1;
                                      let spPrefix2 = fossilItem.sp_prefix2;
                                      let spNameDict = fossilItem.sp_name_dict;
                                      let spSuffix = fossilItem.sp_suffix;
                                      let Name = Utils.fullName(taxonName1, taxonName2, genusPrefix, spPrefix1, spPrefix2, spNameDict, spSuffix);

                                      return (
                                        <Panel
                                          // key="fossil"
                                          key={fossilItem.id + "fossil"}
                                          // key={
                                          //   fossilItem.id
                                          // }
                                          style={customPanelStyle}
                                          header={
                                            <div>
                                              <span className="search-author">
                                                <span className="search-item-property search-all-property">Fossil:</span>
                                                <span>{fossilItem.id}</span>
                                                <span onClick={(e) => that.showFossilRef(fossilItem.ref_id, e)}>{fossilItem.ref_id ? `(ref:${fossilItem.ref_id})` : ""}</span>
                                                <span className="search-fossils-title">{fossilItem.informal_name ? fossilItem.informal_name : Name}</span>
                                                <span className="search-fossils-title last-show-comment">{fossilItem.fossil_group ? `[${fossilItem.fossil_group}]` : ""}</span>
                                                <span className="search-fossils-title last-show-comment" title={fossilItem.comment}>
                                                  {fossilItem.comment ? `(${fossilItem.comment})` : ""}
                                                </span>
                                              </span>
                                              <span
                                                title={that.state.isInputer === true ? "add new fossil" : "sorry!!You do not have permission to add data"}
                                                data-collid={cItem.coll_id}
                                                data-unitid={cItem.unit_id}
                                                className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                                onClick={(e) => that.addNewFossil(e)}
                                              ></span>
                                              <span
                                                title={that.state.isInputer === true ? "add new fossilOption" : "sorry!!You do not have permission to add data"}
                                                className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                                onClick={(e) => that.showOptionModal(fossilItem, e)}
                                              ></span>
                                              <span title="view taxonomy" className="iconfont icon-xiangqing" onClick={(e) => that.showTaxonomyDetail(fossilItem.taxonmixed_id, e)}></span>
                                              <span
                                                title={`view taxonomy parent ${fossilItem.parentCount === 0 ? "(no data)" : ""}`}
                                                className={`iconfont icon-parent-fill ${fossilItem.parentCount === 0 ? "parent-noresource" : ""}`}
                                                onClick={(e) => that.showTaxparentDetail(fossilItem.taxonmixed_id, fossilItem.parentCount, e)}
                                              ></span>
                                              <span
                                                title={`view taxonomy siblings ${fossilItem.parentCount === 0 ? "(no data)" : ""}`}
                                                className={`iconfont icon-list-alt ${fossilItem.parentCount === 0 ? "parent-noresource" : ""}`}
                                                onClick={(e) => that.showTaxonomySiblings(fossilItem.taxonmixed_id, fossilItem.parentCount, e)}
                                              ></span>
                                            </div>
                                          }
                                        >
                                          {fossilItem && fossilItem.children && Array.isArray(fossilItem.children) && fossilItem.children.length > 0 ? (
                                            <div className="fossilItem-search-results">
                                              {fossilItem.children.map((fossilOptionItem, index) => {
                                                return (
                                                  <Panel
                                                    key={fossilOptionItem.id + "fossilOption"}
                                                    style={customPanelStyle}
                                                    header={
                                                      <div>
                                                        <span className="search-author">
                                                          <span className="search-item-property search-all-property search-item-option">Opinion{`${index + 1}:`}</span>
                                                          <span className="option-refid">{fossilOptionItem.id ? fossilOptionItem.id : ""}</span>
                                                          <span className="option-refid" onClick={(e) => that.showFossilRef(fossilOptionItem.ref_id, e)}>
                                                            {fossilOptionItem.ref_id ? `(ref:${fossilOptionItem.ref_id})` : ""}
                                                          </span>

                                                          <span className="search-fossils-title last-show-comment">
                                                            {fossilOptionItem.fullfossil_name !== null ? fossilOptionItem.fullfossil_name : ""}
                                                            {fossilOptionItem.informal_name !== null ? `${fossilOptionItem.informal_name}(informal name)` : ""}
                                                          </span>
                                                          <span className="search-fossils-title  last-show-comment" title={fossilOptionItem.comment}>
                                                            {fossilOptionItem.comment !== null && fossilOptionItem.comment !== "" ? `(${fossilOptionItem.comment})` : ""}
                                                          </span>
                                                        </span>
                                                      </div>
                                                    }
                                                  ></Panel>
                                                );
                                              })}

                                              {/* </Collapse> */}
                                            </div>
                                          ) : fossilItem && fossilItem.children && Array.isArray(fossilItem.children) && fossilItem.children.length == 0 ? (
                                            <div className="search-no-resource">
                                              <span className="search-item-property search-all-property">Opinion:null</span>
                                            </div>
                                          ) : null}
                                        </Panel>
                                      );
                                    })}
                                  </Collapse>
                                </div>
                              ) : cItem && cItem.children && Array.isArray(cItem.children) && cItem.children.length == 0 ? (
                                <div className="search-no-resource">
                                  <span className="search-item-property search-all-property">Fossil:null</span>
                                  <span
                                    title={that.state.isInputer === true ? "add new fossil" : "sorry!!You do not have permission to add data"}
                                    data-collid={cItem.coll_id}
                                    data-unitid={cItem.unit_id}
                                    className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                    onClick={(e) => that.addNewFossil(e)}
                                  ></span>
                                </div>
                              ) : null}
                            </Panel>
                          );
                        })}
                      </Collapse>
                    </div>
                  ) : unitItem && unitItem.children && Array.isArray(unitItem.children) && unitItem.children.length == 0 ? (
                    <div className="search-no-resource">
                      <span className="search-item-property search-all-property">Collection:null</span>
                      <span
                        title={that.state.isInputer === true ? "add new collection" : "sorry!!You do not have permission to add data"}
                        data-unitid={unitItem.unit_id}
                        className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                        onClick={(e) => that.addNewCollection(unitItem.unit_id, e)}
                      ></span>
                    </div>
                  ) : null}
                </Panel>
              );
            })}
          </Collapse>
        </div>
      ) : sectionItem && sectionItem.children && Array.isArray(sectionItem.children) && sectionItem.children.length == 0 ? (
        <div className="search-no-resource">
          <span className="search-item-property search-all-property">Unit:null</span>
          <span
            title={that.state.isInputer === true ? "add new unit" : "sorry!!You do not have permission to add data"}
            data-formationid={sectionItem.formation_id}
            data-sectionid={sectionItem.section_basic_id}
            data-geologyid={sectionItem.geology_id}
            className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
            onClick={(e) => that.addNewUnit(sectionItem.formation_id, e)}
          ></span>
        </div>
      ) : null}
    </div>
  );
};

class AllResultList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSize: 20,
      // ref
      referencePage: 1,
      referenceTotal: 0,
      referenceData: [],
      currentReferenceId: "",
      referenceLoading: false,
      referenceHasMore: true,
      referenceActive: false,
      //  SECTION
      sectionPage: 1,
      sectionData: [],
      showSection: false,
      sectionLoading: false,
      sectionHasMore: true,
      sectionTotal: 0,
      currentSectionId: "",

      sectionType: "baseinfo",

      formationDataObj: {},
      formationLoading: false,
      // 添加观点
      showSearchTaxon: false,
      showBackIcon: false,

      // taxonomy关系
      showTaxonResult: false,
      openSectionids: [],
      // allOtherParams查询的其他条件
      allOtherParams: {},
      showUnitObj: {},
      openChildTypes: {},
      showUnitIcon: true,
    };
  }

  showReferenceData = async (searchParams, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState(
      {
        currentReferenceId: Number(searchParams.ref_id),
      },
      async () => {
        let { pageSize, referencePage } = this.state;

        let allParams = {};
        let pageParams = {
          pageSize,
          page: referencePage,
        };
        let sortParams = {};

        Object.assign(allParams, pageParams, sortParams, searchParams);
        await this.getreferenceData(allParams, async (res) => {
          this.setState(
            {
              referenceData: res.result,
              referenceTotal: res.total,
              referenceLoading: false,
            },
            () => {
              let { referenceData, referenceTotal } = this.state;

              if (referenceData.length < referenceTotal) {
                this.setState({
                  referenceHasMore: true,
                });
              }
            }
          );
        });
      }
    );
  };

  handleInfiniteOnLoad = () => {
    let { referenceData } = this.state;
    this.setState({
      referenceLoading: true,
    });
    let { referenceTotal } = this.state;

    if (referenceData.length >= referenceTotal) {
      // message.warning("Reference data loaded all");
      this.setState({
        referenceHasMore: false,
        referenceLoading: false,
      });
      return;
    }
    let { pageSize, referencePage, queryParams } = this.state;
    this.setState(
      {
        referencePage: referencePage + 1,
      },
      () => {
        let searchParams = {
          pageSize,
          page: this.state.referencePage,

          ...queryParams,
        };
        this.getreferenceData(searchParams, (res) => {
          referenceData = referenceData.concat(res.result);
          this.setState({
            referenceData,
            referenceLoading: false,
          });
        });
      }
    );
  };

  getreferenceData = async (condition, callback) => {
    this.setState(
      {
        referenceLoading: true,
      },
      async () => {
        let searchResult = await Apis.searchReference(condition);

        if (searchResult.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: searchResult.error,
          });
          setTimeout(() => {
            modal.destroy();
          }, 5000);
        } else {
          callback(searchResult);
        }
      }
    );
  };
  referenceReset = () => {
    this.setState({
      referenceLoading: false,
      referenceHasMore: true,

      //  SECTION
      sectionPage: 1,
      sectionData: [],
      showSection: false,
      sectionLoading: false,
      sectionHasMore: true,
      sectionTotal: 0,
      currentSectionId: "",

      sectionType: "baseinfo",

      formationLoading: false,
      // 添加观点
      showSearchTaxon: false,

      // taxonomy关系
      showTaxonResult: false,
      openSectionids: [],
      // allOtherParams查询的其他条件
      showUnitObj: {},
      formationDataObj: {},
      openChildTypes: {},
    });
  };
  sectionReset = () => {
    this.setState({
      //  SECTION
      sectionLoading: false,
      sectionHasMore: true,
      sectionType: "baseinfo",
      showSearchTaxon: false,
      showTaxonResult: false,
      formationLoading: false,
    });
  };
  referenceCallback = (key, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    let allParams = {};

    let item = {
      ref_id: key,
    };
    let { sectionSerachConditions } = this.state;
    Object.assign(allParams, item, sectionSerachConditions);

    let { referenceActive, currentReferenceId } = this.state;
    if (currentReferenceId === key) {
      referenceActive = !referenceActive;
    } else {
      referenceActive = true;
    }
    this.referenceReset();
    this.setState(
      {
        referenceActive,
        currentReferenceId: Number(key),
      },
      () => {
        let { referenceActive } = this.state;
        if (referenceActive === true) {
          this.setState(
            {
              showSection: referenceActive,
            },
            () => {
              // if(this.state.currentReferenceId!==key){
              this.showSectionData(allParams);

              // }
            }
          );
        } else {
          this.setState({
            showSection: referenceActive,
          });
        }
      }
    );
  };
  getsectionData = async (condition, callback) => {
    this.setState(
      {
        sectionLoading: true,
      },
      async () => {
        let allParams = {};
        let sortParams = {
          sort: { name: "createdAt", type: 1 },
        };
        Object.assign(allParams, sortParams, condition);
        let searchResult = await Apis.searchSection(allParams);

        if (searchResult.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: searchResult.error,
          });
          setTimeout(() => {
            modal.destroy();
          }, 5000);
        } else {
          callback(searchResult);
        }
      }
    );
  };
  showSectionData = async (sectionItem, type) => {
    this.setState(
      {
        sectionData: [],
      },
      async () => {
        let { currentReferenceId, pageSize, sectionPage } = this.state;
        if (currentReferenceId) {
          let allParams = {};
          let pageParams = {
            pageSize,
            page: sectionPage,
          };
          let searchParams = sectionItem;
          let sortParams = {
            sort: { name: "createdAt", type: 1 },
          };
          Object.assign(allParams, pageParams, sortParams, searchParams);
          this.getsectionData(allParams, async (res) => {
            this.setState(
              {
                sectionData: res.result,
                sectionLoading: false,
                sectionTotal: res.total,
              },
              () => {
                let { sectionData, sectionTotal } = this.state;

                if (sectionData.length < sectionTotal) {
                  this.setState({
                    sectionHasMore: true,
                  });
                }
                if (type === "start") {
                  setTimeout(() => {
                    if (sectionData && Array.isArray(sectionData) && sectionData.length > 0) {
                      if (document.getElementById(sectionData[0].section_basic_id + "section")) {
                        document.getElementById(sectionData[0].section_basic_id + "section").click();
                      }
                    }

                    const sectioninner = this.refs.sectioninner;
                    if (sectioninner) {
                      this.onSectionHandle();
                    }
                  }, 20);
                }

                setTimeout(() => {
                  const sectioninner = this.refs.sectioninner;
                  if (sectioninner) {
                    this.onSectionHandle();
                  }
                }, 20);
              }
            );
          });
        }
      }
    );
  };
  isGbdbisInputer = async () => {
    let isInputer = false;

    isInputer = await Users.isGbdbInputer();

    this.setState({
      isInputer,
    });
  };
  onScrollHandle() {
    // 使用滚动时自动加载更多
    const loadMoreFn = this.handleInfiniteOnLoad;
    const wrapper = this.refs.wrapper;

    let timeoutId;
    function callback() {
      //获取到按钮离顶部的距离
      const top = wrapper.getBoundingClientRect().top;

      const windowHeight = window.screen.height;

      if (top && top < windowHeight) {
        // 证明 wrapper 已经被滚动到暴露在页面可视范围之内了
        loadMoreFn();
      }
    }
    window.addEventListener(
      "scroll",
      function () {
        let { referenceLoading, referenceHasMore } = this.state;
        if (referenceLoading || !referenceHasMore) {
          return;
        }
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        //如果在50ms 以内没有执行scroll 就会执行callBack，如果有下一次滚动，定时器就会被清空
        timeoutId = setTimeout(callback, 50);
      }.bind(this),
      false
    );
  }
  onSectionHandle() {
    // 使用滚动时自动加载更多
    const loadMoreFn = this.handleData;

    const sectioninner = this.refs.sectioninner;

    let timeoutId;
    function callback() {
      //获取到按钮离顶部的距离

      const sectiontop = sectioninner.getBoundingClientRect().top;

      const windowHeight = window.screen.height;

      if (sectiontop && sectiontop < windowHeight) {
        // 证明 wrapper 已经被滚动到暴露在页面可视范围之内了
        loadMoreFn("section");
      }
    }
    window.addEventListener(
      "scroll",
      function () {
        let { sectionLoading, sectionHasMore } = this.state;
        if (sectionLoading || !sectionHasMore) {
          return;
        }
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        //如果在50ms 以内没有执行scroll 就会执行callBack，如果有下一次滚动，定时器就会被清空
        timeoutId = setTimeout(callback, 50);
      }.bind(this),
      false
    );
  }
  LoginModify = (params) => {
    if (params === "isInputer") {
      this.setState({
        isInputer: true,
      });
    } else if (params === "Inputerout") {
      this.setState({
        isInputer: false,
      });
    }
  };
  componentDidMount() {
    this.onScrollHandle();

    this.isGbdbisInputer();
    window.LoginModify = this.LoginModify;
    if (this.props.location.query) {
      let { refSerachConditions } = this.state;
      let queryParams = {};
      refSerachConditions = this.props.location.query.searchParams;
      Object.assign(queryParams, refSerachConditions);
      this.setState({
        queryParams,
        refSerachConditions,
        showBackIcon: true,
      });
      this.showReferenceData(queryParams);
      
      if(refSerachConditions){
        
        Storage.setItem({
          name: "referenceRefreshParam",
          value: refSerachConditions
        });
      }
    } else if (Storage.getItem("referenceRefreshParam")) {

      let { refSerachConditions } = this.state;
      let queryParams = {};
      refSerachConditions = Storage.getItem("referenceRefreshParam")
      Object.assign(queryParams, refSerachConditions);
      this.setState({
        queryParams,
        refSerachConditions,
        showBackIcon: true,
      });
      this.showReferenceData(queryParams);
      
      if(refSerachConditions){
        Storage.setItem({
          name: "referenceRefreshParam",
          value: refSerachConditions
        });
      }
    } else if (this.props.location.search) {
      let inheritRefIds = Utils.getSearch(this.props.location.search, "refId") || "";
      let baseId = Utils.getSearch(this.props.location.search, "baseId") || "";
      let formationId = Utils.getSearch(this.props.location.search, "formationId") || "";
      let unitId = Utils.getSearch(this.props.location.search, "unitId") || "";
      let collId = Utils.getSearch(this.props.location.search, "collId") || "";
      let fossilId = Utils.getSearch(this.props.location.search, "fossilId") || "";
      if (inheritRefIds) {
        inheritRefIds = Number(inheritRefIds);

        let queryParams = {};
        this.setState(
          {
            currentReferenceId: inheritRefIds,
            refSerachConditions: { ref_id: inheritRefIds },
          },
          async () => {
            let { refSerachConditions } = this.state;
            Object.assign(queryParams, refSerachConditions);
            await this.showReferenceData(queryParams);
          }
        );
      }
      // refId=159&baseId=4&formationId=16&unitId=68&collId=67&fossilId=22
      if (baseId) {
        let { sectionSerachConditions, openChildTypes } = this.state;
        baseId = Number(baseId);
        this.setState(
          {
            showSection: true,
            currentSectionId: baseId,
          },
          async () => {
            let otherParams = {};
            let { showUnitIcon } = this.state;
            if (formationId !== "") {
              formationId = Number(formationId);
              let formationConditions = { formation_id: formationId };
              Object.assign(otherParams, formationConditions);

              if (unitId !== "") {
                unitId = Number(unitId);
                let unitConditions = { unit_id: unitId };
                Object.assign(otherParams, unitConditions);
              }

              if (collId !== "") {
                collId = Number(collId);
                let collectionConditions = { coll_id: collId };
                Object.assign(otherParams, collectionConditions);
              }
              if (fossilId !== "") {
                fossilId = Number(fossilId);
                let fossilConditions = { id: fossilId };
                Object.assign(otherParams, fossilConditions);
              }
              showUnitIcon = false;
              openChildTypes[baseId] = false;
            }
            if (formationId == "" && unitId !== "") {
              if (unitId !== "") {
                unitId = Number(unitId);
                let unitConditions = { unit_id: unitId };
                Object.assign(otherParams, unitConditions);
              }

              if (collId !== "") {
                collId = Number(collId);
                let collectionConditions = { coll_id: collId };
                Object.assign(otherParams, collectionConditions);
              }
              if (fossilId !== "") {
                fossilId = Number(fossilId);
                let fossilConditions = { id: fossilId };
                Object.assign(otherParams, fossilConditions);
              }
              showUnitIcon = false;
              openChildTypes[baseId] = true;
            }

            let searchParams = {};
            sectionSerachConditions = {
              // ref_id: inheritRefIds,
              section_basic_id: baseId,
            };
            this.setState({
              sectionSerachConditions,
              referenceActive: true,
              allOtherParams: otherParams,
              showUnitIcon,
              openChildTypes,
            });
            Object.assign(searchParams, sectionSerachConditions);
            await this.showSectionData(searchParams, "start");
          }
        );
      }
    }
  }

  getformationData = async (condition, callback) => {
    let searchResult = await Apis.getformationResults(condition);

    if (searchResult.error) {
      const modal = Modal.error({
        title: "This is an error message",
        content: searchResult.error,
      });
      setTimeout(() => {
        modal.destroy();
      }, 5000);
    } else {
      callback(searchResult);
    }
  };
  getunitData = async (condition, callback) => {
    let searchResult = await Apis.getUnitsResults(condition);

    if (searchResult.error) {
      const modal = Modal.error({
        title: "This is an error message",
        content: searchResult.error,
      });
      setTimeout(() => {
        modal.destroy();
      }, 5000);
    } else {
      callback(searchResult);
    }
  };
  showFormationData = async (sectionItem, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    let { currentSectionId, formationDataObj } = this.state;

    if (currentSectionId) {
      if (formationDataObj.hasOwnProperty(currentSectionId)) {
        return;
      }
      this.setState(
        {
          formationLoading: true,
        },
        () => {
          let allParams = {};
          let pageParams = {};
          let searchParams = sectionItem;
          Object.assign(allParams, pageParams, searchParams);

          this.getformationData(allParams, async (res) => {
            formationDataObj[currentSectionId] = {
              defaultActiveKey: res.defaultActiveKey,
              children: res.result,
            };
            this.setState(
              {
                formationDataObj,

                formationLoading: false,
              },
              () => {}
            );
          });
        }
      );
    }
  };
  showUnitData = (searchParams, e) => {
    let { currentSectionId, showUnitObj } = this.state;

    if (currentSectionId) {
      if (showUnitObj.hasOwnProperty(currentSectionId)) {
        return;
      }
      this.setState(
        {
          formationLoading: true,
        },
        () => {
          let allParams = {};
          let pageParams = {};
          let { allOtherParams } = this.state;

          Object.assign(allParams, pageParams, allOtherParams, searchParams);

          this.getunitData(allParams, async (res) => {
            showUnitObj[currentSectionId] = {
              defaultActiveKey: res.defaultActiveKey,
              children: res.result,
            };
            this.setState({
              showUnitObj,

              formationLoading: false,
            });
          });
        }
      );
    }
  };
  sectionCallback = async (key, event) => {
    let currentKey;
    let { openSectionids, openChildTypes } = this.state;
    let nowopenSectionids = openSectionids;
    let isKey = Array.isArray(key);
    if (isKey) {
      // 说明是展开
      if (key.length > openSectionids.length) {
        currentKey = key[key.length - 1];
        if (currentKey) {
          let formationId = Utils.getSearch(this.props.location.search, "formationId") || "";
          let unitId = Utils.getSearch(this.props.location.search, "unitId") || "";
          if (formationId) {
            openChildTypes[parseInt(currentKey)] = false;
          }
          if (formationId === "" && unitId !== "") {
            openChildTypes[parseInt(currentKey)] = true;
          }
          if (!openChildTypes.hasOwnProperty(parseInt(currentKey))) {
            openChildTypes[parseInt(currentKey)] = false;
          }
          //  else {
          //     openChildTypes[parseInt(currentKey)] = false
          //   }
        }
      }
      nowopenSectionids = key;
    } else {
      currentKey = key + "section";
      // 之前未展开过
      if (!nowopenSectionids.includes(currentKey)) {
        nowopenSectionids.push(currentKey);
      }
    }
    let allParams = {};
    let item = {
      section_basic_id: parseInt(currentKey),
    };
    let { allOtherParams } = this.state;
    Object.assign(allParams, allOtherParams, item);
    this.sectionReset();

    this.setState(
      {
        openSectionids: nowopenSectionids,
        currentSectionId: parseInt(currentKey),
        openChildTypes,
      },
      async () => {
        if (this.state.openSectionids.length <= nowopenSectionids.length) {
          if (this.state.openChildTypes[parseInt(currentKey)]) {
            await this.showUnitData(allParams);
          } else {
            await this.showFormationData(allParams);
          }
        }
      }
    );
  };

  // -----
  sectionSeeChild = (sectionItem, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let { openChildTypes } = this.state;
    let classLists = e.nativeEvent.target.classList;
    let key = sectionItem.section_basic_id;

    if (classLists.contains("icon-unit")) {
      // true查看unit
      openChildTypes[key] = true;
      this.sectionCallback(key);
    } else if (classLists.contains("icon-formation")) {
      openChildTypes[key] = false;
      this.sectionCallback(key);
    }
    this.setState({
      openChildTypes,
    });
  };

  // -----

  showReferenceDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.setState({
      showDetailForm: true,
      referenceDetail: item,
    });
  };
  showFossilRef = async (refid, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let condition = {
      ref_id: refid,
    };
    let searchResult = await Apis.searchReference(condition);
    if (searchResult.result && searchResult.result.length > 0) {
      this.setState({
        showDetailForm: true,
        referenceDetail: searchResult.result[0],
      });
    } else {
      if (searchResult.error) {
        Modal.error({
          content: searchResult.error,
        });
      }
    }
  };
  detailReferenceOk = () => {
    this.setState({
      showDetailForm: false,
    });
  };
  detailReferenceCancel = () => {
    this.setState({
      showDetailForm: false,
    });
  };
  // section
  showSectionDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showSectionForm: true,
      sectionDetail: item,
      sectionType: "baseinfo",
    });
  };
  detailSectionFormOk = () => {
    let { sectionType } = this.state;
    this.setState(
      {
        showSectionForm: false,
      },
      () => {
        if (sectionType == "geology") {
          this.setState({
            sectionType: "baseinfo",
          });
        }
      }
    );
  };
  detailSectionCancel = () => {
    let { sectionType } = this.state;
    this.setState(
      {
        showSectionForm: false,
      },
      () => {
        if (sectionType == "geology") {
          this.setState({
            sectionType: "baseinfo",
          });
        }
      }
    );
  };
  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionForm: true,
      sectionType,
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionForm: true,
      sectionType,
    });
  };

  // formation
  showFormationDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showSectionForm: true,
      sectionDetail: item,
      sectionType: "formation",
    });
  };

  // unit
  showUnitDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showSectionForm: true,
      sectionDetail: item,
      sectionType: "unit",
    });
  };

  // collection
  showCollectionDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showSectionForm: true,
      sectionDetail: item,
      sectionType: "collection",
    });
  };

  addNewReference = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let uid = Storage.getItem("gbdbid");
    if (uid == undefined) {
      Modal.confirm({
        title: "This is a prompt message",
        content: (
          <div>
            <p>
              Only <span className="canlogin-condition">logged-in</span> <span className="canlogin-condition">data compiler</span> can add data,whether to log in or not
            </p>
          </div>
        ),
        onOk() {
          let loginDom = document.querySelector(".user-mainheader-login");

          if (loginDom) {
            loginDom.click();
          }
        },
      });
      return;
    }
    if (this.state.isInputer === false) {
      Modal.info({
        content: "Sorry!!!,you are not an expert or data compiler,you have no permission",
      });
      return;
    }

    let url = "/reference/add";

    let params = `${url}`;

    const w = window.open("about:blank");
    w.location.href = params;
    // this.props.history.push(params);
  };

  addNewSection = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let uid = Storage.getItem("gbdbid");
    if (uid == undefined) {
      Modal.confirm({
        title: "This is a prompt message",
        content: (
          <div>
            <p>
              Only <span className="canlogin-condition">logged-in</span> <span className="canlogin-condition">data compiler</span> can add data,whether to log in or not
            </p>
          </div>
        ),
        onOk() {
          let loginDom = document.querySelector(".user-mainheader-login");

          if (loginDom) {
            loginDom.click();
          }
        },
      });
      return;
    }
    if (this.state.isInputer === false) {
      Modal.info({
        content: "Sorry!!!,you are not an expert or data compiler,you have no permission",
      });
      return;
    }
    let { currentReferenceId } = this.state;

    let url = "/section/baseinfo?";
    let refUrl = currentReferenceId ? "refId=" + currentReferenceId : "";

    let params = `${url}${refUrl}`;

    const w = window.open("about:blank");
    w.location.href = params;
    // this.props.history.push(params);
  };
  addNewFormation = async (sectionItem, e) => {
    let parentValue = e.currentTarget.dataset;
    let sectionid = parentValue.sectionid;
    let geologyid = parentValue.geologyid;

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let uid = Storage.getItem("gbdbid");
    if (uid == undefined) {
      Modal.confirm({
        title: "This is a prompt message",
        content: (
          <div>
            <p>
              Only <span className="canlogin-condition">logged-in</span> <span className="canlogin-condition">data compiler</span> can add data,whether to log in or not
            </p>
          </div>
        ),
        onOk() {
          let loginDom = document.querySelector(".user-mainheader-login");

          if (loginDom) {
            loginDom.click();
          }
        },
      });
      return;
    }
    if (this.state.isInputer === false) {
      Modal.info({
        content: "Sorry!!!,you are not an expert or data compiler,you have no permission",
      });
      return;
    }
    let { currentReferenceId } = this.state;

    let url = "/section/formation?";
    let refUrl = currentReferenceId ? "refId=" + currentReferenceId : "";
    let baseIdUrl = sectionid ? "&baseId=" + sectionid : "";
    let geographyIdUrl = geologyid ? "&geographyId=" + geologyid : "";
    let params = `${url}${refUrl}${baseIdUrl}${geographyIdUrl}`;

    // this.props.history.push(params);
    const w = window.open("about:blank");
    w.location.href = params;
  };
  addNewUnit = async (item, e) => {
    let parentValue = e.currentTarget.dataset;
    let sectionid = parentValue.sectionid;
    let geologyid = parentValue.geologyid;
    let formationid = parentValue.formationid;
    let { currentReferenceId } = this.state;

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let uid = Storage.getItem("gbdbid");
    if (uid == undefined) {
      Modal.confirm({
        title: "This is a prompt message",
        content: (
          <div>
            <p>
              Only <span className="canlogin-condition">logged-in</span> <span className="canlogin-condition">data compiler</span> can add data,whether to log in or not
            </p>
          </div>
        ),
        onOk() {
          let loginDom = document.querySelector(".user-mainheader-login");

          if (loginDom) {
            loginDom.click();
          }
        },
      });
      return;
    }
    if (this.state.isInputer === false) {
      Modal.info({
        content: "Sorry!!!,you are not an expert or data compiler,you have no permission",
      });
      return;
    }

    let url = "/section/unit?";
    let refUrl = currentReferenceId ? "refId=" + currentReferenceId : "";
    let baseIdUrl = sectionid ? "&baseId=" + sectionid : "";
    let geographyIdUrl = geologyid ? "&geographyId=" + geologyid : "";
    let formationIdUrl = formationid ? "&formationId=" + formationid : "";

    let params = `${url}${refUrl}${baseIdUrl}${geographyIdUrl}${formationIdUrl}`;

    // this.props.history.push(params);
    const w = window.open("about:blank");
    w.location.href = params;
  };
  addNewCollection = async (item, e) => {
    let parentValue = e.currentTarget.dataset;
    let unitid = parentValue.unitid;

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let uid = Storage.getItem("gbdbid");
    if (uid == undefined) {
      Modal.confirm({
        title: "This is a prompt message",
        content: (
          <div>
            <p>
              Only <span className="canlogin-condition">logged-in</span> <span className="canlogin-condition">data compiler</span> can add data,whether to log in or not
            </p>
          </div>
        ),
        onOk() {
          let loginDom = document.querySelector(".user-mainheader-login");

          if (loginDom) {
            loginDom.click();
          }
        },
      });
      return;
    }
    if (this.state.isInputer === false) {
      Modal.info({
        content: "Sorry!!!,you are not an expert or data compiler,you have no permission",
      });
      return;
    }
    let { currentReferenceId } = this.state;

    let url = "/collection/add?";
    let refUrl = currentReferenceId ? "refId=" + currentReferenceId : "";

    let UnitIdUrl = unitid ? "&unitId=" + unitid : "";

    let params = `${url}${refUrl}${UnitIdUrl}`;

    // this.props.history.push(params);
    const w = window.open("about:blank");
    w.location.href = params;
  };
  addcollectionSubset = async (cItem, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let uid = Storage.getItem("gbdbid");
    if (uid == undefined) {
      Modal.confirm({
        title: "This is a prompt message",
        content: (
          <div>
            <p>
              Only <span className="canlogin-condition">logged-in</span> <span className="canlogin-condition">data compiler</span> can add data,whether to log in or not
            </p>
          </div>
        ),
        onOk() {
          let loginDom = document.querySelector(".user-mainheader-login");

          if (loginDom) {
            loginDom.click();
          }
        },
      });
      return;
    }
    if (this.state.isInputer === false) {
      Modal.info({
        content: "Sorry!!!,you are not an expert or data compiler,you have no permission",
      });
      return;
    }

    let params = {
      uid,
      assetstype: 3,

      ids: [cItem.coll_id],
    };
    let collectBack = await SubsetApis.collectAssets(params);
    if (collectBack.result) {
      Modal.success({
        title: "This is an successful message",
        content: "successfully added ",
      });
    } else {
      Modal.error({
        title: "This is an error message",
        content: collectBack.error,
      });
    }
  };

  addNewFossil = async (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let uid = Storage.getItem("gbdbid");
    if (uid == undefined) {
      Modal.confirm({
        title: "This is a prompt message",
        content: (
          <div>
            <p>
              Only <span className="canlogin-condition">logged-in</span> <span className="canlogin-condition">data compiler</span> can add data,whether to log in or not
            </p>
          </div>
        ),
        onOk() {
          let loginDom = document.querySelector(".user-mainheader-login");

          if (loginDom) {
            loginDom.click();
          }
        },
      });
      return;
    }
    if (this.state.isInputer === false) {
      Modal.info({
        content: "Sorry!!!,you are not an expert or data compiler,you have no permission",
      });
      return;
    }
    let parentValue = e.currentTarget.dataset;
    let collid = parentValue.collid;
    let unitid = parentValue.unitid;
    let { currentReferenceId } = this.state;
    let url = "/collection/addTaxonomies?";
    let refUrl = currentReferenceId ? "refId=" + currentReferenceId : "";

    let collIdUrl = collid ? "&collId=" + collid : "";
    let UnitIdUrl = unitid ? "&unitId=" + unitid : "";
    let params = `${url}${refUrl}${UnitIdUrl}${collIdUrl}`;

    // this.props.history.push(params);
    const w = window.open("about:blank");
    w.location.href = params;
  };
  showOptionModal = (fossilItem, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let uid = Storage.getItem("gbdbid");
    if (uid == undefined) {
      Modal.confirm({
        title: "This is a prompt message",
        content: (
          <div>
            <p>
              Only <span className="canlogin-condition">logged-in</span> <span className="canlogin-condition">data compiler</span> can add data,whether to log in or not
            </p>
          </div>
        ),
        onOk() {
          let loginDom = document.querySelector(".user-mainheader-login");

          if (loginDom) {
            loginDom.click();
          }
        },
      });
      return;
    }
    if (this.state.isInputer === false) {
      Modal.info({
        content: "Sorry!!!,you are not an expert or data compiler,you have no permission",
      });
      return;
    }
    this.setState({
      currentFossilId: fossilItem.id,
      currentFossilItem: fossilItem,
      currentCollectionId: fossilItem.collection_id,
      showSearchTaxon: true,
    });
  };
  searchTaxonCancel = () => {
    this.setState({
      showSearchTaxon: false,
    });
  };

  addViewPort = (newItem, nameType) => {
    this.setState(
      {
        showSearchTaxon: false,
      },
      () => {
        let { currentFossilItem } = this.state;

        currentFossilItem.children.unshift(newItem);
        this.setState(
          {
            currentFossilItem,
          },
          () => {}
        );
      }
    );
  };
  goTypeAdd = () => {
    let type = this.props.match.params.type;
    if (type === "reference") {
      let url = "/reference/add";
      const w = window.open("about:blank");
      w.location.href = url;
      // this.props.history.push("/reference/add");
    } else if (type === "section") {
      let url = "/section/baseinfo";
      const w = window.open("about:blank");
      w.location.href = url;
      // this.props.history.push("/section/baseinfo");
    } else if (type === "collection") {
      let url = "/collection/add";
      const w = window.open("about:blank");
      w.location.href = url;
      // this.props.history.push("/collection/add");
    } else if (type === "oceanic") {
      let url = "/oceanic/add/huici";
      const w = window.open("about:blank");
      w.location.href = url;
      // this.props.history.push("/oceanic/add/huici");
    } else {
      let url = "/taxonomy/addTaxon";
      const w = window.open("about:blank");
      w.location.href = url;
      // this.props.history.push("/taxonomy/addHigther");
    }
  };
  openTaxonModal = () => {
    this.setState({
      taxonOpModal: true,
    });
  };
  closeTaxonModal = () => {
    this.setState({
      taxonOpModal: false,
    });
  };
  addTaxModal = () => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        // let addResult = await addTaxon(values);
        let addResult = [];
        if (addResult.result) {
          let that = this;
          Modal.success({
            title: "This is a successful message",
            content: "化石添加成功",
            onOk() {
              that.props.form.resetFields();
            },
          });
        } else {
          Modal.error({
            title: "This is a error message",
            content: addResult.error,
          });
        }
      }
    });
  };
  cancelTaxonModal = () => {
    this.setState({
      addTaxonModal: false,
    });
  };
  openAddTaxon = () => {
    this.setState({
      addTaxonModal: true,
    });
  };

  handleData = (type) => {
    let { currentReferenceId, currentSectionId, currentFormationId, currentUnitId, currentCollectionId, currentFossilId } = this.state;
    let allParams = {};
    if (type === "section") {
      allParams = {
        ref_id: currentReferenceId,
      };
    } else if (type === "formation") {
      allParams = {
        section_basic_id: currentSectionId,
      };
    } else if (type === "unit") {
      allParams = {
        formation_id: currentFormationId,
      };
    } else if (type === "collection") {
      allParams = {
        unit_id: currentUnitId,
      };
    } else if (type === "fossil") {
      allParams = {
        collection_id: currentCollectionId,
      };
    } else if (type === "fossiloption") {
      allParams = {
        fossillist_id: currentFossilId,
      };
    }

    let dataType = type + "Data";
    let loadingType = type + "Loading";
    let totalType = type + "Total";
    let hasMoreType = type + "HasMore";
    let pageType = type + "Page";

    // 方法
    let getDataType = `get${type}Data`;
    // 数据名称
    let DataName = `${type}Data`;
    //数据
    let dataResult = this.state[dataType];
    // 总数
    let totalResult = this.state[totalType];
    this.setState({
      [loadingType]: true,
    });

    if (dataResult.length >= totalResult) {
      // message.warning(`${type} data loaded all`);
      this.setState({
        [hasMoreType]: false,
        [loadingType]: false,
      });
      return;
    }

    let currentPage = this.state[pageType];
    let queryParams = allParams;
    let pageSize = this.state.pageSize;

    this.setState(
      {
        [pageType]: currentPage + 1,
      },
      () => {
        let searchParams = {
          pageSize,
          page: this.state[pageType],
          ...queryParams,
        };

        let dataFun = this[getDataType];

        dataFun(searchParams, (res) => {
          totalResult = dataResult.concat(res.result);

          this.setState({
            [DataName]: totalResult,
            [loadingType]: false,
          });
        });
      }
    );
  };
  goHistoryBack = () => {
    this.props.history.goBack();
  };
  resultTaxonCancel = () => {
    this.setState({
      showTaxonResult: false,
    });
  };
  resultTaxonOk = () => {
    this.setState({
      showTaxonResult: false,
    });
  };
  onTaxonomy = (ref) => {
    this.taxonomyChild = ref;
  };
  showTaxonomyDetail = async (taxonmixed, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let params = {
      id: taxonmixed,
    };
    let searchResult = await Apis.getTaxons(params);
    if (searchResult.result && searchResult.result.length > 0) {
      this.setState({
        sectionDetail: searchResult.result[0],
        sectionType: "taxonomy",
        showSectionForm: true,
      });
    } else {
      if (searchResult.error) {
        Modal.error({
          content: searchResult.error,
        });
      }
    }
  };
  showTaxparentDetail = async (taxonmixed, parentCount, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (parentCount === 0) {
      return;
    }

    let params = {
      child_id: taxonmixed,
    };

    let searchResult = await Apis.searchTaxonParent(params);
    if (searchResult.result && searchResult.result.length > 0) {
      this.setState({
        sectionDetail: searchResult.result[0],
        sectionType: "taxonomy",
        showSectionForm: true,
      });
    } else {
      if (searchResult.error) {
        Modal.error({
          content: searchResult.error,
        });
      }
      if (searchResult.result && searchResult.result.length == 0) {
        Modal.info({
          content: "No parent",
        });
      }
    }
  };
  showTaxonomySiblings = async (taxonmixed, parentCount, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (parentCount === 0) {
      return;
    }
    let params = {
      id: taxonmixed,
    };
    this.setState(
      {
        showTaxonResult: true,
      },
      () => {
        setTimeout(() => {
          this.taxonomyChild.searchTaxonomyDatas(params);
        }, 20);
      }
    );
  };
  TaxonomyDetail = (itemValue) => {
    this.setState({
      sectionDetail: itemValue,
      sectionType: "taxonomy",
      showSectionForm: true,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      // ref
      referenceTotal,
      referenceHasMore,
      referenceLoading,
      referenceData,
      currentReferenceId,
      referenceActive,
      // section
      sectionData,

      sectionLoading,

      sectionHasMore,
      // formation

      formationDataObj,

      // 观点
      showSearchTaxon,

      // 添加观点

      // 新增
      openSectionids,
      openChildTypes,
      showUnitObj,
      showUnitIcon,
    } = this.state;
    let nameType = this.props.match.params.type;
    let showName = this.props.match.params.type.replace(nameType[0], nameType[0].toUpperCase());

    return (
      <div className="search-results search-all-results ">
        <div className="searchBase-title collectionAdd-title">
          <div className="reference-search-operate">
            <p className="title">{showName} Check Search Result</p>
          </div>
          <div className="common-back">{this.state.showBackIcon ? <span className="iconfont icon-back" onClick={this.goHistoryBack}></span> : null}</div>
        </div>
        <div className="search-results-content">
          <p className="search-results-title">
            Search {`${this.props.match.params.type}`} matched
            <span className="search-reult-num">{referenceTotal}</span>references
          </p>
          <div className="search-infinite-container" ref="wrapper">
            {/* <InfiniteScroll
              initialLoad={false}
              pageStart={referencePage}
              loadMore={this.handleInfiniteOnLoad}
              hasMore={!referenceLoading && referenceHasMore}
              useWindow={false}
            > */}
            {referenceData.map((item, index) => {
              return (
                <div key={item.ref_id}>
                  <div className="referennce-result-itemTitle" onClick={(e) => this.referenceCallback(item.ref_id, e)}>
                    <span className="search-result-index">{index + 1}</span>
                    <span className="search-author">
                      {currentReferenceId === item.ref_id && referenceActive ? <span className="iconfont icon-caret-down"></span> : <span className="iconfont icon-caret-right"></span>}

                      <span className="search-item-property search-all-property">Reference:</span>
                      <span className="search-item-detail">{item.ref_id}</span>
                    </span>

                    <span title="view details" className="iconfont icon-xiangqing" onClick={(e) => this.showReferenceDetail(item, e)}></span>
                    <span
                      title={this.state.isInputer === true ? "add new reference" : "sorry!!You do not have permission to add data"}
                      className={`iconfont icon-tianjia ${this.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                      onClick={(e) => this.addNewReference(item, e)}
                    ></span>
                  </div>
                  <div className="search-result-item">
                    <div className="search-item-detail">
                      <p>
                        <span className="search-author">
                          <span className="search-item-property">First author：</span>
                          {item.ref_first_author}
                        </span>
                      </p>
                      <p>
                        <span className="search-author">
                          <span className="search-item-property">Other authors：</span>
                          {item.ref_authors ? (item.ref_authors.indexOf(",") !== -1 ? item.ref_authors.substring(item.ref_authors.indexOf(",") + 1) : "") : ""}
                        </span>
                      </p>
                      <p>
                        <span className="search-title" title={item.ref_title}>
                          <span className="search-item-property">Title：</span>
                          {item.ref_title}
                        </span>
                      </p>
                      <p>
                        <span className="search-book">
                          <span className="search-item-property">The title of a magazine or book：</span>
                          {item.ref_journal_booktitle}
                        </span>
                      </p>
                      <p>
                        <span className="search-rollup">
                          <span className="search-item-property">Volume no：</span>
                          {item.ref_Journal_volume}
                        </span>
                        <span className="search-page-begin">
                          <span className="search-item-property">Page Number：</span>
                          {item.ref_page_number_begin}
                        </span>
                        <span>~</span>
                        <span className="">{item.ref_page_number_end}</span>
                      </p>
                      <p>
                        <span className="search-enterer">
                          <span className="search-item-property">Enterer：</span>
                          {item.enterer_username}
                        </span>
                      </p>
                      <p>
                        <span className="search-author">
                          <span className="search-item-property">Authorize by：</span>
                          {item.authorizer}
                        </span>
                      </p>
                    </div>
                  </div>
                  {this.state.showSection && this.state.sectionData.length > 0 && item.ref_id === this.state.currentReferenceId ? (
                    <div className="search-all-parts" ref="sectioninner">
                      {/* <InfiniteScroll
                          initialLoad={false}
                          pageStart={sectionPage}
                          loadMore={() => this.handleData("section")}
                          hasMore={!sectionLoading && sectionHasMore}
                          useWindow={false}
                        > */}

                      <Collapse
                        activeKey={openSectionids}
                        bordered={false}
                        onChange={this.sectionCallback}
                        expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                        // destroyInactivePanel={true}
                      >
                        {sectionData.map((sectionItem, index) => {
                          let degreeLat = 0;
                          let minuteLat = 0;
                          let secondLat = 0;
                          let degreeLng = 0;
                          let minuteLng = 0;
                          let secondLng = 0;
                          let directionLat = "";
                          let directionLng = "";

                          if (sectionItem.geology_latitude_decimal) {
                            let a = sectionItem.geology_latitude_decimal;
                            degreeLat = parseInt(a);
                            minuteLat = parseInt((a - degreeLat) * 60);
                            secondLat = parseFloat((a - degreeLat) * 3600 - minuteLat * 60).toFixed(0);
                          }
                          if (sectionItem.geology_longitude_decimal) {
                            let a = sectionItem.geology_longitude_decimal;
                            degreeLng = parseInt(a);
                            minuteLng = parseInt((a - degreeLng) * 60);
                            secondLng = parseFloat((a - degreeLng) * 3600 - minuteLng * 60).toFixed(0);
                          }
                          if (sectionItem.geology_latitude_direction) {
                            directionLat = sectionItem.geology_latitude_direction[0];
                          }
                          if (sectionItem.geology_longitude_direction) {
                            directionLng = sectionItem.geology_longitude_direction[0];
                          }

                          return (
                            <Panel
                              key={sectionItem.section_basic_id + "section"}
                              style={customPanelStyle}
                              header={
                                <div id={sectionItem.section_basic_id + "section"}>
                                  <span className="search-author">
                                    <span className="search-item-property search-all-property">Section ID:</span>
                                    <span>{sectionItem.section_basic_id}</span>
                                    <span className="search-formation-thicksign">{`${degreeLat}°${Math.abs(minuteLat)}'${Math.abs(secondLat)}"${directionLat}`}</span>
                                    <span className="search-formation-thicksign">{`${degreeLng}°${Math.abs(minuteLng)}'${Math.abs(secondLng)}"${directionLng}`}</span>
                                    <span title="view details" className="iconfont icon-xiangqing" onClick={(e) => this.showSectionDetail(sectionItem, e)}></span>
                                    <span
                                      title={this.state.isInputer === true ? "add new section" : "sorry!!You do not have permission to add data"}
                                      className={`iconfont icon-tianjia ${this.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                      onClick={(e) => this.addNewSection(item, e)}
                                    ></span>
                                    {showUnitIcon ? (
                                      <span
                                        title={JSON.stringify(openChildTypes) !== "{}" && openChildTypes[sectionItem.section_basic_id] ? "see formation" : "see  unit"}
                                        className={JSON.stringify(openChildTypes) !== "{}" && openChildTypes[sectionItem.section_basic_id] ? "iconfont icon-formation" : "iconfont icon-unit"}
                                        onClick={(e) => this.sectionSeeChild(sectionItem, e)}
                                      ></span>
                                    ) : null}
                                  </span>
                                </div>
                              }
                            >
                              {JSON.stringify(openChildTypes) !== "{}" && !openChildTypes[sectionItem.section_basic_id] ? (
                                JSON.stringify(formationDataObj) !== "{}" &&
                                formationDataObj[sectionItem.section_basic_id] &&
                                formationDataObj[sectionItem.section_basic_id].children &&
                                formationDataObj[sectionItem.section_basic_id].children.length > 0 ? (
                                  formationHasShow(this, sectionItem)
                                ) : JSON.stringify(formationDataObj) !== "{}" &&
                                  formationDataObj[sectionItem.section_basic_id] &&
                                  formationDataObj[sectionItem.section_basic_id].children &&
                                  formationDataObj[sectionItem.section_basic_id].children.length === 0 ? (
                                  <div className="search-no-resource results-formation-part">
                                    <span className="search-item-property search-all-property search-formation-property">Formation:null</span>
                                    <span
                                      title={this.state.isInputer === true ? "add new formation" : "sorry!!You do not have permission to add data"}
                                      className={`iconfont icon-tianjia ${this.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                      data-sectionid={sectionItem.section_id}
                                      data-geologyid={sectionItem.geology_id}
                                      onClick={(e) => this.addNewFormation(sectionItem, e)}
                                    ></span>
                                  </div>
                                ) : null
                              ) : JSON.stringify(openChildTypes) !== "{}" && openChildTypes[sectionItem.section_basic_id] ? (
                                JSON.stringify(showUnitObj) !== "{}" &&
                                showUnitObj[sectionItem.section_basic_id] &&
                                showUnitObj[sectionItem.section_basic_id].children &&
                                showUnitObj[sectionItem.section_basic_id].children.length > 0 ? (
                                  sectionUnitHasShow(this, sectionItem)
                                ) : JSON.stringify(showUnitObj) !== "{}" &&
                                  showUnitObj[sectionItem.section_basic_id] &&
                                  showUnitObj[sectionItem.section_basic_id].children &&
                                  showUnitObj[sectionItem.section_basic_id].children.length === 0 ? (
                                  <div className="search-no-resource">
                                    <span className="search-item-property search-all-property">Unit:null</span>
                                    <span
                                      title={this.state.isInputer === true ? "add new unit" : "sorry!!You do not have permission to add data"}
                                      data-formationid={sectionItem.formation_id}
                                      data-sectionid={sectionItem.section_basic_id}
                                      data-geologyid={sectionItem.geology_id}
                                      className={`iconfont icon-tianjia ${this.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                                      onClick={(e) => this.addNewUnit(sectionItem.formation_id, e)}
                                    ></span>
                                  </div>
                                ) : null
                              ) : null}
                            </Panel>
                          );
                        })}
                      </Collapse>
                      {/* </InfiniteScroll> */}
                    </div>
                  ) : this.state.showSection && this.state.sectionData.length == 0 && item.ref_id === this.state.currentReferenceId ? (
                    <div className="search-no-resource referennce-section-itemTitle">
                      <span className="search-item-property search-all-property">{this.state.sectionLoading ? " Section:loading..." : "Section:null"}</span>
                      <span
                        title={this.state.isInputer === true ? "add new section" : "sorry!!You do not have permission to add data"}
                        className={`iconfont icon-tianjia ${this.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                        onClick={(e) => this.addNewSection(e)}
                      ></span>
                    </div>
                  ) : null}
                  {/* {referenceLoading && referenceHasMore && (
                      <div id="allResult">
                        <div className="allLoader">
                          <div className="loader-inner ball-spin-fade-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <span>Loading...</span>
                        </div>
                      </div>
                    )} */}

                  {this.state.formationLoading && (
                    <div id="allResult">
                      <div className="allLoader">
                        <div className="loader-inner ball-spin-fade-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <span>Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {/* </InfiniteScroll> */}
          </div>
        </div>
        {this.state.showBackIcon ? (
          <div className="reference-to-add submit-btnDiv">
            <Button
              onClick={() => {
                this.goTypeAdd();
              }}
              type="gbdb"
              className="submit-lastButton"
              disabled={this.state.isInputer === true ? false : true}
              title={this.state.isInputer === true ? "" : "sorry!!You do not have permission to add data"}
            >{`Add ${this.props.match.params.type}`}</Button>
          </div>
        ) : null}

        <Modal
          destroyOnClose
          visible={this.state.taxonOpModal}
          className="taxon-op-modal taxon-ops-modal"
          width="48%"
          title={null}
          footer={null}
          onOk={this.openTaxonModal}
          onCancel={this.closeTaxonModal}
        >
          <div className="taxon-all-operates">
            <Button type="gbdb" onClick={() => this.openAddTaxon()}>
              Add Opinion
            </Button>
            <Button type="gbdb">Taxon Detail</Button>
            <Button type="gbdb">
              <p>Show highter</p>
              <p>Taxon</p>
            </Button>
          </div>
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.addTaxonModal}
          className="taxon-op-modal taxon-add-modal"
          width="53%"
          title={null}
          // footer={null}
          onOk={this.addTaxModal}
          onCancel={this.cancelTaxonModal}
        >
          <div className="taxon-all-operates taxon-add-operates">
            <Form layout="inline" className="addtax-modal-form">
              <Form.Item label="Taxon ID">
                {getFieldDecorator("taxon_id", {
                  initialValue: "",
                })(<Input></Input>)}
              </Form.Item>
              <Form.Item label="Taxon name">
                {getFieldDecorator("taxon_name", {
                  initialValue: "",
                })(<Input></Input>)}
              </Form.Item>

              <Form.Item label="Reference ID">
                {getFieldDecorator("taxon_ref_id", {
                  initialValue: "",
                })(<Input></Input>)}
              </Form.Item>
              <Form.Item label="View">
                {getFieldDecorator("taxon_view", {
                  initialValue: "",
                })(
                  // <div >

                  <Input className="add-taxon-view"></Input>
                  // </div>
                )}
              </Form.Item>
            </Form>
          </div>
        </Modal>
        {/* 观点modal */}
        <Modal className="TaxonSearch-all-modal" width="76.8%" title="" visible={showSearchTaxon} onCancel={this.searchTaxonCancel} destroyOnClose={true} footer={null}>
          <div className="">
            <TaxonViewPort
              collection_id={this.state.currentCollectionId}
              addViewPort={this.addViewPort}
              fossillist_id={this.state.currentFossilId}
              ref_id={this.state.currentReferenceId}
              fossilInfo={this.state.currentFossilItem}
            ></TaxonViewPort>
          </div>
        </Modal>

        {/* 详情 */}
        <Modal
          title=""
          destroyOnClose
          visible={this.state.showDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailReferenceOk}
          onCancel={this.detailReferenceCancel}
        >
          <ReferenceDetailForm detailData={this.state.referenceDetail} searchSection={this.searchSection} onlySeeRef={true}></ReferenceDetailForm>
        </Modal>

        <Modal
          zIndex="1001"
          title=""
          destroyOnClose
          visible={this.state.showSectionForm}
          className="searchResultModal taxResultModal DetailModal result-DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailSectionFormOk}
          onCancel={this.detailSectionCancel}
        >
          {this.state.sectionType === "baseinfo" ? (
            <BaseDetailForm detailData={this.state.sectionDetail} viewGeography={this.viewGeography} seeOnly={true}></BaseDetailForm>
          ) : this.state.sectionType === "geology" ? (
            <GeographyDetailForm seeOnly={true} detailData={this.state.sectionDetail} viewBase={this.viewBase} seeOnly={true}></GeographyDetailForm>
          ) : this.state.sectionType === "formation" ? (
            <FormationDetailForm seeOnly={true} detailData={this.state.sectionDetail}></FormationDetailForm>
          ) : this.state.sectionType === "unit" ? (
            <UnitDetailForm seeOnly={true} detailData={this.state.sectionDetail}></UnitDetailForm>
          ) : this.state.sectionType === "collection" ? (
            <CollectionDetailForm seeOnly={true} detailData={this.state.sectionDetail}></CollectionDetailForm>
          ) : this.state.sectionType === "taxonomy" ? (
            <TaxonomyDetailForm detailData={this.state.sectionDetail} onlySeeRef={true} seeOnly={true}></TaxonomyDetailForm>
          ) : null}
        </Modal>

        {/* 化石列表 */}
        <Modal
          destroyOnClose
          visible={this.state.showTaxonResult}
          className="searchResultModal taxResultModal noBottomPadding tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.resultTaxonOk}
          onCancel={this.resultTaxonCancel}
        >
          <TaxonomyTableResult
            searchParams={this.state.searchParams}
            searchValues={this.state.searchValues}
            onTaxonomy={this.onTaxonomy}
            changeCheckSelected={this.changeCheckSelected}
            getDetail={this.TaxonomyDetail}
            checkOne={true}
            checkTaxonomyOne={this.checkTaxonomyOne}
            showSiblings={true}
          ></TaxonomyTableResult>
        </Modal>
        {referenceLoading && referenceHasMore && (
          <div className="demo-loading-container pc-more1-loading">
            <Spin />
          </div>
        )}
        {sectionLoading && sectionHasMore && (
          <div className="demo-loading-container pc-more1-loading">
            <Spin />
          </div>
        )}
      </div>
    );
  }
}

AllResultList = Form.create({})(AllResultList);
export default AllResultList;
