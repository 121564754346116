import React, { Component } from 'react';
import {Form,Input,DatePicker, Button,Pagination, message, Modal } from 'antd'
import axios from 'axios'
import './index.scss'
import moment from 'moment'

    const {RangePicker}=DatePicker

 class AntdForm extends Component {
    constructor(props){
        super(props);
        this.state={
            tableData:[],
            nowPage:1,
            defaultPageSize:10,
            isShowInfo:false,
            total:0,
            isResponse:false
        }
    }
    handleSubmit(page){
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    nowPage:page
                })
                var sendData={
                    page,
                    pageSize:this.state.defaultPageSize
                }
                if(values.createTime){
                    sendData.dateFrom=moment(values.createTime[0]).format('YYYY/MM/DD');
                    sendData.dateTo=moment(values.createTime[1]).format('YYYY/MM/DD');
                }
                   
                   
                axios.get('/api/mes/admin/getNews',{
                    params:sendData,
                }).then(res=>{
                    if(res.data.error===null){
                        this.setState({
                            // nowPage:1,
                            tableData:res.data.result,
                            total:res.data.total,
                            isResponse:true

                        })
                    }
                    if(res.data.result.length===0){
                        // message.warning('查询结果为空！')
                    }
                   
                }).catch(err=>{
                    message.error('this an error message~')
                })
                
              
            }
        })
    }
    changePage(page,pageSize){
        this.setState({
            nowPage:page
        })
        this.handleSubmit(page)
    }
    deleteNew(id){
        Modal.confirm({
            content:'确定删除吗?',
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                axios.delete(`/api/mes/new?id=${id}`).then(res=>{
                    if(res.data.error===null){
                        message.success("删除成功！")
                        this.handleSubmit(this.state.nowPage)
                    }
                }).catch(err=>{
                    message.error('this an error message~')
                })
            }
        })
        //删除新闻
       
    }
   
    render() {
        let {defaultPageSize,nowPage,total,isResponse} =this.state;
        function showTotal(total) {
            return `每页显示\xa0${defaultPageSize}\xa0条\xa0\xa0\xa0  共 ${total} 条记录`;
          }
        const { getFieldDecorator} = this.props.form;
        const {tableData}=this.state;
        return (
            <div id='ARedact'>
                <div className='title'>编辑新闻</div>
                <div className="form">
                    <Form layout='vertical'>

                        <Form.Item label='创建时间'>
                            {
                                getFieldDecorator('createTime',{

                                })(<RangePicker></RangePicker>)
                            }
                        </Form.Item>
                        
                        <Form.Item className='foot'>
                           <Button type='primary' onClick={this.handleSubmit.bind(this,1)}>筛选</Button>
                        </Form.Item>

                    </Form>
                   
                </div>
                {
                    tableData.length>0?(
                        <div className='table'>
                        <table>
                            <tbody>
                            <tr className='head'>
                                <td>序号</td>
                                <td>新闻标题</td>
                                <td>发布时间</td>
                                <td>操作</td>
                            </tr>
                            {
                                tableData.map((item,index)=>{
                                    return (
                                        <tr key={index} className={`tr ${index%2===0?'color':''}`}>
                                            <td>{(nowPage-1)*defaultPageSize+index+1}</td>
                                            <td>{item.title}</td>
                                            <td>{moment(item.release_date).format('YYYY/MM/DD')}</td>
                                            <td >
                                                <Button type='link' onClick={this.deleteNew.bind(this,item.id)}>删除</Button>
                                            </td>
                                        </tr>
                                    )
    
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    ):null
                }
                {
                    tableData.length>0?(
                        <div className='pages'>
                                    <Pagination size='small' 
                                    current={nowPage}
                                    defaultPageSize={defaultPageSize} 
                                    total={total} 
                                    // showTotal={showTotal.bind(this)}
                                    onChange={this.changePage.bind(this)}
                                    ></Pagination>
                                </div>
                    ):null
                }
               {tableData.length===0 && isResponse ? <div className='pageTip'>暂无数据</div> :null}
               
            
            </div>
        
            );
    }
}
const AUser = Form.create()(AntdForm);
export default AUser

