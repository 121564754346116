import React, { Component } from "react";
import "./index.css";
import { Form, Button, Input, Modal, Tabs, message, Spin } from "antd";
import Storage from "src/js/storage";
import InfiniteScroll from "react-infinite-scroller";
import feedbackApis from "src/js/apis/feedback";
import moment from "moment";

import "src/js/global.js";
const { TextArea } = Input;
const { TabPane } = Tabs;
class Feedback extends Component {
  state = {
    uploadFiles: [],
    feedbackList: [],
    loading: false,
    hasMore: true,
    total: 0,
    currentPage: 1,
    pageSize: 20,
  };
  isGbdbUser = () => {
    let token = Storage.getItem("token");

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page",
        });
        this.props.history.push("/home");
      }
    }
  };
  componentDidMount = () => {
    this.isGbdbUser();
  };
  handleSubmit = () => {};

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };
  onChange = () => {};
  onChangeFile = (file) => {
    let { uploadFiles } = this.state;

    let newest = file;

    this.setState(
      {
        uploadFiles: [...uploadFiles, newest],
      },
      () => {}
    );
  };
  removeFile = (file, index) => {
    let { uploadFiles } = this.state;
    uploadFiles.splice(index, 1);
    this.setState({
      uploadFiles,
    });
  };
  submitSuggestion = () => {
    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (Storage.getItem("gbdbid")) {
          let allParams = {
            uid: Storage.getItem("gbdbid"),
            content: values.suggestions,
          };

          // const formData = new FormData();
          // for (let [key, value] of Object.entries(addReferenceData)) {
          //   formData.append(key, value);
          // }
          // let { uploadFiles } = this.state;

          // if (uploadFiles.length > 0) {
          //   uploadFiles.map((item, index) => {
          //     formData.append("files", item);
          //   });
          // }

          let feedback = await feedbackApis.createFeedback(allParams);
          if (feedback.error) {
            Modal.error({
              title: "This is an error message",
              content: feedback.error,
            });
          } else if (feedback.result) {
            Modal.success({
              title: "Operation feedback",
              content: "Your feedback has been submitted",
            });
            this.props.form.resetFields();
          }
        }
      }
    });
  };
  changeTab = async (activeKey) => {
    this.setState({
      loading: false,
      hasMore: true,
      total: 0,
      currentPage: 1,
    });
    if (activeKey === "feedback") {
      this.props.form.resetFields();
    } else if (activeKey === "list") {
      let { pageSize, currentPage } = this.state;
      let pageParams = {
        pageSize,
        page: currentPage,
      };
      await this.getList(pageParams, (res) => {
        this.setState({
          feedbackList: res.result,
          total: res.total,
        });
      });
    }
  };
  getList = async (pageParams, callback) => {
    let allParams = {};

    let orderParams = { ordername: "id", orderType: 1 };
    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }
    if (Storage.getItem("gbdbid")) {
      let searchParams = {
        uid: Storage.getItem("gbdbid"),
      };
      Object.assign(allParams, searchParams, pageParams, orderParams);
      let feedbackBack = await feedbackApis.getUserFeedback(allParams);
      if (feedbackBack.error) {
        const modal = Modal.error({
          title: "This is an error message",
          content: feedbackBack.error,
        });
        setTimeout(() => {
          modal.destroy();
        }, 3000);
      } else {
        callback(feedbackBack);
      }
    }
  };
  handleInfiniteOnLoad = () => {
    let { feedbackList, total } = this.state;
    this.setState({
      loading: true,
    });
    if (feedbackList.length >= total) {
      message.warning(" List loaded all");
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }
    let { pageSize, currentPage } = this.state;
    this.setState(
      {
        currentPage: currentPage + 1,
      },
      () => {
        let pageParams = {
          pageSize,
          page: this.state.currentPage,
        };
        this.getList(pageParams, (res) => {
          feedbackList = feedbackList.concat(res.result);
          this.setState({
            feedbackList,
            loading: false,
          });
        });
      }
    );
  };
  deleteFeedBackItem = async (item, index) => {
    let that = this;
    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }

    Modal.confirm({
      title: "please choose",
      content: "Do you want to delete the feedback?",
      async onOk() {
        if (Storage.getItem("gbdbid")) {
          let allParams = {
            id: item.id,
            uid: Storage.getItem("gbdbid"),
          };
          let deleteBack = await feedbackApis.deleteUserMessage(allParams);
          if (deleteBack.result) {
            let { feedbackList, total } = that.state;
            total = total - 1;
            feedbackList.splice(index, 1);
            that.setState(
              {
                feedbackList,
                total,
              },
              () => {
                Modal.success({
                  content: "deleted successfully",
                });

                let { currentPage, pageSize, feedbackList, total } = that.state;

                if (feedbackList.length < total) {
                  let pageParams = {
                    pageSize: 1,
                    page: currentPage * pageSize,
                  };
                  that.getList(pageParams, (res) => {
                    feedbackList = feedbackList.concat(res.result);
                    that.setState({
                      feedbackList,
                      loading: false,
                    });
                  });
                }
              }
            );
          } else {
            if (deleteBack.error) {
              Modal.error({
                content: deleteBack.error,
              });
            }
          }
        }
      },
      onCancel() {},
    });
  };
  render() {
    let { feedbackList } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="questions-content">
        <div className="reference-search-operate">
          <p className="group-news-title">Feedback</p>
        </div>
        <div className="feedback-tabs">
          <Tabs defaultActiveKey="feedback" onChange={this.changeTab}>
            <TabPane tab="Feedback" key="feedback">
              <div className="reference-add-form">
                <Form>
                  <div className="referenceAdd-comments">
                    <Form.Item label="" className="long-label">
                      {getFieldDecorator("suggestions", {
                        rules: [{}],
                        initialValue: "",
                      })(<TextArea placeholder="Please enter...(Enter a maximum of 255 characters)" rows={10}></TextArea>)}
                    </Form.Item>
                  </div>
                  {/* <div className="referenceAdd-upload">
              <Form.Item
                label="Upload Files"
                extra=""
             
                className="long-label"
              >
                {getFieldDecorator("ref_file_path", {
                  // initialValue: this.state.uploadFiles
                })(
                  <BaseUpload
                    // defaultImg={}
                    showImg={false}
                    listType="text"
                    showUploadList={true}
                    onChangeFile={file => this.onChangeFile(file)}
                    removeFile={(file, index) => this.removeFile(file, index)}
                  ></BaseUpload>
                )}
              </Form.Item>
            </div> */}
                </Form>
              </div>

              <div className="questions-submit">
                <Button type="gbdb" onClick={this.submitSuggestion}>
                  Submit
                </Button>
              </div>
            </TabPane>

            <TabPane tab="List" key="list">
              <div className="feedback-list">
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={this.handleInfiniteOnLoad}
                  hasMore={!this.state.loading && this.state.hasMore}
                  useWindow={false}
                  className="feedback-lists-scroll"
                >
                  <ul className="feedback-lists-ul">
                    {feedbackList.length > 0 ? (
                      feedbackList.map((item, index) => {
                        return (
                          <li
                            className="message-list-item"
                            // onClick={() => this.viewNewDetail(item, index)}
                            key={index}
                          >
                            <div className="">{index + 1}</div>
                            <div className="" title={item.content}>
                              {item.content}
                            </div>

                            <div className="">
                              {moment(item.createdAt).format("YYYY-MM-DD")}
                              <span title="delete the feedback" className="iconfont icon-delete" onClick={() => this.deleteFeedBackItem(item, index)}></span>
                            </div>
                            {/* <div className="col feedback-list-delete">
                           
                            </div> */}
                          </li>
                        );
                      })
                    ) : (
                      <li className="message-no-res">No information submitted</li>
                    )}

                    {this.state.loading && this.state.hasMore && (
                      <div className="feedlist-loading-container">
                        <Spin />
                      </div>
                    )}
                  </ul>
                </InfiniteScroll>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}
Feedback = Form.create({})(Feedback);
export default Feedback;
