/* eslint-disable  */

import axios from 'axios'
const pending = {}
const CancelToken = axios.CancelToken
const removePending = (key, isRequest = false) => {

  if (pending[key] && isRequest) {
    pending[key]('取消重复请求err')
  }
  delete pending[key]
}
const getRequestIdentify = (config, isReuest = false) => {
  let url = config.url

  if (isReuest) {
    url = config.baseURL + config.url.substring(1, config.url.length)
  }
  // return config.method === 'get' ? encodeURIComponent(url + JSON.stringify(config.params)) : encodeURIComponent(config.url + JSON.stringify(config.data))
  return config.method === 'get' ? encodeURIComponent(config.url + config.method) : encodeURIComponent(config.url + config.method)
}

// 请求拦截器
axios.interceptors.request.use(config => {

  // 拦截重复请求(即当前正在进行的相同请求)
  let requestData = getRequestIdentify(config, true)
  if (config) {

    if (config.url !== "/api/user/refresh" &&config.url !== "/api/user/byuserid") {
      removePending(requestData, true)

    }
  }

  

  config.cancelToken = new CancelToken((c) => {
    pending[requestData] = c
  })

  return config
}, error => {


  // return Promise.reject(error)
  return { data: { result: null, error: '取消重复请求err' } };
})

// 异常处理
axios.interceptors.response.use(response => {
  // 把已经完成的请求从 pending 中移除
  let requestData = getRequestIdentify(response.config)
  if (response.config) {

    if (response.config.url !== "/api/user/refresh" && response.config.url !== "/api/user/byuserid") {
      removePending(requestData)

    }
  }





  return {
    code: response.status,
    message: response.statusText,
    data: response.data
  }
}, err => {
  // console.log('err1', err)


  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        err.message = '错误请求'
        break
      case 401:
        err.message = '未授权，请重新登录'
        break
      case 403:
        err.message = '拒绝访问'
        break
      case 404:
        err.message = '请求错误,未找到该资源'
        break
      case 405:
        err.message = '请求方法未允许'
        break
      case 408:
        err.message = '请求超时'
        break
      case 500:
        err.message = '服务器端出错'
        break
      case 501:
        err.message = '网络未实现'
        break
      case 502:
        err.message = '网络错误'
        break
      case 503:
        err.message = '服务不可用'
        break
      case 504:
        err.message = '网络超时'
        break
      case 505:
        err.message = 'http版本不支持该请求'
        break
      default:
        err.message = `连接错误${err.response.status}`
    }
    let errData = {
      code: err.response.status,
      message: err.message
    }
    // 统一错误处理可以放这，例如页面提示错误...
    // console.log('统一错误处理: ', errData)
    return { data: { result: null, error: err.message } };
  }

  return { data: { result: null, error: '取消重复请求err' } };
  // return Promise.reject(err)
})
const asyncAxios = {
  async get(url, data) {

    try {

      let res = await axios.get(url, { params: data })


      res = res.data
      return new Promise((resolve) => {

        if (res.result) {
          resolve(res)
        } else {


          if (res.error !== "取消重复请求err") {
            resolve(res)
          }

        }
      })
    } catch (err) {
      // console.log('err2', err)
      // alert('服务器出错')

    }
  },
  async delete(url, data) {

    try {

      let res = await axios.delete(url, { params: data })
      res = res.data
      return new Promise((resolve) => {
        if (res.result) {
          resolve(res)
        } else {
          resolve(res)
        }
      })
    } catch (err) {
      // console.log('err3', err)
      // alert('服务器出错')

    }
  },
  async post(url, data) {
    try {
      let res = await axios.post(url, data)
      res = res.data
      return new Promise((resolve, reject) => {
        if (res.result) {
          resolve(res)
        } else {
          if (res.error !== "取消重复请求err") {
            resolve(res)
          }

        }
      })
    } catch (err) {

      return
      // alert('服务器出错')
      // console.log(err)
    }
  },
}
export default asyncAxios

