import React, { Component } from "react";
import "./index.css";


class About extends Component {
  constructor(props) {
    super(props);
  }

  toPeople(peopleName) {
    this.props.history.push({pathname:"/research/people",state:{peopleName:peopleName}});
  }
  render() {
    return (
      <div className="box">
        <div className="contact-title">Contact</div>
        <p className="contact-text-head">Please contact our web developer </p>
          <a onClick={()=>this.toPeople("Ming-Hang Zhu")}> Minghang Zhu</a> <p className="contact-text">if you want to become a GBDB contributor and/or with general questions about the database. </p>
        <br/>
        <p className="contact-text-head">For questions about policy and data use, please contact Data Manager </p>
        <a onClick={()=>this.toPeople("Dr. Lin Na")}> Lin Na</a>
        <p className="contact-text"> and Executive Committee </p>
        <a onClick={()=>this.toPeople("Qi-Jian Li")}>Qijian Li</a><p className="contact-text">.</p>
        <br/>
        <p className="contact-text-head">To request an official publication number, please email to Data Manager</p> 
        <a onClick={()=>this.toPeople("Dr. Lin Na")}> Lin Na</a>
        <p className="contact-text"> or Executive Committee </p>
        <a onClick={()=>this.toPeople("Qi-Jian Li")}>Qijian Li</a><p className="contact-text">.</p>
        
        </div>
    );
  }
}
export default About;
