import React, { Component } from "react";
import { Checkbox, Input, Modal, Radio ,InputNumber,message} from "antd";
import { withRouter } from "react-router-dom";
import Apis from "src/js/apis/api";
import Utils from "src/js/utils";
import "./index.css";

@withRouter
class OccurenceTableResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResultVisible: false,
      detailData: {},
      showDetailForm: false,
      taxType: "Original",
      isCheckAll: false,
      checkedObj: {},
      defaultCheckedList: [],
      searchParams: props.searchParams || {},
      searchValues: props.searchValues || {},
      currentPage: 1,
      totalNumbers: 0,
      resultLists: [],
      pageSize: 16,
      AllPages: 0,
      checkOne: props.checkOne === true ? true : false
      // askLoading:false
    };
  }
  componentDidMount() {
    if (this.props.onGeography) {
      this.props.onGeography(this);
    }
    if (this.props.checkOne) {
      this.setState({
        checkOne: true
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      searchParams: nextProps.searchParams,
      searchAllValues: nextProps.searchAllValues
    });
    if (this.props.checkOne) {
      this.setState({
        checkOne: true
      });
    }

    if (nextProps.showDeleteSubset !== this.props.showDeleteSubset) {
      this.setState({
        showDeleteSubset: nextProps.showDeleteSubset
      });
    }
    if (nextProps.showAddSubset !== this.props.showAddSubset) {
      this.setState({
        showAddSubset: nextProps.showAddSubset
      });
    }
  }

  searchSectionDatas = async (searchParams, searchAllValues, type,parameter1,parameter2,page) => {
    if(page==undefined){
      this.setState({
        currentPage:1
      })
      
    }
    this.setState(
      {
        askLoading: true
      },
      async () => {
        let searchResult = [];
        let searchCondition = {};
        let pageParams = {
          page: this.state.currentPage,
          pageSize: this.state.pageSize
        };
        let searchParamsCopy = Utils.deepObjCopy(searchParams);
        if (searchParamsCopy.early_age) {
          searchParamsCopy.early_age = {
            condition: "before",
            value: searchParamsCopy.early_age
          };
        }
        if (searchParamsCopy.late_age) {
          searchParamsCopy.late_age = {
            condition: "after",
            value: searchParamsCopy.late_age
          };
        }
        Object.assign(searchCondition, searchParamsCopy, pageParams);

        searchResult = await Apis.getGeochemicals(searchCondition);

        if (searchResult.error) {
          this.setState({
            askLoading: undefined
          });
          Modal.error({
            title: "This is an error message",
            content: searchResult.error
          });
        } else if (searchResult.result) {
          let result = searchResult.result;
          let totalNumbers = searchResult.total;
          let { pageSize } = this.state;
          let AllPages = Math.ceil(totalNumbers / pageSize);
          this.setState(
            {
              resultLists: result,
              totalNumbers,
              AllPages,
              conditions: searchAllValues,
              askLoading: false
            },
            () => {}
          );
        }
      }
    );
  };
  changePage = value => {
    this.setState(
      {
        currentPage: value,
        isCheckAll: false,
        askLoading: undefined,
        checkedObj: {},
        resultLists: []
      },
      () => {
        let { searchParams, values, optionType } = this.state;
        this.searchSectionDatas(searchParams, values, optionType,'','',this.state.currentPage);
      }
    );
  };
  gotoPage = e => {
    if (e.keyCode === 13) {
      var page = parseInt(e.target.value);

      let { AllPages } = this.state;
      if (page < 1 || page > AllPages) {
         message.warning("Please enter the correct page number");
        return;
      } else {
        this.setState(
          {
            currentPage: page
          },
          () => {
            this.changePage(this.state.currentPage);
          }
        );
      }
    }
  };
  gotoPages=()=>{
    let pageDom=document.querySelector('.searchResult-select input')
    if(pageDom){
      let pageValue=pageDom.value
      if(pageValue){
        let  page= parseInt(pageValue);
        let { AllPages } = this.state;
        if (page < 1 || page > AllPages) {
           message.warning("Please enter the correct page number");
          return;
        } else {
          this.setState(
            {
              currentPage: page
            },
            () => {
              this.changePage(this.state.currentPage);
            }
          );
        }

      }
    }

  }
  toFirst = e => {
    this.setState(
      {
        currentPage: 1
      },
      () => {
        this.changePage(this.state.currentPage);
      }
    );
  };

  toPre = e => {
    if (this.state.currentPage === 1) {
      return;
    } else {
      this.setState(
        {
          currentPage: this.state.currentPage - 1
        },
        () => {
          this.changePage(this.state.currentPage);
        }
      );
    }
  };

  toNext = e => {
    if (this.state.currentPage === this.state.AllPages) {
      return;
    } else {
      this.setState(
        {
          currentPage: this.state.currentPage + 1
        },
        () => {
          this.changePage(this.state.currentPage);
        }
      );
    }
  };

  CheckAll = e => {
    let { resultLists, checkedObj } = this.state;
    if (e.target.checked === true) {
      this.setState(
        {
          isCheckAll: true
        },
        () => {
          resultLists.map((item, index) => {
            checkedObj[item.id] = {
              item,
              index
            };
          });
          this.setState(
            {
              checkedObj
            },
            () => {
              let { checkedObj, resultLists } = this.state;
              this.props.changeCheckSelected(checkedObj, resultLists);
            }
          );
        }
      );
    } else {
      this.setState(
        {
          isCheckAll: false
        },
        () => {
          this.setState(
            {
              checkedObj: {}
            },
            () => {
              let { checkedObj, resultLists } = this.state;
              this.props.changeCheckSelected(checkedObj, resultLists);
            }
          );
        }
      );
    }
  };

  itemCheckbox = (item, index, event) => {
    if (this.props.location.search) {
      this.setState(
        {
          isSearch: true
        },
        () => {
          // this.props.history.push('')
        }
      );
    } else {
      this.setState({
        isSearch: false
      });
    }

    let { resultLists, checkedObj } = this.state;
    let id = Number(event.target.name);
    if (event.target.checked === true) {
      checkedObj[id] = {
        item,
        index
      };
    } else if (event.target.checked === false && checkedObj[id]) {
      delete checkedObj[id];
    }

    let checkedLength = Object.keys(checkedObj).length;
    this.setState(
      {
        isCheckAll: checkedLength === resultLists.length
      },
      () => {
        if (this.props.checkOne) {
          if (this.props.checkOccuranceOne) {
            this.props.checkOccuranceOne(item);
          }
        } else {
          let { checkedObj, resultLists } = this.state;
          if (this.props.changeCheckSelected) {
            this.props.changeCheckSelected(checkedObj, resultLists);
          }
        }
      }
    );
  };

  getDetail = detailItem => {
    this.props.getDetail(detailItem);
  };
  resetCheckData = () => {
    this.setState({
      currentPage: 1,
      isCheckAll: false,
      askLoading: undefined,
      checkedObj: {}
    });
  };
  render() {
    let { resultLists } = this.state;

    let {
      currentPage,

      AllPages,
      isCheckAll,
      checkedObj,

      checkOne
    } = this.state;
    return (
      <div id="SearchResult" className="SearchResult-refsearch">
        {/*  taxResult-main */}
        <div className="">
          <div className="content">
            {resultLists.length > 0 ? (
              <table>
                <tbody>
                  <tr className="head">
                    {checkOne ? (
                      <td></td>
                    ) : (
                      <td className="check">
                        <Checkbox
                          onChange={e => this.CheckAll(e, "id")}
                          checked={isCheckAll}
                        ></Checkbox>
                      </td>
                    )}

                    <td>Geochemical ID</td>
                    <td>Ref ID</td>
                    <td className="ref-id">Section ID</td>
                    <td>Enterer</td>
                    <td>Upload path</td>
                  </tr>

                  {resultLists.map((item, index) => {
                    return (
                      <tr key={item.id} className="row">
                        {checkOne ? (
                          <td className="check">
                            <Radio
                              name={item.id.toString()}
                              onChange={event =>
                                this.itemCheckbox(item, index, event)
                              }
                              checked={checkedObj.hasOwnProperty(item.id)}
                            ></Radio>
                          </td>
                        ) : (
                          <td className="check">
                            <Checkbox
                              name={item.id.toString()}
                              onChange={event =>
                                this.itemCheckbox(item, index, event)
                              }
                              checked={checkedObj.hasOwnProperty(item.id)}
                            ></Checkbox>
                          </td>
                        )}

                        <td>{item.id}</td>
                        <td>{item.ref_id}</td>

                        <td>{item.section_basic_id}</td>
                        <td>{item.enterer_username}</td>
                        <td>
                          <a
                            href={`/api${item.upload_path}`}
                            download={item.upload_path}
                          >
                            {item.upload_path}
                          </a>
                        </td>

                        {/* 
                      <td className="btn">
                        <Button
                          type="gbdb"
                          onClick={() => this.getDetail(item)}
                        >
                          <div>Details</div>
                        </Button>
                      </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : this.state.askLoading == true ? (
              <div className="tablelist-no-resource"> <div id="allResult">
                      <div className="allLoader">
                        <div className="loader-inner ball-spin-fade-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <span>Loading...</span>
                      </div>
                    </div></div>
            ) : resultLists.length === 0 && this.state.askLoading == false ? (
              <div className="tablelist-no-resource">No Resource</div>
            ) : (
              <div className="tablelist-no-resource"></div>
            )}
          </div>
          {resultLists.length > 0 ? (
            <div className="footer">
              <div className="first" onClick={this.toFirst}>
                First
              </div>
              <div className="pre" onClick={this.toPre}>
                Pre
              </div>
              <div className="pages">{`${currentPage}/${AllPages}pages`}</div>
              <div className="next" onClick={this.toNext}>
                Next
              </div>

              <div className="page-input">
              <InputNumber
                  className="searchResult-select"
                  onKeyDown={this.gotoPage}
                  min={1}
                ></InputNumber>
                 <span className="goto" onClick={this.gotoPages}>Goto</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default OccurenceTableResult;
