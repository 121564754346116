import React, { Component } from "react";
import "./index.css";
import dataApis from "src/js/apis/data";
import Utils from "src/js/utils";
import { Form, Input, Select, Button, Modal, AutoComplete, InputNumber } from "antd";
import Storage from "src/js/storage";
import "src/js/global.js";
import Apis from "src/js/apis/api";
import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";

import TaxonomyTableResult from "commons/AllTableList/Taxonomy/index.js";
import TaxonomyDetailForm from "commons/AllDetails/Taxonomy/index.js";
import Users from "src/js/users";

const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

class AddRevised extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fossilgroup: {},
      treatises: [],
      name1Opts: [],
      name2Opts: [],
      clatorOpts: [],
      checkedObj: {},
      refSearch: false,
      taxSearch: false,
      genusValue: {},
      originalValue:{},
      allChildError: {},
      refType:'ref_id',
      previewValue:{},
      refValue:{},
      rev_genid:'',
      ori_spid:'',
      ref_describing:''
    };
  }
  isGbdbUser = async () => {
    let Inputerid=false
    let token = Storage.getItem("token");
     Inputerid = await Users.isGbdbInputer();
  

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    } else {
      if (Inputerid == false) {
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title: "Sorry!!",
            content: "You do not have permission to add data"
          });
          this.props.history.push("/home");
        }
      }
    }
  };
  async componentDidMount() {
    this.isGbdbUser();
    let fossilgroup = await dataApis.fossilgroup();
    let treatises = await dataApis.getTreatise();
    this.setState({
      fossilgroup: fossilgroup && fossilgroup.result ? fossilgroup.result : {},
      treatises: treatises && treatises.result ? treatises.result : []
    });
  }

  addComplete = () => {};
  addOriginal = () => {};
  handleName1Change = value => {
    this.props.form.setFieldsValue({
      taxName1: value
    });
  };
  handleName1Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name1"],
        taxon_name1: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name1Opts: [] });
        } else {
          this.setState(
            {
              name1Opts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ name1Opts: [] });
    }
  };

  handleName2Change = value => {
    this.props.form.setFieldsValue({
      taxName2: value
    });
  };
  handleName2Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name2"],
        taxon_name2: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name2Opts: [] });
        } else {
          this.setState(
            {
              name2Opts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ name2Opts: [] });
    }
  };

  handleNomSearch = async value => {
    if (value) {
      let params = {
        attributes: ["nomenclator"],
        nomenclator: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);
      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ clatorOpts: [] });
        } else {
          this.setState(
            {
              clatorOpts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ clatorOpts: [] });
    }
  };

  handleNomChange = value => {
    this.props.form.setFieldsValue({
      nomenclator: value
    });
  };


  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };

  showSearchRef = (type,e) => {
    e.target.blur();
    if(type=="ref_id"){
      this.setState({
        refType:'ref_id'
      })
    }else if(type=="ref_describing"){
      this.setState({
        refType:'ref_describing'
      })
    }
  
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };

  onRef = ref => {
    this.child = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true
    });

    //点击 detail 按扭执行代码
  };
  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            refSearch: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues } = this.state;

              this.child.searchReferenceDatas(searchParams, searchValues);
            }, 1000);
          }
        );
      }
    });
  };

  checkRefOne = value => {
    let {refType}=this.state
    if(refType=="ref_id"){
      this.props.form.setFieldsValue({
        ref_id: value.ref_id || "",
        // nomenclator: value.authorizer || ""
      });
    }else if(refType=="ref_describing"){
      this.props.form.setFieldsValue({
        ref_describing: value.ref_id || "",
        // nomenclator: value.authorizer || ""
      });
      this.setState({
        refValue:value
      })
    }
    this.setState({
      refSearch: false,
      
   
    });
  };

  searchTaxonCancel = () => {
    this.setState({
      showTaxonResult: false
    });
  };
  onTaxonomy = ref => {
    this.taxonomyChild = ref;
  };
  handleTaxonOk = () => {
    this.setState({
      showTaxonResult: false
    });
  };

  detailTaxonomyFormOk = () => {
    this.setState({
      showTaxonomyDetailForm: false
    });
  };
  detailTaxonomyFormCancel = () => {
    this.setState({
      showTaxonomyDetailForm: false
    });
  };
  TaxonomyDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showTaxonomyDetailForm: true
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            refSearch: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues } = this.state;

              this.child.searchReferenceDatas(searchParams, searchValues);
            }, 1000);
          }
        );
      }
    });
  };
  checkTaxonomyOne = item => {
    let value = "";
    let idType=""
    if (this.state.searchType == "genus") {
      value = "genusValue";
      idType="rev_genid"
    }else if (this.state.searchType == "original") {
      value = "originalValue";
      idType="ori_spid"
    }
    this.setState(
      {
        showTaxonResult: false,
        [value]: item,
        [idType]:item.id,
      },
      async () => {
        if (this.state.searchType == "genus") {
          this.props.form.setFieldsValue({
            genus_name: ""
          });
        }else  if (this.state.searchType == "original") {
          this.props.form.setFieldsValue({
            taxon_name1: "",
            taxon_name2: "",
            nomenclator: "",
            year: "",
            ref_id: "",
          });
        }
      }
    );
  };
  searchGenus = async () => {
    const { getFieldValue } = this.props.form;
    let genusData = {};
    let getGenus = getFieldValue("genus_name");

    if ( getGenus == undefined) {
      getGenus=""
     
    }
    genusData={
      taxon_name1:getGenus,
      taxon_type:'genus'
    }
    this.setState(
      {
        searchParams: genusData,
        searchValues: {},
        showTaxonResult: true,
        searchType: "genus"
      },
      () => {
        setTimeout(() => {
          let { searchParams, searchValues } = this.state;
          this.taxonomyChild.searchTaxonomyDatas(searchParams, searchValues);
        }, 1000);
      }
    );
  };
  searchOriginal=async()=>{
    const { getFieldsValue } = this.props.form;
    let getOriginal = getFieldsValue([
      "taxon_name1",
      "taxon_name2",
      "nomenclator",
      "year",
      "ref_id"
    ]);
    let allParams={}
    Object.assign(allParams,getOriginal,{taxon_type:'species-original'})
    this.setState({
      searchParams: allParams,
        searchValues: {},
        showTaxonResult: true,
        searchType: "original"
    },()=>{
      setTimeout(() => {
        let { searchParams, searchValues } = this.state;
        this.taxonomyChild.searchTaxonomyDatas(searchParams, searchValues);
      }, 1000);
    })
  }
  previewTaxon=()=>{
    let {genusValue,originalValue,refValue}=this.state
   if((JSON.stringify(genusValue)==="{}") || (JSON.stringify(genusValue)==="{}")){
     Modal.error({
      title: 'This is an error message',
      content: 'There is no data for  genus or original ',
     })
     return
   }
    let previewValue={
      taxon_name1:genusValue.taxon_name1?genusValue.taxon_name1:'',
      taxon_name2:originalValue.taxon_name2?originalValue.taxon_name2:'',
      nomenclator:originalValue.nomenclator?originalValue.nomenclator:'',
      year:originalValue.year?originalValue.year:'',
      ref_first_author:refValue.ref_first_author?refValue.ref_first_author:'',
      ref_publication_year:refValue.ref_publication_year?refValue.ref_publication_year:''
    }
   

    this.setState({
      previewValue
    })
  }
  addRevised=async()=>{
    const { getFieldsValue,getFieldValue } = this.props.form;
    let {ori_spid,rev_genid}=this.state
    let refId=getFieldValue('ref_describing')
    if(ori_spid==""){
      Modal.error({
        content:'please enter original species '
      })
      return
    }
    else if(rev_genid==""){
      Modal.error({
        content:'please enter Genus'
      })
      return
    }else if(refId==undefined||refId==""){
      Modal.error({
        content:'please enter ref_describing'
      })
      return
    }
    let revised = getFieldsValue([
      "fossil_group",
      "taxon_type",
      "notes",
      "from_treatise",
     
    ]);
   
    revised=Utils.filterParams(revised)
    let allParams={}
    let params={
      ori_spid,
      rev_genid,
      ref_id:getFieldValue('ref_describing'),
      enterer:Storage.getItem('gbdbid')
    }
    Object.assign(allParams,revised,params)
   let RevisedBack=await Apis.createRevised(allParams)
   if(RevisedBack.result){
    Modal.success({
      title: "This is a successful message",
      content: "add success"
   
    });
     this.props.form.resetFields();
     this.setState({
      genusValue: {},
      originalValue:{},
      allChildError: {},
      refType:'ref_id',
      previewValue:{},
      refValue:{},
      rev_genid:'',
      ori_spid:'',
      ref_describing:''
     })
   }else{
    Modal.error({
      title: 'This is an error message',
      content:RevisedBack.error,
    })
   }
  }
  goAddOrigin = () => {
    let url = "/taxonomy/addTaxon";
    // this.props.history.push(url);
    const w=window.open('about:blank');
    w.location.href=url
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let { fossilgroup, treatises, checkedObj,previewValue } = this.state;
    const name1Opts = this.state.name1Opts.map((item, indx) => (
      <Option key={item.taxon_name1}>{item.taxon_name1}</Option>
    ));
    const name2Opts = this.state.name2Opts.map((item, indx) => (
      <Option key={item.taxon_name2}>{item.taxon_name2}</Option>
    ));
    const clatorOpts = this.state.clatorOpts.map((item, index) => (
      <Option key={index}>{item.nomenclator}</Option>
    ));
    return (
      <div id="addOriginal" className="taxonomy-common">
        <div className="addOriginal-title">
          <div className="taxonomy-common-title form-common-title">
            Add revised sp.name
          </div>

          <div className="addOriginal-main">
            <div className="reference-search-form addHigther-form addOriginal-form addRevised-form">
              <Form>
                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<p>Fossil group</p>}>
                      {getFieldDecorator("fossil_group", {
                        rules: [{}],
                        initialValue: "Acanthocephala" 
                      })(<Select>{Utils.fossilGroup(fossilgroup)}</Select>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<p>Taxon type</p>}>
                      {getFieldDecorator("taxon_type", {
                        rules: [],
                        initialValue: "species-revised"
                      })(
                        <Select>
                          <Option value="species-revised">
                            species-revised
                          </Option>
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className="sectionGeography-map">
                  <div className="sectionGeography-map-border"></div>
                  <div className="sectionGeography-tutude sectionGeography-tutude-title">
                    <div className="addOriginal-search">
                      Search for taxa and relation
                    </div>
                  </div>
                  <FormItem label={<p>Genus name</p>}>
                    <div className="sectionGeography-tutude">
                      <span>
                        {getFieldDecorator(
                          "genus_name",
                          {}
                        )(
                          <AutoComplete
                            onSearch={this.handleName1Search}
                            onChange={this.handleName1Change}
                            placeholder=""
                          >
                            {name1Opts}
                          </AutoComplete>
                        )}
                      </span>
                      <span className="sectionGeography-tutude-btn">
                        <Button type="gbdb" onClick={this.searchGenus}>
                          <p>Search</p>
                        </Button>
                      </span>
                      <span className="sectionGeography-tutude-btn">
                        <Button type="gbdb" onClick={this.goAddOrigin}>
                          <p>Add new taxon</p>
                        </Button>
                      </span>
                    </div>
                  </FormItem>
                  <div>
                    {JSON.stringify(this.state.genusValue) !== "{}" ? (
                      <div className="revised-show-name">
                        <span>{this.state.genusValue.taxon_name1}</span>
                        <span> {this.state.genusValue.nomenclator},</span>
                        <span>  {this.state.genusValue.year}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="sectionGeography-tutude sectionGeography-tutude-title">
                    <div className="addOriginal-search">
                      Search original species
                    </div>
                  </div>
                  <FormItem label={<p>Original species name</p>}>
                    <div className="sectionGeography-tutude">
                      <span>
                        {getFieldDecorator(
                          "taxon_name1",
                          {}
                        )(
                          <AutoComplete
                            onSearch={this.handleName1Search}
                            onChange={this.handleName1Change}
                            placeholder=""
                          >
                            {name1Opts}
                          </AutoComplete>
                        )}
                      </span>
                      <span className="revised-tutude-title">
                        {getFieldDecorator(
                          "taxon_name2",
                          {}
                        )(
                          <AutoComplete
                            onSearch={this.handleName2Search}
                            onChange={this.handleName2Change}
                            placeholder=""
                          >
                            {name2Opts}
                          </AutoComplete>
                        )}
                      </span>
                    </div>
                  </FormItem>

                  <FormItem label={<p> Year</p>}>
                    {getFieldDecorator(
                      "year",
                      {}
                    )(
                      <div className="sectionGeography-tutude">
                        <span>
                          <Input placeholder=""></Input>
                        </span>
                      </div>
                    )}
                  </FormItem>
                  <FormItem label={<p>Reference</p>}>
                    
                      <div className="sectionGeography-tutude">
                        <span>
                        {getFieldDecorator(
                      "ref_id",
                      {}
                    )(
                          <InputNumber placeholder="" onFocus={(e)=>this.showSearchRef('ref_id',e)}></InputNumber>
                          )}
                        </span>
                      </div>
                   
                  </FormItem>

                  <FormItem label={<p>Nomenclator</p>}>
                    
                      <div className="sectionGeography-tutude">
                        <span>
                        {getFieldDecorator(
                      "nomenclator",
                      {}
                    )(
                          <AutoComplete
                            onSearch={this.handleNomSearch}
                            onChange={this.handleNomChange}
                            placeholder=""
                          >
                            {clatorOpts}
                          </AutoComplete>
                             )}
                        </span>

                        <span className="sectionGeography-tutude-btn">
                          <Button type="gbdb" onClick={this.searchOriginal }>
                            <p>Search</p>
                          </Button>
                        </span>
                        <span className="sectionGeography-tutude-btn" >
                          <Button type="gbdb" onClick={this.goAddOrigin}>
                            <p>Add new taxon</p>
                          </Button>
                        </span>
                      </div>
                 
                  </FormItem>
                  <div>
                    {JSON.stringify(this.state.originalValue) !== "{}" ? (
                      <div className="revised-show-name">
                        <span>{this.state.originalValue.taxon_name1}</span>
                        <span>{this.state.originalValue.taxon_name2}</span>
                        <span> {this.state.originalValue.nomenclator}</span>
                        <span>  {this.state.originalValue.year},</span>
                        <span>{this.state.originalValue.ref_id}</span>
                      
                        
                       
                      
                        
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="resource-line"></div>
                  <div className="sectionGeography-tutude sectionGeography-tutude-title"></div>
                  <FormItem label={<p>Ref.describing relation</p>}>
                   
                      <div className="sectionGeography-tutude">
                        <span>
                        {getFieldDecorator(
                      "ref_describing",
                      {}
                    )(
                      <InputNumber placeholder="" onFocus={(e)=>this.showSearchRef('ref_describing',e)}></InputNumber>
                          )}
                        </span>
                      </div>
                  
                  </FormItem>

                  <FormItem label={<p>Collect from Treatise?</p>}>
                   
                      <div className="sectionGeography-tutude">
                        <span>
                        {getFieldDecorator(
                      "from_treatise",
                      {}
                    )(
                          <Select>
                            {treatises.map((item, index) => {
                              return (
                                <Option value={item.name} key={item.name}>
                                  {item.name}
                                </Option>
                              );
                            })}
                          </Select>
                          )}
                        </span>
                        <span className="sectionGeography-tutude-btn">
                          <Button type="gbdb" onClick={this.previewTaxon }>
                            <p>Preview taxon</p>
                          </Button>
                        </span>
                      </div>
                    
                  </FormItem>
                </div>
                <div>
                  <div className="revised-show-name">Revised species name:</div>
                    {JSON.stringify(previewValue) !== "{}" ? (
                      <div className="revised-show-name">
                        <span>{previewValue.taxon_name1}</span>
                        <span>{previewValue.taxon_name2}</span>
                        {
                          previewValue.nomenclator||previewValue.year?
                          <span>({previewValue.nomenclator},{previewValue.year});</span>
                          :
                          null
                        }
                        {
                          previewValue.ref_first_author?
                          <span>from:{previewValue.ref_first_author},</span>
                          :
                          null
                        }
                       
                      
                        <span>{previewValue.ref_publication_year}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                <div className="referenceAdd-comments last-formItem">
                  <Form.Item label="Notes" className="long-label">
                    {getFieldDecorator("notes", {
                      rules: [{}]
                    })(<TextArea rows={6}></TextArea>)}
                  </Form.Item>
                </div>

                <div className="referenceSearch-btns taxonomy-btns">
                  <div>
                    <Button
                      type="gbdb"
                      onClick={() => {
                        this.addRevised();
                      }}
                    >
                      <p>Add</p>
                    </Button>
                  </div>
                </div>
                <div className="taxonomy-tips">
                  <div>Tips:</div>
                  <div>
                    1. "Originat name" means the name used while the taxon was
                    formally named For example.Climacograptus angustus Pemer.
                    1895 is an original name, and Normalograptus angustus
                    (Perner. 1895) is a revised name, white the latter indicates
                    that the species has been moved from genus Climacograptus to
                    Normalograptus after formally named.
                  </div>
                  <div>
                    2. Revised species name - Original name + Relation. So you
                    can also use the *Add relationship" interface to add revised
                    names if you know the original name and the relation, which
                    is extraordinarily fast if you need to input MANY revised
                    names under the same genus.
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          <ReferenceOption
            childError={this.childError}
            conditions={{}}
            ref="reference"
          ></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          <ReferenceTableResult
            searchParams={this.state.searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showRefDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailRefFormOk}
          onCancel={this.detailRefFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
            // searchSection={this.searchSection}
            onlySeeRef={true}
          ></ReferenceDetailForm>
        </Modal>

        {/*originalModal */}

        <Modal
          destroyOnClose
          visible={this.state.showTaxonResult}
          className="searchResultModal taxResultModal noBottomPadding tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleTaxonOk}
          onCancel={this.searchTaxonCancel}
        >
          <TaxonomyTableResult
            searchParams={this.state.searchParams}
            searchValues={this.state.searchValues}
            onTaxonomy={this.onTaxonomy}
            changeCheckSelected={this.changeCheckSelected}
            getDetail={this.TaxonomyDetail}
            checkOne={this.state.taxonType === "parent" ? true : false}
            checkTaxonomyOne={this.checkTaxonomyOne}
            checkOne={true}
          ></TaxonomyTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showTaxonomyDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailTaxonomyFormOk}
          onCancel={this.detailTaxonomyFormCancel}
        >
          <TaxonomyDetailForm
            detailData={this.state.detailData}
            onlySeeRef={true}
          ></TaxonomyDetailForm>
        </Modal>
      </div>
    );
  }
}
AddRevised = Form.create({})(AddRevised);
export default AddRevised;
