import React, { Component } from "react";

import "src/js/global.js";
import datas from "commons/data.js";
import Utils from "src/js/utils";
import dataApis from "src/js/apis/data";
import Apis from "src/js/apis/api";

import "./index.css";
import { withRouter } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Modal,
  DatePicker,
  InputNumber,
  AutoComplete
} from "antd";
const { Option } = Select;
const FormItem = Form.Item;

let {
  preservation,
  temporals,
  spatials,
  stattens,
  orientations,
  sediments,
  sortings,
  fragmentations,
  bioerosions
} = datas;
@withRouter
class CollectionOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizers: [],
      enterers: [],
      errorRef: false,
      conditions: props.conditions || {},

      Countrys: [],
      Provinces: [],
      Villages: [],
      Locations: [],
      allChildError: {},
      reffrom: props.conditions ? props.conditions.ref_id_from : "",
      refto: props.conditions ? props.conditions.ref_id_to : "",
      collfrom: props.conditions ? props.conditions.coll_id_from : "",
      collto: props.conditions ? props.conditions.coll_id_to : "",
      collnofrom: props.conditions ? props.conditions.coll_no_from : "",
      collnoto: props.conditions ? props.conditions.coll_no_to : ""
    };
  }
  componentDidMount = () => {};
  publishYear = value => {};
  geologySearch = async (value, type, key) => {
    let params = {
      attributes: [key],
      [key]: value
      // [key]: {
      //   condition: "includes",
      //   value
      // }
    };

    let geoSearchBack = await Apis.autoCompleteGeology(params);

    let searchReust = geoSearchBack.result || [];
    let resultArray = [];

    searchReust.map((item, index) => {
      resultArray.push(item[key]);
    });

    this.setState({
      [type]: resultArray
    });
  };
  geologyChange = (value, field) => {
    this.props.form.setFieldsValue({
      [field]: value
    });
  };
  handleNomSearch = async (value, type) => {
    if (value) {
      let searchResult;
      if (type === "authorizers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteExperts(params);
      } else if (type === "enterers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteEnterer(params);
      }

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });

        return;
      } else if (searchResult.result) {
        this.setState(
          {
            [type]: searchResult.result
          },
          () => {}
        );
      }
    } else {
      this.setState({ [type]: [] });
    }
  };

  handleNomChange = (value, type) => {
    this.props.form.setFieldsValue({
      [type]: value
    });
  };
  onStartChange = value => {
    this.onTimeChange("startValue", value);
  };
  onEndChange = value => {
    this.onTimeChange("endValue", value);
  };
  onTimeChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  sectionStartChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  sectionEndChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  onSectionChange=()=>{}
  onSectionChange1 = (value, field, type) => {
    let stateType = field + type;
    let { allChildError } = this.state;
    this.setState(
      {
        [stateType]: value
      },
      () => {
        let compareFrom = field + "from";
        let compareTo = field + "to";

        let compareOne = this.state[compareFrom];
        let compareTwo = this.state[compareTo];

        let errorType = "error" + field;
        let helpType = "help" + field;
        if (
          (Utils.isEmpty(compareOne) && !Utils.isEmpty(compareTwo)) ||
          (Utils.isEmpty(compareTwo) && !Utils.isEmpty(compareOne))
        ) {
          this.setState(
            {
              [errorType]: true,
              [helpType]: "Start and end data need to be filled in"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
        } else if (compareTwo && compareOne && compareTwo < compareOne) {
          this.setState(
            {
              [errorType]: true,
              [helpType]: "The starting value is less than the ending value"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
        } else {
          this.setState(
            {
              [errorType]: false,
              [helpType]: ""
            },
            () => {
              if (allChildError[errorType]) {
                delete allChildError[errorType];
              }
            }
          );
        }
      }
    );
  };

  render() {
    let { conditions, Countrys, Provinces, Villages, Locations } = this.state;

    const { getFieldDecorator } = this.props.form;
    const authorizers = this.state.authorizers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    const enterers = this.state.enterers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    return (
      <div id="main" className="reference-search-form">
        <div className="searchSection-main  reference-search-form search-common-form">
          <div className="reference-search-content reference-search-baseform collction-options">
        <Form>
          <div>
            <div className="referenceSearch-base">
              <div className="referenceSearch-autor referenceSearch-line">
              <div className="referenceAdd-author-title" >Collection ID</div>
                <div className="referenceSearch-author searchReference-reference">
                  <span className="search-line-pre">
                    <FormItem
                      label=""
                      help={this.state.helpcoll}
                      validateStatus={
                        this.state.errorcoll ? "error" : "success"
                      }
                    >
                      {getFieldDecorator("coll_id_from", {
                        rules: [
                          {
                            type: "number"
                          }
                        ],
                        initialValue: conditions ? conditions.coll_id_from : ""
                      })(
                        <InputNumber
                          min={1}
                          placeholder=""
                          onChange={value =>
                            this.sectionStartChange(value, "coll", "from")
                          }
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span className="search-line">
                    <span className="iconfont icon-line1"></span>
                  </span>
                  <span>
                    <FormItem
                      label=""
                      help={this.state.helpcoll}
                      validateStatus={
                        this.state.errorcoll ? "error" : "success"
                      }
                    >
                      {getFieldDecorator("coll_id_to", {
                        rules: [
                          {
                            type: "number"
                          }
                        ],
                        initialValue: conditions ? conditions.coll_id_to : ""
                      })(
                        <InputNumber
                          min={1}
                          onChange={value =>
                            this.sectionEndChange(value, "coll", "to")
                          }
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                </div>
              </div>
            </div>
            <div className="referenceSearch-base">
              <div className="referenceSearch-autor referenceSearch-line">
              <div className="referenceAdd-author-title" >Reference ID</div>
                <div className="referenceSearch-author searchReference-reference">
                  <span className="search-line-pre">
                    <FormItem
                      label=""
                      help={this.state.helpref}
                      validateStatus={this.state.errorref ? "error" : "success"}
                    >
                      {getFieldDecorator("ref_id_from", {
                        rules: [
                          {
                            type: "number"
                          }
                        ],
                        initialValue: conditions ? conditions.ref_id_from : ""
                      })(
                        <InputNumber
                          min={1}
                          onChange={value =>
                            this.sectionEndChange(value, "ref", "from")
                          }
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span className="search-line">
                    <span className="iconfont icon-line1"></span>
                  </span>
                  <span>
                    <FormItem
                      label=""
                      help={this.state.helpref}
                      validateStatus={this.state.errorref ? "error" : "success"}
                    >
                      {getFieldDecorator("ref_id_to", {
                        rules: [
                          {
                            type: "number"
                          }
                        ],
                        initialValue: conditions ? conditions.ref_id_to : ""
                      })(
                        <InputNumber
                          min={1}
                          onChange={value =>
                            this.sectionEndChange(value, "ref", "to")
                          }
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                </div>
              </div>
            </div>
            <div className="referenceSearch-base">
              <div className="referenceSearch-autor searchReference-book referenceSearch-line">
              <div className="referenceAdd-author-title" >Input date</div>
                  <div className="referenceSearch-author">
                    <span className="search-line-pre">
                    <FormItem label="">
                      {getFieldDecorator("createdAt_from", {
                        rules: [
                          { type: "object", message: "Please select time!" }
                        ],
                        initialValue: conditions
                          ? conditions.createdAt_from
                          : ""
                      })(
                        <DatePicker
                          disabledDate={this.disabledStartDate}
                          format="YYYY-MM-DD"
                          onChange={this.onStartChange}
                        ></DatePicker>
                      )}
                       </FormItem>
                    </span>
                    <span className="search-line">
                      <span className="iconfont icon-line1"></span>
                    </span>
                    <span>
                    <FormItem label="">
                      {getFieldDecorator("createdAt_to", {
                        rules: [
                          { type: "object", message: "Please select time!" }
                        ],

                        initialValue: conditions ? conditions.createdAt_to : ""
                      })(
                        <DatePicker
                          disabledDate={this.disabledEndDate}
                          format="YYYY-MM-DD"
                          onChange={this.onEndChange}
                        ></DatePicker>
                      )}
                       </FormItem>
                    </span>
                  </div>
               
              </div>
            </div>

            <div className="referenceSearch-base">
              <div className="referenceSearch-autor referenceSearch-line">
              <div className="referenceAdd-author-title" >Coll Number</div>
                <div className="referenceSearch-author searchReference-reference">
                  <span className="search-line-pre">
                    <FormItem
                      label=""
                      help={this.state.helpcollno}
                      validateStatus={
                        this.state.errorcoll ? "error" : "success"
                      }
                    >
                      {getFieldDecorator("coll_no_from", {
                        rules: [
                          {
                            type: "number"
                          }
                        ],
                        initialValue: conditions ? conditions.coll_no_from : ""
                      })(
                        <InputNumber
                          min={1}
                          placeholder=""
                          onChange={value =>
                            this.sectionStartChange(value, "collno", "from")
                          }
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span className="search-line">
                    <span className="iconfont icon-line1"></span>
                  </span>
                  <span>
                    <FormItem
                      label=""
                      help={this.state.helpcollno}
                      validateStatus={
                        this.state.errorcollno ? "error" : "success"
                      }
                    >
                      {getFieldDecorator("coll_no_to", {
                        rules: [
                          {
                            type: "number"
                          }
                        ],
                        initialValue: conditions ? conditions.coll_no_to : ""
                      })(
                        <InputNumber
                          min={1}
                          onChange={value =>
                            this.sectionEndChange(value, "collno", "to")
                          }
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="referenceSearch-numberProject">
              <div className="Reference-number  search-two">
                <Form.Item label="Authorizer">
                  {getFieldDecorator("authorizer_id", {
                    rules: [{}],
                    initialValue: conditions ? conditions.authorizer_id : ""
                  })(
                    <AutoComplete
                      onSearch={value =>
                        this.handleNomSearch(value, "authorizers")
                      }
                      onChange={value =>
                        this.handleNomChange(value, "authorizer_id")
                      }
                      placeholder=""
                    >
                      {authorizers}
                    </AutoComplete>
                  )}
                </Form.Item>
              </div>
              <div className="referenceSearch-no">
                <Form.Item label="Enterer">
                  {getFieldDecorator("enterer", {
                    rules: [],
                    initialValue: conditions ? conditions.enterer : ""
                  })(
                    <AutoComplete
                      onSearch={value =>
                        this.handleNomSearch(value, "enterers")
                      }
                      onChange={value => this.handleNomChange(value, "enterer")}
                      placeholder=""
                    >
                      {enterers}
                    </AutoComplete>
                  )}
                </Form.Item>
              </div>
            </div>
            {/* <div className="referenceSearch-numberProject referenceSearch-location">
              <div className="Reference-number">
                <Form.Item label="Country / Continent">
                  {getFieldDecorator("geology_country", {
                    rules: [{}],
                    initialValue: ""
                  })(
                    <AutoComplete
                      onSearch={value =>
                        this.geologySearch(value, "Countrys", "geology_country")
                      }
                      onChange={value =>
                        this.geologyChange(value, "geology_country")
                      }
                      placeholder=""
                    >
                      {Countrys.map((item, index) => {
                        return <Option value={item}>{item}</Option>;
                      })}
                    </AutoComplete>
                  )}
                </Form.Item>
              </div>
              <div className="referenceSearch-no">
                <Form.Item label="Province / State">
                  {getFieldDecorator("geology_province", {
                    rules: [{}],
                    initialValue: ""
                  })(
                    <AutoComplete
                      onSearch={value =>
                        this.geologySearch(
                          value,
                          "Provinces",
                          "geology_province"
                        )
                      }
                      onChange={value =>
                        this.geologyChange(value, "geology_province")
                      }
                      placeholder=""
                    >
                      {Provinces.map((item, index) => {
                        return <Option value={item}>{item}</Option>;
                      })}
                    </AutoComplete>
                  )}
                </Form.Item>
              </div>
            </div>

            <div className="referenceSearch-numberProject referenceSearch-location">
              <div className="Reference-number">
                <Form.Item label="City / Village">
                  {getFieldDecorator("geology_village", {
                    rules: [{}],
                    initialValue: ""
                  })(
                    <AutoComplete
                      onSearch={value =>
                        this.geologySearch(value, "Villages", "geology_village")
                      }
                      onChange={value =>
                        this.geologyChange(value, "geology_village")
                      }
                      placeholder=""
                    >
                      {Villages.map((item, index) => {
                        return <Option value={item}>{item}</Option>;
                      })}
                    </AutoComplete>
                  )}
                </Form.Item>
              </div>
              <div className="referenceSearch-no">
                <Form.Item label="Location">
                  {getFieldDecorator("geology_location", {
                    initialValue: ""
                  })(
                    <AutoComplete
                      onSearch={value =>
                        this.geologySearch(
                          value,
                          "Locations",
                          "geology_location"
                        )
                      }
                      onChange={value =>
                        this.geologyChange(value, "geology_location")
                      }
                      placeholder=""
                    >
                      {Locations.map((item, index) => {
                        return <Option value={item}>{item}</Option>;
                      })}
                    </AutoComplete>
                  )}
                </Form.Item>
              </div>
            </div> */}
 

            <div className="referenceAdd-journal">
              <Form.Item
                label={
                  <div className="sectionGeography-scale">
                    <p className="lineNormal">Modes of preservation</p>
                  </div>
                }
              >
                {getFieldDecorator("coll_preservation", {
                  rules: [{}],
                  initialValue: ""
                })(
                  <Select placeholder="">
                    {preservation.map((item, index) => {
                      return (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div className="referenceAdd-journal">
              <Form.Item
                label={
                  <div className="sectionGeography-scale">
                    <p className="lineNormal">Original biominerals</p>
                  </div>
                }
              >
                {getFieldDecorator("coll_biominerals", {
                  rules: [{}],
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </div>

            <div className="referenceAdd-journal">
              <Form.Item
                label={
                  <div className="sectionGeography-scale">
                    <p className="lineNormal">Replacement Minernals</p>
                  </div>
                }
              >
                {getFieldDecorator("coll_minerals", {
                  rules: [{}],
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </div>

            <div className="referenceAdd-journal">
              <Form.Item
                label={
                  <div className="sectionGeography-scale">
                    <p className="lineNormal">Special modes of occurrence</p>
                  </div>
                }
              >
                {getFieldDecorator("coll_occurrence", {
                  rules: [{}],
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </div>

            <div className="referenceAdd-journal">
              <Form.Item
                label={
                  <div className="sectionGeography-scale">
                    <p className="lineNormal">Temporal Resolution</p>
                  </div>
                }
              >
                {getFieldDecorator("coll_tmporal", {
                  rules: [{}],
                  initialValue: ""
                })(
                  <Select placeholder="">
                    {temporals.map((item, index) => {
                      return (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="referenceAdd-journal">
              <Form.Item
                label={
                  <div className="sectionGeography-scale">
                    <p className="lineNormal">Spatial resolution</p>
                  </div>
                }
              >
                {getFieldDecorator("coll_resolution", {
                  rules: [{}],
                  initialValue: ""
                })(
                  <Select placeholder="">
                    {spatials.map((item, index) => {
                      return (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div className="referenceAdd-title">
              <Form.Item label={<p>Lager statten type</p>}>
                {getFieldDecorator("coll_type", {
                  rules: [{}],
                  initialValue: ""
                })(
                  <Select placeholder="">
                    {stattens.map((item, index) => {
                      return (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div className="referenceAdd-journal">
              <Form.Item
                label={
                  <div className="sectionGeography-scale">
                    <p className="lineNormal">Degree of Concentration</p>
                  </div>
                }
              >
                {getFieldDecorator("coll_concentration", {
                  rules: [{}],
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </div>

            <div className="referenceAdd-title">
              <Form.Item label={<p>Spatial orientation</p>}>
                {getFieldDecorator("coll_orientation", {
                  rules: [{}],
                  initialValue: ""
                })(
                  <Select placeholder="">
                    {orientations.map((item, index) => {
                      return (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="referenceAdd-title">
              <Form.Item label={<p>Anatomical detail</p>}>
                {getFieldDecorator("coll_detail", {
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </div>

            <div className="referenceAdd-journal">
              <Form.Item
                label={
                  <div className="sectionGeography-scale">
                    <p className="lineNormal">Abundance in sediment</p>
                  </div>
                }
              >
                {getFieldDecorator("coll_sediment", {
                  rules: [{}],
                  initialValue: ""
                })(
                  <Select placeholder="">
                    {sediments.map((item, index) => {
                      return (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div className="referenceAdd-title">
              <Form.Item label={<p>Size sorting</p>}>
                {getFieldDecorator("coll_sorting", {
                  rules: [{}],
                  initialValue: ""
                })(
                  <Select placeholder="">
                    {sortings.map((item, index) => {
                      return (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div className="referenceAdd-title">
              <Form.Item label={<p>Fragmentation</p>}>
                {getFieldDecorator("coll_fragmentation", {
                  rules: [{}],
                  initialValue: ""
                })(
                  <Select placeholder="">
                    {fragmentations.map((item, index) => {
                      return (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div className="referenceAdd-title">
              <Form.Item label={<p>Bioerpsion</p>}>
                {getFieldDecorator("coll_Bioerosion", {
                  rules: [{}],
                  initialValue: ""
                })(
                  <Select placeholder="">
                    {bioerosions.map((item, index) => {
                      return (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div className="referenceAdd-title">
              <Form.Item label={<p>Encrustation</p>}>
                {getFieldDecorator("coll_Encrustation", {
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </div>

            <div className="referenceAdd-title">
              <Form.Item
                label={<p className="lineNormal">Comments on preservation</p>}
              >
                {getFieldDecorator("coll_preservations", {
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </div>
            <div className="referenceAdd-title">
              <Form.Item label={<p>Size classes</p>}>
                {getFieldDecorator("coll_classes", {
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </div>

            <div className="referenceAdd-title">
              <Form.Item
                label={<p className="lineNormal">Feeding/predation traces</p>}
              >
                {getFieldDecorator("coll_traces", {
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </div>

            <div className="referenceAdd-title collectionAdd-comments">
              <Form.Item
                label={
                  <p className="lineNormal">Comments on included components</p>
                }
              >
                {getFieldDecorator("coll_components", {
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </div>
            </div>
          </Form>
       
        </div>
       </div>
      </div>
    );
  }
}

CollectionOption = Form.create({})(CollectionOption);
export default CollectionOption;
