import React, { Component } from "react";
import "./index.scss";
import "src/js/global.js";

export default class Group extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="my-insects">
        {/* <div className= "title">Ancient insects</div> */}
        {this.props.children}
      </div>
    );
  }
}
