import React, { Component } from "react";
import Utils from "src/js/utils";
import Storage from "src/js/storage";
import Apis from "src/js/apis/api";

import { Form, Select, Button, Modal, InputNumber, Input } from "antd";

const { Option } = Select;

const FormItem = Form.Item;
let getGeochemistrytype = async () => {
  let GeochemistrytypeBack = await Apis.getGeochemistrytype({});
  if (GeochemistrytypeBack.result) {
    return GeochemistrytypeBack.result;
  } else {
    return [];
  }
};
class AddGeochemicalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inheritRefIds: props.inheritRefIds || "",
      geochemistryoccurrenceId: props.geochemistryoccurrenceId || "",
      geochemistryoccurrenceItemId: props.geochemistryoccurrenceItemId || "",
      geochemicalDataDetail: {},
      canModify: props.canModify === true ? true : false,
      isAdd: props.isAdd === true ? true : false,
      geochemistryoccurrenceData: props.geochemistryoccurrenceData || {}
    };
  }
  async componentDidMount() {
    let { geochemistryoccurrenceItemId, geochemistryoccurrenceId } = this.state;

    let Geochemistrytype = await getGeochemistrytype();
    this.setState({
      Geochemistrytype
    });
    //////无接口///需要
  
    if (geochemistryoccurrenceItemId !== '') {

      let params = {
        id: geochemistryoccurrenceItemId
      };
      let getGeochemicalsDataBack = await Apis.searchDataById(params);

      if (getGeochemicalsDataBack.result && getGeochemicalsDataBack.result.length > 0) {
        this.setState({
          geochemicalDataDetail: getGeochemicalsDataBack.result[0]
        });
      } else {
        this.setState({
          geochemicalDataDetail: {}
        });
      }
    }
    // 获取数据//////无接口///需要
    if (geochemistryoccurrenceId !== '') {
      let params = {
        id: geochemistryoccurrenceId
      };
      let getGeochemicalsDataBack = await Apis.searchGeochemicalOccurrence(params);

      if (getGeochemicalsDataBack.result && getGeochemicalsDataBack.result.length > 0) {
        this.setState({
          geochemistryoccurrenceData: getGeochemicalsDataBack.result[0]
        });
      } else {
        this.setState({
          geochemistryoccurrenceData: {}
        });
      }
    }
  }
  changeUnit = (value, type) => {
    let result = 0
    value = parseFloat(value)
    if (type === "inches") {
      result = value * 0.0254
    } else if (type === "cm") {
      result = value / 100
    } else if (type === "feet") {
      result = value * 0.3048
    }
    return result

  }
  createGeochemData = () => {
    let { geochemistryoccurrenceData } = this.state;

    // 获取Geochemical Form数据
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
 
        
        let addGeochemicalData = Utils.filterParams(values);
        addGeochemicalData = Utils.filterCondition(addGeochemicalData);
        addGeochemicalData = Utils.addEnterer(addGeochemicalData);

        addGeochemicalData = Utils.addAudit(addGeochemicalData, geochemistryoccurrenceData)
        addGeochemicalData.geochemistryoccurrence_id = this.state.geochemistryoccurrenceId;

        let createGeochemDataBack = await Apis.createGeochemData(
          addGeochemicalData
        );
        if (createGeochemDataBack.result) {
          Modal.success({
            title: "This is a successful message",
            content: "add GeochemicalData successfully"
          });
          this.props.form.resetFields();
        } else {
          Modal.error({
            title: "This is an error message",
            content: createGeochemDataBack.error
          });
        }
      }
    });
  };
  modifyGeochemData = async () => {
    let { geochemicalDataDetail } = this.state;
    // 获取Geochemical Form数据
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
     

        let addGeochemicalData = Utils.filterCondition(values);
        addGeochemicalData = Utils.addEnterer(addGeochemicalData);
        addGeochemicalData.id = geochemicalDataDetail.id;
        addGeochemicalData.geochemistryoccurrence_id = this.state.geochemistryoccurrenceItemId;

        let modifyGeochemDataBack = await Apis.changeGeochemicalDataById(
          addGeochemicalData
        );
        if (modifyGeochemDataBack.result) {
          Modal.success({
            title: "This is a successful message",
            content: "change GeochemicalData successfully"
          });
          this.props.changeGeochemicalData(modifyGeochemDataBack.result);
        } else {
          Modal.error({
            title: "This is an error message",
            content: modifyGeochemDataBack.error
          });
        }
      }
    });
  };
  render() {
    let { getFieldDecorator } = this.props.form;
    let { Geochemistrytype, geochemicalDataDetail, isAdd, canModify } = this.state;

    let isClick = false;
    let userid = Storage.getItem("gbdbid");
    let { enterer } = geochemicalDataDetail;
    if (enterer && userid && enterer === userid) {
      isClick = true;
    }
    if ((isClick === false) && (isAdd === false) && (canModify == true)) {
      getFieldDecorator = (...rest) => {
        return element => {
          let NewElement = React.cloneElement(element, {
            disabled: true
          });
          return this.props.form.getFieldDecorator(...rest)(NewElement);
        };
      };
    }
    return (
      <div className="occurance-add-form">
        <div className="geochemical-main-form">
          <Form>
            <div className="referenceSearch-book">
              <Form.Item label="Geochemical data type" >
                {getFieldDecorator("geochemistry_type", {
                  rules: [{}],
                  initialValue: JSON.stringify(geochemicalDataDetail)!=="{}"
                    ? geochemicalDataDetail.geochemistry_type
                    : ""
                })(<Select>{Utils.fossilGroup(Geochemistrytype)}</Select>)}
              </Form.Item>
            </div>

            {/* <div className="referenceAdd-authours">
              <FormItem label="Reference ID" className="long-label">
                <div className="referenceAdd-author ">
                  <span>
                    {getFieldDecorator("ref_id", {
                      rules: [
                        {
                          required: true,

                          type: "number"
                        }
                      ],
                      initialValue: inheritRefIds ? Number(inheritRefIds) :geochemicalDataDetail.ref_id?geochemicalDataDetail.ref_id: ''
                    })(<Input disabled></Input>)}
                  </span>
                </div>
              </FormItem>
            </div> */}

            <div className="referenceSearch-base">
              <div className="referenceSearch-autor searchReference-book   referenceSearch-line">
                <div className="referenceAdd-depth-title" >Depth</div>
                <div className="referenceSearch-author">
                  <span className="search-line-pre">
                    <FormItem label="">
                      {getFieldDecorator("depthfrom", {
                        rules: [{ type: "number" }],
                        initialValue: JSON.stringify(geochemicalDataDetail)!=="{}"
                          ? geochemicalDataDetail.depthfrom
                          : null
                      })(<InputNumber placeholder=""></InputNumber>)}
                    </FormItem>
                  </span>
                  <span className="search-line">
                    <span className="iconfont icon-line1"></span>
                  </span>
                  <span>
                    <FormItem label=" ">
                      {getFieldDecorator("depthto", {
                        rules: [{ type: "number" }],
                        initialValue: JSON.stringify(geochemicalDataDetail)!=="{}"
                          ? geochemicalDataDetail.depthto
                          : null
                      })(<InputNumber placeholder=""></InputNumber>)}
                    </FormItem>
                  </span>
                </div>
              </div>
            </div>

            <div className="referenceAdd-authours">
              <div className="referenceAdd-author ">
                <FormItem label="Depth unit" className="long-label">
                  {getFieldDecorator(`depth_unit`, {
                    initialValue: JSON.stringify(geochemicalDataDetail)!=="{}"
                      ? geochemicalDataDetail.depth_unit
                      : 'meters'
                  })(
                    <Select name="scDepthUnit" id="scDepthUnit">

                      <Option value="meters">meters</Option>
                      <Option value="inches">inches</Option>
                      <Option value="cm">cm</Option>
                      <Option value="feet">feet</Option>
                    </Select>
                  )}
                </FormItem>
              </div>
            </div>

            <div className="referenceAdd-authours">
              <div className="referenceAdd-author ">
                <FormItem label="Value" className="long-label">
                  {getFieldDecorator("value", {
                    rules: [
                      {
                        required: true,

                        type: "number"
                      }
                    ],
                    initialValue: JSON.stringify(geochemicalDataDetail)!=="{}"
                      ? geochemicalDataDetail.value
                      : ""
                  })(<InputNumber placeholder=""></InputNumber>)}
                </FormItem>
              </div>
            </div>

            <div className="referenceAdd-authours">
              <div className="referenceAdd-author ">
                <FormItem label="Unit" className="long-label">
                  {getFieldDecorator(`value_unit`, {
                    rules: [
                      {
                        required: true,
                        message: "Please select the Unit"
                      }
                    ],
                    initialValue: JSON.stringify(geochemicalDataDetail)!=="{}"
                      ? geochemicalDataDetail.value_unit
                      : ""
                  })(
                    <Select name="scValueUnit" id="scValueUnit">
                      {/* <Option value="0"></Option>
                        <Option value="121">%</Option>
                        <Option value="122">‰</Option>
                        <Option value="123">ppm</Option>
                        <Option value="124">ppb</Option>
                        <Option value="125">mg/g</Option>
                        <Option value="126">°C</Option> */}
                      {/* <Option value="0"></Option> */}
                      <Option value="%">%</Option>
                      <Option value="‰">‰</Option>
                      <Option value="ppm">ppm</Option>
                      <Option value="ppb">ppb</Option>
                      <Option value="mg/g">mg/g</Option>
                      <Option value="°C">°C</Option>
                    </Select>
                  )}
                </FormItem>
              </div>
            </div>

            <div className="referenceAdd-authours">
              <div className="referenceAdd-author ">
                <FormItem label="Standard deviation" className="long-label">
                  {getFieldDecorator(`standard_deviation`, {
                    initialValue: JSON.stringify(geochemicalDataDetail)!=="{}"
                      ? geochemicalDataDetail.standard_deviation
                      : null,
                    rules: [
                      {

                        // type: "number"
                      }
                    ],
                    // })(<InputNumber placeholder=""></InputNumber>)}
                  })(<Input placeholder=""></Input>)}
                </FormItem>
              </div>
            </div>

            <div className="referenceSearch-btns">
              <div>
                {canModify ? (
                  <FormItem>
                    <Button type="gbdb" onClick={this.modifyGeochemData}>
                      <p> Save modify</p>
                    </Button>
                  </FormItem>
                ) : isAdd ? (
                  <FormItem>
                    <Button type="gbdb" onClick={this.createGeochemData}>
                      <p> Add</p>
                    </Button>
                  </FormItem>
                ) :
                    null}
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
AddGeochemicalForm = Form.create({})(AddGeochemicalForm);
export default AddGeochemicalForm;
