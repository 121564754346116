import React, { Component } from "react";
import { Form, Input, Button, Modal, InputNumber } from "antd";
import BaseUpload from "commons/BaseUpload/index";
import Utils from "src/js/utils";
import Apis from "src/js/apis/api";
import Storage from "src/js/storage";
import "./index.css";
import "src/js/global.js";
import Users from "src/js/users";
const { TextArea } = Input;
const FormItem = Form.Item;

class YanXin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameOpts: [],
      clatorOpts: [],
      refSearch: false,
      uploadFiles: [],
     
    };
  }
  isGbdbUser = async () => {
    let Inputerid=false
    let token = Storage.getItem("token");
     Inputerid = await Users.isGbdbInputer();
  

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    } else {
      if (Inputerid == false) {
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title: "Sorry!!",
            content: "You do not have permission to add data"
          });
          this.props.history.push("/home");
        }
      }
    }
  };
  componentDidMount = () => {
    this.isGbdbUser();
  };
  addYanXin = () => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let searchResults = Utils.filterParams(values);

        let searchValues = Utils.filterCondition(searchResults);
        searchValues = Utils.addEnterer(searchValues);

        const formData = new FormData();
        for (let [key, value] of Object.entries(searchValues)) {
          formData.append(key, value);
        }
        let { uploadFiles } = this.state;
        
        if (uploadFiles.length > 0) {
          uploadFiles.map((item, index) => {
            formData.append("files", item);
          });
        }
        let coreimageBack = await Apis.createCoreimage(formData);
        if (coreimageBack.result) {
          Modal.success({
            title: "This is a success message",
            content: "Your information added successfully"
          });
          this.props.form.resetFields();
          this.setState({
            uploadFiles: []
          });
        } else {
          Modal.error({
            title: "This is an error message",
            content: coreimageBack.error
          });
        }
      }
    });
  };
  onChangeFile = file => {
    let newest = file;

    this.setState(
      {
        uploadFiles: [newest]
      },
      () => {

      }
    );
  };
  removeFile = (file, index) => {
    let { uploadFiles } = this.state;
    uploadFiles.splice(index, 1);
    this.setState({
      uploadFiles
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="huici" className="taxonomy-common">
        <div className="taxonomy-common-title form-common-title">
          添加岩心图像
        </div>
        <div className="huici-content">
          <div className="reference-search-form addHigther-form addHuici-form AddYanxin-form">
            <Form>
              <div className="referenceSearch-numberProject">
                <div className="Reference-number">
                  <Form.Item label={<span>井号</span>}>
                    {getFieldDecorator("JH", {
                      rules: [
                        {
                          required: true,
                          message: "wrong input"
                        }
                      ],
                      initialValue: ""
                    })(<Input></Input>)}
                  </Form.Item>
                </div>
                <div className="referenceSearch-no">
                  <Form.Item label={<span>回次</span>}>
                    {getFieldDecorator("QXCS", {
                      rules: [
                        {
                          required: true,
                          message: "wrong input",
                          type: "number"
                        }
                      ],
                      initialValue: ""
                    })(<InputNumber></InputNumber>)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceSearch-numberProject">
                <div className="Reference-number">
                  <Form.Item label={<span>起始深度</span>}>
                    {getFieldDecorator("QSSD", {
                      rules: [
                        {
                          required: true,
                          message: "wrong input",
                          type: "number"
                        }
                      ],
                      initialValue: ""
                    })(<InputNumber></InputNumber>)}
                  </Form.Item>
                </div>
                <div className="referenceSearch-no">
                  <Form.Item label={<span>终止深度</span>}>
                    {getFieldDecorator("ZZSD", {
                      rules: [
                        {
                          required: true,
                          type: "number",
                          message: "wrong input"
                        }
                      ],
                      initialValue: ""
                    })(<InputNumber></InputNumber>)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceSearch-numberProject">
                <div className="Reference-number">
                  <Form.Item label={<span>井长</span>}>
                    {getFieldDecorator("JC", {
                      rules: [
                        {
                          required: true,
                          type: "number",
                          message: "wrong input"
                        }
                      ],
                      initialValue: ""
                    })(<InputNumber></InputNumber>)}
                  </Form.Item>
                </div>
                <div className="referenceSearch-no">
                  <Form.Item label={<span>岩心长度</span>}>
                    {getFieldDecorator("SMXC", {
                      rules: [
                        {
                          type: "number",
                          required: true,
                          message: "wrong input"
                        }
                      ],
                      initialValue: ""
                    })(<InputNumber></InputNumber>)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceSearch-numberProject">
                <div className="Reference-number">
                  <Form.Item label={<p>CW</p>}>
                    {getFieldDecorator("CW", {
                      // rules: [{ type: "number" }],
                      initialValue: ""
                    })(<Input></Input>)}
                  </Form.Item>
                </div>
                <div className="referenceSearch-no">
                  <Form.Item label={<p>外表面</p>}>
                    {getFieldDecorator("WBM", {
                      // rules: [{ type: "number" }],
                      initialValue: ""
                    })(<Input></Input>)}
                  </Form.Item>
                </div>
              </div>
              <div className="referenceSearch-numberProject">
                <div className="Reference-number">
                  <Form.Item label={<span>纵切面</span>}>
                    {getFieldDecorator("vertical_section", {
                      rules: [
                        {
                          required: true,
                          message: "wrong input"
                        }
                      ],
                      initialValue: ""
                    })(<Input></Input>)}
                  </Form.Item>
                </div>
                <div className="referenceSearch-no">
                  <Form.Item label={<span>FZJD</span>}>
                    {getFieldDecorator("FZJD", {
                      // rules: [{ type: "number" }],
                      initialValue: ""
                    })(<Input></Input>)}
                  </Form.Item>
                </div>
              </div>
              <div className="referenceAdd-upload">
                <Form.Item
                  label="ZQM"
                  extra=""
                  key="ref_file_path"
                  className="long-label"
                >
                  {getFieldDecorator("ZQM", {
                    // initialValue: this.state.uploadFiles
                  })(
                    <BaseUpload
                    uploadFiles={this.state.uploadFiles}
                    fileList={this.state.uploadFiles}
                      multiple={false}
                      showImg={false}
                      listType="text"
                      showUploadList={true}
                      onChangeFile={file => this.onChangeFile(file)}
                      removeFile={(file, index) => this.removeFile(file, index)}
                    ></BaseUpload>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-comments">
                <Form.Item
                  label="备注"
                  key="ref_comments"
                  className="long-label"
                >
                  {getFieldDecorator("BZ", {
                    rules: [{}],
                    initialValue: ""
                  })(<TextArea rows={6}></TextArea>)}
                </Form.Item>
              </div>
              <div className="referenceSearch-btns taxonomy-btns">
                <div>
                  <FormItem>
                    <Button
                      type="gbdb"
                      onClick={() => {
                        this.addYanXin();
                      }}
                    >
                      <p>提交</p>
                    </Button>
                  </FormItem>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
YanXin = Form.create({})(YanXin);
export default YanXin;
