import React, { Component } from "react";
import { Menu, InputNumber } from "antd";
import { NavLink } from "react-router-dom";
import "./index.css";
import YearPicker from "commons/YearPicker/index";
import dataApis from "src/js/apis/data";
import Utils from "src/js/utils";
import { Form, Input, Select, Button, Modal, AutoComplete } from "antd";
import Storage from "src/js/storage";
const { Option } = Select;
const FormItem = Form.Item;

let refProjects = [
  "journal article",
  "book",
  "edit book",
  "book chapter",
  "Ph. D. dissertation",
  "master thesis",
  "paper presented at meeting",
  "abstract",
  "technical report"
];

class ReferenceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey:
        this.props.location.pathname.split("/")[
        this.props.location.pathname.split("/").length - 1
        ] || "reference",
      authorizers: [],
      enterers: [],
      yearValue: null
    };
  }
  addKey = e => {
    this.setState(
      {
        currentKey: e.key
      },
      () => { }
    );
  };
  isGbdbUser = () => {
    let token = Storage.getItem("token");

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    }
  };
  componentDidMount = () => {
    this.isGbdbUser();
    let url = this.props.location;
    if (url) {
      let pathname = url.pathname;
      let all = pathname.split("/");
      let keyValue = all[all.length - 1];
      this.setState(
        {
          currentKey: keyValue
        },
        () => { }
      );
    }
  };

  handleSubmit = () => {

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let searchValues = Utils.filterParams(values);
        let searchResult = Utils.filterCondition(searchValues);

        let queryParam = {
          searchParams: searchResult,
          type: this.state.currentKey
        };
        let url = "/references/allresult/" + this.state.currentKey;
        this.props.history.push({ pathname: url, query: queryParam });
      }
    });
  };

  handleNomSearch = async (value, type) => {
    if (value) {
      let searchResult;
      if (type === "authorizers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteExperts(params);
      } else if (type === "enterers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteEnterer(params);
      }

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });

        return;
      } else if (searchResult.result) {
        this.setState(
          {
            [type]: searchResult.result
          },
          () => { }
        );
      }
    } else {
      this.setState({ [type]: [] });
    }
  };

  handleNomChange = (value, type) => {
    this.props.form.setFieldsValue({
      [type]: value
    });
  };
  publishYear = value => {
    this.setState({
      yearValue: value
    });
  };
  render() {
    let { getFieldDecorator } = this.props.form;

    let { currentKey } = this.state;
    const authorizers = this.state.authorizers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    const enterers = this.state.enterers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    return (
      <div id="reference-search" className="form-contents">
        <div className="reference-search-operate">
          <p>Add Data</p>
        </div>
        <div className="reference-header" id="referenceMenu">
          <Menu
            defaultSelectedKeys={currentKey}
            mode="horizontal"
            onClick={e => this.addKey(e)}
            className="reference-search-menu reference-menu"
            selectedKeys={currentKey}
          >
            <Menu.Item key="reference">
              <div>
                <NavLink to="/references/search/reference"> Reference </NavLink>
              </div>
            </Menu.Item>
            <Menu.Item key="section">
              <div>
                <NavLink to="/references/search/section"> Section </NavLink>
              </div>
            </Menu.Item>

            <Menu.Item key="collection">
              <div>
                <NavLink to="/references/search/collection">Collection</NavLink>
              </div>
            </Menu.Item>

            <Menu.Item key="taxonomy">
              <div>
                <NavLink to="/taxonomy/addTaxon"> Taxonomy </NavLink>
              </div>
            </Menu.Item>
            <Menu.Item key="oceanic">
              <div>
                <NavLink to="/oceanic/add/huici"> Oceanic data </NavLink>
              </div>
            </Menu.Item>
          </Menu>
        </div>

        <div className="reference-search-main form-mian-content">
          <div className="reference-search-form">
            <div className="reference-search-title  form-common-title">
              <span>Reference Check Form</span>
            </div>
            <div className="reference-search-content reference-search-baseform">
              <Form>
                <div className="referenceSearch-base  referenceSearch-new-id">
                  <div className="referenceSearch-autor referenceSearch-line">
                    <div className="referenceAdd-author-title">
                      Reference ID
                    </div>
                    <div className="referenceSearch-author searchReference-reference">
                      <span className="search-line-pre">
                        <FormItem
                          label=""
                          help={this.state.helpref}
                          validateStatus={
                            this.state.errorref ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("ref_id_from", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],

                          })(
                            <InputNumber
                              min={1}
                              placeholder=""
                            // onChange={value =>
                            //   this.sectionStartChange(value, "ref", "from")
                            // }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      <span className="search-line">
                        <span className="iconfont icon-line1"></span>
                      </span>
                      <span>
                        <FormItem
                          label=""
                          help={this.state.helpref}
                          validateStatus={
                            this.state.errorref ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("ref_id_to", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],

                          })(
                            <InputNumber
                              min={1}
                            // onChange={value =>
                            //   this.sectionEndChange(value, "ref", "to")
                            // }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>


                <div className="referenceSearch-base">
                  <div className="referenceSearch-autor">
                    <div className="referenceAdd-author-title">Author Name</div>
                    <div className="referenceSearch-author">
                      <span>
                        <FormItem label="">
                          {getFieldDecorator("ref_authors_condition", {
                            initialValue: "begins"
                          })(
                            <Select>
                              <Option value="equals">equals</Option>
                              <Option value="begins">begins with</Option>
                              <Option value="includes">includes</Option>
                              <Option value="ends">ends with</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem label="">
                          {getFieldDecorator("ref_authors_values", {
                            initialValue: ""
                          })(<Input placeholder=""></Input>)}
                        </FormItem>
                      </span>
                    </div>
                  </div>

                  <div className="referenceSearch-published">
                    <div className="referenceAdd-author-title">Published</div>
                    <div className="referenceSearch-author">
                      <span>
                        <FormItem label="">
                          {getFieldDecorator("ref_publication_year_condition", {
                            initialValue: "in"
                          })(
                            <Select>
                              <Option value="in">in</Option>
                              <Option value="after">after</Option>
                              <Option value="before">before</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                      <span className="100wh">
                        <FormItem label="">
                          {getFieldDecorator("ref_publication_year_values", {
                            rules: []
                          })(
                            <YearPicker
                              time={this.state.yearValue}
                              onChange={value => this.publishYear(value)}
                            ></YearPicker>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="referenceSearch-book referenceSearch-museum">
                  <FormItem
                    label={
                      <div className="referenceSearch-book-label">
                        <p>Title Includes</p>
                      </div>
                    }
                  >
                    <div>
                      <span>
                        {getFieldDecorator("ref_title_includevalues", {
                          initialValue: ""
                        })(<Input placeholder=""></Input>)}
                      </span>
                    </div>
                  </FormItem>
                </div>

                <div className="referenceSearch-book referenceSearch-museum">
                  <div>
                    <div className="referenceSearch-author">
                      <span>
                        <FormItem
                          label={
                            <div className="referenceSearch-book-label">
                              <p>Book/serial/ museum name</p>
                            </div>
                          }
                          key="ref_journal_booktitle"
                        >
                          {getFieldDecorator(
                            "ref_journal_booktitle_condition",
                            {
                              initialValue: "begins"
                            }
                          )(
                            <Select placeholder="begins with">
                              <Option value="begins">begins with</Option>
                              <Option value="includes">includes</Option>
                              <Option value="ends">ends with</Option>
                              <Option value="equals">equals</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>

                      <span>
                        <FormItem
                          label={
                            <div className="referenceSearch-book-label">
                              <p></p>
                            </div>
                          }
                          key="ref_journal_booktitle"
                        >
                          {getFieldDecorator(
                            "ref_journal_booktitle_includevalues",
                            {
                              initialValue: ""
                            }
                          )(<Input placeholder=""></Input>)}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div className="referenceSearch-numberProject"> */}
                <div className="referenceSearch-book referenceSearch-museum">
                  {/* <div className="Reference-number">
                    <FormItem label={<p>Reference-number</p>}>
                      {getFieldDecorator("ref_numer", {
                        rules: [
                          {
                            type: "number"
                          }
                        ],
                        initialValue: ""
                      })(<InputNumber></InputNumber>)}
                    </FormItem>
                  </div> */}
                  <div className="referenceSearch-no">
                    <FormItem label="Project">
                      {getFieldDecorator("ref_publication_type", {
                        rules: [],
                        initialValue: ""
                      })(
                        <Select className="ref-refProjects">
                          {refProjects.map((item, index) => {
                            return (
                              <Option value={item} title={item} key={item}>
                                {item}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </FormItem>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <FormItem label="Authorizer">
                      {getFieldDecorator("authorizer_id", {
                        rules: [{}],
                        initialValue: ""
                      })(
                        <AutoComplete
                          onSearch={value =>
                            this.handleNomSearch(value, "authorizers")
                          }
                          onChange={value =>
                            this.handleNomChange(value, "authorizer_id")
                          }
                          placeholder=""
                        >
                          {authorizers}
                        </AutoComplete>
                      )}
                    </FormItem>
                  </div>
                  <div className="referenceSearch-no">
                    <FormItem label="Enterer">
                      {getFieldDecorator("enterer", {
                        rules: [],
                        initialValue: ""
                      })(
                        <AutoComplete
                          onSearch={value =>
                            this.handleNomSearch(value, "enterers")
                          }
                          onChange={value =>
                            this.handleNomChange(value, "enterer")
                          }
                          placeholder=""
                        >
                          {enterers}
                        </AutoComplete>
                      )}
                    </FormItem>
                  </div>
                </div>

                <div className="referenceSearch-authours referenceSearch-editors  form-last">

                  <div>
                    <div className="referenceSearch-author">
                      <span>
                        <FormItem label={<p>Sort by</p>}>
                          {getFieldDecorator("sort_names", {
                            rules: [{}],
                            initialValue: "ref_authors"
                          })(
                            <Select placeholder="author">
                              <Option value="ref_authors">author</Option>
                              <Option value="ref_publication_year">year</Option>

                              <Option value="authorizer_id">authorizer</Option>
                              <Option value="enterer">enterer</Option>
                              <Option value="createdAt">entry date</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem label="">
                          {getFieldDecorator("sort_types", {
                            rules: [{}],
                            initialValue: "-1"
                          })(
                            <Select placeholder="ascending">
                              <Option value="1">ascending</Option>
                              <Option value="-1">descending</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>

                </div>

                <div className="referenceSearch-btns">
                  <div>
                    <FormItem>
                      <Button
                        type="gbdb"
                        onClick={() => {
                          this.handleSubmit();
                        }}
                      >
                        <p> Search</p>
                      </Button>
                    </FormItem>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ReferenceSearch = Form.create({})(ReferenceSearch);
export default ReferenceSearch;
