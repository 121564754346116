import React from 'react'
import {Modal,Form,Button,Input} from 'antd'
import './collectionModal.scss'
import BasicInfoForm from './infoForm'
import ListForm from './listForm'


export default class CollectionModal extends React.Component{
    constructor(props){
        super(props)
        this.state={
            isSelectInfo:true,
            isSelectList:false,
        }
    }
   
    selectInfo(){
        this.setState({
            isSelectInfo:true,
            isSelectList:false,
        })
    }
    selectList(){
        this.setState({
            isSelectInfo:false,
            isSelectList:true,
        })
    }
    render(){
       
        const {isSelectInfo,isSelectList}=this.state
        return (
            <div>
                <Modal 
                className='AModal collectionModal'
                visible={this.props.visible}
                onCancel={this.props.cancelShowInfo}
                footer={null}
                >
                     {/* <div className='topBtn'>
                        <Button type='agbdb1' className={`btn1 ${isSelectInfo?'btnFocus':'btnBlur'}`} onClick={this.selectInfo.bind(this)}>Collection Info</Button>
                        <Button type='agbdb1' className={`btn2 ${isSelectList?'btnFocus':'btnBlur'}`} onClick={this.selectList.bind(this)}>FossilList</Button>
                    </div> */}
                    <div className='form'>
                        {
                            isSelectInfo?
                            (<BasicInfoForm
                                modalData={this.props.modalData}
                                cancelShowInfo={this.props.cancelShowInfo.bind(this)}
                                refreshList={this.props.refreshList.bind(this)}
                            ></BasicInfoForm>) 
                            :
                            (<ListForm
                                modalData={this.props.modalData}
                                cancelShowInfo={this.props.cancelShowInfo.bind(this)}
                                refreshList={this.props.refreshList.bind(this)}
                            ></ListForm>)
                        }
                    </div>
                   
                    
                </Modal>
            </div>
        )
      
    }
}