import React, { Component } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import "./index.css";


export default class Research extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey:
        this.props.location.pathname.split("/")[
          this.props.location.pathname.split("/").length - 1
        ] || "case"
    };
  }
  addKey = e => {
    this.setState(
      {
        currentKey: e.key
      },
      () => {
      }
    );
  };
  render() {
    let { currentKey } = this.state;
    return (
      <div id="reference-search" className="form-contents">
        <div className="reference-search-operate research-all-title">
          {/* <p>About</p> */}
        </div>
        

        <div className="reference-search-main form-mian-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}
