import React, { Component } from "react";

import "./index.css";

import Utils from "src/js/utils";
import Apis from "src/js/apis/api";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Modal,
  InputNumber,
  AutoComplete,
  Radio
} from "antd";
import { withRouter } from "react-router-dom";
import dataApis from "src/js/apis/data";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

@withRouter
class SectionOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchAllValues: props.searchAllValues || {},
      authorizers: [],
      enterers: [],
      durations: [],
      sectionType: "",
      Countrys: [],
      Provinces: [],
      Villages: [],
      Locations: [],
      Intervals: [],
      Intages: [],
      showBase: props.showBase === true ? true : false,
      showGeography: props.showGeography === true ? true : false,
      showUnit: props.showUnit === true ? true : false,
      showFomation: props.showFomation === true ? true : false,
      showOccurrence: props.showOccurrence === true ? true : false,
      showAlloption: props.showAlloption === true ? true : false,
      allChildError: {},
      reffrom: props.searchAllValues ? props.searchAllValues.ref_id_from : "",
      refto: props.searchAllValues ? props.searchAllValues.ref_id_to : "",
      selectDuration: { erathem_max: [], period_max: [], epoch_max: [], early_interval: [] },
      unitPartShow:
        props.location.pathname === "/search/section" ||
          props.location.pathname === "/searchs/result/section/base"
          ? true
          : false,
          hideAgeOption:
          props.location.pathname === "/search/section" ||
            props.location.pathname === "/searchs/result/section/base"
            ? true
            : false,

      // sectionfrom:props.searchAllValues?props.searchAllValues.section_id_from:'',
      // sectionto:props.searchAllValues?props.searchAllValues.section_id_to:'',

      sectionfrom: props.searchAllValues
        ? props.searchAllValues.section_basic_id_from
        : "",
      sectionto: props.searchAllValues
        ? props.searchAllValues.section_basic_id_to
        : "",

      taxonfrom: props.searchAllValues ? props.searchAllValues.id_from : "",
      taxonto: props.searchAllValues ? props.searchAllValues.id_to : "",

      createdAtfrom: props.searchAllValues ? props.searchAllValues.createdAt_from : null,
      createdAtto: props.searchAllValues ? props.searchAllValues.createdAt_to : null,
    };
  }
  async componentDidMount() {
    if (this.props.sectionOptionChild) {

      this.props.sectionOptionChild(this);
    }

  }
  changeDurationParent = (selectDuration) => {

    this.setState({
      selectDuration
    }, () => {

    })

  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      showBase: nextProps.showBase,
      showGeography: nextProps.showGeography,
      showUnit: nextProps.showUnit,
      showFomation: nextProps.showFomation,
      showOccurrence: nextProps.showOccurrence,
      searchAllValues: nextProps.searchAllValues
    });
  }

  handleNomSearch = async (value, type) => {
    if (value) {
      let searchResult;
      if (type === "authorizers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteExperts(params);
      } else if (type === "enterers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteEnterer(params);
      } else if (type === "durations") {
        let params = {
          name: value
        };
        searchResult = await dataApis.autoCompleteChronostratigraphics(params);
      }

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });

        return;
      } else if (searchResult.result) {
        this.setState(
          {
            [type]: searchResult.result
          },
          () => { }
        );
      }
    } else {
      this.setState({ [type]: [] });
    }
  };
  handleNomChange = (value, type) => {
    this.props.form.setFieldsValue(
      {
        [type]: value
      }

    );
  };
  handleDuration = (value, type, e) => {
    if (type === "unit_duration") {
      if (e.props && e.props.datavalue) {
        let { selectDuration } = this.state
        let level = Number(e.props.datavalue.level)
        if (level === 1) {
          selectDuration.erathem_max.push(value)
        } else if (level === 2) {
          selectDuration.period_max.push(value)
        } else if (level === 3) {
          selectDuration.epoch_max.push(value)
        } else if (level === 4) {
          selectDuration.early_interval.push(value)
        }
        this.setState({
          selectDuration
        }, () => {

          if (this.props.changeDuration) {


            this.props.changeDuration(this.state.selectDuration);
          }

          let searchType = this.props.form.getFieldValue("duration_search_type");
          let unitDuration = this.props.form.getFieldValue("unit_duration");
          if (

            unitDuration.length > 0 &&
            searchType === ""
          ) {
            this.props.form.setFieldsValue({
              duration_search_type: "or"
            });
          }


        })

      }

    }

  }
  handleonBlure = (value, type, e) => {
    let { selectDuration } = this.state
    if (e.props && e.props.datavalue) {
      

      let level = Number(e.props.datavalue.level)
      if (level === 1) {
        selectDuration.erathem_max.forEach(function (item, index, arr) {
          if (item == value) {
            selectDuration.erathem_max.splice(index, 1);
          }
        });

      } else if (level === 2) {
        selectDuration.period_max.forEach(function (item, index, arr) {
          if (item == value) {
            selectDuration.period_max.splice(index, 1);
          }
        });

      } else if (level === 3) {
        selectDuration.epoch_max.forEach(function (item, index, arr) {
          if (item === value) {
            selectDuration.epoch_max.splice(index, 1);
          }
        });

      } else if (level === 4) {
        selectDuration.early_interval.forEach(function (item, index, arr) {
          if (item === value) {
            selectDuration.early_interval.splice(index, 1);
          }
        });

      }
   

    }
    if(e.props && e.props.value){
      selectDuration.erathem_max.forEach(function (item, index, arr) {
        if (item === value) {
          selectDuration.erathem_max.splice(index, 1);
        }
      });
      selectDuration.period_max.forEach(function (item, index, arr) {
        if (item === value) {
          selectDuration.period_max.splice(index, 1);
        }
      });
      selectDuration.epoch_max.forEach(function (item, index, arr) {
        if (item === value) {
          selectDuration.epoch_max.splice(index, 1);
        }
      });
      selectDuration.early_interval.forEach(function (item, index, arr) {
        if (item === value) {
          selectDuration.early_interval.splice(index, 1);
        }
      });
    }
    this.setState({
      selectDuration
    }, () => {


      if (this.props.changeDuration) {
        this.props.changeDuration(this.state.selectDuration);
      }


    })
    setTimeout(() => {
      let searchType = this.props.form.getFieldValue("duration_search_type");
      let unitDuration = this.props.form.getFieldValue("unit_duration");


      if (

        unitDuration.length === 0 &&
        searchType !== ""
      ) {
        this.props.form.setFieldsValue({
          duration_search_type: ""
        });
      }
    }, 20)
  }

  sectionStartChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  sectionEndChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  onSectionChange = () => { }
  onSectionChange1 = (value, field, type) => {
    let stateType = field + type;
    let { allChildError } = this.state;
    this.setState(
      {
        [stateType]: value
      },
      () => {
        let compareFrom = field + "from";
        let compareTo = field + "to";

        let compareOne = this.state[compareFrom];
        let compareTwo = this.state[compareTo];

        let errorType = "error" + field;
        let helpType = "help" + field;
        if (
          (Utils.isEmpty(compareOne) && !Utils.isEmpty(compareTwo)) ||
          (Utils.isEmpty(compareTwo) && !Utils.isEmpty(compareOne))
        ) {
          this.setState(
            {
              [errorType]: true,
              [helpType]: "Start and end data need to be filled in"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
        } else if (compareTwo && compareOne && compareTwo < compareOne) {
          this.setState(
            {
              [errorType]: true,
              [helpType]: "The starting value is less than the ending value"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
        } else {
          this.setState(
            {
              [errorType]: false,
              [helpType]: ""
            },
            () => {
              if (allChildError[errorType]) {
                delete allChildError[errorType];
              }
            }
          );
        }
      }
    );
  };
  onStartChange = value => {
    this.onTimeChange("startValue", value);
    this.sectionStartChange(value, "createdAt", "from");
  };
  onEndChange = value => {
    this.onTimeChange("endValue", value);
    this.sectionStartChange(value, "createdAt", "to");
  };
  onTimeChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  geologySearch = async (value, type, key) => {
    let params = {
      attributes: [key],
      [key]: value
      // [key]: {
      //   condition: "includes",
      //   value
      // }
    };

    let geoSearchBack = await Apis.autoCompleteGeology(params);

    let searchReust = geoSearchBack.result || [];
    let resultArray = [];

    searchReust.map((item, index) => {
      resultArray.push(item[key]);
    });

    this.setState({
      [type]: resultArray
    });
  };
  geologyChange = (value, field) => {
    this.props.form.setFieldsValue({
      [field]: value
    });
  };
  IntervalChange = async (value, e, field) => {
    // let dataitem = e.props.dataitem;

    // if (dataitem) {
    //   let age =
    //     (parseFloat(dataitem.early_age) + parseFloat(dataitem.late_age)) / 2;
    //   this.changePaleogeography(age);
    //   this.props.form.setFieldsValue({
    //     early_age: parseFloat(dataitem.early_age),
    //     late_age: parseFloat(dataitem.late_age)
    //   });
    // }
    this.props.form.setFieldsValue({
      [field]: value
    });
  };
  IntervalSearch = async (value, type, key) => {
    let params = {
      keyword: value
    };

    let geoSearchBack = await dataApis.autoCompleteInterval(params);

    let searchReust = geoSearchBack.result || [];
    this.setState(
      {
        [type]: searchReust
      },
      () => { }
    );
  };
  changePaleogeography = async age => {
    let params = {
      age,
      latitude: this.state.latitude,
      longitude: this.state.longitude
    };
    let Paleogeography = await Apis.gpsConverts(params);

    if (Paleogeography.result) {
      this.props.form.setFieldsValue({
        geo_latitude_decimal: Paleogeography.result.geoLatitude,
        geo_longitude_decimal: Paleogeography.result.geoLongitude
      });
    } else {
      Modal.error({
        title: "This is a err3 message",
        content: Paleogeography.error
      });
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let {
      searchAllValues,

      showBase,
      showGeography,
      showUnit,
      showFomation,
      showOccurrence,
      Countrys,
      Provinces,
      Villages,
      Locations,
      Intervals,
      Intages,
      showAlloption
    } = this.state;

    const authorizers = this.state.authorizers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    const enterers = this.state.enterers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    const durations = this.state.durations.map((item, index) => (
      <Option key={item.name} datavalue={item}>{item.name}</Option>
    ));

    const intervals = Intervals.map((item, index) => (
      <Option
        value={item.interval_name}
        dataitem={item}
        key={item.interval_name}
      >
        {item.interval_name}
      </Option>
    ));
    const intages = Intages.map((item, index) => (
      <Option
        value={item.interval_name}
        dataitem={item}
        key={item.interval_name}
      >
        {item.interval_name}
      </Option>
    ));
    return (
      //   <div id="searchSection">
      <div id="main" className="reference-search-form commonsection-search">
        <div className="searchSection-main  reference-search-form search-common-form">
          <div className="reference-search-content reference-search-baseform searchSection-form">
            <Form>
              {showBase ? (
                <div>
                  <div className="referenceSearch-base">
                    <div className="referenceSearch-autor referenceSearch-line">
                      <div className="referenceAdd-author-title">
                        Reference ID
                      </div>
                      <div className="referenceSearch-author searchReference-reference">
                        <span className="search-line-pre">
                          <FormItem
                            label=""
                            help={this.state.helpref}
                            validateStatus={
                              this.state.errorref ? "error" : "success"
                            }
                          >
                            {getFieldDecorator("ref_id_from", {
                              rules: [
                                {
                                  type: "number"
                                }
                              ],
                              initialValue: searchAllValues
                                ? searchAllValues.ref_id_from
                                : ""
                            })(
                              <InputNumber
                                min={1}
                                placeholder=""
                                onChange={value =>
                                  this.sectionStartChange(value, "ref", "from")
                                }
                              ></InputNumber>
                            )}
                          </FormItem>
                        </span>
                        <span className="search-line">
                          <span className="iconfont icon-line1"></span>
                        </span>
                        <span>
                          <FormItem
                            label=""
                            help={this.state.helpref}
                            validateStatus={
                              this.state.errorref ? "error" : "success"
                            }
                          >
                            {getFieldDecorator("ref_id_to", {
                              rules: [
                                {
                                  type: "number"
                                }
                              ],
                              initialValue: searchAllValues
                                ? searchAllValues.ref_id_to
                                : ""
                            })(
                              <InputNumber
                                min={1}
                                onChange={value =>
                                  this.sectionEndChange(value, "ref", "to")
                                }
                              ></InputNumber>
                            )}
                          </FormItem>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="referenceSearch-base">
                    {!showGeography ? (
                      <div className="referenceSearch-autor referenceSearch-line">
                        <div className="referenceAdd-author-title">
                          Section ID
                        </div>
                        <div className="referenceSearch-author searchReference-reference">
                          <span className="search-line-pre">
                            <FormItem
                              label=""
                              help={this.state.helpsection}
                              validateStatus={
                                this.state.errorsection ? "error" : "success"
                              }
                            >
                              {getFieldDecorator("section_id_from", {
                                rules: [
                                  {
                                    type: "number"
                                  }
                                ],
                                initialValue: searchAllValues
                                  ? searchAllValues.section_id_from
                                  : ""
                              })(
                                <InputNumber
                                  min={1}
                                  placeholder=""
                                  onChange={value =>
                                    this.sectionStartChange(
                                      value,
                                      "section",
                                      "from"
                                    )
                                  }
                                ></InputNumber>
                              )}
                            </FormItem>
                          </span>
                          <span className="search-line">
                            <span className="iconfont icon-line1"></span>
                          </span>
                          <span>
                            <FormItem
                              label=""
                              help={this.state.helpsection}
                              validateStatus={
                                this.state.errorsection ? "error" : "success"
                              }
                            >
                              {getFieldDecorator("section_id_to", {
                                rules: [
                                  {
                                    type: "number"
                                  }
                                ],
                                initialValue: searchAllValues
                                  ? searchAllValues.section_id_to
                                  : ""
                              })(
                                <InputNumber
                                  min={1}
                                  onChange={value =>
                                    this.sectionEndChange(
                                      value,
                                      "section",
                                      "to"
                                    )
                                  }
                                ></InputNumber>
                              )}
                            </FormItem>
                          </span>
                        </div>
                      </div>
                    ) : (
                        <div className="referenceSearch-autor referenceSearch-line">
                          <div className="referenceAdd-author-title">
                            Section ID
                        </div>
                          <div className="referenceSearch-author searchReference-reference">
                            <span className="search-line-pre">
                              <FormItem
                                label=""
                                help={this.state.helpsection}
                                validateStatus={
                                  this.state.errorsection ? "error" : "success"
                                }
                              >
                                {getFieldDecorator("section_basic_id_from", {
                                  rules: [
                                    {
                                      type: "number"
                                    }
                                  ],
                                  initialValue: searchAllValues
                                    ? searchAllValues.section_basic_id_from
                                    : ""
                                })(
                                  <InputNumber
                                    min={1}
                                    placeholder=""
                                    onChange={value =>
                                      this.sectionStartChange(
                                        value,
                                        "section",
                                        "from"
                                      )
                                    }
                                  ></InputNumber>
                                )}
                              </FormItem>
                            </span>
                            <span className="search-line">
                              <span className="iconfont icon-line1"></span>
                            </span>
                            <span>
                              <FormItem
                                label=""
                                help={this.state.helpsection}
                                validateStatus={
                                  this.state.errorsection ? "error" : "success"
                                }
                              >
                                {getFieldDecorator("section_basic_id_to", {
                                  rules: [
                                    {
                                      type: "number"
                                    }
                                  ],
                                  initialValue: searchAllValues
                                    ? searchAllValues.section_basic_id_to
                                    : ""
                                })(
                                  <InputNumber
                                    min={1}
                                    onChange={value =>
                                      this.sectionEndChange(
                                        value,
                                        "section",
                                        "to"
                                      )
                                    }
                                  ></InputNumber>
                                )}
                              </FormItem>
                            </span>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="referenceSearch-base">
                    <div className="referenceSearch-autor referenceSearch-line">
                      <div className="referenceAdd-author-title">
                        Input date
                      </div>
                      <div className="referenceSearch-author searchReference-reference">
                        <span className="search-line-pre">
                          <FormItem
                            label=""
                            help={this.state.helpcreatedAt}
                            validateStatus={
                              this.state.errorcreatedAt ? "error" : "success"
                            }
                          >
                            {getFieldDecorator("createdAt_from", {
                              rules: [{ type: "object", message: "" }],
                              initialValue: searchAllValues
                                ? searchAllValues.createdAt_from
                                : null
                            })(
                              <DatePicker
                                disabledDate={this.disabledStartDate}
                                format="YYYY-MM-DD"
                                onChange={this.onStartChange}
                              ></DatePicker>
                            )}
                          </FormItem>
                        </span>
                        <span className="search-line">
                          <span className="iconfont icon-line1"></span>
                        </span>
                        <span>
                          <FormItem
                            label=""
                            help={this.state.helpcreatedAt}
                            validateStatus={
                              this.state.errorcreatedAt ? "error" : "success"
                            }
                          >
                            {getFieldDecorator("createdAt_to", {
                              rules: [{ type: "object", message: "" }],

                              initialValue: searchAllValues
                                ? searchAllValues.createdAt_to
                                : null
                            })(
                              <DatePicker
                                disabledDate={this.disabledEndDate}
                                format="YYYY-MM-DD"
                                onChange={this.onEndChange}
                              ></DatePicker>
                            )}
                          </FormItem>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="referenceSearch-book">
                    <Form.Item label="Project">
                      {getFieldDecorator("section_project", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.section_project
                          : ""
                      })(
                        <Select placeholder="Default">
                          <Option value="Null">Null</Option>
                          <Option value="Default">Default</Option>
                          <Option value="BGS digitization">
                            BGS digitization
                          </Option>
                          <Option value="ocean_data">Ocean Data</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </div>

                  <div className="referenceSearch-book">
                    <Form.Item label="Section Name">
                      {getFieldDecorator("section_name", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.section_name
                          : ""
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>

                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two">
                      <Form.Item label="Authorizer">
                        {getFieldDecorator("authorizer_id", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.authorizer_id
                            : ""
                        })(
                          <AutoComplete
                            onSearch={value =>
                              this.handleNomSearch(value, "authorizers")
                            }
                            onChange={value =>
                              this.handleNomChange(value, "authorizer_id")
                            }
                            placeholder=""
                          >
                            {authorizers}
                          </AutoComplete>
                        )}
                      </Form.Item>
                    </div>
                    <div className="Reference-number search-two">
                      <Form.Item label="Enterer">
                        {getFieldDecorator("enterer", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.enterer
                            : ""
                        })(
                          <AutoComplete
                            onSearch={value =>
                              this.handleNomSearch(value, "enterers")
                            }
                            onChange={value =>
                              this.handleNomChange(value, "enterer")
                            }
                            placeholder=""
                          >
                            {enterers}
                          </AutoComplete>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* <div className="referenceSearch-book">
                <Form.Item
                  label={<p>Reach or fossil group</p>}
               
                >
                  {getFieldDecorator("fossil_group", {
                    rules: [{}],
                    // initialValue: treatises[0]
                    initialValue: ""
                  })(
                    <Select placeholder={treatises[0]}>
                      {treatises.map((item, index) => {
                        return <Option value={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div> */}

              {/* <div className="referenceSearch-book searchTaxonomy-fossil">
                <div className="form-group-title sectionGeography-tutude-title">
                  <span>Genus or Higher</span>
                  <span>Species</span>
                </div>
                <div className="referenceSearch-base">
                  <div className="referenceSearch-autor referenceSearch-middle-autor">
                    <span className="sectionGeography-map-label searchOccurence-fossil">
                      Fossil name
                    </span>

                    <div className="referenceSearch-author searchReference-reference">
                      <span>
                        <FormItem
                          label={
                            <p className="h5-map-title">Genus or Higher：</p>
                          }
                          className="h5-genus"
                        >
                          {getFieldDecorator("geology_latitude_genus", {
                            initialValue: ""
                          })(<Input placeholder=""></Input>)}
                        </FormItem>
                      </span>

                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Species：</p>}
                      
                        >
                          {getFieldDecorator("geology_latitude_species", {
                            initialValue: ""
                          })(<Input placeholder=""></Input>)}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}

              {showGeography ? (
                <div>
                  <div className="referenceSearch-numberProject referenceSearch-location">
                    <div className="Reference-number">
                      <Form.Item label="Country / Continent">
                        {getFieldDecorator("geology_country", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.geology_country
                            : ""
                        })(
                          <AutoComplete
                            onSearch={value =>
                              this.geologySearch(
                                value,
                                "Countrys",
                                "geology_country"
                              )
                            }
                            onChange={value =>
                              this.geologyChange(value, "geology_country")
                            }
                            placeholder=""
                          >
                            {Countrys.map((item, index) => {
                              return (
                                <Option value={item} key={item}>
                                  {item}
                                </Option>
                              );
                            })}
                          </AutoComplete>
                        )}
                      </Form.Item>
                    </div>
                    <div className="referenceSearch-no">
                      <Form.Item label="Province / State">
                        {getFieldDecorator("geology_province", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.geology_province
                            : ""
                        })(
                          <AutoComplete
                            onSearch={value =>
                              this.geologySearch(
                                value,
                                "Provinces",
                                "geology_province"
                              )
                            }
                            onChange={value =>
                              this.geologyChange(value, "geology_province")
                            }
                            placeholder=""
                          >
                            {Provinces.map((item, index) => {
                              return (
                                <Option value={item} key={item}>
                                  {item}
                                </Option>
                              );
                            })}
                          </AutoComplete>
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="referenceSearch-numberProject referenceSearch-location">
                    <div className="Reference-number">
                      <Form.Item label="City / Village">
                        {getFieldDecorator("geology_village", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.geology_village
                            : ""
                        })(
                          <AutoComplete
                            onSearch={value =>
                              this.geologySearch(
                                value,
                                "Villages",
                                "geology_village"
                              )
                            }
                            onChange={value =>
                              this.geologyChange(value, "geology_village")
                            }
                            placeholder=""
                          >
                            {Villages.map((item, index) => {
                              return (
                                <Option value={item} key={item}>
                                  {item}
                                </Option>
                              );
                            })}
                          </AutoComplete>
                        )}
                      </Form.Item>
                    </div>
                    <div className="referenceSearch-no">
                      <Form.Item label="Location">
                        {getFieldDecorator("geology_location", {
                          initialValue: searchAllValues
                            ? searchAllValues.geology_location
                            : ""
                        })(
                          <AutoComplete
                            onSearch={value =>
                              this.geologySearch(
                                value,
                                "Locations",
                                "geology_location"
                              )
                            }
                            onChange={value =>
                              this.geologyChange(value, "geology_location")
                            }
                            placeholder=""
                          >
                            {Locations.map((item, index) => {
                              return (
                                <Option value={item} key={item}>
                                  {item}
                                </Option>
                              );
                            })}
                          </AutoComplete>
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="sectionGeography-map">
                    <div className="sectionGeography-map-border"></div>
                    <div className="sectionGeography-tutude sectionGeography-tutude-title">
                      <span>Degrees</span>
                      <span>Minutes</span>
                      <span>Seconds</span>
                      {/* <span>Declmal</span> */}
                      <span>Direction</span>
                    </div>

                    <div className="sectionGeography-tutude">
                      <span className="sectionGeography-map-label">
                        Latitude
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Degrees：</p>}
                          help={this.state.helpLatitudedegree}
                          validateStatus={
                            this.state.errorLatitudedegree ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("geology_latitude_degree_from", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.geology_latitude_degree_from
                              : ""
                          })(
                            <InputNumber
                              placeholder=""
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Latitudedegree",
                                  "from"
                                )
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>

                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Minutes：</p>}
                          help={this.state.helpLatitudeminute}
                          validateStatus={
                            this.state.errorLatitudeminute ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("geology_latitude_minute_from", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.geology_latitude_minute_from
                              : ""
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Latitudeminute",
                                  "from"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Seconds：</p>}
                          help={this.state.helpLatitudeSecond}
                          validateStatus={
                            this.state.errorLatitudeSecond ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("geology_latitude_second_from", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.geology_latitude_second_from
                              : ""
                          })(
                            <InputNumber
                              placeholder=""
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "LatitudeSecond",
                                  "from"
                                )
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      {/* <span>
                        <FormItem
                          label={<p className="h5-map-title">Declmal：</p>}
                        
                          help={this.state.helpLatitudedecimal}
                          validateStatus={
                            this.state.errorLatitudedecimal
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_latitude_decimal_from", {
                            initialValue: searchAllValues
                              ? searchAllValues.geology_latitude_decimal_from
                              : ""
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Latitudedecimal",
                                  "from"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>*/}
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Direction：</p>}
                          help={this.state.helpLatitudedirection}
                          validateStatus={
                            this.state.errorLatitudedirection
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_latitude_direction", {
                            initialValue: searchAllValues
                              ? searchAllValues.geology_latitude_direction
                              : ""
                          })(
                            <Select
                              placeholder=""
                            // onChange={value =>
                            //   this.sectionStartChange(
                            //     value,
                            //     "Latitudedirection",
                            //     "from"
                            //   )
                            // }
                            >
                              <Option value="North">North</Option>
                              <Option value="East">East</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                    </div>

                    <div className="sectionGeography-tutude">
                      <span className="sectionGeography-map-label">
                        Longitude
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Degrees：</p>}
                          help={this.state.helpLongitudedegree}
                          validateStatus={
                            this.state.errorLongitudedegree
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_longitude_degree_from", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.geology_longitude_degree_from
                              : ""
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Longitudedegree",
                                  "from"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>

                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Minutes：</p>}
                          help={this.state.helpLongitudeminute}
                          validateStatus={
                            this.state.errorLongitudeminute
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_longitude_minute_from", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.geology_longitude_minute_from
                              : ""
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Longitudeminute",
                                  "from"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Seconds：</p>}
                          help={this.state.helpLongitudeSecond}
                          validateStatus={
                            this.state.errorLongitudeSecond
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_longitude_second_from", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.geology_longitude_second_from
                              : ""
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "LongitudeSecond",
                                  "from"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      {/* <span>
                        <FormItem
                          label={<p className="h5-map-title">Declmal：</p>}
                        
                          help={this.state.helpLongitudedecimal}
                          validateStatus={
                            this.state.errorLongitudedecimal
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_longitude_decimal_from", {
                            initialValue: searchAllValues
                              ? searchAllValues.geology_longitude_decimal_from
                              : ""
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Longitudedecimal",
                                  "from"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      
                       */}
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Direction：</p>}
                          help={this.state.helpLongitudedirection}
                          validateStatus={
                            this.state.errorLongitudedirection
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_longitude_direction", {
                            initialValue: searchAllValues
                              ? searchAllValues.geology_longitude_direction
                              : ""
                          })(
                            <Select
                              placeholder=""
                            // onChange={value =>
                            //   this.sectionStartChange(
                            //     value,
                            //     "Longitudedirection",
                            //     "from"
                            //   )
                            // }
                            >
                              <Option value="East">East</Option>
                              <Option value="West">West</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                    </div>
                    <div className="sectionGeography-tutude sectionGeography-tutude-title">
                      <span className="sectionGeography-tutude-to">To</span>
                    </div>

                    <div className="sectionGeography-tutude">
                      <span className="sectionGeography-map-label">
                        Latitude
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Degrees：</p>}
                          help={this.state.helpLatitudedegree}
                          validateStatus={
                            this.state.errorLatitudedegree ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("geology_latitude_degree_to", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.geology_latitude_degree_to
                              : ""
                          })(
                            <InputNumber
                              placeholder=""
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Latitudedegree",
                                  "to"
                                )
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>

                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Minutes：</p>}
                          help={this.state.helpLatitudeminute}
                          validateStatus={
                            this.state.errorLatitudeminute ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("geology_latitude_minute_to", {
                            initialValue: searchAllValues
                              ? searchAllValues.geology_latitude_minute_to
                              : "",
                            rules: [
                              {
                                type: "number"
                              }
                            ]
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Latitudeminute",
                                  "to"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Seconds：</p>}
                          help={this.state.helpLatitudeSecond}
                          validateStatus={
                            this.state.errorLatitudeSecond ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("geology_latitude_second_to", {
                            initialValue: searchAllValues
                              ? searchAllValues.geology_latitude_second_to
                              : "",
                            rules: [
                              {
                                type: "number"
                              }
                            ]
                          })(
                            <InputNumber
                              placeholder=""
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "LatitudeSecond",
                                  "to"
                                )
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      {/* <span>
                        <FormItem
                          label={<p className="h5-map-title">Declmal：</p>}

                          help={this.state.helpLatitudedecimal}
                          validateStatus={
                            this.state.errorLatitudedecimal
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_latitude_decimal_to", {
                            initialValue: searchAllValues
                              ? searchAllValues.geology_latitude_decimal_to
                              : ""
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Latitudedecimal",
                                  "to"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      */}
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Direction：</p>}
                          help={this.state.helpLatitudedirection}
                          validateStatus={
                            this.state.errorLatitudedirection
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_latitude_direction", {
                            initialValue: searchAllValues
                              ? searchAllValues.geology_latitude_direction
                              : ""
                          })(
                            <Select
                              placeholder=""
                            // onChange={value =>
                            //   this.sectionStartChange(
                            //     value,
                            //     "Latitudedirection",
                            //     "to"
                            //   )
                            // }
                            >
                              <Option value="North">North</Option>
                              <Option value="East">East</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                    </div>

                    <div className="sectionGeography-tutude">
                      <span className="sectionGeography-map-label">
                        Longitude
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Degrees：</p>}
                          help={this.state.helpLongitudedegree}
                          validateStatus={
                            this.state.errorLongitudedegree
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_longitude_degree_to", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.geology_longitude_degree_to
                              : ""
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Longitudedegree",
                                  "to"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>

                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Minutes：</p>}
                          help={this.state.helpLongitudeminute}
                          validateStatus={
                            this.state.errorLongitudeminute
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_longitude_minute_to", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.geology_longitude_minute_to
                              : ""
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Longitudeminute",
                                  "to"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Seconds：</p>}
                          help={this.state.helpLongitudeSecond}
                          validateStatus={
                            this.state.errorLongitudeSecond
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_longitude_second_to", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.geology_longitude_second_to
                              : ""
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "LongitudeSecond",
                                  "to"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>

                      {/* <span>
                        <FormItem
                          label={<p className="h5-map-title">Declmal：</p>}
                         
                          help={this.state.helpLongitudedecimal}
                          validateStatus={
                            this.state.errorLongitudedecimal
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_longitude_decimal_to", {
                            initialValue: searchAllValues
                              ? searchAllValues.geology_longitude_decimal_to
                              : ""
                          })(
                            <InputNumber
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "Longitudedecimal",
                                  "to"
                                )
                              }
                              placeholder=""
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      */}
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Direction：</p>}
                          help={this.state.helpLongitudedirection}
                          validateStatus={
                            this.state.errorLongitudedirection
                              ? "error"
                              : "success"
                          }
                        >
                          {getFieldDecorator("geology_longitude_direction", {
                            initialValue: searchAllValues
                              ? searchAllValues.geology_longitude_direction
                              : ""
                          })(
                            <Select
                              placeholder=""
                            // onChange={value =>
                            //   this.sectionStartChange(
                            //     value,
                            //     "Longitudedirection",
                            //     "to"
                            //   )
                            // }
                            >
                              <Option value="East">East</Option>
                              <Option value="West">West</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}

              {showFomation ? (
                <div>
                  {
                    !this.state.hideAgeOption?
                    <div className="referenceSearch-base">
                    <div className="referenceSearch-autor referenceSearch-line">
                      <div className="referenceAdd-author-title">Age</div>
                      <div className="referenceSearch-author searchReference-reference">
                        <span className="search-line-pre">
                          <FormItem
                            label=""
                            help={this.state.helpage}
                            validateStatus={
                              this.state.errorage ? "error" : "success"
                            }
                          >
                            {getFieldDecorator("late_age", {
                              initialValue: searchAllValues
                                ? searchAllValues.late_age
                                : null
                            })(
                              <InputNumber
                                className="age-format-unit"
                                // formatter={value => value ? `${value}Ma` : ""}
                                // parser={value => value.replace('Ma', '')}
                                placeholder="Late age"
                                onChange={value =>
                                  this.sectionStartChange(value, "age", "from")
                                }
                                step={0.01}
                                min={0}
                              ></InputNumber>
                            )}
                          </FormItem>
                        </span>
                        <span className="search-line">
                          <span className="iconfont icon-line1"></span>
                        </span>
                        <span>
                          <FormItem
                            label=""
                            help={this.state.helpage}
                            validateStatus={
                              this.state.errorage ? "error" : "success"
                            }
                          >
                            {getFieldDecorator("early_age", {
                              initialValue: searchAllValues
                                ? searchAllValues.early_age
                                : null
                            })(
                              <InputNumber
                                className="age-format-unit"
                                // formatter={value => value ? `${value}Ma` : ""}
                                // parser={value => value.replace('Ma', '')}
                                placeholder="Early age"
                                onChange={value =>
                                  this.sectionEndChange(value, "age", "to")
                                }
                                step={0.01}
                                min={0}
                              ></InputNumber>
                            )}
                          </FormItem>
                        </span>
                      </div>
                    </div>
                  </div>
                  :null
                  }
                  
                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two">
                      <Form.Item label="Group">
                        {getFieldDecorator("formation_group", {
                          initialValue: searchAllValues
                            ? searchAllValues.formation_group
                            : ""
                        })(<Input />)}
                      </Form.Item>

                    </div>
                    <div className="Reference-number search-two">
                      <Form.Item label="Bed">
                        {getFieldDecorator("formation_bed", {
                          initialValue: searchAllValues
                            ? searchAllValues.formation_bed
                            : ""
                        })(<Input />)}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two">
                      <Form.Item label="Formation">
                        {getFieldDecorator("formation_name", {
                          initialValue: searchAllValues
                            ? searchAllValues.formation_name
                            : ""
                        })(<Input />)}
                      </Form.Item>
                    </div>
                    <div className="Reference-number search-two">
                      <Form.Item label="Member">
                        {getFieldDecorator("formation_member", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.formation_member
                            : ""
                        })(<Input />)}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two">
                      <Form.Item label="Early interval">
                        {getFieldDecorator("early_interval", {
                          initialValue: searchAllValues
                            ? searchAllValues.early_interval
                            : ""
                        })(
                          <AutoComplete
                            onSearch={value =>
                              this.IntervalSearch(
                                value,
                                "Intervals",
                                "early_interval"
                              )
                            }
                            onChange={(value, e) =>
                              this.IntervalChange(value, e, "early_interval")
                            }
                            placeholder=""
                          >
                            {intervals}
                          </AutoComplete>
                        )}
                      </Form.Item>
                    </div>
                    <div className="Reference-number search-two">
                      <Form.Item label="Intage max">
                        {getFieldDecorator("intage_max", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.intage_max
                            : ""
                        })(
                          <AutoComplete
                            onSearch={value =>
                              this.IntervalSearch(
                                value,
                                "Intages",
                                "intage_max"
                              )
                            }
                            onChange={(value, e) =>
                              this.IntervalChange(value, e, "intage_max")
                            }
                            placeholder=""
                          >
                            {intages}
                          </AutoComplete>
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two">
                      <Form.Item label="Overlying">
                        {getFieldDecorator("formation_overlying", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.formation_overlying
                            : ""
                        })(<Input />)}
                      </Form.Item>
                    </div>
                    <div className="Reference-number search-two">
                      <Form.Item label="Color">
                        {getFieldDecorator("formation_color", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.formation_color
                            : ""
                        })(<Input />)}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two">
                      <Form.Item label="Main Lithology">
                        {getFieldDecorator("formation_lithology", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.formation_lithology
                            : ""
                        })(<Input />)}
                      </Form.Item>
                    </div>
                    <div className="Reference-number search-two">
                      <Form.Item label="Contact base">
                        {getFieldDecorator("formation_conta_base", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.formation_conta_base
                            : ""
                        })(<Input />)}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two">
                      <Form.Item label="Authorizer">
                        {getFieldDecorator("authorizer_id", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.authorizer_id
                            : ""
                        })(
                          <AutoComplete
                            onSearch={value =>
                              this.handleNomSearch(value, "authorizers")
                            }
                            onChange={value =>
                              this.handleNomChange(value, "authorizer_id")
                            }
                            placeholder=""
                          >
                            {authorizers}
                          </AutoComplete>
                        )}
                      </Form.Item>
                    </div>
                    <div className="Reference-number search-two">
                      <Form.Item label="Enterer">
                        {getFieldDecorator("enterer", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.enterer
                            : ""
                        })(
                          <AutoComplete
                            onSearch={value =>
                              this.handleNomSearch(value, "enterers")
                            }
                            onChange={value =>
                              this.handleNomChange(value, "enterer")
                            }
                            placeholder=""
                          >
                            {enterers}
                          </AutoComplete>
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="referenceAdd-journal">
                    <Form.Item label="Paleoenviroment">
                      {getFieldDecorator("formation_paleoenvironment", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.formation_paleoenvironment
                          : ""
                      })(<Input />)}
                    </Form.Item>
                  </div>
                </div>
              ) : null}
              {showUnit ? (
                <div>
                  {
                    !this.state.hideAgeOption?
                    <div className="referenceSearch-base">
                    <div className="referenceSearch-autor referenceSearch-line">
                      <div className="referenceAdd-author-title">Age</div>
                      <div className="referenceSearch-author searchReference-reference">
                        <span className="search-line-pre">
                          <FormItem
                            label=""
                            help={this.state.helpage}
                            validateStatus={
                              this.state.errorage ? "error" : "success"
                            }
                          >
                            {getFieldDecorator("late_age", {
                              initialValue: searchAllValues
                                ? searchAllValues.late_age
                                : null
                            })(
                              <InputNumber
                                className="age-format-unit"
                                // formatter={value => value ? `${value}Ma` : ""}
                                // parser={value => value.replace('Ma', '')}
                                placeholder=""
                                onChange={value =>
                                  this.sectionStartChange(value, "age", "from")
                                }
                                step={0.01}
                                min={0}
                              ></InputNumber>
                            )}
                          </FormItem>
                        </span>
                        <span className="search-line">
                          <span className="iconfont icon-line1"></span>
                        </span>
                        <span>
                          <FormItem
                            label=""
                            help={this.state.helpage}
                            validateStatus={
                              this.state.errorage ? "error" : "success"
                            }
                          >
                            {getFieldDecorator("early_age", {
                              initialValue: searchAllValues
                                ? searchAllValues.early_age
                                : ""
                            })(
                              <InputNumber
                                className="age-format-unit"
                                // formatter={value => value ? `${value}Ma` : ""}
                                // parser={value => value.replace('Ma', '')}
                                onChange={value =>
                                  this.sectionEndChange(value, "age", "to")
                                }
                                step={0.01}
                                min={0}
                              ></InputNumber>
                            )}
                          </FormItem>
                        </span>
                      </div>
                    </div>
                  </div>
                  :
                  null

                  }
                  
                  {this.state.unitPartShow === true ? (
                    <div className="referenceAdd-journal">
                      <Form.Item
                        label={
                          <div className="sectionGeography-scale">
                            <p>Litho-unit</p>
                          </div>
                        }
                      >
                        {getFieldDecorator("unit_litho", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.unit_litho
                            : ""
                        })(<Input />)}
                      </Form.Item>
                    </div>
                  ) : null}

                  <div className="sectionGeography-map sectionGeography-map-nopadding">
                    <div className="sectionGeography-tutude sectionGeography-tutude-title sectionGeography-Lithology">
                      <span>Color1</span>
                      <span>Color2</span>
                      <span>Lithology1</span>
                      <span>Lithology2</span>
                      <span>Main Lithology</span>
                    </div>
                    <div className="sectionGeography-tutude">
                      <span className="sectionGeography-map-label">
                        Lithology
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Color1：</p>}
                        >
                          {getFieldDecorator("unit_color1", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_color1
                              : ""
                          })(<Input placeholder=""></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Color2：</p>}
                        >
                          {getFieldDecorator("unit_color2", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_color2
                              : ""
                          })(<Input placeholder=""></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Lithology1：</p>}
                        >
                          {getFieldDecorator("unit_lithology1", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_lithology1
                              : ""
                          })(<Input placeholder=""></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Lithology2：</p>}
                        >
                          {getFieldDecorator("unit_lithology2", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_lithology2
                              : ""
                          })(<Input placeholder=""></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Main Lithology：</p>}

                        >
                          {getFieldDecorator("unit_main_lithogy", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_main_lithogy
                              : ""
                          })(<Input placeholder=""></Input>)}
                        </FormItem>
                      </span>
                      {/* <span>
                        <FormItem
                          label={<p className="h5-map-title">Relation：</p>}
                        >
                          {getFieldDecorator("unit_relation", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_relation
                              : ""
                          })(
                            <Select placeholder="">
                              <Option value="and">And</Option>
                              <Option value="interbedded by with some">
                                Interbedded by with some
                              </Option>
                              <Option value="with on the top">
                                With on the top
                              </Option>
                              <Option value="with in the middle">
                                With in the middle
                              </Option>
                              <Option value="with at the base">
                                With at the base
                              </Option>
                            </Select>
                          )}
                        </FormItem>
                      </span> */}
                    </div>
                    {/* <div className="sectionGeography-tutude">
                      <span className="sectionGeography-map-label"></span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Color1：</p>}
                        
                        >
                          {getFieldDecorator("unit_color1b", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_color1b
                              : ""
                          })(<Input placeholder="color1b"></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Color2：</p>}
                         
                        >
                          {getFieldDecorator("unit_color2b", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_color2b
                              : ""
                          })(<Input placeholder="color2b"></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Lithology1：</p>}
                         
                        >
                          {getFieldDecorator("unit_lithology1b", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_lithology1b
                              : ""
                          })(<Input placeholder="lithology1b"></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Lithology2：</p>}
                       
                        >
                          {getFieldDecorator("unit_lithology2b", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_lithology2b
                              : ""
                          })(<Input placeholder="lithology2b"></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Relation：</p>}
                       
                        >
                          {getFieldDecorator("unit_relationb", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_relationb
                              : ""
                          })(
                            <Select placeholder="relationb">
                              <Option value="and">And</Option>
                              <Option value="interbedded by with some">
                                Interbedded by with some
                              </Option>
                              <Option value="with on the top">
                                With on the top
                              </Option>
                              <Option value="with in the middle">
                                With in the middle
                              </Option>
                              <Option value="with at the base">
                                With at the base
                              </Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                    </div>
                    <div className="sectionGeography-tutude">
                      <span className="sectionGeography-map-label"></span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Color1：</p>}
                         
                        >
                          {getFieldDecorator("unit_color1c", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_color1c
                              : ""
                          })(<Input placeholder="color1c"></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Color2：</p>}
                        
                        >
                          {getFieldDecorator("unit_color2c", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_color2c
                              : ""
                          })(<Input placeholder="color2c"></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Lithology1：</p>}
                        
                        >
                          {getFieldDecorator("unit_lithology1c", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_lithology1c
                              : ""
                          })(<Input placeholder="lithology1c"></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Lithology2：</p>}
                         
                        >
                          {getFieldDecorator("unit_lithology2c", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_lithology2c
                              : ""
                          })(<Input placeholder="lithology2c"></Input>)}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem
                          label={<p className="h5-map-title">Relation：</p>}
                         
                        >
                          {getFieldDecorator("unit_relationc", {
                            initialValue: searchAllValues
                              ? searchAllValues.unit_relationc
                              : ""
                          })(
                            <Select placeholder="relationc">
                              <Option value="and">And</Option>
                              <Option value="interbedded by with some">
                                Interbedded by with some
                              </Option>
                              <Option value="with on the top">
                                With on the top
                              </Option>
                              <Option value="with in the middle">
                                With in the middle
                              </Option>
                              <Option value="with at the base">
                                With at the base
                              </Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                    </div>
                 */}
                  </div>
                  {this.state.unitPartShow === true ? (
                    <div className="referenceSearch-base">
                      <div className="referenceSearch-autor section-duration">
                        <FormItem label="Duration">
                          <div className="referenceSearch-author">
                            <span>
                              {getFieldDecorator("unit_duration", {
                                initialValue: searchAllValues
                                  ? searchAllValues.unit_duration
                                  : []
                              })(
                                // <AutoComplete
                                //   onSearch={value =>
                                //     this.handleNomSearch(value, "durations")
                                //   }
                                //   onChange={value =>
                                //     this.handleNomChange(value, "unit_duration")
                                //   }
                                //   placeholder=""
                                // >
                                //   {durations}
                                // </AutoComplete>
                                <Select
                                  mode="multiple"
                                  placeholder="You can choose  chronostratigraphic units"

                                  onSearch={value =>
                                    this.handleNomSearch(value, "durations")
                                  }
                                  onSelect={(value, e) =>
                                    this.handleDuration(value, "unit_duration", e)
                                  }
                                  onDeselect={(value, e) =>
                                    this.handleonBlure(value, "unit_duration", e)
                                  }
                                  style={{ width: '100%' }}
                                >
                                  {durations}
                                </Select>
                              )}
                            </span>

                            <span>
                              <span className="duration-search-type">
                                Search Type
                              </span>
                              {getFieldDecorator("duration_search_type", {
                                initialValue: searchAllValues
                                  ? searchAllValues.duration_search_type
                                  : ""
                              })(
                                <Radio.Group
                                // onChange={this.onChange}
                                // value={this.state.value}
                                >
                                  <Radio value="or">or</Radio>
                                  <Radio value="and">and</Radio>
                                </Radio.Group>
                              )}
                            </span>
                          </div>
                        </FormItem>
                      </div>
                    </div>
                  ) : null}
                  <div className="referenceAdd-comments">
                    <Form.Item label="Notes" className="long-label">
                      {getFieldDecorator("section_notes", {
                        rules: [{}],

                        initialValue: searchAllValues
                          ? searchAllValues.section_notes
                          : ""
                      })(<TextArea rows={5}></TextArea>)}
                    </Form.Item>
                  </div>
                  <div className="referenceAdd-comments last-formItem">
                    <Form.Item label="Paleoenvironment" className="long-label">
                      {getFieldDecorator("formation_paleoenvironment", {
                        rules: [{}],

                        initialValue: searchAllValues
                          ? searchAllValues.formation_paleoenvironment
                          : ""
                      })(<TextArea rows={5}></TextArea>)}
                    </Form.Item>
                  </div>
                  {showAlloption === true ? (
                    <div className="referenceSearch-base">
                      <div className="referenceSearch-autor section-duration">
                        <FormItem>
                          <div className="referenceSearch-author">
                            <span>
                              <span className="duration-search-type">
                                Contains only chemical data
                              </span>
                              {getFieldDecorator("hasGeochemistry", {
                                initialValue: "0"
                              })(
                                <Radio.Group>
                                  <Radio value="0">no</Radio>
                                  <Radio value="1">yes</Radio>
                                </Radio.Group>
                              )}
                            </span>
                          </div>
                        </FormItem>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : showOccurrence ? (
                <div>
                  {
                    <div>
                      {/* <div className="referenceSearch-base">
                        <div className="referenceSearch-autor referenceSearch-line">
                         
                            <div className="referenceSearch-author searchReference-reference">
                              <span className="search-line-pre">
                              <FormItem
                            label="Occurrence ID"
                         
                            help={this.state.helpoccurrence}
                            validateStatus={
                              this.state.erroroccurrence ? "error" : "success"
                            }
                          >
                                {getFieldDecorator("id_from", {
                                  rules: [
                                    {
                                      type: "number"
                                    }
                                  ],
                                  initialValue: searchAllValues
                                    ? searchAllValues.id_from
                                    : ""
                                })(
                                  <InputNumber
                                    min={1}
                                    placeholder=""
                                    onChange={value =>
                                      this.sectionStartChange(
                                        value,
                                        "id",
                                        "from"
                                      )
                                    }
                                  ></InputNumber>
                                )}
                                 </FormItem>
                              </span>
                              <span className="search-line">
                                <span className="iconfont icon-line1"></span>
                              </span>
                              <span>
                              <FormItem
                            label=""
                           
                            help={this.state.helpoccurrence}
                            validateStatus={
                              this.state.erroroccurrence ? "error" : "success"
                            }
                          >
                                {getFieldDecorator("id_to", {
                                  rules: [
                                    {
                                      type: "number"
                                    }
                                  ],
                                  initialValue: searchAllValues
                                    ? searchAllValues.id_to
                                    : ""
                                })(
                                  <InputNumber
                                    min={1}
                                    onChange={value =>
                                      this.sectionEndChange(value, "id", "to")
                                    }
                                  ></InputNumber>
                                )}
                                 </FormItem>
                              </span>
                            </div>
                         
                        </div>
                      </div> */}
                      <div className="referenceSearch-base">
                        <div className="referenceSearch-autor searchReference-book   referenceSearch-line">
                          <div className="referenceAdd-author-title">
                            Reference ID
                          </div>
                          <div className="referenceSearch-author">
                            <span className="search-line-pre">
                              <FormItem label="">
                                {getFieldDecorator("ref_id_from", {
                                  rules: [{ type: "number" }],
                                  initialValue: searchAllValues
                                    ? searchAllValues.ref_id_from
                                    : ""
                                })(<InputNumber placeholder=""></InputNumber>)}
                              </FormItem>
                            </span>
                            <span className="search-line">
                              <span className="iconfont icon-line1"></span>
                            </span>
                            <span>
                              <FormItem label="">
                                {getFieldDecorator("ref_id_to", {
                                  rules: [{ type: "number" }],
                                  initialValue: searchAllValues
                                    ? searchAllValues.ref_id_to
                                    : ""
                                })(<InputNumber placeholder=""></InputNumber>)}
                              </FormItem>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="referenceSearch-base">
                        <div className="referenceSearch-autor searchReference-book   referenceSearch-line">
                          <div className="referenceAdd-author-title">
                            Section ID
                          </div>
                          <div className="referenceSearch-author">
                            <span className="search-line-pre">
                              <FormItem label="">
                                {getFieldDecorator("section_basic_id_from", {
                                  rules: [{ type: "number" }],
                                  initialValue: searchAllValues
                                    ? searchAllValues.section_basic_id_from
                                    : ""
                                })(<InputNumber placeholder=""></InputNumber>)}
                              </FormItem>
                            </span>
                            <span className="search-line">
                              <span className="iconfont icon-line1"></span>
                            </span>
                            <span>
                              <FormItem label="">
                                {getFieldDecorator("section_basic_id_to", {
                                  rules: [{ type: "number" }],
                                  initialValue: searchAllValues
                                    ? searchAllValues.section_basic_id_to
                                    : ""
                                })(<InputNumber placeholder=""></InputNumber>)}
                              </FormItem>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="referenceSearch-book">
                        <Form.Item label="Authorizer">
                          {getFieldDecorator("authorizer_id", {
                            rules: [{}],
                            initialValue: searchAllValues
                              ? searchAllValues.authorizer_id
                              : ""
                          })(
                            <AutoComplete
                              onSearch={value =>
                                this.handleNomSearch(value, "authorizers")
                              }
                              onChange={value =>
                                this.handleNomChange(value, "authorizer_id")
                              }
                              placeholder=""
                            >
                              {authorizers}
                            </AutoComplete>
                          )}
                        </Form.Item>
                      </div>

                      <div className="referenceSearch-book">
                        <Form.Item label="Auth time">
                          {getFieldDecorator("auth_time", {
                            rules: [{}],
                            initialValue: searchAllValues
                              ? searchAllValues.auth_time
                              : ""
                          })(<Input></Input>)}
                        </Form.Item>
                      </div>
                    </div>
                  }
                  {/* 
                  <div className="referenceSearch-base">
                    <div className="referenceSearch-autor searchReference-book   referenceSearch-line">
                      <FormItem label="Reference ID">
                        <div className="referenceSearch-author">
                          <span className="search-line-pre">
                            {getFieldDecorator("ref_id_from", {
                              rules: [{ type: "number" }],
                              initialValue: ""
                            })(<InputNumber placeholder=""></InputNumber>)}
                          </span>
                          <span className="search-line">
                            <span className="iconfont icon-line1"></span>
                          </span>
                          <span>
                            {getFieldDecorator("ref_id_to", {
                              rules: [{ type: "number" }],
                              initialValue: ""
                            })(<InputNumber placeholder=""></InputNumber>)}
                          </span>
                        </div>
                      </FormItem>
                    </div>
                  </div>
                  <div className="referenceSearch-base">
                    <div className="referenceSearch-autor searchReference-book   referenceSearch-line">
                      <FormItem label="Depth">
                        <div className="referenceSearch-author">
                          <span className="search-line-pre">
                            {getFieldDecorator("depthfrom", {
                              rules: [{ type: "number" }],
                              initialValue: ""
                            })(<InputNumber placeholder=""></InputNumber>)}
                          </span>
                          <span className="search-line">
                            <span className="iconfont icon-line1"></span>
                          </span>
                          <span>
                            {getFieldDecorator("depthto", {
                              rules: [{ type: "number" }],
                              initialValue: ""
                            })(<InputNumber placeholder=""></InputNumber>)}
                          </span>
                        </div>
                      </FormItem>
                    </div>
                  </div>

                  <div className="referenceAdd-authours">
                    <FormItem label="Depth unit" className="long-label">
                      <div className="referenceAdd-author ">
                        <span>
                          {getFieldDecorator(`depth_unit`, {
                            initialValue: ""
                          })(
                            <Select name="scDepthUnit" id="scDepthUnit">
                              <Option value="meters">meters</Option>
                              <Option value="inches">inches</Option>
                              <Option value="cm">cm</Option>
                              <Option value="feet">feet</Option>
                            </Select>
                          )}
                        </span>
                      </div>
                    </FormItem>
                  </div>

                  <div className="referenceAdd-authours">
                    <FormItem label="Value" className="long-label">
                      <div className="referenceAdd-author ">
                        <span>
                          {getFieldDecorator("value", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: ""
                          })(<InputNumber placeholder=""></InputNumber>)}
                        </span>
                      </div>
                    </FormItem>
                  </div>

                  <div className="referenceAdd-authours">
                    <FormItem label="Unit" className="long-label">
                      <div className="referenceAdd-author ">
                        <span>
                          {getFieldDecorator(`value_unit`, {
                            rules: [
                              {
                                // required: true,
                                // message: "Please select the Unit"
                              }
                            ]
                          })(
                            <Select name="scValueUnit" id="scValueUnit">
                              <Option value="%">%</Option>
                              <Option value="‰">‰</Option>
                              <Option value="ppm">ppm</Option>
                              <Option value="ppb">ppb</Option>
                              <Option value="mg/g">mg/g</Option>
                              <Option value="°C">°C</Option>
                            </Select>
                          )}
                        </span>
                      </div>
                    </FormItem>
                  </div>

                  <div className="referenceAdd-authours">
                    <FormItem label="Standard deviation" className="long-label">
                      <div className="referenceAdd-author ">
                        <span>
                          {getFieldDecorator(`standard_deviation`, {
                            initialValue: ""
                          })(<InputNumber placeholder=""></InputNumber>)}
                        </span>
                      </div>
                    </FormItem>
                  </div>
                </div> */}
                </div>
              ) : null}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

SectionOption = Form.create({})(SectionOption);
export default SectionOption;
