import React, { Component } from "react";
import { Button, Input, Modal, List, Spin } from "antd";
import { NavLink } from "react-router-dom";
import "./index.scss";
import "./index.css";
import Utils from "src/js/utils";
import Apis from "src/js/apis/api";

import TaxonSearch from "commons/AllOptions/Taxonomy/CollectionSearch/index.js";

import InfiniteScroll from "react-infinite-scroller";
import CollectionOption from "commons/AllOptions/Collection/index.js";

import CollectionTableResult from "commons/AllTableList/Collection/index.js";
import CollectionDetailForm from "commons/AllDetails/Collection/index.js";
import "src/js/global.js";
const { Search } = Input;
class AddTaxonomies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchTaxon: false,
      fossilist: [],
      fossilistObj: {},
      unitInfo: {},
      sectionInfo: {},
      showCollCondition: false,
      isSearch: false,
      collectInfo: {},

      combinationName: "",

      loading: false,
      hasMore: true,
      allChildError: {},
      showBackIcon: false
    };
  }
  AddTaxon = () => { };
  searchTaxonCancel = () => {
    this.setState({
      showSearchTaxon: false
    });
  };
  goSearchTax = e => {
    let { collId } = this.state;
     e.target.blur();
    if (!collId) {
      Modal.error({
        title: "This is an error message",
        content: "Please select a collection"
      });
     
    } else {
      e.target.blur();
      this.setState({
        showSearchTaxon: true
      });
    }
  };
  checkCollectionOne = value => {
    this.setState(
      {
        collectionSearch: false,
        collectInfo: value,
        collId: value.coll_id,
        fossilist:[],
        fossilistObj:{},
       
        unitInfo: {},
        sectionInfo: {},
        combinationName: "",
        
      },
      async () => {
        let { collectInfo } = this.state;

        let params = {
          unit_id: collectInfo.unit_id
        };
        let collectionparams = {
          collection_id: collectInfo.coll_id
        };
        let getUnitsBack = await Apis.getUnits(params);
        let fossilOccurrenceBack = await Apis.searchAllFossilOccurrence(
          collectionparams
        );
        if (getUnitsBack.result && getUnitsBack.result.length > 0) {
          this.setState(
            {
              unitInfo: getUnitsBack.result[0]
            },
            async () => {
              let { unitInfo } = this.state;
              let sectionid = unitInfo.section_basic_id;

              let params = {
                section_id: sectionid
              };

              let sectionSearchBack = await Apis.sectionBase(params);
              if (sectionSearchBack.result && sectionSearchBack.result.length > 0) {
                this.setState(
                  {
                    sectionInfo: sectionSearchBack.result[0]
                  },
                  () => { }
                );
              } else {
                this.setState({
                  sectionInfo: {}
                })
              }


            }
          );
        }else if(getUnitsBack.result && getUnitsBack.result.length ==0){
          this.setState(
            {
              unitInfo: {},
              sectionInfo: {}
            },
         
          );

        }
        else if (getUnitsBack.error) {
          Modal.error({
            title: "This is an error message",
            content: "No information found"
          });
          this.setState({

            unitInfo: {}
          });
        }
        if (fossilOccurrenceBack.result && fossilOccurrenceBack.result.length > 0) {
          this.setState(
            {
              fossilOccurrence: fossilOccurrenceBack.result[0]
            },
            async () => {
              let { fossilOccurrence } = this.state;
              let id = fossilOccurrence ? fossilOccurrence.id : "";
              let params = {
                fossiloccurrence_id: id
              };
              let AllFossilByOccurrence = await Apis.searchAllFossilByOccurrence(
                params
              );
              if (AllFossilByOccurrence.result) {
                this.setState({
                  fossilist: AllFossilByOccurrence.result
                });
              } else {
                Modal.error({
                  title: "This is an error message",
                  content: AllFossilByOccurrence.error
                });
              }
            }
          );
        } else if (fossilOccurrenceBack.error) {
          this.setState({
            fossilOccurrence: {}
          })
          Modal.error({
            title: "This is an error message",
            content: fossilOccurrenceBack.error
          });
        }
      }
    );
  };
  AddTaxonomies = (value, combinationName, comment) => {

    let { fossilist, fossilistObj } = this.state;

    // fossilistObj[value.taxonmixed_id]=value.taxonmixed_id
    this.setState(
      {
        showSearchTaxon: false,
        combinationName,
        fossilist: [value, ...fossilist],
        fossilistObj
      },
      () => {
       
      }
    );
  };
  async componentDidMount() {
    let host = window.location.host;
    
    // let host2 = document.domain;
    let historyLength = this.props.history.length
    if (historyLength > 1) {
      this.setState({
        showBackIcon: true
      })
    }
    if (this.props.location.search) {
      let inheritRefIds =
        Utils.getSearch(this.props.location.search, "refId") || "";
      let unitId = Utils.getSearch(this.props.location.search, "unitId") || "";
      let collId = Utils.getSearch(this.props.location.search, "collId") || "";
      this.setState(
        {
          inheritRefIds,
          unitId,
          collId,
          isSearch: true
        },
        async () => {
          let params = {
            unit_id: unitId
          };
          let collectParams = {
            coll_id: collId
          };
          let getUnitsBack = await Apis.getUnits(params);
          let getCollectionsBack = await Apis.getCollections(collectParams);

          if (getUnitsBack.result && getUnitsBack.result.length > 0) {
            this.setState(
              {
                unitInfo: getUnitsBack.result[0]
              },
              async () => {
                let { unitInfo } = this.state;
                let sectionid = unitInfo.section_basic_id;

                let params = {
                  section_id: sectionid
                };
                let sectionSearchBack = await Apis.sectionBase(params);
                if (sectionSearchBack.result && sectionSearchBack.result.length > 0) {
                  this.setState({
                    sectionInfo: sectionSearchBack.result[0]
                  });
                } else {
                  this.setState({
                    sectionInfo: {}
                  });
                }

              }
            );
          } else {
            Modal.error({
              title: "This is an error message",
              content: "No information found"
            });
            this.setState({
              unitInfo: {}
            });
          }

          if (getCollectionsBack.result && getCollectionsBack.result.length > 0) {
            this.setState(
              {
                collectInfo: getCollectionsBack.result[0]
              },
              async () => {
                let { collectInfo } = this.state;
                let params = {
                  collection_id: collectInfo.coll_id
                };
                let fossilOccurrenceBack = await Apis.searchAllFossilOccurrence(
                  params
                );
                if (fossilOccurrenceBack.result && fossilOccurrenceBack.result.length > 0) {
                  this.setState(
                    {
                      fossilOccurrence: fossilOccurrenceBack.result[0]
                    },
                    async () => {
                      let { fossilOccurrence } = this.state;
                      let id = fossilOccurrence.id;
                      let params = {
                        fossiloccurrence_id: id
                      };
                      let AllFossilByOccurrence = await Apis.searchAllFossilByOccurrence(
                        params
                      );

                      if (AllFossilByOccurrence.result) {
                        this.setState({
                          fossilist: AllFossilByOccurrence.result
                        });
                      } else {
                        Modal.error({
                          title: "This is an error message",
                          content: AllFossilByOccurrence.error
                        });
                      }
                    }
                  );
                } else {
                  if (fossilOccurrenceBack.error) {
                    Modal.error({
                      title: "This is an error message",
                      content: fossilOccurrenceBack.error
                    });
                  }

                }
              }
            );
          } else {
            Modal.error({
              title: "This is an error message",
              content: "No information found"
            });
            this.setState({
              collectInfo: {}
            });
          }
        }
      );
    } else {
      this.setState({
        isSearch: false
      });
    }
  }
  openCollCondition = () => {
    this.setState({
      showCollCondition: true
    });
  };
  handleCollectOk = () => {
    this.setState({
      showCollCondition: false
    });
  };
  handleCollectCancel = () => {
    this.setState({
      showCollCondition: false
    });
  };
  getFossilDetail = () => { };

  // 新整理
  onCollection = ref => {
    this.collectionChild = ref;
  };
  handleCollectionOk = () => {
    this.setState({
      collectionSearch: false
    });
  };
  handleCollectionCancel = () => {
    this.setState({
      collectionSearch: false
    });
  };
  detailCollectionFormOk = () => {
    this.setState({
      showCollDetailForm: false
    });
  };
  detailCollectionFormCancel = () => {
    this.setState({
      showCollDetailForm: false
    });
  };

  checkAgain = optionType => {
    // let { allChildError } = this.state;
    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      let showType = "collectionSearch";

      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            showCollCondition: false,
            [showType]: true,

            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues } = this.state;
              this.collectionChild.searchCollectionDatas(
                searchParams,
                searchValues
              );
            }, 20);
          }
        );
      }
    });
  };
  collectionDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showCollDetailForm: true
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  goHistoryBack = () => {
    this.props.history.push("/collection/add");
    // this.props.history.goBack();
  };
  handleInfiniteOnLoad = () => {

  };
  render() {
    let {
      showSearchTaxon,
      unitInfo,
      collId,
      sectionInfo,
      collectInfo,
      fossilist,
      fossilistObj,
      searchParams,
      searchValues
    } = this.state;

    return (
      <div className="main">
        <div className="searchBase-title collectionAdd-title">
          <div className="reference-search-operate">
            <div className="head">
              <div>Add Fossil</div>
              <div className="common-back common-back-hide">
                {/* <NavLink to="/collection/add"> */}
                {
                  this.state.showBackIcon ?
                    <span
                      className="iconfont icon-back"
                      onClick={this.goHistoryBack}
                    ></span>
                    :
                    null
                }
                {/* </NavLink> */}
              </div>
            </div>
          </div>
        </div>

        <div id="addTaxonomies-main">
          <div className="content">
            <div className="title">
              {collId ? (
                <p>
                  <span>[{JSON.stringify(sectionInfo) !== "{}" ? sectionInfo.section_name : ""}]</span>
                  <span>[{JSON.stringify(unitInfo) !== "{}" ? unitInfo.unit_name : ""}]</span>
                  <span>[{collId ? collId : ""}]</span>
                </p>
              ) : null}

              {/* <p>
                Consult the
                <span> occurrence tip sheet </span>
                and
                <span> plant organ tip sheet</span>
              </p> */}
            </div>

            <div>
              <div className="addtaxon-search-item">
                <Search
                  placeholder="search taxon"
                  onFocus={e => this.goSearchTax(e)}
                />
              </div>
            </div>
            {/* <div className="addTaxonomies-search-coll">
              <Button type="gbdb" onClick={this.openCollCondition}>
                Search Collection
              </Button>
            </div> */}
            <div className="list">
              <div className="tip addTaxonomies-show-result">
                <span> Taxon ID </span>
                <span> Taxon name </span>
                <span>Reference</span>
                {/* <span className="h5-view-title">View</span> */}
                <span>Comment</span>
                {/* <span>Detail</span> */}
              </div>

              <div className="fossil-infinite-container">
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={this.handleInfiniteOnLoad}
                  hasMore={!this.state.loading && this.state.hasMore}
                  useWindow={false}
                >
                  <List>
                    {fossilist.map((item, index) => {
                      let Name = Utils.fullName(
                        item.taxon_name1,
                        item.taxon_name2,
                        item.genus_prefix,
                        item.sp_prefix1,
                        item.sp_prefix2,
                        item.sp_name_dict,
                        item.sp_suffix
                      );
                      fossilistObj[item.taxonmixed_id] = item.taxonmixed_id;
                      return (
                        <List.Item
                          key={index}
                          className="link-fossilist-item"
                        >
                          <span>{item.taxonmixed_id}</span>

                          <span title={Name}>{Name}</span>
                          <span>{item.ref_id}</span>
                          <span title={item.comment}>{item.comment}</span>
                          {/* <span>
                          <Button
                          type="gbdb"
                          onClick={() => this.getFossilDetail()}
                        >
                          <div>Details</div>
                        </Button>
                          </span>  */}
                          {/* <span>
                    <span className="pc-view-title">View</span>
                    <Input></Input>
                  </span> */}
                        </List.Item>
                      );
                    })}
                    {this.state.loading && this.state.hasMore && (
                      <div className="demo-loading-container">
                        <Spin />
                      </div>
                    )}
                  </List>
                </InfiniteScroll>
              </div>
            </div>
          </div>

          {/* <div className="addBtn">
            <Button type="gbdb" onClick={() => this.AddTaxon()}>
              <div>Add </div>
            </Button>
          </div> */}
          {/* <div className="saveBtn">
            <Button type="gbdb">
              <p>Save and</p>
              <p>Continue</p>
            </Button>
          </div> */}
        </div>

        <Modal
          className="TaxonSearch-all-modal"
          width="76.8%"
          title=""
          visible={showSearchTaxon}
          onCancel={this.searchTaxonCancel}
          destroyOnClose={true}
          footer={null}
        >
          <div className="">
            <TaxonSearch
              collectInfo={collectInfo}
              AddTaxonomies={this.AddTaxonomies}
              fossilistObj={this.state.fossilistObj}
            ></TaxonSearch>
          </div>
        </Modal>
        <Modal
          destroyOnClose
          visible={this.state.showCollCondition}
          className="searchResultModal taxResultModal"
          width="76.8%"
          footer={null}
          header={null}
          onOk={this.handleCollectOk}
          onCancel={this.handleCollectCancel}
        >
          <div>
            <CollectionOption
              childError={this.childError}
              ref="collection"
            ></CollectionOption>
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("collection");
                  }}
                >
                  <p> Search</p>
                  <p>Collection</p>
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.collectionSearch}
          className="searchResultModal taxResultModal noBottomPadding tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleCollectionOk}
          onCancel={this.handleCollectionCancel}
        >
          <CollectionTableResult
            searchParams={searchParams}
            searchValues={searchValues}
            onCollection={this.onCollection}
            changeCheckSelected={this.changeCheckSelected}
            collectionDetail={this.collectionDetail}
            checkOne={true}
            checkCollectionOne={this.checkCollectionOne}
          ></CollectionTableResult>
        </Modal>
        <Modal
          title=""
          destroyOnClose
          visible={this.state.showCollDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76%"
          footer={null}
          // header={null}
          onOk={this.detailCollectionFormOk}
          onCancel={this.detailCollectionFormCancel}
        >
          <CollectionDetailForm
            detailData={this.state.detailData}
            seeOnly={true}
          ></CollectionDetailForm>
        </Modal>
      </div>
    );
  }
}

export default AddTaxonomies;
