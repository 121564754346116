import React, { Component } from "react";
import { Form, Input, Button, DatePicker, Modal, message } from "antd";

import BaseUpload from "commons/BaseUpload/index";
import userApis from "src/js/apis/user";
import Storage from "src/js/storage";
import Utils from "src/js/utils";
import moment from "moment";
import "./index.css";
import "src/js/global.js";

let userIdentity = (userType, authState) => {
  let Identity = "";
  // 认证中
  if (authState === 0) {
    Identity = "Not veryfied";
  } else if (authState === 1) {
    Identity = "Veryfying";
  } else if (authState === 2 && userType == 1) {
    Identity = "Data compilier";
  } else if (authState === 3) {
    switch (userType) {
      case 0:
        Identity = "Not veryfied";
        break;
      case 1:
        Identity = "Data compilier";
        break;
      case 2:
        Identity = "Expert";
        break;
      default:
        Identity = "";
    }
  } else if (authState === 4) {
    Identity = "Failed";
  } else if (authState === 5 && userType == 1) {
    Identity = "Data compilier";
  }
  return Identity;
};
let showCompilerData = (userType, authState) => {
  let showUser = false;
  if (authState === 1) {
    showUser = true;
  } else if (authState === 3 && userType === 1) {
    showUser = true;
  } else if (authState === 4 && userType !== 2) {
    showUser = true;
  } else if (authState === 5 && userType == 1) {
    showUser = true;
  }
  return showUser;
};
class Compiler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadFiles: {},
      userData: {},
      filePath: process.env.PUBLIC_URL + "/imgs/uploadUser.png",
      otherPddbs: [],
      showCheckInput: false,
      checker: ""
    };
  }
  changeCompiler = () => {
    let that = this;
    Modal.confirm({
      title: "Make sure  to be a data compiler?",
      content: "the application data will overwrite the expert's data",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.props.form.validateFieldsAndScroll(async (err, values) => {
          let defaultPath = "/api/assets/user/default/upload.png";
          let { filePath, uploadFiles } = that.state;

          if (filePath == defaultPath && JSON.stringify(uploadFiles) === "{}") {
            message.error("Please upload proof pictures");
            return;
          }
          if (!err) {
            let pbdbStr = "";
            // let compilerData = Utils.filterParams(values);
            let compilerData = values;
            let { otherPddbs } = that.state;
            if (otherPddbs.length > 0) {
              pbdbStr = otherPddbs.join(",");
              compilerData.otherpddb = pbdbStr;
            }
            if (compilerData.study_time_start) {
              compilerData.study_time_start = moment(
                compilerData.study_time_start
              ).format("YYYY-MM-DD h:mm:ss");
            }
            if (compilerData.study_time_end) {
              compilerData.study_time_end = moment(
                compilerData.study_time_end
              ).format("YYYY-MM-DD h:mm:ss");
            }
            const formData = new FormData();
            let { userData } = that.state;
            let id = userData.id;
            formData.append("id", id);
            let files = that.state.uploadFiles;
            for (let [key, value] of Object.entries(compilerData)) {
              formData.append(key, value);
            }

            formData.append("files", files);

            let compilerUserBack = await userApis.userAuthCompiler(formData);
            if (compilerUserBack.error) {
              Modal.error({
                title: "This is an error message",
                content: compilerUserBack.error
              });
            } else {
              Modal.success({
                title: "This is a successful message",
                content: "Modify successfully"
              });
            }
          }
        });
      },
      onCancel() {
     
      }
    });
  };
  cancelCompiler = () => {
    this.props.form.resetFields();
    Modal.info({
      title: "This is a notification message",
      content: "The modification message has been reset"
    });
  };
  onChangeFile = file => {
    let newest = file;
    this.setState(
      {
        uploadFiles: newest
      },
      () => {}
    );
  };
  isGbdbUser = () => {
    let token = Storage.getItem("token");

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    }
  };
  async componentDidMount() {
    this.isGbdbUser();
    let allUserData = {};
    let token = Storage.getItem("token");
    let params = {
      token,
      loginby: 1
    };
    let fossilBack = await userApis.initUser(params);
    if (fossilBack.result) {
      let updateUser = {
        userid: fossilBack.result.id,
        loginby: 1
      };
      let gbdbUserBack = await userApis.gbdbUser(updateUser);
      if (gbdbUserBack.result) {
        let gbdbData = gbdbUserBack.result;
        if (gbdbData) {
          let { userType, authState } = gbdbData;
          // let isCompiler = showCompilerData(userType, authState);

          // if (isCompiler === false) {
          //   let userData = {};
          //   this.setState({
          //     userData: Object.assign(userData, fossilBack.result,gbdbData, {
          //       id: gbdbData.id,
          //       authState: authState,
          //       userType: userType,
          //     })
          //   });

          //   return;
          // }
        }

        let baseData = fossilBack.result;
        Object.assign(allUserData, gbdbData, baseData);
        // 设置默认图片
        this.setState(
          {
            filePath: "/api" + gbdbData.filePath
          },
          () => {}
        );
        // 设置标签
        let otherpddb = gbdbData.otherpddb;
        if (otherpddb !== "" && otherpddb.indexOf(",") !== -1) {
          let otherPddbs = otherpddb.split(",");
          this.setState({
            otherPddbs
          });
        } else if (otherpddb !== "" && otherpddb.indexOf(",") === -1) {
          let otherPddbs = [otherpddb];

          this.setState({
            otherPddbs
          });
        }

        let showCheckInput = gbdbData.checker;
        if (showCheckInput) {
          this.setState({
            showCheckInput: false
          });
        } else {
          this.setState({
            showCheckInput: true
          });
        }
      } else {
        let url = "/home";
        this.props.history.push({ pathname: url });
      }

      this.setState({
        userData: allUserData
      });
    } else {
      if (fossilBack.state === 204) {
        Storage.removeItem("token");
        Storage.removeItem("fossilid");
        Storage.removeItem("gbdbid");
        Storage.removeItem("checkerid");
        Storage.removeItem("checkerName");
        Storage.removeItem("gbdblevel");
        Storage.removeItem("Inputerid");
        return;
      }
      Modal.error({
        title: "This is an error message",
        content: fossilBack.error
      });
      this.props.history.push("/home");
    }
  }
  addotherPbdb = () => {
    let { otherPddbs } = this.state;
    let pbdbValue = this.props.form.getFieldValue("otherpddb");
    if (pbdbValue !== "") {
      let hasIndex = otherPddbs.findIndex(function(value, index, arr) {
        return value === pbdbValue;
      });
      if (hasIndex === -1) {
        otherPddbs = [pbdbValue, ...otherPddbs];
        this.setState({
          otherPddbs
        });
        this.props.form.setFieldsValue({
          otherpddb: ""
        });
      } else {
        Modal.error({
          title: "This is an error message",
          content: "name repetition"
        });
      }
    }
  };
  deletePddbs = (item, index) => {
    let { otherPddbs } = this.state;
    otherPddbs.splice(index, 1);
    this.setState({
      otherPddbs
    });
  };
  changeCheckCode = () => {
    this.setState(
      {
        showCheckInput: true
      },
      () => {}
    );
  };
  cancelChangeCode = () => {
    this.setState(
      {
        showCheckInput: false
      },
      () => {}
    );
  };
  confirmChangeCode = async () => {
    let checkcode = this.props.form.getFieldValue("check_code_admin");
    let params = {
      check_code: checkcode,
      id: Storage.getItem("gbdbid")
    };
    let verifyCheckBack = await userApis.updateCheckCode(params);
    if (verifyCheckBack.error) {
      Modal.error({
        title: "This is an error message",
        content: verifyCheckBack.error
      });
    } else {
      let { userData } = this.state;
      let checkUser = verifyCheckBack.checker;
      let checkerName = `${checkUser.firstname}  ${checkUser.lastname}`;

      let lastData = {};
      let codeData = {
        check_code_admin: checkcode,
        checker: checkUser.id,
        checker_name: checkerName
      };

      Object.assign(lastData, userData, codeData);

      this.setState(
        {
          userData: lastData,
          showCheckInput: false,
          checker: checkUser.id
        },
        () => {
          Modal.success({
            title: "This is a successful message",
            content: "Modify  successfully"
          });
          Storage.setItem({
            name: "checkerid",
            value: checkUser.id
          });
        }
      );
    }
  };
  render() {
    let { getFieldDecorator } = this.props.form;
    let { filePath, userData, otherPddbs, showCheckInput } = this.state;

    return (
      <div id="compiler_main" className="">
        <div className="form-mian-content profile-basic">
          <div className="profile-basic-title compiler-title">
            <span>Data compiler</span>
          </div>
          <div className="profile-basic-content clearfix compiler-content">
            <div className="profile-basic-form">
              <Form layout="vertical">
                <div className="compiler-realname">
                  <Form.Item label="First Name">
                    {getFieldDecorator("firstname", {
                      initialValue: userData.firstname || "",
                      rules: [
                        {
                          required: true
                        }
                      ]
                    })(<Input placeholder="" />)}
                  </Form.Item>
                  <Form.Item label="Last Name">
                    {getFieldDecorator("lastname", {
                      initialValue: userData.lastname || "",
                      rules: [
                        {
                          required: true
                        }
                      ]
                    })(<Input placeholder="" />)}
                  </Form.Item>
                </div>
                <Form.Item label="Degree">
                  {getFieldDecorator("degree", {
                    initialValue: userData.degree || ""
                  })(<Input placeholder="" />)}
                </Form.Item>
                <Form.Item label="Graduate from">
                  {getFieldDecorator("graduate", {
                    initialValue: userData.graduate || ""
                  })(<Input placeholder="" />)}
                </Form.Item>
                <div className="compiler-realname compiler-study-time">
                  <Form.Item label="Study Time">
                    {getFieldDecorator("study_time_start", {
                      rules: [
                        {
                          type: "object"
                        }
                      ],
                      initialValue: userData.study_time_start
                        ? moment(userData.study_time_start)
                        : null
                    })(<DatePicker format="YYYY-MM-DD"></DatePicker>)}
                  </Form.Item>
                  <Form.Item label="">
                    {getFieldDecorator("study_time_end", {
                      rules: [
                        {
                          type: "object"
                        }
                      ],
                      initialValue: userData.study_time_start
                        ? moment(userData.study_time_end)
                        : null
                    })(<DatePicker format="YYYY-MM-DD"></DatePicker>)}
                  </Form.Item>
                </div>
                <Form.Item label="Present Institute">
                  {getFieldDecorator("preInstitute", {
                    initialValue: userData.preInstitute || ""
                  })(<Input placeholder="" />)}
                </Form.Item>
                <Form.Item label="Major in">
                  {getFieldDecorator("major", {
                    initialValue: userData.major || ""
                  })(<Input placeholder="" />)}
                </Form.Item>
                <Form.Item label="Other Palaeobiogeography Database">
                  {getFieldDecorator("otherpddb", {
                    initialValue: ""
                  })(<Input placeholder="" />)}
                  <Button
                    type="gbdb"
                    className="profile-change-password compiler-code-btn"
                    onClick={this.addotherPbdb}
                  >
                    Add
                  </Button>
                </Form.Item>

                {otherPddbs.length > 0 ? (
                  <div className="otherpbdb-datebase clearfix">
                    {otherPddbs.map((item, index) => {
                      return (
                        <span
                          className="otherpbdb-datebase-item"
                          key={item}
                          title={item}
                        >
                          {item}
                          <span
                            className="iconfont icon-baseline-close-px"
                            onClick={() => this.deletePddbs(item, index)}
                          ></span>
                        </span>
                      );
                    })}
                  </div>
                ) : null}

                <Form.Item label="Other Information">
                  {getFieldDecorator("otherInfo", {
                    initialValue: userData.otherInfo || ""
                  })(<Input placeholder="" />)}
                </Form.Item>
                {!showCheckInput ? (
                  <div className="profile-code-result">
                    <div className="profile-code-detail">
                      <div className="check-code-title">Check code</div>
                      <p className="checker-code-number">
                        <span>Checker Code: </span>
                        <span>{userData.check_code_admin}</span>
                      </p>
                      <p className="checker-code-name">
                        <span>Your checker: </span>
                        <span>{userData.checker_name}</span>
                      </p>
                    </div>
                    <div className="change-code-btn">
                      <Button
                        type="gbdb"
                        className="profile-change-password"
                        onClick={this.changeCheckCode}
                      >
                        Change
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Form.Item label="Check code">
                      {getFieldDecorator("check_code_admin", {
                        initialValue: userData.check_code_admin || ""
                      })(<Input placeholder="" />)}
                      {/* compiler-code-btn */}
                    </Form.Item>
                    <div className="profile-changes-code">
                      <Button
                        type="gbdb"
                        className="profile-change-password"
                        onClick={this.confirmChangeCode}
                      >
                        Confirm
                      </Button>
                      <Button
                        type="gbdb"
                        className="profile-change-password"
                        onClick={this.cancelChangeCode}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
              </Form>
            </div>

            <div className="profile-basic-photo">
              <div className="basic-photo-show">
                {/* <img src={require("src/assets/images/profile/upload.png")} /> */}
                <BaseUpload
                  showImg={true}
                  defaultImg={filePath}
                  listType="picture-card"
                  showUploadList={false}
                  onChangeFile={file => this.onChangeFile(file)}
                ></BaseUpload>
              </div>

              <div className="user-indentity-result">
                {userIdentity(userData.userType, userData.authState)}
              </div>

              {/* <div className="basic-upload-confirm">
                <Button type="gbdb" className="profile-change-password">
                  Confirm
                </Button>
              </div> */}
            </div>
          </div>

          <div className="profile-basic-btns">
            <Button
              type="gbdb"
              className="profile-change-password"
              onClick={this.changeCompiler}
            >
              Submit
            </Button>
            {/* <Button
              type="gbdb"
              className="profile-change-password"
              onClick={this.cancelCompiler}
            >
              Cancel
            </Button> */}
          </div>

          <div className="identity-tip">
            <div>Tips:</div>
            <div>
              Expert and data compiler identity mutually exclusive, both can
              only choose one
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Compiler = Form.create({})(Compiler);
export default Compiler;
