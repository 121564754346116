import React, { Component } from "react";
import Utils from "src/js/utils";

import Apis from "src/js/apis/api";
import InfiniteScroll from "react-infinite-scroller";

import GeochemicalOption from "commons/AllOptions/Geochemical/index.js";
import OccurenceTableResult from "commons/AllTableList/Section/Occurence/index.js";
import GeochemicalTableResult from "commons/AllTableList/Section/Geochemical/index.js";

import AddGeochemicalForm from "components/Section/geochemical/addGeochemicalForm.js";
import echarts from "echarts";
import "./index.css";
import "src/js/global.js";
import { Form, Input, Select, Button, Modal, List, message } from "antd";

const { Option } = Select;

class viewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      currentDataType: "",
      geochemialType: "",

      OccurrenceId: "",
      deepFromObject: {},
      valueObject: {},
      currentOption: {},
      baseId: "",
      inheritRefIds: "",
      geochemialId: "",
      Geochemicals: {},
      dataGeochemistryTypes: [],
      ShowType: "list",
      // 分页
      loading: false,
      hasMore: true,
      pageSize: 10,
      currentPage: 1,
      dataTotal: 0,

      // chart分页
      chartLoading: false,
      chartHasMore: true,
      chartPageSize: 30000,
      chartCurrentPage: 1,
      chartDataTotal: 0,

      showOccurrence: false,
      xInverse: false,

      geochemistryoccurrenceItemId: "",
      currentDataIndex: 0,
      allChildError: {},
      showBackIcon:{}
    };
  }
  handleInfiniteOnLoad = () => {
    let { dataList, dataTotal } = this.state;

    this.setState({
      loading: true
    });

    if (dataList.length >= dataTotal) {
      message.warning("Infinite List loaded all");
      this.setState({
        hasMore: false,
        loading: false
      });
      return;
    }
    let { pageSize, currentPage } = this.state;
    this.setState(
      {
        currentPage: currentPage + 1
      },
      () => {
        let pageParams = {
          pageSize,
          page: this.state.currentPage
        };
        this.getDatas(pageParams, res => {
          dataList = dataList.concat(res.result);
          this.setState({
            dataList,
            loading: false
          });
        });
      }
    );
  };
  resize = () => {
    this.myChart.resize();
  };

  async componentDidMount() {
    let host = window.location.host;
    
    // let host2 = document.domain;
    let historyLength = this.props.history.length
    if (historyLength > 1) {
      this.setState({
        showBackIcon: true
      })
    }
    if (this.props.location.search) {
      let inheritRefIds =
        Utils.getSearch(this.props.location.search, "refId") || "";
      let baseId = Utils.getSearch(this.props.location.search, "baseId") || "";
      let geochemialId =
        Utils.getSearch(this.props.location.search, "geochemialId") || "";

      this.setState(
        {
          isSearch: true,
          baseId,
          inheritRefIds,
          geochemialId
        },
        async () => {
          if (geochemialId) {
            this.setState({
              OccurrenceId: geochemialId
            });
            let searchParams = {
              id: geochemialId
            };
            let Geochemicals = await Apis.getGeochemicals(searchParams);

            if (Geochemicals.result) {
              this.setState(
                {
                  Geochemicals: Geochemicals.result[0]
                },
                async () => {
                  let GeochemistryTypes = await Apis.getGeochemistryTypes({
                    geochemistryoccurrence_id: geochemialId
                  });
                  if (GeochemistryTypes.result) {
                    this.setState({
                      dataGeochemistryTypes: GeochemistryTypes.result
                    });
                  } else {
                    this.setState({
                      dataGeochemistryTypes: []
                    });
                  }
                }
              );
            } else {
              Modal.error({
                title: "This is an error message",
                content: Geochemicals.error
              });
            }
          }
        }
      );
    } else {
      this.setState({
        isSearch: false
      });
    }
  }
  initData = async pageParams => {
    let { geochemialType, OccurrenceId } = this.state;
    let params = {
      geochemistry_type: geochemialType,
      geochemistryoccurrence_id: OccurrenceId
    };
    let searchParams = {};
    Object.assign(searchParams, params, pageParams);

    let getGeochemDataList = await Apis.getGeochemDataList(searchParams);

    //获取Select data的接口
    if (getGeochemDataList.result) {
      return getGeochemDataList;
    } else {
      Modal.error({
        title: "This is an error message",
        content: getGeochemDataList.error
      });
    }
  };
  getDatas = async (pageParams, callback) => {
    let { geochemialType, OccurrenceId } = this.state;
    let params = {
      geochemistry_type: geochemialType,
      geochemistryoccurrence_id: OccurrenceId
    };
    let searchParams = {};
    Object.assign(searchParams, params, pageParams);

    let getGeochemDataList = await Apis.getGeochemDataList(searchParams);

    //获取Select data的接口
    if (getGeochemDataList.result) {
      callback(getGeochemDataList);
    } else {
      Modal.error({
        title: "This is an error message",
        content: getGeochemDataList.error
      });
    }
  };

  getMoreChartData = async () => {
    let { dataList, chartDataTotal } = this.state;

    this.setState({
      chartLoading: true
    });

    if (dataList.length >= chartDataTotal) {
      message.warning("Infinite List loaded all");
      this.setState({
        chartHasMore: false,
        chartLoading: false
      });
      return;
    }
    let { chartCurrentPage } = this.state;
    this.setState(
      {
        chartCurrentPage: chartCurrentPage + 1
      },
      async () => {
        let pageParams = {
          pageSize: this.state.chartPageSize,
          page: this.state.chartCurrentPage
        };
        await this.getDatas(pageParams, res => {
          dataList = dataList.concat(res.result);

          this.setState(
            {
              dataList,
              chartLoading: false
            },
            () => {
              this.showChart(dataList);
            }
          );
        });
      }
    );
  };
  findValue = (array, sort, type) => {
    let resultValue = "";
    if (sort == 1) {
      // 正序
      resultValue = array.find(item => item[type] !== null);
    } else if (sort == -1) {
      resultValue = array.reverse().find(item => item[type] !== null);
    }

    return resultValue[type];
  };
  showChart = dataList => {
    if (dataList.length > 0) {
      let { ShowType, isReverse } = this.state;

      // let maxDepth = this.findValue(dataList, 1, "depthfrom");
      // let minDepth = this.findValue(dataList, -1, "depthfrom");

      let option = {};

      if (ShowType === "horizontal") {
        option = {
          dataZoom: [
            {
              show: true,
              type: "slider",
              filterMode: "none",
              xAxisIndex: [0],
              start: 0,
              end: 100
            },
            {
              show: true,
              type: "slider",
              filterMode: "none",
              yAxisIndex: [0],
              start: 0,
              end: 100
            }
          ],
          grid: { bottom: 100 },
          tooltip: {
            trigger: "axis",

            formatter: function(params) {
              return "X: " + params[0].axisValue + "<br>Y: " + params[0].value;
            }
          },
          xAxis: {
            name: `Depth(${
              dataList[0].depth_unit ? dataList[0].depth_unit : ""
            })`,
            nameLocation: "middle",
            nameTextStyle: {
              padding: 20
            },
            type: "category",

            data: dataList.map(item =>
              item.depthfrom ? item.depthfrom.toFixed(2) : 0
            )
          },
          yAxis: {
            name: `${
              dataList[0].geochemistry_type ? dataList[0].geochemistry_type : ""
            } ${dataList[0].value_unit ? dataList[0].value_unit : ""}`,
            type: "value"
          },
          series: [
            {
              type: "line",
              data: dataList.map(item =>
                item.value ? item.value.toFixed(2) : 0
              )
            }
          ]
        };
      } else if (ShowType === "vertical") {
        option = {
          dataZoom: [
            {
              show: true,
              type: "slider",
              filterMode: "none",
              xAxisIndex: [0],
              start: 0,
              end: 100
            },
            {
              show: true,
              type: "slider",
              filterMode: "none",
              yAxisIndex: [0],
              start: 0,
              end: 100
            }
          ],
          tooltip: {
            trigger: "axis",

            formatter: function(params) {
              return "X: " + params[0].value + "<br>Y: " + params[0].axisValue;
            }
          },
          xAxis: {
            name: `${
              dataList[0].geochemistry_type ? dataList[0].geochemistry_type : ""
            } ${dataList[0].value_unit ? dataList[0].value_unit : ""}`,
            type: "value"
          },
          yAxis: {
            data: dataList.map(item =>
              item.depthfrom ? item.depthfrom.toFixed(2) : 0
            ),
            name: `Depth(${
              dataList[0].depth_unit ? dataList[0].depth_unit : ""
            })`,
            type: "category"
          },
          series: [
            {
              type: "line",
              data: dataList.map(item =>
                item.value ? item.value.toFixed(2) : 0
              )
            }
          ]
        };
      }

      this.setState(
        {
          currentOption: option
        },
        () => {
          this.myChart = echarts.init(
            document.getElementById(`${ShowType}${isReverse}`)
          );
          // 绘制图表

          this.myChart.setOption(this.state.currentOption);

          window.addEventListener("resize", this.resize);
        }
      );
    }
  };
  showChartDatas = async () => {
    let { isReverse } = this.state;
    let searchParams = {};

    let pageParams = {
      pageSize: this.state.chartPageSize,
      page: this.state.chartCurrentPage
    };
    let sortParams = {
      orderType: isReverse === false ? -1 : 1,
      ordername: "depthfrom"
    };
    Object.assign(searchParams, pageParams, sortParams);

    let getGeochemDataList = await this.initData(searchParams);
    if (getGeochemDataList.result) {
      this.setState(
        {
          dataList: getGeochemDataList.result,
          chartDataTotal: getGeochemDataList.total
        },
        () => {
          this.showChart(getGeochemDataList.result);
        }
      );
    }
  };

  showHorizontal = async () => {
    this.setState(
      {
        ShowType: "horizontal",
        isReverse: false
      },
      () => {
        setTimeout(() => {
          this.showChartDatas();
        }, 20);
      }
    );
  };
  showVertical = async () => {
    this.setState(
      {
        ShowType: "vertical",
        isReverse: false
      },
      () => {
        setTimeout(() => {
          this.showChartDatas();
        }, 20);
      }
    );
  };
  showReverse = async () => {
    let { ShowType, isReverse, orderType } = this.state;
    this.setState(
      {
        isReverse: !isReverse,

        ShowType: ShowType === "list" ? "horizontal" : ShowType
      },
      () => {
        setTimeout(() => {
          this.showChartDatas();
        }, 20);
      }
    );
  };

  getDataList = async () => {
    let pageParams = {
      pageSize: this.state.pageSize,
      // page: this.state.currentPage
      page:1
    };

    let getGeochemDataList = await this.initData(pageParams);
    if (getGeochemDataList.result) {
      this.setState({
        dataList: getGeochemDataList.result,
        dataTotal: getGeochemDataList.total,
        ShowType: "list",
        currentPage:1,
         hasMore:true
      });
    }
  };

  changeGeochemialType = async value => {
    
    this.setState({
      geochemialType: value,
      dataList: [],
      ShowType: "list",
       currentPage:1,
       hasMore:true
    },()=>{
      if(value){
        setTimeout(()=>{
          this.getDataList()
        })
      }
    });
  };
  changeDataType = value => {
    this.setState({
      currentDataType: value
    });
  };
  addSectionData = type => {
    let url = "/section/" + type;
    this.props.history.push(url);
  };

  onGeochemial = ref => {
    this.geochemialChild = ref;
  };

  checkGeochemistryOne = value => {
    this.props.form.setFieldsValue({
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || "",
      id: value.id || "",
      geochemistry_type: ""
    });
    this.setState(
      {
        inheritRefIds: value.ref_id,
        baseId: value.section_basic_id,
        sectionInfo: value,
        geochemistrySearch: false,
        OccurrenceId: value.id,
        dataList: [],
        ShowType: "list",
        geochemialType: ""
      },
      async () => {
        let GeochemistryTypes = await Apis.getGeochemistryTypes({
          geochemistryoccurrence_id: value.id
        });
        if (GeochemistryTypes.result) {
          this.setState({
            dataGeochemistryTypes: GeochemistryTypes.result
          });
        } else {
          this.setState({
            dataGeochemistryTypes: []
          });
        }
      }
    );
  };
  handleGeographyOk = () => {
    this.setState({
      geochemistrySearch: false
    });
  };
  handleGeochemistryCancel = () => {
    this.setState({
      geochemistrySearch: false
    });
  };
  uploadGeochemForm = () => {
    this.setState({
      addFormModal: false
    });
  };
  cancelUploadForm = () => {
    this.setState({
      addFormModal: false
    });
  };

  showSearchGeography = (type, e) => {
    e.target.blur();
    this.setState(
      {
        sectionType: type
      },
      () => {
        this.setState({
          sectionOptionVisible: true
        });
      }
    );
  };

  handFormOk = () => {
    this.setState({
      sectionOptionVisible: false
    });
  };
  handleFormCancel = () => {
    this.setState({
      sectionOptionVisible: false
    });
    Modal.destroyAll();
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      let ShowType = "geochemistrySearch";
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            sectionOptionVisible: false,
            [ShowType]: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues, sectionType } = this.state;

              this.geochemialChild.searchSectionDatas(
                searchParams,
                searchValues,
                sectionType
              );
            }, 20);
          }
        );
      }
    });
  };
  getGeochemicalDataDetail = async (item, index) => {
    let { id } = item;

    this.setState({
      addFormModal: true,
      currentDataIndex: index,
      geochemistryoccurrenceItemId: id
    });
  };
  changeGeochemicalData = item => {
    let { currentDataIndex, dataList } = this.state;
    dataList[currentDataIndex] = item;
    this.setState({
      dataList,
      addFormModal: false
    });
  };
  goHistoryBack = () => {
    this.props.history.goBack();
  };
  render() {
    let { getFieldDecorator } = this.props.form;
    let {
      dataList,
      dataGeochemistryTypes,
      Geochemicals,
      currentOption,
      ShowType,

      isReverse,

      searchParams,
      sectionOptionVisible,
      sectionType,
      searchValues
    } = this.state;

    return (
      <div id="sectionGeochemical" className="view-sectionGeochemical">
        <div className="searchBase-title collectionAdd-title">
          <div className="reference-search-operate">
            <p>Geochemical data</p>
          </div>
          <div className="common-back  common-back-hide">
            {/* <NavLink to="/references/search/collection"> */}
            {
             this.state.showBackIcon?
             <span
             className="iconfont icon-back common-back-hide"
             onClick={this.goHistoryBack}
           ></span>
           :
           null
           }
            {/* </NavLink> */}
          </div>
        </div>
        <div className="sectionFormation-mian geochemical-content">
          <div className="sectionFormation-form viewGeochemical">
            <Form>
              <div className="referenceAdd-title">
                <Form.Item label="Geochemical Id">
                  {getFieldDecorator("id", {
                    rules: [{}],
                    initialValue: Geochemicals ? Geochemicals.id : ""
                  })(
                    <Input
                      onFocus={e => this.showSearchGeography("occurrence", e)}
                    ></Input>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Geochemial data type">
                  {getFieldDecorator("geochemistry_type", {
                    rules: [{}],
                    initialValue: Geochemicals
                      ? Geochemicals.geochemistry_type
                      : ""
                  })(
                    <Select onChange={this.changeGeochemialType}>
                      {/* {Utils.fossilGroup(dataGeochemistryTypes)
                      } */}
                      {dataGeochemistryTypes.map((item, index) => {
                        return (
                          <Option
                            value={item.geochemistry_type}
                            key={item.geochemistry_type}
                          >
                            {item.geochemistry_type}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label="Ref Id">
                  {getFieldDecorator("ref_id", {
                    rules: [{}],
                    initialValue: Geochemicals ? Geochemicals.ref_id : ""
                  })(<Input disabled></Input>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Section ID">
                  {getFieldDecorator("section_basic_id", {
                    rules: [{}],
                    initialValue: Geochemicals
                      ? Geochemicals.section_basic_id
                      : ""
                  })(<Input disabled></Input>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-volumeNo  geochemical-show-btns">
                <div className="">
                  <div className="geochemical-show-btn">
                    <Button
                      type="gbdb"
                      title={
                        this.state.OccurrenceId == "" ||
                        this.state.geochemialType == ""
                          ? "must input occorance id and Geochemial data type"
                          : ""
                      }
                      disabled={
                        this.state.OccurrenceId == "" ||
                        this.state.geochemialType == ""
                      }
                      onClick={this.getDataList}
                    >
                      <p>Show geochemical data (list)</p>
                    </Button>
                  </div>
                </div>
                <div className="">
                  <div className="geochemical-show-btn">
                    <Button
                      title={
                        this.state.OccurrenceId == "" ||
                        this.state.geochemialType == ""
                          ? "must input occorance id and Geochemial data type"
                          : ""
                      }
                      type="gbdb"
                      disabled={
                        this.state.OccurrenceId == "" ||
                        this.state.geochemialType == ""
                      }
                      onClick={this.showHorizontal}
                    >
                      <p>Show figure (horizontal)</p>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="referenceAdd-volumeNo  geochemical-show-btns">
                <div className="referenceAdd-no">
                  <div className="geochemical-show-btn">
                    <Button
                      type="gbdb"
                      title={
                        this.state.OccurrenceId == "" ||
                        this.state.geochemialType == ""
                          ? "must input occorance id and Geochemial data type"
                          : ""
                      }
                      disabled={
                        this.state.OccurrenceId == "" ||
                        this.state.geochemialType == ""
                      }
                      onClick={this.showVertical}
                    >
                      <p>Show figure (vertical)</p>
                    </Button>
                  </div>
                </div>
                <div className="referenceAdd-no">
                  <div className="geochemical-show-btn">
                    <Button
                      title={
                        this.state.OccurrenceId == "" ||
                        this.state.geochemialType == ""
                          ? "please select occurrence or "
                          : ""
                      }
                      type="gbdb"
                      disabled={
                        this.state.OccurrenceId == "" ||
                        this.state.geochemialType == ""
                      }
                      onClick={this.showReverse}
                    >
                      <p>Reverse the depth axis</p>
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div className="sectionBase-show-charts last-formItem">
            {this.state.ShowType !== "list" ? (
              <div className="line-vertical-echart">
                {JSON.stringify(currentOption) !== "{}" ? (
                  <div
                    className="geochemistry-chart"
                    id={`${ShowType}${isReverse}`}
                    style={{ height: "600px", width: "100%" }}
                  ></div>
                ) : null}
              </div>
            ) : this.state.ShowType === "list" ? (
              <div className="show-list-data">
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={this.handleInfiniteOnLoad}
                  hasMore={!this.state.loading && this.state.hasMore}
                  useWindow={false}
                >
                  <List
                    header={
                      <div className="show-list-item">
                        <span className="datalist-serial">index</span>
                        <span>geochemistry type</span>
                        <span>depth</span>
                        <span>value</span>
                        <span>enterer</span>
                        <span>Detail</span>
                      </div>
                    }
                  >
                    {dataList.map((item, index) => {
                      return (
                        <List.Item key={index}>
                          <div className="show-list-item">
                            <span className="datalist-serial">{index + 1}</span>
                            <span>
                              {item && item.geochemistry_type
                                ? item.geochemistry_type
                                : ""}
                            </span>
                            <span>
                              {item && item.depthfrom !== undefined
                                ? item.depthfrom
                                : ""}
                              {(item && item.depthfrom !== undefined) ||
                              (item && item.depthto !== undefined)
                                ? "~"
                                : null}
                              {item && item.depthto !== undefined
                                ? item.depthto
                                : ""}
                              {item && item.depth_unit !== undefined
                                ? item.depth_unit
                                : ""}
                            </span>
                            <span>
                              {item && item.value ? item.value : ""}
                              {item && item.value_unit ? item.value_unit : ""}
                              {"±0"}
                            </span>
                            <span>
                              {item && item.enterer_username
                                ? item.enterer_username
                                : ""}
                            </span>
                            <span>
                              <Button
                                type="gbdb"
                                onClick={() =>
                                  this.getGeochemicalDataDetail(item, index)
                                }
                              >
                                <div>Details</div>
                              </Button>
                            </span>
                          </div>
                        </List.Item>
                      );
                    })}
                  </List>
                </InfiniteScroll>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          {/* <div className="referenceSearch-btns sectionBase-btns  section-common">
                <div className="formation-complete-btns">
                  <Button
                    type="gbdb"
                    onClick={() => this.addSectionData("geochemical")}
                  >
                    <div>
                      <p> Add Geochemical</p>
                    </div>
                  </Button>

                  <Button
                    type="gbdb"
                    onClick={() => this.addSectionData("formation")}
                  >
                    <div>
                      <p> Add Fomation</p>
                    </div>
                  </Button>
                  <Button
                    type="gbdb"
                    onClick={() => this.addSectionData("unit")}
                  >
                    <div>
                      <p> Add Unit</p>
                    </div>
                  </Button>
                </div>
              </div> */}
        </div>

        <Modal
          className="searchResultModal"
          width="76%"
          visible={sectionOptionVisible}
          footer=""
          // closable={false}
          onOk={this.handFormOk}
          onCancel={this.handleFormCancel}
        >
          <GeochemicalOption
            // conditions={conditions}

            ref="geochemistry"
            childError={this.childError}
          ></GeochemicalOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("geochemistry");
                  }}
                >
                  <p> Search </p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.geochemistrySearch}
          className="searchResultModal taxResultModal noBottomPadding"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleGeochemistryOk}
          onCancel={this.handleGeochemistryCancel}
        >
          <GeochemicalTableResult
            searchParams={this.state.searchParams}
            searchValues={this.state.searchValues}
            onGeochemial={this.onGeochemial}
            changeCheckSelected={this.changeCheckSelected}
            checkGeochemistryOne={this.checkGeochemistryOne}
            checkOne={true}
            showDetail={false}
          ></GeochemicalTableResult>
        </Modal>
        <Modal
          title=""
          destroyOnClose
          visible={this.state.showSectionDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76%"
          footer={null}
          // header={null}
          onOk={this.detailSectionFormOk}
          onCancel={this.detailSectionFormCancel}
        >
          <div>11</div>
        </Modal>
        <Modal
          destroyOnClose
          className="searchResultModal taxResultModal"
          width="78.6%"
          title={null}
          footer={null}
          visible={this.state.addFormModal}
          onOk={this.uploadGeochemForm}
          onCancel={this.cancelUploadForm}
        >
          {/* <div className="reference-search-form search-common-form geochemical-upload-form"> */}
          <div className="reference-search-content reference-search-baseform">
            <AddGeochemicalForm
              inheritRefIds={this.state.inheritRefIds}
              geochemistryoccurrenceItemId={
                this.state.geochemistryoccurrenceItemId
              }
              geochemistryoccurrenceId={this.state.OccurrenceId}
              canModify={false}
              changeGeochemicalData={this.changeGeochemicalData}
            ></AddGeochemicalForm>
          </div>
          {/* </div> */}
        </Modal>
      </div>
    );
  }
}
viewForm = Form.create({})(viewForm);
export default viewForm;
