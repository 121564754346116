import React, { Component } from "react";
import "./index.css";

import InfiniteScroll from "react-infinite-scroller";

import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";

import TaxonomyTableResult from "commons/AllTableList/Taxonomy/index.js";
import TaxonomyDetailForm from "commons/AllDetails/Taxonomy/index.js";

import {
  Form,
  Input,
  Select,
  Button,
  Modal,
  AutoComplete,
  message,
  InputNumber
} from "antd";
import Utils from "src/js/utils";
import Apis from "src/js/apis/api";
import dataApis from "src/js/apis/data";
import Users from "src/js/users";
import Storage from "src/js/storage";
import "src/js/global.js";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

class AddRelationship extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name1Opts: [],
      name2Opts: [],
      clatorOpts: [],
      treatises: [],
      refSearch: false,
      taxSearch: false,
      resultList: [],

      taxonType: "Parent",
      fossilgroup: [],
      embellish: {},

      parentObj: {},

      ChildIdsObj: {},
      ChildIdsArray: [],
      // 分页
      currentPage: 1,
      Total: 0,
      pageSize: 200,

      loading: false,
      hasMore: true,
      checkedObj: {},
      newTaxonObj: {},
      allChildError: {},
      refInfo:{}
    };
  }
  isGbdbUser = async () => {
    let Inputerid=false
    let token = Storage.getItem("token");
     Inputerid = await Users.isGbdbInputer();
  

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    } else {
      if (Inputerid == false) {
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title: "Sorry!!",
            content: "You do not have permission to add data"
          });
          this.props.history.push("/home");
        }
      }
    }
  };
  async componentDidMount() {
    this.isGbdbUser();
    let fossilgroup = await dataApis.fossilgroup();
    let treatises = await dataApis.getTreatise();
    this.setState({
      fossilgroup: fossilgroup && fossilgroup.result ? fossilgroup.result : {},

      treatises: treatises && treatises.result ? treatises.result : []
    });
  }

  goAddOrigin = () => {
    let url = "/taxonomy/addOriginal";
    // this.props.history.push(url);
    const w=window.open('about:blank');
    w.location.href=url
  };

  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refSearch: true
    });
  };

  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };
  clickRefId = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",
      year: value.ref_publication_year || ""
    });
    this.setState({
      refSearch: false
    });
  };

  // tax

  handleTaxOk = () => {
    this.setState({
      taxSearch: false
    });
  };

  handleTaxCancel = () => {
    this.setState({
      taxSearch: false
    });
  };
  // let searchKeys = ['equals','begins','ends', 'includes','in','after', 'before','median'];
  showTaxRef = async e => {
    e.target.blur();
    this.setState({
      taxSearch: true
    });
    let allParas = {};
    let searchTaxs = this.props.form.getFieldsValue([
      "ref_id",
      "year",
      "nomenclator",
      "taxon_name2"
    ]);

    let { ref_id, year, nomenclator, taxon_name2 } = searchTaxs;
    if (
      ref_id === "" &&
      year === "" &&
      nomenclator === "" &&
      taxon_name2 === ""
    ) {
      Modal.error({
        title: "This is an error message",
        content: "Please enter the query criteria"
      });
      return;
    }
    if (ref_id) {
      allParas.ref_id = {
        condition: "includes",
        value: ref_id
      };
    }
    if (year) {
      allParas.year = {
        condition: "equals",
        value: year
      };
    }
    if (nomenclator) {
      allParas.nomenclator = {
        condition: "equals",
        value: nomenclator
      };
    }
    if (taxon_name2) {
      allParas.taxon_name2 = {
        condition: "includes",
        value: taxon_name2
      };
    }

    let searchAll = await Apis.searchAll(allParas, "taxon");
    if (searchAll.error) {
    } else {
      this.setState(
        {
          taxSearch: true,
          resultList: searchAll.result
        },
        () => {}
      );
    }
  };

  handleName1Change = value => {
    this.props.form.setFieldsValue({
      taxName1: value
    });
  };
  handleName1Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name1"],
        taxon_name1: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);
      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name1Opts: [] });
        } else {
          this.setState(
            {
              name1Opts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ name1Opts: [] });
    }
  };

  handleName2Change = value => {
    this.props.form.setFieldsValue({
      taxName2: value
    });
  };
  handleName2Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name2"],
        taxon_name2: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);
      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name2Opts: [] });
        } else {
          this.setState(
            {
              name2Opts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ name2Opts: [] });
    }
  };

  handleNomSearch = async value => {
    if (value) {
      let params = {
        attributes: ["nomenclator"],
        nomenclator: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);
      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ clatorOpts: [] });
        } else {
          this.setState(
            {
              clatorOpts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ clatorOpts: [] });
    }
  };

  handleNomChange = value => {
    this.props.form.setFieldsValue({
      nomenclator: value
    });
  };
  searchParent = async () => {
    const { getFieldsValue } = this.props.form;
    let parentData = {};
    let getParent = getFieldsValue([
      "fossil_group_parent",
      "taxon_name1_parent"
    ]);

    if (getParent.fossil_group_parent && getParent.fossil_group_parent !== "") {
      parentData.fossil_group = getParent.fossil_group_parent;
    }
    if (getParent.taxon_name1_parent && getParent.taxon_name1_parent !== "") {
      parentData.taxon_name1 = getParent.taxon_name1_parent;
    }

    this.setState(
      {
        searchParams: parentData,
        searchValues: {},
        showTaxonResult: true,
        taxonType: "parent"
      },
      () => {
        setTimeout(() => {
          let { searchParams, searchValues, taxonType } = this.state;

          this.taxonomyChild.searchTaxonomyDatas(
            searchParams,
            searchValues,
            taxonType
          );
        }, 1000);
      }
    );
  };
  searchChild = async () => {
    const { getFieldsValue } = this.props.form;
    let childData = {};
    let getChild = getFieldsValue([
      "fossil_group_child",
      "taxon_name1_child",
      "taxon_name2_child"
    ]);
    if (getChild.fossil_group_child && getChild.fossil_group_child !== "") {
      childData.fossil_group = getChild.fossil_group_child;
    }
    if (getChild.taxon_name1_child && getChild.taxon_name1_child !== "") {
      childData.taxon_name1 = getChild.taxon_name1_child;
    }
    if (getChild.taxon_name2_child && getChild.taxon_name2_child !== "") {
      childData.taxon_name2 = getChild.taxon_name2_child;
    }
    this.setState(
      {
        searchParams: childData,
        searchValues: {},
        showTaxonResult: true,
        taxonType: "children"
      },
      () => {
        setTimeout(() => {
          let { searchParams, searchValues, taxonType } = this.state;
          this.taxonomyChild.searchTaxonomyDatas(
            searchParams,
            searchValues,
            taxonType
          );
        }, 1000);
      }
    );
  };
  deleteChild = async(item, index, e) => {
    let that = this;
    Modal.confirm({
      title: "This is a warning message",
      content: "Are you sure to delete",
        onOk: async() => {
        let { ChildIdsObj, ChildIdsArray } = that.state;
        if(item.taxarelation_id!==undefined){
          let params={
            id:item.taxarelation_id
          }
          let TaxonChildrensBack = await Apis.deleteTaxarelationbyId(
            params
          );
          if(TaxonChildrensBack.result){
            Modal.info({
              content:'The added relationship has been removed'
            })
          }else{
            Modal.error({
              content:'Delete failed'
            })
          }
        }
       
       
        delete ChildIdsObj[item.id];
        ChildIdsArray.splice(index, 1);
        that.setState(
          {
            ChildIdsObj,
            ChildIdsArray
          },
          () => {}
        );
      }
    });
  };

  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };

  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };

  onRef = ref => {
    this.child = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true
    });

    //点击 detail 按扭执行代码
  };
  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            refSearch: true,
            // refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues } = this.state;

              this.child.searchReferenceDatas(searchParams, searchValues);
            }, 1000);
          }
        );
      }
    });
  };
  searchOriginal = () => {
    this.setState(
      {
        showTaxonResult: true
      },
      () => {
        this.props.form.validateFieldsAndScroll(async (err, values) => {
          let originaParamsl = Utils.filterParams(values);
          originaParamsl = Utils.filterCondition(originaParamsl);
          originaParamsl.taxon_type = "species-original";

          this.setState(
            {
              searchParams: originaParamsl,
              searchValues: values
            },
            () => {
              let {
                searchParams,
                searchValues,
                embellish,
                taxonType
              } = this.state;
              this.taxonomyChild.searchTaxonomyDatas(
                searchParams,
                searchValues,
                embellish,
                taxonType
              );
            }
          );
        });
      }
    );
  };
  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",

      // nomenclator: value.authorizer || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };

  searchTaxonCancel = () => {
    this.setState({
      showTaxonResult: false
    });
  };
  onTaxonomy = ref => {
    this.taxonomyChild = ref;
  };
  handleTaxonOk = () => {
    this.setState({
      showTaxonResult: false
    });
  };

  detailTaxonomyFormOk = () => {
    this.setState({
      showTaxonomyDetailForm: false
    });
  };
  detailTaxonomyFormCancel = () => {
    this.setState({
      showTaxonomyDetailForm: false
    });
  };
  TaxonomyDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showTaxonomyDetailForm: true
    });
  };

  checkTaxonomyOne = item => {
    this.setState(
      {
        showTaxonResult: false,
        parentObj: item,
        ChildIdsObj: {}
      },
      async () => {
        let searchCondition = {};
        let parentParms = {
          parent_id: item.id
        };
        let pageParams = {
          page: this.state.currentPage,
          pageSize: this.state.pageSize
        };
        Object.assign(searchCondition, parentParms, pageParams);
        // 获取子元素
        let parentHasRelation;
        let TaxonChildrensBack = await Apis.searchTaxonChildrens(
          searchCondition
        );
        if (TaxonChildrensBack.result) {
          parentHasRelation = TaxonChildrensBack.result;
          this.setState(
            {
              ChildIdsArray: parentHasRelation,
              Total: TaxonChildrensBack.total
            },
            () => {
              let { ChildIdsArray, ChildIdsObj } = this.state;
              if (ChildIdsArray.length > 0) {
                ChildIdsArray.map(
                  (item, index) => {
                    ChildIdsObj[item.id] = item;
                  },
                  () => {
                    this.setState({
                      ChildIdsObj
                    });
                  }
                );
              }
            }
          );
        } else {
          Modal.error({
            content: TaxonChildrensBack.error
          });
        }

        let parentTaxId = item.id;
        let { ChildIdsObj } = this.state;
        if (ChildIdsObj.hasOwnProperty(parentTaxId)) {
          delete ChildIdsObj[parentTaxId];
        }
        this.setState({
          ChildIdsObj
        });
      }
    );
  };
  getParentChild = async (condition, callback) => {
    let searchResult = await Apis.searchTaxonChildrens(condition);

    if (searchResult.error) {
      const modal = Modal.error({
        title: "This is an error message",
        content: searchResult.error
      });
      setTimeout(() => {
        modal.destroy();
      }, 5000);
    } else {
      callback(searchResult);
    }
  };
  handleInfiniteOnLoad = () => {
    let { ChildIdsObj, ChildIdsArray, Total } = this.state;
    this.setState({
      loading: true
    });

    if (ChildIdsArray.length >= Total) {
      message.warning("The data loaded all");
      this.setState({
        hasMore: false,
        loading: false
      });
      return;
    }
    let { pageSize, currentPage, queryParams } = this.state;
    this.setState(
      {
        currentPage: currentPage + 1
      },
      () => {
        let searchParams = {
          pageSize,
          page: this.state.currentPage,
          ...queryParams
        };
        this.getParentChild(searchParams, res => {
          ChildIdsArray = ChildIdsArray.concat(res.result);

          ChildIdsArray.map((item, index) => {
            ChildIdsObj[item.id] = item;
          });
          this.setState({
            ChildIdsArray,
            ChildIdsObj,
            loading: false
          });
        });
      }
    );
  };
  changeCheckSelected = checkedObj => {
    

    this.setState(
      {
        checkedObj
      },
      () => {}
    );
  };

  addChildTaxon = () => {
    let {
      ChildIdsObj,
      checkedObj,
      parentObj,
      ChildIdsArray,
      newTaxonObj
    } = this.state;
    Object.assign(newTaxonObj, checkedObj);
    let parentTaxId = parentObj.id;
    let newTaxonIds = checkedObj;
    let newTaxonArray = Object.values(newTaxonIds);
    if (checkedObj.hasOwnProperty(parentTaxId)) {
      delete checkedObj[parentTaxId];
    }

    ChildIdsObj = Object.assign(ChildIdsObj, newTaxonIds);

    this.setState(
      {
        showTaxonResult: false,
        ChildIdsObj,
        ChildIdsArray: [...newTaxonArray, ...ChildIdsArray],
        newTaxonObj
      },
      () => {
        this.setState({
          checkedObj: {}
        });
      }
    );
  };

  addRelation = () => {
    let { parentObj, ChildIdsObj, newTaxonObj,refInfo } = this.state;
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (
          JSON.stringify(parentObj) === "{}" ||
          JSON.stringify(ChildIdsObj) === "{}"
        ) {
          Modal.error({
            title: "This is an error message",
            content: "Please enter Parent taxon and Child taxon"
          });
          return;
        } else {
          let addConditions = {};

          let relations = this.props.form.getFieldsValue([
            "ref_id",
            "from_treatise",
            "notes",
            "status",
            "baisis",
            "phylogenetic_status",
            "spelling_reason",
            "diagnosis",
            "diagnosis_given",
            "classification_quality",
            "max_interval_no",
            "min_interval_no",
            "first_occurrence",
            "last_occurrence"
          ]);

          relations = Utils.filterParams(relations);
          relations = Utils.filterCondition(relations);
          relations = Utils.addEnterer(relations);
          relations = Utils.addAudit(relations,refInfo);

          for (let key in relations) {
            addConditions[key] = relations[key];
          }
          addConditions.parent_id = parentObj.id;
          addConditions.child_id = Object.keys(newTaxonObj);

          //新增参数参数添加
          // let newParams = this.props.form.getFieldsValue([
          //   "status",
          //   "baisis",
          //   "phylogenetic_status",
          //   "spelling_reason",
          //   "diagnosis",
          //   "diagnosis_given",
          //   "classification_quality",
          //   "max_interval_no",
          //   "min_interval_no",
          //   "first_occurrence",
          //   "last_occurrence"
          // ]);
          // newParams = Utils.filterParams(newParams);

          if(Object.keys(newTaxonObj).length==0){
            Modal.info({
              content:"All relationships have been added"
            })
            return 
          }
          let addRelation = await Apis.addRelation(addConditions);
          if (addRelation.error) {
            Modal.error({
              title: "This is an error message",
              content: addRelation.error
            });
            return;
          } else {
            Modal.success({
              title: "This is a successful message",
              content: "add success"
           
            });
            this.setState({
              newTaxonObj: {},
              resultList: [],
              taxonType: "Parent",
              parentObj: {},
              ChildIdsObj: {},
              ChildIdsArray: [],
              currentPage: 1,
              Total: 0,
              checkedObj: {},
              newTaxonObj: {},
              allChildError: {},
              refInfo:{}
            });
            this.props.form.resetFields();
            
          }
        }
      } else {
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let {
      parentObj,
      fossilgroup,
      ChildIdsObj,
      ChildIdsArray,
      checkedObj,
      treatises
    } = this.state;

    const name1Opts = this.state.name1Opts.map((item, indx) => (
      <Option key={item.taxon_name1}>{item.taxon_name1}</Option>
    ));
    const name2Opts = this.state.name2Opts.map((item, indx) => (
      <Option key={item.taxon_name2}>{item.taxon_name2}</Option>
    ));
    const clatorOpts = this.state.clatorOpts.map((item, index) => (
      <Option key={index}>{item.nomenclator}</Option>
    ));

    return (
      <div
        id="addRelationship"
        className="taxonomy-common incomplete-relationship"
      >
        <div className="addRelationship-title">
          <div className="taxonomy-common-title form-common-title">
            Add relationship
          </div>

          <div className="addRelationship-main">
            <div className="reference-search-form addOriginal-form addIncomplete-form">
              <Form>
                <div className="referenceSearch-numberProject">
                  <Form.Item label={<span>Reference</span>}>
                    {getFieldDecorator("ref_id", {
                      rules: [
                        {
                          required: true,
                          message: "Please input the Ref ID"
                        }
                      ],

                      initialValue: ""
                    })(<Input onFocus={this.showSearchRef} />)}
                  </Form.Item>

                  <Form.Item label={<p>Collect from treatise?</p>}>
                    {getFieldDecorator("from_treatise", {
                      rules: [{}],
                      initialValue: ""
                    })(
                      <Select>
                        {treatises.map((item, index) => {
                          return <Option value={item.name} key={item.name}>{item.name}</Option>;
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="referenceAdd-comments">
                  <Form.Item label="Notes" className="long-label">
                    {getFieldDecorator("notes", {
                      rules: [{}],
                      initialValue: ""
                    })(<TextArea rows={6}></TextArea>)}
                  </Form.Item>
                </div>

                <div className="sectionGeography-map sectionGeography-fossil-group">
                  <div className="sectionGeography-tutude">
                    <span className="combination-title">Parent taxon</span>
                    {/* <span className="addIncomplete-tutude-title">
                      Fossil group
                    </span>
                    <span>
                      <FormItem
                        className="h5-no-margin"
                        label=""
                        key="fossil_group_parent"
                      >
                        {getFieldDecorator("fossil_group_parent", {
                          initialValue: ""
                        })(<Select>{Utils.fossilGroup(fossilgroup)}</Select>)}
                      </FormItem>
                    </span> */}
                    <span className="addIncomplete-tutude-title">
                      Taxon name
                    </span>
                    <span>
                      <FormItem>
                        {getFieldDecorator("taxon_name1_parent", {
                          initialValue: ""
                        })(
                          <AutoComplete
                            onSearch={this.handleName1Search}
                            onChange={this.handleName1Change}
                            placeholder=""
                          >
                            {name1Opts}
                          </AutoComplete>
                        )}
                      </FormItem>
                    </span>

                    <span className="sectionGeography-tutude-btn">
                      <Button type="gbdb" onClick={this.searchParent}>
                        <p>search</p>
                      </Button>
                    </span>
                    {/* <span className="sectionGeography-tutude-btn">
                      <Button type="gbdb" onClick={this.goAddOrigin}>
                        <p>Add new taxon</p>
                      </Button>
                    </span> */}
                  </div>

                  {/* <div className="sectionGeography-tutude">
                    <span className="addIncomplete-tutude-title">
                      Taxon name
                    </span>
                    <span>
                      <FormItem>
                        {getFieldDecorator("taxon_name1_parent", {
                          initialValue: ""
                        })(
                          <AutoComplete
                            onSearch={this.handleName1Search}
                            onChange={this.handleName1Change}
                            placeholder=""
                          >
                            {name1Opts}
                          </AutoComplete>
                        )}
                      </FormItem>
                    </span>

                    <span className="sectionGeography-tutude-btn">
                      <Button type="gbdb" onClick={this.searchParent}>
                        <p>search</p>
                      </Button>
                    </span>
                    <span className="sectionGeography-tutude-btn">
                      <Button type="gbdb" onClick={this.goAddOrigin}>
                        <p>Add new taxon</p>
                      </Button>
                    </span>
                  </div> */}

                  {JSON.stringify(parentObj) !== "{}" ? (
                    <div className="taxparent-list">
                      {/* <span className="iconfont icon-dot"></span> */}
                      <span>
                        <span className="parent-title">TaxonID</span>
                        <span>{parentObj.id ? parentObj.id : ""}</span>
                      </span>
                      <span>
                        <span className="parent-title"> Fossil group</span>
                        <span
                          title={
                            parentObj.fossil_group ? parentObj.fossil_group : ""
                          }
                        >
                          {parentObj.fossil_group ? parentObj.fossil_group : ""}
                        </span>
                      </span>
                      <span>
                        <span className="parent-title"> Taxon type</span>
                        <span
                          title={
                            parentObj.taxon_type ? parentObj.taxon_type : ""
                          }
                        >
                          {parentObj.taxon_type ? parentObj.taxon_type : ""}
                        </span>
                      </span>
                      <span>
                        <span className="parent-title"> Taxon name</span>
                        <span
                          title={
                            parentObj.taxon_name1 ? parentObj.taxon_name1 : ""
                          }
                        >
                          {parentObj.taxon_name1 ? parentObj.taxon_name1 : ""}
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div className="notaxparent-list">
                    <div className="taxparent-list ">
                      {/* <span className="iconfont icon-dot"></span> */}
                      <span>
                        <span className="parent-title">TaxonID</span>
                        <span></span>
                      </span>
                      <span>
                        <span className="parent-title"> Fossil group</span>
                        <span></span>
                      </span>
                      <span>
                        <span className="parent-title"> Taxon type</span>
                        <span></span>
                      </span>
                      <span>
                        <span className="parent-title"> Taxon name</span>
                        <span></span>
                      </span>
                      
                    </div>
                     
                      <div className="notaxparent-list-tip">(please select parent taxon first)</div>
                      </div>
                  )}
                 

                  <div className="sectionGeography-tutude">
                    <span className="combination-title"> Child taxon</span>

                    {/* <span className="addIncomplete-tutude-title">
                      Fossil group
                    </span>
                    <span>
                      <FormItem label={<p></p>}>
                        {getFieldDecorator("fossil_group_child", {
                          initialValue: ""
                        })(<Select>{Utils.fossilGroup(fossilgroup)}</Select>)}
                      </FormItem>
                    </span> */}
                    <span className="addIncomplete-tutude-title">
                      Taxon name
                    </span>
                    <span className="input-h5-padding">
                      <FormItem>
                        {getFieldDecorator("taxon_name1_child", {
                          initialValue: ""
                        })(
                          <AutoComplete
                            onSearch={this.handleName1Search}
                            onChange={this.handleName1Change}
                            placeholder=""
                          >
                            {name1Opts}
                          </AutoComplete>
                        )}
                      </FormItem>
                    </span>
                    <span>
                      <FormItem>
                        {getFieldDecorator("taxon_name2_child", {
                          initialValue: ""
                        })(
                          <AutoComplete
                            onSearch={this.handleName1Search}
                            onChange={this.handleName1Change}
                            placeholder=""
                          >
                            {name2Opts}
                          </AutoComplete>
                        )}
                      </FormItem>
                    </span>
                  </div>

                  {/* <div className="sectionGeography-tutude">
                    <span className="addIncomplete-tutude-title">
                      Taxon name
                    </span>
                    <span className="input-h5-padding">
                      <FormItem>
                        {getFieldDecorator("taxon_name1_child", {
                          initialValue: ""
                        })(
                          <AutoComplete
                            onSearch={this.handleName1Search}
                            onChange={this.handleName1Change}
                            placeholder=""
                          >
                            {name1Opts}
                          </AutoComplete>
                        )}
                      </FormItem>
                    </span>
                    <span>
                      <FormItem>
                        {getFieldDecorator("taxon_name2_child", {
                          initialValue: ""
                        })(
                          <AutoComplete
                            onSearch={this.handleName1Search}
                            onChange={this.handleName1Change}
                            placeholder=""
                          >
                            {name2Opts}
                          </AutoComplete>
                        )}
                      </FormItem>
                    </span>
                  </div> */}

                  <div className="sectionGeography-tutude">
                    <span className="addIncomplete-tutude-title">
                      Nomenclator
                    </span>
                    <span>
                      <FormItem>
                        {getFieldDecorator("nomenclator", {
                          initialValue: ""
                        })(
                          <AutoComplete
                            onSearch={this.handleNomSearch}
                            onChange={this.handleNomChange}
                            placeholder=""
                          >
                            {clatorOpts}
                          </AutoComplete>
                        )}
                      </FormItem>
                    </span>
                    <span className="sectionGeography-tutude-btn">
                      <Button
                        type="gbdb"
                        onClick={this.searchChild}
                        disabled={
                          JSON.stringify(this.state.parentObj) === "{}"
                            ? true
                            : false
                        }
                        title={
                          JSON.stringify(this.state.parentObj) === "{}"
                            ? "please select parent taxon first"
                            : ""
                        }
                      >
                        <p>search</p>
                      </Button>
                    </span>
                    {/* <span className="sectionGeography-tutude-btn">
                      <Button type="gbdb" onClick={this.goAddOrigin}>
                        <p>Add new taxon</p>
                      </Button>
                    </span> */}
                  </div>

                  {ChildIdsArray && ChildIdsArray.length > 0 ? (
                    <div className="taxchiled-list">
                      <div className="taxchiled-list-title">
                        {/* <span className="iconfont icon-dot"></span> */}
                        <span>
                          <span className="parent-title">TaxonID</span>
                        </span>
                        <span>
                          <span className="parent-title"> Fossil group</span>
                        </span>
                        <span>
                          <span className="parent-title"> Taxon type</span>
                        </span>
                        <span>
                          <span className="parent-title"> Taxon name</span>
                        </span>
                      </div>
                      <div className="fossil-relation-container">
                        <InfiniteScroll
                          initialLoad={false}
                          pageStart={0}
                          loadMore={this.handleInfiniteOnLoad}
                          hasMore={!this.state.loading && this.state.hasMore}
                          useWindow={false}
                        >
                          {ChildIdsArray.map((item, index) => {
                            return (
                              <div  key={index}>
                                {/* <span className="iconfont icon-dot"></span> */}

                                <span>{item.id ? item.id : ""}</span>

                                <span
                                  title={
                                    item.fossil_group ? item.fossil_group : ""
                                  }
                                >
                                  {item.fossil_group ? item.fossil_group : ""}
                                </span>

                                <span
                                  title={item.taxon_type ? item.taxon_type : ""}
                                >
                                  {item.taxon_type ? item.taxon_type : ""}
                                </span>

                                <span
                                className="fossil-relation-name"
                                  title={
                                    item.taxon_name1 ? item.taxon_name1 : ""
                                  }
                                >
                                  {item.taxon_name1 ? item.taxon_name1 : ""}
                                </span>

                                {/* <span>
                                {item.taxon_name1 ? item.taxon_name1 : ""}
                              </span> */}
                                <div className="relation-delete-child ">
                                  <span
                                    className="iconfont icon-Recyclebintrashgarbage"
                                    onClick={e =>
                                      this.deleteChild(item, index, e)
                                    }
                                  ></span>
                                </div>
                              </div>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="referenceSearch-numberProject">
                  <Form.Item label={<p>Status</p>}>
                      {getFieldDecorator("status", {
                        rules: [{}],
                        initialValue: "belongs to"
                      })(
                        <Select placeholder="belongs to">
                          <Option value="belongs to">belongs to</Option>
                          <Option value="subjective synonym of">subjective synonym of</Option>
                          <Option value="objective synonym of">objective synonym of</Option>
                          <Option value="invalid subgroup of">invalid subgroup of</Option>
                          <Option value="misspelling of">misspelling of</Option>
                          <Option value="replaced by">replaced by</Option>
                          <Option value="nomendubium">nomendubium</Option>
                          <Option value="nomen nudum">nomen nudum</Option>
                          <Option value="nomen oblitum">nomen oblitum</Option>
                          <Option value="nomen vanum">nomen vanum</Option>
                        </Select>
                      )}
                  </Form.Item>

                  <Form.Item label={<p>Baisis</p>}>
                      {getFieldDecorator("baisis", {
                        rules: [{}],
                        initialValue: ""
                      })(
                        <Select placeholder="">
                          <Option value=""></Option>
                          <Option value="stated with evidence">stated with evidence</Option>
                          <Option value="stated withoutevidence">stated withoutevidence</Option>
                          <Option value="implied">implied</Option>
                          <Option value="second hand">second hand</Option>
                        </Select>
                      )}
                  </Form.Item>
                </div>

                <div className="referenceSearch-numberProject">
                  <Form.Item label={<p>Phylogenetic Status</p>}>
                      {getFieldDecorator("phylogenetic_status", {
                        rules: [{}],
                        initialValue: ""
                      })(
                        <Select placeholder="">
                          <Option value=""></Option>
                          <Option value="monophyletic">monophyletic</Option>
                          <Option value="paraphyletic">paraphyletic</Option>
                          <Option value="polyphyletic">polyphyletic</Option>
                          <Option value="unknown">unknown</Option>
                        </Select>
                      )}
                  </Form.Item>

                  <Form.Item label={<p>Spelling Reason</p>}>
                      {getFieldDecorator("spelling_reason", {
                        rules: [{}],
                        initialValue: "original spelling"
                      })(
                        <Select placeholder="original spelling">
                          <Option value="original spelling">original spelling</Option>
                          <Option value="recombination">recombination</Option>
                          <Option value="reassignment">reassignment</Option>
                          <Option value="correction">correction</Option>
                          <Option value="rank change">rank change</Option>
                          <Option value="misspelling">misspelling</Option>
                        </Select>
                      )}
                  </Form.Item>
                </div>

                <div className="referenceAdd-comments">
                  <Form.Item label="Diagnosis" className="long-label">
                    {getFieldDecorator("diagnosis", {
                      rules: [{}],
                      initialValue: ""
                    })(<TextArea rows={6}></TextArea>)}
                  </Form.Item>
                </div>

                <div className="referenceSearch-numberProject">
                  <Form.Item label={<p>Diagnosis_given</p>}>
                      {getFieldDecorator("diagnosis_given", {
                        rules: [{}],
                        initialValue: ""
                      })(
                        <Select placeholder="">
                          <Option value=""></Option>
                          <Option value="none">none</Option>
                          <Option value="new">new</Option>
                          <Option value="emended">emended</Option>
                          <Option value="repeated">repeated</Option>
                        </Select>
                      )}
                  </Form.Item>

                  <Form.Item label={<p>Classification Quality</p>}>
                      {getFieldDecorator("classification_quality", {
                        rules: [{}],
                        initialValue: ""
                      })(
                        <Select placeholder="">
                          <Option value=""></Option>
                          <Option value="authoritative">authoritative</Option>
                          <Option value="implied">implied</Option>
                          <Option value="standard">standard</Option>
                          <Option value="second hand">second hand</Option>
                        </Select>
                      )}
                  </Form.Item>
                </div>

                <div className="referenceSearch-numberProject">
                  <Form.Item label={<p>Max Interval No</p>}>
                      {getFieldDecorator("max_interval_no", {
                        initialValue: 0
                      })(
                        <InputNumber  defaultValue={0}/>
                      )}
                  </Form.Item>

                  <Form.Item label={<p>Min Interval No</p>}>
                      {getFieldDecorator("min_interval_no", {
                        initialValue: 0
                      })(
                        <InputNumber  defaultValue={0}/>
                      )}
                  </Form.Item>
                </div>

                <div className="referenceSearch-numberProject">
                  <Form.Item label={<p>First Occurrence</p>}>
                      {getFieldDecorator("first_occurrence", {
                        rules: [{}],
                        initialValue: ""
                      })(
                        <Input/>
                      )}
                  </Form.Item>

                  <Form.Item label={<p>Last Occurrence</p>}>
                      {getFieldDecorator("last_occurrence", {
                        rules: [{}],
                        initialValue: ""
                      })(
                        <Input/>
                      )}
                  </Form.Item>
                </div>

                <div className="referenceSearch-btns taxonomy-btns">
                  <div>
                    <Button
                    // title="Please enter  Parent taxon and Child taxon"
                    
                      type="gbdb"
                      onClick={() => {
                        this.addRelation();
                      }}
                    >
                      <p>Add</p>
                    </Button>
                  </div>
                </div>
                <div className="taxonomy-tips">
                  <div>TIPS:</div>
                  <div>
                    1. Revised species name = Original name + Relation. So you
                    can also use the present interface to add revised names if
                    you know the original name and the relation, which is
                    extraordinarily fast if you need to input MANY revised names
                    under the same genus.
                  </div>
                  <div>
                    2. You can use input content of any field or combination of
                    two or more fields to search for parent or child taxa, for
                    example, using "angustus" or "angustus+Perner" to find
                    Climacograptus angustus Perner, 1895
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>

        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          <ReferenceOption
            childError={this.childError}
            conditions={{}}
            ref="reference"
          ></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          <ReferenceTableResult
            searchParams={this.state.searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showRefDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailRefFormOk}
          onCancel={this.detailRefFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
            // searchSection={this.searchSection}
            onlySeeRef={true}
          ></ReferenceDetailForm>
        </Modal>

        {/*originalModal */}

        <Modal
          destroyOnClose
          visible={this.state.showTaxonResult}
          className="searchResultModal taxResultModal noBottomPadding tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleTaxonOk}
          onCancel={this.searchTaxonCancel}
        >
          {this.state.taxonType === "children" ? (
            <Button
              className="add-relation-taxon"
              type="gbdb"
              onClick={this.addChildTaxon}
              title={
                JSON.stringify(checkedObj) == "{}"
                  ? "please select taxon first"
                  : ""
              }
              disabled={JSON.stringify(checkedObj) == "{}" ? true : false}
            >
              Add to Child taxon
            </Button>
          ) : null}
          <TaxonomyTableResult
            searchParams={this.state.searchParams}
            searchValues={this.state.searchValues}
            onTaxonomy={this.onTaxonomy}
            changeCheckSelected={this.changeCheckSelected}
            getDetail={this.TaxonomyDetail}
            checkOne={this.state.taxonType === "parent" ? true : false}
            checkTaxonomyOne={this.checkTaxonomyOne}
            // disableCheck={true}
            ChildIdsObj={ChildIdsObj}
            parentObj={parentObj}
          ></TaxonomyTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showTaxonomyDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailTaxonomyFormOk}
          onCancel={this.detailTaxonomyFormCancel}
        >
          <TaxonomyDetailForm
            detailData={this.state.detailData}
            onlySeeRef={true}
          ></TaxonomyDetailForm>
        </Modal>
      </div>
    );
  }
}

AddRelationship = Form.create({})(AddRelationship);
export default AddRelationship;
