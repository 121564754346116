import React, { Component } from "react";
import { Menu,Modal } from "antd";
import { NavLink } from "react-router-dom";
import "./index.css";
import Storage from "src/js/storage";
import "src/js/global.js";
class SectionTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey: "baseinfo"
    };
  }

  addKey = e => {
    this.setState(
      {
        currentKey: e.key
      },
      () => {}
    );
  };
  isGbdbUser=()=>{
    let token = Storage.getItem("token");
   
    if(token==undefined){
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    }
  
  }
  componentDidMount = () => {
    // this.isGbdbUser()
  }
  render() {
    let sectionPath = this.props.location.pathname;
    let pathArr = sectionPath.split("/");
    let currentKey = pathArr[pathArr.length - 1];
    return (
      <div id="sectionTiltle">
        <div className="sectionTiltle-header">
          <div className="reference-search-operate">
            <p>Add Section</p>
          </div>
          <div className="common-back">
            <NavLink to="/references/search/section">
              <span className="iconfont icon-back"></span>
            </NavLink>
          </div>
        </div>

        {/* <Menu
          mode="horizontal"
          className="sectionTiltle-menu pc-sectionTiltle-menu"
          onClick={e => this.addKey(e)}
          defaultSelectedKeys={currentKey}
          selectedKeys={currentKey}
        >
          <Menu.Item key="baseinfo">
            <div>
              <NavLink to="/section/baseinfo">
                <span className="ant-form-item-required">Base Info</span>
              </NavLink>
            </div>
          </Menu.Item>
          <Menu.Item key="arrow1" className="section-arrow">
            <div>
              <span className="iconfont icon-arrowright"></span>
            </div>
          </Menu.Item>
          <Menu.Item key="geography" disabled title="Fill in the geographic data, starting with baseinfo">
            <div title="Fill in the geographic data, starting with baseinfo" disabled >
              <NavLink to="/section/geography" disabled >
                <span className="ant-form-item-required" >Geology</span>
              </NavLink>
            </div>
          </Menu.Item> */}

          {/* pc */}

          {/* <Menu.Item className="geochemical-menu-arrow pc-title-show">
            <div key="arrow2" className="section-arrow">
              <div>
                <span className="iconfont icon-arrowright"></span>
              </div>
            </div>
            <div key="arrow22" className="section-arrow">
              <div>
                <span className="iconfont icon-arrowright"></span>
              </div>
            </div>
          </Menu.Item>
          <Menu.Item className="geochemical-menu pc-title-show">
            <div key="geochemical" className="section-Stratigraphy-parent">
              <div className="section-Stratigraphy">
                <NavLink
                  to="/section/geochemical"
                  className={`${
                    currentKey === "geochemical" ? "currentActive" : ""
                  }`}
                >
                  <p>Geochemical</p>

                  <p>Data</p>
                </NavLink>
              </div>
            </div>

            <div key="formation" className="section-Stratigraphy-parent">
              <div className="section-Stratigraphy">
                <NavLink
                  to="/section/formation"
                  className={`${
                    currentKey === "formation" ? "currentActive" : ""
                  }`}
                >
                  <p>
                    <span className="ant-form-item-required">Stratigraphy</span>
                  </p>

                  <p>&Lithology</p>
                </NavLink>
              </div>
            </div>
          </Menu.Item> */}

          {/* h5手机端显示 */}

          {/* <Menu.Item key="arrow3" className="section-arrow h5-title-show">
            <div>
              <span className="iconfont icon-arrowright"></span>
            </div>
          </Menu.Item>
          <Menu.Item key="formation" className="h5-title-show">
            <NavLink to="/section/formation" className="section-Stratigraphy-parent">
              <div className="section-Stratigraphy">
                <p>
                  <span className="ant-form-item-required">Stratigraphy</span>
                </p>

                <p>&Lithology</p>
              </div>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="arrow4" className="section-arrow h5-title-show">
            <div>
              <span className="iconfont icon-arrowright"></span>
            </div>
          </Menu.Item>
          <Menu.Item key="geochemical" className="h5-title-show">
            <NavLink to="/section/geochemical" className="section-Stratigraphy-parent">
              <div className="section-Stratigraphy">
                <p>Geochemical</p>

                <p>Data</p>
              </div>
            </NavLink>
          </Menu.Item> */}

          {/* h5结束 */}

          {/* <Menu.Item key="arrow5" className="section-arrow">
            <div>
              <span className="iconfont icon-arrowright"></span>
            </div>
          </Menu.Item>
          <Menu.Item key="unit">
            <div>
              <NavLink to="/section/unit">
                <span className="ant-form-item-required">Unit</span>
              </NavLink>
            </div>
          </Menu.Item>
        </Menu> */}
        
        {/* <div className="section-adds-tips">
          <div className="section-save-tip">
          <p>*Click the button at the top to add different categories of data</p>
        <p>*Make sure the data you have entered is saved before switching categories</p>
        </div>
        </div> */}
       

        <div className="section-parts form-mian-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default SectionTitle;
