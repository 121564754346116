import React, { Component } from "react";
import Utils from "src/js/utils";

import Apis from "src/js/apis/api";

import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";

import SectionOption from "commons/AllOptions/Section/index.js";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";
import BaseTableResult from "commons/AllTableList/Section/Base/index.js";
import FormationTableResult from "commons/AllTableList/Section/Formation/index.js";
import FormationDetailForm from "commons/AllDetails/Section/Formation/index.js";
import Storage from "src/js/storage";
import "src/js/global.js";
import "./index.css";
import { Form, Input, Select, Button, Modal, InputNumber, message } from "antd";
import Users from "src/js/users";
import datas from "commons/data.js";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
let {
  contactBase
} = datas;

class SectionUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inheritRefIds: "",
      formationId: "",
      formationInfo: {},
      baseInfo: {},
      isSearch: false,

      baseId: "",
      searchType: "",
      conditionType: "geology",

      refCondition: false,
      geographySearch: false,
      sectionOptionVisible: false,
      sectionType: "baseinfo",
      allChildError: {},

    };
  }
  isGbdbUser = async () => {
    let Inputerid = false
    let token = Storage.getItem("token");
    Inputerid = await Users.isGbdbInputer();


    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    } else {
      if (Inputerid == false) {
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title: "Sorry!!",
            content: "You do not have permission to add data"
          });
          this.props.history.push("/home");
        }
      }
    }
  };
  componentDidMount() {
    this.isGbdbUser();
    if (this.props.location.search) {
      let inheritRefIds =
        Utils.getSearch(this.props.location.search, "refId") || "";

      let formationId =
        Utils.getSearch(this.props.location.search, "formationId") || "";
      let baseId = Utils.getSearch(this.props.location.search, "baseId") || "";
      let geographyId =
        Utils.getSearch(this.props.location.search, "geographyId") || "";
      this.setState(
        {
          inheritRefIds,
          formationId,
          baseId,
          isSearch: true,
          geographyId
        },
        async () => {
          if (formationId) {
            let params = {
              formation_id: formationId
            };
            let getFormationBack = await Apis.getFormations(params);
            if (getFormationBack.result&&getFormationBack.result.length>0) {
              this.setState({
                formationInfo: getFormationBack.result
                  ? getFormationBack.result[0]
                  : {}
              });
            } else {
              if(getFormationBack.error){
                Modal.error({
                  title: "This is an error message",
                  content: getFormationBack.error
                });
              }
            
            }
          }
          if (baseId) {
            let searchParams = {
              section_id: baseId
            };
            let sectionBase = await Apis.sectionBase(searchParams);
            let baseInfo = {};
            if (sectionBase.result && sectionBase.result.length > 0) {
              baseInfo = sectionBase.result[0];
            } else {
              baseInfo = {};
            }
            this.setState({
              baseInfo
            });
          }
        }
      );
    } else {
      this.setState({
        isSearch: false
      });
    }
  }

  completeUnit = type => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let earlyValue = this.props.form.getFieldValue("early_age");
        let lateValue = this.props.form.getFieldValue("late_age");

        if (!isNaN(earlyValue) && !isNaN(lateValue)) {
          if (parseFloat(earlyValue) < parseFloat(lateValue)) {
            message.error("early_age must be greater than late_age");


            return;
          }
        }
        
    
        if(values.unit_thickness !== "" && values.unit_thickness !== null && values.unit_thickness !== undefined){
          if(values.unit_thickness_unit==="cm"){
            
            let value=parseFloat(values.unit_thickness)/100
            values.unit_thickness=value
            values.unit_thickness_unit="meters"

          }else if(values.unit_thickness_unit==="feet"){
            let value=parseFloat(values.unit_thickness)*0.3048
            values.unit_thickness=value
            values.unit_thickness_unit="meters"
          }
        }

        let addUnit = Utils.filterParams(values);
        // let addUnit = values
        addUnit = Utils.filterCondition(addUnit);

        addUnit = Utils.addEnterer(addUnit);
        let sectionData = {};
        let searchParams = {};
        if (values.formation_id !== null) {
          searchParams = {
            formation_id: values.formation_id
          };
          let FormationsBack = await Apis.getFormations(searchParams);
          if (FormationsBack.result && FormationsBack.result.length > 0) {
            sectionData = FormationsBack.result[0];
          }
        } else {
          searchParams = {
            section_id: values.section_basic_id
          };
          let sectionBase = await Apis.sectionBase(searchParams);

          if (sectionBase.result && sectionBase.result.length > 0) {
            sectionData = sectionBase.result[0];
          }
        }
        addUnit = Utils.addAudit(addUnit, sectionData);
        // 先获取地址年代表数据

              let isCalculate = true;
        if ((earlyValue === "" || earlyValue === null || earlyValue === undefined) && (lateValue === "" || lateValue === null || lateValue === undefined)) {
          isCalculate = false;
        }
        if(isCalculate){
           let params = {
            early_age: earlyValue,
            late_age: lateValue,
          };
            let TimeLinesById = await Apis.getEarlyInterval(params);
               if (TimeLinesById && TimeLinesById.result) {
            let TimeLines = TimeLinesById.result;

            if (TimeLines) {
              let erathem_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.erathem_max ? TimeLines.ages.erathem_max.name : "";
              let period_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.period_max ? TimeLines.ages.period_max.name : "";
              let epoch_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.epoch_max ? TimeLines.ages.epoch_max.name : "";
              let early_interval = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.early_interval ? TimeLines.ages.early_interval.name : "";

              if (erathem_max !== "") {
                addUnit.erathem_max = erathem_max;
                
              }
              if (period_max !== "") {
                addUnit.period_max = period_max;
               
              }
              if (epoch_max !== "") {
                addUnit.epoch_max = epoch_max;
                
              }
              if (early_interval !== "") {
                 addUnit.early_interval = early_interval;
              }
            }
          } else {
            if (TimeLinesById.error) {
              Modal.error({
                content: TimeLinesById.error,
              });
            }
          }
        }




  

  
        //  注释服务器
        let addUnitResult = await Apis.createUnit(addUnit);

        if (addUnitResult.error) {
          Modal.error({
            title: "This is an error message",
            content: addUnitResult.error
          });
        } else {
          if (type === "complete") {
            Modal.success({
              title: "This is a successful message",
              content: "successfully added "
            });
            this.props.form.resetFields();
            // this.props.history.push({ pathname: url, query: queryParam });
          } else {
            let url = "/collection/add?";
            let unitId = addUnitResult.result.unit_id || "";
            let { inheritRefIds } = this.state;
            let refUrl = "refId=" + inheritRefIds || "";
            let unitUrl = "&unitId=" + unitId || "";

            let params = `${url}${refUrl}${unitUrl}`;

            this.props.history.push(params);
          }
        }
      }
    });
  };

  ///
  Calculate = async e => {

    let earlyValue = this.props.form.getFieldValue("early_age");
    let lateValue = this.props.form.getFieldValue("late_age");

    if (!isNaN(earlyValue) && !isNaN(lateValue)) {
      if (parseFloat(earlyValue) < parseFloat(lateValue)) {
        message.error("early_age must be greater than late_age");


        return;
      }
    }


  };
  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };
  clickRefId = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };
  onRef = ref => {
    this.child = ref;
  };

  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true
    });

    //点击 detail 按扭执行代码
  };

  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    let showType = "refSearch";

    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (optionType === "reference") {
        showType = "refSearch";
      } else {
        showType = "geographySearch";
      }

      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            sectionOptionVisible: false,
            [showType]: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues, sectionType } = this.state;

              if (optionType === "reference") {
                this.child.searchReferenceDatas(searchParams, searchValues);
              } else {
                this.baseChild.searchSectionDatas(
                  searchParams,
                  searchValues,
                  sectionType
                );
              }
            }, 20);
          }
        );
      }
    });
  };

  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false
    });
  };

  // section
  onBase = ref => {
    this.baseChild = ref;
  };
  geographyDetail = itemValue => {

    this.setState({
      detailData: itemValue,
      showSectionDetailForm: true
    });
  };
  handFormOk = () => {
    this.setState({
      sectionOptionVisible: false
    });
  };
  handleFormCancel = () => {
    this.setState({
      sectionOptionVisible: false
    });
    Modal.destroyAll();
  };

  showSearchGeography = (type, e) => {
    e.target.blur();
    this.setState(
      {
        sectionType: type
      },
      () => {
        this.setState({
          sectionOptionVisible: true
        });
      }
    );
  };
  handleGeographyOk = () => {
    this.setState({
      geographySearch: false
    });
  };
  handleGeographyCancel = () => {
    this.setState({
      geographySearch: false
    });
  };
  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }));
  };


  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType
    });
  };
  detailSectionFormOk = () => {
    this.setState({
      showSectionDetailForm: false
      // sectionType: "baseinfo"
    });
  };
  detailSectionFormCancel = () => {
    this.setState({
      showSectionDetailForm: false
      // sectionType: "baseinfo"
    });
  };

  checkBaseOne = value => {

    this.props.form.setFieldsValue({
      section_basic_id: value.section_id || "",
      ref_id: value.ref_id || "",
      geology_id: value.geology_id || "",
      formation_id: value.formation_id || null,
      // early_age: Utils.isNumber(value.early_age)?parseFloat(value.early_age) : null,
      // late_age: Utils.isNumber(value.late_age)?parseFloat(value.late_age) : null  
    });
    this.setState({
      inheritRefIds: value.ref_id,
      baseId: value.section_id,
      geographyId: value.geology_id,
      formationId: value.formation_id,
      geographySearch: false,
      baseInfo: value
    });
  };
  checkFormationOne = value => {
    this.props.form.setFieldsValue({
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || "",
      geology_id: value.geology_id || "",
      formation_id: value.formation_id || null,
      early_age: Utils.isNumber(value.early_age)?parseFloat(value.early_age) : null,
      late_age: Utils.isNumber(value.late_age)?parseFloat(value.late_age) : null  
    });
    this.setState({
      inheritRefIds: value.ref_id,
      baseId: value.section_id,
      geographyId: value.geology_id,

      geographySearch: false,
      formationInfo: value
    });
  };
  render() {
    let {
      formationId,
      inheritRefIds,
      formationInfo,

      baseId,

      searchParams,
      sectionOptionVisible,
      sectionType,
      searchValues
    } = this.state;

    const { getFieldDecorator } = this.props.form;
    return (
      <div id="sectionUnit" className="section-common">
        <div className="sectionUnit-mian">
          <div className="section-common-title form-common-title">Add Unit</div>

          <div className="sectionUnit-form">
            <Form>
              <div className="referenceAdd-title reference-inherit">
                {
                  <span className="geography-ref-item">
                    <Form.Item label="Reference ID">
                      {getFieldDecorator("ref_id", {
                        rules: [
                          {
                            type: "number",
                            required: true,
                            message: "Please input the Reference"
                          }
                        ],
                        initialValue: Number(inheritRefIds) || ""
                      })(
                        <InputNumber
                          onFocus={this.showSearchRef}
                          disabled
                        ></InputNumber>
                      )}
                    </Form.Item>
                  </span>
                }
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Section ID">
                  {getFieldDecorator("section_basic_id", {
                    rules: [
                      {
                        type: "number",
                        required: true,
                        message: "Please input the Section"
                      }
                    ],
                    initialValue: Number(baseId) || ""
                  })(
                    <InputNumber
                      onFocus={e => this.showSearchGeography("baseinfo", e)}
                    ></InputNumber>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label="Formation ID">
                  {getFieldDecorator("formation_id", {
                    rules: [
                      {
                        type: "number"
                      }
                      // {
                      //   required: true,
                      //   message: "Please input the Formation ID"
                      // }
                    ],
                    initialValue: Number(formationId) || null
                  })(
                    <InputNumber
                      onFocus={e => this.showSearchGeography("formation", e)}
                    ></InputNumber>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Unit Name">
                  {getFieldDecorator("unit_name", {
                  rules: [{ required: true}],
                    initialValue: ""
                  })(<Input></Input>)}
                </Form.Item>
              </div>

             

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Early age">
                    {getFieldDecorator("early_age", {
                      rules: [
                        {
                          type: "number"
                          // required: true,
                          // message: "error early age"
                        }
                      ],
                      initialValue: (JSON.stringify(formationInfo)!=="{}"&& Utils.isNumber(formationInfo.early_age))? parseFloat(formationInfo.early_age) : null
                    })(<InputNumber
                      className="age-format-unit"
                      // formatter={value => value ? `${value}Ma` : ""}
                      // parser={value => value.replace('Ma', '')}
                      step={0.01}
                      min={0}
                      name="early_age" onBlur={e => this.Calculate(e)} />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Late age">
                    {getFieldDecorator("late_age", {
                      rules: [
                        {
                          type: "number"
                          // required: true,
                          // message: "error late age"
                        }
                      ],
                      initialValue: (JSON.stringify(formationInfo)!=="{}"&& Utils.isNumber(formationInfo.late_age))? parseFloat(formationInfo.late_age) : null
                    })(<InputNumber
                      className="age-format-unit"
                      // formatter={value => value ? `${value}Ma` : ""}
                      // parser={value => value.replace('Ma', '')}
                      step={0.01}
                        min={0}
                         name="late_age" onBlur={e => this.Calculate(e)} />)}
                  </Form.Item>
                </div>
              </div>

              {/* 新增biozone */}
              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Early Biozone">
                    {getFieldDecorator("early_biozone", {
                    rules: [{}],
                      initialValue: ""
                    })(<Input  className="age-format-unit"></Input>)}
                  </Form.Item>
                  </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Late Biozone">
                    {getFieldDecorator("late_biozone", {
                    rules: [{}],
                      initialValue: ""
                    })(<Input  className="age-format-unit"></Input>)}
                  </Form.Item>
                </div>
              </div>



              <div className="sectionGeography-map">
                <div className="sectionGeography-map-border"></div>
                <div className="sectionGeography-tutude sectionGeography-tutude-title">
                  <span>Color1</span>
                  <span>Color2</span>
                  <span>Lithology1</span>
                  <span>Lithology2</span>
                  <span>Main Lithology*</span>
                  <span>Relation</span>
                </div>

                <div className="sectionGeography-tutude">
                  <span>
                    <FormItem label={<p className="h5-map-title">Color1：</p>}>
                      {getFieldDecorator("unit_color1a", {
                        initialValue: ""
                      })(<Input placeholder="color1a"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Color2：</p>}>
                      {getFieldDecorator("unit_color2a", {
                        initialValue: ""
                      })(<Input placeholder="color2a"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Lithology1：</p>}
                    >
                      {getFieldDecorator("unit_lithology1a", {
                        initialValue: ""
                      })(<Input placeholder="lithology1a"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Lithology2：</p>}
                    >
                      {getFieldDecorator("unit_lithology2a", {
                        initialValue: ""
                      })(<Input placeholder="lithology2a"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Main Lithology*</p>}
                      className="require-aLithology"
                    >
                      {getFieldDecorator("unit_main_lithogya", {
                       
                        rules: [
                          {
                            required: true,
                          },
                          
                        ],
                          initialValue: JSON.stringify(formationInfo)!=="{}" ? formationInfo.formation_lithology : ''
                      })(<Input placeholder="MainLithogya"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Relation：</p>}
                    >
                      {getFieldDecorator("unit_relationa", {
                        initialValue: ""
                      })(
                        <Select placeholder="relationa">
                          <Option value="" title=""></Option>
                          <Option value="and" title="and">and</Option>
                          <Option value="interbedded by with some" title="interbedded by with some">
                            interbedded by with some
                          </Option>
                          <Option value="with some" title="with some">
                            with some
                          </Option>
                          <Option value="with on the top" title="with on the top">
                            with on the top
                          </Option>
                          <Option value="with in the middle" title="with in the middle">
                            with in the middle
                          </Option>
                          <Option value="with at the base" title="with at the base">
                            with at the base
                          </Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>

                <div className="sectionGeography-tutude">
                  <span>
                    <FormItem label={<p className="h5-map-title">Color1：</p>}>
                      {getFieldDecorator("unit_color1b", {
                        initialValue: ""
                      })(<Input placeholder="color1b"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Color2：</p>}>
                      {getFieldDecorator("unit_color2b", {
                        initialValue: ""
                      })(<Input placeholder="color2b"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Lithology1：</p>}
                    >
                      {getFieldDecorator("unit_lithology1b", {
                        initialValue: ""
                      })(<Input placeholder="lithology1b"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Lithology2：</p>}
                    >
                      {getFieldDecorator("unit_lithology2b", {
                        initialValue: ""
                      })(<Input placeholder="lithology2b"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Main Lithology</p>}
                    >
                      {getFieldDecorator("unit_main_lithogyb", {
                        initialValue: "",
                        rules: [
                          {
                          
                            // required: true,
                          
                          }
                        ],
                      })(<Input placeholder="MainLithogyb"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Relation：</p>}
                    >
                      {getFieldDecorator("unit_relationb", {
                        initialValue: ""
                      })(
                        <Select placeholder="relationb">
                         <Option value="" title=""></Option>
                         <Option value="and" title="and">and</Option>
                         <Option value="interbedded by with some" title="interbedded by with some">
                           interbedded by with some
                         </Option>
                         <Option value="with some" title="with some">
                           with some
                         </Option>
                         <Option value="with on the top" title="with on the top">
                           with on the top
                         </Option>
                         <Option value="with in the middle" title="with in the middle">
                           with in the middle
                         </Option>
                         <Option value="with at the base" title="with at the base">
                           with at the base
                         </Option>
                       </Select>
                      )}
                    </FormItem>
                  </span>
                </div>

                <div className="sectionGeography-tutude">
                  <span>
                    <FormItem label={<p className="h5-map-title">Color1：</p>}>
                      {getFieldDecorator("unit_color1c", {
                        initialValue: ""
                      })(<Input placeholder="color1c"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Color2：</p>}>
                      {getFieldDecorator("unit_color2c", {
                        initialValue: ""
                      })(<Input placeholder="color2c"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Lithology1：</p>}
                    >
                      {getFieldDecorator("unit_lithology1c", {
                        initialValue: ""
                      })(<Input placeholder="lithology1c"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Lithology2：</p>}
                    >
                      {getFieldDecorator("unit_lithology2c", {
                        initialValue: ""
                      })(<Input placeholder="lithology2c"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Main Lithology</p>}
                    >
                      {getFieldDecorator("unit_main_lithogyc", {
                        initialValue: "",
                        rules: [
                          {
                          
                            // required: true,
                          
                          }
                        ],
                      })(<Input placeholder="MainLithogyc"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Relation：</p>}
                    >
                      {getFieldDecorator("unit_relationc", {
                        initialValue: ""
                      })(
                       <Select placeholder="relationc">
                         <Option value="" title=""></Option>
                         <Option value="and" title="and">and</Option>
                          <Option value="interbedded by with some" title="interbedded by with some">
                            interbedded by with some
                          </Option>
                          <Option value="with some" title="with some">
                            with some
                          </Option>
                          <Option value="with on the top" title="with on the top">
                            with on the top
                          </Option>
                          <Option value="with in the middle" title="with in the middle">
                            with in the middle
                          </Option>
                          <Option value="with at the base" title="with at the base">
                            with at the base
                          </Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>

                <div className="sectionGeography-tutude">
                  <span>
                    <FormItem label={<p className="h5-map-title">Color1：</p>}>
                      {getFieldDecorator("unit_color1d", {
                        initialValue: ""
                      })(<Input placeholder="color1d"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Color2：</p>}>
                      {getFieldDecorator("unit_color2d", {
                        initialValue: ""
                      })(<Input placeholder="color2d"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Lithology1：</p>}
                    >
                      {getFieldDecorator("unit_lithology1d", {
                        initialValue: ""
                      })(<Input placeholder="lithology1d"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Lithology2：</p>}
                    >
                      {getFieldDecorator("unit_lithology2d", {
                        initialValue: ""
                      })(<Input placeholder="lithology2d"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Main Lithology</p>}
                    >
                      {getFieldDecorator("unit_main_lithogyd", {
                        initialValue: "",
                        rules: [
                          {
                          
                            // required: true,
                          
                          }
                        ],
                      })(<Input placeholder="MainLithogyd"></Input>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Relation：</p>}
                    >
                      {getFieldDecorator("unit_relationd", {
                        initialValue: ""
                      })(
                       <Select placeholder="relationd">
                         <Option value="" title=""></Option>
                         <Option value="and" title="and">and</Option>
                          <Option value="interbedded by with some" title="interbedded by with some">
                            interbedded by with some
                          </Option>
                          <Option value="with some" title="with some">
                            with some
                          </Option>
                          <Option value="with on the top" title="with on the top">
                            with on the top
                          </Option>
                          <Option value="with in the middle" title="with in the middle">
                            with in the middle
                          </Option>
                          <Option value="with at the base" title="with at the base">
                            with at the base
                          </Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>
              </div>

              <div className="sectionGeography-map sectionGeography-map-nopadding">
                <div className="sectionGeography-tutude">
                  <span className="sectionFormation-thickness">
                    <FormItem label="Thickness">
                      {getFieldDecorator("unit_thickness_sign", {
                        initialValue: ""
                      })(
                        <Select>
                          <Option value="=">=</Option>
                          <Option value="<">&lt;</Option>
                          <Option value=">">&gt;</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>

                  <span>
                    <FormItem label="">
                      {getFieldDecorator("unit_thickness", {
                        initialValue: ""
                      })(<InputNumber placeholder="" min={0}></InputNumber>)}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label="">
                      {getFieldDecorator("unit_thickness_unit", {
                        initialValue: "meters"
                      })(
                        <Select>
                          
                          <Option value="meters">Meter</Option>
                          <Option value="cm">Cm</Option>
                          <Option value="feet">Feet</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>
              </div>

           
              <div className="referenceAdd-journal">
              <Form.Item label={<p>Contact base</p>}>
                  {getFieldDecorator("unit_con_base", {
                    rules: [{}],
                    initialValue: ""
                  })(
                    <Select placeholder="">
                      {contactBase.map((item, index) => {
                        return <Option value={item} key={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
                {/* <Form.Item label={<p>Contact base</p>}>
                  {getFieldDecorator("unit_con_base", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input />)}
                </Form.Item> */}
              </div>
              {/* <div className="referenceAdd-journal">
                <Form.Item label={<p>Sediment</p>}>
                  {getFieldDecorator("unit_sediment", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item label={<p>Facies</p>}>
                  {getFieldDecorator("unit_facies", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-comments last-formItem">
                <Form.Item label="Paleoenvironment" className="long-label">
                  {getFieldDecorator("unit_paleoenvironment", {
                    rules: [{}],
                    initialValue: ""
                  })(<TextArea rows={6}></TextArea>)}
                </Form.Item>
              </div> */}

              <div className="referenceSearch-btns sectionBase-btns referenceSearch-completes">
                <div>
                  <Button
                    type="gbdb"
                    onClick={() => this.completeUnit("continue")}
                  >
                    <div>
                      <p> Save and</p>
                      <p> Continue</p>
                    </div>
                  </Button>
                </div>

                <div>
                  <Button
                    type="gbdb"
                    className="referenceAdd-complete-btn"
                    onClick={() => this.completeUnit("complete")}
                  >
                    Complete
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}
          <ReferenceOption
            childError={this.childError}
            conditions={{}}
            ref="reference"
          ></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}

          <ReferenceTableResult
            searchParams={searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            onlySeeRef={true}
          // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showRefDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailRefFormOk}
          onCancel={this.detailRefFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
          // searchSection={this.searchSection}
          ></ReferenceDetailForm>
        </Modal>

        {/* section */}

        <Modal
          destroyOnClose
          className="searchResultModal"
          width="76%"
          visible={sectionOptionVisible}
          footer=""
          // closable={false}
          onOk={this.handFormOk}
          onCancel={this.handleFormCancel}
        >
          <SectionOption
            // conditions={conditions}
            showGeography={sectionType === "geology" ? true : false}
            showUnit={false}
            showBase={sectionType === "baseinfo" ? true : false}
            showFomation={sectionType === "formation" ? true : false}
            ref={sectionType}
            childError={this.childError}
          ></SectionOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain(sectionType);
                  }}
                >
                  {<p> Search</p>}
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.geographySearch}
          className="searchResultModal taxResultModal noBottomPadding"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleGeographyOk}
          onCancel={this.handleGeographyCancel}
        >
          {/*  */}
          {sectionType === "baseinfo" || sectionType === "geology" ? (
            <BaseTableResult
              searchParams={searchParams}
              searchValues={searchValues}
              onBase={this.onBase}
              changeCheckSelected={this.changeCheckSelected}
              getDetail={this.geographyDetail}
              checkOne={true}
              checkBaseOne={this.checkBaseOne}
            ></BaseTableResult>
          ) : sectionType === "formation" ? (
            <FormationTableResult
              searchParams={searchParams}
              searchValues={searchValues}
              onBase={this.onBase}
              changeCheckSelected={this.changeCheckSelected}
              getDetail={this.geographyDetail}
              checkOne={true}
              checkFormationOne={this.checkFormationOne}
            ></FormationTableResult>
          ) : null}
        </Modal>
        <Modal
          title=""
          destroyOnClose
          visible={this.state.showSectionDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76%"
          footer={null}
          // header={null}
          onOk={this.detailSectionFormOk}
          onCancel={this.detailSectionFormCancel}
        >
          {sectionType === "baseinfo" ? (
            <BaseDetailForm
              detailData={this.state.detailData}
              viewGeography={this.viewGeography}
              seeOnly={true}
            ></BaseDetailForm>
          ) : sectionType === "geology" ? (
            <GeographyDetailForm
              seeOnly={true}
              detailData={this.state.detailData}
              viewBase={this.viewBase}
            ></GeographyDetailForm>
          ) : sectionType === "formation" ? (
            <FormationDetailForm
              seeOnly={true}
              detailData={this.state.detailData}
            ></FormationDetailForm>
          ) : null}
        </Modal>
      </div>
    );
  }
}
SectionUnit = Form.create({})(SectionUnit);
export default SectionUnit;
