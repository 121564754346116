import React, { Component } from 'react';
import './index.scss'
import { Menu, Icon, Button,Select } from 'antd';

const { SubMenu } = Menu;
const {Option}=Select

export default class System extends Component {
    state = {
        collapsed: false,
      };
    
    componentDidMount(){
      
    }
    render() {
        return (
            <div>
                <Select style={{width:400}}>
                    <Option value={1}>11111</Option>
                    <Option value={2}>2222</Option>
                    <Option value={3}>3333</Option>
                    <Option value={4}>4444</Option>
                    <Option value={5}>5555</Option>
                </Select>
            </div>
            );
        }
    }
