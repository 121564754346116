import React, { Component } from "react";
import { Menu, Modal, Form, Input, Button, Select, Layout } from "antd";

import folderApis from "src/js/apis/folder";
import { NavLink } from "react-router-dom";
import "./index.css";

import Storage from "src/js/storage";
import SubsetApis from "src/js/apis/subset";
import groupApis from "src/js/apis/group";
import Apis from "src/js/apis/api";
import "src/js/global.js";
import InfiniteScroll from "react-infinite-scroller";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";
import SectionAllTableResult from "commons/AllTableList/Section/SectionAll/index.js";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";

import CollectionTableResult from "commons/AllTableList/Collection/index.js";
import CollectionDetailForm from "commons/AllDetails/Collection/index.js";

import GeochemicalTableResult from "commons/AllTableList/Section/Geochemical/index.js";

// 添加
import OccurenceTableList from "commons/AllTableList/Occurence/index.js";

import Utils from "src/js/utils";

const { confirm } = Modal;
const { Sider } = Layout;

const { Option } = Select;
const { SubMenu } = Menu;

class Subset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey: "reference",
      // 父级和子级的设置没有parent字段

      currentFolderKey: 0,
      currentFolderMoveKey: 0,
      folderModal: false,
      folderObjs: {},
      folderNames: {},
      allFolders: [],
      childrenFolders: [],
      moveFolderModal: false,
      moveTofolderKey: 0,
      folderMoveLevel: 1,
      folderLevel: 1,
      currentMoveToFolder: {},
      currentMoveToIndex: 0,
      currentFolderIndex: 0,
      parentFoldersObj: {},
      childrenFoldersObj: {},
      sectionType: "baseinfo",
      shareFolderKey: "",
      currentParentFolderName: "Default",
      currentParentIndex: 0,
      parentFoldersMoveObj: {},
      childrenFoldersMoveObj: {},
      childrenMoveFolders: [],
      openKeys: [],
      openMoveKeys: [],
      rootSubmenuKeys: {},
      nowPage: 1,
      showNum: 17,
      pages: 0,
      showDetailForm: false,
      detailData: {},
      isCollapsed: false,
      isBroken: false,
      isCheckAll: false,
      checkedObj: {},
      resultLists: [],

      isShare: 0,
      myGroups: [],
      groupids: [],

      // 移动文件夹

      isVisit: false,
      conopUrl: "",
      currentJournalType: "",
      currentFossilGroupType: "all",

      currentFossilGroups: [],
      // 是否全选
      allfossilGroups: 1,
      checkNomenclaturesAll: false,
      currentNomenclature: "allnome",
      // tabs当前值
      LoadConditionType: "fossilType",
      fossilgroup: {},
    };
  }
  getGroups = async () => {
    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }
    let uid = Storage.getItem("gbdbid");
    if (uid) {
      let params = {
        uid,
      };
      let myGroups = await groupApis.getGroups(params);

      if (myGroups.error) {
        Modal.error({
          title: "This is an error message",
          content: myGroups.error,
        });
      } else {
        this.setState({
          myGroups: myGroups.result,
        });
      }
    }
  };
  getFolderAssets = () => {
    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }
    let uid = Storage.getItem("gbdbid");
    let params = {
      uid,
      folder: Number(this.state.currentFolderKey),
      assetstype: Utils.folderTypeFun(this.state.currentKey),
      folder_level: this.state.folderLevel,
    };
    this.setState(
      {
        searchParams: params,
      },
      () => {
        let { searchParams, currentKey } = this.state;

        setTimeout(() => {
          if (currentKey === "reference") {
            this.child.searchSubset(searchParams);
          } else if (currentKey === "section") {
            this.geographyChild.searchSubset(searchParams);
          } else if (currentKey === "collection") {
            this.collectionChild.searchSubset(searchParams);
          } else if (currentKey === "geochemical") {
            this.geochemialChild.searchSubset(searchParams);
          } else if (currentKey === "fossilOccurrence") {
            this.occurenceChild.searchSubset(searchParams);
          }
        }, 20);
      }
    );
  };
  getFolderAddRes = async () => {
    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }
    let uid = Storage.getItem("gbdbid");
    // 获取整个文件夹
    let params = {};
    let { isVisit } = this.state;
    if (isVisit) {
      this.setState(
        {
          askLoading: true,
        },
        async () => {
          let groupid = Utils.getSearch(this.props.location.search, "groupid");
          params = {
            groupid,
            folder_type: Utils.folderTypeFun(this.state.currentKey),
          };
          let ShareFolder = await SubsetApis.getGroupShareFolder(params);

          if (ShareFolder.result) {
            this.setState(
              {
                allFolders: ShareFolder.result,
                askLoading: false,
              },
              () => {
                if (ShareFolder.result.length > 0) {
                  this.setState(
                    {
                      shareFolderKey: ShareFolder.result[0].folder_level == 1 ? ShareFolder.result[0].id + "parent" : ShareFolder.result[0].id,
                    },
                    () => {
                      let uid = ShareFolder.result[0].uid;
                      let folderKey = Number(this.state.shareFolderKey);
                      if (ShareFolder.result[0].folder_level == 1) {
                        folderKey = Number(this.state.shareFolderKey.split("parent")[0]);
                      }
                      let params = {
                        uid,
                        folder: folderKey,
                        assetstype: Utils.folderTypeFun(this.state.currentKey),
                        folder_level: ShareFolder.result[0].folder_level,
                      };
                      this.setState(
                        {
                          searchParams: params,
                        },
                        () => {
                          let { searchParams, currentKey } = this.state;

                          setTimeout(() => {
                            if (currentKey === "reference") {
                              this.child.searchSubset(searchParams);
                            } else if (currentKey === "section") {
                              this.geographyChild.searchSubset(searchParams);
                            } else if (currentKey === "collection") {
                              this.collectionChild.searchSubset(searchParams);
                            } else if (currentKey === "geochemical") {
                              this.geochemialChild.searchSubset(searchParams);
                            } else if (currentKey === "fossilOccurrence") {
                              this.occurenceChild.searchSubset(searchParams);
                            }
                          }, 20);
                        }
                      );
                    }
                  );
                }
              }
            );
          } else {
            this.setState({
              askLoading: undefined,
            });
            Modal.error({
              content: "Failed to get folder",
            });
          }
        }
      );
    } else {
      this.setState(
        {
          askLoading: true,
        },
        async () => {
          let params = {
            uid,
            folder_type: Utils.folderTypeFun(this.state.currentKey),
            folder_level: this.state.folderLevel,
          };

          let foldersBack = await folderApis.getFolders(params);

          if (foldersBack.error) {
            const modal = Modal.error({
              title: "This is an error message",
              content: foldersBack.error,
            });
            this.setState({
              askLoading: undefined,
            });
            setTimeout(() => {
              modal.destroy();
            }, 3000);
          } else {
            this.setState(
              {
                askLoading: false,
                allFolders: foldersBack.result,
              },
              async () => {
                let uid = Storage.getItem("gbdbid");
                let params = {
                  uid,
                  folder: Number(this.state.currentFolderKey),
                  assetstype: Utils.folderTypeFun(this.state.currentKey),
                  folder_level: this.state.folderLevel,
                };
                this.setState(
                  {
                    searchParams: params,
                  },
                  () => {
                    let { searchParams, currentKey } = this.state;

                    setTimeout(() => {
                      if (currentKey === "reference") {
                        this.child.searchSubset(searchParams);
                      } else if (currentKey === "section") {
                        this.geographyChild.searchSubset(searchParams);
                      } else if (currentKey === "collection") {
                        this.collectionChild.searchSubset(searchParams);
                      } else if (currentKey === "geochemical") {
                        this.geochemialChild.searchSubset(searchParams);
                      } else if (currentKey === "fossilOccurrence") {
                        this.occurenceChild.searchSubset(searchParams);
                      }
                    }, 20);
                  }
                );
              }
            );
          }
        }
      );
    }
  };
  isGbdbUser = () => {
    let token = Storage.getItem("token");

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page",
        });
        this.props.history.push("/home");
      }
    }
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      window.location.reload();
    }
  }
  componentDidMount = async () => {
    this.isGbdbUser();
    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }
    let uid = Storage.getItem("gbdbid");
    //  先验证是否在组里
    if (this.props.location.search) {
      let groupid = Utils.getSearch(this.props.location.search, "groupid");
      let params = {
        groupid,
        uid,
        state: 2,
      };
      let userInGroup = await groupApis.getPersonsGroups(params);

      if (userInGroup.result) {
        if (userInGroup.result.length == 0) {
          Modal.error({
            content: "You are not in the group",
          });
          this.props.history.push("/group/list");
        } else {
          this.setState(
            {
              isVisit: true,
            },
            async () => {
              await this.getFolderAddRes();
            }
          );
        }
      } else {
        Modal.error({
          content: userInGroup.error,
        });
      }
    } else {
      this.setState(
        {
          isVisit: false,
        },
        async () => {
          await this.getFolderAddRes();
        }
      );
    }
  };
  changeType = async (e) => {
    this.setState(
      {
        currentKey: e.key,
        currentFolderKey: 0,
        currentFolderMoveKey: 0,
        folderObjs: {},
        folderNames: {},
        allFolders: [],
        childrenFolders: [],
        folderMoveLevel: 1,
        folderLevel: 1,
        openKeys: [],
        openMoveKeys: [],
        checkedObj: {},
      },
      () => {
        this.getFolderAddRes();
      }
    );
  };
  handFolderleOk = () => {
    this.setState({
      folderModal: false,
      myGroups: [],
      isShare: 0,
    });
  };
  handleFolderCancel = () => {
    this.setState({
      folderModal: false,
      myGroups: [],
      isShare: 0,
    });
  };
  showAddFolder = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let { folderLevel } = this.state;

    this.setState(
      {
        folderLevel,
        folderModal: true,
        isShare: 0,
      },
      () => {}
    );
  };

  deleteSubset = async () => {
    let { checkedObj, currentKey } = this.state;
    if (currentKey === "reference") {
      this.child.deleteSubset(checkedObj);
    } else if (currentKey === "section") {
      this.geographyChild.deleteSubset(checkedObj);
    } else if (currentKey === "collection") {
      this.collectionChild.deleteSubset(checkedObj);
    } else if (currentKey === "geochemical") {
      this.geochemialChild.deleteSubset(checkedObj);
    } else if (currentKey === "fossilOccurrence") {
      this.occurenceChild.searchSubset(checkedObj);
    }
  };
  createFolderOperate = (needAddLevel, createFolderBack) => {
    let createBack = createFolderBack.result;
    let createResult = {};
    let otherParams = {
      folder_second_count: 0,
      folder_level: needAddLevel,
      assetsCount: 0,
    };
    Object.assign(createResult, createBack, otherParams);
    let {
      allFolders,
      currentFolderKey,

      childrenFolders,
    } = this.state;

    if (needAddLevel === 2) {
      childrenFolders = [...childrenFolders, createResult];
      //为了获取操作的一级文件夹

      let currentFolder = this.state.parentFoldersObj[currentFolderKey];
      let deleteIndex = currentFolder.index;

      allFolders[deleteIndex].folder_second_count += 1;
      allFolders[deleteIndex].folder_level = 1;

      this.setState(
        {
          childrenFolders,
          folderModal: false,
          openKeys: [currentFolderKey + "parent"],
          allFolders: allFolders,
          currentFolderKey: createBack.id,
          folderLevel: 2,
        },
        () => {}
      );
    } else {
      // 如果添加的为一级文件夹
      allFolders = [...allFolders, createResult];
      this.setState(
        {
          childrenFolders,
          folderModal: false,
          allFolders,
          folderLevel: 1,
          currentFolderKey: createBack.id,
        },
        () => {}
      );
    }
  };
  confirmAddFolder = () => {
    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let {
          currentFolderKey,
          folderLevel,

          currentParentFolderName,
        } = this.state;

        let uid = Storage.getItem("gbdbid");
        if (!uid) {
          this.props.history.push("/home");
        }
        let allParams = {};
        let parentParams = {};
        let baseParams = {
          uid,
          folder_type: Utils.folderTypeFun(this.state.currentKey),
          folder_name: values.folder_name,
        };
        let needAddLevel = 1;
        let needAddParentkey = "";
        if (folderLevel === 1) {
          if (currentParentFolderName === "Default") {
            needAddLevel = 1;
            needAddParentkey = "";
          } else {
            needAddLevel = 2;
            needAddParentkey = currentFolderKey;
          }
        }
        if (needAddLevel == 2) {
          parentParams = { parent: needAddParentkey };
        } else {
          parentParams = {};
        }
        let levelParams = {
          folder_level: needAddLevel,
        };

        Object.assign(allParams, baseParams, parentParams, levelParams);

        let createFolderBack = await folderApis.createFolder(allParams);

        if (createFolderBack.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: createFolderBack.error,
          });
          setTimeout(() => {
            modal.destroy();
          }, 3000);
        } else {
          // 共享创建的文件夹
          // 如果是共享
          let lastParams = {};
          let { groupids } = this.state;
          let initParams = {
            uid: Storage.getItem("gbdbid"),
            folder_level: needAddLevel,
            folder: createFolderBack.result.id,
            groupids,
          };

          if (this.state.isShare === 1) {
            let shareBack = await folderApis.shareFolder(initParams);

            if (shareBack.result) {
              // 如果添加的为一级文件夹
              this.createFolderOperate(needAddLevel, createFolderBack);
            } else {
              Modal.error({
                content: shareBack.error,
              });
            }
          } else {
            this.createFolderOperate(needAddLevel, createFolderBack);
          }
        }
      }
    });
  };

  folderToIndex = (item, index) => {
    this.setState({
      currentMoveToFolder: item,
      currentMoveToIndex: index,
    });
  };
  //
  deleteFolderOperate = async (params) => {
    let { currentFolderKey, childrenFoldersObj, parentFoldersObj, allFolders, folderLevel, childrenFolders, currentParentIndex } = this.state;
    let deleteBack = await folderApis.deleteFolder(params);

    if (deleteBack.result) {
      if (folderLevel === 2) {
        let currentFolder = childrenFoldersObj[currentFolderKey];
        let deleteIndex = currentFolder.index;

        let parentOrder = currentParentIndex;

        --allFolders[parentOrder].folder_second_count;

        childrenFolders.splice(deleteIndex, 1);

        this.setState(
          {
            childrenFolders,
            allFolders,
            // 设置当前的文件夹为删除文件夹的父级
            folderLevel: 1,
            currentFolderKey: allFolders[parentOrder].id,
          },
          () => {
            this.getFolderAssets();
          }
        );
      } else if (folderLevel === 1) {
        let currentFolder = parentFoldersObj[currentFolderKey];
        let deleteIndex = currentFolder.index;
        allFolders.splice(deleteIndex, 1);

        this.setState(
          {
            allFolders,
            currentFolderKey: 0,
            currentParentFolderName: "Default",
            currentParentIndex: 0,
          },
          () => {
            // 删除后重新请求展示的的资源
            this.getFolderAssets();
          }
        );
      }
    } else {
      Modal.error({
        title: "This is an error message",
        content: deleteBack.error,
      });
    }
  };
  deleteFolder = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let that = this;
    let {
      currentFolderKey,

      folderLevel,
    } = that.state;

    confirm({
      title: "Are you sure delete this folder?",
      content: "",
      okText: "Yes",
      okType: "danger",
      okButtonProps: {
        //
      },
      cancelText: "No",
      // flag=1 保留资源
      onOk() {
        confirm({
          title: "Please read the instruction",
          content: "Do you want to delete the files under the folder?",
          okText: "Yes",
          okType: "danger",
          okButtonProps: {},
          cancelText: "No",
          async onOk() {
            let params = {
              id: currentFolderKey,
              flag: 0,
              folder_level: folderLevel,
            };
            that.deleteFolderOperate(params);
          },
          async onCancel() {
            let { currentFolderKey, folderObjs, allFolders } = that.state;
            let params = {
              id: currentFolderKey,
              flag: 1,
              folder_level: folderLevel,
            };

            that.deleteFolderOperate(params);
          },
        });
      },
      onCancel() {},
    });
  };
  // 移动文件夹
  clickParentMove = async (e, type, itemValue) => {
    if (e) {
      e.domEvent.stopPropagation();
      e.domEvent.preventDefault();
    }
    if (type === "nochildren") {
      this.setState({
        openMoveKeys: [],
      });
    }

    this.setState(
      {
        currentFolderMoveKey: e.key.split("parent")[0],
        folderMoveLevel: 1,
      },
      async () => {
        let params = {
          parent: this.state.currentFolderMoveKey,
          uid: Storage.getItem("gbdbid"),
          folder_type: Utils.folderTypeFun(this.state.currentKey),
        };
        let getSecondBack = await folderApis.getSecondFolders(params);

        if (getSecondBack.result) {
          const childrenFolderLists = getSecondBack.result;

          this.setState(
            {
              childrenMoveFolders: childrenFolderLists,
            },
            () => {}
          );
        } else {
          Modal.error({
            content: "Folder fetch failed",
          });
        }
      }
    );
  };
  clickChildMove = (e, itemValue) => {
    if (e) {
      e.domEvent.stopPropagation();
      e.domEvent.preventDefault();
    }
    this.setState(
      {
        currentFolderMoveKey: e.key,
        folderMoveLevel: 2,
      },
      () => {}
    );
  };
  detailFormOk = () => {
    this.setState({
      showDetailForm: false,
    });
  };
  detailFormCancel = () => {
    this.setState({
      showDetailForm: false,
    });
  };

  handleInfiniteOnLoad = () => {};
  clickParentFolder = async (e, type, itemValue, index) => {
    if (e) {
      e.domEvent.stopPropagation();
      e.domEvent.preventDefault();
    }

    if (type === "nochildren") {
      this.setState({
        openKeys: [],
      });
    }

    this.setState(
      {
        currentFolderKey: e.key.split("parent")[0],
        folderLevel: 1,
        currentParentFolderName: itemValue.folder_name,
        currentParentIndex: index,
        childrenFolders: [],
        childrenFoldersObj: {},
      },
      async () => {
        let params = {
          parent: this.state.currentFolderKey,
          uid: Storage.getItem("gbdbid"),
          folder_type: Utils.folderTypeFun(this.state.currentKey),
        };
        let getSecondBack = await folderApis.getSecondFolders(params);

        if (getSecondBack.result) {
          const childrenFolderLists = getSecondBack.result;

          this.setState(
            {
              childrenFolders: childrenFolderLists,
            },
            () => {}
          );
        } else {
          Modal.error({
            content: "Folder fetch failed",
          });
        }
        // 获取资源
        this.resetCheckData();
        this.getFolderAssets();
      }
    );
  };
  clickChildFolder = (e, itemValue) => {
    if (e) {
      e.domEvent.stopPropagation();
      e.domEvent.preventDefault();
    }
    this.setState(
      {
        currentFolderKey: e.key,
        folderLevel: 2,
      },
      () => {
        this.resetCheckData();
        this.getFolderAssets();
      }
    );
  };
  //目前展开则点击后关闭，没有的话，则展开
  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => this.state.openKeys.indexOf(key) === -1);
    this.setState(
      {
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      },
      () => {}
    );
  };
  moveFolder = () => {
    this.setState(
      {
        moveFolderModal: true,
        currentFolderMoveKey: 0,
        folderMoveLevel: 1,
      },
      () => {}
    );
  };
  moveTofolderId = async (value) => {
    let moveTofolderKey = value.key;
    this.setState({
      moveTofolderKey,
    });
  };
  moveTofolderOk = async () => {
    let { currentFolderMoveKey, currentFolderKey, checkedObj, folderMoveLevel, folderLevel } = this.state;

    if (currentFolderMoveKey == currentFolderKey && folderLevel === folderMoveLevel) {
      Modal.error({
        content: "The operation folder is the same as the original folder",
      });
      return;
    }

    let params = {
      folder: currentFolderMoveKey,
      ids: Object.keys(checkedObj),
      folder_level: folderMoveLevel,
    };
    let moveFolderBack = await SubsetApis.moveAssetsFolder(params);

    // 移动文件夹成功处理未写
    if (moveFolderBack.result) {
      let { currentKey } = this.state;
      if (currentKey === "reference") {
        this.child.deleteFun();
      } else if (currentKey === "section") {
        this.geographyChild.deleteFun();
      } else if (currentKey === "collection") {
        this.collectionChild.deleteFun();
      } else if (currentKey === "geochemical") {
        this.geochemialChild.deleteFun();
      } else if (currentKey === "fossilOccurrence") {
        this.occurenceChild.deleteFun();
      }
      this.setState({
        moveFolderModal: false,
        openMoveKeys: [],
        currentFolderMoveKey: 0,
        folderMoveLevel: 1,
      });
    }
  };
  moveTofolderCancel = () => {
    this.setState({
      moveFolderModal: false,
      openMoveKeys: [],
      currentFolderMoveKey: 0,
      folderMoveLevel: 1,
    });
  };
  changeFolderType = (value) => {
    this.setState(
      {
        isShare: Number(value),
      },
      () => {
        this.getGroups();
      }
    );
  };
  clickMenu = async (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!this.state.isVisit) {
      await this.setState(
        {
          currentFolderKey: 0,
          folderLevel: 1,
          openKeys: [],
          currentParentFolderName: "Default",
          currentParentIndex: 0,
        },
        () => {
          this.getFolderAssets();
        }
      );
    }
  };
  changeGroups = (value) => {
    this.setState(
      {
        groupids: value,
      },
      () => {}
    );
  };

  onOpenMoveChange = (openMoveKeys) => {
    const latestOpenKey = openMoveKeys.find((key) => this.state.openMoveKeys.indexOf(key) === -1);
    this.setState(
      {
        openMoveKeys: latestOpenKey ? [latestOpenKey] : [],
      },
      () => {}
    );
  };
  getShareFolderAssets = (itemValue) => {
    // let uid = Storage.getItem("gbdbid");
    let params = {
      uid: itemValue.uid,
      folder: Number(itemValue.id),
      assetstype: Utils.folderTypeFun(this.state.currentKey),
      folder_level: itemValue.folder_level,
    };
    this.setState(
      {
        searchParams: params,
      },
      () => {
        let { searchParams, currentKey } = this.state;

        setTimeout(() => {
          if (currentKey === "reference") {
            this.child.searchSubset(searchParams);
          } else if (currentKey === "section") {
            this.geographyChild.searchSubset(searchParams);
          } else if (currentKey === "collection") {
            this.collectionChild.searchSubset(searchParams);
          } else if (currentKey === "geochemical") {
            this.geochemialChild.searchSubset(searchParams);
          } else if (currentKey === "fossilOccurrence") {
            this.occurenceChild.searchSubset(searchParams);
          }
        }, 20);
      }
    );
  };
  shareFolderAsset = async (e, itemValue) => {
    this.resetCheckData();
    if (e) {
      e.domEvent.stopPropagation();
      e.domEvent.preventDefault();
    }
    this.setState({
      shareFolderKey: itemValue.folder_level == 1 ? itemValue.id + "parent" : itemValue.id,
    });
    this.getShareFolderAssets(itemValue);
  };

  onRef = (ref) => {
    this.child = ref;
  };
  changeCheckSelected = (checkedObj) => {
    this.setState(
      {
        checkedObj,
      },
      () => {}
    );
  };
  getRefDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showDetailForm: true,
    });

    //点击 detail 按扭执行代码
  };
  detailFormOk = () => {
    this.setState({
      showDetailForm: false,
    });
  };
  detailFormCancel = () => {
    this.setState({
      showDetailForm: false,
    });
  };
  showOptions = () => {
    this.setState({
      ResultVisible: true,
    });
  };

  // section
  onGeography = (ref) => {
    this.geographyChild = ref;
  };
  geographyDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showSectionDetailForm: true,
    });
  };
  checkGeographyOne = () => {};

  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showDetailForm: true,
      sectionType,
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showDetailForm: true,
      sectionType,
    });
  };
  detailSectionFormOk = () => {
    this.setState({
      showSectionDetailForm: false,
      // sectionType: "baseinfo"
    });
  };
  detailSectionFormCancel = () => {
    this.setState({
      showSectionDetailForm: false,
      // sectionType: "baseinfo"
    });
  };

  // collection
  onCollection = (ref) => {
    this.collectionChild = ref;
  };
  collectionDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showCollDetailForm: true,
    });
  };

  detailCollectionFormOk = () => {
    this.setState({
      showCollDetailForm: false,
    });
  };
  detailCollectionFormCancel = () => {
    this.setState({
      showCollDetailForm: false,
    });
  };
  // Geochemial
  onGeochemial = (ref) => {
    this.geochemialChild = ref;
  };

  getGeochemialDetail = (itemValue) => {
    this.setState(
      {
        detailData: itemValue,
        // showDetailForm: true
      },
      () => {
        // 化学页面跳页面
        let inheritRefIds = itemValue.ref_id ? itemValue.ref_id : "";
        let baseId = itemValue.section_basic_id ? itemValue.section_basic_id : "";
        let geochemialId = itemValue.id ? itemValue.id : "";

        let url = "/views/geochemical?";

        let refUrl = inheritRefIds ? "refId=" + inheritRefIds : "";

        let baseIdUrl = baseId ? "&baseId=" + baseId : "";
        let geochemialUrl = geochemialId ? "&geochemialId=" + geochemialId : "";

        let params = `${url}${refUrl}${baseIdUrl}${geochemialUrl}`;
        const w = window.open("about:blank");
        w.location.href = params;
        // this.props.history.push(params);
      }
    );
  };

  detailGeochemicalFormOk = () => {
    this.setState({
      showGeochemicalDetailForm: false,
    });
  };
  detailGeochemicalFormOk = () => {
    this.setState({
      showGeochemicalDetailForm: false,
    });
  };

  resetCheckData = () => {
    let { searchParams, currentKey } = this.state;

    if (currentKey === "reference") {
      if (this.child.resetCheckData) {
        this.child.resetCheckData(searchParams);
      }
    } else if (currentKey === "section") {
      if (this.geographyChild.resetCheckData) {
        this.geographyChild.resetCheckData(searchParams);
      }
    } else if (currentKey === "collection") {
      if (this.collectionChild.resetCheckData) {
        this.collectionChild.resetCheckData(searchParams);
      }
    } else if (currentKey === "geochemical") {
      if (this.geochemialChild.resetCheckData) {
        this.geochemialChild.resetCheckData(searchParams);
      }
    } else if (currentKey === "fossilOccurrence") {
      if (this.occurenceChild.resetCheckData) {
        this.occurenceChild.resetCheckData(searchParams);
      }
    }
    this.setState({
      checkedObj: {},
      isCheckAll: false,
    });
  };

  //

  onOccurence = (ref) => {
    this.occurenceChild = ref;
  };

  getOccurenceDetail = (itemValue) => {
    this.setState(
      {
        detailData: itemValue,
        // showDetailForm: true
      },
      async () => {
        let url = "/references/allresult/section?";
        let refId = itemValue.ref_id;
        let refUrl = refId ? "refId=" + refId : "";

        let baseId = itemValue.section_basic_id;
        let baseIdUrl = baseId ? "&baseId=" + baseId : "";

        let formationId = itemValue.formation_id;
        let formationUrl = formationId ? "&formationId=" + formationId : "";

        let unitId = itemValue.unit_id;
        let unitUrl = unitId ? "&unitId=" + unitId : "";

        let collId = itemValue.collection_id;
        let collUrl = collId ? "&collId=" + collId : "";

        let fossilId = itemValue.id;
        let fossilOccurnceUrl = fossilId ? "&fossilId=" + fossilId : "";

        if (unitId) {
          let params = { unit_id: unitId };
          let searchResult = await Apis.getUnits(params);
          if (searchResult.result && searchResult.result.length > 0) {
            formationId = searchResult.result[0].formation_id;
            formationUrl = formationId ? "&formationId=" + formationId : "";
          }
        }

        let params = `${url}${refUrl}${baseIdUrl}${formationUrl}${unitUrl}${collUrl}${fossilOccurnceUrl}`;
        const w = window.open("about:blank");
        w.location.href = params;
      }
    );
  };
  goGroupPage = () => {
    let url = "/group/create";
    const w = window.open("about:blank");
    w.location.href = url;
  };

  showMap = async () => {
    let {checkedObj} = this.state;
    if (Storage.getItem("token") == undefined) {
      Modal.error({
        title: "Please login first",
        content: "Only after the login can collect data!!!"
      })
      return
    }
    let ids= Object.keys(checkedObj);
    let url= "/subMap?sectionIds="+Array.from(ids);

    console.log("checkedObj:"+ids);

    const w = window.open("about:blank");
    w.location.href = url;
  };

  exportSinocor =async () =>{
    let {checkedObj} = this.state;
    if (Storage.getItem("token") == undefined) {
      Modal.error({
        title: "Please login first",
        content: "Only after the login can collect data!!!"
      })
      return
    }

    let params = {
      sectionIds: Object.keys(checkedObj)
    };

    let downBack = await SubsetApis.exportSinocor(params);
    if (downBack.error) {
      Modal.error({
        title: "This is an error message",
        content: downBack.error
      });
    }else if (downBack.result) {
      //this.openFile(downBack.result);

      if(downBack.result==""){
        alert("Insufficient data to generate file!");
      }else{
        let file=downBack.result;
        //console.log("fileValue:"+file);
        this.downloadFile(file);
      }
    }

  }

  downloadFile = file => {

    let idx = file.indexOf(".");
    let file_new = file.substring(idx + 1, file.length);
    let href = "/tsc" + file_new;
    // console.log("href:"+href);

    let idx2 = file.lastIndexOf("/");
    let name = file.substring(idx2 + 1, file.length);
    
    var a = document.createElement("a");
    a.setAttribute("href", href);
    a.setAttribute("download", name);
    a.setAttribute("id", "downloadFileA");
    a.setAttribute("name", "name");
    // 防止反复添加
    if (document.getElementById("downloadFileA")) {
      document.body.removeChild(document.getElementById("downloadFileA"));
    }
    document.body.appendChild(a);

    a.click();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    let {
      currentKey,
      currentFolderKey,

      allFolders,
      folderNames,

      isCollapsed,
      isBroken,

      checkedObj,
      myGroups,
      childrenFolders,

      rootSubmenuKeys,
      parentFoldersObj,
      childrenFoldersObj,
      folderLevel,
      sectionType,
      childrenFoldersMoveObj,
      parentFoldersMoveObj,
      childrenMoveFolders,
      folderMoveLevel,
      currentFolderMoveKey,
      isVisit,
    } = this.state;
    let value = "";
    if (folderLevel === 1) {
      value = currentFolderKey.toString() + "parent";
    } else {
      value = currentFolderKey.toString();
    }
    let selectedKeys = [value];

    let moveFolderValue = "";
    if (folderMoveLevel === 1) {
      moveFolderValue = currentFolderMoveKey.toString() + "parent";
    } else {
      moveFolderValue = currentFolderMoveKey.toString();
    }
    let selectedMoveKeys = [moveFolderValue];

    return (
      <div className="group-index subset-index">
        <div className="reference-search-operate">{this.state.isVisit ? <p className="group-index-title">Visit subset</p> : <p className="group-index-title">Data subset</p>}</div>
        <div className="reference-header" id="referenceMenu">
          <Menu defaultSelectedKeys={currentKey} mode="horizontal" onClick={(e) => this.changeType(e)} className="reference-search-menu reference-menu" selectedKeys={currentKey}>
            <Menu.Item key="reference">
              <div>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Reference
                </a>
              </div>
            </Menu.Item>
            <Menu.Item key="section">
              <div>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Section
                </a>
              </div>
            </Menu.Item>

            <Menu.Item key="collection">
              <div>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Collection
                </a>
              </div>
            </Menu.Item>
            <Menu.Item key="fossilOccurrence">
              <div>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Occurrence
                </a>
              </div>
            </Menu.Item>

            <Menu.Item key="geochemical">
              <div>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Geochemical
                </a>
              </div>
            </Menu.Item>
          </Menu>
        </div>

        <div className="form-mian-content subset-index-content clearfix">
          <Sider
            className={`subset-folder-sider ${isCollapsed && isBroken ? "noMenu" : isCollapsed === false && isBroken ? "hasMenu" : null}`}
            width={"18.2%"}
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              if (broken === true) {
                this.setState({
                  isBroken: true,
                });
              } else {
                this.setState({
                  isBroken: false,
                });
              }
            }}
            onCollapse={(collapsed, type) => {
              if (collapsed === true) {
                this.setState({
                  isCollapsed: true,
                });
              } else {
                this.setState({
                  isCollapsed: false,
                });
              }
            }}
          >
            {/* */}
            <div className="subset-folder" onClick={this.clickMenu}>
              <InfiniteScroll initialLoad={false} pageStart={0} loadMore={this.handleInfiniteOnLoad} hasMore={!this.state.loading && this.state.hasMore} useWindow={false}>
                {this.state.isVisit ? (
                  <Menu
                    className="subset-folder-menu"
                    mode="inline"
                    defaultSelectedKeys={[this.state.shareFolderKey.toString()]}
                    style={{ width: 256 }}
                    selectedKeys={[this.state.shareFolderKey.toString()]}
                  >
                    {allFolders.length > 0 && this.state.askLoading == false ? (
                      allFolders.map((item, index) => {
                        parentFoldersMoveObj[item.id] = {
                          item: item,
                          index: index,
                        };
                        return (
                          <Menu.Item key={item.folder_level == 1 ? item.id.toString() + "parent" : item.id.toString()} onClick={(e) => this.shareFolderAsset(e, item)}>
                            {item.folder_name}
                          </Menu.Item>
                        );
                      })
                    ) : this.state.askLoading == true ? (
                      <div className="no-share-folder folder-loading">Loading...</div>
                    ) : allFolders.length === 0 && this.state.askLoading == false ? (
                      <div disabled className="no-share-folder">
                        No resources
                      </div>
                    ) : (
                      <div className="no-share-folder"></div>
                    )}
                  </Menu>
                ) : (
                  <Menu className="subset-folder-menu" mode="inline" openKeys={this.state.openKeys} onOpenChange={this.onOpenChange} style={{ width: 256 }} selectedKeys={selectedKeys}>
                    {allFolders.length > 0 && this.state.askLoading == false ? (
                      allFolders.map((item, index) => {
                        parentFoldersObj[item.id] = {
                          item: item,
                          index: index,
                        };

                        if (item.folder_level === 1 && item.folder_second_count !== 0) {
                          rootSubmenuKeys[item.id] = {
                            item,
                          };
                          return (
                            <SubMenu
                              key={item.id + "parent"}
                              datavalue={item}
                              title={
                                <span>
                                  <span>{item.folder_name}</span>
                                </span>
                              }
                              onTitleClick={(e) => this.clickParentFolder(e, "", item, index)}
                            >
                              {childrenFolders.map((childrenItem, childrenIndex) => {
                                childrenFoldersObj[childrenItem.id] = {
                                  item: childrenItem,
                                  index: childrenIndex,
                                  // parent: item,
                                  // parentIndex: index
                                };
                                return (
                                  <Menu.Item key={childrenItem.id.toString()} onClick={(e) => this.clickChildFolder(e, childrenItem)}>
                                    {childrenItem.folder_name}
                                  </Menu.Item>
                                );
                              })}
                            </SubMenu>
                          );
                        } else {
                          return (
                            <Menu.Item key={item.id.toString() + "parent"} onClick={(e) => this.clickParentFolder(e, "nochildren", item, index)}>
                              {item.folder_name}
                            </Menu.Item>
                          );
                        }
                      })
                    ) : this.state.askLoading == true ? (
                      <div className="no-share-folder folder-loading">Loading...</div>
                    ) : allFolders.length === 0 && this.state.askLoading == false ? (
                      <div disabled className="no-share-folder">
                        Unshared resources
                      </div>
                    ) : (
                      <div className="no-share-folder"></div>
                    )}
                  </Menu>
                )}
              </InfiniteScroll>
              {this.state.isVisit ? null : (
                <div className="folder-operate-icons clearfix">
                  <button
                    className="iconfont icon-plus31 fl"
                    title={folderLevel === 2 ? "The maximum folder level is two" : "add folder"}
                    onClick={this.showAddFolder}
                    disabled={folderLevel === 2 ? true : false}
                  ></button>
                  <button
                    className="iconfont icon-minus_bordered fr"
                    title={JSON.stringify(currentFolderKey) === "0" ? "The default folder cannot be deleted" : "delete folder"}
                    disabled={JSON.stringify(currentFolderKey) === "0" || allFolders.length < 0 ? true : false}
                    onClick={this.deleteFolder}
                  ></button>
                </div>
              )}
            </div>
          </Sider>

          <div className={`subset-data-list ${isCollapsed && isBroken ? "subset-list-full" : isCollapsed === false && isBroken ? "subset-list-none" : null}`}>
            <div id="SearchResult">
              <div className="SearchResult-main taxResult-main">
                <div className="subset-content">
                  <InfiniteScroll>
                    {currentKey === "reference" ? (
                      <ReferenceTableResult
                        showAddSubset={isVisit === true ? true : false}
                        showDeleteSubset={isVisit === true ? false : true}
                        showExportFile={true}
                        searchParams={this.state.searchParams}
                        searchAllValues={this.state.searchAllValues}
                        onRef={this.onRef}
                        getDetail={this.getRefDetail}
                        changeCheckSelected={this.changeCheckSelected}
                      ></ReferenceTableResult>
                    ) : currentKey === "section" ? (
                      <SectionAllTableResult
                        showAddSubset={isVisit === true ? true : false}
                        showDeleteSubset={isVisit === true ? false : true}
                        showExportFile={true}
                        showSubMap={true}
                        searchParams={this.state.searchParams}
                        searchValues={this.state.searchValues}
                        onGeography={this.onGeography}
                        changeCheckSelected={this.changeCheckSelected}
                        getDetail={this.geographyDetail}
                        // checkOne={true}
                        // checkGeographyOne={this.checkGeographyOne}
                      ></SectionAllTableResult>
                    ) : currentKey === "collection" ? (
                      <CollectionTableResult
                        showAddSubset={isVisit === true ? true : false}
                        showDeleteSubset={isVisit === true ? false : true}
                        showExportFile={true}
                        searchParams={this.state.searchParams}
                        onCollection={this.onCollection}
                        changeCheckSelected={this.changeCheckSelected}
                        collectionDetail={this.collectionDetail}
                      ></CollectionTableResult>
                    ) : currentKey === "geochemical" ? (
                      <GeochemicalTableResult
                        showAddSubset={isVisit === true ? true : false}
                        showDeleteSubset={isVisit === true ? false : true}
                        showExportFile={true}
                        searchParams={this.state.searchParams}
                        searchValues={this.state.searchValues}
                        onGeochemial={this.onGeochemial}
                        changeCheckSelected={this.changeCheckSelected}
                        getDetail={this.getGeochemialDetail}
                        checkOne={false}
                      ></GeochemicalTableResult>
                    ) : currentKey === "fossilOccurrence" ? (
                      <OccurenceTableList
                        showAddSubset={isVisit === true ? true : false}
                        showDeleteSubset={isVisit === true ? false : true}
                        showExportFile={true}
                        searchParams={this.state.searchParams}
                        searchValues={this.state.searchValues}
                        onOccurence={this.onOccurence}
                        changeCheckSelected={this.changeCheckSelected}
                        getDetail={this.getOccurenceDetail}
                        checkOne={false}
                      ></OccurenceTableList>
                    ) : null}
                  </InfiniteScroll>
                </div>

                {this.state.isVisit ? null : (
                  <div className={currentKey !== "reference" ? "subset-file-btns subset-move-folderbtn  subset-section-folderbtn" : "subset-file-btns subset-move-folderbtn"}>
                    {/* <Button type="gbdb">Export to excel files</Button> */}

                    <Button type="gbdb" onClick={this.moveFolder} disabled={Object.keys(checkedObj).length == 0}>
                      Move Folder
                    </Button>
                  </div>
                )}

                {this.state.currentKey === "section" ? (
                  <div style={{left:600}} className={currentKey === "section" ? "subset-file-btns subset-move-folderbtn  subset-section-folderbtn" : "subset-file-btns subset-move-folderbtn"}>
                    {/* <Button type="gbdb">Export to excel files</Button> */}

                    <Button type="gbdb" onClick={this.showMap} disabled={Object.keys(checkedObj).length == 0}>
                      Show Map Point
                    </Button>
                  </div>
                ):null}

                {this.state.currentKey === "section" ? (
                  <div style={{left:800}} className={currentKey === "section" ? "subset-file-btns subset-move-folderbtn  subset-section-folderbtn" : "subset-file-btns subset-move-folderbtn"}>
                    {/* <Button type="gbdb">Export to excel files</Button> */}

                    <Button type="gbdb" onClick={this.exportSinocor} disabled={Object.keys(checkedObj).length == 0}>
                      Export Sinocor files
                    </Button>
                  </div>
                ):null}
              </div>
            </div>
          </div>
        </div>

        <Modal destroyOnClose title="" visible={this.state.folderModal} onOk={this.handFolderleOk} onCancel={this.handleFolderCancel} footer={null} header={null}>
          <div>
            <Form layout="vertical" className="addfolder-form">
              <Form.Item label="Folder Name">
                {getFieldDecorator("folder_name", {
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        try {
                          if (!value) {
                            callback("Please input your Folder Name!");
                          } else if (folderNames.hasOwnProperty(value) || value === "Default") {
                            callback("Folder name repetition");
                          } else {
                            callback();
                          }
                        } catch (err) {
                          callback(err);
                        }
                      },
                      required: true,
                    },
                  ],
                })(<Input placeholder="Folder Name" />)}
              </Form.Item>
              <Form.Item label="Whether the public">
                {getFieldDecorator("isShare", {
                  rules: [
                    {
                      required: true,
                    },
                  ],
                  initialValue: "0",
                })(
                  <Select placeholder="Whether the public" onChange={this.changeFolderType}>
                    <Option key="0">Private</Option>
                    <Option key="1">Public</Option>
                  </Select>
                )}
              </Form.Item>
              {this.state.isShare === 1 && myGroups.length > 0 ? (
                <Form.Item label="Choose group to share">
                  {getFieldDecorator("groupid", {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(
                    <Select mode="multiple" style={{ width: "100%" }} placeholder="Please select" onChange={this.changeGroups}>
                      {myGroups.map((item, index) => {
                        return <Option key={item.id}>{item.name}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              ) : this.state.isShare === 1 && myGroups.length === 0 ? (
                <div>
                  You don't have a group,
                  <span className="folder-go-page" onClick={this.goGroupPage}>
                    Please create
                  </span>
                </div>
              ) : null}
            </Form>

            <div className="addgroup-form-operate">
              <Button type="gbdb" onClick={() => this.confirmAddFolder()} disabled={this.state.isShare === 1 && myGroups.length === 0}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>

        {currentKey === "reference" ? (
          <Modal
            title=""
            destroyOnClose
            visible={this.state.showDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76.8%"
            footer={null}
            // header={null}
            onOk={this.detailFormOk}
            onCancel={this.detailFormCancel}
          >
            <ReferenceDetailForm
              detailData={this.state.detailData}
              // onlySeeRef={isVisit?true:false}
              onlySeeRef={true}
              //  searchSection={this.searchSection}
            ></ReferenceDetailForm>
          </Modal>
        ) : currentKey === "section" ? (
          <Modal
            title=""
            destroyOnClose
            visible={this.state.showSectionDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76.8%"
            footer={null}
            // header={null}
            onOk={this.detailSectionFormOk}
            onCancel={this.detailSectionFormCancel}
          >
            {sectionType === "baseinfo" ? (
              <BaseDetailForm
                detailData={this.state.detailData}
                viewGeography={this.viewGeography}
                // seeOnly={isVisit?true:false}
                seeOnly={true}
                seeGeographyFossil={!isVisit ? true : false}
              ></BaseDetailForm>
            ) : (
              <GeographyDetailForm
                detailData={this.state.detailData}
                // seeOnly={isVisit?true:false}
                seeOnly={true}
                viewBase={this.viewBase}
                seeGeographyFossil={!isVisit ? true : false}
              ></GeographyDetailForm>
            )}
          </Modal>
        ) : currentKey === "collection" ? (
          <Modal
            title=""
            destroyOnClose
            visible={this.state.showCollDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76%"
            footer={null}
            // header={null}
            onOk={this.detailCollectionFormOk}
            onCancel={this.detailCollectionFormCancel}
          >
            <CollectionDetailForm detailData={this.state.detailData} seeGeographyFossil={!isVisit ? true : false}></CollectionDetailForm>
          </Modal>
        ) : null}

        <Modal
          title="Move folder"
          destroyOnClose
          visible={this.state.moveFolderModal}
          className="searchResultModal taxResultModal DetailModal subset-movemodal clearfix"
          width="30%"
          onOk={this.moveTofolderOk}
          onCancel={this.moveTofolderCancel}
        >
          <div className="subset-folder subset-movemodal-folder">
            <Menu className="subset-folder-menu" mode="inline" openKeys={this.state.openMoveKeys} onOpenChange={this.onOpenMoveChange} style={{ width: 256 }} selectedKeys={selectedMoveKeys}>
              {allFolders.map((item, index) => {
                parentFoldersMoveObj[item.id] = {
                  item: item,
                  index: index,
                };

                if (item.folder_level === 1 && item.folder_second_count !== 0) {
                  // if (item.folder_second_count !== 0) {
                  rootSubmenuKeys[item.id] = {
                    item,
                  };
                  return (
                    <SubMenu
                      key={item.id.toString() + "parent"}
                      datavalue={item}
                      title={
                        <span>
                          <span>{item.folder_name}</span>
                        </span>
                      }
                      onTitleClick={(e) => this.clickParentMove(e, "", item)}
                    >
                      {childrenMoveFolders.map((childrenItem, childrenIndex) => {
                        childrenFoldersMoveObj[childrenItem.id] = {
                          item: childrenItem,
                          index: childrenIndex,
                          // parent: item,
                          // parentIndex: index
                        };
                        return (
                          <Menu.Item key={childrenItem.id.toString()} onClick={(e) => this.clickChildMove(e, childrenItem)}>
                            {childrenItem.folder_name}
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  );
                } else if (item.folder_level === 1 && item.folder_second_count === 0) {
                  return (
                    <Menu.Item key={item.id.toString() + "parent"} onClick={(e) => this.clickParentMove(e, "nochildren", item)}>
                      {item.folder_name}
                    </Menu.Item>
                  );
                }
              })}
            </Menu>
          </div>
        </Modal>
      </div>
    );
  }
}

Subset = Form.create({})(Subset);
export default Subset;
