import React from "react";

import { Form, Input, Select, Button, AutoComplete, Modal } from "antd";
import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";
import YearPicker from "commons/YearPicker/index";
import centerApis from "src/js/apis/center";

import Utils from "src/js/utils";

import Storage from "src/js/storage";
import Apis from "src/js/apis/api";
import dataApis from "src/js/apis/data";
import "./index.css";
import { withRouter } from "react-router-dom";
const { Option } = Select;
const { TextArea } = Input;

@withRouter
class TaxonomyDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      // reference , taxonomy , section,occurence,formation
      detailData: props.detailData || {},
      onlySeeRef: props.onlySeeRef === true ? true : false,

      name1Opts: [],
      name2Opts: [],
      clatorOpts: [],
      seeOnly: props.seeOnly === true ? true : false,
      treatises: [],
      taxonTypes: [],
      fossilgroup: {},
      allChildError: {},
      isCenterUser: props.isCenterUser === true ? true : false,
      yearValue: null
    };
  }
  async componentDidMount() {
    let fossilgroup = await dataApis.fossilgroup();
    let taxonTypes = await dataApis.getTaxontypes();
    let treatises = await dataApis.getTreatise();
    this.setState(
      {
        fossilgroup:
          fossilgroup && fossilgroup.result ? fossilgroup.result : {},
        taxonTypes: taxonTypes && taxonTypes.result ? taxonTypes.result : [],
        treatises: treatises && treatises.result ? treatises.result : []
      },
      () => {}
    );

    let id = this.state.detailData.id;
    let getTaxonsBack;
    let { isCenterUser } = this.state;
    // let isCenter=(this.props.location.pathname==='/center')
    if (!isCenterUser) {
      let params = {
        id
      };
      getTaxonsBack = await Apis.getTaxons(params);
    } else {
      let params = {
        assetstype: 5,
        enterer: Storage.getItem("gbdbid"),
        id
      };
      getTaxonsBack = await centerApis.searchAssets(params);
    }

    if (getTaxonsBack.result && getTaxonsBack.result.length > 0) {
      this.setState({
        detailData: getTaxonsBack.result[0] || {},
        taxonType: getTaxonsBack.result[0].taxon_type
      });
    } else {
      this.setState({
        detailData: {},
        taxonType: "species-original"
      });
      if(getTaxonsBack.error){
        Modal.error({
          content: getTaxonsBack.error
        });
      }
     
    }
  }
  viewSection = () => {
    this.props.searchSection();
  };

  handleName1Change = value => {
    this.props.form.setFieldsValue({
      taxName1: value
    });
  };
  handleName1Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name1"],
        taxon_name1: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name1Opts: [] });
        } else {
          this.setState(
            {
              name1Opts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ name1Opts: [] });
    }
  };

  handleName2Change = value => {
    this.props.form.setFieldsValue({
      taxName2: value
    });
  };
  handleName2Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name2"],
        taxon_name2: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name2Opts: [] });
        } else {
          this.setState(
            {
              name2Opts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ name2Opts: [] });
    }
  };

  handleNomSearch = async value => {
    if (value) {
      let params = {
        attributes: ["nomenclator"],
        nomenclator: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);
      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ clatorOpts: [] });
        } else {
          this.setState(
            {
              clatorOpts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ clatorOpts: [] });
    }
  };

  handleNomChange = value => {
    this.props.form.setFieldsValue({
      nomenclator: value
    });
  };
  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };
  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",
      year: value.ref_publication_year || null,
      // nomenclator: value.authorizer || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };
  onRef = ref => {
    this.child = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true
    });

    //点击 detail 按扭执行代码
  };
  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            refSearch: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues } = this.state;

              this.child.searchReferenceDatas(searchParams, searchValues);
            }, 20);
          }
        );
      }
    });
  };
  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };

  modifyTaxomy = async () => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        // let changeTaxon = Utils.filterParams(values);
        let changeTaxon = Utils.filterCondition(values);

        changeTaxon = Utils.addEnterer(changeTaxon);

        let refData = {};
        let searchParams = { ref_id: values.ref_id };

        let refresResult = await Apis.searchReference(searchParams);

        if (refresResult.result && refresResult.result.length > 0) {
          refData = refresResult.result[0];
        }
        changeTaxon = Utils.addAudit(changeTaxon, refData);
        let { detailData } = this.state;
        let id = detailData.id;
        changeTaxon.id = id;
        changeTaxon.authState = 0;
        // 服务器注释
        let changeTaxonBack = await Apis.changeTaxonById(changeTaxon);
        if (changeTaxonBack.error) {
          Modal.error({
            title: "This is an error message",
            content: changeTaxonBack.error
          });
        } else if (changeTaxonBack.result) {
          if (this.props.modifyTaxomy) {
            this.props.modifyTaxomy(changeTaxonBack.result);
          }
        }
      }
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  publishYear = value => {
    this.setState({
      yearValue: value
    });
  };
  render() {
    let { getFieldDecorator } = this.props.form;
    let { detailData, seeOnly, taxonTypes, taxonType } = this.state;
    let { enterer } = detailData;
    let userid = Storage.getItem("gbdbid") ? Storage.getItem("gbdbid") : "";
    let isClick = true;
    let isCenter = this.props.location.pathname === "/center";
    if (enterer && userid && enterer === userid && isCenter) {
      isClick = true;
    }

    if (isClick === false) {
      getFieldDecorator = (...rest) => {
        return element => {
          let NewElement = React.cloneElement(element, {
            disabled: true
          });
          return this.props.form.getFieldDecorator(...rest)(NewElement);
        };
      };
    }

    let { fossilgroup, treatises } = this.state;
    const name1Opts = this.state.name1Opts.map((item, indx) => (
      <Option key={item.taxon_name1}>{item.taxon_name1}</Option>
    ));
    const name2Opts = this.state.name2Opts.map((item, indx) => (
      <Option key={item.taxon_name2}>{item.taxon_name2}</Option>
    ));
    const clatorOpts = this.state.clatorOpts.map((item, index) => (
      <Option key={index}>{item.nomenclator}</Option>
    ));
    return (
      <div id="detail_form">
        <div className=" collection-add-main">
          <div className="detailform-title">
            <span className="detailform-name">Taxonomy</span>
          </div>

          <div className="addHigther-main">
            <div className="reference-search-form addHigther-form" id="main">
              <Form>
                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<p>Fossil group</p>}>
                      {getFieldDecorator("fossil_group", {
                        rules: [{}],
                        initialValue: detailData.fossil_group || null
                      })(<Select>{Utils.fossilGroup(fossilgroup)}</Select>)}
                    </Form.Item>
                  </div>

                  <div className="referenceSearch-no">
                    <Form.Item label={<p>Taxon type</p>}>
                      {getFieldDecorator("taxon_type", {
                        rules: [],
                        initialValue: detailData.taxon_type || null
                      })(
                        <Select>
                          {taxonType === "species-original" ? (
                            <Option value="species-original">
                              species-original
                            </Option>
                          ) : taxonType === "species-incomplete" ? (
                            <Option value="species-incomplete">
                              species-incomplete
                            </Option>
                          ) : (
                            taxonTypes.map((item, index) => {
                              return (
                                <Option value={item.type_name} key={item.type_name}>
                                  {item.type_name}
                                </Option>
                              );
                            })
                          )}
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <Form.Item label={<span>Taxon name1</span>}>
                    {getFieldDecorator("taxon_name1", {
                      rules: [
                        {
                          required: true,
                          message: "Please input the Taxon name"
                        }
                      ],

                      initialValue: detailData.taxon_name1 || null
                    })(
                      <AutoComplete
                        onSearch={this.handleName1Search}
                        onChange={this.handleName1Change}
                        placeholder=""
                      >
                        {name1Opts}
                      </AutoComplete>
                    )}
                  </Form.Item>

                  <Form.Item label={<span>Taxon name2</span>}>
                    {getFieldDecorator("taxon_name2", {
                      rules: [
                        {
                          // required: true,
                          // message: 'Please input the Taxon name',
                        }
                      ],

                      initialValue: detailData.taxon_name2 || null
                    })(
                      <AutoComplete
                        onSearch={this.handleName2Search}
                        onChange={this.handleName2Change}
                        placeholder=""
                      >
                        {name2Opts}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<p>Nomenclator</p>}>
                      {getFieldDecorator("nomenclator", {
                        rules: [{}],
                        initialValue: detailData.nomenclator || null
                      })(
                        <AutoComplete
                          onSearch={this.handleNomSearch}
                          onChange={this.handleNomChange}
                          placeholder=""
                        >
                          {clatorOpts}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<p>Year</p>}>
                      {getFieldDecorator("year", {
                        rules: [],

                        initialValue:
                          detailData && detailData.year ? detailData.year : null
                      })(
                        <YearPicker
                          onChange={value => this.publishYear(value)}
                          time={
                            detailData.year
                              ? detailData.year
                              : this.state.yearValue
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<p>Collect from Treatise?</p>}>
                      {getFieldDecorator("from_treatise", {
                        rules: [{}],
                        initialValue: detailData.from_treatise || null
                      })(
                        <Select>
                          {treatises.map((item, index) => {
                            return (
                              <Option value={item.name} key={item.name}>{item.name}</Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>Reference</span>}>
                      {getFieldDecorator("ref_id", {
                        rules: [
                          {
                            required: true
                          }
                        ],
                        // initialValue: detailData.ref_id || ""
                        initialValue: detailData.ref_id || "0"
                      })(<Input onFocus={this.showSearchRef} />)}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceAdd-comments last-formItem">
                  <Form.Item label="Notes" className="long-label">
                    {getFieldDecorator("notes", {
                      rules: [{}],
                      initialValue: detailData.notes || null
                    })(<TextArea rows={6}></TextArea>)}
                  </Form.Item>
                </div>
                {detailData.auth_time !== null ? (
                  <div className="referenceAdd-authours referenceAdd-editors">
                    <div className="authcomment-details">
                      {detailData.authState === 2 ? (
                        <div className="referenceAdd-author authcomment-detail-item">
                          <div className="referenceAdd-author-title authcomment-title">
                            Auth Comment
                          </div>
                          <div className="authcomment-content">
                            {detailData.auth_comment !== ""
                              ? detailData.auth_comment
                              : "No comment"}
                          </div>
                        </div>
                      ) : null}
                      {detailData.authState !== 0 ? (
                        <div className="referenceAdd-author authcomment-detail-item">
                          <div className="referenceAdd-author-title authcomment-title">
                            Auth Time
                          </div>
                          <div className="authcomment-content">
                            {detailData.auth_time
                              ? detailData.auth_time.split("T")[0]
                              : ""}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </Form>
            </div>
          </div>

          <div className="referenceSearch-btns sectionBase-btns">
            <div className="formation-complete-btns">
              {isClick && seeOnly === false ? (
                <Button type="gbdb" onClick={this.modifyTaxomy}>
                
                    <p>Save modify</p>
                  
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          <ReferenceOption
            childError={this.childError}
            conditions={{}}
            ref="reference"
          ></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>
        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          <ReferenceTableResult
            searchParams={this.state.searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showRefDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailRefFormOk}
          onCancel={this.detailRefFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
            // searchSection={this.searchSection}
            onlySeeRef={true}
          ></ReferenceDetailForm>
        </Modal>
      </div>
    );
  }
}
TaxonomyDetailForm = Form.create({})(TaxonomyDetailForm);
export default TaxonomyDetailForm;
