import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { Card,Form, Row, Col, Input, Button, } from "antd";


import "./index.scss";
import "src/js/global.js";
import insectsApis from "src/js/apis/insects";
import Storage from "src/js/storage";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function InsectsSearch() {
  const history = useHistory();
  /** 生成表单单个元素 */
  function getInput ({
    name,
    label = "Name",
    span = 8,
    rules,
    placeholder
  } = {}) {
    return (
      <Col span={span}>
        <Form.Item
          name={name || label.toLowerCase()}
          label={label}
          rules={rules}
        >
          <Input placeholder={placeholder} />
        </Form.Item>
      </Col>
    )
  };

  /** 执行搜索 */
  function doSearch() {
    console.log(">>>>>>>>>.执行搜索")
    history.push("/insects/list");
  }

  function getData(){
    let dataList=[
      {
        title: "CONOP64",
        name: "CONOP64",
        v: "6.21",
        size: "17.5 MB",
        time: "2021/2/26 22:30:13",
        desc: "CONOP 64 is a program of quantitative biostratigraphy designed by Prof. P. Sadler. With the permission by Prof. Sadler, we provide the latest version of CONOP for download.",
        url: [{
          name: "Download",
          url: "/download/7  CONOP64/Conop64.zip"
        }]
      },
      // {
      //   title: "CONOP 9",
      //   name: "CONOP9",
      //   v: "9",
      //   size: "4MB",
      //   time: "2013/9/11 22:30:13",
      //   desc: "CONOP 9 is a program of quantitative biostratigraphy designed by Prof. P. Sadler. With the permission by Prof. Sadler, we provide the latest version of CONOP for download.",
      //   url: [{
      //     name: "Download",
      //     url: "/download/1  CONOP 9/CONOP9.zip"
      //   }]
      // },
      {
        title: "SinoCor 4.0(ver 4.013)",
        name: "SinoCor4.zip",
        v: "4.0",
        size: "2.9MB",
        time: "2012/11/7 11:01:13",
        desc: "SinoCor is a program conducting graphic correlation, a widely-used method of quantititave biostratigraphy. SinoCor 4 provides several mathematical algorithms for generation of the LOC and calculates the equations for them. Furthermore, except for the preservation of the final result, SinoCor provides the possibility of storing and loading the processing data in each previous step, such as selection of points and equations of LOCs. Users can use the ‘Backward’ and ‘Forward’ functions to retrieve any previous data and operations. Because all the data and operations are saved in one SinoCor project file, users can pick up and continue their work at any time. All data and charts, such as the range data of the composite standard, correlation chart and composite standard range chart, can be outputted as an excel file, pdf file, or script file for Corel Draw.",
        url: [{
          name: "Download",
          url: "/download/2  SinoCor 4.0 (ver 4.013)/SinoCor4.zip"
        }]
      },
      // {
      //   title: "Reference batch import check tool",
      //   name: "ImportChecker.rar",
      //   v: "2.0",
      //   size: "11.2KB",
      //   time: "2012/8/7 17:19:04",
      //   desc: "This is a tool designed to check references import to the database in batch.",
      //   url: ""
      // },
      {
        title: "Microsoft .NET Framework Version 2.0 Redistributable Package (x86)",
        name: "dotnetfx_en.exe/dotnetfx_cn.exe",
        v: "2.0",
        size: "22.4MB",
        time: "2008/4/9 16:16:39",
        desc: `The Microsoft .NET Framework version 2.0 redistributable package installs the .NET Framework runtime and associated files required to run applications developed to target the .NET Framework v2.0.

        The .NET Framework version 2.0 improves scalability and performance of applications with improved caching, application deployment and updating with ClickOnce, support for the broadest array of browsers and devices with ASP.NET 2.0 controls and services.`,
        url: [{
          name: "Download EN",
          url: "/download/4  Microsoft .NET Framework Version 2.0 Redistributable Package (x86) EN/dotnetfx_en.exe"
        }, {
          name: "Download CN",
          url: "/download/5 Microsoft .NET Framework Version 2.0 Redistributable Package (x86) CN/dotnetfx_cn.exe"
        }]
      },
    ]
    return dataList;
  }

  return (
    <div className="insects-search">
      <div className="search-title">Related Software</div>
      <div className="search-content">
        {getData().map(file =>
              <div className="content-item">
                <Card title={file.title} extra={
                  <>
                    {
                      file.url.map(urlItem => {
                        return (
                          <Button type="primary" shape="round" href={urlItem.url} className="download-btn" target="_blank" download={file.name}>
                            {urlItem.name}
                          </Button>
                        )
                      }
                      )
                    }
                  </>
                }>
                  <Row className="file-desc">
                    <Col span={24}>
                      {file.desc}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      File Name:
                  </Col>
                    <Col span={18}>
                      {file.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      Version:
                    </Col>
                    <Col span={18}>
                      {file.v}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      Data Published:
                    </Col>
                    <Col span={18}>
                      {file.time}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      Download Size:
                    </Col>
                    <Col span={18}>
                      {file.size}
                    </Col>
                  </Row>
                </Card>
              </div>)}
      </div>
    </div>
  );
}
export default InsectsSearch;
