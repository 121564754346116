import React, { Component } from "react";
import { Button, Modal } from "antd";
import TaxonomyDetailForm from "commons/AllDetails/Taxonomy/index.js";
import SubsetApis from "src/js/apis/subset";
import TaxonomyOption from "commons/AllOptions/Taxonomy/AllSearch/index.js";
import TaxonomyResult from "commons/AllTableList/Taxonomy/index.js";

import Storage from "src/js/storage";

import Utils from "src/js/utils";

import "./index.css";
import "src/js/global.js";
class TaxonomySearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResultVisible: false,

      searchAllValues: {},
      detailData: {},
      showDetailForm: false,
      taxType: "Original",
      isCheckAll: false,
      checkedObj: {},
      resultLists: [],
      defaultCheckedList: [],
      allChildError: {},
      showBackIcon:false
    };
  }

  detailFormOk = () => {
    this.setState({
      showDetailForm: false
    });
  };
  detailFormCancel = () => {
    this.setState({
      showDetailForm: false
    });
  };
  showOptions = () => {
    this.setState({
      ResultVisible: true
    });
  };
  handFormOk = () => {
    this.setState({
      ResultVisible: false
    });
  };
  handleFormCancel = () => {
    this.setState({
      ResultVisible: false
    });
    Modal.destroyAll();
  };

  async componentDidMount() {
    let host = window.location.host;
    
    // let host2 = document.domain;
    let historyLength = this.props.history.length
    if (historyLength > 1) {
      this.setState({
        showBackIcon: true
      })
    }
    if (this.props.location.query) {
      const { searchParams, searchAllValues } = this.props.location.query;
      this.setState(
        {
          searchParams,
          searchAllValues
        },
        () => {
          let { searchParams, searchAllValues, sectionType } = this.state;

          setTimeout(() => {
            this.taxonomyChild.searchTaxonomyDatas(
              searchParams,
              searchAllValues,
              sectionType
            );
          }, 20);
        }
      );
    }
    if (this.props.location.search) {
      let inheritRefIds =
        Utils.getSearch(this.props.location.search, "refId") || "";
      let searchParams = {
        ref_id: inheritRefIds
      };
      let searchAllValues = {
        ref_id_from: Number(inheritRefIds),
        ref_id_to: Number(inheritRefIds)
      };
      this.setState({
        searchParams,
        searchAllValues
      });
    }
  }
  selectReference = () => {};

  checkAgain = optionType => {
    // let { allChildError } = this.state;
    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            let { searchParams, searchAllValues } = this.state;

            setTimeout(() => {
              this.taxonomyChild.searchTaxonomyDatas(
                searchParams,
                searchAllValues
              );
            }, 20);
          }
        );
      }
    });
  };
  showOptions = () => {
    this.setState({
      ResultVisible: true
    });
  };

  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showDetailForm: true
    });

    //点击 detail 按扭执行代码
  };
  searchSection = () => {
    this.setState(
      {
        showDetailForm: false
      },
      () => {
        let { detailData } = this.state;
        let queryParam = {
          refId: detailData.id
        };
        let url = "/searchs/result/section/base";
        this.props.history.push({ pathname: url, query: queryParam });
      }
    );
  };

  onTaxonomy = ref => {
    this.taxonomyChild = ref;
  };
  changeCheckSelected = checkedObj => {
    this.setState({
      checkedObj
    });
  };
  addSubset = async () => {
    let { checkedObj } = this.state;

    let uid = Storage.getItem("gbdbid");

    let params = {
      uid,
      assetstype: 5,

      ids: Object.keys(checkedObj)
    };
    let collectBack = await SubsetApis.collectAssets(params);
    if (collectBack.result) {
      Modal.success({
        title: "This is an successful message",
        content: "successfully added "
      });
    } else {
      Modal.error({
        title: "This is an error message",
        content: collectBack.error
      });
    }
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  goHistoryBack = () => {
    this.props.history.push("/search/taxonomy");
    // this.props.history.goBack();
  };

  modifyTaxomy = (modifyesult) => {
    console.log("modifysuccess:"+modifyesult);
    let { currentIndex, showDatas } = this.state;
    Modal.success({
      content: "modify successfully",
    });
    this.setState(
      {
        showDetailForm: false,
      },
      () => {
        let { searchParams, searchAllValues } = this.state;

        setTimeout(() => {
          this.taxonomyChild.searchTaxonomyDatas(
            searchParams,
            searchAllValues
          );
        }, 20);
      }
    );
  };
  render() {
    let { searchParams, searchAllValues, searchValues } = this.state;

    return (
      // <div id="SearchResult">
      //   <div className="SearchResult-main">
      <div id="SearchResult">
        <div className="SearchResult-main reference-search-form">
          <div className="head">
            {/* <NavLink to={`/search/reference`}> */}
            {/* {
             this.state.showBackIcon?
             <span
             className="iconfont icon-back"
             onClick={this.goHistoryBack}
           ></span>
           :
           null
           } */}
            {/* </NavLink> */}
            <span className="head-title">Taxonomy Search Result</span>
          </div>

          <div className="option-btn">
            <Button type="gbdb">
              <div
                className="result-search-option"
                onClick={() => this.showOptions()}
              >
                Search option
              </div>

              <div id="searchModel">
                <Modal
                  className="searchResultModal TaxonSearch-all-modal"
                  width="72%"
                  visible={this.state.ResultVisible}
                  footer=""
                  // closable={false}
                  onOk={this.handFormOk}
                  onCancel={this.handleFormCancel}
                >
                  <TaxonomyOption
                    childError={this.childError}
                    searchAllValues={searchAllValues}
                    ref="taxonomy"
                  ></TaxonomyOption>

                  {
                    <div className="referenceSearch-btns">
                      <div>
                        <Button
                          type="gbdb"
                          onClick={() => {
                            this.checkAgain("taxonomy");
                          }}
                        >
                          <p> Search</p>
                        </Button>
                      </div>
                    </div>
                  }
                </Modal>
              </div>
            </Button>
          </div>

          {/* <div className="select-the-reference">
            <Button type="gbdb" onClick={this.selectReference}>
              Select the reference
            </Button>
          </div> */}

          <TaxonomyResult
            searchParams={searchParams}
            searchValues={searchValues}
            onTaxonomy={this.onTaxonomy}
            getDetail={this.getDetail}
            changeCheckSelected={this.changeCheckSelected}
          ></TaxonomyResult>
   
        </div>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailFormOk}
          onCancel={this.detailFormCancel}
        >
          <TaxonomyDetailForm
            detailData={this.state.detailData}
            modifyTaxomy={this.modifyTaxomy}
          ></TaxonomyDetailForm>
        </Modal>
      </div>
    );
  }
}

export default TaxonomySearchResult;
