import React, { Component } from "react";
import Echarts from "components/Echarts/index.js";

import "./index.css";
import "./map/navitator.css";
import "./map/leaflet.css";
// import '../../assets/css/navigator.css';
import Storage from "src/js/storage";
import messageApis from "src/js/apis/message";

import { Modal } from "antd";
import chartData from "./timeEchart.json";
// import chartData from './data1.js';
import paleo_nav from "../../js/map/index";
import InfoModal from './infoModal';
import FormationInfoModal from "./formationModal";
// import L from "leaflet";
import Utils from "src/js/utils";

import userApis from "src/js/apis/user";

import axios from "axios";
let ancientMap = {
  visible: false,

};

// 初始化地图

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showH5Geo: false,
      activeIcon: "",
      mapType: true,
      isShowInfoModal: false,
      isShowFormationInfoModal: false,
      params:null,//地图modal显示数据参数
      newsList: [],
      refCount: 0,
      collTotal: 0,
      points_count: 0,
      fossilTotal: 0,
      collection_count: 0,
      fossilCount: 0,
      sectionCount: 0,
      showLowTips: false,
      ExplorerType: "",
      pcHideEchart: false,
      searchName:"",
      interval:""
    };
  }
  getCount(age_from, age_to) {
    if (age_from && age_to) {
      var sendData = {
        age_from,
        age_to
      }
      axios.get('/api/search/geologyPoints/count', {
        params: sendData
      }).then(res => {
        if (res.data.error === null) {
          var data = res.data.result[0]
          this.setState({
            refCount: data.ref_count,
            pointCount: data.points_count,
            sectionCount: data.sectionCount,
            collTotal: data.collection_count,
            fossilTotal: data.fossilCount,
            collection_count: data.collection_count,
            fossilCount: data.fossilCount,
          })
        }
      }).catch(err => {

      })
    } else {
      // axios.get('/api/search/geologyPoints/count').then(res=>{
      axios.get('/api/assets/map/points/totalCount.json').then(res => {
        if (res.data.error === null) {
          var data = res.data.result[0]
          this.setState({
            refCount: data.ref_count,
            pointCount: data.points_count,
            sectionCount: data.sectionCount,
            collTotal: data.collection_count,
            fossilTotal: data.fossilCount,
            collection_count: data.collection_count,
            fossilCount: data.fossilCount,
          })
        }
      }).catch(err => {
     
      })
    }
  }
  isLownExplorer = () => {
    let Explorer = ""
    let ExplorerVersion = Utils.getExplorer2()

    if (ExplorerVersion.length > 0) {
      Explorer = ExplorerVersion[0]
      let ExplorerType = Explorer.split("/")[0]
      if (ExplorerType == "IE") {
        return { type: "IE", isLowVersionr: true }

      } else if (ExplorerType == "chrome") {
        let subFirstStr = Explorer.substring(Explorer.indexOf('/') + '/'.length, Explorer.length);
        let version = subFirstStr.substring(0, subFirstStr.indexOf('.'));
        if (version <= 75) {
          return { type: "chrome", isLowVersionr: false }
        }

      } else if (ExplorerType == "firefox") {
        let subFirstStr = Explorer.substring(Explorer.indexOf('/') + '/'.length, Explorer.length);
        let version = subFirstStr.substring(0, subFirstStr.indexOf('.'));

        if (version < 70) {
          return { type: "firefox", isLowVersionr: false }
        }
      }
    }
  }

   componentWillReceiveProps(nextProps){
    var searchName=nextProps.location.searchName;
    let updateFlag=false;
    // console.log("this-searchName:"+this.state.searchName);
    // console.log("next-searchName:"+nextProps.location.searchName);
    if(typeof(searchName) == "undefined"){
      searchName="";
    }
    if (searchName != this.state.searchName){
      updateFlag=true;
    }

    this.setState({
      searchName:searchName
    });
    
    // console.log("updateFlag:"+updateFlag);
    if(updateFlag){
      this.componentDidMount();
    }
   // this.componentDidMount();
  }
  
  async componentDidMount() {
    let isLowVersionr = this.isLownExplorer()

    if (isLowVersionr) {
      if(isLowVersionr.type=="IE"){
        this.setState({
          showLowTips: true,
          ExplorerType: isLowVersionr.type
        })

      }
     
    }
    this.getCount.bind(this)()
    let params = {
      pageSize: 3,
    }
    let NewsBack = await messageApis.getNews(params)
    if (NewsBack.result) {
      this.setState({
        newsList: NewsBack.result
      })
    }

    this.initUser()
    this.init();
    window.showInfoModal = this.showInfoModal.bind(this)
    window.setTotal = this.setTotal.bind(this)
    window.clearTotal = this.clearTotal.bind(this)
  }

  init() {
    paleo_nav.init(this.state.searchName,this.state.interval);
    ancientMap = window.ancientMap;
  }
  homeGoAdd = () => {
    let url = "/references/search/reference";
    this.props.history.push(url);
  };
  homeGoFeedback = () => {
    let url = "/feedback";
    this.props.history.push(url);
  };
  homeGoAbout = () => {
    let url = "/about";
    this.props.history.push(url);
  };

  homeGoContact = () => {
    let url = "/contact";
    this.props.history.push(url);
  };
  initUser = async () => {
    let allUserData = {};
    let token = Storage.getItem("token");
    if (token) {
      axios.defaults.headers.token = token || ""; // 全局axios 设置请求头token
      let params = {
        token,
        loginby: 1
      };
      let fossilBack = await userApis.initUser(params);
      if (fossilBack.result) {

        let updateUser = {
          userid: fossilBack.result.id,
          loginby: 1
        };
        Storage.setItem({
          name: "fossilid",
          value: fossilBack.result.id
        });
        let gbdbUserBack = await userApis.gbdbUser(updateUser);
        if (gbdbUserBack.result) {
          let gbdbData = gbdbUserBack.result;
          let baseData = fossilBack.result;
          Object.assign(allUserData, gbdbData, baseData);
          Storage.setItem({
            name: "gbdbid",
            value: gbdbData.id
          });
          Storage.setItem({
            name: "checkerid",
            value: gbdbData.checker
          });
          Storage.setItem({
            name: "checkerName",
            value: gbdbData.checker_name
          });
          Storage.setItem({
            name: "gbdblevel",
            value: gbdbData.level
          });
        } else {
          this.props.history.push("/home");
        }

        this.setState({
          userData: allUserData
        });
      } else {
        if (fossilBack.state === 204) {
          Storage.removeItem("token");
          Storage.removeItem("fossilid");
          Storage.removeItem("gbdbid");
          Storage.removeItem("checkerid");
          Storage.removeItem("checkerName");
          Storage.removeItem("gbdblevel");
          Storage.removeItem("Inputerid");

          return;
        }
        // Modal.error({
        //     title: "This is an error message",
        //     content: fossilBack.error
        // });
        this.props.history.push("/home");
      }
    } else {
      Storage.removeItem("token");
      Storage.removeItem("fossilid");
      Storage.removeItem("gbdbid");
      Storage.removeItem("checkerid");
      Storage.removeItem("checkerName");
      Storage.removeItem("gbdblevel");
      Storage.removeItem("Inputerid");
    }
  }
  initLevel = [{
    itemStyle: {
      color: "rgba(80,178,232,0.3)"
    }
  },
  {
    r0: "5%",
    r: "25%",

    itemStyle: {
      borderWidth: 1,

    },

    label: {
      rotate: "tangential",
      minAngle: 50,
      fontStyle: "normal",
      fontWeight: "normal",
      fontFamily: "rRegular",

    }
  },
  {
    r0: "25%",
    r: "45%",
    label: {
      rotate: "tangential",
      minAngle: 50,

      fontStyle: "normal",
      fontWeight: "normal",
      fontFamily: "rRegular"
    }
  },
  {
    r0: "45%",
    r: "90%",
    label: {
      minAngle: 5,

      fontStyle: "normal",
      fontWeight: "normal",
      fontFamily: "rRegular"
    }
  },

  {
    r0: "90%",
    r: "94%",
    label: {
      minAngle: 5,
      show: false,

      fontStyle: "normal",
      fontWeight: "normal",
      fontFamily: "rRegular"
    }
  },
  {
    r0: "94%",
    r: "97%",
    tooltip: {
      textStyle: {
        color: "rgba(0,0,0,0)"
      }
    },
    label: {
      minAngle: 5,

      show: false,

      fontStyle: "normal",
      fontWeight: "normal",
      fontFamily: "rRegular"
    }
  },
  {
    r0: "97%",
    r: "100%",
    label: {
      position: "outside",
      padding: 1,
      silent: false,
      minAngle: 5,
      show: false,

      fontStyle: "normal",
      fontWeight: "normal",
      fontFamily: "rRegular"
    },
    itemStyle: {
      borderWidth: 1,
      fontStyle: "normal",
      textBorderWidth: 1,
      fontWeight: "normal",
      show: false,


      fontFamily: "rRegular"
    }
  }
  ];
  classify = () => {
    let that = this;
    return {
      option: {
        series: {
          type: "sunburst",
          highlightPolicy: "self",
          data: chartData,
          radius: [0, "100%"],
          sort: null,

          emphasis: {

            itemStyle: {
              shadowColor: "rgba(0, 0, 0, 1)",
              borderColor: "#fff",
              shadowBlur: 2,
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              color: "#fff",
              backgroundColor: '#000',
              textBorderWidth: 2,

              // textBorderColor:"yellow"
            },
            label: {
              formatter: function (param) {
                let timeName = param.name
                if (timeName) {
                  return timeName.substring(0, 6)
                }
              },
              fontSize: 30
            },

          },
          downplay: {
            label: {
              formatter: function (param) {
                let timeName = param.name
                if (timeName) {
                  return timeName.substring(0, 6)
                }
              }
            },
          },
          highlight: {

          },
          label: {
            formatter: function (param) {
              let timeName = param.name
              if (timeName) {
                return timeName.substring(0, 6)
              }
            }
          },
          levels: this.initLevel,
          initialTreeDepth: 3,
          draggable: true,

        },

        tooltip: {
          show: true,
          textStyle: {
            // color: "rgba(0, 0, 0, 0.6)",
            color: "rgba(0, 0, 0, 0.6)",
            fontFamily: "rMedium",
            fontSize: 14
          },
          padding: 0,
          formatter: function (param) {

            return `
            <div  style="background: ${param.color}; padding:10px;">
         ${param.name ? `<p> Name: ${param.name}</p>` : ""}

          ${
              (param.data.age_from !== undefined) && (param.data.age_to !== undefined)
                ? `  
       
          <p> Time: ${param.data.age_from}-${param.data.age_to}</p>`
                : ""
              }
           </div>
           `;
          }
        },
        toolbox: {
          iconStyle :{
            color:"#fff",
           
          },
          textStyle: {
            fontSize: 12,
        },
        right:10,
          feature: {
            myFull: {
              show: true,
              title: "full",
              icon: "path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891",
              onclick: function (e) {
                that.child.onFull();
              }
            },
            myClose: {
              show: true,
              title: "hide",
              icon: "path://M817.472 199.509333a25.557333 25.557333 0 0 0-36.16 0l-86.912 86.890667A561.237333 561.237333 0 0 0 512 256C220.416 256 42.666667 477.653333 42.666667 512c0 23.274667 82.026667 132.501333 225.6 200.533333l-75.797334 75.797334a25.578667 25.578667 0 1 0 36.16 36.16L817.493333 235.669333a25.557333 25.557333 0 0 0 0-36.16zM341.333333 512a170.837333 170.837333 0 0 1 170.666667-170.666667 166.976 166.976 0 0 1 96.554667 30.933334l-30.933334 30.933333A125.013333 125.013333 0 0 0 512 384a128.149333 128.149333 0 0 0-128 128 125.013333 125.013333 0 0 0 19.178667 65.621333l-30.933334 30.933334A166.976 166.976 0 0 1 341.333333 512zM793.536 330.88l-122.026667 122.026667A168.917333 168.917333 0 0 1 682.666667 512a170.837333 170.837333 0 0 1-170.666667 170.666667 168.917333 168.917333 0 0 1-59.093333-11.157334l-78.976 78.954667A560.384 560.384 0 0 0 512 768c291.584 0 469.333333-221.653333 469.333333-256 0-21.162667-67.712-113.408-187.797333-181.12z",
              onclick: function (e) {
                that.closeEchart();
              }
            },
          }
        }
      }
    };
  };
  showInfoModal(data) {
    let {count,formation_id,formation_ids} = data;
    this.setState({params:{formation_id,formation_ids,count}},()=>{
      if (count>1)
      {
        this.setState({
          isShowFormationInfoModal: true
        })
      }
      else{
        this.setState({
          isShowInfoModal: true
        })
      }
    });
   
  }
  setTotal(collection_count, fossilCount) {
 
    this.setState({
      collection_count,
      fossilCount
    })
  }
  clearTotal() {
    var collection_count = this.state.collTotal;
    var fossilCount = this.state.fossilTotal
    this.setState({
      collection_count,
      fossilCount
    })
  }
  cancelShowInfoModal(e,mode) {

    if (mode)
    {
      this.setState({
        isShowFormationInfoModal: false
      })
    }
    else
    {
      this.setState({
        isShowInfoModal: false
      })
    }

  }
  clickEchart = params => {
    if (params.data) {
      // let intervel = Object.assign(params.data, { color: selfColor });
      if (paleo_nav) {
        setTimeout(() => {
          this.setState({
            interval:params.data
          });
          paleo_nav.changeMapsData(params.data,this.state.searchName)
        }, 2)
      }

      /* this.setState({
        mapType: false
      }) */
    }
  };

  onRef = ref => {
    this.child = ref;
  };
  showH5Geo = () => {
    this.setState({
      showH5Geo: true,
      pcHideEchart:false
    });
    this.child.onFull();
  };
  changeMapType = () => {

    if (paleo_nav) paleo_nav.toggleReconstructMap();
    let ancientMap = window.ancientMap.visible;
    if (ancientMap) {
      this.setState({
        mapType: false
      });
    } else {
      this.setState(
        {
          mapType: true
        },
        () => {
        }
      );
    }
  };
  zoomMap = () => {
    if (paleo_nav && this.state.mapType) paleo_nav.zoomIn();
  };
  shrinkMap = () => {
    if (paleo_nav && this.state.mapType) paleo_nav.zoomOut();
  };
  homeIcon = e => {
    this.setState({
      activeIcon: e.target.id
    });
  };
  goNewsList = () => {
    this.props.history.push('/news/list')
  }
  goNewsDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    //  let url = "/news/detail";
    // let queryParam = item;
    // this.props.history.push({ pathname: url, query: {queryParam} });
    ///search写法
    const { id } = item;

    let url = "/news/detail?";

    let newsId = id ? "id=" + id : "";

    let params = `${url}${newsId}`;

    this.props.history.push(params);
  }
  closeExplorerTip = () => {
    this.setState({
      showLowTips: false,
      ExplorerType: ""
    })
  }

  // 
  closeEchart = () => {
    this.setState({
      pcHideEchart: true
    })
  }
  showPcChart = () => {
    this.setState({
      pcHideEchart: false
    })
  }
  render() {
    let { newsList } = this.state
    return (
      <div id="home">
        <div className="home-main">
          {
            this.state.showLowTips && this.state.ExplorerType !== "IE" ?
              <div className="home-explorer-tip">
                Warm prompt:Your browser version is too low, the system recommends that you use a high version of the mainstream browser!!!
          <span className="iconfont icon-close explorer-tip-close" onClick={this.closeExplorerTip}></span>
              </div>
              :
              this.state.showLowTips && this.state.ExplorerType == "IE" ?
                <div className="home-explorer-tip">
                  Warm prompt:IE does not currently support high-performance map viewing, please use a higher version of other browsers to view!!!
        <span className="iconfont icon-close explorer-tip-close" onClick={this.closeExplorerTip}></span>
                </div>
                :
                null
          }

          <div className="home-add-icons" onClick={e => this.homeIcon(e)}>
            <div
              id="homeEarth"
              title="Paleomap"
              className={`home-item-icon home-item-earth ${
                this.state.mapType === false ? "active" : ""
                }`}
              onClick={() => this.changeMapType()}
            >
              {/* <img src={require("assets/images/home/world1.png")} /> */}
            </div>
            {/* <div
              id="homeZoom"
              className={`home-item-icon home-item-zoom ${
                this.state.mapType === false ? "disabled" : ""
                } ${
                this.state.activeIcon === "homeZoom" &&
                  this.state.mapType === true
                  ? "active"
                  : ""
                }`}
              onClick={e => this.zoomMap(e)}
            > */}
              {/* <img src={require("assets/images/home/zoom1.png")} /> */}
            {/* </div> */}
            {/* <div
              id="homeMinus"
              className={`home-item-icon home-item-minus ${
                this.state.mapType === false ? "disabled" : ""
                } ${
                this.state.activeIcon === "homeMinus" &&
                  this.state.mapType === true
                  ? "active"
                  : ""
                }`}
              onClick={() => this.shrinkMap()}
            > */}
              {/* <img src={require("assets/images/home/minus1.png")} /> */}
            {/* </div> */}
            {/* <div
              id="homeFilter"
              className={`home-item-icon home-item-filter ${
                this.state.activeIcon === "homeFilter" ? "active" : ""
                }`}
            > */}
              {/* <img src={require("assets/images/home/filter1.png")} /> */}
            {/* </div> */}
            {/* <div
              id="homeStatistics"
              className={`home-item-icon home-item-statistics ${
                this.state.activeIcon === "homeStatistics" ? "active" : ""
                }`}
            > */}
              {/* <img src={require("assets/images/home/statistics1.png")} /> */}
            {/* </div> */}
            {/* <div
              id="homeFinished"
              className={`home-item-icon home-item-finished ${
                this.state.activeIcon === "homeFinished" ? "active" : ""
                }`}
            > */}
              {/* <img src={require("assets/images/home/finished1.png")} /> */}
            {/* </div> */}
            {/* <div
              id="homeAdd"
              className={`home-item-icon home-item-add ${
                this.state.activeIcon === "homeAdd" ? "active" : ""
                }`}
              title="add data"
              onClick={() => {
                this.homeGoAdd();
              }}
            > */}
              {/* <img src={require("assets/images/home/add1.png")} /> */}
            {/* </div> */}
            {/* <div
              id="homeQuestion"
              title="feedback"
              onClick={() => {
                this.homeGoFeedback();
              }}
              className={`home-item-icon home-item-question  ${
                this.state.activeIcon === "homeQuestion" ? "active" : ""
                }`}
            > */}
              {/* <img src={require("assets/images/home/question1.png")} /> */}
            {/* </div> */}
            {/* <div
              id="about"
              title="About"
              onClick={() => {
                this.homeGoAbout();
              }}
              className={`home-item-icon home-item-question  ${
                this.state.activeIcon === "about" ? "active" : ""
                }`}
            >
            </div> */}

            <div
              id="contact"
              title="Contact"
              onClick={() => {
                this.homeGoContact();
              }}
              className={`home-item-icon home-item-contact  ${
                this.state.activeIcon === "contact" ? "active" : ""
                }`}
            >
              {/* <img src={require("assets/images/home/question1.png")} /> */}
            </div>
          </div>
          <div className="home-earth-main">
            <div className="home-earth-content">
              {/* 高德地图 */}
              <div id="map"></div>
              <div id="svgMap"></div>
              {/* 古地理地图 */}
              <div id="reconstructMap">
                <div id="reconstructMapRefContainerContainer">
                  {/* <div id="reconstructMapRefContainer">
                    <div id="reconstructMapReference">
                      <div id="reconstructMapStats">
                        <a
                          id="gPlatesLogoLink"
                          data-toggle="modal"
                          href="#gPlatesBox"
                        >
                        </a>
                        <h5 id="interval"></h5>
                        <p id="age"></p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              {/*<img
                src={require("assets/images/home/earth.png")}
              />*/}
            </div>
          </div>
          {
            this.state.pcHideEchart ?
              <div className="home-show-echart" onClick={this.showPcChart}>Show Geological Time Scale</div>
              :
              null

          }
          {/* <div className="home-chart" {this.state.pcHideEchart ? "show-homepc-echart" : "hide-homepc-echart"}> */}
          <div 
          className={`home-chart ${!this.state.pcHideEchart ? "show-homepc-echart" : "hide-homepc-echart"}`  }
          >
          <Echarts
            
            onRef={this.onRef}
            id="classify"
            data={this.classify()}
            clickEchart={params => this.clickEchart(params)}
          // chartSize={{ height: "320px", width: "320px" }}
          ></Echarts>
          </div>
         
         
          <div
            className={`h5-show-geological ${
              this.state.showH5Geo ? "h5-show-geological" : ""
              }`}
            onClick={() => this.showH5Geo()}
          >
            Show Geological Time Scale
          </div>

          <div className="home-earth-tip">
            <div className="home-tip-img">
              <img src={require("assets/images/home/tips.png")} alt="" />
            </div>
            <div className="home-tip-content">
              <p>
                <span className="iconfont icon-dot"></span>
                <span>Total  Reference: {this.state.refCount}</span>
              </p>
              <p>
                <span className="iconfont icon-dot"></span>
                <span>Total  Sections: {this.state.sectionCount}</span>
              </p>
              <p>
                <span className="iconfont icon-dot"></span>
                <span>Total Formations: {this.state.pointCount}</span>
              </p>
              <p>
                <span className="iconfont icon-dot"></span>
                <span>Total Collections: {this.state.collection_count}</span>
              </p>
              <p>
                <span className="iconfont icon-dot"></span>
                <span>Total Occurrences: {this.state.fossilCount}</span>
              </p>
            </div>
          </div>

          <div className="home-left-tip">
            <div className="home-left-parts">
              

              <p className="home-left-items-p">The Geobiodiversity Database (GBDB - www.geobiodiversity.com), an integrated system for the management and analysis of stratigraphic and paleontological information, was started in 2006 and became available online in 2007.</p>

            </div>
          </div>
        </div>
        <div id="loading">
          <div className="loader">
            <div className="loader-inner ball-spin-fade-loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <span>Loading...</span>
          </div>
        </div>
        {
          this.state.isShowInfoModal ? (
            <InfoModal
              formation_id={this.state.params.formation_id}
              visible={this.state.isShowInfoModal}
              cancelShowInfoModal={this.cancelShowInfoModal.bind(this)}
            ></InfoModal>) : null
        }
        {
          this.state.isShowFormationInfoModal ? (
            <FormationInfoModal
              params={this.state.params}
              visible={this.state.isShowFormationInfoModal}
              cancelShowInfoModal={this.cancelShowInfoModal.bind(this,1,1)}
            ></FormationInfoModal>) : null
        }

      </div>
    );
  }
}
