import React from 'react'
import {Modal,Form,Button,Input,DatePicker} from 'antd'
import Taxonomy from './taxonomy'
import Revised from './taxonomyrevisedModal'
import Taxarelation from './taxarelation'



class AntdForm extends React.Component{
    constructor(props){
        super(props)
        this.state={
            isTaxonomy:true,
            isRevised:false,
            isTaxarelation:false
           
        }
    }
    
  
    render(){
        const {modalData,type}=this.props
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 },
            },
          };
     
        return (
            <div>
                <Modal 
                className='AModal taxonomyModal'
                visible={this.props.visible}
                onCancel={this.props.cancelShowInfo}
                footer={null}
                >
                    
                    {type===8?(
                    <Taxonomy
                        modalData={this.props.modalData} 
                        cancelShowInfo={this.props.cancelShowInfo}
                        refreshList={this.props.refreshList}
                     ></Taxonomy>):null}

                    {type===9?(
                    <Revised
                        modalData={this.props.modalData}
                        cancelShowInfo={this.props.cancelShowInfo}
                        refreshList={this.props.refreshList}
                     ></Revised>):null}

                    {type===10?(<Taxarelation
                        modalData={this.props.modalData}
                        cancelShowInfo={this.props.cancelShowInfo}
                        refreshList={this.props.refreshList}
                     ></Taxarelation>):null}
                  
                </Modal>
        </div>
        )
      
    }
}
const AUserInfoModal = Form.create()(AntdForm);
export default AUserInfoModal