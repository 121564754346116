import React, { Component } from "react";
import "./index.css";

import { Menu, Button, Modal, message } from "antd";
import centerApis from "src/js/apis/center";
import Apis from "src/js/apis/api";
import InfiniteScroll from "react-infinite-scroller";
import { NavLink } from "react-router-dom";

import userApis from "src/js/apis/user";
import Storage from "src/js/storage";
import moment from "moment";
import Utils from "src/js/utils";
import "./index.css";

import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";
import CollectionDetailForm from "commons/AllDetails/Collection/index.js";
import GeochemicalDetailForm from "commons/AllDetails/Geochemical/index.js";
import TaxonomyDetailForm from "commons/AllDetails/Taxonomy/index.js";
import OceanicDetailForm from "commons/AllDetails/Oceanic/index.js";
import FormationDetailForm from "commons/AllDetails/Section/Formation/index.js";
import UnitDetailForm from "commons/AllDetails/Section/Unit/index.js";
import "src/js/global.js";
class Center extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey: "reference",
      userData: {},

      detailData: {},
      taxType: "Original",
      showDetailForm: false,

      thumbnail: process.env.PUBLIC_URL + "/imgs/avator.png",

      //  0未审核 1审核中 2已审核 3审核失败"
      authState: "",
      ResAccounts: {},
      // 滚动
      showDatas: [],
      dataLoading: false,
      dataHasMore: true,
      currentPage: 1,
      dataTotal: 0,
      pageSize: 16,

      //
      sectionType: "baseinfo",
      // 回次 roundtrip
      // 岩心图像 coreimage
      // 岩性剖面 lithology_section
      // 岩性描述 lithology_description

      oceanicType: "roundtrip",
    };
  }

  getCenterData = async (searchParams, callback) => {
    let allParams = {};
    let sortParams = {
      sort: { name: "updatedAt", type: "1" },
    };
    Object.assign(allParams, searchParams, sortParams);
    let centerResBack = await centerApis.searchAssets(allParams);

    if (centerResBack.error) {
      const modal = Modal.error({
        title: "This is an error message",
        content: centerResBack.error,
      });
      setTimeout(() => {
        modal.destroy();
      }, 5000);
    } else {
      callback(centerResBack);
    }
  };
  initCenterRes = async (oceanictype) => {
    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }
    let { authState, currentKey, pageSize, currentPage } = this.state;
    let token = Storage.getItem("token");
    if (token) {
      let lastParams = {};
      let dataparams = {
        assetstype: oceanictype ? oceanictype : Utils.folderTypeFun(this.state.currentKey),
        enterer: Storage.getItem("gbdbid"),
      };
      let pageParams = {
        pageSize,
        page: currentPage,
      };
      let authParams = {};

      if (authState !== "") {
        authParams = { authState: Number(authState) };
      }
      let sortParams = {
        sort: { name: "updatedAt", type: "1" },
      };
      Object.assign(lastParams, dataparams, pageParams, authParams, sortParams);

      let centerResBack = await centerApis.searchAssets(lastParams);
      if (centerResBack.result) {
        this.setState({
          showDatas: centerResBack.result,
          dataTotal: centerResBack.total,
          dataparams,
        });
        let accountParams = {
          enterer: Storage.getItem("gbdbid"),
        };
        if (currentKey !== "reference") {
          let assetstype = oceanictype ? oceanictype : Utils.folderTypeFun(this.state.currentKey);
          if (assetstype !== 1) {
            Object.assign(accountParams, { assetstype });
          }
          let assetsCountBack = await centerApis.assetsCount(accountParams);
          if (assetsCountBack.result) {
            this.setState({
              ResAccounts: assetsCountBack.result,
            });
          } else {
            Modal.error({
              title: "This is an error message",
              content: assetsCountBack.error,
            });
          }
        }
      } else {
        Modal.error({
          title: "This is an error message",
          content: centerResBack.error,
        });
      }
    }
  };
  isGbdbUser = () => {
    let token = Storage.getItem("token");

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page",
        });
        this.props.history.push("/home");
      }
    }
  };
  async componentDidMount() {
    // this.isGbdbUser();
    let allUserData = {};
    let token = Storage.getItem("token");
    let params = {
      token,
      loginby: 1,
    };
    let fossilBack = await userApis.initUser(params);

    if (fossilBack.result) {
      let updateUser = {
        userid: fossilBack.result.id,
        loginby: 1,
      };

      let gbdbUserBack = await userApis.gbdbUser(updateUser);

      if (gbdbUserBack.result) {
        let gbdbData = gbdbUserBack.result;

        let baseData = fossilBack.result;
        Object.assign(allUserData, gbdbData, baseData);
        // 设置默认图片
      } else {
        this.props.history.push("/home");
      }

      this.setState(
        {
          userData: allUserData,
          thumbnail: "/api" + fossilBack.result.thumbnail,
        },
        () => {
          this.initCenterRes();
        }
      );
    } else {
      // if (fossilBack.state === 204) {
      //   Storage.removeItem("token");
      //   Storage.removeItem("fossilid");
      //   Storage.removeItem("gbdbid");
      //   Storage.removeItem("checkerid");
      //   Storage.removeItem("checkerName");
      //   Storage.removeItem("gbdblevel");
      //   Storage.removeItem("Inputerid");
      //   return;
      // }
      Storage.removeItem("token");
      Storage.removeItem("fossilid");
      Storage.removeItem("gbdbid");
      Storage.removeItem("checkerid");
      Storage.removeItem("checkerName");
      Storage.removeItem("gbdblevel");
      Storage.removeItem("Inputerid");
      Modal.error({
        title: "This is an error message",
        content: fossilBack.error,
      });
      this.props.history.push("/home");
    }
  }
  changeResType = async (e) => {
    this.setState(
      {
        currentKey: e.key,
        oceanicType: "roundtrip",
        sectionType: "baseinfo",
        authState: "",
        currentPage: 1,
        ResAccounts: {},
        dataTotal: 0,
      },
      async () => {
        this.initCenterRes();
      }
    );
  };
  changeResState = (e) => {
    let authState = e.target.getAttribute("name");
    this.setState(
      {
        authState,
        currentPage: 1,
      },
      () => {
        let { sectionType } = this.state;
        if (sectionType == "section_formation" || sectionType == "section_unit") {
          sectionType = Utils.folderTypeFun(sectionType);
          this.initCenterRes(sectionType);
        } else {
          this.initCenterRes();
        }
      }
    );
  };
  handleInfiniteOnLoad = () => {
    let { showDatas, dataTotal } = this.state;
    this.setState({
      dataLoading: true,
    });

    if (showDatas.length >= dataTotal) {
      message.warning("Infinite List loaded all");
      this.setState({
        dataHasMore: false,
        dataLoading: false,
      });
      return;
    }
    let { pageSize, currentPage, dataparams } = this.state;
    this.setState(
      {
        currentPage: currentPage + 1,
      },
      () => {
        let searchParams = {
          pageSize,
          page: this.state.currentPage,
          ...dataparams,
        };
        this.getCenterData(searchParams, (res) => {
          showDatas = showDatas.concat(res.result);
          this.setState({
            showDatas,
            dataLoading: false,
          });
        });
      }
    );
  };

  getDetail = (itemValue, index) => {
    this.setState({
      showDetailForm: true,
      detailData: itemValue,
      currentIndex: index,
    });
  };
  changeVerifyNumber = () => {
    let { ResAccounts, authState } = this.state;

    if (parseInt(this.state.detailData.authState) === 2) {
      --ResAccounts.Unfinished;
      ++ResAccounts.Verifing;
    }
    if (parseInt(this.state.detailData.authState) === 1) {
      --ResAccounts.Verified;
      ++ResAccounts.Verifing;
    }

    this.setState(
      {
        ResAccounts,
      },
      () => {}
    );
  };

  changeDataList = () => {
    let { authState, showDatas, currentIndex } = this.state;
    if (parseInt(authState) === 2 || parseInt(authState) === 1) {
      showDatas.splice(currentIndex, 1);
    }

    this.setState(
      {
        showDatas,
      },
      () => {}
    );
  };
  // reference修改成功
  modifyReference = (modifyesult) => {
    let { currentIndex, showDatas } = this.state;
    Modal.success({
      content: "modify successfully",
    });
    this.setState(
      {
        showDetailForm: false,
      },
      () => {
        showDatas[currentIndex] = modifyesult;
        this.setState({
          showDatas,
        });
        this.changeVerifyNumber();
        this.changeDataList();
      }
    );
  };
  modifyTaxomy = (modifyesult) => {
    let { currentIndex, showDatas } = this.state;
    Modal.success({
      content: "modify successfully",
    });
    this.setState(
      {
        showDetailForm: false,
      },
      () => {
        showDatas[currentIndex] = modifyesult;
        this.setState({
          showDatas,
        });
        this.changeVerifyNumber();
        this.changeDataList();
      }
    );
  };
  modifyOceanic = (modifyesult) => {
    let { currentIndex, showDatas } = this.state;
    Modal.success({
      content: "modify successfully",
    });
    this.setState(
      {
        showDetailForm: false,
      },
      () => {
        showDatas[currentIndex] = modifyesult;
        this.setState({
          showDatas,
        });
        this.changeVerifyNumber();
        this.changeDataList();
      }
    );
  };
  modifyCollection = (modifyesult) => {
    let { currentIndex, showDatas } = this.state;
    Modal.success({
      content: "modify successfully",
    });
    this.setState(
      {
        showDetailForm: false,
      },
      () => {
        showDatas[currentIndex] = modifyesult;
        this.setState({
          showDatas,
        });
        this.changeVerifyNumber();
        this.changeDataList();
      }
    );
  };
  modifyGeochemical = (modifyesult) => {
    let { currentIndex, showDatas } = this.state;
    Modal.success({
      content: "modify successfully",
    });
    this.setState(
      {
        showDetailForm: false,
      },
      () => {
        showDatas[currentIndex] = modifyesult;
        this.setState({
          showDatas,
        });
        this.changeVerifyNumber();
        this.changeDataList();
      }
    );
  };
  modifyBase = (modifyesult) => {
    let { currentIndex, showDatas } = this.state;
    Modal.success({
      content: "modify successfully",
    });

    this.setState(
      {
        showDetailForm: false,
      },
      () => {
        showDatas[currentIndex] = modifyesult;
        this.setState({
          showDatas,
        });
        this.changeVerifyNumber();
        this.changeDataList();
      }
    );
  };
  modifyGeography = (modifyesult) => {
    Modal.success({
      content: "modify successfully",
    });
    this.setState({
      showDetailForm: false,
      sectionType: "baseinfo",
    });
    this.changeVerifyNumber();
    this.changeDataList();
  };
  modifyFormation = (modifyesult) => {
    let { currentIndex, showDatas } = this.state;
    Modal.success({
      content: "modify successfully",
    });

    this.setState(
      {
        showDetailForm: false,
      },
      () => {
        showDatas[currentIndex] = modifyesult;
        this.setState({
          showDatas,
        });
        this.changeVerifyNumber();
        this.changeDataList();
      }
    );
  };
  modifyunit = (modifyesult) => {
    let { currentIndex, showDatas } = this.state;
    Modal.success({
      content: "modify successfully",
    });

    this.setState(
      {
        showDetailForm: false,
      },
      () => {
        showDatas[currentIndex] = modifyesult;
        this.setState({
          showDatas,
        });
        this.changeVerifyNumber();
        this.changeDataList();
      }
    );
  };
  detailFormOk = () => {
    this.setState(
      {
        showDetailForm: false,
        // sectionType: "baseinfo"
      },
      () => {
        if (this.state.sectionType == "geology") {
          this.setState({
            sectionType: "baseinfo",
          });
        }
      }
    );
  };
  detailFormCancel = () => {
    this.setState(
      {
        showDetailForm: false,
        // sectionType: "baseinfo"
      },
      () => {
        if (this.state.sectionType == "geology") {
          this.setState({
            sectionType: "baseinfo",
          });
        }
      }
    );
  };

  // section
  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showDetailForm: true,
      sectionType: "geology",
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showDetailForm: true,
      sectionType: "baseinfo",
    });
  };
  changeOceanicType = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let oceanicType = e.target.getAttribute("name");
    this.setState(
      {
        oceanicType,
        currentPage: 1,
        authState: "",
        ResAccounts: {},
        dataTotal: 0,
      },
      () => {
        let oceanictype = Utils.folderTypeFun(this.state.oceanicType);
        this.initCenterRes(oceanictype);
      }
    );
  };
  changeSectionType = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let sectionType = e.target.getAttribute("name");
    this.setState(
      {
        sectionType,
        currentPage: 1,
        authState: "",
        ResAccounts: {},
        dataTotal: 0,
      },
      () => {
        let sectionType = Utils.folderTypeFun(this.state.sectionType);

        this.initCenterRes(sectionType);
      }
    );
  };
  // 删除reference操作
  deleteRefChangeData = (item) => {
    let { dataTotal } = this.state;
    dataTotal -= 1;
    this.setState(
      {
        dataTotal,
      },
      () => {
        let that = this;
        let { currentPage, dataparams, pageSize, showDatas, dataTotal } = that.state;

        let total = dataTotal;
        if (showDatas.length < total) {
          let pageParams = {
            pageSize: 1,
            page: currentPage * pageSize,
            ...dataparams,
          };
          that.getCenterData(pageParams, (res) => {
            showDatas = showDatas.concat(res.result);
            that.setState({
              showDatas,
              loading: false,
            });
          });
        }
      }
    );
  };
  deleteChangeData = (item) => {
    let { ResAccounts } = this.state;
    if (parseInt(item.authState) === 0) {
      ResAccounts.Verifing -= 1;
    } else if (parseInt(item.authState) === 1) {
      ResAccounts.Verified -= 1;
    } else if (parseInt(item.authState) === 2) {
      ResAccounts.Unfinished -= 1;
    }
    this.setState(
      {
        ResAccounts,
      },
      () => {
        let that = this;
        let { currentPage, dataparams, pageSize, showDatas, ResAccounts } = that.state;

        let total = Number(ResAccounts.Verifing) + Number(ResAccounts.Verified) + Number(ResAccounts.Unfinished);

        if (showDatas.length < total) {
          let pageParams = {
            pageSize: 1,
            page: currentPage * pageSize,
            ...dataparams,
          };
          that.getCenterData(pageParams, (res) => {
            showDatas = showDatas.concat(res.result);
            that.setState({
              showDatas,
              loading: false,
            });
          });
        }
      }
    );
  };
  deleteCenterRef = async (item, index, e) => {
    let that = this;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    Modal.confirm({
      title: "Are you sure delete the resource?",
      content: "Please choose carefully",
      okText: "Yes",
      okType: "danger",

      cancelText: "No",
      async onOk() {
        if (item.auth_record == 1) {
          Modal.error({
            title: "Cannot delete",
            content: "This resource has been checked once and cannot be deleted",
          });
          return;
        }
        let params = {
          id: item.ref_id,
        };
        let deleteRes = await Apis.deleteReference(params);
        if (deleteRes.result) {
          let { showDatas } = that.state;
          showDatas.splice(index, 1);
          that.setState(
            {
              currentIndex: index,
              showDatas,
            },
            () => {
              that.deleteRefChangeData(item);

              Modal.success({
                content: "The resource has been deleted",
              });
            }
          );
        } else {
          if (deleteRes.error) {
            if (deleteRes.state === 300) {
              Modal.error({
                title: deleteRes.error.msg,
                content: `The resource contains ${deleteRes.error.result.total} subresources under it`,
              });
            }
          }
        }
      },
      onCancel() {},
    });
  };
  deleteCenterSection = async (item, index, e) => {
    let that = this;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    Modal.confirm({
      title: "Are you sure delete the resource?",
      content: "Please choose carefully",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        if (item.auth_record == 1) {
          Modal.error({
            title: "Cannot delete",
            content: "This resource has been checked once and cannot be deleted",
          });
          return;
        }
        let params = {
          id: item.section_id,
        };
        let deleteRes = await Apis.deleteBasicById(params);
        if (deleteRes.result) {
          let { showDatas } = that.state;
          showDatas.splice(index, 1);
          that.setState(
            {
              currentIndex: index,
              showDatas,
            },
            () => {
              that.deleteChangeData(item);
              Modal.success({
                content: "The resource has been deleted",
              });
            }
          );
        } else {
          if (deleteRes.error) {
            if (deleteRes.state === 300) {
              Modal.error({
                title: deleteRes.error.msg,
                content: `The resource contains ${deleteRes.error.result.total} subresources under it`,
              });
            }
          }
        }
      },
      onCancel() {},
    });
  };
  deleteCenterFormation = async (item, index, e) => {
    let that = this;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    Modal.confirm({
      title: "Are you sure delete the resource?",
      content: "Please choose carefully",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        if (item.auth_record == 1) {
          Modal.error({
            title: "Cannot delete",
            content: "This resource has been checked once and cannot be deleted",
          });
          return;
        }
        let params = {
          id: item.formation_id,
        };
        let deleteRes = await Apis.deleteFormationById(params);
        if (deleteRes.result) {
          let { showDatas } = that.state;
          showDatas.splice(index, 1);
          that.setState(
            {
              currentIndex: index,
              showDatas,
            },
            () => {
              that.deleteChangeData(item);
              Modal.success({
                content: "The resource has been deleted",
              });
            }
          );
        } else {
          if (deleteRes.error) {
            if (deleteRes.state === 300) {
              Modal.error({
                title: deleteRes.error.msg,
                content: `The resource contains ${deleteRes.error.result.total} subresources under it`,
              });
            }
          }
        }
      },
      onCancel() {},
    });
  };
  deleteCenterUnit = async (item, index, e) => {
    let that = this;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    Modal.confirm({
      title: "Are you sure delete the resource?",
      content: "Please choose carefully",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        if (item.auth_record == 1) {
          Modal.error({
            title: "Cannot delete",
            content: "This resource has been checked once and cannot be deleted",
          });
          return;
        }
        let params = {
          id: item.unit_id,
        };
        let deleteRes = await Apis.deleteUnitById(params);
        if (deleteRes.result) {
          let { showDatas } = that.state;
          showDatas.splice(index, 1);
          that.setState(
            {
              currentIndex: index,
              showDatas,
            },
            () => {
              that.deleteChangeData(item);
              Modal.success({
                content: "The resource has been deleted",
              });
            }
          );
        } else {
          if (deleteRes.error) {
            if (deleteRes.state === 300) {
              Modal.error({
                title: deleteRes.error.msg,
                content: `The resource contains ${deleteRes.error.result.total} subresources under it`,
              });
            }
          }
        }
      },
      onCancel() {},
    });
  };
  deleteCenterColl = async (item, index, e) => {
    let that = this;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    Modal.confirm({
      title: "Are you sure delete the resource?",
      content: "Please choose carefully",
      okText: "Yes",
      okType: "danger",

      cancelText: "No",
      async onOk() {
        if (item.auth_record == 1) {
          Modal.error({
            title: "Cannot delete",
            content: "This resource has been checked once and cannot be deleted",
          });
          return;
        }
        let params = {
          id: item.coll_id,
        };
        let deleteRes = await Apis.deleteCollectionById(params);
        if (deleteRes.result) {
          let { showDatas } = that.state;
          showDatas.splice(index, 1);
          that.setState(
            {
              currentIndex: index,
              showDatas,
            },
            () => {
              that.deleteChangeData(item);
              Modal.success({
                content: "The resource has been deleted",
              });
            }
          );
        } else {
          if (deleteRes.error) {
            if (deleteRes.state === 300) {
              Modal.error({
                title: deleteRes.error.msg,
                content: `The resource contains ${deleteRes.error.result.total} subresources under it`,
              });
            }
          }
        }
      },
      onCancel() {},
    });
  };
  deleteCenterFossil = async (item, index, e) => {
    let that = this;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    Modal.confirm({
      title: "Are you sure delete the resource?",
      content: "Please choose carefully",
      okText: "Yes",
      okType: "danger",

      cancelText: "No",
      async onOk() {
        if (item.auth_record == 1) {
          Modal.error({
            title: "Cannot delete",
            content: "This resource has been checked once and cannot be deleted",
          });
          return;
        }
        let params = {
          id: item.id,
        };
        let deleteRes = await Apis.deleteFossilById(params);
        if (deleteRes.result) {
          let { showDatas } = that.state;
          showDatas.splice(index, 1);
          that.setState(
            {
              currentIndex: index,
              showDatas,
            },
            () => {
              that.deleteChangeData(item);
              Modal.success({
                content: "The resource has been deleted",
              });
            }
          );
        } else {
          if (deleteRes.error) {
            if (deleteRes.state === 300) {
              Modal.error({
                title: deleteRes.error.msg,
                content: `The resource contains ${deleteRes.error.result.total} subresources under it`,
              });
            }
          }
        }
      },
      onCancel() {},
    });
  };
  deleteCenterGeochemical = async (item, index, e) => {
    let that = this;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    Modal.confirm({
      title: "please choose",
      content: "Whether s deletes all subitems?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        if (item.auth_record == 1) {
          Modal.error({
            title: "Cannot delete",
            content: "This resource has been checked once and cannot be deleted",
          });
          return;
        }
        let params = {
          id: item.id,
        };
        let deleteRes = await Apis.deleteGeochemicalById(params);

        if (deleteRes.result) {
          let { showDatas } = that.state;
          showDatas.splice(index, 1);
          that.setState(
            {
              currentIndex: index,
              showDatas,
            },
            () => {
              that.deleteChangeData(item);
              Modal.success({
                content: "The resource has been deleted",
              });
            }
          );
        } else {
          Modal.error({
            content: deleteRes.error,
          });
        }
      },
      onCancel() {},
    });
  };
  deleteCenterTaxon = async (item, index, e) => {
    let that = this;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    Modal.confirm({
      title: "Are you sure delete the resource?",
      content: "Please choose carefully",
      okText: "Yes",
      okType: "danger",

      cancelText: "No",
      async onOk() {
        if (item.auth_record == 1) {
          Modal.error({
            title: "Cannot delete",
            content: "This resource has been checked once and cannot be deleted",
          });
          return;
        }
        let params = {
          id: item.id,
        };
        let deleteRes = await Apis.deleteTaxon(params);
        if (deleteRes.result) {
          let { showDatas } = that.state;
          showDatas.splice(index, 1);
          that.setState(
            {
              currentIndex: index,
              showDatas,
            },
            () => {
              that.deleteChangeData(item);
              Modal.success({
                content: "The resource has been deleted",
              });
            }
          );
        } else {
          if (deleteRes.state === 300) {
            Modal.error({
              title: deleteRes.error.msg,
              content: `The resource contains ${deleteRes.error.result.total} subresources under it`,
            });
          }
        }
      },
      onCancel() {},
    });
  };
  deleteCenterOceanic = async (item, index, e) => {
    let that = this;
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    Modal.confirm({
      title: "Are you sure delete the resource?",
      content: "Please choose carefully",
      okText: "Yes",
      okType: "danger",

      cancelText: "No",
      async onOk() {
        let params = {
          id: item.id,
        };
        let deleteRes;
        let { oceanicType } = that.state;
        if (oceanicType === "roundtrip") {
          deleteRes = await Apis.deleteRoundtripById(params);
        } else if (oceanicType === "coreimage") {
          deleteRes = await Apis.deleteCoreimageById(params);
        } else if (oceanicType === "lithology_section") {
          deleteRes = await Apis.deleteLithologySectionById(params);
        } else if (oceanicType === "lithology_description") {
          deleteRes = await Apis.deleteLithologyDescById(params);
        }

        if (deleteRes.result) {
          let { showDatas } = that.state;
          showDatas.splice(index, 1);
          that.setState(
            {
              currentIndex: index,
              showDatas,
            },
            () => {
              that.deleteRefChangeData(item);
              Modal.success({
                content: "The resource has been deleted",
              });
            }
          );
        } else {
          Modal.error({
            content: deleteRes.error,
          });
        }
      },
      onCancel() {},
    });
  };

  render() {
    let { userData, currentKey, thumbnail, showDatas, dataTotal, ResAccounts, sectionType } = this.state;
    let { degree, institute, graduate, sore } = userData;
    if (sore !== "" && sore > 10000) {
      sore = Math.round((sore / 10000) * 100) / 100 + "万";
    }
    return (
      <div id="center">
        <div className="center-user">
          <div className="center-user-detail">
            <div className="center-user-avator">
              <img src={thumbnail} alt="" />
            </div>
            <div className="center-user-content">
              <div className="center-user-base">
                <span className="center-user-name">{userData.username ? userData.username : ""}</span>
                <span className="center-user-title">
                  {userData.userType === 1 && userData.authState === 3
                    ? "Data compiler"
                    : userData.userType === 1 && userData.authState === 5
                    ? "Data compiler"
                    : userData.userType === 1 && userData.authState === 2
                    ? "Data compiler"
                    : userData.userType === 2 && userData.authState === 3
                    ? "Expert"
                    : userData.userType === 2 && userData.authState === 4
                    ? "Expert"
                    : userData.userType === 2 && userData.authState === 1
                    ? "Expert"
                    : ""}
                </span>
              </div>
              {degree == "" && graduate == "" ? null : (
                <div className="center-profile-detail">
                  {degree ? <div>Degree: {degree}</div> : null}
                  {/* {institute ? <span>{"/"+institute}</span> : null} */}
                  {graduate ? <div title={graduate}>Graduate from: {graduate}</div> : null}
                </div>
              )}

              <div className="h5-point">
                <span className="center-points-number">{sore !== "" ? sore : 0}</span>
                <span className="center-points-unit">Points</span>
              </div>
            </div>
          </div>
          <div className="center-points">
            <span className="center-points-number">{sore !== "" ? sore : 0}</span>
            <span className="center-points-unit">Points</span>
          </div>
        </div>
        <div className="center-user-resource">
          <div className="center-user-menu" id="referenceMenu">
            <Menu defaultSelectedKeys={currentKey} mode="horizontal" onClick={(e) => this.changeResType(e)} className="reference-search-menu reference-menu" selectedKeys={currentKey}>
              <Menu.Item key="reference">
                <div>
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    Reference
                  </a>
                </div>
              </Menu.Item>
              <Menu.Item key="section">
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Section
                </a>
              </Menu.Item>

              <Menu.Item key="collection">
                <div>
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    Collection
                  </a>
                </div>
              </Menu.Item>
              <Menu.Item key="fossil">
                <div>
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    Fossil
                  </a>
                </div>
              </Menu.Item>
              <Menu.Item key="geochemical">
                <div>
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    Geochemical
                  </a>
                </div>
              </Menu.Item>

              <Menu.Item key="taxonomy">
                <div>
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    Taxonomy
                  </a>
                </div>
              </Menu.Item>
              <Menu.Item key="oceanic">
                <div>
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    Oceanic data
                  </a>
                </div>
              </Menu.Item>
            </Menu>
          </div>
          <div className="center-resource-content">
            {currentKey === "oceanic" ? (
              <div className="center-resource-classify center-oceanic-type">
                <span onClick={(e) => this.changeOceanicType(e)} name="roundtrip" className={`${this.state.oceanicType === "roundtrip" ? "oceanic-active" : ""}`}>
                  回次
                </span>

                <span onClick={(e) => this.changeOceanicType(e)} name="coreimage" className={`${this.state.oceanicType === "coreimage" ? "oceanic-active" : ""}`}>
                  岩心图像
                </span>
                <span onClick={(e) => this.changeOceanicType(e)} name="lithology_section" className={`${this.state.oceanicType === "lithology_section" ? "oceanic-active" : ""}`}>
                  岩心剖面
                </span>
                <span onClick={(e) => this.changeOceanicType(e)} name="lithology_description" className={`${this.state.oceanicType === "lithology_description" ? "oceanic-active" : ""}`}>
                  岩性描述
                </span>
              </div>
            ) : currentKey === "section" ? (
              <div className="center-resource-classify center-oceanic-type">
                <span onClick={(e) => this.changeSectionType(e)} name="section_formation" className={`${this.state.sectionType === "section_formation" ? "oceanic-active" : ""}`}>
                  Formation
                </span>

                <span onClick={(e) => this.changeSectionType(e)} name="section_unit" className={`${this.state.sectionType === "section_unit" ? "oceanic-active" : ""}`}>
                  Unit
                </span>
              </div>
            ) : null}
            <div className="center-resource-type clearfix">
              {currentKey === "reference" || currentKey === "oceanic" ? (
                <div className="center-resource-classify">
                  <span name="" className={`${this.state.authState === "" ? "currentAudit-active" : ""}`}>
                    All ( <span>{dataTotal}</span> )
                  </span>
                </div>
              ) : (
                <div className="center-resource-classify" onClick={(e) => this.changeResState(e)}>
                  <span name="" className={`${this.state.authState === "" ? "currentAudit-active" : ""}`}>
                    All (
                    {JSON.stringify(ResAccounts) === "{}" ? (
                      <span name="">0</span>
                    ) : (
                      <span name="">{Number(ResAccounts.Verifing) + Number(ResAccounts.Verified) + Number(ResAccounts.Unfinished)}</span>
                    )}
                    )
                  </span>

                  <span name="0" className={`${this.state.authState === "0" ? "currentAudit-active" : ""}`}>
                    Verifing (<span name="0">{ResAccounts && ResAccounts.Verifing ? ResAccounts.Verifing : 0}</span>)
                  </span>
                  <span name="1" className={`${this.state.authState === "1" ? "currentAudit-active" : ""}`}>
                    Verified (<span name="1">{ResAccounts && ResAccounts.Verified ? ResAccounts.Verified : 0}</span>)
                  </span>

                  <span name="2" className={`${this.state.authState === "2" ? "currentAudit-active" : ""}`}>
                    Unfinished (<span name="2">{ResAccounts && ResAccounts.Unfinished ? ResAccounts.Unfinished : 0}</span>)
                  </span>
                </div>
              )}
              <div className="center-type-add">
                <Button type="gbdb" className="center-pc-addbtn">
                  <NavLink
                    to={`${
                      currentKey === "reference"
                        ? "/references/search/reference"
                        : currentKey === "section"
                        ? "/references/search/section"
                        : currentKey === "collection"
                        ? "/references/search/collection"
                        : currentKey === "geochemical"
                        ? "/section/geochemical"
                        : currentKey === "taxonomy"
                        ? "/taxonomy/addTaxon"
                        : currentKey === "oceanic"
                        ? "/oceanic/add/huici"
                        : null
                    }`}
                    target="_blank"
                  >
                    {/* <p>Edit</p> */}
                    <p>Add</p>
                    <p> {currentKey[0].toUpperCase() + currentKey.slice(1)}</p>
                  </NavLink>
                </Button>
                <p className="center-h5-addbtn">
                  <span>Add {currentKey[0].toUpperCase() + currentKey.slice(1)}</span>
                </p>
              </div>
            </div>
            <div className="user-resource-allList">
              <InfiniteScroll initialLoad={false} pageStart={0} loadMore={this.handleInfiniteOnLoad} hasMore={!this.state.dataLoading && this.state.dataHasMore} useWindow={false}>
                <div className="center-resource-list">
                  {showDatas.length > 0 &&
                    showDatas.map((item, index) => {
                      let imageUrl = require("src/assets/images/form/upload.png");
                      return currentKey === "reference" ? (
                        <div className="center-resource-item" onClick={() => this.getDetail(item, index)} key={index}>
                          <div className="resource-item-content">
                            <div className="item-content-detail">
                              <div className="resource-item-section">
                                <p>{item.ref_id}</p>
                              </div>
                              <div className="resource-item-reference">
                                <p title={item.ref_title}>{item.ref_title}</p>
                              </div>
                              <div className="resource-item-img">
                                <img src={imageUrl} alt="avatar" />
                              </div>
                              <div className="resource-item-time">
                                <p title={item.ref_publication_type}>{item.ref_publication_type}</p>
                              </div>
                              <div className="center-delete" title="delete" onClick={(e) => this.deleteCenterRef(item, index, e)}>
                                <span className="iconfont icon-delete"></span>
                              </div>
                            </div>

                            <div className="section-detail-name">
                              <p>
                                {item.createdAt ? item.createdAt.split("T")[0] : ""}
                                {/* {item.createdAt
                                  ? moment(item.createdAt).format("YYYY-MM-DD")
                                  : ""} */}
                              </p>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      ) : currentKey === "section" ? (
                        sectionType == "section_formation" ? (
                          <div className="center-resource-item" onClick={() => this.getDetail(item, index)} key={index}>
                            <div className="resource-item-content">
                              <div className="item-content-detail">
                                <div className="resource-item-section">
                                  <p>{item.formation_id}</p>
                                </div>
                                <div className="resource-item-reference">
                                  <p title={item.formation_name}>{item.formation_name}</p>
                                </div>
                                <div className="resource-item-img">
                                  <img src={imageUrl} alt="avatar" />
                                </div>
                                <div className="resource-item-time">
                                  <p title={item.formation_lithology}>{item.formation_lithology}</p>
                                </div>
                                <div className="center-delete" title="delete" onClick={(e) => this.deleteCenterFormation(item, index, e)}>
                                  <span className="iconfont icon-delete"></span>
                                </div>
                              </div>

                              <div className="section-detail-name">
                                <p>
                                  {item.createdAt ? item.createdAt.split("T")[0] : ""}
                                  {/* {item.createdAt
                                    ? moment(item.createdAt).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""} */}
                                </p>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        ) : sectionType == "section_unit" ? (
                          <div className="center-resource-item" onClick={() => this.getDetail(item, index)} key={index}>
                            <div className="resource-item-content">
                              <div className="item-content-detail">
                                <div className="resource-item-section">
                                  <p>{item.unit_id}</p>
                                </div>
                                <div className="resource-item-reference">
                                  <p title={item.unit_name}>{item.unit_name}</p>
                                </div>
                                <div className="resource-item-img">
                                  <img src={imageUrl} alt="avatar" />
                                </div>
                                <div className="resource-item-time">
                                  <p title={item.unit_thickness}>{item.unit_thickness}</p>
                                </div>
                                <div className="center-delete" title="delete" onClick={(e) => this.deleteCenterUnit(item, index, e)}>
                                  <span className="iconfont icon-delete"></span>
                                </div>
                              </div>

                              <div className="section-detail-name">
                                <p>
                                  {item.createdAt ? item.createdAt.split("T")[0] : ""}
                                  {/* {item.createdAt
                                    ? moment(item.createdAt).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""} */}
                                </p>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        ) : (
                          <div className="center-resource-item" onClick={() => this.getDetail(item, index)} key={index}>
                            <div className="resource-item-content">
                              <div className="item-content-detail">
                                <div className="resource-item-section">
                                  <p>{item.section_id}</p>
                                </div>
                                <div className="resource-item-reference">
                                  <p title={item.section_name}>{item.section_name}</p>
                                </div>
                                <div className="resource-item-img">
                                  <img src={imageUrl} alt="avatar" />
                                </div>
                                <div className="resource-item-time">
                                  <p title={item.section_notes}>{item.section_notes}</p>
                                </div>
                                <div className="center-delete" title="delete" onClick={(e) => this.deleteCenterSection(item, index, e)}>
                                  <span className="iconfont icon-delete"></span>
                                </div>
                              </div>

                              <div className="section-detail-name">
                                <p>
                                  {item.createdAt ? item.createdAt.split("T")[0] : ""}
                                  {/* {item.createdAt
                                    ? moment(item.createdAt).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""} */}
                                </p>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        )
                      ) : currentKey === "collection" ? (
                        <div className="center-resource-item" onClick={() => this.getDetail(item, index)} key={index}>
                          <div className="resource-item-content">
                            <div className="item-content-detail">
                              <div className="resource-item-section">
                                <p>{item.coll_id}</p>
                              </div>
                              <div className="resource-item-reference">
                                <p>{item.coll_no}</p>
                              </div>
                              <div className="resource-item-img">
                                <img src={imageUrl} alt="avatar" />
                              </div>
                              <div className="resource-item-time">
                                <p title={item.coll_resolution}>{item.coll_resolution}</p>
                              </div>
                              <div className="center-delete" title="delete" onClick={(e) => this.deleteCenterColl(item, index, e)}>
                                <span className="iconfont icon-delete"></span>
                              </div>
                            </div>

                            <div className="section-detail-name">
                              <p>
                                {item.createdAt ? item.createdAt.split("T")[0] : ""}
                                {/* {item.createdAt
                                  ? moment(item.createdAt).format("YYYY-MM-DD")
                                  : ""} */}
                              </p>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      ) : currentKey === "fossil" ? (
                        <div className="center-resource-item" onClick={() => this.getDetail(item, index)} key={index}>
                          <div className="resource-item-content">
                            <div className="item-content-detail">
                              <div className="resource-item-section">
                                <p>{item.id}</p>
                              </div>
                              <div className="resource-item-reference">
                                <p>{item.fossil_no}</p>
                              </div>
                              <div className="resource-item-img">
                                <img src={imageUrl} alt="avatar" />
                              </div>
                              <div className="resource-item-time">
                                <p title={item.collection_id}>coll_id:{item.collection_id}</p>
                              </div>
                              <div className="center-delete" title="delete" onClick={(e) => this.deleteCenterFossil(item, index, e)}>
                                <span className="iconfont icon-delete"></span>
                              </div>
                            </div>

                            <div className="section-detail-name">
                              <p>
                                {item.createdAt ? item.createdAt.split("T")[0] : ""}
                                {/* {item.createdAt
                                  ? moment(item.createdAt).format("YYYY-MM-DD")
                                  : ""} */}
                              </p>
                            </div>               
                            <div></div>
                          </div>
                        </div>
                      ) : currentKey === "geochemical" ? (
                        <div className="center-resource-item" onClick={() => this.getDetail(item, index)} key={index}>
                          <div className="resource-item-content">
                            <div className="item-content-detail">
                              <div className="resource-item-section">
                                <p>{item.id}</p>
                              </div>
                              <div className="resource-item-reference">
                                <p>{item.section_basic_id}</p>
                              </div>
                              <div className="resource-item-img">
                                <img src={imageUrl} alt="avatar" />
                              </div>
                              <div className="resource-item-time">
                                <p title={item.ref_id}>{item.ref_id}</p>
                              </div>
                              <div className="center-delete" title="delete" onClick={(e) => this.deleteCenterGeochemical(item, index, e)}>
                                <span className="iconfont icon-delete"></span>
                              </div>
                            </div>

                            <div className="section-detail-name">
                              <p>
                                {item.createdAt ? item.createdAt.split("T")[0] : ""}
                                {/* {item.createdAt
                                  ? moment(item.createdAt).format("YYYY-MM-DD")
                                  : ""} */}
                              </p>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      ) : currentKey === "taxonomy" ? (
                        <div className="center-resource-item" onClick={() => this.getDetail(item, index)} key={index}>
                          <div className="resource-item-content">
                            <div className="item-content-detail">
                              <div className="resource-item-section">
                                <p>{item.id}</p>
                              </div>
                              <div className="resource-item-reference">
                                <p title={`${item.taxon_name1}`}>{item.taxon_name1}</p>
                              </div>
                              <div className="resource-item-img">
                                <img src={imageUrl} alt="avatar" />
                              </div>
                              <div className="resource-item-time">
                                <p title={item.taxon_type}>{item.taxon_type}</p>
                              </div>
                              <div className="center-delete" title="delete" onClick={(e) => this.deleteCenterTaxon(item, index, e)}>
                                <span className="iconfont icon-delete"></span>
                              </div>
                            </div>

                            <div className="section-detail-name">
                              <p>
                                {item.createdAt ? item.createdAt.split("T")[0] : ""}
                                {/* {item.createdAt
                                  ? moment(item.createdAt).format("YYYY-MM-DD")
                                  : ""} */}
                              </p>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      ) : currentKey === "oceanic" ? (
                        <div className="center-resource-item" onClick={() => this.getDetail(item, index)} key={index}>
                          <div className="resource-item-content">
                            <div className="item-content-detail">
                              <div className="resource-item-section">
                                <p>{item.id}</p>
                              </div>
                              <div className="resource-item-reference">
                                <p title={item.JH}>{item.JH}</p>
                              </div>
                              <div className="resource-item-img">
                                <img src={imageUrl} alt="avatar" />
                              </div>
                              <div className="resource-item-time">
                                <p title={item.QXCS}>{item.QXCS}</p>
                              </div>
                              <div className="center-delete" title="delete" onClick={(e) => this.deleteCenterOceanic(item, index, e)}>
                                <span className="iconfont icon-delete"></span>
                              </div>
                            </div>

                            <div className="section-detail-name">
                              <p>
                                {item.createdAt ? item.createdAt.split("T")[0] : ""}
                                {/* {item.createdAt
                                  ? moment(item.createdAt).format("YYYY-MM-DD")
                                  : ""} */}
                              </p>
                            </div>
                            {/* <div></div> */}
                          </div>
                        </div>
                      ) : null;
                    })}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>

        {currentKey === "reference" ? (
          <Modal
            title=""
            destroyOnClose
            visible={this.state.showDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76.8%"
            footer={null}
            onOk={this.detailFormOk}
            onCancel={this.detailFormCancel}
          >
            <ReferenceDetailForm
              detailData={this.state.detailData}
              modifyReference={this.modifyReference}
              onlySeeRef={false}
              isCenterUser={true}

              //  searchSection={this.searchSection}
            ></ReferenceDetailForm>
          </Modal>
        ) : currentKey === "section" ? (
          <Modal
            title=""
            destroyOnClose
            visible={this.state.showDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76.8%"
            footer={null}
            // header={null}
            onOk={this.detailFormOk}
            onCancel={this.detailFormCancel}
          >
            {this.state.sectionType === "baseinfo" ? (
              <BaseDetailForm detailData={this.state.detailData} viewGeography={this.viewGeography} modifyBase={this.modifyBase} seeOnly={false} isCenterUser={true}></BaseDetailForm>
            ) : this.state.sectionType === "geology" ? (
              <GeographyDetailForm detailData={this.state.detailData} seeOnly={false} viewBase={this.viewBase} modifyGeography={this.modifyGeography} isCenterUser={true}></GeographyDetailForm>
            ) : this.state.sectionType === "section_formation" ? (
              <FormationDetailForm seeOnly={false} detailData={this.state.detailData} modifyFormation={this.modifyFormation} isCenterUser={true}></FormationDetailForm>
            ) : this.state.sectionType === "section_unit" ? (
              <UnitDetailForm seeOnly={false} detailData={this.state.detailData} modifyunit={this.modifyunit} isCenterUser={true}></UnitDetailForm>
            ) : null}
          </Modal>
        ) : currentKey === "collection" ? (
          <Modal
            title=""
            destroyOnClose
            // visible={this.state.showDetailForm}
            visible={this.state.showDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76%"
            footer={null}
            // header={null}
            onOk={this.detailFormOk}
            onCancel={this.detailFormCancel}
          >
            <CollectionDetailForm detailData={this.state.detailData} seeOnly={false} modifyCollection={this.modifyCollection} isCenterUser={true}></CollectionDetailForm>
          </Modal>
        ) : currentKey === "geochemical" ? (
          <Modal
            title=""
            destroyOnClose
            visible={this.state.showDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76%"
            footer={null}
            // header={null}
            onOk={this.detailFormOk}
            onCancel={this.detailFormCancel}
          >
            <GeochemicalDetailForm detailData={this.state.detailData} seeOnly={false} modifyGeochemical={this.modifyGeochemical} isCenterUser={true}></GeochemicalDetailForm>
          </Modal>
        ) : currentKey === "taxonomy" ? (
          <Modal
            title=""
            destroyOnClose
            visible={this.state.showDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76%"
            footer={null}
            // header={null}
            onOk={this.detailFormOk}
            onCancel={this.detailFormCancel}
          >
            <TaxonomyDetailForm detailData={this.state.detailData} onlySeeRef={true} seeOnly={false} modifyTaxomy={this.modifyTaxomy} isCenterUser={true}></TaxonomyDetailForm>
          </Modal>
        ) : currentKey === "oceanic" ? (
          <Modal
            title=""
            destroyOnClose
            visible={this.state.showDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76%"
            footer={null}
            // header={null}
            onOk={this.detailFormOk}
            onCancel={this.detailFormCancel}
          >
            <OceanicDetailForm
              detailData={this.state.detailData}
              onlySeeRef={true}
              seeOnly={false}
              modifyOceanic={this.modifyOceanic}
              oceanicType={this.state.oceanicType}
              isCenterUser={true}
            ></OceanicDetailForm>
          </Modal>
        ) : null}
      </div>
    );
  }
}
export default Center;
