import React, { Component } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import "./index.css";
class SearchBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSearchKey:
        this.props.location.pathname.split("/")[
          this.props.location.pathname.split("/").length - 1
        ] + "Search" || "referenceSearch"
    };
  }
  componentDidMount = () => {
    let url = this.props.location;
    if (url) {
      let pathname = url.pathname;
      let all = pathname.split("/");
      let keyValue = all[all.length - 1] + "Search";
      this.setState(
        {
          currentSearchKey: keyValue
        },
        () => {}
      );
    }
  };
  searchKey = e => {
    this.setState({
      currentSearchKey: e.key
    });
  };
  render() {
    let sectionPath = this.props.location.pathname;
    let pathArr = sectionPath.split("/");
    let currentSearchKey = pathArr[pathArr.length - 1] + "Search";

    return (
      <div id="searchBase" className="form-contents">
        <div className="searchBase-title">
          <div className="reference-search-operate">
            <p>Search</p>
          </div>
         
        </div>
        <div className="searchBase-main">
          <div id="searchMenu">
            <Menu
              mode="horizontal"
              className="serach-menu"
              onClick={e => this.searchKey(e)}
              defaultSelectedKeys={currentSearchKey}
              selectedKeys={currentSearchKey}
            >
              <Menu.Item key="referenceSearch">
                <div>
                  <NavLink to="/search/reference">
                    <p>Reference</p>

                    <p>search</p>
                  </NavLink>
                </div>
              </Menu.Item>

              <Menu.Item key="sectionSearch">
                <div>
                  <NavLink to="/search/section">
                    <p>Section</p>

                    <p>search</p>
                  </NavLink>
                </div>
              </Menu.Item>
              <Menu.Item key="taxonomySearch">
                <div>
                  <NavLink to="/search/taxonomy">
                    <p>Taxonomy</p>

                    <p>search</p>
                  </NavLink>
                </div>
              </Menu.Item>
              <Menu.Item key="occurenceSearch">
                <div>
                  <NavLink to="/search/occurence">
                    <p>Occurrence</p>

                    <p>search</p>
                  </NavLink>
                </div>
              </Menu.Item>
            </Menu>
          </div>
        </div>
        <div className="search-parts form-mian-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default SearchBase;
