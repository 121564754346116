import React, { Component } from "react";
import "./index.css";
import { Menu, Modal } from "antd";
import { NavLink } from "react-router-dom";
import userApis from "src/js/apis/user";
import Storage from "src/js/storage";
import "src/js/global.js";
let showExpertData = (userType, authState) => {
  let showUser = false;
  if (authState === 2) {
    showUser = true;
  } else if (authState === 3 && userType === 2) {
    showUser = true;
  } else if (authState === 5 && userType !==1) {
    showUser = true;
  } else if (authState === 4&& userType==2) {
    showUser = true;
  }

  return showUser;
};
export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey:
        this.props.location.pathname.split("/")[
          this.props.location.pathname.split("/").length - 1
        ] || "compiler"
    };
  }
  taxonKey = e => {
    this.setState({
      currentKey: e.key
    });
  };
  isGbdbUser=()=>{
    let token = Storage.getItem("token");
   
    if(token==undefined){
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    }
  
  }
  async componentDidMount() {
    // this.isGbdbUser()
    let allUserData = {};
    let token = Storage.getItem("token");
    let params = {
      token,
      loginby: 1
    };
    let fossilBack = await userApis.initUser(params);
    if (fossilBack.result) {
     
      let updateUser = {
        userid: fossilBack.result.id,
        loginby: 1
      };
      let gbdbUserBack = await userApis.gbdbUser(updateUser);
      if (gbdbUserBack.result) {
        let gbdbData = gbdbUserBack.result;
        if (gbdbData) {
          let { userType, authState } = gbdbData;
          let isExpert = showExpertData(userType, authState);
          if (isExpert) {
            this.setState({
              currentKey: "expert"
            });
            
            this.props.history.push("/profile/advance/"+this.state.currentKey);
          }
        }
        let baseData = fossilBack.result;
        Object.assign(allUserData, gbdbData, baseData);
        // 设置默认图片
        this.setState(
          {
            filePath: "/api" + gbdbData.filePath
          },
          () => {}
        );
      } else {
        this.props.history.push("/home");
      }

      this.setState({
        userData: allUserData
      });
    } else {
      if (fossilBack.state === 204) {
        Storage.removeItem("token");
        Storage.removeItem("fossilid");
        Storage.removeItem("gbdbid");
        Storage.removeItem("checkerid");
        Storage.removeItem("checkerName");
        Storage.removeItem("gbdblevel");
        Storage.removeItem("Inputerid");
        return;
      }
      Modal.error({
        title: "This is an error message",
        content: fossilBack.error
      });
      this.props.history.push("/home");
    }
  }
  render() {
    let { currentKey } = this.state;

    return (
      <div id="profile_advance">
        <div className="group-index form-contents">
          <div className="reference-search-operate">
            <p className="group-index-title">Profile</p>
          </div>
          <div className="">
            <div id="profileMenu">
              <Menu
                mode="horizontal"
                className="reference-menu profile-index-menu"
                defaultSelectedKeys="advanced"
                selectedKeys="advanced"
              >
                <Menu.Item key="basic">
                  <div>
                    <NavLink to="/profile/basic">Basic Info</NavLink>
                  </div>
                </Menu.Item>
                <Menu.Item key="advanced">
                  <div>
                    <NavLink to="/profile/advance/compiler">
                      Advanced Info
                    </NavLink>
                  </div>
                </Menu.Item>
              </Menu>
            </div>

            <div id="compilerMenu">
              <div className="menu-line"></div>
              <Menu
                mode="horizontal"
                className="compiler-menu"
                onClick={e => this.taxonKey(e)}
                defaultSelectedKeys={currentKey}
                selectedKeys={currentKey}
              >
                <Menu.Item key="compiler">
                  <div>
                    <NavLink to="/profile/advance/compiler">
                      Data compiler
                    </NavLink>
                  </div>
                </Menu.Item>
                <Menu.Item key="expert">
                  <div>
                    <NavLink to="/profile/advance/expert"> Expert </NavLink>
                  </div>
                </Menu.Item>
              </Menu>
            </div>
          </div>

          <div className="taxonomy-parts">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
