import React, { Component } from "react";
import { Button, Checkbox, Input, Modal, Radio, InputNumber,message } from "antd";
import { withRouter } from "react-router-dom";
import Apis from "src/js/apis/api";
import Utils from "src/js/utils";
import Storage from "src/js/storage";
import SubsetApis from "src/js/apis/subset";
import "./index.css";

@withRouter
class FormationTableResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResultVisible: false,
      detailData: {},
      showDetailForm: false,

      isCheckAll: false,
      checkedObj: {},
      defaultCheckedList: [],
      searchParams: props.searchParams || {},
      searchValues: props.searchValues || {},
      currentPage: 1,
      totalNumbers: 0,
      resultLists: [],
      pageSize: 16,
      AllPages: 0,
      checkOne: false,
      sectionType: "baseinfo",
      // askLoading:false
    };
  }
  componentDidMount() {
    if (this.props.onGeochemial) {
      this.props.onGeochemial(this);
    }
    if (this.props.onBase) {
      this.props.onBase(this);
    }
    if (this.props.checkOne) {
      this.setState({
        checkOne: true,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      searchParams: nextProps.searchParams,
      searchAllValues: nextProps.searchAllValues,
    });
    if (this.props.checkOne) {
      this.setState({
        checkOne: true,
      });
    }
    if (nextProps.showDeleteSubset !== this.props.showDeleteSubset) {
      this.setState({
        showDeleteSubset: nextProps.showDeleteSubset,
      });
    }
    if (nextProps.showAddSubset !== this.props.showAddSubset) {
      this.setState({
        showAddSubset: nextProps.showAddSubset,
      });
    }
  }

  gotoPage = (e) => {
    if (e.keyCode === 13) {
      var page = parseInt(e.target.value);
      let { AllPages } = this.state;

      if (page < 1 || page > AllPages) {
         message.warning("Please enter the correct page number");
        return;
      } else {
        this.setState(
          {
            currentPage: page,
          },
          () => {
            this.changePage(this.state.currentPage);
          }
        );
      }
    }
  };
  gotoPages = () => {
    let pageDom = document.querySelector(".searchResult-select input");
    if (pageDom) {
      let pageValue = pageDom.value;
      if (pageValue) {
        let page = parseInt(pageValue);
        let { AllPages } = this.state;
        if (page < 1 || page > AllPages) {
          message.warning("Please enter the correct page number");
          return;
        } else {
          this.setState(
            {
              currentPage: page,
            },
            () => {
              this.changePage(this.state.currentPage);
            }
          );
        }
      }
    }
  };
  toFirst = (e) => {
    this.setState(
      {
        currentPage: 1,
      },
      () => {
        this.changePage(this.state.currentPage);
      }
    );
  };

  toPre = (e) => {
    if (this.state.currentPage === 1) {
      return;
    } else {
      this.setState(
        {
          currentPage: this.state.currentPage - 1,
        },
        () => {
          this.changePage(this.state.currentPage);
        }
      );
    }
  };

  changePage = (value) => {
    this.setState(
      {
        currentPage: value,
        isCheckAll: false,
        askLoading: undefined,
        checkedObj: {},
        resultLists: [],
      },
      () => {
        let { searchParams, values } = this.state;

        let pathname = this.props.location.pathname;
        if (pathname === "/subset") {
          this.searchSubset(searchParams, values);
        } else {
          this.searchSectionDatas(searchParams, values, "", "", this.state.currentPage);
        }
      }
    );
  };
  searchSectionDatas = async (searchParams, searchAllValues, parameter1, parameter2, page) => {
    if (page == undefined) {
      this.setState({
        currentPage: 1,
      });
    }
    this.setState(
      {
        askLoading: true,
      },
      async () => {
        let searchResult = [];
        let searchCondition = {};
        let pageParams = {
          page: this.state.currentPage,
          pageSize: this.state.pageSize,
        };
        let searchParamsCopy = Utils.deepObjCopy(searchParams);
        if (searchParamsCopy.early_age) {
          searchParamsCopy.early_age = {
            condition: "before",
            value: searchParamsCopy.early_age,
          };
        }
        if (searchParamsCopy.late_age) {
          searchParamsCopy.late_age = {
            condition: "after",
            value: searchParamsCopy.late_age,
          };
        }
        Object.assign(searchCondition, searchParamsCopy, pageParams);

        searchResult = await Apis.getFormations(searchCondition);

        if (searchResult.error) {
          this.setState({
            askLoading: undefined,
          });
          Modal.error({
            title: "This is an error message",
            content: searchResult.error,
          });
        } else if (searchResult.result) {
          let result = searchResult.result;
          let totalNumbers = searchResult.total;
          let { pageSize } = this.state;
          let AllPages = Math.ceil(totalNumbers / pageSize);
          this.setState(
            {
              resultLists: result,
              totalNumbers,
              AllPages,
              conditions: searchAllValues,
              askLoading: false,
            },
            () => {}
          );
        }
      }
    );
  };
  toNext = (e) => {
    if (this.state.currentPage === this.state.AllPages) {
      return;
    } else {
      this.setState(
        {
          currentPage: this.state.currentPage + 1,
        },
        () => {
          this.changePage(this.state.currentPage);
        }
      );
    }
  };

  CheckAll = (e) => {
    let { resultLists, checkedObj } = this.state;
    if (e.target.checked === true) {
      this.setState(
        {
          isCheckAll: true,
        },
        () => {
          resultLists.map((item, index) => {
            checkedObj[item.formation_id] = {
              item,
              index,
            };
          });
          this.setState(
            {
              checkedObj,
            },
            () => {
              let { checkedObj, resultLists } = this.state;
              this.props.changeCheckSelected(checkedObj, resultLists);
            }
          );
        }
      );
    } else {
      this.setState(
        {
          isCheckAll: false,
        },
        () => {
          this.setState(
            {
              checkedObj: {},
            },
            () => {
              let { checkedObj, resultLists } = this.state;
              this.props.changeCheckSelected(checkedObj, resultLists);
            }
          );
        }
      );
    }
  };

  itemCheckbox = (item, index, event) => {
    let { resultLists, checkedObj } = this.state;
    let nameId = Number(event.target.name);
    if (event.target.checked === true) {
      checkedObj[nameId] = {
        item,
        index,
      };
    } else if (event.target.checked === false && checkedObj[nameId]) {
      delete checkedObj[nameId];
    }

    let checkedLength = Object.keys(checkedObj).length;

    this.setState(
      {
        isCheckAll: checkedLength === resultLists.length,
      },
      () => {
        if (this.props.checkOne) {
          let datavalue = event.target.datavalue;

          this.props.checkFormationOne(datavalue);
        } else {
          let { checkedObj, resultLists } = this.state;
          this.props.changeCheckSelected(checkedObj, resultLists);
        }
      }
    );
  };

  getDetail = (detailItem) => {
    this.props.getDetail(detailItem);
  };
  searchSubset = async (searchParams) => {
    this.setState(
      {
        askLoading: true,
      },
      async () => {
        let pageSize = 11;
        let searchCondition = {};
        let pageParams = {
          page: this.state.currentPage,
          pageSize,
        };
        Object.assign(searchCondition, searchParams, pageParams);
        let folderResBack = await SubsetApis.getAssetsInfolder(searchCondition);
        if (folderResBack.error) {
          this.setState({
            askLoading: undefined,
          });
          const modal = Modal.error({
            title: "This is an error message",
            content: folderResBack.error,
          });
          setTimeout(() => {
            modal.destroy();
          }, 3000);
        } else {
          let result = folderResBack.result;
          let totalNumbers = folderResBack.total;

          let AllPages = Math.ceil(totalNumbers / pageSize);
          this.setState(
            {
              resultLists: result,
              totalNumbers,
              AllPages,
              askLoading: false,
              // conditions: searchAllValues
            },
            () => {}
          );
        }
      }
    );
  };
  deleteFun = async () => {
    let { checkedObj, resultLists } = this.state;
    let rankValue = Object.values(checkedObj).sort(Utils.objComparison("index"));
    let deleteArrays = [];
    rankValue.map((item, index) => {
      deleteArrays.push(item.item.formation_id);
    });
    // let params = {
    //   uid,
    //   assetstype: 6,
    //   ids: deleteArrays
    // };
    deleteArrays.map((item, index) => {
      resultLists.splice(checkedObj[item].index, 1);
      delete checkedObj[item];
    });
    if (resultLists.length < 11) {
      if (this.state.currentPage == this.state.AllPages) {
        if (resultLists.length == 0) {
          this.toPre();
        }
      } else if (this.state.currentPage == 1) {
        this.toFirst();
      } else {
        this.toPre();
      }
    }
    this.setState(
      {
        checkedObj,
        resultLists,
        isCheckAll: false,
      },
      () => {
        this.props.changeCheckSelected(checkedObj, resultLists);
      }
    );
  };
  deleteSubset = async () => {
    let { checkedObj, resultLists } = this.state;
    let rankValue = Object.values(checkedObj).sort(Utils.objComparison("index"));
    let deleteArrays = [];

    rankValue.map((item, index) => {
      deleteArrays.push(item.item.formation_id);
    });
    let uid = Storage.getItem("gbdbid");

    let params = {
      uid,
      assetstype: 6,
      ids: deleteArrays,
    };

    let deleteAssetsBack = await SubsetApis.deleteAssets(params);
    if (deleteAssetsBack.result) {
      deleteArrays.map((item, index) => {
        resultLists.splice(checkedObj[item].index, 1);
        delete checkedObj[item];
      });

      if (resultLists.length < 11) {
        if (this.state.currentPage == this.state.AllPages) {
          if (resultLists.length == 0) {
            this.toPre();
          }
        } else if (this.state.currentPage == 1) {
          this.toFirst();
        } else {
          this.toPre();
        }
      }
      this.setState(
        {
          checkedObj,
          resultLists,
          isCheckAll: false,
        },
        () => {
          this.props.changeCheckSelected(checkedObj, resultLists);
        }
      );
    } else {
      Modal.error({
        title: "This is an error message",
        content: deleteAssetsBack.error,
      });
    }
  };
  resetCheckData = () => {
    this.setState({
      currentPage: 1,
      isCheckAll: false,
      askLoading: undefined,
      checkedObj: {},
    });
  };
  render() {
    let { resultLists } = this.state;

    let { currentPage, checkOne, AllPages, isCheckAll, checkedObj } = this.state;

    return (
      <div id="SearchResult" className="SearchResult-refsearch">
        {/*  taxResult-main */}
        <div className="">
          <div className="content">
            {resultLists.length > 0 && this.state.askLoading == false ? (
              <table>
                <tbody>
                  <tr className="head">
                    {!checkOne ? (
                      <td className="check">
                        <Checkbox onChange={(e) => this.CheckAll(e, "formation_id")} checked={isCheckAll}></Checkbox>
                      </td>
                    ) : (
                      <td></td>
                    )}

                    <td className="ref-id">Formation ID</td>
                    <td>Formation Name</td>

                    <td>Formation Group</td>
                    <td>Formation Lithology</td>
                    <td>Formation Thick</td>
                    <td>Formation paleoenvironment</td>
                    <td>Enterer</td>
                    <td>Accessibility</td>
                    <td>Details</td>
                  </tr>

                  {resultLists.map((item, index) => {
                    return (
                      <tr key={item.formation_id} className="row">
                        {checkOne ? (
                          <td className="check">
                            <Radio
                              name={item.formation_id.toString()}
                              onChange={(event) => this.itemCheckbox(item, index, event)}
                              datavalue={item}
                              checked={checkedObj.hasOwnProperty(item.formation_id)}
                            ></Radio>
                          </td>
                        ) : (
                          <td className="check">
                            <Checkbox
                              name={item.formation_id.toString()}
                              onChange={(event) => this.itemCheckbox(item, index, event)}
                              datavalue={item}
                              checked={checkedObj.hasOwnProperty(item.formation_id)}
                            ></Checkbox>
                          </td>
                        )}

                        <td>{item.formation_id}</td>

                        <td>{item.formation_name}</td>
                        <td>{item.formation_group}</td>
                        <td>{item.formation_lithology}</td>
                        <td>
                          {item.formation_thick_sign} {item.formation_thick}
                          {item.formation_thick_unit}
                        </td>
                        <td>{item.formation_paleoenvironment}</td>
                        <td>{item.enterer_username}</td>

                        <td>{item.accessibility === 0 ? "open" : "private"}</td>

                        <td className="btn">
                          <Button type="gbdb" onClick={() => this.getDetail(item, index)}>
                            <div>Details</div>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : this.state.askLoading == true ? (
              <div className="tablelist-no-resource">
                 <div id="allResult">
                      <div className="allLoader">
                        <div className="loader-inner ball-spin-fade-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <span>Loading...</span>
                      </div>
                    </div>
              </div>
            ) : resultLists.length === 0 && this.state.askLoading == false ? (
              <div className="tablelist-no-resource">No Resource</div>
            ) : (
              <div className="tablelist-no-resource"></div>
            )}
          </div>

          {resultLists.length > 0 ? (
            <div className="footer">
              <div className="first" onClick={this.toFirst}>
                First
              </div>
              <div className="pre" onClick={this.toPre}>
                Pre
              </div>
              <div className="pages">{`${currentPage}/${AllPages}pages`}</div>
              <div className="next" onClick={this.toNext}>
                Next
              </div>

              <div className="page-input">
                <InputNumber className="searchResult-select" onKeyDown={this.gotoPage} min={1}></InputNumber>
                <span className="goto" onClick={this.gotoPages}>
                  Goto
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default FormationTableResult;
