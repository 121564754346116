import React from 'react'
import {Modal,Form,Button,Pagination,message,Icon,Collapse} from 'antd'
import axios from 'axios'
import './infoModal.scss'
const {Panel}=Collapse

export default class CollectionInfoModal extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            nowPage:1,
            nowPage2:1,
            defaultPageSize:5,
            defaultPageSize2:5,
            total:0,
            total1:0,
            modalData:[],
            isshowInfo:false,
            fossilList:[],
            nowCollId:null,
            key:[],
            isLoading:true
        }
    }
    
    getCollection(page=1){
        let {unit_id,formation_id} = this.props.params;
        const {defaultPageSize}=this.state;
        axios.get(`/api/search/collectionList`,{
            params:{
                page,
                pageSize:defaultPageSize,
                unit_id,
                // formation_id:2,
                formation_id
            }
        }).then(res=>{
         
            if(res.data.error===null){
                var data=res.data.result
                var modalData=this.state.modalData
               
                this.setState({
                    modalData:modalData.concat(data),
                    total:res.data.total,
                    nowPage:page,
                    isLoading:false
                })
               
            }
           
        })
    }
   
    componentDidMount(){
        this.getCollection()
    }
    loadMore(){
        var {nowPage}=this.state
        nowPage++;
        this.setState({
            nowPage,
        },()=>{
            this.getCollection(this.state.nowPage)
        })
       
    }
    changePage2(page,pageSize){
        this.setState({
            nowPage2:page
        })
        this.getFossilList(this.state.nowCollId,page)
        
    }
    getFossilList(nowCollId,page=1){
        const {modalData}=this.props
        const {nowPage}=this.state;
        var sendData={
            collection_id:nowCollId,
            page,
            pageSize:this.state.defaultPageSize2
        }
         axios.get(`/api/search/fossilList`,{
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                var data=res.data.result;
                const {fossilList}=this.state
                fossilList[nowCollId]=data
             
                this.setState({
                    fossilList,
                    total2:res.data.total,
                    nowPage2:page
                })
            }else{
                message.error(res.data.error)
            }
        }).catch(err=>{
            // message.error(err)
        })
    }
    fullName(
        taxonName1,
        taxonName2,
        genusPrefix,
        spPrefix1,
        spPrefix2,
        spNameDict,
        spSuffix
      ) {
    
      

        let fullTaxonName = "";
        let genusName = "";
        let speciesName = "";
        genusName = taxonName1?taxonName1:'';
        speciesName = taxonName2?taxonName2:'';
     
        if (genusPrefix && genusPrefix.trim() != "" && genusPrefix.trim() != "Null") {
          switch (genusPrefix.trim()) {
            case '""':
              genusName = '"' + genusName + '"';
              break;
            case '""cf.':
              genusName = 'cf. "' + genusName + '"';
              break;
            case '?""cf.':
              genusName = '? cf. "' + genusName + '"';
              break;
            default:
              genusName = genusPrefix.trim() + " " + genusName;
              break;
          }
        }
        if (spPrefix1 && spPrefix1.trim() != "" && spPrefix1.trim() != "Null") {
          switch (spPrefix1.trim()) {
            case '""':
              speciesName = '"' + speciesName + '"';
              break;
            case '?""':
              speciesName = '? "' + speciesName + '"';
              break;
            default:
              speciesName = spPrefix1.trim() + " " + speciesName;
              break;
          }
        }
    
        if (spPrefix2 && spPrefix2.trim() != "" && spPrefix2.trim() != "Null") {
          speciesName = spPrefix2.trim() + " " + speciesName;
        }
    
        if (spNameDict && spNameDict.trim() != "" && spNameDict.trim() != "Null") {
          //speciesName = spNameDict.trim() + " " + speciesName;
          speciesName = speciesName + " " + spNameDict.trim();
        }
    
        if (spSuffix && spSuffix.trim() != "" && spSuffix.trim() != "Null") {
          speciesName = speciesName + " " + spSuffix.trim();
        }
    
        fullTaxonName = genusName + " " + speciesName;
    
        return fullTaxonName;
      }
    changeColl(key){

       
        var oldKey=this.state.key
        if(key.length>oldKey.length){
            var nowCollId=key[key.length-1]
            this.getFossilList(nowCollId,this.state.nowPage2)
            this.setState({
                key,
                nowCollId,
                
            })
        }else{
            this.setState({
                key,
                nowCollId:null
            })
        }
      
    }
    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 4 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 20 },
            },
          };
       
        const {nowPage,nowPage2,defaultPageSize,defaultPageSize2,total,total2,modalData,fossilList,isLoading}=this.state;
        return (
            <Modal
                width={'1100px'}
                className='AModal infoModal'
                visible={this.props.visible}
                onCancel={this.props.cancelShowCollection}
                footer={null}
                mask={false}
                maskClosable={false}
            >
                <div>
                    <div>
                        {
                            isLoading?<div className='tip1'>Loading</div>:null
                        }
                        
                        {
                           !isLoading && modalData.length===0?<div className='tip1'>There is no data...</div>:null
                        }
                        {
                            modalData.length>0?(
                                <Collapse style={{width:'95%',margin:"0 auto"}} onChange={this.changeColl.bind(this)}>
                                {
                                    modalData.map((item,index)=>{
                                        return (
                                        <Panel header={`collection no(${item.coll_no})`} key={item.coll_id}>
                                        <Form>
                                                <div className='line'>
                                                    <div className='lineItem'> 
                                                        <Form.Item label='Reference ID'>
                                                            <span>{item.ref_id}</span>
                                                        </Form.Item>
                                                    </div>
                                                    <div className='lineItem'>
                                                        <Form.Item label='Unit ID'>
                                                            <span>{item.unit_id}</span>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='line'>
                                                    <div className='lineItem'>
                                                        <Form.Item label='Collection no'>
                                                            <span>{item.coll_no}</span>
                                                        </Form.Item>
                                                    </div>
                                                    <div className='lineItem'>
                                                        <Form.Item className='lineHeight' label={<div>Modes of<br/>preservation</div>}>
                                                            <span>{item.coll_preservation}</span>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className='line'>
                                                    <div className='lineItem'>
                                                        <Form.Item className='lineHeight' label={<div>Original<br/>biominerals</div>}>
                                                            <span>{item.coll_biominerals}</span>
                                                        </Form.Item>
                                                    </div>
                                                    <div className='lineItem'>
                                                        <Form.Item className='lineHeight' label={<div>Replacement<br/>Minerals</div>}>
                                                            <span>{item.coll_minerals}</span>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className='line'>
                                                    <div className='lineItem'>
                                                        <Form.Item className='lineHeight' label={<div>Special modes<br/>of occurrence</div>}>
                                                            <span>{item.coll_occurrence}</span>
                                                        </Form.Item>
                                                    </div>
                                                    <div className='lineItem'>
                                                        <Form.Item className='lineHeight' label={<div>Temporal<br/>Resolution</div>}>
                                                            <span>{item.coll_tmporal}</span>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className='line'>
                                                    <div className='lineItem'>
                                                        <Form.Item className='lineHeight' label={<div>Spatial<br/>resoution</div>}>
                                                            <span>{item.coll_resolution}</span>
                                                        </Form.Item>
                                                    </div>
                                                    <div className='lineItem'>
                                                        <Form.Item label='Lager statten type'>
                                                            <span>{item.coll_type}</span>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className='line'>
                                                    <div className='lineItem'>
                                                        <Form.Item className='lineHeight' label={<div>Degree of<br/>concentration</div>}>
                                                            <span>{item.coll_concentration}</span>
                                                        </Form.Item>
                                                    </div>
                                                    <div className='lineItem'>
                                                        <Form.Item label='Spatial orientation'>
                                                            <span>{item.coll_orientation}</span>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className='line'>
                                                    <div className='lineItem'>
                                                        <Form.Item label='Size sorting'>
                                                            <span>{item.coll_sorting}</span>
                                                        </Form.Item>
                                                    </div>
                                                    <div className='lineItem'>
                                                        <Form.Item label='Fragmentation'>
                                                            <span>{item.coll_fragmentation}</span>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className='line'>
                                                    <div className='lineItem'>
                                                        <Form.Item label='Anatomical detail'>
                                                            <span>{item.coll_detail}</span>
                                                        </Form.Item>
                                                    </div>
                                                    <div className='lineItem'>
                                                        <Form.Item className='lineHeight' label={<div>Abundance in<br/>sediment</div>}>
                                                            <span>{item.coll_sediment}</span>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className='line'>
                                                    <div className='lineItem'>
                                                        <Form.Item label='Bioerpsion'>
                                                            <span>{item.coll_Bioerosion}</span>
                                                        </Form.Item>
                                                    </div>
                                                    <div className='lineItem'>
                                                        <Form.Item label='Encrustation'>
                                                            <span>{item.coll_Encrustation}</span>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='line'>
                                                    <div className='lineItem'>
                                                        <Form.Item className='lineHeight' label={<div>Comments on<br/>preservation</div>}>
                                                            <span>{item.coll_preservations}</span>
                                                        </Form.Item>
                                                    </div>
                                                    <div className='lineItem'>
                                                        <Form.Item label='Size classes'>
                                                            <span>{item.coll_classes}</span>
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className='line'>
                                                    <div className='lineItem'>
                                                        <Form.Item className='lineHeight' label={<div>Feeding/predation<br/>traces</div>}>
                                                            <span>{item.coll_traces}</span>
                                                        </Form.Item>
                                                    </div>
                                                    <div className='lineItem'>
                                                        <Form.Item className='lineHeight' label={<div>Comments on<br/>included components</div>}>
                                                            <span>{item.coll_components}</span>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </Form>
                                            {
                                            fossilList[item.coll_id] && fossilList[item.coll_id].length>0?
                                            (
                                                <div>
                                                    <div className='tip1'>FossilList</div>
                                                    <div className='modalTable'>
                                                        <table>
                                                            <tbody>
                                                                <tr className='head'>
                                                                    <td>序号</td>
                                                                    <td>创建人</td>
                                                                    <td>Fossil ID</td>
                                                                    <td>Fossil no</td>
                                                                    <td>Fossil group</td>
                                                                    <td>Taxon type</td>
                                                                    <td>Taxon name</td>
                                                                    <td>Collect from Treatise?</td>
                                                                </tr>
                                                                {
                                                                    fossilList[item.coll_id].map((item,index)=>{
                                                                        return (
                                                                            <tr className="tr" key={item.id}>
                                                                                <td>{defaultPageSize2*(nowPage2-1)+index+1}</td>
                                                                                <td>{item.enterer_username}</td>
                                                                                <td>{item.id}</td>
                                                                                <td>{item.fossil_no}</td>
                                                                                <td>{item.fossil_group}</td>
                                                                                <td>{item.taxon_type}</td>
                                                                                <td>{this.fullName(item.taxon_name1,item.taxon_name2,item.genus_prefix,item.sp_prefix1,item.sp_prefix2,item.sp_name_dict,item.sp_suffix)}</td>
                                                                                <td>{item.from_treatise}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    total2>defaultPageSize2?(
                                                                        <tr>
                                                                            <td colSpan={8}>
                                                                                <div className='pages' style={{marginTop:'20px'}}>
                                                                                    <Pagination size='small' 
                                                                                        current={nowPage2}
                                                                                        defaultPageSize={defaultPageSize2} 
                                                                                        total={total2} 
                                                                                        // showTotal={showTotal.bind(this)}
                                                                                        onChange={this.changePage2.bind(this)}
                                                                                    ></Pagination>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    
                                                                    ):null
                                                                }
                                                            
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ):(<div className='tip1'>There is no fossilList data</div>)
                                            }
                                        </Panel>
                                        )
                                    })
                                }
                            </Collapse>
                            ):null
                        }
                            
                           
                        {
                            Math.ceil(total/defaultPageSize)>nowPage?(
                                <div className='loadMore' onClick={this.loadMore.bind(this)}>show more collections ...</div>
                            ):null
                        }
                        <div className="foot">
                            <Button type='agbdb1' onClick={this.props.cancelShowCollection}>Close</Button>
                        </div>
                    </div>
                </div>

            </Modal>
        )
    }
}
