import React, { Component } from "react";
import "./index.css";
import {
  Form,

  Button,

  Modal
} from "antd";
import Utils from "src/js/utils";


import TaxonomyOption from "commons/AllOptions/Taxonomy/AllSearch/index.js";



class SearchTaxonomy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allChildError: {}
    };
  }
 
  searchTax = () => {
    this.refs.taxonomy.validateFieldsAndScroll(async (err, values) => {
      // let { allChildError } = this.state;
      // if (JSON.stringify(allChildError) !== "{}") {
      //   Modal.warn({
      //     content: "Please check the query form"
      //   });
      //   return;
      // }
      if (!err) {
        let searchResults = Utils.filterParams(values);

        let searchValues = Utils.filterCondition(searchResults);

        let queryParam = {
          searchParams: searchValues,
          type: "taxonomy",
          searchAllValues: values
        };
        let url = "/searchs/result/taxonomy";
        this.props.history.push({ pathname: url, query: queryParam });
      }
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  render() {
 

    return (
      <div id="searchTaxonomy">
        <div className="searchTaxonomy-main reference-search-form search-common-form">
          <div className="search-common-title form-common-title">
            Taxonomy search
          </div>

          <TaxonomyOption
            showBase={true}
            showGeography={true}
            showUnit={true}
            showFomation={true}
            showOccurrence={true}
            childError={this.childError}
            ref="taxonomy"
          ></TaxonomyOption>
          <div className="referenceSearch-btns">
            <div>
              <Button
                type="gbdb"
                onClick={() => {
                  this.searchTax();
                }}
              >
                <p> Search</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SearchTaxonomy = Form.create({})(SearchTaxonomy);
export default SearchTaxonomy;
