import downloadAxios from "src/js/downloadAxios";

export default {
    // 下载
    referenceCiteDownload:async (params) => {
        let url = `/api/subset/export/reference/cite_download`;
      
        let conop = await downloadAxios.get(url, params);
      
        return conop;
      },
};


