import React from 'react'
import {Form,Button,Modal,message,Input} from 'antd'
import moment from 'moment'
import axios from'axios'

export default class GeoForm extends React.Component{
    constructor(props){
        super(props)
        this.state={
            geoInfo:null,
            isLoading:true

        }
    }
    componentDidMount(){
        var sendData={
            assetstype:2,
            section_basic_id:this.props.sectionId
        }
        axios.get('/api/admin/searchAssets',{
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                this.setState({
                    geoInfo:res.data.result[0],
                    isLoading:false
                })
            }
           
            
        })
    }
    passGeology(){
        Modal.confirm({
            content:'点击“确定”后，BasicInfo、Geology将通过审核！',
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var sendData={
                    section_basic_id:this.props.sectionId,
                    authState:1,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                }
       
                axios.post('/api/admin/review/section',sendData).then(res=>{
                    if(res.data.error===null){
                        this.props.changeStatus();
                    }
                })
            }
        })
    }
    handleReject(){
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                var sendData={
                    section_basic_id:this.props.sectionId,
                    authState:2,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                    auth_comment:str
                }
                axios.post('/api/admin/review/section',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已驳回！')
                        this.props.cancelShowInfo()
                    }
                })
               
            },
          
        })
    }
    time(t){
       if(t){
            t=t.split('T')[0]
            return t ;
       }
       
    }
    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 5 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 19 },
            },
          };
          const {geoInfo,isLoading}=this.state;
        return (
            <div>
                <div style={isLoading?{display:'block'}:{display:'none'}} className='tip1'>Loading...</div>
                {
                geoInfo?
                (<div>
                    <Form {...formItemLayout}>

                        <Form.Item label='Reference ID'>
                        <span>{geoInfo.ref_id}</span>
                        </Form.Item>
                        <div className='line'>
                            <Form.Item className='lineItem' label='Country/Continent'>
                                <span>{geoInfo.geology_country}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Province/State'>
                                <span>{geoInfo.geology_province}</span>
                            </Form.Item>

                        </div>
                    
                        <div className='line'>
                            <Form.Item className='lineItem' label='City/Village'>
                                <span>{geoInfo.geology_village}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Locality'>
                                <span>{geoInfo.geology_location}</span>
                            </Form.Item>
                        </div>

                        <div className='table'>
                            <table>
                                <tbody>
                                <tr>
                                    <td className='first'></td>
                                    <td>Degrees</td>
                                    <td>Minutes</td>
                                    <td>Seconds</td>
                                    <td>Declmal</td>
                                    <td>Direction</td>
                                </tr>
                                <tr>
                                    <td>Latitude</td>
                                    <td>{geoInfo.geology_latitude_degree}</td>
                                    <td>{geoInfo.geology_latitude_minute}</td>
                                    <td>{geoInfo.geology_latitude_second}</td>
                                    <td>{geoInfo.geology_latitude_decimal}</td>
                                    <td>{geoInfo.geology_latitude_direction}</td>
                                </tr>
                                <tr>
                                    <td>Longitude</td>
                                    <td>{geoInfo.geology_longitude_degree}</td>
                                    <td>{geoInfo.geology_longitude_minute}</td>
                                    <td>{geoInfo.geology_longitude_second}</td>
                                    <td>{geoInfo.geology_longitude_decimal}</td>
                                    <td>{geoInfo.geology_longitude_direction}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <Form.Item className='lineHeight' label={<div>Scale of geograhic<br/>resolution</div>}>
                            <span>{geoInfo.geology_geographic_scale}</span>
                        </Form.Item>

                        <Form.Item label='Basic of coordinate'>
                            <span>{geoInfo.geology_coordinates}</span>
                        </Form.Item>

                        <Form.Item label='Altitude'>
                            <span>{geoInfo.geology_altitude?`${geoInfo.geology_altitude} ${geoInfo.geology_altitude_unit}`:null}</span>
                        </Form.Item>

                        <Form.Item label='Location Details'>
                            <span>{geoInfo.geology_details}</span>
                        </Form.Item>

                        <Form.Item label='Comments'>
                            <span>{geoInfo.geology_comments}</span>
                        </Form.Item>
                    </Form>
                    <div className='foot'>
                            <Button  type='agbdb1' onClick={this.passGeology.bind(this)}>通过</Button>
                            <Button type='agbdb2' onClick={this.handleReject.bind(this)}>拒绝</Button>
                    </div>
                </div>):null
                }
                {
                    !geoInfo && !isLoading? (<div>
                        <div className='tip'>暂无数据</div>
                        <div style={{textAlign:'center',height:'80px'}}><Button type='agbdb1'onClick={()=>{this.props.changeStatus()}}>继续</Button></div>
                    </div>):null
                }
               
            </div>
        )
    }
}