import React, { Component } from "react";
import { Button, Checkbox, Input, Modal, Radio,InputNumber,message } from "antd";
import { withRouter } from "react-router-dom";
import Apis from "src/js/apis/api";

import "./index.css";
import Utils from "src/js/utils";

@withRouter
class TaxonomyTableResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ResultVisible: false,
      detailData: {},
      showDetailForm: false,
      taxType: "Original",
      isCheckAll: false,
      checkedObj: {},
      defaultCheckedList: [],
      searchParams: props.searchParams || {},
      searchValues: props.searchValues || {},
      currentPage: 1,
      totalNumbers: 0,
      resultLists: [],
      pageSize: 16,
      AllPages: 0,
      checkOne: props.checkOne === true ? true : false,
      embellish: {},
      disableCheck: props.disableCheck === true ? true : false,
      fossilistObj: props.fossilistObj || {},
      ChildIdsObj: props.ChildIdsObj || {},
      parentObj: props.parentObj || {},
      disabledObj: {},
      // askLoading: false,

      showSiblings: props.showSiblings || false,
    };
  }
  componentDidMount() {
    let { ChildIdsObj, parentObj } = this.state;
    if (this.props.onTaxonomy) {
      this.props.onTaxonomy(this);
    }

    if (this.props.checkOne) {
      this.setState({
        checkOne: true
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      searchParams: nextProps.searchParams,
      searchAllValues: nextProps.searchAllValues
    });
    if (this.props.checkOne) {
      this.setState({
        checkOne: true
      });
    }
    if (nextProps.showDeleteSubset!==this.props.showDeleteSubset) {
      this.setState({
        showDeleteSubset: nextProps.showDeleteSubset
      });
    }
    if (nextProps.showAddSubset!==this.props.showAddSubset) {
      this.setState({
        showAddSubset: nextProps.showAddSubset
      });
    } 
    if (nextProps.showSiblings!==this.props.showSiblings) {
      this.setState({
        showSiblings: nextProps.showSiblings
      });
    } 
  }

  searchTaxonomyDatas = async (
    searchParams,
    searchAllValues,
    embellish,
    taxonType,parameter1,parameter2,page
  ) => {
    if(page==undefined){
      this.setState({
        currentPage:1
      })
      
    }
    this.setState({
      searchParams,
      searchAllValues,
      embellish,
      taxonType,
       askLoading:true
    },async()=>{
      let searchResult = [];
      let searchCondition = {};
      let pageParams = {
        page: this.state.currentPage,
        pageSize: this.state.pageSize
      };
  
      Object.assign(searchCondition, searchParams, pageParams);
      let {showSiblings}=this.state
      if(showSiblings){
    
        searchResult = await Apis.searchTaxonBrothers(searchCondition);
      }else{
        searchResult = await Apis.getTaxons(searchCondition);
      }
  
      
      if (searchResult.error) {
        this.setState({
          askLoading:undefined
        })
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        let result = searchResult.result;
        let totalNumbers = searchResult.total;
        let { pageSize } = this.state;
        let AllPages = Math.ceil(totalNumbers / pageSize);
        this.setState(
          {
            resultLists: result,
            totalNumbers,
            AllPages,
            conditions: searchAllValues,
            askLoading:false
          },
          () => {}
        );
      }
    });
   
  };
  changePage = value => {
    this.setState(
      {
        currentPage: value,
        isCheckAll: false,
        askLoading:undefined,
        checkedObj:{},
        resultLists:[]

      },
      () => {
        let { searchParams, values, embellish, taxonType } = this.state;
        this.searchTaxonomyDatas(searchParams, values, embellish, taxonType,'','',this.state.currentPage);
      }
    );
  };
  gotoPage = e => {
    if (e.keyCode === 13) {
      var page = parseInt(e.target.value);

      let { AllPages } = this.state;
      if (page < 1 || page > AllPages) {
        message.warning("Please enter the correct page number");
        return;
      } else {
        this.setState(
          {
            currentPage: page
          },
          () => {
            this.changePage(this.state.currentPage);
          }
        );
      }
    }
  };
  gotoPages=()=>{
    let pageDom=document.querySelector('.searchResult-select input')
    if(pageDom){
      let pageValue=pageDom.value
      if(pageValue){
        let  page= parseInt(pageValue);
        let { AllPages } = this.state;
        if (page < 1 || page > AllPages) {
           message.warning("Please enter the correct page number");
          return;
        } else {
          this.setState(
            {
              currentPage: page
            },
            () => {
              this.changePage(this.state.currentPage);
            }
          );
        }

      }
    }

  }
  toFirst = e => {
    this.setState(
      {
        currentPage: 1
      },
      () => {
        this.changePage(this.state.currentPage);
      }
    );
  };

  toPre = e => {
    if (this.state.currentPage === 1) {
      return;
    } else {
      this.setState(
        {
          currentPage: this.state.currentPage - 1
        },
        () => {
          this.changePage(this.state.currentPage);
        }
      );
    }
  };

  toNext = e => {
    if (this.state.currentPage === this.state.AllPages) {
      return;
    } else {
      this.setState(
        {
          currentPage: this.state.currentPage + 1
        },
        () => {
          this.changePage(this.state.currentPage);
        }
      );
    }
  };

  CheckAll = e => {
    let { resultLists, checkedObj, disabledObj, parentObj } = this.state;
    let parentIdObj = {};
    if (JSON.stringify(parentObj) !== "{}") {
      parentIdObj = {
        [parentObj.id]: parentObj.id
      };
    }
    let allDisabledObj = Object.assign({}, disabledObj, parentIdObj);
    this.setState({
      disabledObj: allDisabledObj
    });
    // if(JSON.stringify(disabledObj)!=="{}"){
    //   this.setState({
    //     isCheckAll:false
    //   })
    //   return
    // }

    if (e.target.checked === true) {
      this.setState(
        {
          isCheckAll: true
        },
        () => {
          resultLists.map((item, index) => {
            if (allDisabledObj[item.id] === undefined) {
              checkedObj[item.id] = item;
            }
          });
          this.setState(
            {
              checkedObj
            },
            () => {
              let { checkedObj, resultLists } = this.state;
              if(this.props.changeCheckSelected){
                this.props.changeCheckSelected(checkedObj, resultLists);
              }

              
            }
          );
        }
      );
    } else {
      this.setState(
        {
          isCheckAll: false
        },
        () => {
          this.setState(
            {
              checkedObj: {}
            },
            () => {
              let { checkedObj, resultLists } = this.state;
              if( this.props.changeCheckSelected){
                this.props.changeCheckSelected(checkedObj, resultLists);
              }
              }
           
          );
        }
      );
    }
  };

  itemCheckbox = (item, index, event, combinationName) => {
    if (this.props.location.search) {
      this.setState(
        {
          isSearch: true
        },
        () => {
          // this.props.history.push('')
        }
      );
    } else {
      this.setState({
        isSearch: false
      });
    }

    let { resultLists, checkedObj, fossilistObj } = this.state;

    if (event.target.checked === true) {
      checkedObj[item.id] = item;
    } else if (
      event.target.checked === false &&
      checkedObj[item.id]
    ) {
      delete checkedObj[item.id];
    }

    let checkedLength = Object.keys(checkedObj).length;
    this.setState(
      {
        isCheckAll: checkedLength === resultLists.length
      },
      () => {
        if (this.props.checkOne) {
          // if(fossilistObj.hasOwnProperty(item.id)){
          //   Modal.info({
          //     content:'This resource has been added,Please re-select'
          //   })
          //   return
          // }

          this.props.checkTaxonomyOne(item, combinationName);
        } else {
          let { checkedObj, resultLists } = this.state;
          if( this.props.changeCheckSelected){
            this.props.changeCheckSelected(checkedObj, resultLists);
          }
          
        }
      }
    );
  };

  getDetail = detailItem => {
    this.props.getDetail(detailItem);
  };
  resetCheckData=()=>{
    this.setState({
      currentPage: 1,
      isCheckAll: false,
      askLoading:undefined,
      checkedObj:{}
    })
  }
  render() {
    let {
      currentPage,

      AllPages,
      isCheckAll,
      checkedObj,

      checkOne,
      disableCheck,
      ChildIdsObj,
      parentObj,
      disabledObj,
      resultLists,
      embellish
    } = this.state;

    return (
      <div id="SearchResult" className="SearchResult-refsearch">
        {/*  taxResult-main */}
        <div className="">
          <div className="content">
            {resultLists.length > 0 &&this.state.askLoading==false? (
              <table>
                <tbody>
                  <tr className="head">
                    {this.state.showSiblings?
                          null
                          :
                    checkOne && disableCheck === false ? (
                      <td></td>
                    ) : disableCheck === true ? null : (
                      <td className="check">
                        <Checkbox
                          onChange={e => this.CheckAll(e, "id")}
                          checked={isCheckAll == true}
                          title="Some resources have been added"
                        ></Checkbox>
                      </td>
                    )}

                    <td>Taxon ID</td>
                    <td>Fossil group</td>
                    <td className="ref-id">Taxon type</td>
                    <td>Taxon name</td>
                    <td>Described in</td>
                    <td>Enterer</td>
                    <td>Details</td>

                    {/* <td>Details</td> */}
                  </tr>

                  {resultLists.map((item, index) => {
                    let taxonName1 = item.taxon_name1;
                    let taxonName2 = item.taxon_name2;
                    let genusPrefix = embellish ? embellish.genusPrefixV : "";
                    let spPrefix1 = embellish ? embellish.spPrefix1V : "";
                    let spPrefix2 = embellish ? embellish.spPrefix2V : "";
                    let spNameDict = embellish ? embellish.spDictV : "";
                    let spSuffix = embellish ? embellish.spSuffixV : "";
                    let Name = Utils.fullName(
                      taxonName1,
                      taxonName2,
                      genusPrefix,
                      spPrefix1,
                      spPrefix2,
                      spNameDict,
                      spSuffix
                    );
                    if (
                      ChildIdsObj.hasOwnProperty(item.id) ||
                      parentObj.id === item.id
                    ) {
                      disabledObj[item.id] = item.id;
                    }
                    return (
                      <tr key={item.id} className="row">
                        {
                          this.state.showSiblings?
                          null
                          :
                        !disableCheck && checkOne ? (
                          <td className="check">
                            <Radio
                              name={(item.id).toString()}
                              datavalue={item}
                              onChange={event =>
                                this.itemCheckbox(item, index, event, Name)
                              }
                              checked={checkedObj.hasOwnProperty(item.id)}
                            ></Radio>
                          </td>
                        ) : !disableCheck && !checkOne ? (
                          <td className="check">
                            <Checkbox
                              name={(item.id).toString()}
                              datavalue={item}
                              onChange={event =>
                                this.itemCheckbox(item, index, event, Name)
                              }
                              checked={checkedObj.hasOwnProperty(item.id)}
                              disabled={
                                ChildIdsObj.hasOwnProperty(item.id) ||
                                parentObj.id === item.id
                              }
                            ></Checkbox>
                          </td>
                        ) : null}

                        <td>{item.id}</td>
                        <td>{item.fossil_group}</td>

                        <td>{item.taxon_type}</td>
                        <td>{Name}</td>
                        <td>{`(ID = ${item.ref_id})`}</td>
                        <td>{item.enterer_username}</td>

                        <td className="btn">
                          <Button
                            type="gbdb"
                            onClick={() => this.getDetail(item)}
                          >
                            <div>Details</div>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )   
            :
            this.state.askLoading==true?
            (
              <div className="tablelist-no-resource"> <div id="allResult">
                      <div className="allLoader">
                        <div className="loader-inner ball-spin-fade-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <span>Loading...</span>
                      </div>
                    </div></div>
            )
            :
            resultLists.length ===0 &&this.state.askLoading==false?
            (
              <div className="tablelist-no-resource">No Resource</div>
            )
            :
            <div className="tablelist-no-resource"></div>
          }
          </div>
          {resultLists.length > 0 ? (
            <div className="footer">
              <div className="first" onClick={this.toFirst}>
                First
              </div>
              <div className="pre" onClick={this.toPre}>
                Pre
              </div>
              <div className="pages">{`${currentPage}/${AllPages}pages`}</div>
              <div className="next" onClick={this.toNext}>
                Next
              </div>

              <div className="page-input">
              <InputNumber
                  className="searchResult-select"
                  onKeyDown={this.gotoPage}
                  min={1}
                ></InputNumber>
                  <span className="goto" onClick={this.gotoPages}>Goto</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default TaxonomyTableResult;
