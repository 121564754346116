/* eslint-disable  */
import React from "react";
import { Select } from "antd";
import moment from "moment";
import Storage from "src/js/storage";
import axios from "axios";
import userApis from "src/js/apis/user";
import "src/js/global.js";
export default {
  userInputer(userType, authState) {
    let isInputer = false;
    if (userType > 0) {
      isInputer = true;
      if (userType == 1 && authState == 4) {
        isInputer = false;
      }
       if (userType == 1 && authState ==1) {
        isInputer = false;
      }
       if (userType == 2 && authState == 2) {
        isInputer = false;
      }
      if (userType == 2 && authState == 5) {
        isInputer = false;
      }
    }

    return isInputer;
  },
  async isGbdbInputer() {
    //
    let allUserData = {};
    let isInputer = false;
    let token = Storage.getItem("token");
    if (token) {
      let params = {
        token,
        loginby: 1
      };
      let fossilBack = await userApis.initUser(params);
      if (fossilBack.result) {
        let updateUser = {
          userid: fossilBack.result.id,
          loginby: 1
        };
        Storage.setItem({
          name: "fossilid",
          value: fossilBack.result.id
        });
        let gbdbUserBack = await userApis.gbdbUser(updateUser);
        if (gbdbUserBack.result) {
          let gbdbData = gbdbUserBack.result;
          let baseData = fossilBack.result;
          Object.assign(allUserData, gbdbData, baseData);
          Storage.setItem({
            name: "gbdbid",
            value: gbdbData.id
          });
          Storage.setItem({
            name: "checkerid",
            value: gbdbData.checker
          });
          Storage.setItem({
            name: "checkerName",
            value: gbdbData.checker_name
          });

          let { userType, authState } = allUserData;
      

          isInputer = this.userInputer(userType, authState);

          if (isInputer == true) {
            Storage.setItem({
              name: "Inputerid",
              value: gbdbData.id
            });
          } else {
            Storage.removeItem("Inputerid");
          }
        } else {
          Storage.removeItem("token");
          Storage.removeItem("fossilid");
          Storage.removeItem("gbdbid");
          Storage.removeItem("gbdblevel");
          Storage.removeItem("checkerid");
          Storage.removeItem("checkerName");
          Storage.removeItem("Inputerid");
        }
      } else {
        Modal.error({
          title: "This is an error message",
          content: fossilBack.error
        });
        Storage.removeItem("token");
        Storage.removeItem("fossilid");
        Storage.removeItem("gbdbid");
        Storage.removeItem("checkerid");
        Storage.removeItem("checkerName");
        Storage.removeItem("gbdblevel");
        Storage.removeItem("Inputerid");
      }
    } else {
      Storage.removeItem("token");
      Storage.removeItem("fossilid");
      Storage.removeItem("gbdbid");
      Storage.removeItem("checkerid");
      Storage.removeItem("checkerName");
      Storage.removeItem("gbdblevel");
      Storage.removeItem("Inputerid");
    }
    
    return isInputer;
  }
};
