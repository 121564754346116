import React, { Component } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import "./index.css";


export default class Research extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey:
        this.props.location.pathname.split("/")[
          this.props.location.pathname.split("/").length - 1
        ] || "case"
    };
  }
  addKey = e => {
    this.setState(
      {
        currentKey: e.key
      },
      () => {
      }
    );
  };
  render() {
    let { currentKey } = this.state;
    return (
      <div id="reference-search" className="form-contents">
        <div className="reference-search-operate research-all-title">
          <p>Research</p>
        </div>
        <div className="reference-header" id="referenceMenu">
          <Menu
            defaultSelectedKeys={currentKey}
            mode="horizontal"
            onClick={e => this.addKey(e)}
            className="reference-search-menu reference-menu"
            selectedKeys={currentKey}
          >
            <Menu.Item key="case">
              <div>
                {/* <NavLink to="/research/case"> Case study </NavLink> */}
                <NavLink to="/research/case">Research </NavLink>
              </div>
            </Menu.Item>
            <Menu.Item key="publication">
              <div>
                <NavLink to="/research/publication"> Publications </NavLink>
              </div>
            </Menu.Item>

            <Menu.Item key="people">
              <div>
                <NavLink to="/research/people"> People </NavLink>
              </div>
            </Menu.Item>

            <Menu.Item key="funding">
              <div>
                <NavLink to="/research/funding"> Funding </NavLink>
              </div>
            </Menu.Item>
            
            <Menu.Item key="transform">
              <div>
                <NavLink to="/research/transform"> Transform </NavLink>
              </div>
            </Menu.Item>

            <Menu.Item key="vis">
              <div>
                <NavLink to="/research/vis"> Spatiotemporal Vis</NavLink>
              </div>
            </Menu.Item>

            {/* <Menu.Item key="download">
              <div>
                <NavLink to="/research/download"> Download</NavLink>
              </div>
            </Menu.Item> */}
          </Menu>
        </div>

        <div className="reference-search-main form-mian-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}
