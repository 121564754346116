import React, { Component } from "react";
import { Dropdown, Menu, Input, Modal, Form, Button , Select,} from "antd";
import { NavLink } from "react-router-dom";
import userApis from "src/js/apis/user";
import Storage from "src/js/storage";
import "src/js/global.js";
import axios from "axios";
import "./index.css";
import "./index.scss";
import Login from "commons/Login/index";
import Register from "commons/Register/index";
import { withRouter } from "react-router-dom";

const { confirm } = Modal;

const { Search } = Input;

const menu = (
  <Menu className="h5-head-menu">
    <Menu.Item>
     <a href="http://fossil-ontology.com/" target="_blank" rel="">Fossil-ontology</a>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/research/case"> Research </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/search/reference"> Search </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/home"> Home </NavLink>
    </Menu.Item>
    {/* <Menu.Item>
      <NavLink to="/center"> Center </NavLink>
    </Menu.Item> */}
    {/* <Menu.Item>
      <NavLink to="/profile"> Profile </NavLink>
    </Menu.Item> */}
    {/* <Menu.Item>
      <NavLink to="/subset"> Subset </NavLink>
    </Menu.Item> */}
    {/* <Menu.Item>
      <NavLink to="/group/list"> Group </NavLink>
    </Menu.Item> */}
    <Menu.Item>
      <NavLink to="/news/list"> News </NavLink>
    </Menu.Item>
    {/* <Menu.Item>
      <NavLink to="/message"> Message </NavLink>
    </Menu.Item> */}
    {/* <Menu.Item>
      <NavLink to="/questions"> Questions </NavLink>
    </Menu.Item> */}
  </Menu>
);
let userInputer = (userType, authState) => {
  let isInputer = false;
  if (userType > 0) {
    isInputer = true;
    if (userType == 1 && authState == 4) {
      isInputer = false;
    }
    if (userType == 2 && authState == 5) {
      isInputer = false;
    }
  }

  return isInputer;
};
@withRouter
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLogin: false,
      device: 1,
      showLoginModal: false,
      headerRegisterModal: false,
      userData: {},
      urlName: ""
    };
  }

  userLogin = () => {
    this.setState({
      showLoginModal: true
    });
  };
  signUp = () => {
    this.setState({
      showLoginModal: false,
      headerRegisterModal: true
    });
  };

  handleLoginCancel = () => {
    this.setState({
      showLoginModal: false
    });
  };
  handleRegisterCancel = () => {
    this.setState({
      headerRegisterModal: false
    });
  };
  bottom() { }
  componentWillReceiveProps(nextProps) {
    let token = Storage.getItem("token");
    if (token === undefined) {
      this.setState({
        userData: {}
      });
    }
    // if (this.props.location !== nextProps.location) {

    //   setTimeout(() => {
    //     window.scrollTo(0, 0);
    //   }, 16);
    // }
  }
  fossilSuccess = loginBack => {
    let allUserData = {};
    this.setState(
      {
        userData: loginBack.result,
        showLoginModal: false
      },
      async () => {
        let updateUser = {
          userid: loginBack.result.id,
          loginby: 1
        };
        let gbdbUserBack = await userApis.gbdbUser(updateUser);
        if (gbdbUserBack.result) {
          let gbdbData = gbdbUserBack.result;
          let baseData = loginBack.result;
          Object.assign(allUserData, gbdbData, baseData);
          let isInputer = false;
          let { userType, authState } = allUserData;

          isInputer = userInputer(userType, authState);

          if (isInputer == true) {
            Storage.setItem({
              name: "Inputerid",
              value: gbdbData.id
            });
            if (window.LoginModify) {
              window.LoginModify("isInputer");
            }
          } else {
            Storage.removeItem("Inputerid");
          }
          Storage.setItem({
            name: "gbdbid",
            value: gbdbData.id
          });
          Storage.setItem({
            name: "checkerid",
            value: gbdbData.checker
          });
          Storage.setItem({
            name: "checkerName",
            value: gbdbData.checker_name
          });
          Storage.setItem({
            name: "gbdblevel",
            value: gbdbData.level
          });

          this.setState(
            {
              userData: allUserData
            },
            () => { }
          );
        } else {
          let urlName = this.props.location.pathname.split("/")[1];
          if (global.constants.notGoHome.includes(urlName) == false) {
            this.props.history.push("/home");
          }
        }
      }
    );
  };
  
  async componentDidMount() {
    this.bottom();

    //
    let allUserData = {};
    let token = Storage.getItem("token");
    if (token) {
      axios.defaults.headers.token = token || ""; // 全局axios 设置请求头token
      let params = {
        token,
        loginby: 1
      };
      let fossilBack = await userApis.initUser(params);
      if (fossilBack.result) {
        let updateUser = {
          userid: fossilBack.result.id,
          loginby: 1
        };
        Storage.setItem({
          name: "fossilid",
          value: fossilBack.result.id
        });
        let gbdbUserBack = await userApis.gbdbUser(updateUser);
        if (gbdbUserBack.result) {
          let gbdbData = gbdbUserBack.result;
          let baseData = fossilBack.result;
          Object.assign(allUserData, gbdbData, baseData);
          Storage.setItem({
            name: "gbdbid",
            value: gbdbData.id
          });
          Storage.setItem({
            name: "checkerid",
            value: gbdbData.checker
          });
          Storage.setItem({
            name: "checkerName",
            value: gbdbData.checker_name
          });
          let isInputer = false;
          let { userType, authState } = allUserData;

          isInputer = userInputer(userType, authState);

          if (isInputer == true) {
            Storage.setItem({
              name: "Inputerid",
              value: gbdbData.id
            });
          } else {
            Storage.removeItem("Inputerid");
          }
        } else {
          Storage.removeItem("token");
          Storage.removeItem("fossilid");
          Storage.removeItem("gbdbid");
          Storage.removeItem("gbdblevel");
          Storage.removeItem("checkerid");
          Storage.removeItem("checkerName");
          Storage.removeItem("Inputerid");
          let urlName = this.props.location.pathname.split("/")[1];
          if (global.constants.notGoHome.includes(urlName) == false) {
            this.props.history.push("/home");
          }
        }

        this.setState(
          {
            userData: allUserData
          },
          () => { }
        );
      } else {
        // if (fossilBack.state === 204) {
        //   Storage.removeItem("token");
        //   Storage.removeItem("fossilid");
        //   Storage.removeItem("gbdbid");
        //   Storage.removeItem("checkerid");
        //   Storage.removeItem("checkerName");
        //   Storage.removeItem("gbdblevel");
        //   this.setState({
        //     userData: {}
        //   });
        //   return;
        // }
        Modal.error({
          title: "This is an error message",
          content: fossilBack.error
        });
        Storage.removeItem("token");
        Storage.removeItem("fossilid");
        Storage.removeItem("gbdbid");
        Storage.removeItem("checkerid");
        Storage.removeItem("checkerName");
        Storage.removeItem("gbdblevel");
        Storage.removeItem("Inputerid");
        this.setState(
          {
            userData: {}
          },
          () => {
            let urlName = this.props.location.pathname.split("/")[1];
            if (global.constants.notGoHome.includes(urlName) == false) {
              this.props.history.push("/home");
            }
          }
        );
      }
    } else {
      Storage.removeItem("token");
      Storage.removeItem("fossilid");
      Storage.removeItem("gbdbid");
      Storage.removeItem("checkerid");
      Storage.removeItem("checkerName");
      Storage.removeItem("gbdblevel");
      Storage.removeItem("Inputerid");
      this.setState({
        userData: {}
      });
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        this.props.history.push("/home");
      }
    }
  }
  goHome = () => {
    this.props.history.push("/home");
  };

  goRefSearch = () => {
    let urlName = this.props.location.pathname.split("/")[1];
    if (urlName == "search") {
      return;
    }
    this.props.history.push("/search/reference");
  };
  goPage = page => {
    this.props.history.push(page);
  };

  goSearchMap=(searchName)=>{
    //this.refs.searchBar.input.state.value='';
    this.props.history.push({ pathname: "/home", searchName: searchName });
  };

  logout = e => {
    Storage.removeItem("token");
    Storage.removeItem("fossilid");
    Storage.removeItem("gbdbid");
    Storage.removeItem("checkerid");
    Storage.removeItem("checkerName");
    Storage.removeItem("gbdblevel");
    Storage.removeItem("Inputerid");
    this.setState({
      userData: {}
    });
    // Modal.info({
    //   title: "This is a notification message",
    //   content: (
    //     <div>
    //       <p> You're logged out</p>
    //     </div>
    //   )
    // });
    setTimeout(() => {
      // window.location.reload();
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        this.props.history.push("/home");
      }

      if (urlName == "references") {
        if (window.LoginModify) {
          window.LoginModify("Inputerout");
        }
      }
    }, 80);
  };
  userSignOut = e => {
    e.preventDefault();
    let that = this;
    confirm({
      title: "Do you Want to sign out?",
      onOk() {
        that.logout();
      },
      onCancel() { }
    });
  };
  registerSuccess = () => {
    this.setState(
      {
        headerRegisterModal: false
      },
      () => {
        Modal.info({
          title: "This is a notification message",
          content: (
            <div>
              <p>Go to email for authentication</p>
            </div>
          ),
          onOk() { }
        });
      }
    );
  };
  registerGoLogin = (email, password) => {
    this.setState(
      {
        headerRegisterModal: false,
        showLoginModal: true
      },
      () => {
        setTimeout(() => {
          this.refs.login.setFieldsValue({
            email: email,
            password: password
          });
        }, 30);
      }
    );
  };

  onLogin = loginRef => {
    this.loginchild = loginRef;
  };
  onRegister = registerRef => {
    this.registerchild = registerRef;
  };
  aboutMenu = () =>{
    return(
      <Menu className="header-resource">  
        <Menu.Item key="news">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/news/list");
            }}
          >
            <span className="Resources">
              News
            </span>
          </a>
        </Menu.Item>
        <Menu.Item key="research">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/about/research");
            }}
          >
            <span className="Resources">
              Research
            </span>
          </a>
        </Menu.Item>
        <Menu.Item key="publications">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/about/publication");
            }}
          >
            <span className="Resources">
              Publications
            </span>
          </a>
        </Menu.Item>
        <Menu.Item key="people">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/about/people");
            }}
          >
            <span className="Resources">
              People
            </span>
          </a>
        </Menu.Item>
        <Menu.Item key="funding">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/about/funding");
            }}
          >
            <span className="Resources">
              Funding
            </span>
          </a>
        </Menu.Item>
        <Menu.Item key="transform">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/about/transform");
            }}
          >
            <span className="Resources">
              Transform
            </span>
          </a>
        </Menu.Item>
        <Menu.Item key="vis">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/about/vis");
            }}
          >
            <span className="Resources">
              Spatiotemporal Vis
            </span>
          </a>
        </Menu.Item>
      </Menu>
    );
    
  };
  resourcesMenu = () =>{
    return(
      <Menu className="header-resource">
        <Menu.Item key="fossil_ontology">
        <a href="http://fossil-ontology.com/" target="_blank" rel="">
          <span className="Resources" style={{width:600}}>
            Fossil-ontology
          </span>
        </a>
        </Menu.Item>
        <Menu.Item key="home">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/insects/search");
            }}
          >
            <span className="Resources">
              Paleoentomology Database
            </span>
          </a>
        </Menu.Item>
        <Menu.Item key="home">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/relatedSoftware");
            }}
          >
            <span className="Resources">
              Related Software
            </span>
          </a>
        </Menu.Item>
      </Menu>
    );
  };
  pcMenu = () => {
    let {userData}=this.state;
    return (
      <Menu className="user-login-res">
        <Menu.Item key="home">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/home");
            }}
          >
            <span className="Resources">Home</span>
          </a>
        </Menu.Item>
        {/* <Menu.Item key="datas">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/references/search/reference");
            }}
          >
            <span className="datas">Add data</span>
          </a>
        </Menu.Item> */}
        <Menu.Item key="center">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/center");
            }}
          >
            <span className="Resources">Center</span>
          </a>
        </Menu.Item>

        <Menu.Item key="profile">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/profile/basic");
            }}
          >
            <span>
              <span className="Resources">Profile</span>
            </span>
          </a>
        </Menu.Item>
        <Menu.Item key="subset">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/subset");
            }}
          >
            <span className="Message">Subset</span>
          </a>
        </Menu.Item>
        <Menu.Item key="group">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/group/list");
            }}
          >
            <span className="Message">Group</span>
          </a>
        </Menu.Item>

        <Menu.Item key="news">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/news/list");
            }}
          >
            <span className="News">News</span>
          </a>
        </Menu.Item>
        <Menu.Item key="message">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/message");
            }}
          >
            <span className="Message">Message</span>
          </a>
        </Menu.Item>
        <Menu.Item key="feedback">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/feedback");
            }}
          >
            <span className="Message">Feedback</span>
          </a>
        </Menu.Item>

        {userData && (userData.level === 1 || userData.level === 2 || userData.level === 3) ? 
          <Menu.Item key="Audit">
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                this.goPage("/audit/datas/section");
              }}
            >
              <span className="Message">Audit</span>
            </a>
          </Menu.Item>
         : null}

        <Menu.Item key="research" className="h5-header-titles">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/research/case");
            }}
          >
            <span className="Search">Research</span>
          </a>
        </Menu.Item>
        <Menu.Item key="search" className="h5-header-titles">
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              this.goPage("/search/reference");
            }}
          >
            <span className="Search">Search</span>
          </a>
        </Menu.Item>
        <Menu.Item key="fossil" className="h5-header-titles">
          <a href="http://fossil-ontology.com/" target="_blank" rel="">Fossil-ontology</a>
        </Menu.Item>

        <Menu.Item key="3">
          <a
            href="#"
            onClick={e => {
              this.userSignOut(e);
            }}
          >
            <span>
              <span className="Resources">Sign Out</span>
            </span>
          </a>
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    let { userData } = this.state;
    // console.log("userstate:"+userData.level);
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        userData
      })
    );

    return (
      <div id="main" className="web-main">
        <div className="common-header clearfix">
          <div className="head-icon" onClick={() => this.goHome()}>
            <div className="header-img">
              <img src={require("src/assets/images/header/logo.png")} alt="icon" />
            </div>

            <div className="title">
              <p>Geobiodiversity</p>
              <p>Database</p>
            </div>

            <div className="name">
              <p>地球生物多</p>
              <p>样性数据库</p>
            </div>
            
          </div>
          <div className="head-nav my-home-header">
            {/* <div className="header-fossil">
              <a href="http://fossil-ontology.com/home" target="_blank" rel="">Paleomap</a>
            </div> */}
            <div className="header-fossil">
              <NavLink to="/search/reference">Search</NavLink>
            </div>
            <div className="header-fossil">
              <NavLink to="/references/search/reference">Add Data</NavLink>
            </div>
            <div className="header-fossil">
              <NavLink to="/download/main"> Download </NavLink>
            </div>
            <div className="entrance-search-item">
              <Search ref="searchBar" placeholder="sections" onSearch={(value)=>{this.goSearchMap(value)}} onKeyPress={(e,value)=>{if(e.keycode===13){this.goSearchMap(value)}}}/>
              {/* <Search placeholder="" onFocus={() => this.goRefSearch()} /> */}
            </div>
            {/* <div className="header-research">
              <NavLink to="/research/case"> Research </NavLink>
            </div> */}

            <Dropdown overlay={this.aboutMenu()}>
              <span className="header-resource-font">
              About
              </span>
            </Dropdown>
            {/* <div className="entrance-search-item header-research ">
              <div className="header-fossil">
                <NavLink to="/about"> About </NavLink>
              </div>
            </div> */}
            <Dropdown overlay={this.resourcesMenu()}>
              <span className="header-resource-font">
                Resources
              </span>
            </Dropdown>
          </div>
          {JSON.stringify(userData) === "{}" ? (
            <div className="mobile">
              <Dropdown overlay={menu} placement="bottomRight">
                <a className="ant-dropdown-link" href="#">
                  <span className="iconfont icon-3401caidan"></span>
                </a>
              </Dropdown>
            </div>
          ) : null}

          {JSON.stringify(userData) !== "{}" ? (
            <div>
            
            

            <div className="header-icon">
              <Dropdown overlay={this.pcMenu()}>
                <span className="login-user">
                  {userData.thumbnail ? <img src={"/api" + userData.thumbnail} alt="avatar" /> : <img src={require("src/assets/images/user/avator.png")} alt="icon" />}
                </span>
              </Dropdown>
              <span className="gbdb-user-name" title={userData.username ? userData.username : ""} onClick={e => {
                e.preventDefault();
                this.goPage("/center");
              }}>
                {userData.username ? userData.username : ""}
              </span>

            

            </div>
            {/* <span className="old-version old-version-item">
                <a href="http://www.geobiodiversity.com:8081/" target="_blank" rel="">old version</a>
              </span> */}
            </div>
          ) : (
              <div className="entrance-user">
                {/* <span className="iconfont icon-xxhdpiShape"></span> */}
                {/* <span className="old-version-item">
                  <a href="http://www.geobiodiversity.com:8081/" target="_blank" rel="">old version</a>
                </span> */}
                <span onClick={() => this.signUp()}>Sign up</span>
                <span onClick={() => this.userLogin()} className="user-mainheader-login">Sign in</span>
              </div>
            )}
        </div>

        <div className="home-main-childs">{childrenWithProps}</div>

        <div className="common-footer">
          <div className="common-footer-admin common-footer-title">
            {userData && (userData.level === 1 || userData.level === 2 ) ? <NavLink to="/admin/datas/section">Admin</NavLink> : null}
          </div>
          <div className="common-footer-content">
            <p>
              &copy; Nanjing Institute of Geology and Palaeonotology.All Rights Reserved.
              <br />
              {/* <p onClick={()=>{window.open("https://beian.miit.gov.cn/")}}>苏ICP备05063896号-3</p> */}
              <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备05063896号-3</a>
            </p>
          </div>
          <div className="common-footer-about common-footer-title">About</div>
        </div>
        {/* 注册 */}
        <Modal className="user-login-modal register-modal" width="25%" title="Sign up" visible={this.state.headerRegisterModal} onCancel={this.handleRegisterCancel} footer={null} destroyOnClose>
          <Register ref="register" onRegister={this.onRegister} registerSuccess={this.registerSuccess} registerGoLogin={(email, password) => this.registerGoLogin(email, password)}></Register>
        </Modal>

        {/* 登录 */}
        <Modal
          className="user-login-modal"
          width="25%"
          title="Sign in"
          visible={this.state.showLoginModal}
          onCancel={this.handleLoginCancel}
          destroyOnClose
          footer={
            <div className="login-footer">
              No account?
              <a onClick={() => this.signUp()}>Sign up for free</a>
            </div>
          }
        >
          <Login ref="login" onLogin={this.onLogin} fossilSuccess={this.fossilSuccess}></Login>
        </Modal>
      </div>
    );
  }
}

Main = Form.create({})(Main);
export default Main;
