import React, { Component } from "react";
import { Button, Modal } from "antd";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";

import OccurenceOption from "commons/AllOptions/Occurence/index.js";
import OccurenceTableList from "commons/AllTableList/Occurence/index.js";

import Apis from "src/js/apis/api";
import Utils from "src/js/utils";
import "src/js/global.js";
import "./index.css";

class OccurenceSearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResultVisible: false,

      detailData: {},
      showDetailForm: false,
      taxType: "Original",
      isCheckAll: false,
      checkedObj: {},
      resultLists: [],
      defaultCheckedList: [],
      allChildError: {},
      selectDuration: { erathem_max: [], period_max: [], epoch_max: [], early_interval: [] },
      showBackIcon:false
    };
  }
  async componentDidMount() {
    let host = window.location.host;
    
    // let host2 = document.domain;
    let historyLength = this.props.history.length
    if (historyLength > 1) {
      this.setState({
        showBackIcon: true
      })
    }
    if (this.props.location.search) {
      let inheritRefIds =
        Utils.getSearch(this.props.location.search, "refId") || "";
      let searchParams = {
        ref_id: inheritRefIds
      };
      let searchAllValues = {
        ref_id_from: Number(inheritRefIds),
        ref_id_to: Number(inheritRefIds)
      };
      this.setState(
        {
          searchParams,
          searchAllValues
        },
        () => {
          let { searchParams, searchAllValues } = this.state;
          setTimeout(() => {
            this.occurenceChild.searchOccurenceDatas(
              searchParams,
              searchAllValues
            );
          }, 20);
        }
      );
    }
    if (this.props.location.query) {
      const { searchParams, searchAllValues,selectDuration } = this.props.location.query;
  
     
      this.setState(
        {
          searchParams,
          searchAllValues,
          selectDuration
        },
        () => {
     
          let { searchParams, searchAllValues } = this.state;

          setTimeout(() => {
           
            this.occurenceChild.searchOccurenceDatas(
              searchParams,
              searchAllValues
            );
          }, 200);
        }
      );
    }
  }
  detailFormOk = () => {
    this.setState({
      showDetailForm: false
    });
  };
  detailFormCancel = () => {
    this.setState({
      showDetailForm: false
    });
  };
 
  handFormOk = () => {
    this.setState({
      ResultVisible: false
    });
  };
  handleFormCancel = () => {
    this.setState({
      ResultVisible: false
    });
    Modal.destroyAll();
  };

  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(
      async (err, searchAllValues) => {
        if (!err) {
          let searchParams = Utils.filterParams(searchAllValues);

          searchParams = Utils.filterCondition(searchParams);
          let {selectDuration}=this.state
          if(selectDuration.erathem_max.length>0){
            searchParams.erathem_max=selectDuration.erathem_max
          }
          if(selectDuration.period_max.length>0){
            searchParams.period_max=selectDuration.period_max
          }
          if(selectDuration.epoch_max.length>0){
            searchParams.epoch_max=selectDuration.epoch_max
          }
          if(selectDuration.early_interval.length>0){
            searchParams.early_interval=selectDuration.early_interval
          }

          this.setState(
            {
              ResultVisible: false,
              searchParams,
              searchAllValues,
              optionType
            },
            () => {
         
              let { searchParams, searchAllValues } = this.state;

              setTimeout(() => {
                this.occurenceChild.searchOccurenceDatas(
                  searchParams,
                  searchAllValues
                );
              }, 20);
            }
          );
        }
      }
    );
  };
  showOptions = () => {
    this.setState({
      ResultVisible: true
    },()=>{
      setTimeout(() => {
        if(this.occOptionChild.changeDurationParent){
          this.occOptionChild.changeDurationParent(this.state.selectDuration)
        }
      }, 20);
            
    
    });
  };

  getDetail = itemValue => {
    this.setState(
      {
        detailData: itemValue
        // showDetailForm: true
      },
      async () => {
        let url = "/references/allresult/section?";
        let refId = itemValue.ref_id;
        let refUrl = refId ? "refId=" + refId : "";

        let baseId = itemValue.section_basic_id;
        let baseIdUrl = baseId ? "&baseId=" + baseId : "";

        let formationId = itemValue.formation_id;
        let formationUrl = formationId ? "&formationId=" + formationId : "";

        let unitId = itemValue.unit_id;
        let unitUrl = unitId ? "&unitId=" + unitId : "";

        let collId = itemValue.collection_id;
        let collUrl = collId ? "&collId=" + collId : "";

        let fossilId = itemValue.id;
        let fossilOccurnceUrl = fossilId
          ? "&fossilId=" + fossilId
          : "";

        if (unitId) {
          let params = { unit_id: unitId };
          let searchResult = await Apis.getUnits(params);
          if (searchResult.result && searchResult.result.length > 0) {
            formationId = searchResult.result[0].formation_id;
            formationUrl = formationId ? "&formationId=" + formationId : "";
          }
        }

        let params = `${url}${refUrl}${baseIdUrl}${formationUrl}${unitUrl}${collUrl}${fossilOccurnceUrl}`;
        const w=window.open('about:blank');
        w.location.href=params

        // this.props.history.push(params);
      }
    );

    //点击 detail 按扭执行代码
  };
  searchSection = () => {
    this.setState(
      {
        showDetailForm: false
      },
      () => {
        let { detailData } = this.state;
        let searchParams = {
          section_basic_id: detailData.section_basic_id
        };
        let url = "/searchs/result/section/base";
        this.props.history.push({ pathname: url, query: searchParams });
      }
    );
  };
  goHistoryBack = () => {
    this.props.history.push("/search/occurence");
    // this.props.history.goBack();
  };
  onOccurence = ref => {
    this.occurenceChild = ref;
  };
  OccurenceOptionChild= ref => {
    this.occOptionChild = ref;
  };
  changeCheckSelected = checkedObj => {
    this.setState({
      checkedObj
    });
  };

  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  changeDuration= selectDuration => {
   
    this.setState({
      selectDuration
    },()=>{
    
    });
  };
  render() {
    let { searchParams, searchAllValues } = this.state;

    return (
      <div id="SearchResult">
        <div className="SearchResult-main reference-search-form">
          <div className="head">
          {/* {
             this.state.showBackIcon?
             <span
             className="iconfont icon-back"
             onClick={this.goHistoryBack}
           ></span>
           :
           null
           } */}

            <span className="head-title">Occurrence Search Result</span>
          </div>

          <div className="option-btn">
            <Button type="gbdb">
              <div
                className="result-search-option"
                onClick={() => this.showOptions()}
              >
                Search option
              </div>

              <div id="searchModel">
                <Modal
                  className="searchResultModal"
                  width="76%"
                  visible={this.state.ResultVisible}
                  footer=""
                  // closable={false}
                  onOk={this.handFormOk}
                  onCancel={this.handleFormCancel}
                >
                  <OccurenceOption
                    showBase={true}
                    showGeography={true}
                    showUnit={true}
                    showFomation={false}
                    showOccurrence={true}
                    childError={this.childError}
                    searchAllValues={searchAllValues}
                    ref="occurence"
                    changeDuration={this.changeDuration}
                    OccurenceOptionChild={this.OccurenceOptionChild}
                  ></OccurenceOption>

                  {
                    <div className="referenceSearch-btns">
                      <div>
                        <Button
                          type="gbdb"
                          onClick={() => {
                            this.checkAgain("occurence");
                          }}
                        >
                          <p> Search</p>
                        </Button>
                      </div>
                    </div>
                  }
                </Modal>
              </div>
            </Button>
          </div>

          <OccurenceTableList
            searchParams={searchParams}
            searchAllValues={searchAllValues}
            onOccurence={this.onOccurence}
            getDetail={this.getDetail}
            changeCheckSelected={this.changeCheckSelected}
            showDeleteSubset={false}
            showAddSubset={true}
            showExportFile={true}
          ></OccurenceTableList>
        </div>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailFormOk}
          onCancel={this.detailFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
            searchSection={this.searchSection}
          ></ReferenceDetailForm>
        </Modal>
      </div>
    );
  }
}

export default OccurenceSearchResult;
