import React, { Component } from "react";
import OccurenceOption from "commons/AllOptions/Occurence/index.js";

import Utils from "src/js/utils";

import "./index.css";


import { Form, Button ,Modal} from "antd";

class SearchSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allChildError: {},
      selectDuration: { erathem_max: [], period_max: [], epoch_max: [], early_interval: [] },
    };
  }

  sectionOccurence = (type) => {
    this.refs[type].validateFieldsAndScroll(async (err, values) => {
    //   let { allChildError } = this.state;
    //   if (JSON.stringify(allChildError) !== "{}") {
    //     const modal = Modal.warn({
    //       content: "Please check the query form"
    //     });
    //     return;
    //   }
      if (!err) {
        let searchResults = Utils.filterParams(values);

        let queryCondition = Utils.filterCondition(searchResults);
       
        let {selectDuration}=this.state
        if(selectDuration.erathem_max.length>0){
          queryCondition.erathem_max=selectDuration.erathem_max
        }
        if(selectDuration.period_max.length>0){
          queryCondition.period_max=selectDuration.period_max
        }
        if(selectDuration.epoch_max.length>0){
          queryCondition.epoch_max=selectDuration.epoch_max
        }
        if(selectDuration.early_interval.length>0){
          queryCondition.early_interval=selectDuration.early_interval
        }
        


        // 注释

        if (queryCondition) {
          let queryParam = {
            searchParams: queryCondition,
            type: "section",
            searchAllValues: values,
            selectDuration:this.state.selectDuration
          };
          let url = "/searchs/result/occurence";
          this.props.history.push({ pathname: url, query: queryParam });
        }
      }
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  // 这个是处理Duration
  changeDuration= selectDuration => {
   
    this.setState({
      selectDuration
    },()=>{
   
    });
  };
  render() {
    return (
      <div id="searchSection">
        <div className="searchSection-main  reference-search-form search-common-form">
          <div className="search-common-title form-common-title">
          Occurrence search
          </div>
          <OccurenceOption
            showBase={true}
            showGeography={true}
            showUnit={true}
            showFomation={false}
            showOccurrence={true}
            childError={this.childError}
            ref="occurence"
            changeDuration={this.changeDuration}
          ></OccurenceOption>

          <div className="referenceSearch-btns">
            <div>
              <Button
                type="gbdb"
                onClick={() => {
                  this.sectionOccurence('occurence');
                }}
              >
                <p> Search</p>
                <p>Occurrence</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SearchSection = Form.create({})(SearchSection);
export default SearchSection;
