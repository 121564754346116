import asyncAxios from "src/js/asyncAxios";

export default {
  // reference
  // search
  getAssLabels: async params => {
    let url = "/api/basic/assLabels";

    let AssLabels = await asyncAxios.get(url, params);

    return AssLabels;
  },
 getTaxontypes: async params => {
    let url = "/api/basic/taxontypes";

    let Taxontypes = await asyncAxios.get(url, params);

    return Taxontypes;
  },
   fossilgroup: async params => {
    let url = "/api/basic/fossilgroup";

    let fossilgroup = await asyncAxios.get(url, params);
    return fossilgroup;
  },
     getTreatise: async params => {
    let url = "/api/basic/treatise";

    let Treatise = await asyncAxios.get(url, params);

    return Treatise;
  },
    autoCompleteInterval: async params => {
    let url = "/api/basic/autoCompleteInterval";

    let Interval = await asyncAxios.get(url, params);

    return Interval;
  },
      getGeochemistrytype: async params => {
    let url = "/api/basic/geochemistrytype";

    let Geochemistrytype = await asyncAxios.get(url, params);

    return Geochemistrytype;
  },
   autoCompleteExperts: async params => {
    let url = "/api/basic/autoCompleteExperts";

    let Geochemistrytype = await asyncAxios.get(url, params);

    return Geochemistrytype;
  },
     autoCompleteEnterer: async params => {
    let url = "/api/basic/autoCompleteEnterer";

    let Geochemistrytype = await asyncAxios.get(url, params);

    return Geochemistrytype;
  },
  getChronostratigraphics:async (params)=>{
      let url = "/api/basic/getChronostratigraphics";

    let Chronostratigraphics = await asyncAxios.get(url, params);

    return Chronostratigraphics;
  },
  autoCompleteChronostratigraphics:async (params)=>{
    let url = "/api/basic/autoCompleteChronostratigraphics";

  let Chronostratigraphics = await asyncAxios.get(url, params);

  return Chronostratigraphics;
},
    getGeochemistryTypes:async (params)=>{
      let url = "/api/basic/getGeochemistryTypes";

    let GeochemistryTypes = await asyncAxios.get(url, params);

    return GeochemistryTypes;
  }
};


