import React from 'react'
import {Modal,Button, message,Input} from 'antd'
import './sectionModal.scss'
import BasicForm from './basicForm'
import GeologyForm from './geoForm'
import StraForm from './straForm'
import GeoDataForm from './genDataForm'
import axios from 'axios'


export default class SectionModal extends React.Component{
    constructor(props){
        super(props)
        this.state={
            basicInfoActive:true,
            geologyActive:false,
            geochemicalActive:false,
            stratigraphyActive:false,
            unitActive:false,
        }
    }
  
    tobasicInfo(){
        this.setState({
            basicInfoActive:true,
            geologyActive:false,
            geochemicalActive:false,
            stratigraphyActive:false,
            unitActive:false,
        })

    }
    
    togeology(){
        this.setState({
            basicInfoActive:false,
            geologyActive:true,
            geochemicalActive:false,
            stratigraphyActive:false,
            unitActive:false,
        })
    }
    togeochemical(){
        this.setState({
            basicInfoActive:false,
            geologyActive:false,
            geochemicalActive:true,
            stratigraphyActive:false,
            unitActive:false,
        })
    }
    tostratigraphy(){
        this.setState({
            basicInfoActive:false,
            geologyActive:false,
            geochemicalActive:false,
            stratigraphyActive:true,
            unitActive:false,
        })
    }
    tounit(){
        this.setState({
            basicInfoActive:false,
            geologyActive:false,
            geochemicalActive:false,
            stratigraphyActive:false,
            unitActive:true,
        })
    }
    backBasic(){
        const {geologyActive}=this.state;
        if(geologyActive){
            this.setState({
                basicInfoActive:true,
                geologyActive:false,
                geochemicalActive:false,
                stratigraphyActive:false,
                unitActive:false,
            })
        }
    }
    
    // handPass(){
    //     this.props.cancelShowInfo();
    //     message.success('审核完成！')
    // }
   
    render(){
        const {basicInfoActive,geologyActive,geochemicalActive,stratigraphyActive,unitActive}=this.state;
        const {sectionId,basicInfo}=this.props
        return (
            <div>
                <Modal 
                className='AModal showInfoModal'
                visible={this.props.visible}
                onOk={this.props.handleOk}
                onCancel={this.props.cancelShowInfo}
                footer={null}
                >
                    <div className='modalTitle'>
                        <Button className={`firstbtn ${basicInfoActive?'btnactive':''}`} type='default' onClick={this.backBasic.bind(this)}>Basic Info</Button>
                        <svg t="1576811440416" className="svgfirst" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14936" width="25" height="25"><path d="M950.2 535.2c0.2-0.3 0.4-0.5 0.7-0.8 0.2-0.3 0.4-0.5 0.6-0.8 0.2-0.2 0.4-0.5 0.5-0.7l0.6-0.9c0.2-0.2 0.3-0.4 0.4-0.7 0.2-0.3 0.4-0.7 0.6-1 0.1-0.2 0.2-0.4 0.4-0.6 0.2-0.4 0.4-0.7 0.6-1.1l0.3-0.6c0.2-0.4 0.4-0.7 0.6-1.1 0.1-0.2 0.2-0.5 0.3-0.7 0.2-0.3 0.3-0.7 0.5-1 0.1-0.3 0.2-0.6 0.4-0.9 0.1-0.3 0.2-0.6 0.4-0.9 0.1-0.4 0.3-0.7 0.4-1.1 0.1-0.2 0.2-0.5 0.3-0.7l0.3-1.2c0.1-0.2 0.1-0.4 0.2-0.7l0.3-1.2c0.1-0.2 0.1-0.5 0.2-0.7 0.1-0.4 0.1-0.8 0.2-1.1 0-0.3 0.1-0.5 0.1-0.8 0.1-0.3 0.1-0.7 0.1-1.1 0-0.3 0.1-0.6 0.1-0.9 0-0.3 0.1-0.6 0.1-1s0.1-0.7 0.1-1.1v-0.9-1.1-0.3-0.5-0.6c0.2-10.3-3.5-20.8-11.3-28.8l-215-221c-15.4-15.8-40.7-16.2-56.6-0.8-15.8 15.4-16.2 40.7-0.8 56.6l150.9 155.1H104.5c-22.1 0-40 17.9-40 40s17.9 40 40 40h722.3L675.4 710c-15.2 16.1-14.4 41.4 1.6 56.5 7.7 7.3 17.6 10.9 27.4 10.9 10.6 0 21.2-4.2 29.1-12.6l215-227.9c0.1-0.1 0.2-0.2 0.2-0.3 0.3-0.3 0.5-0.5 0.7-0.8 0.4-0.1 0.6-0.4 0.8-0.6z" p-id="14937" fill="#3a94bb"></path></svg>
                        <Button className={geologyActive?'btnactive':null} type='default' >Geology</Button>
                        <div className='btngroup'>
                            <div className='geo'>
                            <svg t="1576811440416" className="svgup" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14936" width="25" height="25"><path d="M950.2 535.2c0.2-0.3 0.4-0.5 0.7-0.8 0.2-0.3 0.4-0.5 0.6-0.8 0.2-0.2 0.4-0.5 0.5-0.7l0.6-0.9c0.2-0.2 0.3-0.4 0.4-0.7 0.2-0.3 0.4-0.7 0.6-1 0.1-0.2 0.2-0.4 0.4-0.6 0.2-0.4 0.4-0.7 0.6-1.1l0.3-0.6c0.2-0.4 0.4-0.7 0.6-1.1 0.1-0.2 0.2-0.5 0.3-0.7 0.2-0.3 0.3-0.7 0.5-1 0.1-0.3 0.2-0.6 0.4-0.9 0.1-0.3 0.2-0.6 0.4-0.9 0.1-0.4 0.3-0.7 0.4-1.1 0.1-0.2 0.2-0.5 0.3-0.7l0.3-1.2c0.1-0.2 0.1-0.4 0.2-0.7l0.3-1.2c0.1-0.2 0.1-0.5 0.2-0.7 0.1-0.4 0.1-0.8 0.2-1.1 0-0.3 0.1-0.5 0.1-0.8 0.1-0.3 0.1-0.7 0.1-1.1 0-0.3 0.1-0.6 0.1-0.9 0-0.3 0.1-0.6 0.1-1s0.1-0.7 0.1-1.1v-0.9-1.1-0.3-0.5-0.6c0.2-10.3-3.5-20.8-11.3-28.8l-215-221c-15.4-15.8-40.7-16.2-56.6-0.8-15.8 15.4-16.2 40.7-0.8 56.6l150.9 155.1H104.5c-22.1 0-40 17.9-40 40s17.9 40 40 40h722.3L675.4 710c-15.2 16.1-14.4 41.4 1.6 56.5 7.7 7.3 17.6 10.9 27.4 10.9 10.6 0 21.2-4.2 29.1-12.6l215-227.9c0.1-0.1 0.2-0.2 0.2-0.3 0.3-0.3 0.5-0.5 0.7-0.8 0.4-0.1 0.6-0.4 0.8-0.6z" p-id="14937" fill="#3a94bb"></path></svg>
                            <Button className={geochemicalActive?'btnactive':null} type='default' ><div><div>Geochemical</div><div>Data</div></div></Button>
                            </div>
                            <div className='str'>
                            <svg t="1576811440416" className="svgdown" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14936" width="25" height="25"><path d="M950.2 535.2c0.2-0.3 0.4-0.5 0.7-0.8 0.2-0.3 0.4-0.5 0.6-0.8 0.2-0.2 0.4-0.5 0.5-0.7l0.6-0.9c0.2-0.2 0.3-0.4 0.4-0.7 0.2-0.3 0.4-0.7 0.6-1 0.1-0.2 0.2-0.4 0.4-0.6 0.2-0.4 0.4-0.7 0.6-1.1l0.3-0.6c0.2-0.4 0.4-0.7 0.6-1.1 0.1-0.2 0.2-0.5 0.3-0.7 0.2-0.3 0.3-0.7 0.5-1 0.1-0.3 0.2-0.6 0.4-0.9 0.1-0.3 0.2-0.6 0.4-0.9 0.1-0.4 0.3-0.7 0.4-1.1 0.1-0.2 0.2-0.5 0.3-0.7l0.3-1.2c0.1-0.2 0.1-0.4 0.2-0.7l0.3-1.2c0.1-0.2 0.1-0.5 0.2-0.7 0.1-0.4 0.1-0.8 0.2-1.1 0-0.3 0.1-0.5 0.1-0.8 0.1-0.3 0.1-0.7 0.1-1.1 0-0.3 0.1-0.6 0.1-0.9 0-0.3 0.1-0.6 0.1-1s0.1-0.7 0.1-1.1v-0.9-1.1-0.3-0.5-0.6c0.2-10.3-3.5-20.8-11.3-28.8l-215-221c-15.4-15.8-40.7-16.2-56.6-0.8-15.8 15.4-16.2 40.7-0.8 56.6l150.9 155.1H104.5c-22.1 0-40 17.9-40 40s17.9 40 40 40h722.3L675.4 710c-15.2 16.1-14.4 41.4 1.6 56.5 7.7 7.3 17.6 10.9 27.4 10.9 10.6 0 21.2-4.2 29.1-12.6l215-227.9c0.1-0.1 0.2-0.2 0.2-0.3 0.3-0.3 0.5-0.5 0.7-0.8 0.4-0.1 0.6-0.4 0.8-0.6z" p-id="14937" fill="#3a94bb"></path></svg>
                            <Button className={stratigraphyActive?'btnactive':null} type='default'><div><div>Stratigraphy</div><div>&Lithology</div></div></Button>
                            </div>
                        </div>
                        {/* <svg t="1576811440416" className="svglast" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14936" width="25" height="25"><path d="M950.2 535.2c0.2-0.3 0.4-0.5 0.7-0.8 0.2-0.3 0.4-0.5 0.6-0.8 0.2-0.2 0.4-0.5 0.5-0.7l0.6-0.9c0.2-0.2 0.3-0.4 0.4-0.7 0.2-0.3 0.4-0.7 0.6-1 0.1-0.2 0.2-0.4 0.4-0.6 0.2-0.4 0.4-0.7 0.6-1.1l0.3-0.6c0.2-0.4 0.4-0.7 0.6-1.1 0.1-0.2 0.2-0.5 0.3-0.7 0.2-0.3 0.3-0.7 0.5-1 0.1-0.3 0.2-0.6 0.4-0.9 0.1-0.3 0.2-0.6 0.4-0.9 0.1-0.4 0.3-0.7 0.4-1.1 0.1-0.2 0.2-0.5 0.3-0.7l0.3-1.2c0.1-0.2 0.1-0.4 0.2-0.7l0.3-1.2c0.1-0.2 0.1-0.5 0.2-0.7 0.1-0.4 0.1-0.8 0.2-1.1 0-0.3 0.1-0.5 0.1-0.8 0.1-0.3 0.1-0.7 0.1-1.1 0-0.3 0.1-0.6 0.1-0.9 0-0.3 0.1-0.6 0.1-1s0.1-0.7 0.1-1.1v-0.9-1.1-0.3-0.5-0.6c0.2-10.3-3.5-20.8-11.3-28.8l-215-221c-15.4-15.8-40.7-16.2-56.6-0.8-15.8 15.4-16.2 40.7-0.8 56.6l150.9 155.1H104.5c-22.1 0-40 17.9-40 40s17.9 40 40 40h722.3L675.4 710c-15.2 16.1-14.4 41.4 1.6 56.5 7.7 7.3 17.6 10.9 27.4 10.9 10.6 0 21.2-4.2 29.1-12.6l215-227.9c0.1-0.1 0.2-0.2 0.2-0.3 0.3-0.3 0.5-0.5 0.7-0.8 0.4-0.1 0.6-0.4 0.8-0.6z" p-id="14937" fill="#3a94bb"></path></svg>
                        <Button className={unitActive?'btnactive':null} type='default'>Unit</Button> */}
                    </div>
                    
                    <div className='form'>
                        {
                            basicInfoActive?(
                            <BasicForm
                             sectionId={sectionId}
                             basicInfo={basicInfo}
                             cancelShowInfo={this.props.cancelShowInfo.bind(this)}
                             changeStatus={this.togeology.bind(this)}
                              ></BasicForm>):null
                        }
                        {
                            geologyActive?(
                            <GeologyForm
                             sectionId={sectionId}
                             cancelShowInfo={this.props.cancelShowInfo.bind(this)}
                             changeStatus={this.togeochemical.bind(this)}
                              ></GeologyForm>):null
                        }
                         {
                            geochemicalActive?(
                            <GeoDataForm
                             sectionId={sectionId}
                             cancelShowInfo={this.props.cancelShowInfo.bind(this)}
                             changeStatus={this.tostratigraphy.bind(this)}
                              ></GeoDataForm>):null
                        }
                        {
                            stratigraphyActive?(
                            <StraForm
                             sectionId={sectionId}
                             cancelShowInfo={this.props.cancelShowInfo.bind(this)}
                              ></StraForm>):null
                        }
                        {/* {
                            unitActive?(
                            <UnitForm
                            //  sendData={this.props.sendData}
                             handleReject={this.props.handleReject.bind(this)}
                             handleOk={this.props.handleShowInfo.bind(this)}
                              ></UnitForm>):null
                        } */}
                    </div>
                </Modal>
            </div>
        )
      
    }
}
