import React from 'react'
import {Modal,Form,Button,Input,message,Tabs,Select,Pagination} from 'antd'
import axios from 'axios'
import moment from 'moment'
import echarts from 'echarts'

const {TabPane}=Tabs
const {Option}=Select

export default class CollectionModal extends React.Component{
    constructor(props){
        super(props)
        this.state={
            typeData:[],
            chartData:[],
            nowType:null,
            tabNum:1,
            isReverse1:false,
            isReverse2:false,
            nowPage:1,
            pageSize:50,
            nowGeoType:'',
            isLoading:true
        }
    }
    
    componentDidMount(){
        const {modalData}=this.props
        var sendData={
            geochemistryoccurrence_id:modalData.id
        }
        axios.get('/api/basic/getGeochemistryTypes',{
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                this.setState({
                    typeData:res.data.result,
                    isLoading:false
                })
            }
           
        })
    }
    handleReject(){
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                var sendData={
                    id:this.props.modalData.id,
                    assetstype:3,
                    authState:2,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                    auth_comment:str
                }
              
                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已驳回！')
                        this.props.cancelShowInfo()
                        this.props.refreshList()
                    }
                })
               
            },
          
        })
    }
    handlePass(){
        const {modalData}=this.props;
        var sendData={
            id:modalData.id,
            assetstype:3,
            authState:1,
            auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
        }
       
        axios.post('/api/admin/review/assets',sendData).then(res=>{
            if(res.data.error===null){
               message.success('审核通过!')
               this.props.cancelShowInfo()
               this.props.refreshList()
            }else{
                message.error(res.data.error)
            }
        }).catch(err=>{
            message.error(err)
        })
    }
    selectType(type){
        this.setState({
            nowType:type
        },function(){
            this.getGeochemistryDatas()
        })
        
    }
    getGeochemistryDatas(){
        const {modalData}=this.props
        var sendData={
            geochemistryoccurrence_id:modalData.id,
            geochemistry_type:this.state.nowType,
        }

        axios.get('/api/admin/getGeochemistryDatas',{
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                var data=res.data.result
                this.setState({
                    chartData:data,
                },function(){
                   if(this.state.tabNum==='2' || this.state.tabNum==='3'){
                       this.createChart()
                   }
                })
            }
        })
    }
    createChart(){
        var {chartData,tabNum,isReverse1,isReverse2}=this.state;
        if(tabNum==='2'){
            if(isReverse1){
                chartData.sort((a,b)=> {return b.depthfrom-a.depthfrom} )
            }else{
                chartData.sort((a,b)=> {return a.depthfrom-b.depthfrom} )
            }
            var option={
                dataZoom: [
                    {
                        type:'slider',
                        show: true,
                        realtime: true,
                        start: 0,
                        end: 25
                    },
                ],
                grid: {
                    left: '1%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b}<br/>{c}'
                },
                xAxis:{
                    name:`Depth(${chartData[0].depth_unit})`,
                    nameLocation:'middle',
                    nameTextStyle:{
                        // padding:[30,0,0,0],
                    },
                    type:'category',
                    data:chartData.map(item=>item.depthfrom)
                },
                yAxis:{
                    name:`${chartData[0].geochemistry_type} ${chartData[0].value_unit}`,
                    type:'value'
                },
                series:[{
                    type:'line',
                    data:chartData.map(item=>item.value)
                }]
            }
            var myChart1=echarts.init(document.querySelector('#chart1'));
            myChart1.setOption(option)
        }
        if(tabNum==='3'){
            if(isReverse2){
                chartData.sort((a,b)=> {return b.depthfrom-a.depthfrom} )
            }else{
                chartData.sort((a,b)=> {return a.depthfrom-b.depthfrom} )
            }
            var option={
                dataZoom: [
                    {
                        type:'slider',
                        orient: 'vertical',
                        show: true,
                        realtime: true,
                        start: 0,
                        end: 25
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b}<br/>{c}'
                },
                xAxis:{
                    name:`${chartData[0].geochemistry_type} ${chartData[0].value_unit}`,
                    type:'value',
                },
                yAxis:{
                    data:chartData.map(item=>item.depthfrom),
                    name:`Depth(${chartData[0].depth_unit})`,
                    type:'category',
                },
                series:[{
                    type:'line',
                    data:chartData.map(item=>item.value)
                }]
            }
            var myChart2=echarts.init(document.querySelector('#chart2'));
            myChart2.setOption(option)
        }
    }
    changeTabs(tab){
        this.setState({
            tabNum:tab
        },function(){
            if(tab==='2' || tab==='3'){
                this.createChart()
            }
        })
       
    }
    reverse(){
        var {tabNum,isReverse1,isReverse2}=this.state
        if(tabNum==='2'){
            this.setState({
                isReverse1:!isReverse1
            },()=>{
                this.createChart()
            })
        }
        if(tabNum==='3'){
            this.setState({
                isReverse2:!isReverse2
            },()=>{
                this.createChart()
            })
        }
    }
    changePage(page,pageSize){
        this.setState({
            nowPage:page
        })
        
    }
    render(){
          const {modalData}=this.props
          const {chartData,typeData,nowPage,pageSize,isLoading}=this.state
        return (
            <div>
                <Modal 
                className='AModal geoInfo'
                visible={this.props.visible}
                onCancel={this.props.cancelShowInfo}
                footer={null}
                >
                    <div className='form'>
                        <div className="tip1">Geochemical</div>
                        {
                            isLoading?<div className="tip1">Loading</div> :(
                                <div>
                                <Form>
                                    <Form.Item label='Geochemial data type'>
                                        <Select style={{width:400}} onSelect={(v)=>{this.selectType(v)}} placeholder='选择类型后显示数据'>
                                            {
                                                typeData.map((item,index)=>{
                                                    return (
                                                        <Option key={index} value={item.geochemistry_type}>{item.geochemistry_type}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Form>
                                {
                                    chartData.length>0 ?(
                                        <div className='tabs'>
                                        <Tabs type="card" onChange={this.changeTabs.bind(this)}>
                                            
                                            <TabPane tab="Show geochemical data(list)" key="1">
                                                <div className='table'>
                                                    <table>
                                                        <tbody>
                                                        <tr className='head'>
                                                            <td>index</td>
                                                            <td>geochemistry type</td>
                                                            <td>depth</td>
                                                            <td>value</td>
                                                            <td>enterer</td>
                                                        </tr>
                                                        {
                                                            chartData.filter((item,index)=>{
                                                                return index>=(nowPage-1)*pageSize && index<nowPage*pageSize
                                                            }).map((item,index)=>{
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{(nowPage-1)*pageSize+index+1}</td>
                                                                        <td>{item.geochemistry_type}</td>
                                                                        <td>{item.depthfrom+item.depth_unit}</td>
                                                                        <td>{item.value+item.value_unit}</td>
                                                                        <td>{modalData.enterer_username}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        <tr>
                                                            <td colSpan={5}>
                                                            <Pagination size='small' 
                                                            current={nowPage}
                                                            defaultPageSize={pageSize} 
                                                            total={chartData.length} 
                                                            onChange={this.changePage.bind(this)}
                                                            ></Pagination>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>
                                            
                                            <TabPane forceRender={true} tab="Show figure(horizontal)" key="2">
                                                <div id='chart1' style={{height:500}}></div>
                                            </TabPane>
                    
                                            <TabPane forceRender={true} tab="Show figure(vertical)" key="3">
                                                <div id='chart2' style={{height:700}}></div>
                                            </TabPane>
                    
                                        
                    
                                        </Tabs>
                                            <Button type="link" onClick={()=>{this.reverse()}}>Reverse the depth axis</Button>
                                        </div>
                                    ):null
                                }
                                        </div>
                                    )
                        }
                        
                        <div className='foot'>
                            <Button  type='agbdb1' onClick={this.handlePass.bind(this)}>通过</Button>
                            <Button type='agbdb2' onClick={this.handleReject.bind(this)}>拒绝</Button>
                        </div>
                    </div>
                   
                    
                </Modal>
            </div>
        )
      
    }
}