import React, { Component } from "react";
import groupApis from "src/js/apis/group";
import "./index.css";
import InfiniteScroll from "react-infinite-scroller";
import { Button, Modal } from "antd";
import Storage from "src/js/storage";
import "src/js/global.js";
import userApis from "src/js/apis/user";
export default class GroupApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMember: [],
      userPhotos: {}
    };
  }
  agreeApply = async (item, index) => {
    let that = this;
    Modal.confirm({
      title: "please choose",
      content: `Are you sure to agree the application`,
      async onOk() {
        let params = {
          groupid: item.groupid,
          uid: item.uid
        };

        let permitBack = await groupApis.permitAddGroup(params);

        if (permitBack.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: permitBack.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 5000);
        } else {
          let { currentMember } = that.state;
          currentMember.splice(index, 1);
          that.setState(
            {
              currentMember
            },
            () => {
              const modal = Modal.success({
                title: "This is an successful message",
                content: "The request was granted"
              });
              setTimeout(() => {
                modal.destroy();
              }, 3000);
            }
          );
        }
      },
      onCancel() {}
    });
  };
  denyApply = async (item, index) => {
    let that = this;
    Modal.confirm({
      title: "please choose",
      content: `Are you sure to DENY the application`,
      async onOk() {
        let params = {
          groupid: item.groupid,
          uid: item.uid
        };
        let denyBack = await groupApis.rejectApply(params);
        if (denyBack.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: denyBack.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 3000);
        } else {
          let { currentMember } = that.state;
          currentMember.splice(index, 1);
          that.setState(
            {
              currentMember
            },
            () => {
              const modal = Modal.success({
                title: "This is an successful message",
                content: "Has been rejected"
              });
              setTimeout(() => {
                modal.destroy();
              }, 3000);
            }
          );
        }
      },
      onCancel() {}
    });
  };
  isGbdbUser = () => {
    let token = Storage.getItem("token");

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    }
  };
  // 0:驳回 1:审核中 2:已通过，3:自愿退出
  async componentDidMount() {
    this.isGbdbUser();
    if (Storage.getItem("gbdbid")) {
      let audits = await groupApis.getPersonInGroup({
        creator: Storage.getItem("gbdbid"),
        state: 1
      });

      if (audits.error) {
        const modal = Modal.error({
          title: "This is an error message",
          content: audits.error
        });
        setTimeout(() => {
          modal.destroy();
        }, 5000);
      } else {
        this.setState(
          {
            currentMember: audits.result
          },
          async () => {
            if (audits.result && audits.result.length > 0) {
              let currentMember = audits.result;
              let memberIds = [];

              currentMember.map((item, index) => {
                memberIds.push(item.userid);
              });
              let idsParams = { ids: memberIds };
              let gbdbGetUser = await userApis.gbdbGetUserByIds(idsParams);
              if (gbdbGetUser.result) {
                this.setState({
                  userPhotos: gbdbGetUser.result
                });
              } else {
                this.setState({
                  userPhotos: {}
                });
              }
            }
          }
        );
      }
    }
  }
  render() {
    let { currentMember } = this.state;
    return (
      <div className="group-apply">
        <div className="group-apply-content">
          <div className="group-create-title">Application list</div>
          {currentMember.length > 0 ? (
            <div className="group-apply-list">
              {
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={this.handleInfiniteOnLoad}
                  hasMore={!this.state.loading && this.state.hasMore}
                  useWindow={false}
                >
                  <ul>
                    {currentMember.map((item, index) => {
                      return (
                        <li className="member-item apply-member-item">
                          <div className="member-avatar">
                            {JSON.stringify(this.state.userPhotos) === "{}" ? (
                              <img
                                src={require("src/assets/images/group/user.png")}
                                alt=""
                              />
                            ) : (
                              <img
                                src={
                                  "/api" +
                                  this.state.userPhotos[item.userid]
                                }
                                alt=""
                              />
                            )}
                          </div>
                          <div className="member-detail clearfix">
                            <div className="apply-item-name">
                              <span className="member-name">
                                {item.username}
                              </span>
                            </div>
                            <div className="apply-item-group">
                              <span className="apply-group-name">
                                {item.name}
                              </span>
                            </div>
                            <div className="apply-btns">
                              <Button
                                type="gbdb"
                                className="apply-btn-agree"
                                onClick={e => this.agreeApply(item)}
                              >
                                Agree
                              </Button>
                              <Button
                                type="gbdb"
                                className="apply-btn-deny"
                                onClick={e => this.denyApply(item)}
                              >
                                Deny
                              </Button>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </InfiniteScroll>
              }
            </div>
          ) : (
            <div className="group-apply-list">
              <div className="has-nogroup">No list of applications</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
