import React, { Component } from 'react';
import './index.css';
import { List } from "antd";
import asyncAxios from "src/js/asyncAxios";

async function information() {
  let pubs = await asyncAxios.get("/api/assets/informations/publications.json")
  
  return pubs

}
export default class ResearchPublic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoList: []
    };
  }
  async componentDidMount() {
    let that = this

    let infoLists = await information()
    that.setState({
      infoList: infoLists
    }, () => {
      
    })


  }
  render() {
    return (
      <div className="public">
        <h2 className="title">Publications based on data of Geobiodiversity Database (GBDB)</h2>
        <div className="public-content">

          <List
            className="demo-loadmore-list"
            // loading={initLoading}
            itemLayout="horizontal"
            // loadMore={loadMore}
            dataSource={this.state.infoList}
            pagination={{
              onChange: page => {
                console.log(page);
              },
              pageSize: 6,
            }}
            renderItem={item => (
              <List.Item
                className="public-text1"

              >
                <div key={item.key}>
                  <div className="public-year">{item.key}</div>
                  <div className="public-contents">
                  {item.value.map((item,index)=>{
                      return <p>{item}</p>
                    })}
                  </div>
                 
                   
                 
                </div>

              </List.Item>
            )}
          />
         
        </div>
      </div>
    );
  }
}
