import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "antd";

import "./index.scss";
import "src/js/global.js";
import insectsApis from "src/js/apis/insects";
import Storage from "src/js/storage";

function InsectsList() {
  const history = useHistory();
  let dataSource = [
    {
      class: 'Insecta',
      key: 1,
      id: 10,
      subclass: {
        content: '',
        author: '',
        date: ''
      },
      infraclass: {
        content: '',
        author: '',
        date: ''
      },
      superorder: {
        content: '',
        author: '',
        date: ''
      },
      order: {
        content: 'Hymenoptera',
        author: 'Linné',
        date: '1758'
      },
      suborder: {
        content: 'Apocrita',
        author: 'Gerstackner',
        date: '1867'
      },
      infraorder: {
        content: '',
        author: '',
        date: ''
      },
      superfamily: {
        content: 'Vespoidea',
        author: 'Latreille',
        date: '1802'
      },
      family: {
        content: '',
        author: '',
        date: ''
      },
      subfamily: {
        content: '',
        author: '',
        date: ''
      },
      genericName: {
        content: 'Archaeoscolia',
      },
      speciesName: {
        content: 'senilis',
      },
      tribe: {
        content: '',
        author: '',
        date: ''
      },
      reference: {
        title: 'Archaeoscolienae, an extinct subfamily of Scoliid wasps (Insecta Vespida = Hymenoptera Scoliidae).',
        author: 'Rasnitsyn, A.P.',
        vol: '2 (1)',
        pages: '85-96.',
        date: '1993',
        pub: 'Journal of Hymenoptera Research'
      },
      lithostratigraphy: {
        lithostrat: 'Khurilt member',
        member: 'Khurilt Member',
        formation: 'Dzun-Bain Formation',
        group: ''
      },
      time: {
        stage: 'Aptian',
        epoch: 'Lower Cretaceous',
        subPeriod: '',
        period: 'Cretaceous',
        era: 'Mesozoic',
        start: '125',
        end: '113'
      },
      site: {
        siteName: 'Bon-Tsagan',
        town: 'Bon-Tsagan - Nuur lake',
        county: 'Bayan-Khongor Aymak',
        state: '',
        country: 'Mongolia',
        continent: 'Asia'
      }  
    },
    {
      class: 'Insecta',
      key: 2,
      id: 13,
      subclass: {
        content: '',
        author: '',
        date: ''
      },
      infraclass: {
        content: '',
        author: '',
        date: ''
      },
      superorder: {
        content: 'Pancondylognatha',
        author: 'Yoshizawa & Leinhard',
        date: '2016'
      },
      order: {
        content: 'Hemiptera',
        author: 'Linné',
        date: '1758'
      },
      suborder: {
        content: 'Heteroptera',
        author: 'Latreille',
        date: '1810'
      },
      infraorder: {
        content: '',
        author: '',
        date: ''
      },
      superfamily: {
        content: 'Reduvioidea',
        author: 'Latreille',
        date: '1807'
      },
      family: {
        content: '',
        author: '',
        date: ''
      },
      subfamily: {
        content: '',
        author: '',
        date: ''
      },
      genericName: {
        content: 'Apicrenus',
      },
      speciesName: {
        content: 'fossilis',
      },
      tribe: {
        content: '',
        author: '',
        date: ''
      },
      reference: {
        title: 'Apicrenus fossilis gen & sp. nov (Heteroptera Reduviidae Apiomerinae) from Dominican Amber L. Oligocene-U. Eocene.',
        author: 'Maldonado Capriles, J. et al.',
        vol: '24(2)',
        pages: '139-142.',
        date: '1993',
        pub: 'Entomologica Scandinavica'
      },
      lithostratigraphy: {
        lithostrat: 'Amber Dominican Republic',
        member: '',
        formation: '',
        group: ''
      },
      time: {
        stage: 'Burdigalian',
        epoch: 'Miocene',
        subPeriod: '',
        period: 'Neogene',
        era: 'Cenozoic',
        start: '20.44',
        end: '15.97'
      },
      site: {
        siteName: 'La Toca mine',
        town: 'Las Aguitas nr Santiago',
        county: '',
        state: '',
        country: 'Dominican Republic',
        continent: 'South America'
      }  
    }
  ];
  const columns = [
    {
      key: 'genericName',
      title: 'GenericName',
      dataIndex: 'genericName',
      align: 'center',
      width: 120,
      fixed: 'left',
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'speciesName',
      title: 'SpeciesName',
      dataIndex: 'speciesName',
      align: 'center',
      width: 120,
      fixed: 'left',
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'subfamily',
      title: 'Subfamily',
      dataIndex: 'subfamily',
      align: 'center',
      width: 100,
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'family',
      title: 'Family',
      dataIndex: 'family',
      align: 'center',
      width: 80,
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'superfamily',
      title: 'Superfamily',
      dataIndex: 'superfamily',
      align: 'center',
      width: 110,
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'infraorder',
      title: 'InfraOrder',
      dataIndex: 'infraorder',
      align: 'center',
      width: 100,
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'suborder',
      title: 'Suborder',
      dataIndex: 'suborder',
      align: 'center',
      width: 110,
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'order',
      title: 'Order',
      dataIndex: 'order',
      width: 120,
      align: 'center',
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'superorder',
      title: 'SuperOrder',
      dataIndex: 'superorder',
      align: 'center',
      width: 150,
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'infraclass',
      title: 'InfraClass',
      dataIndex: 'infraclass',
      align: 'center',
      width: 100,
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'subclass',
      title: 'SubClass',
      dataIndex: 'subclass',
      align: 'center',
      width: 90,
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'class',
      title: 'Class',
      dataIndex: 'class',
      align: 'center',
      width: 70,
      render: (text) => {
        return  <>{text.content || '--'}</>
      }
    }, {
      key: 'action',
      title: 'Action',
      align: 'center',
      width: 80,
      fixed: 'right',
      render: (text, record) => {
        return  <a onClick= { doLookDetails.bind(this, record || {})}>Details</a>
      }
    },
  ]
  

  /** 查看详情 */
  function doLookDetails(record){
    history.push({
      pathname:"/insects/details",
      query: {
        ...record
      }
    });
  }

  return (
    <div className="insects-list">
      <div className="list-title">Result</div>
      <div className="list-content">
        <Table dataSource={dataSource} columns={columns} pagination={ false } bordered= {true} scroll= {{x: 1300}} />
      </div>
    </div>
  )
}
export default InsectsList;