import React, { Component } from "react";
import "./index.css";
import { Form, Input, Select, Button, Modal, AutoComplete } from "antd";

import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";

import TaxonomyTableResult from "commons/AllTableList/Taxonomy/index.js";
import TaxonomyDetailForm from "commons/AllDetails/Taxonomy/index.js";
import YearPicker from "commons/YearPicker/index";
import dataApis from "src/js/apis/data";
import moment from "moment";
import Utils from "src/js/utils";
import Apis from "src/js/apis/api";
import Storage from "src/js/storage";
import Users from "src/js/users";
import "src/js/global.js";
const { Option } = Select;
const { TextArea } = Input;

class AddIncomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name1Opts: [],
      name2Opts: [],
      clatorOpts: [],
      refSearch: false,
      taxSearch: false,
      resultList: [],
      originalTaxonInfo: {},
      showOriginal: false,
      treatises: [],
      refInfo: {},
      allChildError: {},
      yearValue: null
    };
  }
  isGbdbUser = async () => {
    let Inputerid=false
    let token = Storage.getItem("token");
     Inputerid = await Users.isGbdbInputer();
  

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    } else {
      if (Inputerid == false) {
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title: "Sorry!!",
            content: "You do not have permission to add data"
          });
          this.props.history.push("/home");
        }
      }
    }
  };
  async componentDidMount() {
    this.isGbdbUser();
    let fossilgroup = await dataApis.fossilgroup();
    let treatises = await dataApis.getTreatise();
    this.setState({
      fossilgroup: fossilgroup && fossilgroup.result ? fossilgroup.result : {},

      treatises: treatises && treatises.result ? treatises.result : []
    });
  }

  addIncomplete = () => {
    let { refInfo } = this.state;
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      
      if (!err) {
        let addIncomplete = Utils.filterParams(values);
        addIncomplete = Utils.filterCondition(addIncomplete);
        addIncomplete = Utils.addEnterer(addIncomplete);
        addIncomplete = Utils.addAudit(addIncomplete, refInfo);
        let addIncompletes = await Apis.createHigher(addIncomplete);
        if (addIncompletes && addIncompletes.error) {
          Modal.error({
            title: "This is an error message",
            content: addIncompletes.error
          });
          return;
        } else {
          let that = this;
          Modal.success({
            title: "This is a successful message",
            content: "add success",
            onOk() {
              that.props.form.resetFields();
              that.setState({
                yearValue:null
              })
            }
          });
        }
      } else {
      }
    });
  };

  handleName1Change = value => {
    this.props.form.setFieldsValue({
      taxName1: value
    });
  };
  handleName1Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name1"],
        taxon_name1: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name1Opts: [] });
        } else {
          this.setState(
            {
              name1Opts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ name1Opts: [] });
    }
  };

  handleName2Change = value => {
    this.props.form.setFieldsValue({
      taxName2: value
    });
  };
  handleName2Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name2"],
        taxon_name2: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name2Opts: [] });
        } else {
          this.setState(
            {
              name2Opts: searchResult.result
            },
            () => {
           
            }
          );
        }
      }
    } else {
      this.setState({ name2Opts: [] });
    }
  };
  handleNomSearch = async value => {
    if (value) {
      let params = {
        attributes: ["nomenclator"],
        nomenclator: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });

        return;
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ clatorOpts: [] });
        } else {
          this.setState(
            {
              clatorOpts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ clatorOpts: [] });
    }
  };

  handleNomChange = value => {
    this.props.form.setFieldsValue({
      nomenclator: value
    });
  };

  // tax

  // let searchKeys = ['equals','begins','ends', 'includes','in','after', 'before','median'];
  showTaxRef = async e => {
    e.target.blur();
    this.setState({
      taxSearch: true
    });
    let allParas = {};
    let searchTaxs = this.props.form.getFieldsValue([
      "ref_id",
      "year",
      "nomenclator",
      "taxon_name2"
    ]);

    let { ref_id, year, nomenclator, taxon_name2 } = searchTaxs;
    if (
      ref_id === "" &&
      year === "" &&
      nomenclator === "" &&
      taxon_name2 === ""
    ) {
      Modal.error({
        title: "This is an error message",
        content: "Please enter the query criteria"
      });
      return;
    }
    if (ref_id) {
      allParas.ref_id = {
        condition: "includes",
        value: ref_id
      };
    }
    if (year) {
      allParas.year = {
        condition: "equals",
        value: year
      };
    }
    if (nomenclator) {
      allParas.nomenclator = {
        condition: "equals",
        value: nomenclator
      };
    }
    if (taxon_name2) {
      allParas.taxon_name2 = {
        condition: "includes",
        value: taxon_name2
      };
    }

    let searchAll = await Apis.searchAll(allParas, "taxon");
    if (searchAll.error) {
    } else {
      this.setState(
        {
          taxSearch: true,
          resultList: searchAll.result
        },
        () => {}
      );
    }
  };

  // ref

  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };

  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };

  onRef = ref => {
    this.child = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true
    });

    //点击 detail 按扭执行代码
  };
  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            refSearch: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues } = this.state;

              this.child.searchReferenceDatas(searchParams, searchValues);
            }, 20);
          }
        );
      }
    });
  };
  searchOriginal = () => {
    this.setState(
      {
        showTaxonResult: true,
        showOriginal: true
      },
      () => {
        this.props.form.validateFieldsAndScroll(async (err, values) => {
          let originaParamsl = Utils.filterParams(values);
          originaParamsl = Utils.filterCondition(originaParamsl);
          originaParamsl.taxon_type = "species-original";

          this.setState(
            {
              searchParams: originaParamsl,
              searchValues: values
            },
            () => {
              let { searchParams, searchValues } = this.state;
              this.taxonomyChild.searchTaxonomyDatas(
                searchParams,
                searchValues,
                { embellish: {} }
              );
            }
          );
        });
      }
    );
  };
  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",

      // nomenclator: value.authorizer || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };

  searchTaxonCancel = () => {
    this.setState({
      showTaxonResult: false,
      showOriginal: false
    });
  };
  onTaxonomy = ref => {
    this.taxonomyChild = ref;
  };
  handleTaxonOk = () => {
    this.setState({
      showTaxonResult: false,
      showOriginal: false
    });
  };

  detailTaxonomyFormOk = () => {
    this.setState({
      showTaxonomyDetailForm: false
    });
  };
  detailTaxonomyFormCancel = () => {
    this.setState({
      showTaxonomyDetailForm: false
    });
  };
  TaxonomyDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showTaxonomyDetailForm: true
    });
  };
  // 这里只查询是否重复  不操作
  checkTaxonomyOne = (value, combinationName) => {
    this.setState(
      {
        showTaxonResult: false,
        originalTaxonInfo: value,
        showOriginal: false
      },
      () => {
        let { originalTaxonInfo } = this.state;

        Object.keys(originalTaxonInfo).map((item, inxex) => {
          // itemValue = this.props.form.getFieldsValue([item]);
          this.props.form.setFieldsValue({
            [item]: originalTaxonInfo[item]
          });
        });
      }
    );
  };
  publishYear = year => {
    this.setState(
      {
        yearValue: year
      },
      () => {
       
      }
    );
  };
  render() {
    const { getFieldDecorator } = this.props.form;

    let {
      originalTaxonInfo,
      fossilgroup,
      searchParams,
      searchValues,
      showOriginal,
      treatises
    } = this.state;
    const name1Opts = this.state.name1Opts.map((item, indx) => (
      <Option key={item.taxon_name1}>{item.taxon_name1}</Option>
    ));
    const name2Opts = this.state.name2Opts.map((item, indx) => (
      <Option key={item.taxon_name2}>{item.taxon_name2}</Option>
    ));
    const clatorOpts = this.state.clatorOpts.map((item, index) => (
      <Option key={index}>{item.nomenclator}</Option>
    ));
    
    return (
      <div id="addRevised" className="taxonomy-common">
        <div className="addRevised-title">
          <div className="taxonomy-common-title form-common-title">
            Add species name incomplete or type-unknown form
          </div>

          <div className="addRevised-main">
            <div className="reference-search-form addHigther-form addOriginal-form">
              <Form>
                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<p>Fossil group</p>}>
                      {getFieldDecorator("fossil_group", {
                        rules: [{}],
                        initialValue: originalTaxonInfo
                          ? originalTaxonInfo.fossil_group
                          : ""
                      })(<Select>{Utils.fossilGroup(fossilgroup)}</Select>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<p>Taxon type</p>}>
                      {getFieldDecorator("taxon_type", {
                        rules: [],
                        initialValue: "species-incomplete"
                      })(
                        <Select>
                          <Option value="species-incomplete">
                            species-incomplete
                          </Option>
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  {showOriginal === true ? (
                    <Form.Item label={<span>Taxon name</span>}>
                      {getFieldDecorator("taxon_name1", {
                        rules: [
                          {
                            required: false
                          }
                        ],

                        initialValue: originalTaxonInfo
                          ? originalTaxonInfo.taxon_name1
                          : ""
                      })(
                        <AutoComplete
                          onSearch={this.handleName1Search}
                          onChange={this.handleName1Change}
                          placeholder=""
                        >
                          {name1Opts}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  ) : (
                    <Form.Item label={<span>Taxon name1</span>}>
                      {getFieldDecorator("taxon_name1", {
                        rules: [
                          {
                            required: true
                          }
                        ],

                        initialValue: originalTaxonInfo
                          ? originalTaxonInfo.taxon_name1
                          : ""
                      })(
                        <AutoComplete
                          onSearch={this.handleName1Search}
                          onChange={this.handleName1Change}
                          placeholder=""
                        >
                          {name1Opts}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  )}

                  <Form.Item label={<span>Taxon name2</span>}>
                    {getFieldDecorator("taxon_name2", {
                      rules: [
                        {
                          required: true
                        }
                      ],

                      initialValue: originalTaxonInfo
                        ? originalTaxonInfo.taxon_name2
                        : ""
                    })(
                      <AutoComplete
                        onSearch={this.handleName2Search}
                        onChange={this.handleName2Change}
                        placeholder=""
                      >
                        {name2Opts}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<p>Nomenclator</p>}>
                      {getFieldDecorator("nomenclator", {
                        rules: [{}],
                        initialValue: originalTaxonInfo
                          ? originalTaxonInfo.nomenclator
                          : ""
                      })(
                        <AutoComplete
                          onSearch={this.handleNomSearch}
                          onChange={this.handleNomChange}
                          placeholder=""
                        >
                          {clatorOpts}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<p>Year</p>}>
                      {getFieldDecorator("year", {
                        rules: [],
                        initialValue:
                          originalTaxonInfo && originalTaxonInfo.year
                            ? originalTaxonInfo.year
                            : ""
                      })(
                        <YearPicker
                          time={
                            originalTaxonInfo && originalTaxonInfo.year
                              ? originalTaxonInfo.year
                              : this.state.yearValue
                          }
                          onChange={value => this.publishYear(value)}
                        ></YearPicker>
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className="referenceSearch-numberProject">
                  {showOriginal === true ? (
                    <div className="Reference-number">
                      <Form.Item label={<span>Reference</span>}>
                        {getFieldDecorator("ref_id", {
                          rules: [
                            {
                              required: false
                            }
                          ],
                          initialValue: originalTaxonInfo
                            ? originalTaxonInfo.ref_id
                            : ""
                        })(<Input onFocus={this.showSearchRef}></Input>)}
                      </Form.Item>
                    </div>
                  ) : (
                    <div className="Reference-number">
                      <Form.Item label={<span>Reference</span>}>
                        {getFieldDecorator("ref_id", {
                          rules: [
                            {
                              required: true
                            }
                          ],
                          initialValue: originalTaxonInfo
                            ? originalTaxonInfo.ref_id
                            : ""
                        })(<Input onFocus={this.showSearchRef}></Input>)}
                      </Form.Item>
                    </div>
                  )}

                  <div className="referenceSearch-no">
                    <Form.Item label={<p>Collect from Treatise?</p>}>
                      {getFieldDecorator("from_treatise", {
                        rules: [{}],
                        initialValue: originalTaxonInfo
                          ? originalTaxonInfo.from_treatise
                          : ""
                      })(
                        <Select>
                          {treatises.map((item, index) => {
                            return (
                              <Option key={item.name} value={item.name}>{item.name}</Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceAdd-comments last-formItem">
                  <Form.Item label="Notes" className="long-label">
                    {getFieldDecorator("notes", {
                      rules: [{}],
                      initialValue: originalTaxonInfo
                        ? originalTaxonInfo.notes
                        : ""
                    })(<TextArea rows={6}></TextArea>)}
                  </Form.Item>
                </div>
                <div className="addRevised-search incomplete-search-btn">
                  <Button
                    type="gbdb"
                    title={`Tips: Only use this button if you want to find the original name through the combination of "TaxonName field 2" and any other field of nomenclator, year or reference. If it works, please use the "Add revised species name" or "Add relationship" interfaces instead.`}
                    // onClick={this.showTaxRef}

                    onClick={() => {
                      this.searchOriginal();
                    }}
                  >
                    <p>Search for original name</p>
                  </Button>
                </div>

                <div className="referenceSearch-btns taxonomy-btns">
                  <div>
                    <Button
                      type="gbdb"
                      onClick={() => {
                        this.addIncomplete();
                      }}
                    >
                      <p>Add taxon</p>
                    </Button>
                  </div>
                </div>
                <div className="taxonomy-tips">
                  <div>TIPS:</div>
                  <div>
                    1. The present interface is ONLY used for the case that you
                    don't know the original name of a revised name or you don't
                    know whether it is an original species name or a revised
                    species name
                  </div>
                  <div>
                    2. If the taxon is an original name, please use the "Add
                    original taxon name" interface
                  </div>
                  <div>
                    3. If the taxon is a revised species name and you know the
                    original name of it. please use the "Add revised taxon name"
                    or the 'Add relationship" interfaces.
                  </div>
                  <div>
                    4 If a species is moved from one genus to another, its name
                    will be changed accordingly from an original name to a
                    revised name For example, Climacograptus angustus Perner,
                    1895 is an original name, and Normalograptus angustus
                    (Perner, 1895) is a revised name
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>

        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          <ReferenceOption
            childError={this.childError}
            conditions={{}}
            ref="reference"
          ></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          <ReferenceTableResult
            searchParams={this.state.searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showRefDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailRefFormOk}
          onCancel={this.detailRefFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
            // searchSection={this.searchSection}
            onlySeeRef={true}
          ></ReferenceDetailForm>
        </Modal>

        {/*originalModal */}

        <Modal
          destroyOnClose
          visible={this.state.showTaxonResult}
          className="searchResultModal taxResultModal noBottomPadding tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleTaxonOk}
          onCancel={this.searchTaxonCancel}
        >
          <TaxonomyTableResult
            searchParams={searchParams}
            searchValues={searchValues}
            onTaxonomy={this.onTaxonomy}
            changeCheckSelected={this.changeCheckSelected}
            getDetail={this.TaxonomyDetail}
            checkOne={true}
            checkTaxonomyOne={this.checkTaxonomyOne}
            disableCheck={true}
          ></TaxonomyTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showTaxonomyDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailTaxonomyFormOk}
          onCancel={this.detailTaxonomyFormCancel}
        >
          <TaxonomyDetailForm
            detailData={this.state.detailData}
            onlySeeRef={true}
            seeOnly={true}
          ></TaxonomyDetailForm>
        </Modal>
      </div>
    );
  }
}

AddIncomplete = Form.create({})(AddIncomplete);
export default AddIncomplete;
