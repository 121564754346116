import React, { Component } from "react";

import "./index.css";

import { Layout, Menu, Button, Modal, message } from "antd";
import groupApis from "src/js/apis/group";
import userApis from "src/js/apis/user";
import InfiniteScroll from "react-infinite-scroller";
import Storage from "src/js/storage";

import "src/js/global.js";
const { Header, Content, Sider } = Layout;

// 0:驳回 1:审核中 2:已通过，3:自愿退出

export default class GroupList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey: "",
      myGroups: [],
      currentMember: [],
      loading: false,
      hasMore: true,
      currentGroupId: "",
      currentGroupIndex: 0,
      uid: "",
      currentCreatorId: "",
      defaultSelectedKeys: "",
      userPhotos:{}
    };
  }
  getGroups = async callback => {
    let uid = Storage.getItem("gbdbid");

    let params = {
      uid,
      state: 2
    };
    let myGroups = await groupApis.getPersonInGroup(params);
    if (myGroups.error) {
      const modal = Modal.error({
        title: "This is an error message",
        content: myGroups.error
      });
      setTimeout(() => {
        modal.destroy();
      }, 3000);
    } else {
      callback(myGroups);
    }
  };
  isGbdbUser = () => {
    let token = Storage.getItem("token");

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    }
  };
  componentDidMount() {
    this.isGbdbUser();
    this.getGroups(res => {
      this.setState(
        {
          myGroups: res.result,
          currentCreatorId: res.result.length > 0 ? res.result[0].creator : "",
          currentGroupId: res.result.length > 0 ? res.result[0].groupid : "",

          currentGroupIndex: 0
        },
        async () => {
          let groupFirst = this.state.myGroups[0];
          if (groupFirst) {
            let groupid = groupFirst.groupid;
            let params = {
              groupid,
              state: 2
            };

            let membersBacks = await groupApis.getPersonInGroup(params);

            if (membersBacks.error) {
              const modal = Modal.error({
                title: "This is an error message",
                content: membersBacks.error
              });
              setTimeout(() => {
                modal.destroy();
              }, 3000);
            } else {
              this.setState({
                currentMember: membersBacks.result || []
              },async()=>{
                if(membersBacks.result&&membersBacks.result.length>0){
                  let  currentMember = membersBacks.result;
                  let memberIds=[]
                  ;
                  currentMember.map((item,index)=>{
                    memberIds.push(item.userid)
                  })
                  let idsParams={ids:memberIds}
                  let gbdbGetUser = await userApis.gbdbGetUserByIds(idsParams)
                  if(gbdbGetUser.result){
                    this.setState({
                      userPhotos:gbdbGetUser.result
                    })
                  }else{
                    this.setState({
                      userPhotos:{}
                    })
                  }
                  }
              });
            }
          }
        }
      );
    });
  }
  deleteMember = (item, index, e) => {
    let that = this;

    let username = item.username;
    Modal.confirm({
      title: "This is a notification message",
      content: `Are you sure to delete the ${username}`,
      async onOk() {
        let params = {
          groupid: item.groupid,
          uid: item.uid
        };

        let deleteBack = await groupApis.deletePerson(params);

        if (deleteBack.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: deleteBack.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 3000);
        } else {
          let { currentMember, myGroups, currentGroupIndex } = that.state;
          currentMember.splice(index, 1);
          // myGroups.splice(currentGroupIndex, 1);
          that.setState(
            {
              currentMember
            },
            () => {
              const modal = Modal.success({
                title: "This is an successful message",
                content: "Has been deleted"
              });
              setTimeout(() => {
                modal.destroy();
              }, 3000);
            }
          );
        }
      },
      onCancel() {}
    });
  };
  handleInfiniteOnLoad = () => {
    // let { currentMember } = this.state;
    // this.setState({
    //   loading: true
    // });
    // if (currentMember.length > 14) {
    //   message.warning("Infinite List loaded all");
    //   this.setState({
    //     hasMore: false,
    //     loading: false
    //   });
    //   return;
    // }
    // this.getGroups(res => {
    //   currentMember = currentMember.concat(res.result);
    //   this.setState({
    //     currentMember,
    //     loading: false
    //   });
    // });
  };
  getGroupMembers = async (item, index) => {
    let groupid = item.groupid;
    let params = { groupid, state: 2 };
    let membersBacks = await groupApis.getPersonInGroup(params);

    if (membersBacks.error) {
      const modal = Modal.error({
        title: "This is an error message",
        content: membersBacks.error
      });
      setTimeout(() => {
        modal.destroy();
      }, 3000);
    } else {
      this.setState({
        currentMember: membersBacks.result || [],
        currentGroupId: groupid,
        currentGroupIndex: index,
        currentCreatorId: item.creator
      },async()=>{
        if(membersBacks.result&&membersBacks.result.length>0){
        let  currentMember = membersBacks.result;
        let memberIds=[]
        ;
        currentMember.map((item,index)=>{
          memberIds.push(item.userid)
        })
        let idsParams={ids:memberIds}
        let gbdbGetUser = await userApis.gbdbGetUserByIds(idsParams)
        if(gbdbGetUser.result){
          this.setState({
            userPhotos:gbdbGetUser.result
          })
        }else{
          this.setState({
            userPhotos:{}
          })
        }
        }
      });
    }
  };
  LeaveGroup = async () => {
    let that = this;
    Modal.confirm({
      title: "Do you Want to leave the group?",

      async onOk() {
        let uid = Storage.getItem("gbdbid");

        let { currentGroupId } = that.state;

        let params = {
          groupid: currentGroupId,
          uid
        };
        let exitBack = await groupApis.exitGroups(params);
        if (exitBack.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: exitBack.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 3000);
        } else {
          //  如果退出的话  就重新设置group列表
          const modal = Modal.success({
            title: "This is an successful message",
            content: "Leave successfully"
          });
          let { myGroups, currentGroupIndex, currentMember } = that.state;
          myGroups.splice(currentGroupIndex, 1);
          let currentGroupId = "";
          if (myGroups.length > 0) {
            let groupid = myGroups[0].groupid;
            currentGroupId = myGroups[0].groupid;
            let params = { groupid, state: 2 };
            let membersBacks = await groupApis.getPersonInGroup(params);
            if (membersBacks.result) {
              currentMember = membersBacks.result;
            } else {
              currentMember = [];
            }
          } else {
            currentMember = [];
          }

          that.setState({
            myGroups,
            currentGroupId,
            currentMember
          });
          setTimeout(() => {
            modal.destroy();
          }, 3000);
        }
      },
      onCancel() {
       
      }
    });
  };
  goGroupFile = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState(
      {
        currentGroupId: item.groupid,
        creatorId: item.creator
      },
      () => {
        let { currentGroupId, creatorId } = this.state;
        let userid = Storage.getItem("gbdbid");
        let baseUrl = "/subset";

        let paramsUrl = "";
        let url = "";
        // ${uidUrl}&&
        if (userid && currentGroupId) {
          if (userid !== creatorId) {
            paramsUrl = `groupid=${currentGroupId}`;
            url = `${baseUrl}?${paramsUrl}`;
          } else if (userid === creatorId) {
            url = `${baseUrl}`;
          }
        } else {
          url = "/home";
        }

        this.props.history.push(url);
      }
    );
  };
  render() {
    let uid = Storage.getItem("gbdbid");
    let { myGroups, currentMember, currentGroupId } = this.state;
  
    return (
      <div className="group-list">
        <Layout className="group-list-contents">
          <Header className="header">
            <div className="">
              <p>My group list</p>
            </div>
          </Header>
          {myGroups.length > 0 ? (
            <Content>
              <Layout>
                <Sider width={207}>
                  <Menu
                    mode="inline"
                    defaultSelectedKeys={[this.state.currentGroupId.toString()]}
                    defaultOpenKeys={[this.state.currentGroupId.toString()]}
                    style={{ height: "100%" }}
                    className="all-groups"
                  >
                    {myGroups.map((item, index) => {
                      return (
                        <Menu.Item
                          key={item.groupid}
                          className="group-item-name"
                        >
                          <div
                            className="group-item-members"
                            onClick={() => this.getGroupMembers(item, index)}
                          >
                            <span
                              title={item.name}
                              className={`${
                                item.creator === uid
                                  ? "ant-form-item-required"
                                  : ""
                              }`}
                            >
                              {item.name}
                            </span>

                            <span
                              title="see the group subset"
                              className="iconfont icon-file sharefolder-icon"
                              onClick={e => this.goGroupFile(item, e)}
                            ></span>
                          </div>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                </Sider>
                <Content
                  className={`group-item-content ${
                    myGroups.length > 0 ? "shadow" : ""
                  }`}
                >
                  {
                    <InfiniteScroll
                      initialLoad={false}
                      pageStart={0}
                      loadMore={this.handleInfiniteOnLoad}
                      hasMore={!this.state.loading && this.state.hasMore}
                      useWindow={false}
                      className="group-members-list"
                    >
                      <ul className="all-user-groups">
                        {currentMember.map((item, index) => {
                          return (
                            <li className="member-item" key={index}>
                              <div className="member-avatar">
                                {
                                  JSON.stringify(this.state.userPhotos)==="{}"?
                                  <img
                                  src={require("src/assets/images/group/user.png")}
                                  alt=""
                                />
                                :
                                <img
                                src={'/api'+this.state.userPhotos[item.userid]}
                                alt=""
                              />
                                }
                              
                              </div>
                              <div className="member-detail">
                                <div className="member-detail-member">
                                  <span className="member-name">
                                    {item.username}
                                  </span>
                                </div>

                                {Storage.getItem("gbdbid") === item.creator &&
                                Storage.getItem("gbdbid") !== item.uid ? (
                                  <div
                                    className="member-delete"
                                    onClick={e =>
                                      this.deleteMember(item, index, e)
                                    }
                                  >
                                    <Button type="gbdb">Delete</Button>
                                  </div>
                                ) : null}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </InfiniteScroll>
                  }
                  {myGroups.length > 0 &&
                  Storage.getItem("gbdbid") !== this.state.currentCreatorId ? (
                    <div className="user-leave-group">
                      <Button
                        type="gbdb"
                        className="leave-btn"
                        onClick={this.LeaveGroup}
                      >
                        Leave
                      </Button>
                    </div>
                  ) : null}
                </Content>
              </Layout>
            </Content>
          ) : (
            <Content className="has-nogroup">Currently has no group</Content>
          )}
        </Layout>
      </div>
    );
  }
}
