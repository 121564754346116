import React from 'react'
import {Form,Button,Pagination, message, Modal,Input} from 'antd'
import axios from 'axios'
import moment from 'moment'
import UnitInfoModal from './unitInfoModal'

export default class StraForm extends React.Component{
    constructor(props){
        super(props)
    }
    state={
        tableData:[],
        nowPage:1,
        defaultPageSize:1,
        unitData:[],
        unitInfo:{},
        isShowUnitInfo:false,
        isLoading:true
        
    }
    componentDidMount(){
        var section_basic_id=this.props.sectionId
        var sendData={
            assetstype:4,
            section_basic_id,
        }

        axios.get('/api/admin/searchAssets',{
            params:sendData
        }).then(res=>{
            var data=res.data.result;
            if(res.data.error===null){
                this.setState({
                    tableData:res.data.result,
                    isLoading:false
                })
                if(data.length>0){
                    var firststra=res.data.result[0].formation_id;
                    this.getUnit(firststra)
                }
               
            }
            if(data.length===0){
                // message.warning('没有数据')
            }
           
        }).catch(err=>{
            message.error('出错啦~')
        })
    }
    getUnit(id){
        var sendData={
            formation_id:id,
            assetstype:5,
        }
   
        axios.get('/api/admin/searchAssets',{
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                
                this.setState({
                    unitData:res.data.result
                })
            }
        })
    }
    passStra(){
        Modal.confirm({
            content:`点击“确定”后，Statigraphy&Lithology(共 ${this.state.tableData.length} 条数据)及包含的Unit将通过审核!`,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                //请求通过
                var sendData={
                    section_basic_id:this.props.sectionId,
                    authState:1,
                    auth_time:moment().format('YYYY/MM/DD'),
                }
                axios.post('/api/admin/review/formations',sendData).then(res=>{
                    if(res.data.error===null){
                        this.props.cancelShowInfo()

                    }
                })
              
            }
        })
    }
    handleReject(){
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                var sendData={
                    section_basic_id:this.props.sectionId,
                    authState:2,
                    auth_time:moment().format('YYYY/MM/DD'),
                    auth_comment:str
                }
                axios.post('/api/admin/review/formations',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已驳回！')
                        this.props.cancelShowInfo()
                    }
                })
               
            },
          
        })
    }
    changePage(page,pageSize){
        this.setState({
            nowPage:page
        })
        var n=this.state.tableData[page-1].formation_id;
       
        this.getUnit(n)
    }
    showUnitInfo(item){
        this.setState({
            unitInfo:item
        },()=>{
            this.setState({
                isShowUnitInfo:true
            })
        })
    }
    closeUnitInfo(){
        this.setState({
            isShowUnitInfo:false
        })
    }
    
    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 5 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 19 },
            },
          };
        const {tableData,nowPage,defaultPageSize,unitData,isShowUnitInfo,isLoading}=this.state
        return (
        <div>
            {
                <div style={isLoading?{display:'block'}:{display:'none'}} className='tip1'>Loading...</div>

            }
            {
                tableData.length===0 && !isLoading?(<div>
                    <div className='tip'>暂无数据</div>
                    <div style={{textAlign:'center',height:'80px'}}><Button type='agbdb1'onClick={()=>{this.props.cancelShowInfo()}}>关闭</Button></div>
                </div>):null
            }
            {
                tableData.length>0?
            
            (<div>
                {
                    tableData.length>1?(<div className='tip'>Stratigraphy&Lithology共<span> {tableData.length} </span>条数据，页面下方可翻页！</div>):null
                }
                
                <Form {...formItemLayout}>
                    <Form.Item label='Reference ID'>
                        <span>{tableData[nowPage-1].ref_id}</span>
                    </Form.Item>

                    <Form.Item label='Formation No'>
                        <span>{tableData[nowPage-1].formation_no}</span>
                    </Form.Item>

                    <div className='line'>
                        <Form.Item className='lineItem' label='Group name'>
                            <span>{tableData[nowPage-1].formation_group}</span>
                        </Form.Item>

                        <Form.Item className='lineItem' label='Member'>
                            <span>{tableData[nowPage-1].formation_member}</span>
                        </Form.Item>
                    </div>

                    <div className='line'>
                        <Form.Item className='lineItem' label='Formation'>
                            <span>{tableData[nowPage-1].formation_id}</span>
                        </Form.Item>

                        <Form.Item className='lineItem' label='Bed'>
                            <span>{tableData[nowPage-1].formation_bed}</span>
                        </Form.Item>
                    </div>

                    <div className='line'>
                        <Form.Item className='lineItem' label='Early interval'>
                            <span>{tableData[nowPage-1].early_interval}</span>
                        </Form.Item>

                        <Form.Item className='lineItem' label='Intage max'>
                            <span>{tableData[nowPage-1].intage_max}</span>
                        </Form.Item>
                    </div>

                    <div className='line'>
                        <Form.Item className='lineItem' label='Early age'>
                            <span>{tableData[nowPage-1].early_age}</span>
                        </Form.Item>

                        <Form.Item className='lineItem' label='Late age'>
                            <span>{tableData[nowPage-1].late_age}</span>
                        </Form.Item>
                    </div>

                    <div className='line'>
                        <Form.Item className='lineItem lineHeight' label={<div>Paleogeograhy<br/>Latitude Decimal</div>}>
                            <span>{tableData[nowPage-1].geo_latitude_decimal}</span>
                        </Form.Item>

                        <Form.Item className='lineItem' label='Longtitude Decimal'>
                            <span>{tableData[nowPage-1].geo_longitude_decimal}</span>
                        </Form.Item>
                    </div>

                    <div className='line'>
                        <Form.Item className='lineItem' label='Overlying'>
                            <span>{tableData[nowPage-1].formation_overlying}</span>
                        </Form.Item>

                        <Form.Item className='lineItem' label='Underline'>
                            <span>{tableData[nowPage-1].formation_underline}</span>
                        </Form.Item>
                    </div>

                    <div className='line'>
                        <Form.Item className='lineItem' label='Color'>
                            <span>{tableData[nowPage-1].formation_color}</span>
                        </Form.Item>

                        <Form.Item className='lineItem' label='Lithology'>
                            <span>{tableData[nowPage-1].formation_lithology}</span>
                        </Form.Item>
                    </div>

                    <Form.Item label='Thickness'>
                        <span>{tableData[nowPage-1].formation_thick?(tableData[nowPage-1].formation_thick_sign+tableData[nowPage-1].formation_thick+tableData[nowPage-1].formation_thick_unit):0}</span>
                    </Form.Item>

                    <Form.Item label='Contact base'>
                        <span>{tableData[nowPage-1].formation_conta_base}</span>
                    </Form.Item>

                    <Form.Item label='Paleoenvironment'>
                        <span>{tableData[nowPage-1].formation_paleoenvironment}</span>
                    </Form.Item>
                </Form>
                {
                    unitData.length===0?(<div className='tip'>暂无Unit数据</div>)
                    :
                    (
                        <div className='modalTable'>
                            <div className='tip1'>Unit数据</div>
                            <table>
                                <tbody>
                                    <tr className='head'>
                                        <td>序号</td>
                                        <td>Reference ID</td>
                                        <td>Formation ID</td>
                                        <td>Unit ID</td>
                                        <td>操作</td>
                                    </tr>
                                    {
                                        unitData.map((item,index)=>{
                                            return (
                                                <tr key={index} className='tr'>
                                                    <td>{index+1}</td>
                                                    <td>{item.ref_id}</td>
                                                    <td>{item.formation_id}</td>
                                                    <td>{item.unit_id}</td>
                                                    <td>{<Button type='link' onClick={this.showUnitInfo.bind(this,item)}>详情</Button>}</td>
                                                </tr>
                                            )
                                        })
                                    }
    
                                </tbody>
                            </table>
                            {
                                isShowUnitInfo?(
                                    <UnitInfoModal
                                    visible={isShowUnitInfo}
                                    info={this.state.unitInfo}
                                    handleClose={this.closeUnitInfo.bind(this)}
                                    ></UnitInfoModal>
                                ):null
                            }
                            

                        </div>
                    )
                }
                
                {
                    tableData.length>1?(
                        <div className='pages'>
                            <Pagination size='small' 
                            current={nowPage}
                            defaultPageSize={defaultPageSize} 
                            total={tableData.length} 
                            // showTotal={showTotal.bind(this)}
                            onChange={this.changePage.bind(this)}
                            ></Pagination>
                        </div>
                    ):null
                }
                <div className='foot'>
                        <Button  type='agbdb1' onClick={this.passStra.bind(this)}>通过</Button>
                        <Button type='agbdb2' onClick={this.handleReject.bind(this)}>拒绝</Button>
                </div>
            </div>):null
            }
            
        </div>
        )
    }
}