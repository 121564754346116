import React from "react";

import { Form, Input, Select, Button, Modal, InputNumber, message, AutoComplete } from "antd";
import Apis from "src/js/apis/api";
import dataApis from "src/js/apis/data";
import centerApis from "src/js/apis/center";
import "./index.css";
import Storage from "src/js/storage";
import { withRouter } from "react-router-dom";
import Utils from "src/js/utils";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
@withRouter
class FormationDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      // reference , taxonomy , section,occurence,formation
      detailData: props.detailData || {},
      sectionData: {},
      seeOnly: props.seeOnly === true ? true : false,
      formationId: "",
      geographyId: "",
      isCenterUser: props.isCenterUser === true ? true : false,

      Intervals: [],
      Intages: [],
      latitude: null,
      longitude: null,
      intervalDataitem: {},
    };
  }
  async componentDidMount() {
    let formationId = "";
    let FormationsBack;
    let { detailData } = this.state;
    let { isCenterUser } = this.state;

    // let isCenter=(this.props.location.pathname==='/center')
    if (detailData && detailData.formation_id !== undefined) {
      formationId = detailData.formation_id;
    }

    this.setState(
      {
        formationId,
      },
      async () => {
        let { formationId } = this.state;
        if (formationId !== "") {
          if (!isCenterUser) {
            let params = {
              formation_id: formationId,
            };

            FormationsBack = await Apis.getFormations(params);
          } else {
            let params = {
              assetstype: 11,
              enterer: Storage.getItem("gbdbid"),
              formation_id: formationId,
            };
            FormationsBack = await centerApis.searchAssets(params);
          }

          if (FormationsBack.result && FormationsBack.result.length > 0) {
            this.setState(
              {
                detailData: FormationsBack.result[0],
              },
              () => {
                if (detailData.geology_id !== null) {
                  this.getGeologyInfo(detailData.geology_id);
                }
              }
            );
          } else {
            this.setState({
              detailData: {},
            });
          }
        }
      }
    );
  }
  getGeologyInfo = async (geographyId) => {
    let params = {
      geology_id: geographyId,
    };
    let getGeologyBack = await Apis.getGeology(params);

    if (getGeologyBack.result) {
      this.setState(
        {
          geologyInfo: getGeologyBack.result.length > 0 ? getGeologyBack.result[0] : {},
          latitude: getGeologyBack.result.length > 0 ? getGeologyBack.result[0].geology_latitude_decimal : null,
          longitude: getGeologyBack.result.length > 0 ? getGeologyBack.result[0].geology_longitude_decimal : null,
        },
        () => {}
      );
    } else {
      Modal.error({
        title: "This is an error message",
        content: getGeologyBack.error,
      });
    }
  };
  modifyFormation = () => {
    let { refInfo, detailData } = this.state;
    let that = this;
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let earlyValue = this.props.form.getFieldValue("early_age");
        let lateValue = this.props.form.getFieldValue("late_age");
        if (!isNaN(earlyValue) && !isNaN(lateValue)) {
          if (parseFloat(earlyValue) < parseFloat(lateValue)) {
            message.error("early_age must be greater than late_age");

            return;
          }
        }

        if (values.formation_thick !== "" && values.formation_thick !== null && values.formation_thick !== undefined) {
          if (values.formation_thick_unit === "cm") {
            let value = parseFloat(values.formation_thick) / 100;
            values.formation_thick = parseFloat(value.toFixed(4));
            values.formation_thick_unit = "meters";
          } else if (values.formation_thick_unit === "feet") {
            let value = parseFloat(values.formation_thick) * 0.3048;
            values.formation_thick = parseFloat(value.toFixed(4));
            values.formation_thick_unit = "meters";
          }
        }
        let formationInfo = Utils.filterCondition(values);
        formationInfo = Utils.addEnterer(formationInfo);
        let searchParams = {
          geology_id: values.geology_id,
        };
        let sectionBase = await Apis.getGeology(searchParams);
        let sectionData = {};
        if (sectionBase.result && sectionBase.result.length > 0) {
          sectionData = sectionBase.result[0];
        }

        formationInfo = Utils.addAudit(formationInfo, sectionData);

        let sectionId = values.section_basic_id;
        formationInfo.id = detailData.formation_id;
        formationInfo.authState = 0;

        // 地质年代最后的填充
        let earlyInterval = this.props.form.getFieldValue("early_interval");
        let isCalculate = true;
        if ((earlyValue === "" || earlyValue === null || earlyValue === undefined) && (lateValue === "" || lateValue ===null || lateValue === undefined)) {
          isCalculate = false;
        }
        // 如果early_age或late_age不为空
       
        if (isCalculate) {
          let params = {
            early_age: earlyValue,
            late_age: lateValue,
          };
          let TimeLinesById = await Apis.getEarlyInterval(params);
          if (TimeLinesById && TimeLinesById.result) {
            let TimeLines = TimeLinesById.result;

            if (TimeLines) {
              let erathem_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.erathem_max ? TimeLines.ages.erathem_max.name : "";
              let period_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.period_max ? TimeLines.ages.period_max.name : "";
              let epoch_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.epoch_max ? TimeLines.ages.epoch_max.name : "";
              let early_interval = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.early_interval ? TimeLines.ages.early_interval.name : "";

              if (erathem_max !== "") {
                formationInfo.erathem_max = erathem_max;
                formationInfo.age_color = TimeLines.ages.erathem_max.color;
              }
              if (period_max !== "") {
                formationInfo.period_max = period_max;
                formationInfo.age_color = TimeLines.ages.period_max.color;
              }
              if (epoch_max !== "") {
                formationInfo.epoch_max = epoch_max;
                formationInfo.age_color = TimeLines.ages.epoch_max.color;
              }
              if (early_interval !== "") {
                if (earlyInterval !== "" && earlyInterval !== null && earlyInterval !== undefined) {
                  if (early_interval == earlyInterval) {
                    formationInfo.age_color = TimeLines.ages.early_interval.color;
                  }
                } else {
                  formationInfo.early_interval = early_interval;
                  formationInfo.age_color = TimeLines.ages.early_interval.color;
                }
              }
            }
          } else {
            if (TimeLinesById.error) {
              Modal.error({
                content: TimeLinesById.error,
              });
            }
          }

        
          that.changeFormationDetail(formationInfo);
        } else {
          let { intervalDataitem } = this.state;
          if (JSON.stringify(intervalDataitem) !== "{}") {
            let params = { id: intervalDataitem.chronostratigraphy_id };
            let TimeLinesById = await Apis.getParentTimeLinesById(params);
            if (TimeLinesById && TimeLinesById.result) {
              let TimeLines = TimeLinesById.result;

              if (TimeLines) {
                let erathem_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.erathem_max ? TimeLines.ages.erathem_max.name : "";
                let period_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.period_max ? TimeLines.ages.period_max.name : "";
                let epoch_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.epoch_max ? TimeLines.ages.epoch_max.name : "";
                let early_interval = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.early_interval ? TimeLines.ages.early_interval.name : "";

                if (erathem_max !== "") {
                  formationInfo.erathem_max = erathem_max;
                  formationInfo.age_color = TimeLines.ages.erathem_max.color;
                }
                if (period_max !== "") {
                  formationInfo.period_max = period_max;
                  formationInfo.age_color = TimeLines.ages.period_max.color;
                }
                if (epoch_max !== "") {
                  formationInfo.epoch_max = epoch_max;
                  formationInfo.age_color = TimeLines.ages.epoch_max.color;
                }
                if (early_interval !== "") {
                  if (early_interval == earlyInterval) {
                    formationInfo.age_color = TimeLines.ages.early_interval.color;
                  }
                }

                // 颜色处理
              }
            } else {
              if (TimeLinesById.error) {
                Modal.error({
                  content: TimeLinesById.error,
                });
              }
            }
          }
          that.changeFormationDetail(formationInfo);
        }
      }
    });
  };
  changeFormationDetail = async (formationInfo) => {
    let changeFormation = await Apis.changeFormationById(formationInfo);
    if (changeFormation.error) {
      Modal.error({
        title: "This is an error message",
        content: changeFormation.error,
      });
    } else if (changeFormation.result) {
      if (this.props.modifyFormation) {
        this.props.modifyFormation(changeFormation.result);
      }
    }
  };
  changePaleogeography = async (age) => {
    if (
      this.state.latitude === "" ||
      this.state.latitude === null ||
      this.state.latitude === undefined ||
      this.state.longitude === "" ||
      this.state.longitude === null ||
      this.state.longitude === undefined ||
      age === "" ||
      age === null ||
      age === undefined
    ) {
      return;
    } else {
      let params = {
        age,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
      };
      let Paleogeography = await Apis.gpsConverts(params);

      if (Paleogeography.result) {
        this.props.form.setFieldsValue({
          geo_latitude_decimal: Paleogeography.result.geoLatitude,
          geo_longitude_decimal: Paleogeography.result.geoLongitude,
        });
      } else {
        if (Paleogeography.error) {
          Modal.error({
            title: "This is an error message",
            content: Paleogeography.error,
          });
        }
      }
    }
  };
  IntervalChange = async (value, e, field) => {
    let dataitem = e.props.dataitem;

    if (dataitem) {
      let age = (parseFloat(dataitem.early_age) + parseFloat(dataitem.late_age)) / 2;
      this.changePaleogeography(age);
      this.props.form.setFieldsValue({
        early_age: parseFloat(dataitem.early_age),
        late_age: parseFloat(dataitem.late_age),
      });
      this.setState(
        {
          intervalDataitem: dataitem,
        },
        () => {
          
        }
      );
    }else {
      this.setState(
        {
          intervalDataitem: {},
        },
        () => {}
      );
    }
    this.props.form.setFieldsValue({
      [field]: value,
    });
  };
  IntervalSearch = async (value, type, key) => {
    let params = {
      keyword: value,
    };

    let geoSearchBack = await dataApis.autoCompleteInterval(params);

    let searchReust = geoSearchBack.result || [];
    this.setState(
      {
        [type]: searchReust,
      },
      () => {}
    );
  };
  Calculate = async (e) => {
    let value = e.target.value;
    let type = e.target.name;
    let earlyValue = this.props.form.getFieldValue("early_age");
    let lateValue = this.props.form.getFieldValue("late_age");

    if (!isNaN(earlyValue) && !isNaN(lateValue)) {
      if (parseFloat(earlyValue) < parseFloat(lateValue)) {
        message.error("early_age must be greater than late_age");
        this.props.form.setFieldsValue({
          geo_latitude_decimal: null,
          geo_longitude_decimal: null,
        });

        return;
      }
    }

    if (e.target.value) {
      if (type === "early_age") {
        let lateAge = this.props.form.getFieldValue("late_age");

        if (Utils.isNumber(lateAge)) {
          let age = (parseFloat(value) + parseFloat(lateAge)) / 2;

          this.changePaleogeography(age);
        }
      } else if (type === "late_age") {
        let earlyAge = this.props.form.getFieldValue("early_age");
        if (Utils.isNumber(earlyAge)) {
          let age = (parseFloat(value) + parseFloat(earlyAge)) / 2;

          this.changePaleogeography(age);
        }
      }
    }
  };
  convertUnit = (e) => {
    const { getFieldValue } = this.props.form;
    let resultAltitude, resultUnit;
    let altitude = getFieldValue("formation_thick");
    let unit = getFieldValue("formation_thick_unit");
    if (altitude !== "" && altitude !== null && altitude !== undefined) {
      if (unit == "meters") {
        let altitudess = parseFloat(altitude) * 3.2808399;
        resultAltitude = parseFloat(altitudess.toFixed(4));
        resultUnit = "feet";
      } else if (unit == "feet") {
        let altitudess = parseFloat(altitude) * 0.3048;
        resultAltitude = parseFloat(altitudess.toFixed(4));
        resultUnit = "meters";
      }
      this.props.form.setFieldsValue({
        formation_thick: resultAltitude,
        formation_thick_unit: resultUnit,
      });
    }
  };
  render() {
    let { getFieldDecorator } = this.props.form;

    let { detailData, seeOnly, Intervals, Intages } = this.state;
    let { enterer } = detailData;
    let userid = Storage.getItem("gbdbid");
    let isClick = false;
    let isCenter = this.props.location.pathname === "/center";
    if (enterer && userid && enterer === userid && isCenter) {
      isClick = true;
    }
    if (isClick === false) {
      getFieldDecorator = (...rest) => {
        return (element) => {
          let NewElement = React.cloneElement(element, {
            disabled: true,
          });
          return this.props.form.getFieldDecorator(...rest)(NewElement);
        };
      };
    }
    const intervals = Intervals.map((item, index) => (
      <Option value={item.interval_name} dataitem={item} key={item.interval_name}>
        {item.interval_name}
      </Option>
    ));
    const intages = Intages.map((item, index) => (
      <Option value={item.interval_name} dataitem={item} key={item.interval_name}>
        {item.interval_name}
      </Option>
    ));
    return (
      <div id="detail_form">
        <div>
          <div className="detailform-title">
            <span className="detailform-name">Formation</span>
          </div>
          <div className="sectionFormation-form" id="main">
            <Form>
              <div className="referenceAdd-title reference-inherit">
                {
                  // <span className="geography-ref-item" style={{ width: `${singleWidth}%` }}>
                  <span className="geography-ref-item">
                    <Form.Item label="Reference ID">
                      {getFieldDecorator(`ref_id`, {
                        rules: [
                          {
                            required: true,
                            message: "Please input the Reference",
                          },
                        ],
                        initialValue: detailData.ref_id || "",
                      })(<Input className="inherit-input" disabled></Input>)}
                    </Form.Item>
                  </span>
                }
              </div>

              <div className="referenceAdd-title">
                <Form.Item label="Formation No">
                  {getFieldDecorator("formation_no", {
                    rules: [
                      {
                        validator: (rule, value, cb) => {
                          !isNaN(value) ? cb() : cb(true);
                        },

                        required: true,
                        message: "Please input the correct Formation No",
                      },
                    ],
                    initialValue: detailData.formation_no || null,
                  })(<Input disabled></Input>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-title reference-inherit">
                {
                  // <span className="geography-ref-item" style={{ width: `${singleWidth}%` }}>
                  <span className="geography-ref-item">
                    <Form.Item label="Geography ID">
                      {getFieldDecorator(`geology_id`, {
                        rules: [
                          {
                            required: true,
                            message: "Please input the Section Geography ID",
                          },
                        ],
                        initialValue: detailData.geology_id || "",
                      })(<Input className="inherit-input" disabled></Input>)}
                    </Form.Item>
                  </span>
                }
              </div>
              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Group">
                    {getFieldDecorator("formation_group", {
                      initialValue: detailData.formation_group || "",
                    })(<Input />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Bed">
                    {getFieldDecorator("formation_bed", {
                      rules: [{}],
                      initialValue: detailData.formation_bed || "",
                    })(<Input />)}
                  </Form.Item>
                </div>
              </div>
              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Formation">
                    {getFieldDecorator("formation_name", {
                      rules: [
                        {
                          required: true,
                          message: "Please input the Formation",
                        },
                      ],
                      initialValue: detailData.formation_name || "",
                    })(<Input />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Member">
                    {getFieldDecorator("formation_member", {
                      initialValue: detailData.formation_member || "",
                    })(<Input />)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Early interval">
                    {getFieldDecorator("early_interval", {
                      initialValue: detailData.early_interval || null,
                    })(
                      <AutoComplete
                        onSearch={(value) => this.IntervalSearch(value, "Intervals", "early_interval")}
                        onChange={(value, e) => this.IntervalChange(value, e, "early_interval")}
                       
                      >
                        {intervals}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Intage max">
                    {getFieldDecorator("intage_max", {
                      initialValue: detailData.intage_max || null,
                    })(
                      <AutoComplete onSearch={(value) => this.IntervalSearch(value, "Intages", "intage_max")} onChange={(value, e) => this.IntervalChange(value, e, "intage_max")} >
                        {intages}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Early age">
                    {getFieldDecorator("early_age", {
                      rules: [{ type: "number" }],
                      initialValue: detailData.early_age !== null ? parseFloat(detailData.early_age) : null,
                    })(
                      <InputNumber
                        className="age-format-unit"
                        // formatter={value => value ? `${value}Ma` : ""}
                        // parser={value => value.replace('Ma', '')}
                        name="early_age"
                        step={0.01}
                        onBlur={(e) => this.Calculate(e)}
                        min={0}
                      ></InputNumber>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Late age">
                    {getFieldDecorator("late_age", {
                      rules: [{ type: "number" }],
                      initialValue: detailData.late_age !== null ? parseFloat(detailData.late_age) : null,
                    })(
                      <InputNumber
                        className="age-format-unit"
                        // formatter={value => value ? `${value}Ma` : ""}
                        // parser={value => value.replace('Ma', '')}
                        name="late_age"
                        step={0.01}
                        min={0}
                        onBlur={(e) => this.Calculate(e)}
                      ></InputNumber>
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item
                    label={
                      <div className="sectionGeography-scale">
                        <p>Paleogeography Latitude Decimal</p>
                      </div>
                    }
                  >
                    {getFieldDecorator("geo_latitude_decimal", {
                      rules: [{ type: "number" }],
                      initialValue: detailData.geo_latitude_decimal || null,
                    })(<InputNumber disabled></InputNumber>)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item
                    label={
                      <div className="sectionGeography-scale">
                        <p>Longtitude Decimal</p>
                      </div>
                    }
                  >
                    {getFieldDecorator("geo_longitude_decimal", {
                      rules: [{ type: "number" }],
                      initialValue: detailData.geo_longitude_decimal || null,
                    })(<Input disabled></Input>)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Overlying">
                    {getFieldDecorator("formation_overlying", {
                      initialValue: detailData.formation_overlying || "",
                    })(<Input />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Underline">
                    {getFieldDecorator("formation_underline", {
                      rules: [{}],
                      initialValue: detailData.formation_underline || "",
                    })(<Input />)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Color">
                    {getFieldDecorator("formation_color", {
                      initialValue: detailData.formation_color || "",
                    })(<Input />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Main Lithology">
                    {getFieldDecorator("formation_lithology", {
                      rules: [{}],
                      initialValue: detailData.formation_lithology || null,
                    })(<Input />)}
                  </Form.Item>
                </div>
              </div>

              <div className="sectionGeography-map sectionGeography-map-nopadding">
                <div className="sectionGeography-tutude">
                  <span className="sectionFormation-thickness">
                    <FormItem label="Thickness">
                      {getFieldDecorator("formation_thick_sign", {
                        initialValue: "=",
                      })(
                        <Select>
                          <Option value="=">=</Option>
                          <Option value="<">&lt;</Option>
                          <Option value=">">&gt;</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>

                  <span>
                    <FormItem>
                      {getFieldDecorator("formation_thick", {
                        initialValue: detailData.formation_thick || null,
                      })(<Input ></Input>)}
                    </FormItem>
                  </span>

                  <span>
                    <FormItem>
                      {getFieldDecorator("formation_thick_unit", {
                        initialValue: detailData.formation_thick_unit || "meters",
                      })(
                        <Select>
                          <Option value="meters">Meter</Option>
                          <Option value="cm">Cm</Option>
                          <Option value="feet">Feet</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                  <span className="iconfont icon-shuaxin result-convert-unit" title="convert unit" onClick={(e) => this.convertUnit(e)}></span>
                </div>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item label="Contact base">
                  {getFieldDecorator("formation_conta_base", {
                    rules: [{}],
                    initialValue: detailData.formation_conta_base || null,
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-comments">
                <Form.Item label={<p>Paleoenviroment</p>} className="long-label">
                  {getFieldDecorator("formation_paleoenvironment", {
                    rules: [{}],
                    initialValue: detailData.formation_paleoenvironment || null,
                  })(<TextArea rows={6}></TextArea>)}
                </Form.Item>
              </div>

              {/* <div className="referenceSearch-btns sectionBase-btns">
                  <div className="detail-formation-btns">
                    <Button type="gbdb" onClick={() => this.viewGeochemical()}>
                      <div>
                        <p> view Geochemical</p>
                      </div>
                    </Button>
                    <Button type="gbdb" onClick={() => this.viewUnit()}>
                      <div>
                        <p> view Unit</p>
                      </div>
                    </Button>
                  </div>
                </div> */}
              {detailData.auth_time !== null ? (
                <div className="referenceAdd-authours referenceAdd-editors">
                  <div className="authcomment-details">
                    {detailData.authState === 2 ? (
                      <div className="referenceAdd-author authcomment-detail-item">
                        <div className="referenceAdd-author-title authcomment-title">Auth Comment</div>
                        <div className="authcomment-content">{detailData.auth_comment !== "" ? detailData.auth_comment : "No comment"}</div>
                      </div>
                    ) : null}
                    {detailData.authState !== 0 ? (
                      <div className="referenceAdd-author authcomment-detail-item">
                        <div className="referenceAdd-author-title authcomment-title">Auth Time</div>
                        <div className="authcomment-content">{detailData.auth_time ? detailData.auth_time.split("T")[0] : ""}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Form>
          </div>
        </div>
        <div className="referenceSearch-btns sectionBase-btns">
          {/* <div className="source-inner-btns">
            <Button type="gbdb" onClick={() => this.viewFossils()}>
              <div>
                <p>Lithostratigraphy</p>
                <p>& Fossils</p>
              </div>
            </Button>
            <Button type="gbdb" onClick={() => this.viewFormation()}>
              <div>
                <p>Stratigraphy</p>
                <p>&Lithology</p>
              </div>
            </Button>
            <Button type="gbdb" onClick={() => this.viewGeochemical()}>
              <div>
                <p>Geochemical</p>
                <p> Data</p>
              </div>
            </Button>
          </div> */}
          <div className="formation-complete-btns">
            {isClick && seeOnly === false ? (
              <Button type="gbdb" onClick={() => this.modifyFormation()}>
                <p>Save modify</p>
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
FormationDetailForm = Form.create({})(FormationDetailForm);
export default FormationDetailForm;
