import React, { Component } from "react";
import { Modal, message, Spin, Form } from "antd";

import InfiniteScroll from "react-infinite-scroller";

import messageApis from "src/js/apis/message";
import moment from "moment";

import "./index.css";

class NewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasMore: true,
      messageLists: [],
      isRange: false,
      mesTotal: 0,
      pageSize: 20,
      currentPage: 1,
     
    };
  }

  async componentDidMount() {
    let { pageSize, currentPage } = this.state;
    let pageParams = {
      pageSize,
      page: currentPage
    };
    await this.getNews(pageParams, res => {
      this.setState({
        messageLists: res.result,
        mesTotal: res.total
      },()=>{
        
      });
    });
  }

  getNews = async (pageParams, callback) => {
    this.setState({
      loading:true
    },async()=>{
      let params = {};
      let orderParams = { ordername: "createdAt", orderType: 1 };
      Object.assign(params, pageParams, orderParams);
      
      let MessagesBack = await messageApis.getNews(params);
      if (MessagesBack.error) {
        const modal = Modal.error({
          title: "This is an error message",
          content: MessagesBack.error
        });
        setTimeout(() => {
          modal.destroy();
        }, 3000);
      } else {
        this.setState({
          loading:false
        },()=>{})
        callback(MessagesBack);
       
      }
    })
 
  };
  handleInfiniteOnLoad = () => {
    
    let { messageLists, mesTotal } = this.state;
    this.setState({
      loading: true
    });
    if (messageLists.length >= mesTotal) {
      message.warning("List loaded all");
      this.setState({
        hasMore: false,
        loading: false
      });
      return;
    }
    let { pageSize, currentPage } = this.state;
    this.setState(
      {
        currentPage: currentPage + 1
      },
      () => {
        let pageParams = {
          pageSize,
          page: this.state.currentPage
        };
        this.getNews(pageParams, res => {
          messageLists = messageLists.concat(res.result);
          this.setState({
            messageLists,
            loading: false
          });
        });
      }
    );
    
  };
  blurRange = () => {
    this.setState({
      isRange: false
    });
  };
  handleChange = value => {
    if (moment(value[1], "YYYY")) {
      this.setState({
        isRange: true
      });
    } else {
      this.setState({
        isRange: false
      });
    }
  };
  viewNewDetail = (item, index) => {
    // let url = "/news/detail";
    // let queryParam = item;
    // this.props.history.push({ pathname: url, query: {queryParam} });

    const { id } = item;

    let url = "/news/detail?";

    let newsId = id ? "id=" + id : "";

    let params = `${url}${newsId}`;

    this.props.history.push(params);
  };
  render() {
    let { messageLists } = this.state;
  
    return (
      <div id="message">
        <div className="message-content">
          <div className="reference-search-operate">
            <p className="group-index-title">News List</p>
          </div>
          <div className="newslist-mian-content">
            <div className="message-lists-all new-lists-all">
              <div className="news-lists">
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={this.handleInfiniteOnLoad}
                  hasMore={!this.state.loading && this.state.hasMore}
                  useWindow={false}
                  className="message-lists-scroll"
                >
                  <ul className="message-lists-ul">
                    {messageLists.length > 0 ? (
                      messageLists.map((item, index) => {
                        return (
                          <li
                            className="message-list-item"
                            onClick={() => this.viewNewDetail(item, index)}
                            key={index}
                          >
                             <div className="">
                              {index + 1}
                            </div>
                            <div
                              className=""
                              title={"Click to view details"}
                            >
                              {item.title}
                            </div>
                           
                            <div className="">
                              {moment(item.release_date).format("YYYY-MM-DD")}
                            </div>
                          </li>
                        );
                      })
                    ) :
                    this.state.loading&&messageLists.length===0?
                  (  <li className="message-no-res">Loading...</li>)
                    :
                     (
                      <li className="message-no-res">No news</li>
                    )}

                    {this.state.loading && this.state.hasMore && (
                      <div className="demo-loading-container">
                        <Spin />
                      </div>
                    )}
                  </ul>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
NewsList = Form.create({})(NewsList);
export default NewsList;
