import React from 'react'
import {Form ,Button,Modal} from 'antd'

export default class ShowColInfoModal extends React.Component{
    state={
        modalData:{}
    }
   
    render(){
        const {modalData,visible}=this.props
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 5 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 19 },
            },
          };
        return (
            <Modal
                className='AModal'
                width={1000}
                visible={visible}
                onCancel={this.props.cancelShowInfo}
                footer={null}
            >
            <div>
                <div className='tip1'>Collection Info</div>
                <Form {...formItemLayout}>
                    <Form.Item label='Reference ID'>
                        <span>{modalData.ref_id}</span>
                    </Form.Item>

                    <Form.Item label='Unit ID'>
                        <span>{modalData.unit_id}</span>
                    </Form.Item>

                    <Form.Item label='Collection no'>
                        <span>{modalData.coll_no}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Modes of<br/>preservation</div>}>
                        <span>{modalData.coll_preservation}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Original<br/>biominerals</div>}>
                        <span>{modalData.coll_biominerals}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Replacement<br/>Minerals</div>}>
                        <span>{modalData.coll_minerals}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Special modes<br/>of occurrence</div>}>
                        <span>{modalData.coll_occurrence}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Temporal<br/>Resolution</div>}>
                        <span>{modalData.coll_tmporal}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Spatial<br/>resoution</div>}>
                        <span>{modalData.coll_resolution}</span>
                    </Form.Item>

                    <Form.Item label='Lager statten type'>
                        <span>{modalData.coll_type}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Degree of<br/>concentration</div>}>
                        <span>{modalData.coll_concentration}</span>
                    </Form.Item>

                    <Form.Item label='Spatial orientation'>
                        <span>{modalData.coll_orientation}</span>
                    </Form.Item>

                    <Form.Item label='Anatomical detail'>
                        <span>{modalData.coll_detail}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Abundance in<br/>sediment</div>}>
                        <span>{modalData.coll_sediment}</span>
                    </Form.Item>

                    <Form.Item label='Size sorting'>
                        <span>{modalData.coll_sorting}</span>
                    </Form.Item>

                    <Form.Item label='Fragmentation'>
                        <span>{modalData.coll_fragmentation}</span>
                    </Form.Item>

                    <Form.Item label='Bioerpsion'>
                        <span>{modalData.coll_Bioerosion}</span>
                    </Form.Item>

                    <Form.Item label='Encrustation'>
                        <span>{modalData.coll_Encrustation}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Comments on<br/>preservation</div>}>
                        <span>{modalData.coll_preservations}</span>
                    </Form.Item>

                    <Form.Item label='Size classes'>
                        <span>{modalData.coll_classes}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Feeding/predation<br/>traces</div>}>
                        <span>{modalData.coll_traces}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Comments on<br/>included components</div>}>
                        <span>{modalData.coll_components}</span>
                    </Form.Item>

                </Form>
           
                <div className='foot' style={{textAlign:'center',margin:'20px'}}>
                    <Button  type='agbdb1' onClick={this.props.cancelShowInfo.bind(this)}>关闭</Button>
                </div>
            </div>
        </Modal>
        )
    }
}