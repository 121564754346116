

import React from 'react';

import 'babel-polyfill';
import './App.css';

import Root from './router/index';
import './style/common.css'
import './style/reset.css'

function App() {
  
  return (
    <div className="App">
    <Root></Root>
    </div>
  );
}

export default App;
