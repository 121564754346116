import asyncAxios from "src/js/asyncAxios";

import Storage from "src/js/storage";
export default {
  // reference
  // search

  //   reference
  searchReference: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/reference/search";

    let referenceSearch = await asyncAxios.post(url, allparams);

    return referenceSearch;
  },
  createReference: async params => {
    let url = "/api/reference/add";

    let referenceAdd = await asyncAxios.post(url, params);

    return referenceAdd;
  },

  searchFiles: async params => {
    let url = "/api/reference/searchFileByRefId";

    let Files = await asyncAxios.get(url, params);

    return Files;
  },
  deleteFileById: async params => {
    let url = "/api/reference/deleteFileById";

    let Files = await asyncAxios.delete(url, params);

    return Files;
  },
 
  // section
  createSectionBasic: async params => {
    let url = "/api/section/basic/add";

    let baseResult = await asyncAxios.post(url, params);

    return baseResult;
  },

  sectionBase: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
 

    let url = "/api/section/searchAll";

    let sections = await asyncAxios.post(url, allparams);
    return sections;
  },
  // sectionOnly:async(params)=>{
  //   let url = "/api/section/search";

  //    let sections = await asyncAxios.get(url, params)
  //    return sections
  // },
  createGeography: async params => {
    let url = "/api/section/geography/add";

    let geographyResult = await asyncAxios.post(url, params);

    return geographyResult;
  },
  createFormation: async params => {
    let url = "/api/section/formation/add";

    let fomationResult = await asyncAxios.post(url, params);

    return fomationResult;
  },
  searchFormationByRef: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/section/formation/searchByrefId";
    let FormationByRef = await asyncAxios.get(url, allparams);
    return FormationByRef;
  },

  searchByFormation: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/section/unit/searchByFormationId";
    let FormationByRef = await asyncAxios.get(url, allparams);
    return FormationByRef;
  },
  verifyFormationById: async params => {
    let url = "/api/section/formation/verifyById";
    let FormationByRef = await asyncAxios.get(url, params);
    return FormationByRef;
  },
  getFormations: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }

    let url = "/api/section/formation/searchAll";
    let Formations = await asyncAxios.post(url, allparams);
    return Formations;
  },
  createUnit: async params => {
    let url = "/api/section/unit/add";

    let fomationResult = await asyncAxios.post(url, params);

    return fomationResult;
  },


  getUnits: async params => {
    
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/section/unit/searchAll";

    let Units = await asyncAxios.post(url, allparams);

    return Units;
  },
  searchUnits: async params => {
    
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/search/searchUnits";

    let Units = await asyncAxios.post(url, allparams);

    return Units;
  },
  createGeology: async params => {
    let url = "/api/section/geology/add";

    let fomationResult = await asyncAxios.post(url, params);

    return fomationResult;
  },



  getGeology: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/section/geology/searchAll";

    let getGeologys = await asyncAxios.post(url, allparams);
    return getGeologys;
  },
  autoCompleteGeology: async params => {
    let url = "/api/section/geology/autoComplete";

    let getGeologys = await asyncAxios.post(url, params);
    return getGeologys;
  },
  gpsConverts: async params => {
    let url = "/api/section/geology/gpsConverts";

    let gpsConvert = await asyncAxios.get(url, params);

    return gpsConvert;
  },

  getEarlyInterval: async params => {
    let url = "/api/section/formation/getEarlyInterval";

    let EarlyInterval = await asyncAxios.get(url, params);

    return EarlyInterval;
  },

  // geochemical
  getGeochemDataList: async params => {
    
    let url = "/api/geochemical/geochemicalData";

    let GeochemDataList = await asyncAxios.get(url, params);

    return GeochemDataList;
  },
  deleteDataById: async params => {
    
    let url = "/api/geochemical/deleteDataById";

    let deleteData = await asyncAxios.delete(url, params);

    return deleteData;
  },
  deleteGeochemFile: async params => {
    let url = "/api/geochemical/deleteFile";

    let Files = await asyncAxios.delete(url, params);

    return Files;
  },
  createGeochemData: async params => {
    let url = "/api/geochemical/create/geochemicalData";

    let createGeoData = await asyncAxios.post(url, params);

    return createGeoData;
  },
  createGeochem: async params => {
    let url = "/api/geochemical/create/geochemical";

    let createGeochemRes = await asyncAxios.post(url, params);

    return createGeochemRes;
  },
  uploadGeochemData: async params => {
    let url = "/api/geochemical/geochemicalData/file";

    let uploadGeochems = await asyncAxios.post(url, params);

    return uploadGeochems;
  },
// 获取所有的getGeochemicalsoccurance
  getGeochemicals: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }

    let url = "/api/geochemical/searchAll";

    let getGeochemical = await asyncAxios.post(url, allparams);

    return getGeochemical;
  },
  searchGeochemicals: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }

    let url = "/api/geochemical/searchGeochemicals";

    let getGeochemical = await asyncAxios.post(url, allparams);

    return getGeochemical;
  },

  getGeochemistrytype: async params => {
    let url = "/api/basic/geochemistrytype";

    let Geochemistrytype = await asyncAxios.get(url, params);

    return Geochemistrytype;
  },
  searchDataById: async params => {
    let url = "/api/geochemical/searchDataById";

    let DataById = await asyncAxios.get(url, params);

    return DataById;
  },
  searchGeochemicalOccurrence: async params => {
    let url = "/api/geochemical/searchById";

    let DataById = await asyncAxios.get(url, params);

    return DataById;
  },
// 根据地理数据id查询有数据的types
getGeochemistryTypes: async params => {
  let url = "/api/basic/getGeochemistryTypes";

  let GeochemistryTypes = await asyncAxios.get(url, params);

  return GeochemistryTypes;
},
  // collection
  createCollection: async params => {
    let url = "/api/section/collection/add";

    let fomationResult = await asyncAxios.post(url, params);

    return fomationResult;
  },
  getCollections: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/section/collection/searchAll";
    let Collections = await asyncAxios.post(url, allparams);
    return Collections;
  },

  searchByUnit: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/section/collection/searchByUnit";
    // coll_unit_id
    let Collections = await asyncAxios.get(url, allparams);
    return Collections;
  },
  getMaxCollNo: async params => {
    let url = "/api/section/collection/maxCollNo";

    let MaxCollNo = await asyncAxios.get(url, params);

    return MaxCollNo;
  },

  // Fossils
  getFossils: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/fossil/searchAll";
    let Fossils = await asyncAxios.post(url, allparams);
    return Fossils;
  },

  createFossil: async params => {
    let url = "/api/fossil/create/fossil";
    let Fossils = await asyncAxios.post(url, params);
    return Fossils;
  },
  createFossilOccurrence: async params => {
    let url = "/api/fossil/create/fossilOccurrence";
    let Fossils = await asyncAxios.post(url, params);
    return Fossils;
  },


  searchAllFossilOccurrence: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/fossil/fossilOccurrence/searchAll";
    let Fossils = await asyncAxios.post(url, allparams);
    return Fossils;
  },
  searchAllFossil: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/fossil/fossil/searchAll";
    let Fossils = await asyncAxios.post(url, allparams);
    return Fossils;
  },
  searchAllFossilByOccurrence: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/fossil/fossil/searchAByOccurrence";
    let Fossils = await asyncAxios.post(url, allparams);
    return Fossils;
  },
  searchFossilListByCollection: async (params, type) => {
    let url = `/api/search/fossillist`;
  
    let searchAll = await asyncAxios.get(url, params);
  
    return searchAll;
  },

  getFossilOpinionItems: async (params, type) => {
    let allparams={}
    Object.assign(allparams,params)
    // let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    // if(uid){
    //   Object.assign(allparams,{uid})
    // }
    let url = `/api/fossil/fossilOpinionItems`;
  
    let searchAll = await asyncAxios.get(url, allparams);
  
    return searchAll;
  },
  createFossilOpinion: async (params, type) => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = `/api/fossil/create/fossilOpinion`;
  
    let searchAll = await asyncAxios.post(url, allparams);
  
    return searchAll;
  },
  createFossilOpinionItem: async (params, type) => {
    let url = `/api/fossil/create/fossilOpinionItem`;
  
    let searchAll = await asyncAxios.post(url, params);
  
    return searchAll;
  },
  // 化石
  createHigher: async params => {
    let url = "/api/taxon/higher/add";
    let higherTax = await asyncAxios.post(url, params);
    return higherTax;
  },
  createOriginal: async params => {
    let url = "/api/taxon/original/add";
    let originalTax = await asyncAxios.post(url, params);
    return originalTax;
  },
  createSpecies: async params => {
    let url = "/api/taxon/species/add";
    let SpeciesTax = await asyncAxios.post(url, params);
    return SpeciesTax;
  },
  createRevised: async params => {
    let url = "/api/taxon/revised/add";
    let revisedTax = await asyncAxios.post(url, params);
    return revisedTax;
  },
  addRelation: async params => {
    let url = "/api/taxon/relation/add";
    let relationTax = await asyncAxios.post(url, params);
    return relationTax;
  },

  getTaxons: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/taxon/searchAll";
    let Taxons = await asyncAxios.post(url, allparams);
    return Taxons;
  },

  searchTaxonChildrens: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/taxon/search/relations/child";
    let Taxons = await asyncAxios.post(url, allparams);
    return Taxons;
  },
  searchTaxonParent: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/taxon/search/relations/parent";
    let Taxons = await asyncAxios.post(url, allparams);
    return Taxons;
  },
  searchTaxonBrothers: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/taxon/search/relations/brothers";
    let Taxons = await asyncAxios.post(url, allparams);
    return Taxons;
  },
  //化石查询重复
  // searchTax: async (params, type) => {
  //   let url = "/api/taxon/search";
  //   let searchTax = await asyncAxios.get(url, { [type]: params });

  //   return searchTax;
  // },
  searchTax: async (params) => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/taxon/search";
    let searchTax = await asyncAxios.get(url,allparams);

    return searchTax;
  },
  searchTaxonDetail: async params => {
    let url = "/api/taxon/search";
    let searchTax = await asyncAxios.get(url, params);

    return searchTax;
  },

  // 化石查询
  AutoCompleteTaxon: async params => {
    let url = "/api/taxon/autoComplete";
    let relationTax = await asyncAxios.post(url, params);
    return relationTax;
  },

  searchbyName: async params => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = "/api/taxon/searchbyName";
    let relationTax = await asyncAxios.post(url, allparams);
    return relationTax;
  },
  deleteTaxarelationbyId:async params => {
    let url = "/api/taxon/relation/deleteById";
    let deleteTaxarelation = await asyncAxios.delete(url, params);
    return deleteTaxarelation;
  },
  //  添加回次
  createRoundtrip: async (params, type) => {
    let url = "/api/oceanic/createRoundtrip";

    let Roundtrip = await asyncAxios.post(url, params);

    return Roundtrip;
  },
  createCoreimage: async (params, type) => {
    let url = "/api/oceanic/createCoreimage";

    let Coreimage = await asyncAxios.post(url, params);

    return Coreimage;
  },
  createLithSection: async (params, type) => {
    let url = "/api/oceanic/createLithSection";

    let LithSection = await asyncAxios.post(url, params);

    return LithSection;
  },
  createLithDesc: async (params, type) => {
    let url = "/api/oceanic/createLithDesc";

    let LithDesc = await asyncAxios.post(url, params);

    return LithDesc;
  },

// 查询
searchRoundtrip: async (params, type) => {
  let allparams={}
  Object.assign(allparams,params)
  let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
  if(uid){
    Object.assign(allparams,{uid})
  }
  let url = "/api/oceanic/roundtrip/searchAll";

  let LithDesc = await asyncAxios.post(url, allparams);

  return LithDesc;
},
searchCoreimage: async (params, type) => {
  let allparams={}
  Object.assign(allparams,params)
  let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
  if(uid){
    Object.assign(allparams,{uid})
  }
  let url = "/api/oceanic/coreimage/searchAll";

  let LithDesc = await asyncAxios.post(url, allparams);

  return LithDesc;
},

searchLithologyDesc: async (params, type) => {
  let allparams={}
  Object.assign(allparams,params)
  let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
  if(uid){
    Object.assign(allparams,{uid})
  }
  let url = "/api/oceanic/lithologyDesc/searchAll";

  let LithDesc = await asyncAxios.post(url, allparams);

  return LithDesc;
},
searchLithologySection: async (params, type) => {
  let allparams={}
  Object.assign(allparams,params)
  let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
  if(uid){
    Object.assign(allparams,{uid})
  }
  let url = "/api/oceanic/lithologySection/searchAll";

  let LithDesc = await asyncAxios.post(url, allparams);

  return LithDesc;
},
  // 查询
  searchAll: async (params, type) => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = `/api/${type}/searchAll`;

    let searchAll = await asyncAxios.post(url, allparams);

    return searchAll;
  },
  // 所有查询(复合查询)
  searchSection: async (params, type) => {
    let url = `/api/search/section`;
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
 
    let searchAll = await asyncAxios.post(url, allparams);

    return searchAll;
  },
 
  searchFossilOccurrence: async (params, type) => {
  
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
 
    let url = `/api/search/fossilOccurrence`;
    // let url = `/api/fossil//fossil/searchAByOccurrence`;
  
    let searchAll = await asyncAxios.post(url, allparams);
  
    return searchAll;
  },

  // 修改
  changeById: async (params, type) => {
    let url = `/api/reference/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeBasicById: async (params, type) => {
    let url = `/api/section/basic/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeGeologyById: async (params, type) => {
    let url = `/api/section/geology/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeFormationById: async (params, type) => {
    let url = `/api/section/formation/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeUnitById: async (params, type) => {
    let url = `/api/section/unit/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeCollectionById: async (params, type) => {
    let url = `/api/section/collection/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },

  changeTaxonById: async (params, type) => {
    let url = `/api/taxon/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeRevisedTaxonById: async (params, type) => {
    let url = `/api/taxon/revised/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeTaxarelationbyId: async (params, type) => {
    let url = `/api/taxon/relation/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeRoundtripById: async (params, type) => {
    let url = `/api/oceanic/roundtrip/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeCoreimageById: async (params, type) => {
    let url = `/api/oceanic/coreimage/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeLithologyDescById: async (params, type) => {
    let url = `/api/oceanic/lithologyDesc/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeLithologySectionById: async (params, type) => {
    let url = `/api/oceanic/lithologySection/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeGeochemicalById: async (params, type) => {
    let url = `/api/geochemical/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
  changeGeochemicalDataById: async (params, type) => {
    let url = `/api/geochemical/data/changeById`;
  
    let change = await asyncAxios.post(url, params);
  
    return change;
  },
// 删除
deleteReference: async (params, type) => {
  let url = `/api/reference/delete`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
deleteBasicById: async (params, type) => {
  let url = `/api/section/basic/deleteById`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
deleteFormationById: async (params, type) => {
  let url = `/api/section/formation/deleteById`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
deleteUnitById: async (params, type) => {
  let url = `/api/section/unit/deleteById`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
deleteCollectionById: async (params, type) => {
  let url = `/api/section/collection/deleteById`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
deleteFossilById: async (params, type) => {
  let url = `/api/fossil/deleteById`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
deleteGeochemicalById: async (params, type) => {
  let url = `/api/geochemical/deleteById`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
deleteTaxon: async (params, type) => {
  let url = `/api/taxon/deleteById`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
deleteRoundtripById: async (params, type) => {
  let url = `/api/oceanic/roundtrip/deleteById`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
deleteCoreimageById: async (params, type) => {
  let url = `/api/oceanic/coreimage/deleteById`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
deleteLithologyDescById: async (params, type) => {
  let url = `/api/oceanic/lithologyDesc/deleteById`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
deleteLithologySectionById: async (params, type) => {
  let url = `/api/oceanic/lithologySection/deleteById`;

  let deleteRef = await asyncAxios.delete(url, params);

  return deleteRef;
},
  // 下载
  downConop:async (params) => {
    let url = `/api/subset/export/conop/sections`;
  
    let conop = await asyncAxios.post(url, params);

    return conop;
  },

  exportGeochemistry:async (params) => {
    let url = `/api/subset/export/geochemistry`;
  
    let conop = await asyncAxios.post(url, params);
  
    return conop;
  },

  exportCollection:async (params) => {
    let url = `/api/subset/export/collection`;
  
    let conop = await asyncAxios.post(url, params);
  
    return conop;
  },
  exportFossiloccurrence:async (params) => {  
    let url = `/api/subset/export/fossiloccurrence`;
  
    let conop = await asyncAxios.post(url, params);
  
    return conop;
  },
  downloadRefFiles: async params => {
    let url = "/api/reference/downloadFiles";

    let downloadFile = await asyncAxios.get(url, params);

    return downloadFile;
  },
  download: async params => {
    let url = "/api/subset/download";
    
    // console.log("params:"+params);
    let downloadFile = await asyncAxios.post(url, params);

    return downloadFile;
  },



  // 
  getSectionResults:async (params) => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = `/api/search/getSectionResults`;
  
    let Section = await asyncAxios.post(url, allparams);

    return Section;
  },
  getUnitsResults:async (params) => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = `/api/search/getUnitsResults`;
  
    let Units = await asyncAxios.post(url, allparams);

    return Units;
  },
  getformationResults:async (params) => {
    let allparams={}
    Object.assign(allparams,params)
    let uid=Storage.getItem('gbdbid')?Storage.getItem('gbdbid'):''
    if(uid){
      Object.assign(allparams,{uid})
    }
    let url = `/api/search/getformationResults`;
  
    let Section = await asyncAxios.post(url, allparams);

    return Section;
  },
  getParentTimeLinesById:async (params) => {
    let url = `/api/section/formation/getParentTimeLinesById`;
  
    let TimeLinesById = await asyncAxios.get(url, params);

    return TimeLinesById;
  },

  tsCreator: async params => {
    let url = "/tsc/section/tsCreator";
    
    let allparams={}
    Object.assign(allparams,params)
    console.log("allparams:"+allparams);
    let downloadFile = await asyncAxios.post(url, allparams);

    return downloadFile;
  },
  
};
