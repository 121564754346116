import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Input,
  Modal,
  Radio,
  Tabs,
  Select,
  Spin,
  InputNumber,message
} from "antd";
import { withRouter } from "react-router-dom";
import Apis from "src/js/apis/api";
import Utils from "src/js/utils";
import Storage from "src/js/storage";
import SubsetApis from "src/js/apis/subset";
import dataApis from "src/js/apis/data";
import AllFolders from 'commons/AllFolders/index'
import "./index.css";
const { TabPane } = Tabs;
const { Option } = Select;
const nomenclaturesOptions = [
  "question mark (?)",
  'quotes ("")',
  "cf.",
  "aff.",
  "ex gr.",
  "sp.",
  "ssp.",
  "subsp.",
  "sp. nov.",
  // "sp. 1, sp. A, sp. i, etc.",
  "spp."
];
@withRouter
class SectionAllTableResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResultVisible: false,
      detailData: {},
      showDetailForm: false,

      isCheckAll: false,
      checkedObj: {},
      defaultCheckedList: [],
      searchParams: props.searchParams || {},
      searchValues: props.searchValues || {},
      currentPage: 1,
      totalNumbers: 0,
      resultLists: [],
      pageSize: 11,
      AllPages: 0,
      checkOne: false,
      // askLoading:false,
      //    地图控制
      LngLats: {},
      showMap: false,
      currentLng: null,
      currentLat: null,
      currentItem: {},
      sectionType: "baseinfo",
      LoadConditionType: "fossilType",
      showAddSubset: props.showAddSubset === true ? true : false,
      showDeleteSubset: props.showDeleteSubset === true ? true : false,
      showExportFile: props.showExportFile === true ? true : false,
      downLoadFileLoading: false,
      folderOperateShow: false,



      checkedList: [],
      currentFossilGroups: [],
      currentFossilGroupType: "",
      currentNomenclature: "",
      checkNomenclaturesAll: false,
    };
  }

  componentDidMount() {
    if (this.props.onGeography) {
      this.props.onGeography(this);
    }
    if (this.props.checkOne) {
      this.setState({
        checkOne: true
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      searchParams: nextProps.searchParams,
      searchAllValues: nextProps.searchAllValues
    });
    if (this.props.checkOne) {
      this.setState({
        checkOne: true
      });
    }
    if (nextProps.showDeleteSubset !== this.props.showDeleteSubset) {
      this.setState({
        showDeleteSubset: nextProps.showDeleteSubset
      });
    }
    if (nextProps.showAddSubset !== this.props.showAddSubset) {
      this.setState({
        showAddSubset: nextProps.showAddSubset
      });
    }
  }
  deleteFun = async () => {
    let { checkedObj, resultLists } = this.state;

    let rankValue = Object.values(checkedObj).sort(
      Utils.objComparison("index")
    );
    let deleteArrays = [];
    rankValue.map((item, index) => {
      deleteArrays.push(item.item.section_basic_id);
    });

    deleteArrays.map((item, index) => {
      resultLists.splice(checkedObj[item].index, 1);
      delete checkedObj[item];
    });

    if (resultLists.length < 11) {
      if (this.state.currentPage == this.state.AllPages) {
        if (resultLists.length == 0) {
          this.toPre();
        }
      } else if (this.state.currentPage == 1) {
        this.toFirst();
      } else {
        this.toPre();
      }
    }
    this.setState(
      {
        checkedObj,
        resultLists,
        isCheckAll: false
      },
      () => {
        this.props.changeCheckSelected(checkedObj, resultLists);
      }
    );
  };
  deleteSubset = async () => {
    let that = this;
    Modal.confirm({
      title: "please choose",
      content: "Do you want to delete the subsets?",
      async onOk() {
        let { checkedObj, resultLists } = that.state;

        let rankValue = Object.values(checkedObj).sort(
          Utils.objComparison("index")
        );
        let deleteArrays = [];

        rankValue.map((item, index) => {
          deleteArrays.push(item.item.section_basic_id);
        });

        let uid = Storage.getItem("gbdbid");

        let params = {
          uid,
          assetstype: 2,
          ids: deleteArrays
        };

        let deleteAssetsBack = await SubsetApis.deleteAssets(params);
        if (deleteAssetsBack.result) {
          deleteArrays.map((item, index) => {
            resultLists.splice(checkedObj[item].index, 1);
            delete checkedObj[item];
          });
          if (resultLists.length < 11) {
            if (that.state.currentPage == that.state.AllPages) {
              if (resultLists.length == 0) {
                that.toPre();
              } else {
              }
            } else if (that.state.currentPage == 1) {
              that.toFirst();
            } else {
              that.toPre();
            }
          }
          that.setState(
            {
              checkedObj,
              resultLists,
              isCheckAll: false
            },
            () => {
              if (that.props.changeCheckSelected) {
                that.props.changeCheckSelected(checkedObj, resultLists);
              }
            }
          );
        } else {
          Modal.error({
            title: "This is an error message",
            content: deleteAssetsBack.error
          });
        }
      },
      onCancel() {
   
      }
    });
  };
  searchSubset = async searchParams => {
    this.setState(
      {
        askLoading: true
      },
      async () => {
        let pageSize = this.state.pageSize;
        let searchCondition = {};
        let pageParams = {
          page: this.state.currentPage,
          pageSize: this.state.pageSize
        };
        Object.assign(searchCondition, searchParams, pageParams);
        let folderResBack = await SubsetApis.getAssetsInfolder(searchCondition);
        if (folderResBack.error) {
          this.setState({
            askLoading: undefined
          });
          const modal = Modal.error({
            title: "This is an error message",
            content: folderResBack.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 3000);
        } else {
          let result = folderResBack.result;
          let totalNumbers = folderResBack.total;

          let AllPages = Math.ceil(totalNumbers / pageSize);
          this.setState(
            {
              resultLists: result,
              totalNumbers,
              AllPages,
              askLoading: false
              // conditions: searchAllValues
            },
            () => { }
          );
        }
      }
    );
  };
  searchSectionDatas = async (searchParams, searchAllValues, sectionType, parameter1, parameter2, page) => {
    if (page == undefined) {
      this.setState({
        currentPage: 1
      })

    }
    this.setState(
      {
        askLoading: true
      },
      async () => {
        let searchResult = [];
        let searchCondition = {};
        let pageParams = {
          page: this.state.currentPage,
          pageSize: this.state.pageSize
        };
        let searchParamsCopy = Utils.deepObjCopy(searchParams);
        if (searchParamsCopy.early_age) {
          searchParamsCopy.early_age = {
            condition: "before",
            value: searchParamsCopy.early_age
          };
        }
        if (searchParamsCopy.late_age) {
          searchParamsCopy.late_age = {
            condition: "after",
            value: searchParamsCopy.late_age
          };
        }
        Object.assign(searchCondition, searchParamsCopy, pageParams);

        if (sectionType === "baseinfo") {
          // searchResult = await Apis.getGeology(searchCondition);
          searchResult = await Apis.searchSection(searchCondition);
        } else if (sectionType === "geology") {
          // searchResult = await Apis.searchSection(searchCondition);
          searchResult = await Apis.getGeology(searchCondition);
        } else if (sectionType === "formation") {
          searchResult = await Apis.getFormations(searchCondition);
        } else if (sectionType === "occurrence") {
          searchResult = await Apis.getGeochemicals(searchCondition);
        } else if (sectionType === "unit") {
          searchResult = await Apis.searchUnits(searchCondition);
        } else {
          searchResult = await Apis.searchSection(searchCondition);
        }

        //

        if (searchResult.error) {
          this.setState({
            askLoading: undefined
          });
          Modal.error({
            title: "This is an error message",
            content: searchResult.error
          });
        } else if (searchResult.result) {
          let result = searchResult.result;
          let totalNumbers = searchResult.total;
          let { pageSize } = this.state;
          let AllPages = Math.ceil(totalNumbers / pageSize);
          this.setState(
            {
              resultLists: result,
              totalNumbers,
              AllPages,
              conditions: searchAllValues,
              askLoading: false
            },
            () => { }
          );
        }
        this.setState({
          sectionType
        });
      }
    );
  };
  changePage = value => {
    this.setState(
      {
        currentPage: value,
        isCheckAll: false,
        askLoading: undefined,
        checkedObj: {},
        resultLists: []
      },
      () => {
        let { searchParams, values, sectionType } = this.state;
        let pathname = this.props.location.pathname;
        if (pathname === "/subset") {
          this.searchSubset(searchParams, value);
        } else {
          this.searchSectionDatas(searchParams, values, sectionType, '', '', this.state.currentPage);
        }
      }
    );
  };
  gotoPage = e => {
    if (e.keyCode === 13) {
      var page = parseInt(e.target.value);
      let { AllPages } = this.state;

      if (page < 1 || page > AllPages) {
         message.warning("Please enter the correct page number");
        return;
      } else {
        this.setState(
          {
            currentPage: page
          },
          () => {
            this.changePage(this.state.currentPage);
          }
        );
      }
    }
  };
  gotoPages = () => {
    let pageDom = document.querySelector('.searchResult-select input')
    if (pageDom) {
      let pageValue = pageDom.value
      if (pageValue) {
        let page = parseInt(pageValue);
        let { AllPages } = this.state;
        if (page < 1 || page > AllPages) {
           message.warning("Please enter the correct page number");
          return;
        } else {
          this.setState(
            {
              currentPage: page
            },
            () => {
              this.changePage(this.state.currentPage);
            }
          );
        }

      }
    }

  }
  toFirst = e => {
    this.setState(
      {
        currentPage: 1
      },
      () => {
        this.changePage(this.state.currentPage);
      }
    );
  };

  toPre = e => {
    if (this.state.currentPage === 1) {
      return;
    } else {
      this.setState(
        {
          currentPage: this.state.currentPage - 1
        },
        () => {
          this.changePage(this.state.currentPage);
        }
      );
    }
  };

  toNext = e => {
    if (this.state.currentPage === this.state.AllPages) {
      return;
    } else {
      this.setState(
        {
          currentPage: this.state.currentPage + 1
        },
        () => {
          this.changePage(this.state.currentPage);
        }
      );
    }
  };

  changePageSize=pageSize=>{
    this.setState({
      pageSize: pageSize
    })
    //console.log("pageSize:"+pageSize);
    this.changePage(this.state.currentPage);
  }

  itemCheckbox = (item, index, event) => {
    let { resultLists, checkedObj, LngLats } = this.state;
    let nameid = Number(event.target.name);
    if (event.target.checked === true) {
      checkedObj[nameid] = {
        item,
        index
      };
      this.setState({
        currentItem: item
      });
      if (item.geology_latitude_decimal && item.geology_longitude_decimal) {
        LngLats[nameid] = {
          lat: item.geology_latitude_decimal,
          lng: item.geology_longitude_decimal,
          detail: item
        };
      }
    } else if (event.target.checked === false && checkedObj[nameid]) {
      delete checkedObj[nameid];
      if (LngLats[nameid]) {
        delete LngLats[nameid];
      }
    }

    let checkedLength = Object.keys(checkedObj).length;

    this.setState(
      {
        isCheckAll: checkedLength === resultLists.length
      },
      () => {
        if (this.props.checkOne) {
          this.props.checkGeographyOne(item);
        } else {
          let { checkedObj, resultLists, LngLats, currentItem } = this.state;
          this.props.changeCheckSelected(
            checkedObj,
            resultLists,
            LngLats,
            currentItem
          );
        }
      }
    );
  };
  CheckAll = e => {
    let { resultLists, checkedObj, LngLats } = this.state;
    if (e.target.checked === true) {
      this.setState(
        {
          isCheckAll: true
        },
        () => {
          resultLists.map((item, index) => {
            checkedObj[item.section_basic_id] = {
              item,
              index
            };
            if (
              item.geology_latitude_decimal &&
              item.geology_longitude_decimal
            ) {
              LngLats[item.section_basic_id] = {
                lat: item.geology_latitude_decimal,
                lng: item.geology_longitude_decimal,
                detail: item
              };
            }
          });

          this.setState(
            {
              checkedObj,
              LngLats
            },
            () => {
              let { checkedObj, resultLists, LngLats } = this.state;

              if (this.props.changeCheckSelected) {
                this.props.changeCheckSelected(
                  checkedObj,
                  resultLists,
                  LngLats
                );
              }
            }
          );
        }
      );
    } else {
      this.setState(
        {
          isCheckAll: false
        },
        () => {
          this.setState(
            {
              checkedObj: {},
              LngLats: {}
            },
            () => {
              let { checkedObj, resultLists, LngLats } = this.state;
              if (this.props.changeCheckSelected) {
                this.props.changeCheckSelected(
                  checkedObj,
                  resultLists,
                  LngLats
                );
              }
            }
          );
        }
      );
    }
  };

  addSubset = async () => {
    if (Storage.getItem("token") == undefined) {
      Modal.error({
        title: "Please login first",
        content: "Only after the login can collect data!!!"
      })
      return
    }
    this.setState({
      folderOperateShow: true
    }, () => {

    })
    // let { checkedObj } = this.state;

    // let uid = Storage.getItem("gbdbid");

    // let params = {
    //   uid,
    //   assetstype: 2,

    //   ids: Object.keys(checkedObj)
    // };
    // let collectBack = await SubsetApis.collectAssets(params);
    // if (collectBack.result) {
    //   Modal.success({
    //     title: "This is an successful message",
    //     content: "successfully added "
    //   });
    // } else {
    //   Modal.error({
    //     title: "This is an error message",
    //     content: collectBack.error
    //   });
    // }
  };

  getDetail = (detailItem, index) => {
    let detailValue = {};
    let BaseId = {
      id: detailItem.section_basic_id
    };
    Object.assign(detailValue, BaseId, detailItem);
    this.props.getDetail(detailValue, index);
  };

  downloadModalOk = () => {
    this.setState({
      downloadModal: false,


    });
  };
  downloadModalCancel = () => {
    this.setState({
      downloadModal: false,

      checkedList: [],
      currentFossilGroups: [],
      currentFossilGroupType: "",
      currentNomenclature: "",
      checkNomenclaturesAll: false,
      LoadConditionType: "fossilType",
    });
  };
  downLodaConop = () => {
    this.setState({
      downloadModal: true
    });
  };
  downloadFile = file => {
    let href = "/api" + file;
    let idx = file.lastIndexOf("/");

    let name = file.substring(idx + 1, file.length);
    var a = document.createElement("a");
    a.setAttribute("href", href);
    a.setAttribute("download", name);
    a.setAttribute("id", "downloadFileA");
    a.setAttribute("name", "name");
    // 防止反复添加
    if (document.getElementById("downloadFileA")) {
      document.body.removeChild(document.getElementById("downloadFileA"));
    }
    document.body.appendChild(a);
    a.click();
  };
  godownloadSection = async () => {
    let {
      currentFossilGroupType,
      currentFossilGroups,
      LoadConditionType,
      currentNomenclature,
      checkedList
    } = this.state;
    //     checkedList.forEach((element,index) => {
    //       if(element==="sp. 1, sp. A, sp. i, etc."){
    //         checkedList.splice(index,1)
    //         checkedList.push('sp.')
    //       }


    // });

    if (LoadConditionType === "fossilType") {
      let params = {};
      let allParams = {};
      if (currentFossilGroupType === "all") {
        params = {
          allfossilGroups: 1
        };
      } else if (currentFossilGroupType === "only") {
        params = {
          allfossilGroups: 0,
          include_fossilGroups: currentFossilGroups
        };
      } else if (currentFossilGroupType === "ignore") {
        params = {
          allfossilGroups: 0,
          ignore_fossilGroups: currentFossilGroups
        };
      }
      let { checkedObj } = this.state;
      let section_ids = Object.keys(checkedObj);

      let sectionparams = {
        section_ids
      };
      this.setState(
        {
          downLoadFileLoading: true
        },
        async () => {
          Object.assign(allParams, params, sectionparams);
          let downBack = await Apis.downConop(allParams);

          if (downBack.result) {
            if("error"==downBack.result){
              this.setState(
                {
                  downLoadFileLoading: false
                },
                () => {
                  Modal.error({
                    content: downBack.error
                  });
                }
              );
              
            }else{
              this.setState(
                {
                  conopUrl: downBack.result,
                  downLoadFileLoading: false
                },
                () => {
                  this.downloadFile(downBack.result);
                }
              );
            }
            // this.setState(
            //   {
            //     conopUrl: downBack.result,
            //     downLoadFileLoading: false
            //   },
            //   () => {
            //     this.downloadFile(downBack.result);
            //   }
            // );
          } else {
            Modal.error({
              content: "download failed"
            });
          }
        }
      );
    } else {
      let params = {};
      let allParams = {};
      if (currentNomenclature === "allnome") {
        params = {
          allnomenclatures: 1
        };
      } else if (currentNomenclature === "ignorenome") {
        params = {
          allnomenclatures: 0,
          ignore_nomenclatures: checkedList
        };
      }
      let { checkedObj } = this.state;
      let section_ids = Object.keys(checkedObj);

      let sectionparams = {
        section_ids
      };
      Object.assign(allParams, params, sectionparams);
      let downBack = await Apis.downConop(allParams);
      if (downBack.result) {
        if("error"==downBack.result){
          Modal.error({
            content: downBack.error
          });
        }else{
          this.setState(
            {
              conopUrl: downBack.result,
              downLoadFileLoading: false
            },
            () => {
              this.downloadFile(downBack.result);
            }
          );
        }
        // this.setState(
        //   {
        //     conopUrl: downBack.result
        //   },
        //   () => {
        //     this.downloadFile(downBack.result);
        //   }
        // );
      } else {
        Modal.error({
          content: "download failed"
        });
      }
    }
  };
  changetFossilGroupType = e => {
    this.setState(
      {
        currentFossilGroupType: e.target.value,
        currentFossilGroups: [],
        allfossilGroups: this.state.currentFossilGroupType === "all" ? 1 : 0
      },
      async () => {
        let { currentFossilGroupType } = this.state;
        if (
          currentFossilGroupType === "only" ||
          currentFossilGroupType === "ignore"
        ) {
          let fossilgroup = await dataApis.fossilgroup();
          this.setState({
            fossilgroup:
              fossilgroup && fossilgroup.result ? fossilgroup.result : {}
          });
        }
      }
    );
  };
  ChangeSectionFossilGroup = value => {
    this.setState({
      currentFossilGroups: value
    });
  };

  changetNomenclatureType = e => {
    this.setState(
      {
        currentNomenclature: e.target.value
      },
      () => {
        if (this.state.currentNomenclature === "allnome") {
          this.setState({
            checkedList: [],
            checkNomenclaturesAll: false
          });
        }
      }
    );
  };
  onCheckAllNomenclatures = e => {

    this.setState(
      {
        checkedList: e.target.checked ? nomenclaturesOptions : [],
        currentNomenclature: "ignorenome",
        checkNomenclaturesAll: e.target.checked
      },
      () => { }
    );
  };
  onNomenclatureChange = checkedList => {

    this.setState({
      checkedList,
      checkNomenclaturesAll: checkedList.length === nomenclaturesOptions.length,
      currentNomenclature: "ignorenome"
    });
  };
  changeDownLoadCondition = value => {
    this.setState({
      LoadConditionType: value,


      checkedList: [],
      currentFossilGroups: [],
      currentFossilGroupType: "",
      currentNomenclature: "",
      checkNomenclaturesAll: false,
    });
  };
  resetCheckData = () => {
    this.setState({
      currentPage: 1,
      isCheckAll: false,
      askLoading: undefined,
      checkedObj: {}
    });
  };
  handlefolderOk = () => {
    this.setState({
      folderOperateShow: false
    });
  };
  handlefolderCancel = () => {
    this.setState({
      folderOperateShow: false
    });
  };
  render() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px"
    };

    let {
      currentPage,

      AllPages,
      isCheckAll,
      checkedObj,
      sectionType,
      checkOne,
      fossilgroup,
      resultLists
    } = this.state;

    return (
      <div id="SearchResult" className="SearchResult-refsearch">
        {/*  taxResult-main */}
        <div className="">
          <div className="content">
            {resultLists.length > 0 && this.state.askLoading == false ? (
              <table>
                <tbody>
                  <tr className="head">
                    {!checkOne ? (
                      <td className="check">
                        <Checkbox
                          onChange={e => this.CheckAll(e, "section_basic_id")}
                          checked={isCheckAll}
                        ></Checkbox>
                      </td>
                    ) : (
                        <td></td>
                      )}

                    <td className="ref-id">Section ID</td>
                    {sectionType === "baseinfo" ? <td>Section Name</td> : null}

                    <td>Location</td>

                    <td>City/County,Province,Village</td>
                    <td>Reference ID</td>
                    <td>Enterer</td>
                    <td>Accessibility</td>
                    <td>Details</td>
                  </tr>

                  {resultLists.map((item, index) => {
                    return (
                      <tr key={index} className="row">
                        {checkOne ? (
                          <td className="check">
                            <Radio
                              name={item.section_basic_id.toString()}
                              onChange={event =>
                                this.itemCheckbox(item, index, event)
                              }
                              datavalue={item}
                              checked={checkedObj.hasOwnProperty(
                                item.section_basic_id
                              )}
                            ></Radio>
                          </td>
                        ) : (
                            <td className="check">
                              <Checkbox
                                name={item.section_basic_id.toString()}
                                onChange={event =>
                                  this.itemCheckbox(item, index, event)
                                }
                                datavalue={item}
                                checked={checkedObj.hasOwnProperty(
                                  item.section_basic_id
                                )}
                              ></Checkbox>
                            </td>
                          )}

                        <td>{item.section_basic_id}</td>
                        {sectionType === "baseinfo" ? (
                          <td>{item.section_name}</td>
                        ) : null}

                        <td>{item.geology_location}</td>

                        <td>
                          {`${
                            item.geology_country ? item.geology_country : ""
                            } ${
                            item.geology_province ? item.geology_province : ""
                            } ${
                            item.geology_village ? item.geology_village : ""
                            }`}
                        </td>
                        <td>
                          {/* <p>
                            
                            {item.createdAt !== ""
                              ? item.createdAt.split("T")[0]
                              : ""}
                          </p> */}
                          <p> {item.ref_id}</p>
                        </td>
                        <td>{item.enterer_username}</td>
                        <td>{item.accessibility === 0 ? "open" : "private"}</td>

                        <td className="btn">
                          <Button
                            type="gbdb"
                            onClick={() => this.getDetail(item, index)}
                          >
                            <div>Details</div>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : this.state.askLoading == true ? (
              <div className="tablelist-no-resource"> <div id="allResult">
                      <div className="allLoader">
                        <div className="loader-inner ball-spin-fade-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <span>Loading...</span>
                      </div>
                    </div></div>
            ) : resultLists.length === 0 && this.state.askLoading == false ? (
              <div className="tablelist-no-resource">No Resource</div>
            ) : (
                    <div className="tablelist-no-resource"></div>
                  )}
          </div>

          {resultLists.length > 0 ? (
            <div className="footer">
              <div className="first" onClick={this.toFirst}>
                First
              </div>
              <div className="pre" onClick={this.toPre}>
                Pre
              </div>
              <div className="pages">{`${currentPage}/${AllPages}pages`}</div>
              <div className="next" onClick={this.toNext}>
                Next
              </div>

              <div className="page-input">
                <InputNumber
                  className="searchResult-select"
                  onKeyDown={this.gotoPage}
                  min={1}
                ></InputNumber>
                <span className="goto" onClick={this.gotoPages}>Goto</span>
              </div>

              <div>
                <Select style={{width:"70px"}} value={this.state.pageSize} onChange={value=>this.changePageSize(value)}>
                  <Option value="11">11</Option>
                  <Option value="20">20</Option>
                  <Option value="50">50</Option>
                </Select>
                <span className="goto">PageSize</span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="subset-file-btns">
          {this.state.showExportFile === true ? (
            <Button
              type="gbdb"
              onClick={this.downLodaConop}
              disabled={JSON.stringify(this.state.checkedObj) === "{}"}
            >
              <p title="Please select the file first"> Export to CONOP files</p>
            </Button>
          ) : null}

          {
            // Storage.getItem("token") !== undefined &&
            this.state.showAddSubset === true ? (
              <Button
                type="gbdb"
                disabled={Object.keys(checkedObj).length <= 0}
                onClick={this.addSubset}
              >
                Add to data subsets
              </Button>
            ) : null}
          {Storage.getItem("token") !== undefined &&
            this.state.showDeleteSubset === true ? (
              <Button
                type="gbdb"
                onClick={this.deleteSubset}
                disabled={Object.keys(checkedObj).length <= 0}
              >
                Delete subset
              </Button>
            ) : null}
        </div>
        <Modal
          title=""
          destroyOnClose
          visible={this.state.downloadModal}
          className="download-section-modal"
          width="60%"
          footer={null}
          // header={null}
          onOk={this.downloadModalOk}
          onCancel={this.downloadModalCancel}
        >
          <div>
            <Tabs
              onTabClick={this.changeDownLoadCondition}
              defaultActiveKey={this.state.LoadConditionType}
            >
              <TabPane tab="Fossil groups" key="fossilType">
                <Radio.Group
                  onChange={this.changetFossilGroupType}
                  value={this.state.currentFossilGroupType}
                >
                  <Radio style={radioStyle} value={"all"}>
                    Include all fossil groups
                  </Radio>
                  <Radio style={radioStyle} value={"only"}>
                    Only include the following fossil groups
                  </Radio>
                  {this.state.currentFossilGroupType === "only" ? (
                    <div className="download-fossil-selectdiv">
                      <Select
                        className="download-section-fossilgroup"
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Please select fossil groups"
                        onChange={this.ChangeSectionFossilGroup}
                      >
                        {Utils.fossilGroup(fossilgroup)}
                      </Select>
                    </div>
                  ) : null}

                  <Radio style={radioStyle} value={"ignore"}>
                    Ignore the following fossil groups
                  </Radio>

                  {this.state.currentFossilGroupType === "ignore" ? (
                    <div className="download-fossil-selectdiv">
                      <Select
                        className="download-section-fossilgroup"
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Please select fossil groups"
                        onChange={this.ChangeSectionFossilGroup}
                      >
                        {Utils.fossilGroup(fossilgroup)}
                      </Select>
                    </div>
                  ) : null}
                </Radio.Group>
                <div className="download-section-btns">
                  <Button type="gbdb" onClick={this.godownloadSection}>
                    Export to CONOP files
                  </Button>
                </div>
              </TabPane>
              <TabPane
                tab="Open nomenclatures (names with ?, cf., aff., sp. etc.)"
                key="NomenclatureType"
              >
                <div>
                  <Radio.Group
                    onChange={this.changetNomenclatureType}
                    value={this.state.currentNomenclature}
                  >
                    <Radio style={radioStyle} value={"allnome"}>
                      Include all open nomenclatures
                    </Radio>

                    <Radio style={radioStyle} value={"ignorenome"}>
                      Ignore taxon names with
                    </Radio>

                    <div>
                      <Checkbox
                        className="all-nomenclaturesOptions"
                        onChange={this.onCheckAllNomenclatures}
                        checked={this.state.checkNomenclaturesAll}
                      >
                        Check all
                      </Checkbox>
                      <Checkbox.Group
                        className="download-section-group"
                        options={nomenclaturesOptions}
                        onChange={this.onNomenclatureChange}
                        value={this.state.checkedList}
                      ></Checkbox.Group>
                    </div>
                  </Radio.Group>
                  <div className="download-section-btns">
                    <Button type="gbdb" onClick={this.godownloadSection}>
                      Export to CONOP files
                    </Button>
                  </div>
                </div>
              </TabPane>
            </Tabs>
            {//   this.state.downLoadFileLoading
              this.state.downLoadFileLoading && (
                <div className="download-loading file-downloading">
                  <Spin />
                  <div className="download-tips">
                    File downloading, please wait...
                </div>
                  <div className="download-tips-blocked">
                    Please confirm that the file download function is not blocked
                </div>
                </div>
              )}
          </div>
        </Modal>
        <Modal
          title="Folder"
          destroyOnClose
          visible={this.state.folderOperateShow}
          className="subset-movemodal folder-common-modal clearfix"
          width="30%"
          onOk={this.handlefolderOk}
          onCancel={this.handlefolderCancel}
          footer={null}
        >
          <AllFolders
            folderType="section"
            checkedObj={this.state.checkedObj}
            closeCommonFolder={this.handlefolderOk}
          >
          </AllFolders>
        </Modal>
      </div>
    );
  }
}

export default SectionAllTableResult;
