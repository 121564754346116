/* eslint-disable  */
import React from "react";
import { Select } from "antd";
import moment from "moment";
import Storage from "src/js/storage";
const { Option, OptGroup } = Select;

export default {


  createUuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    var uuid = s.join("");
    return uuid;
  },

  objComparison(propertyName) {
    return function (object1, object2) {
      var value1 = object1[propertyName];
      var value2 = object2[propertyName];
      if (value1 < value2) {
        return 1;
      } else if (value1 > value2) {
        return -1;
      } else {
        return 0;
      }
    };
  },
  objComparisonPos(propertyName) {
    return function (object1, object2) {
      var value1 = object1[propertyName];
      var value2 = object2[propertyName];
      if (value1 < value2) {
        return -1;
      } else if (value1 > value2) {
        return 1;
      } else {
        return 0;
      }
    };
  },

  easySort(value1, value2) {
    if (value1 < value2) {
      return 1;
    } else if (value1 > value2) {
      return -1;
    } else {
      return 0;
    }
  },

  arrayIsEqual(arr1, arr2) {
    //判断2个数组是否相等
    if (arr1 === arr2) {
      //如果2个数组对应的指针相同，那么肯定相等，同时也对比一下类型
      return true;
    } else {
      if (arr1.length != arr2.length) {
        return false;
      } else {
        //长度相同
        for (let i in arr1) {
          //循环遍历对比每个位置的元素
          if (arr1[i] != arr2[i]) {
            //只要出现一次不相等，那么2个数组就不相等
            return false;
          }
        } //for循环完成，没有出现不相等的情况，那么2个数组相等
        return true;
      }
    }
  },

  seesionStorageObj(name, obj) {
    let checkedIdStr = JSON.stringify(obj);

    localStorage.setItem(name, checkedIdStr);
  },
  getSessionObj(name) {
    let objAfter = JSON.parse(localStorage.getItem(name));

    return objAfter;
  },
  clearSessionObj() {
    localStorage.clear();
  },
  arrayIncludes(array, item) {
    return array.includes(item);
  },

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  },
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        let cookie = c.substring(name.length, c.length);
        return cookie;
      }
    }
    return "";
  },
  delCookie(name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = this.getCookie(name);
    if (cval != null)
      document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
  },

  timesFun(timesData) {
    //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
    var dateBegin = new Date(timesData); //将-转化为/，使用new Date
    var dateEnd = new Date(); //获取当前时间
    var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
    var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
    var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
    var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
    //计算相差分钟数
    var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
    var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
    //计算相差秒数
    var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
    var seconds = Math.round(leave3 / 1000);
    var timesString = "";

    if (dayDiff != 0) {
      timesString = dayDiff + " days ago";
    } else if (dayDiff === 0 && hours != 0) {
      timesString = hours + " hours ago";
    } else if (dayDiff === 0 && hours === 0) {
      timesString = minutes + " mins ago";
    }
    return timesString;
  },

  // 经纬度划分
  cacuLonLat(a) {
    let degree = parseInt(a);
    let minute = parseInt((a - degree) * 60);
    let second = parseFloat((a - degree) * 3600 - minute * 60);

    // let decimal= degree +minute/60+second/3600

    return { degree, minute, second };
  },

  filterParams(obj) {
    let _newPar = {};
    for (let key in obj) {
      //如果对象属性的值不为空，就保存该属性（这里我做了限制，如果属性的值为0，保存该属性。如果属性的值全部是空格，属于为空。）
      if (
        (obj[key] === 0 || obj[key]) &&
        obj[key].toString().replace(/(^\s*)|(\s*$)/g, "") !== ""
      ) {
        //记录属性
        _newPar[key] = obj[key];
      }
    }
    //返回对象

    return _newPar;
  },
  filterCondition(searchParams) {

    if (searchParams.ref_publication_year) {
      searchParams["ref_publication_year"] = moment(
        searchParams["ref_publication_year"]
      ).format("YYYY");
    }
    if (searchParams.ref_publication_year === null) {
      searchParams["ref_publication_year"] = "";
    }

    if (searchParams.year) {
      searchParams["year"] = moment(searchParams["year"]).format("YYYY");
    }
    if (searchParams.year === null) {
      searchParams["year"] = "";
    }
    if (searchParams.QXRQ) {
      searchParams["QXRQ"] = moment(searchParams["QXRQ"]).format("YYYY-MM-DD");
    }

    if (searchParams.SMRQ) {
      searchParams["SMRQ"] = moment(searchParams["SMRQ"]).format("YYYY-MM-DD");
    }

    // group_id
    if (searchParams.group_id) {
      if (searchParams.group_id.length > 0) {
        searchParams.group_id = searchParams.group_id.join(",");
      } else {
        searchParams.group_id = searchParams.group_id[0];
      }
    }
    for (var key in searchParams) {
      let resultStr = this.spliteStr(key);
      let objKey = resultStr.first;
      let lastValue = resultStr.last;

      if (lastValue === "from") {
        let from = searchParams[key] !== null ? searchParams[key] : "";
        if (objKey === "createdAt") {
          from = moment(from).format("YYYY-MM-DD");
        }
        searchParams[objKey] = Object.assign(
          searchParams[objKey] || { condition: "median", from: "", to: "" },
          { from }
        );
        delete searchParams[key];
      }
      if (lastValue === "to") {
        let to = searchParams[key] !== null ? searchParams[key] : "";
        if (objKey === "createdAt") {
          to = moment(to).format("YYYY-MM-DD");
        }
        searchParams[objKey] = Object.assign(
          searchParams[objKey] || { condition: "median", from: "", to: "" },
          { to }
        );
        delete searchParams[key];
      }
      if (lastValue === "condition") {
        let condition = searchParams[key] || "";

        searchParams[objKey] = Object.assign(
          searchParams[objKey] || { condition: "", value: "" },
          { condition }
        );
        delete searchParams[key];
      }
      if (lastValue === "values") {
        let value = searchParams[key] || "";

        if (objKey === "ref_publication_year") {
          value = moment(value).format("YYYY");
        }
        searchParams[objKey] = Object.assign(
          searchParams[objKey] || { condition: "", value: "" },
          { value }
        );

        delete searchParams[key];
      }

      if (lastValue === "includevalues") {
        let value = searchParams[key] || "";

        searchParams[objKey] = Object.assign(
          searchParams[objKey] || { condition: "includes", value: "" },
          { value }
        );

        delete searchParams[key];
      }

      if (lastValue === "names") {
        let name = searchParams[key] || "";

        searchParams[objKey] = Object.assign(
          searchParams[objKey] || { name: "", type: "" },
          { name }
        );
        delete searchParams[key];
      }
      if (lastValue === "types") {
        let type = searchParams[key] || "";

        searchParams[objKey] = Object.assign(
          searchParams[objKey] || { name: "", type: "" },
          { type: Number(type) }
        );
        delete searchParams[key];
      }
      if (searchParams["release_date"]) {
        searchParams["release_date"] = moment(
          searchParams["release_date"]
        ).format("YYYY-MM-DD");
      }
    }
    for (let i in searchParams) {
      if (
        searchParams[i] &&
        searchParams[i].condition &&
        searchParams[i].value === ""
      ) {
        delete searchParams[i];
      }
    }

    if (searchParams.sort && searchParams["sort"].name === "") {
      delete searchParams.sort;
    }
    return searchParams;
  },
  spliteStr(str) {
    let index = str.lastIndexOf("_");
    let first = str.substring(0, index);
    let last = str.substring(index + 1, str.length);
    let resultStr = { first, last };
    return resultStr;
  },
  addEnterer(searchParams) {
    // 添加enter
    if (!searchParams.enterer && Storage.getItem("gbdbid")) {
      searchParams.enterer = Storage.getItem("gbdbid");
    }
    return searchParams;
  },
  addAudit(searchParams, inheritData) {
    // 添加enter
    if (!searchParams.authorizer_id && Storage.getItem("gbdbid")) {
      // 获取用户信息
      if (Storage.getItem("checkerid") !== null) {
        searchParams.authorizer_id = Storage.getItem("checkerid");
      } else if (inheritData && inheritData.authorizer_id) {
        searchParams.authorizer_id = inheritData.authorizer_id;
      }
    }
    if (searchParams.accessibility == undefined && Storage.getItem("gbdbid")) {
      // 获取用户信息
      if (inheritData && inheritData.accessibility !== undefined) {
        searchParams.accessibility = inheritData.accessibility;
      }
    }

    return searchParams;
  },

  // 设置localstorage
  setLocalStorage(key, value) {
    var curtime = new Date().getTime(); // 获取当前时间 ，转换成JSON字符串序列
    var valueDate = JSON.stringify({
      val: value,
      timer: curtime
    });
    try {
      localStorage.setItem(key, valueDate);
    } catch (e) {
      /*if(e.name === 'QUOTA_EXCEEDED_ERR' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
            localStorage.clear();
        } else {
        }*/
      // 兼容性写法
      if (isQuotaExceeded(e)) {
        localStorage.clear();
      } else {
      }
    }
  },

  isQuotaExceeded(e) {
    var quotaExceeded = false;
    if (e) {
      if (e.code) {
        switch (e.code) {
          case 22:
            quotaExceeded = true;
            break;
          case 1014: // Firefox
            if (e.name === "NS_ERROR_DOM_QUOTA_REACHED") {
              quotaExceeded = true;
            }
            break;
        }
      } else if (e.number === -2147024882) {
        // IE8
        quotaExceeded = true;
      }
    }
    return quotaExceeded;
  },
  getLocalStorage(key, time) {
    // var exp = time; // 一天的秒数
    var exp = 60 * 60 * 24;
    if (localStorage.getItem(key)) {
      var vals = localStorage.getItem(key); // 获取本地存储的值
      var dataObj = JSON.parse(vals); // 将字符串转换成JSON对象
      // 如果(当前时间 - 存储的元素在创建时候设置的时间) > 过期时间
      var isTimed = new Date().getTime() - dataObj.timer > exp;
      if (isTimed) {
        localStorage.removeItem(key);
        return null;
      } else {
        var newValue = dataObj.val;
      }
      return newValue;
    } else {
      return null;
    }
  },

  //作者逗号处理
  authors(author) {
    let authors;
    let newauthors;
    let nAuth = [
      "First",
      "Second",
      "Third",
      "Fourth",
      "Fifth",
      "Sixth",
      "Seventh",
      "Eighth",
      "Ninth",
      "Tenth"
    ];
    if (author) {
      authors = author.split(",");
      authors.map((item, index) => {
        authors[index] = {
          key: index,
          name: nAuth[index],
          value: item
        };
      });
      // newauthors={
      //   key: authors.length+1,
      //   name: nAuth[authors.length],
      //   value:""
      // }
    } else {
      authors = [];
    }

    return authors;
  },

  //folderTypeFun type变为数字
  folderTypeFun(value) {
    let folderTypeNum = 1;

    if (value === "reference") {
      folderTypeNum = 1;
    } else if (value === "section") {
      folderTypeNum = 2;
    } else if (value === "collection") {
      folderTypeNum = 3;
    } else if (value === "fossilOccurrence") {
      folderTypeNum = 4;
    } else if (value === "taxonomy") {
      folderTypeNum = 5;
    } else if (value === "geochemical") {
      folderTypeNum = 6;
    } else if (value === "roundtrip") {
      folderTypeNum = 7;
    } else if (value === "coreimage") {
      folderTypeNum = 8;
    } else if (value === "lithology_section") {
      folderTypeNum = 9;
    } else if (value === "lithology_description") {
      folderTypeNum = 10;
    } else if (value === "section_formation") {
      folderTypeNum = 11;
    } else if (value === "section_unit") {
      folderTypeNum = 12;
    } else if (value === "section_geology") {
      folderTypeNum = 13;
    } else if (value === "oceanic") {
      folderTypeNum = 7;
    }else if (value === "fossil"){
      folderTypeNum = 14;
    }
    return folderTypeNum;
  },
  disabledDate(current) {
    return current < moment().subtract(1, "days");
  },
  fossilGroup(value) {
    if (JSON.stringify(value) === "{}") {
      return <Option value=""></Option>;
    } else {
      let optgrops = [];
      if (value) {
        value.map((item, index) => {
          optgrops.push(
            <OptGroup label={item.key} key={item.key}>
              {item.children && item.children.length > 0 ? (
                item.children.map(childItem => {
                  let ops = [];
                  ops.push(
                    <Option value={childItem.split(" ")[1]} key={index}>
                      {childItem.split(" ")[1]}
                    </Option>
                  );

                  return ops;
                })
              ) : (
                  <Option value={item.key} key={item.key}>{item.key}</Option>
                )}
            </OptGroup>
          );
        });
      }

      return optgrops;
    }
  },
  fullName(
    taxonName1,
    taxonName2,
    genusPrefix,
    spPrefix1,
    spPrefix2,
    spNameDict,
    spSuffix
  ) {
    let fullTaxonName = "";
    let genusName = "";
    let speciesName = "";
    genusName = taxonName1 ? taxonName1 : "";
    speciesName = taxonName2 ? taxonName2 : "";

    if (genusPrefix) {
      if (genusPrefix.trim() != "" && genusPrefix.trim() != "Null") {
        switch (genusPrefix.trim()) {
          case '""':
            genusName = '"' + genusName + '"';
            break;
          case '""cf.':
            genusName = 'cf. "' + genusName + '"';
            break;
          case '?""cf.':
            genusName = '? cf. "' + genusName + '"';
            break;
          default:
            genusName = genusPrefix.trim() + " " + genusName;
            break;
        }
      }
    }

    if (spPrefix1) {
      if (spPrefix1.trim() != "" && spPrefix1.trim() != "Null") {
        switch (spPrefix1.trim()) {
          case '""':
            speciesName = '"' + speciesName + '"';
            break;
          case '?""':
            speciesName = '? "' + speciesName + '"';
            break;
          default:
            speciesName = spPrefix1.trim() + " " + speciesName;
            break;
        }
      }
    }
    if (spPrefix2) {
      if (spPrefix2.trim() != "" && spPrefix2.trim() != "Null") {
        speciesName = spPrefix2.trim() + " " + speciesName;
      }
    }
    if (spNameDict) {
      if (spNameDict.trim() != "" && spNameDict.trim() != "Null") {
        //speciesName = spNameDict.trim() + " " + speciesName;
        speciesName = speciesName + " " + spNameDict.trim();
      }
    }

    if (spSuffix) {
      if (spSuffix.trim() != "" && spSuffix.trim() != "Null") {
        speciesName = speciesName + " " + spSuffix.trim();
      }
    }

    fullTaxonName = genusName + " " + speciesName;

    return fullTaxonName;
  },
  getSearch(search, key) {
    const paramsString = search.substring(1);
    const searchParams = new URLSearchParams(paramsString);
    const value = searchParams.get(key);

    return value;
  },
  full(ele) {
 
    if (ele.requestFullscreen) {
      ele.requestFullscreen();
    } else if (ele.mozRequestFullScreen) {
      ele.mozRequestFullScreen();
    } else if (ele.webkitRequestFullscreen) {
      ele.webkitRequestFullscreen();
    } else if (ele.msRequestFullscreen) {
      ele.msRequestFullscreen();
    } else {
    }
  },
  exitFullscreen() {
    if (document.exitFullScreen) {
      document.exitFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  },
  getFullscreenElement() {
    return (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullScreenElement ||
      document.webkitFullscreenElement ||
      null
    );
  },
  isFullScreen() {
    return !!(
      document.fullscreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreen ||
      document.webkitFullScreen ||
      document.msFullScreen
    );
  },
  isFullscreenEnabled() {
    return (
      document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.msFullscreenEnabled
    );
  },
  isEmpty(value) {
    //去除空格;
    if (value === "" || value === undefined || value === null) {
      return true;
    } else {
      return false;
    }
  },
  isNumber(val) {
    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    if (regPos.test(val) || regNeg.test(val)) {
      return true;
    } else {
      return false;
    }
  },
  deepObjCopy(obj) {
    if (typeof obj != "object") {
      return obj;
    }
    var newobj = {};
    for (var attr in obj) {
      newobj[attr] = this.deepObjCopy(obj[attr]);
    }
    return newobj;
  },
  isNumber(val){
    

    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    
    if(regPos.test(val) || regNeg.test(val)){
        return true;
    }else{
        return false;
    }

},
  // 浏览器版本检测
  getExplorer() {
    var explorer = window.navigator.userAgent.toLowerCase();
    
    //ie
    if (explorer.indexOf('msie') >= 0) {
    var ver = explorer.match(/msie ([\d.]+)/)[1];
    return { type: 'IE', version: ver };
    }
    //firefox
    else if (explorer.indexOf('firefox') >= 0) {
    var ver = explorer.match(/firefox\/([\d.]+)/)[1];
    return { type: 'Firefox', version: ver };
    }
    //Chrome
    else if (explorer.indexOf('chrome') >= 0) {
    var ver = explorer.match(/chrome\/([\d.]+)/)[1];
    return { type: 'Chrome', version: ver };
    }
    //Opera
    else if (explorer.indexOf('opera') >= 0) {
    var ver = explorer.match(/opera.([\d.]+)/)[1];
    return { type: 'Opera', version: ver };
    }
    //Safari
    else if (explorer.indexOf('Safari') >= 0) {
    var ver = explorer.match(/version\/([\d.]+)/)[1];
    return { type: 'Safari', version: ver };
    }    
},
getExplorer2() {
  var agent = navigator.userAgent.toLowerCase();

	var regStr_ie = /msie [\d.]+;/gi;
	var regStr_ff = /firefox\/[\d.]+/gi
	var regStr_chrome = /chrome\/[\d.]+/gi;
	var regStr_saf = /safari\/[\d.]+/gi;
	var isIE = agent.indexOf("compatible") > -1 && agent.indexOf("msie" > -1); //判断是否IE<11浏览器  
	var isEdge = agent.indexOf("edge") > -1 && !isIE; //判断是否IE的Edge浏览器  
	var isIE11 = agent.indexOf('trident') > -1 && agent.indexOf("rv:11.0") > -1;
	if (isIE) {
		var reIE = new RegExp("msie (\\d+\\.\\d+);");
		reIE.test(agent);
		var fIEVersion = parseFloat(RegExp["$1"]);
		if (fIEVersion == 7) {
			return ["IE/7"];
		} else if (fIEVersion == 8) {
			return ["IE/8"];
		} else if (fIEVersion == 9) {
			return [ "IE/9"];
		} else if (fIEVersion == 10) {
			return ["IE/10"];
		} 
	} //isIE end 
	if (isIE11) {
		return ["IE/11"]
	}
	//firefox
	if (agent.indexOf("firefox") > 0) {
		return agent.match(regStr_ff);
	}
	//Safari
	if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
		return agent.match(regStr_saf);
	}
	//Chrome
	if (agent.indexOf("chrome") > 0) {
		return agent.match(regStr_chrome);
	}

}
}
