import React, { Component } from "react";
import { DatePicker, Button, Modal, message, Spin, Form } from "antd";

import InfiniteScroll from "react-infinite-scroller";
import Storage from "src/js/storage";
import messageApis from "src/js/apis/message";
import moment from "moment";

import "src/js/global.js";
import "./index.css";
const { RangePicker } = DatePicker;

const rangeConfig = {
  rules: [{ type: "array" }]
};

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasMore: true,
      messageLists: [],
      isRange: false,
      mesTotal: 0,
      pageSize: 20,
      currentPage: 1
    };
  }
  isGbdbUser=()=>{
    let token = Storage.getItem("token");
   
    if(token==undefined){
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    }
  
  }
  async componentDidMount() {
    this.isGbdbUser()
    let { pageSize, currentPage } = this.state;
    let pageParams = {
      pageSize,
      page: currentPage
    };
    await this.getMessages(pageParams, res => {
      this.setState({
        messageLists: res.result,
        mesTotal: res.total
      });
    });
  }

  selectMsg = async () => {
    let fieldsValue = this.props.form.getFieldsValue();
    const rangeValue = fieldsValue["messageTime_range"];
    let params;
    if (rangeValue && rangeValue.length > 0) {
      const values = {
        ...fieldsValue,
        messageTime_range: [
          rangeValue[0].format("YYYY-MM-DD"),
          rangeValue[1].format("YYYY-MM-DD")
        ]
      };
      let dateFrom = values.messageTime_range[0];
      let dateTo = values.messageTime_range[1];
      params = {
        dateFrom,
        dateTo,
      
      };
    } else {
      params = {
       
      };
    }
    let { pageSize, currentPage } = this.state;
    let pageParams = {
      pageSize,
      page: 1
    };
    this.setState({
      searchParams:params
    },()=>{
      let {messageLists}=this.state
      this.getMessages(pageParams, res => {
        messageLists = res.result
        this.setState({
          messageLists,
          loading: false
        });
      });
    })
 
  };
  getMessages = async (pageParams, callback) => {
    let allparams = {};
    let uid = Storage.getItem("gbdbid");
    let uidParams={}
    let {searchParams}=this.state
    if (uid) {
      uidParams={uid}
      let orderParams = { ordername: "createdAt", orderType: 1 };
      Object.assign(allparams,uidParams, searchParams, pageParams, orderParams);
      let MessagesBack = await messageApis.getMessages(allparams);
      if (MessagesBack.error) {
        const modal = Modal.error({
          title: "This is an error message",
          content: MessagesBack.error
        });
        setTimeout(() => {
          modal.destroy();
        }, 3000);
      } else {
        callback(MessagesBack);
      }
    }
  };
  handleInfiniteOnLoad = () => {
    let { messageLists, mesTotal } = this.state;
    this.setState({
      loading: true
    });
    if (messageLists.length >= mesTotal) {
      message.warning("List loaded all");
      this.setState({
        hasMore: false,
        loading: false
      });
      return;
    }
    let { pageSize, currentPage } = this.state;
    this.setState(
      {
        currentPage: currentPage + 1
      },
      () => {
        let pageParams = {
          pageSize,
          page: this.state.currentPage
        };
        this.getMessages(pageParams, res => {
          messageLists = messageLists.concat(res.result);
          this.setState({
            messageLists,
            loading: false
          });
        });
      }
    );
  };
  blurRange = () => {
    this.setState({
      isRange: false
    });
  };
  handleChange = value => {
    if (moment(value[1], "YYYY")) {
      this.setState({
        isRange: true
      });
    } else {
      this.setState({
        isRange: false
      });
    }
  };

  render() {
    let { messageLists } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="message">
        <div className="message-content">
          <div className="reference-search-operate">
            <p className="group-index-title">Message</p>
          </div>
          <div className="message-mian-content clearfix">
            <div className="message-search-input">
              <div className="message-time">收到的时间：</div>

              <div>
                <Form>
                  <Form.Item>
                    {getFieldDecorator(
                      "messageTime_range",
                      rangeConfig
                    )(
                      <RangePicker
                        onBlur={this.blurRange}
                        onChange={this.handleChange}
                        className="message-rangepicker"
                      />
                    )}
                  </Form.Item>
                </Form>
              </div>
              <div className="select-message">
                <Button
                  type="gbdb"
                  onClick={this.selectMsg}
                  // disabled={isRange === true ? true : false}
                >
                  Select
                </Button>
              </div>
            </div>
            <div className="message-lists-all message-all">
              <div className="message-icons">
                <span className="iconfont icon-xinfeng"></span>
                {/* <span className="iconfont icon-md-mail-open"></span> */}
              </div>
              <div className="message-lists">
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={this.handleInfiniteOnLoad}
                  hasMore={!this.state.loading && this.state.hasMore}
                  useWindow={false}
                  className="message-lists-scroll"
                >
                  <ul className="message-lists-ul">
                    {messageLists.length > 0 ? (
                      messageLists.map((item, index) => {
                        return (
                          <li className="message-list-item" key={index}>
                             <div className="">
                              {index + 1}
                            </div>
                            <div
                              className=""
                              title={item.content}
                            >
                              {item.content}
                            </div>
                           
                            <div className="">
                              {moment(item.createdAt).format("YYYY-MM-DD")}
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <li className="message-no-res">No Message</li>
                    )}

                    {this.state.loading && this.state.hasMore && (
                      <div className="demo-loading-container">
                        <Spin />
                      </div>
                    )}
                  </ul>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Message = Form.create({})(Message);
export default Message;
