import React, { Component } from "react";
import { Menu, Form, Input, Button, Modal } from "antd";
import { NavLink } from "react-router-dom";
import BaseUpload from "commons/BaseUpload/index";
import "./index.css";
import userApis from "src/js/apis/user";
import Storage from "src/js/storage";
import "src/js/global.js";
const { TextArea } = Input;
let showExpertData = (userType, authState) => {
  let showUser = false;

  if (authState === 2) {
    showUser = true;
  } else if (authState === 3 && userType === 2) {
    showUser = true;
  }

  return showUser;
};

class Basic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey: "basic",
      passwordShow: false,
      uploadFiles: {},
      userData: {},
      thumbnail: process.env.PUBLIC_URL + "/imgs/uploadUser.png",
      isExpert: false,
    };
  }

  changeProfile = (e) => {
    this.setState(
      {
        currentKey: e.key,
      },
      () => {}
    );
  };

  changeBasic = () => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let { userData } = this.state;

        let id = userData.userid;

        const formData = new FormData();
        formData.append("id", id);
        let thumbnail = this.state.uploadFiles;
        for (let [key, value] of Object.entries(values)) {
          formData.append(key, value);
        }

        formData.append("thumbnail", thumbnail);

        let updateUserBack = await userApis.updateUserInfoById(formData);
        if (updateUserBack.error) {
          Modal.error({
            title: "This is an error message",
            content: updateUserBack.error,
          });
        } else {
          this.setState(
            {
              userData: updateUserBack.result,
              thumbnail: "/api" + updateUserBack.result.thumbnail,
            },
            () => {
              Modal.success({
                title: "This is a successful message",
                content: "Change Success",
              });
              if (document.querySelector(".login-user")) {
                let imgDom = document.querySelector(".login-user").querySelector("img");
                if (imgDom) {
                  imgDom.setAttribute("src", this.state.thumbnail);
                }
              }
              if (document.querySelector(".header-icon")) {
                let nmaeDom = document.querySelector(".header-icon").querySelector(".gbdb-user-name");
                nmaeDom.setAttribute("title", this.state.userData.username);
                nmaeDom.innerHTML=this.state.userData.username
              }
            }
          );
        }
      }
    });
  };
  cancelBasic = () => {
    Modal.info({
      title: "This is a notification message",
      content: "Change Success",
    });
    this.props.form.resetFields();
  };
  changePassword = () => {
    let { passwordShow } = this.state;
    if (passwordShow === true) {
      this.setState({
        passwordShow: false,
      });
    } else {
      this.setState({
        passwordShow: true,
      });
    }
  };
  updatePassword = async () => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        if (err.oldPassword) {
          return;
        } else if (err.password) {
          Modal.error({
            title: "Please enter the correct password!",
            content: "At least 8 characters and must contain 1 uppercase letter, 1 lowercase letter and 1 digit, not including special characters.",
          });
          return;
        }
      }

      let { userData } = this.state;
      let oldPassword = values.oldPassword;
      let password = values.password;
      if (oldPassword === password) {
        Modal.error({
          title: "This is an error message",
          content: "The new password is not the same",
        });
        return;
      }
      let params = {
        id: userData.userid,
        oldPassword,
        newPassword: password,
      };
      let pwdBack = await userApis.changePassword(params);
      if (pwdBack.result) {
        Modal.success({
          title: "This is a successful message",
          content: "Password changed successfully",
        });
        this.setState({
          passwordShow: false,
        });
      } else {
        Modal.error({
          title: "This is an error message",
          content: pwdBack.error,
        });
      }
    });
  };
  onChangeFile = (file) => {
    let newest = file;
    this.setState(
      {
        uploadFiles: newest,
      },
      () => {}
    );
  };
  isGbdbUser = () => {
    let token = Storage.getItem("token");

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page",
        });
        this.props.history.push("/home");
      }
    }
  };
  async componentDidMount() {
    this.isGbdbUser();
    let allUserData = {};
    let token = Storage.getItem("token");
    let params = {
      token,
      loginby: 1,
    };
    let fossilBack = await userApis.initUser(params);
    if (fossilBack.result) {
      this.setState(
        {
          thumbnail: "/api" + fossilBack.result.thumbnail,
        },
        () => {}
      );

      let updateUser = {
        userid: fossilBack.result.id,
        loginby: 1,
      };
      let gbdbUserBack = await userApis.gbdbUser(updateUser);
      if (gbdbUserBack.result) {
        let gbdbData = gbdbUserBack.result;
        let baseData = fossilBack.result;
        Object.assign(allUserData, gbdbData, baseData);
        let { userType, authState } = gbdbData;
        let isExpert = showExpertData(userType, authState);
        if (isExpert) {
          this.setState({
            isExpert: true,
          });
        }
      } else {
        this.props.history.push("/home");
      }

      this.setState({
        userData: allUserData,
      });
    } else {
      if (fossilBack.state === 204) {
        Storage.removeItem("token");
        Storage.removeItem("fossilid");
        Storage.removeItem("gbdbid");
        Storage.removeItem("checkerid");
        Storage.removeItem("checkerName");
        Storage.removeItem("gbdblevel");
        Storage.removeItem("Inputerid");
        return;
      }
      Modal.error({
        title: "This is an error message",
        content: fossilBack.error,
      });
      this.props.history.push("/home");
    }
  }

  render() {
    let { getFieldDecorator } = this.props.form;
    let { currentKey, passwordShow } = this.state;
    let { userData, thumbnail } = this.state;

    return (
      <div className="group-index form-contents" id="profile_basic">
        <div className="reference-search-operate">
          <p className="group-index-title">Profile</p>
        </div>
        <div className="reference-header" id="referenceMenu">
          <Menu defaultSelectedKeys={currentKey} mode="horizontal" onClick={(e) => this.changeProfile(e)} className="reference-search-menu reference-menu" selectedKeys={currentKey}>
            <Menu.Item key="basic">
              <div>
                <NavLink to="/profile/basic">Basic Info</NavLink>
              </div>
            </Menu.Item>
            <Menu.Item key="compiler">
              <div>{this.state.isExpert ? <NavLink to="/profile/advance/expert"> Advanced Info</NavLink> : <NavLink to="/profile/advance/compiler">Advanced Info</NavLink>}</div>
            </Menu.Item>
          </Menu>
        </div>

        <div className="form-mian-content profile-basic">
          <div className="profile-basic-title">Basic Info</div>
          <div className="profile-basic-content clearfix">
            <div className="profile-basic-form">
              <Form layout="vertical">
                <Form.Item label="Name to display">
                  {getFieldDecorator("username", {
                    initialValue: userData.username || "",
                  })(<Input placeholder="" />)}
                </Form.Item>
                <Form.Item label="Account">
                  {getFieldDecorator("account", {
                    initialValue: userData.account || "",
                  })(<Input placeholder="" disabled />)}
                </Form.Item>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    initialValue: userData.email || "",
                  })(<Input placeholder="" disabled />)}
                </Form.Item>
                <Form.Item label="Password">
                  {passwordShow
                    ? getFieldDecorator("oldPassword", {
                        rules: [
                          {
                            required: true,
                            message: "Please input the old password",
                          },
                        ],
                        initialValue: "",
                      })(<Input.Password placeholder="old password " className="profile-change-input" />)
                    : null}
                  {passwordShow
                    ? getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter the correct password!",
                            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                          },
                        ],
                        initialValue: "",
                      })(<Input.Password placeholder="new password " className="profile-change-input" />)
                    : null}
                  <Button type="gbdb" className="profile-change-password" onClick={this.changePassword}>
                    {!passwordShow ? "Change" : "Cancel"}
                  </Button>
                  {passwordShow ? (
                    <Button type="gbdb" className="profile-change-password" onClick={this.updatePassword}>
                      Confirm
                    </Button>
                  ) : null}
                </Form.Item>
                <Form.Item label="Your tagline">
                  {getFieldDecorator("tagline", {
                    initialValue: userData.tagline || "",
                  })(<TextArea placeholder="Describe yourself in 140 words" rows={10}></TextArea>)}
                </Form.Item>
              </Form>
            </div>
            <div className="profile-basic-photo">
              <div className="basic-photo-show">
                {/* <img src={require("src/assets/images/profile/upload.png")} /> */}
                <BaseUpload showImg={true} defaultImg={thumbnail} listType="picture-card" showUploadList={false} onChangeFile={(file) => this.onChangeFile(file)}></BaseUpload>
              </div>

              {/* <div className="basic-upload-confirm">
                <Button type="gbdb" className="profile-change-password">
                  Confirm
                </Button>
              </div> */}
            </div>
          </div>
          <div className="profile-basic-btns">
            <Button type="gbdb" className="profile-change-password" onClick={this.changeBasic}>
              Submit
            </Button>
            {/* <Button
              type="gbdb"
              className="profile-change-password"
              onClick={this.cancelBasic}
            >
              Cancel
            </Button> */}
          </div>
        </div>
      </div>
    );
  }
}
Basic = Form.create({})(Basic);
export default Basic;
