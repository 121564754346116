import React, { Component } from "react";
import YearPicker from "commons/YearPicker/index";
import "src/js/global.js";

import Utils from "src/js/utils";
import dataApis from "src/js/apis/data";
import moment from "moment";
import "./index.css";
import { withRouter } from "react-router-dom";

import {
  Form,
  Input,
  Select,
  Modal,
  DatePicker,
  InputNumber,
  AutoComplete
} from "antd";
const { Option } = Select;
const FormItem = Form.Item;

let refTypes = [
  // " ",
  "journal article",
  "book",
  "edit book",
  "book chapter",
  "Ph. D. dissertation",
  "master thesis",
  "paper presented at meeting",
  "abstract",
  "technical report"
];
@withRouter
class ReferenceOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizers: [],
      enterers: [],
      helpref: false,
      searchAllValues: props.searchAllValues || {},
      allChildError: {},
      reffrom: props.searchAllValues ? props.searchAllValues.ref_id_from : "",
      refto: props.searchAllValues ? props.searchAllValues.ref_id_to : "",

      createdAtfrom: props.searchAllValues ? props.searchAllValues.createdAt_from : null,
      createdAtto: props.searchAllValues ? props.searchAllValues.createdAt_to : null,
      yearValue: null
    };
  }
  componentDidMount = () => {};
  componentWillReceiveProps = nextProps => {};
  sectionStartChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  sectionEndChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  onSectionChange=()=>{}
  onSectionChange1 = (value, field, type) => {
   
    let stateType = field + type;
    
    let { allChildError } = this.state;
    this.setState(
      {
        [stateType]: value
      },
      () => {
        let compareFrom = field + "from";
        let compareTo = field + "to";

        let compareOne = this.state[compareFrom];
        let compareTwo = this.state[compareTo];
       
        let errorType = "error" + field;
        let helpType = "help" + field;
        if (
          (Utils.isEmpty(compareOne) && !Utils.isEmpty(compareTwo)) ||
          (Utils.isEmpty(compareTwo) && !Utils.isEmpty(compareOne))
        ) {
          this.setState(
            {
              [errorType]: true,
              [helpType]: "Start and end data need to be filled in"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
        } else if (compareTwo && compareOne && compareTwo < compareOne) {
         
          this.setState(
            {
              [errorType]: true,
              [helpType]: "The starting value is less than the ending value"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
        } else {
          this.setState(
            {
              [errorType]: false,
              [helpType]: ""
            },
            () => {
              if (allChildError[errorType]) {
                delete allChildError[errorType];
              }
            }
          );
        }
      }
    );
  };

  handleNomSearch = async (value, type) => {
    if (value) {
      let searchResult;
      if (type === "authorizers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteExperts(params);
      } else if (type === "enterers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteEnterer(params);
      }

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });

        return;
      } else if (searchResult.result) {
        this.setState(
          {
            [type]: searchResult.result
          },
          () => {}
        );
      }
    } else {
      this.setState({ [type]: [] });
    }
  };

  handleNomChange = (value, type) => {
    this.props.form.setFieldsValue({
      [type]: value
    });
  };
  onStartChange = value => {
    this.onTimeChange("startValue", value);
    this.sectionStartChange(value, "createdAt", "from");
  };
  onEndChange = value => {
    this.onTimeChange("endValue", value);
    this.sectionStartChange(value, "createdAt", "to");
  };
  onTimeChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  publishYear = value => {
    this.setState({
      yearValue: value
    });
    this.props.form.setFieldsValue({
      ref_publication_year_values: value
    });
  };
  render() {
    let { searchAllValues } = this.state;

    const { getFieldDecorator } = this.props.form;
    const authorizers = this.state.authorizers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    const enterers = this.state.enterers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    return (
      <div id="main" className="reference-search-form">
        <div className="searchSection-main  reference-search-form search-common-form">
          <div className="reference-search-content reference-search-baseform">
            <Form>
              <div>
                <div className="referenceSearch-base">
                  <div className="referenceSearch-autor referenceSearch-line">
                    <div className="referenceAdd-author-title">
                      Reference ID
                    </div>
                    <div className="referenceSearch-author searchReference-reference">
                      <span className="search-line-pre">
                        <FormItem
                          label=""
                          help={this.state.helpref}
                          validateStatus={
                            this.state.errorref ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("ref_id_from", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.ref_id_from
                              : ""
                          })(
                            <InputNumber
                              min={1}
                              placeholder=""
                              onChange={value =>
                                this.sectionStartChange(value, "ref", "from")
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      <span className="search-line">
                        <span className="iconfont icon-line1"></span>
                      </span>
                      <span>
                        <FormItem
                          label=""
                          help={this.state.helpref}
                          validateStatus={
                            this.state.errorref ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("ref_id_to", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.ref_id_to
                              : ""
                          })(
                            <InputNumber
                              min={1}
                              onChange={value =>
                                this.sectionEndChange(value, "ref", "to")
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="referenceSearch-book">
                  <Form.Item label="Reference type">
                    {getFieldDecorator("ref_publication_type", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.ref_publication_type
                        : ""
                    })(
                      <Select>
                        {refTypes.map((item, index) => {
                          return (
                            <Option value={item} title={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="referenceSearch-book">
                  <Form.Item label="Title Includes">
                    {getFieldDecorator("ref_title_includevalues", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.ref_title_includevalues
                        : ""
                    })(<Input></Input>)}
                  </Form.Item>
                </div>

                <div className="referenceSearch-book">
                  <div>
                    <div className="referenceAdd-author-title">Author name</div>
                    <div className="referenceSearch-author">
                      <span>
                        <FormItem
                          label={
                            <div className="referenceSearch-book-label">
                              <p></p>
                            </div>
                          }
                        >
                          {getFieldDecorator("ref_authors_condition", {
                            initialValue: searchAllValues
                              ? searchAllValues.ref_title_includevalues
                              : "begins"
                          })(
                            <Select>
                              <Option value="begins">begins with</Option>
                              <Option value="includes">includes</Option>
                              <Option value="ends">ends with</Option>
                              <Option value="equals">equals</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>

                      <span>
                        <FormItem label="">
                          {getFieldDecorator("ref_authors_values", {
                            initialValue: searchAllValues
                              ? searchAllValues.ref_authors_values
                              : ""
                          })(<Input placeholder=""></Input>)}
                        </FormItem>
                      </span>

                      {/* <span>
                      {getFieldDecorator("ref_isfisrtAuthor", {
                        initialValue: searchAllValues
                          ? searchAllValues.ref_isfisrtAuthor
                          : ""
                      })(<Checkbox
                      //  checked={searchAllValues.ref_isfisrtAuthor}
                      >1st author</Checkbox>)}
                    </span> */}
                    </div>
                  </div>
                </div>

                <div className="referenceSearch-book">
                  <div>
                    <div className="referenceSearch-author">
                      <span>
                        <FormItem
                          label={
                            <div className="referenceSearch-book-label">
                              <p>Published year</p>
                            </div>
                          }
                        >
                          {getFieldDecorator("ref_publication_year_condition", {
                            initialValue: searchAllValues
                              ? searchAllValues.ref_publication_year_condition
                              : ""
                          })(
                            <Select>
                              <Option value="begins">begins with</Option>
                              <Option value="includes">includes</Option>
                              <Option value="ends">ends with</Option>
                              <Option value="equals">equals</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>

                      <span>
                        <FormItem label="">
                          {getFieldDecorator("ref_publication_year_values", {
                            initialValue: searchAllValues
                              ? searchAllValues.ref_publication_year_values
                              : ""
                          })(
                            <YearPicker
                              time={
                                searchAllValues
                                  ? searchAllValues.ref_publication_year_values
                                  : this.state.yearValue
                              }
                              onChange={value => this.publishYear(value)}
                            ></YearPicker>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="referenceSearch-book">
                  <Form.Item label={<p>Book/ Report includes</p>}>
                    {getFieldDecorator("ref_journal_booktitle_includevalues", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.ref_journal_booktitle_includevalues
                        : ""
                    })(<Input />)}
                  </Form.Item>
                </div>

                <div className="referenceSearch-base">
                  <div className="referenceSearch-autor searchReference-book referenceSearch-line">
                    <div className="referenceAdd-author-title">Input date</div>
                    <div className="referenceSearch-author">
                      <span className="search-line-pre">
                        <FormItem
                          label=""
                          help={this.state.helpcreatedAt}
                          validateStatus={
                            this.state.errorcreatedAt ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("createdAt_from", {
                            rules: [
                              { type: "object", message: "Please select time!" }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.createdAt_from
                              : ""
                          })(
                            <DatePicker
                              disabledDate={this.disabledStartDate}
                              format="YYYY-MM-DD"
                              onChange={this.onStartChange}
                            ></DatePicker>
                          )}
                        </FormItem>
                      </span>
                      <span className="search-line">
                        <span className="iconfont icon-line1"></span>
                      </span>
                      <span>
                        <FormItem
                          label=""
                          help={this.state.helpcreatedAt}
                          validateStatus={
                            this.state.errorcreatedAt ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("createdAt_to", {
                            rules: [
                              { type: "object", message: "Please select time!" }
                            ],

                            initialValue: searchAllValues
                              ? searchAllValues.createdAt_to
                              : ""
                          })(
                            <DatePicker
                              disabledDate={this.disabledEndDate}
                              format="YYYY-MM-DD"
                              onChange={this.onEndChange}
                            ></DatePicker>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label="Authorizer">
                      {getFieldDecorator("authorizer_id", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.authorizer_id
                          : ""
                      })(
                        <AutoComplete
                          onSearch={value =>
                            this.handleNomSearch(value, "authorizers")
                          }
                          onChange={value =>
                            this.handleNomChange(value, "authorizer_id")
                          }
                          placeholder=""
                        >
                          {authorizers}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label="Enterer">
                      {getFieldDecorator("enterer", {
                        rules: [],
                        initialValue: searchAllValues
                          ? searchAllValues.enterer
                          : ""
                      })(
                        <AutoComplete
                          onSearch={value =>
                            this.handleNomSearch(value, "enterers")
                          }
                          onChange={value =>
                            this.handleNomChange(value, "enterer")
                          }
                          placeholder=""
                        >
                          {enterers}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-authours referenceSearch-editors last-formItem">
                  <div>
                    <div className="referenceSearch-author">
                      <span>
                        <FormItem label="Sort by">
                          {getFieldDecorator("sort_names", {
                            rules: [{}],
                            initialValue: searchAllValues
                              ? searchAllValues.sort_names
                              : ""
                          })(
                            <Select placeholder="author">
                              <Option value="ref_authors">author</Option>
                              <Option value="ref_publication_year">year</Option>

                              <Option value="authorizer_id">authorizer</Option>
                              <Option value="enterer">enterer</Option>
                              <Option value="createdAt">entry date</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                      <span>
                        <FormItem>
                          {getFieldDecorator("sort_types", {
                            rules: [{}],
                            initialValue: searchAllValues
                              ? searchAllValues.sort_names
                              : ""
                          })(
                            <Select placeholder="ascending">
                              <Option value="1">ascending</Option>
                              <Option value="-1">descending</Option>
                            </Select>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

ReferenceOption = Form.create({})(ReferenceOption);
export default ReferenceOption;
