import React, { Component } from 'react';
import { Menu,Modal } from 'antd';
import { NavLink } from 'react-router-dom';
import './index.css'
import Storage from "src/js/storage";
import "src/js/global.js";
class Taxonomy extends Component {
  constructor(props) {

    super(props);
  
    this.state = {
      currentTitleKey: 'taxonomy',
      // currentTaxonKey:this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]||'addHigther'
      currentTaxonKey: props.location.pathname.split('/')[props.location.pathname.split('/').length - 1] || 'addHigther'
    };
  }
  isGbdbUser=()=>{
    let token = Storage.getItem("token");
   
    if(token==undefined){
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    }
  
  }
  componentDidMount = () => {
    // this.isGbdbUser()
    let url = this.props.location
    if (url) {
      let pathname = url.pathname
      let all = pathname.split('/')
      let keyValue = all[all.length - 1]
      this.setState({
        currentTaxonKey: keyValue
      }, () => {
      })

    }

  }
  componentWillReceiveProps(nextProps) {

    let preUrl = this.props.location
    let lateUrl = nextProps.location

    let prePathname = preUrl.pathname
    let latePathname = lateUrl.pathname
    let preAll = prePathname.split('/')
    let lateAll = latePathname.split('/')
    let preKeyValue = preAll[preAll.length - 1]
    let lateKeyValue = lateAll[lateAll.length - 1]
  
    if (preKeyValue !== lateKeyValue)
      this.setState({
        currentTaxonKey: lateKeyValue
      }, () => {
      })


  }
  titleKey = (e) => {

    this.setState({
      currentTitleKey: e.key,
    })
  }
  taxonKey = (e) => {

    this.setState({
      currentTaxonKey: e.key,
    })
  }
  render() {
    let {currentTaxonKey,currentTitleKey}=this.state
    return (
      <div id="taxonomy">
        <div className="form-contents taxonomy-main">
          <div className="reference-search-operate">
            <p>Add Data</p>
          </div>
          <div className="taxonomy-menu-header">
            <div id="referenceMenu" >
              <Menu
                mode="horizontal"
                onClick={(e) => this.titleKey(e)}
                className="reference-menu taxonomy-reference-menu"
                defaultSelectedKeys={currentTitleKey}
                selectedKeys={currentTitleKey}
              >
                <Menu.Item key="reference" >

                  <div>
                    <NavLink to='/references/search/reference'> Reference </NavLink>
                  </div>
                </Menu.Item>
                <Menu.Item key="section">
                  <div>
                    <NavLink to='/references/search/section'> Section </NavLink>
                  </div>

                </Menu.Item>

                <Menu.Item key="collection">
                  <div>
                    <NavLink to='/references/search/collection'> Collection </NavLink>
                  </div>

                </Menu.Item>

                <Menu.Item key="taxonomy">
                  <div>
                    <NavLink to='/taxonomy/addTaxon'> Taxonomy </NavLink>
                  </div>

                </Menu.Item>
                <Menu.Item key="oceanic">
                  <div>
                    <NavLink to='/oceanic/add/huici'>  Oceanic data </NavLink>
                  </div>

                </Menu.Item>
              </Menu>
            </div>


            <div id="taxonomyMenu">
              <div className="menu-line"></div>
              <Menu
                mode="horizontal"
                className="taxonomy-menu"
                onClick={(e) => this.taxonKey(e)}
                defaultSelectedKeys={currentTaxonKey}
                 selectedKeys={currentTaxonKey}
              >
                <Menu.Item key="addTaxon">

                  <div>
                    <NavLink to='/taxonomy/addTaxon'> Add taxon </NavLink>
                  </div>
                </Menu.Item>
                {/* <Menu.Item key="addOriginal">
                  <div>
                    <NavLink to='/taxonomy/addOriginal'> Add original sp.name </NavLink>
                  </div>

                </Menu.Item>



                <Menu.Item key="addIncomplete">
                  <div>
                    <NavLink to='/taxonomy/addIncomplete'>  Add  sp.name incomplete </NavLink>
                  </div>

                </Menu.Item> */}
                <Menu.Item key="addRevised">
                  <div>
                    <NavLink to='/taxonomy/addRevised'> Add revised sp.name </NavLink>
                  </div>

                </Menu.Item>
                <Menu.Item key="addRelationship">
                  <div>
                    <NavLink to='/taxonomy/addRelationship'>  Add relationship </NavLink>
                  </div>

                </Menu.Item>
              </Menu>
            </div>
          </div>

          <div className="taxonomy-parts form-mian-content">
            {this.props.children}
          </div>
        </div>

      </div>
    );
  }
}

export default Taxonomy;
