import React from "react";

import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Modal,
  InputNumber
} from "antd";

import moment from "moment";
import centerApis from "src/js/apis/center";
import Apis from "src/js/apis/api";
import "./index.css";
import Storage from "src/js/storage";
import { withRouter } from "react-router-dom";
import Utils from "src/js/utils";
// 修改查找referenceid
import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
@withRouter
class BaseDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      // reference , taxonomy , section,occurence,formation
      detailData: props.detailData || {},
      sectionData: {},
      seeOnly: props.seeOnly === true ? true : false,

      // 修改
      refCondition: false,
      refInfo: {},
      allChildError: {},
      isCenterUser: props.isCenterUser === true ? true : false,
      oldAccessibility: "",
      seeGeographyFossil: props.seeGeographyFossil === true ? true : false,
    };
  }
  async componentDidMount() {
    let { detailData } = this.state;

    let sectionId = "";
    let sectionResult;
    if (detailData && detailData.section_id !== undefined) {
      sectionId = detailData.section_id;
    }
    if (detailData && detailData.section_basic_id !== undefined) {
      sectionId = detailData.section_basic_id;
    }
    let { isCenterUser } = this.state;

    //  let isCenter = this.props.location.pathname === "/center";
    if (!isCenterUser) {
      let params = {
        section_id: sectionId
      };

      sectionResult = await Apis.sectionBase(params);
    } else {
      let params = {
        assetstype: 2,
        enterer: Storage.getItem("gbdbid"),
        section_id: sectionId
      };
      sectionResult = await centerApis.searchAssets(params);
    }

    if (sectionResult.result && sectionResult.result.length > 0) {
      this.setState(
        {
          sectionData: sectionResult.result[0] || {},
          oldAccessibility: sectionResult.result[0].accessibility
        },
        async () => {
          if (this.state.sectionData.ref_id !== undefined) {
            let refId = this.state.sectionData.ref_id;
            let params = {
              ref_id: refId
            };
            let refInfo = await Apis.searchReference(params);
            if (refInfo && refInfo.result && refInfo.result.length > 0) {
              refInfo = refInfo.result[0];
            } else {
              refInfo = {};
            }
            this.setState({
              refInfo
            });
          }
        }
      );
    } else {
      if (sectionResult.error) {
        Modal.error({
          content: sectionResult.error
        });
      }
    }
  }
  viewGeography = () => {
    let { sectionData } = this.state;
    let sectionId = sectionData.section_id;
    this.props.viewGeography(sectionId, "geology");
  };
  modifyBase = () => {
    let { refInfo, oldAccessibility } = this.state;

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        // let addBaseInfo = Utils.filterParams(values);
        let addBaseInfo = Utils.filterCondition(values);
        addBaseInfo = Utils.addEnterer(addBaseInfo);
        addBaseInfo = Utils.addAudit(addBaseInfo, refInfo);
        let { sectionData } = this.state;
        let sectionId = sectionData.section_id;
        addBaseInfo.id = sectionId;
        addBaseInfo.authState = 0;
        if (Number(oldAccessibility) !== Number(addBaseInfo.accessibility)) {
          addBaseInfo.changeAccessibility = addBaseInfo.accessibility;
        }
        // 服务器注释
        let addSectionBase = await Apis.changeBasicById(addBaseInfo);
        if (addSectionBase.error) {
          Modal.error({
            title: "This is an error message",
            content: addSectionBase.error
          });
        } else if (addSectionBase.result) {
          // Modal.success({
          //   title: "This is a success message",
          //   content: 'modify success'
          // });
          if (this.props.modifyBase) {
            this.props.modifyBase(addSectionBase.result);
          }
        }
      }
    });
  };

  // 修改相关
  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };
  clickRefId = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };
  onRef = ref => {
    this.child = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showDetailForm: true
    });
  };
  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            refSearch: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues } = this.state;

              this.child.searchReferenceDatas(searchParams, searchValues);
            }, 20);
          }
        );
      }
    });
  };
  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };
  detailFormOk = () => {
    this.setState({
      showDetailForm: false
    });
  };
  detailFormCancel = () => {
    this.setState({
      showDetailForm: false
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  viewLithostratigraphy=()=>{
    let { sectionData } = this.state;

    const { section_id, ref_id } = sectionData;

    let url = "/references/allresult/section?";

    let refId = ref_id ? "refId=" + ref_id : "";

    let baseIdUrl = section_id ? "&baseId=" + section_id : "";

    let params = `${url}${refId}${baseIdUrl}`;
    const w = window.open("about:blank");
    w.location.href = params;
    // this.props.history.push(params);
  }
  viewGeochemical = () => {
    let { sectionData } = this.state;
    // let sectionId = detailData.section_basic_id;
    // let url = "/searchs/result/section/geochemical";
    // let searchParams = {
    //   section_basic_id: sectionId
    // };
    // let searchAllValues = {};

    // this.props.history.push({
    //   pathname: url,
    //   query: { searchParams, searchAllValues }
    // });

    let baseId = sectionData.section_id;
    let url = "/searchs/result/section/geochemical?";
    let baseUrl = baseId ? "baseId=" + baseId : "";
    let params = `${url}${baseUrl}`;
    const w = window.open("about:blank");
    w.location.href = params;
  };

  tsCreator=async () => {
    //方法二
    let { sectionData } = this.state;
    let sectionId = sectionData.section_id;
    //console.log("sectionID:"+sectionId);
     let params = {sectionID:sectionId};
    if(params){
      let downBack = await Apis.tsCreator(params);
      //console.log("result:"+downBack.result);
      if (downBack.error) {
        Modal.error({
          title: "This is an error message",
          content: downBack.error
        });
      }else if (downBack.result) {
        //this.openFile(downBack.result);

        if(downBack.result==""){
          alert("Insufficient data to generate file!");
        }else{
          let file=downBack.result;
          let idx = file.indexOf(".");
  
          let file_new = file.substring(idx + 1, file.length);
      
          let href = "/tsc" + file_new;
          window.open(href);
        }
      }
    }
  };

  // openFile = file => {

  //   let idx = file.indexOf(".");

  //   let file_new = file.substring(idx + 1, file.length);

  //   let href = "/api" + file_new;

  //   console.log("href:"+href);
  //   window.open(href);

  // };


  render() {
    let { getFieldDecorator } = this.props.form;
    let { sectionData, seeOnly ,seeGeographyFossil} = this.state;
    let { enterer } = sectionData;
    let userid = Storage.getItem("gbdbid");
    let isClick = false;
    let isCenter = this.props.location.pathname === "/center";
    if (enterer && userid && enterer === userid && isCenter) {
      isClick = true;
    }
    if (isClick === false) {
      getFieldDecorator = (...rest) => {
        return element => {
          let NewElement = React.cloneElement(element, {
            disabled: true
          });
          return this.props.form.getFieldDecorator(...rest)(NewElement);
        };
      };
    }

    return (
      <div id="detail_form">
        <div className="">
          <div className="detailform-title">
            <span className="detailform-name">Section Base</span>
          </div>
          <div className="sectionBase-form" id="main">
            <Form>
              <div className="referenceAdd-title">
                <Form.Item label="Section ID">
                  {getFieldDecorator("section_id", {
                    initialValue: sectionData.section_id || ""
                  })(<Input disabled />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Section name">
                  {getFieldDecorator("section_name", {
                    initialValue: sectionData.section_name || ""
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-comments">
                <Form.Item label="Notes" className="long-label">
                  {getFieldDecorator("section_notes", {
                    rules: [{}],
                    initialValue: sectionData.section_notes || ""
                  })(<TextArea rows={6}></TextArea>)}
                </Form.Item>
              </div>

              <div className="referenceAdd-authours">
                <div>
                  {
                    <div className="referenceAdd-author ">
                      <FormItem label="Reference ID" className="long-label">
                        {getFieldDecorator(`ref_id`, {
                          rules: [
                            {
                              type: "number",

                              required: true,
                              message: "Please input the Reference"
                            }
                          ],

                          initialValue: Number(sectionData.ref_id) || ""
                        })(
                          <InputNumber
                            disabled
                            placeholder=""
                            onFocus={this.showSearchRef}
                          ></InputNumber>
                        )}
                      </FormItem>
                    </div>
                  }

                  {/* <div className="referenceAdd-author-btn">
                      <Button type="gbdb reference-btn-before"
                        onClick={(e) => this.addReferenceId(e)}
                      >Add</Button>
                      <Button type="gbdb"
                        onClick={(e) => this.deleteReferenceId(e)}
                      >Delete</Button>
                    </div> */}
                </div>
              </div>

              <div className="referenceAdd-book">
                <Form.Item label="Project">
                  {getFieldDecorator("section_project", {
                    rules: [{}],
                    initialValue: sectionData.section_project || 'Default'
                  })(
                    <Select placeholder="">
                      <Option value="Null">Null</Option>
                      <Option value="Default">Default</Option>
                      <Option value="BGS digitization">BGS digitization</Option>
                      <Option value="ocean_data">Ocean Data</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              {/* <div className="referenceAdd-journal">
                <Form.Item label="Research group" >
                  {getFieldDecorator("group_id", {
                    rules: [
                      {
                        validator: (rule, value, cb) => {
                          !isNaN(value) ? cb() : cb(true);
                        },

                        type: "number",
                        message: "Please input the Group ID(Number)"
                      }
                    ],
                    initialValue: sectionData.group_id || ""
                  })(<Input></Input>)}
                </Form.Item>
              </div> */}

              <div className="referenceAdd-type">
                <Form.Item label="Accessibility">
                  {getFieldDecorator("accessibility", {
                    rules: [
                      {
                        required: true,
                        message: "Please select the Accessibility"
                      }
                    ],
                    initialValue:
                      sectionData && sectionData.accessibility !== undefined
                        ? sectionData.accessibility.toString()
                        : "1"
                  })(
                    <Select placeholder="">
                      <Option value="0">open</Option>
                      <Option value="1">privacy</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-publication">
                <Form.Item label="Release date">
                  {getFieldDecorator("release_date", {
                    rules: [
                      {
                        required: true,
                        message: "Please select the release date"
                      }
                    ],

                    initialValue: sectionData.release_date
                      ? moment(sectionData.release_date)
                      : null
                  })(
                    <DatePicker
                    allowClear={false}
                      format={"YYYY-MM-DD"}
                      disabledDate={Utils.disabledDate}
                    ></DatePicker>
                  )}
                </Form.Item>
              </div>
              {sectionData.auth_time !== null ? (
                <div className="referenceAdd-authours referenceAdd-editors">
                  <div className="authcomment-details">
                    {sectionData.authState === 2 ? (
                      <div className="referenceAdd-author authcomment-detail-item">
                        <div className="referenceAdd-author-title authcomment-title">
                          Auth Comment
                        </div>
                        <div className="authcomment-content">
                          {sectionData.auth_comment !== ""
                            ? sectionData.auth_comment
                            : "No comment"}
                        </div>
                      </div>
                    ) : null}
                    {sectionData.authState !== 0 ? (
                      <div className="referenceAdd-author authcomment-detail-item">
                        <div className="referenceAdd-author-title authcomment-title">
                          Auth Time
                        </div>
                        <div className="authcomment-content">
                          {sectionData.auth_time
                            ? sectionData.auth_time.split("T")[0]
                            : ""}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Form>
          </div>
        </div>
        <div className="referenceSearch-btns sectionBase-btns">
        {seeGeographyFossil === false ? null : (
            <div className="source-inner-btns">
              <Button type="gbdb" onClick={() => this.viewLithostratigraphy()}>
                <div>
                  <p>Lithostratigraphy</p>
                  <p>& Fossils</p>
                </div>
              </Button>

              <Button type="gbdb" onClick={() => this.viewGeochemical()}>
                <div>
                  <p>Geochemical</p>
                  <p> Data</p>
                </div>
              </Button>
              {/* <Button type="gbdb" onClick={() => this.viewCollection()}>
                <div>
                  <p>Collection</p>
                  <p>Data</p>
                  
                </div>
              </Button> */}
            </div>
          )}

          <div className={`${
                seeGeographyFossil === true
                  ? "source-inner-btns"
                  : "formation-complete-btns"
              }`}>
            <Button type="gbdb" onClick={() => this.viewGeography()}>
              <p>View Geography</p>
            </Button>
            <Button type="gbdb" onClick={() => this.tsCreator()}>
              <p>TS Creator</p>
            </Button>
            {isClick && seeOnly === false ? (
              <Button type="gbdb" onClick={() => this.modifyBase()}>
                <p>Save modify</p>
              </Button>
            ) : null}
          </div>
        </div>
        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          <ReferenceOption
            childError={this.childError}
            ref="reference"
          ></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}

          <ReferenceTableResult
            searchParams={this.state.searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailFormOk}
          onCancel={this.detailFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
            // searchSection={this.searchSection}
            onlySeeRef={true}
          ></ReferenceDetailForm>
        </Modal>
      </div>
    );
  }
}
BaseDetailForm = Form.create({})(BaseDetailForm);
export default BaseDetailForm;
