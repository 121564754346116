import React from 'react'
import {Modal,Form,Button,Input,message} from 'antd'
import axios from 'axios'
import moment from 'moment'

export default class CollectionModal extends React.Component{
    constructor(props){
        super(props)
        this.state={
        }
    }
    handleReject(){
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                var sendData={
                    id:this.props.modalData.unit_id,
                    assetstype:5,
                    authState:2,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                    auth_comment:str
                }
                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已驳回！')
                        this.props.cancelShowInfo();
                        this.props.refreshList()
                    }
                })
               
            },
          
        })
    }
    handlePass(){
        var sendData={
            id:this.props.modalData.unit_id,
            assetstype:5,
            authState:1,
            auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
        }
        axios.post('/api/admin/review/assets',sendData).then(res=>{
            if(res.data.error===null){
                message.success('通过审核!')
                this.props.cancelShowInfo();
                this.props.refreshList()
            }else{
                message.error(res.data.error)
            }
        }).catch(err=>{
            message.error(err)
        })
    }
   
    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 5 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 19 },
            },
          };
          const {modalData}=this.props
        return (
            <div>
                <Modal 
                className='AModal geoInfo'
                visible={this.props.visible}
                onCancel={this.props.cancelShowInfo}
                footer={null}
                >
                    <div className='form'>
                    <div className='tip1'>Unit</div>
                    <Form {...formItemLayout}>

                        <Form.Item label='Reference ID'>
                            <span>{modalData.ref_id}</span>
                        </Form.Item>

                        <Form.Item label='Formation ID'>
                            <span>{modalData.formation_id}</span>
                        </Form.Item>

                        <Form.Item label='Unit ID'>
                            <span>{modalData.unit_id}</span>
                        </Form.Item>

                        <Form.Item label='Early Interval'>
                            <span>{modalData.early_interval}</span>
                        </Form.Item>

                        <Form.Item label='Intage Max'>
                            <span>{modalData.intage_max}</span>
                        </Form.Item>

                        <Form.Item label='Epoch Max'>
                            <span>{modalData.epoch_max}</span>
                        </Form.Item>

                        <Form.Item label='Empleriod Max'>
                            <span>{modalData.emlperiod_max}</span>
                        </Form.Item>
                        
                        <Form.Item label='Period Max'>
                            <span>{modalData.period_max}</span>
                        </Form.Item>

                        <div className='line'>
                            <Form.Item className='lineItem' label='Early age'>
                                <span>{modalData.early_age}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Late age'>
                                <span>{modalData.late_age}</span>
                            </Form.Item>
                        </div>

                        <div className='table'>
                            <table>
                                <tbody>
                                <tr>
                                    <td>Color1</td>
                                    <td>Color2</td>
                                    <td>Lithology1</td>
                                    <td>Lithology2</td>
                                    <td>Relation</td>
                                </tr>
                                <tr>
                                    <td>{modalData.unit_color1a}</td>
                                    <td>{modalData.unit_color2a}</td>
                                    <td>{modalData.unit_lithology1a}</td>
                                    <td>{modalData.unit_lithology2a}</td>
                                    <td>{modalData.unit_relationa}</td>
                                </tr>
                                <tr>
                                    <td>{modalData.unit_color1b}</td>
                                    <td>{modalData.unit_color2b}</td>
                                    <td>{modalData.unit_lithology1b}</td>
                                    <td>{modalData.unit_lithology2b}</td>
                                    <td>{modalData.unit_relationb}</td>
                                </tr>
                                <tr>
                                    <td>{modalData.unit_color1c}</td>
                                    <td>{modalData.unit_color2c}</td>
                                    <td>{modalData.unit_lithology1c}</td>
                                    <td>{modalData.unit_lithology2c}</td>
                                    <td>{modalData.unit_relationc}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <Form.Item label='Thickness'>
                            <span>{modalData.unit_thickness?(modalData.unit_thickness_sign+modalData.unit_thickness+modalData.unit_thickness_unit):0}</span>
                        </Form.Item>

                        <Form.Item label='Altitude'>
                            <span>{`${modalData.unit_sum} ${modalData.unit_thickness_unit}`}</span>
                        </Form.Item>

                        <Form.Item label='Contact base'>
                            <span>{modalData.unit_con_base}</span>
                        </Form.Item>

                        <Form.Item label='Sediment'>
                            <span>{modalData.unit_sediment}</span>
                        </Form.Item>

                        <Form.Item label='Facies'>
                            <span>{modalData.unit_facies}</span>
                        </Form.Item>

                        <Form.Item label='Paleoenvironment'>
                            <span>{modalData.unit_paleoenvironment}</span>
                        </Form.Item>

                        </Form>
                        <div className='foot'>
                        <Button  type='agbdb1' onClick={this.handlePass.bind(this)}>通过</Button>
                        <Button  type='agbdb2' onClick={this.handleReject.bind(this)}>拒绝</Button>
                        </div>
                    </div>
                   
                    
                </Modal>
            </div>
        )
      
    }
}