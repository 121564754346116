import React, { Component } from "react";

import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";
import "./index.css";
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Modal,
  InputNumber
} from "antd";
import Storage from "src/js/storage";
import moment from "moment";
import groupApis from "src/js/apis/group";
import Utils from "src/js/utils";
import Apis from "src/js/apis/api";
import "src/js/global.js";
import Users from "src/js/users";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

class SectionBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refSearch: false,
      refCondition: false,
      isGroup: false,
      groupList: [],
      refInfo: {},
      resultLists: [],
      detailData: {},
      allChildError: {}
    };
  }
  isGbdbUser = async () => {
    let Inputerid=false
    let token = Storage.getItem("token");
     Inputerid = await Users.isGbdbInputer();
  
  

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    } else {
      if (Inputerid == false) {
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title: "Sorry!!",
            content: "You do not have permission to add data"
          });
          setTimeout(()=>{
            this.props.history.push("/home");
          },1000)
        }
      }
    }
  };
  async componentDidMount() {
    this.isGbdbUser();
    if (this.props.location.search) {
      let inheritRefIds =
        Utils.getSearch(this.props.location.search, "refId") || "";
      this.setState(
        {
          inheritRefIds
        },
        async () => {
          let { inheritRefIds } = this.state;
          let params = {
            ref_id: inheritRefIds
          };
          let refInfo = await Apis.searchReference(params);
          if (refInfo.result) {
            this.setState(
              {
                refInfo: refInfo.result[0]
              },
              async () => {
                await this.addAccessibility();
              }
            );
          } else {
            this.setState({
              refInfo: {}
            });
          }
        }
      );
    } else {
    }
  }
  addAccessibility = () => {
    let { refInfo } = this.state;
    if (refInfo && refInfo.accessibility !== "") {
      this.props.form.setFieldsValue({
        accessibility: refInfo.accessibility.toString()
      });
    }
  };

  searchSection = () => {
    this.setState(
      {
        showDetailForm: false
      },
      () => {
        let { detailData } = this.state;
        let queryParam = {
          refId: detailData.id
        };
        let url = "/searchs/result/section/base";
        this.props.history.push({ pathname: url, query: queryParam });
      }
    );
  };
  // 添加审核者

  baseGoGeoloGy = () => {
    let { refInfo } = this.state;
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let addBaseInfo = Utils.filterParams(values);
        addBaseInfo = Utils.filterCondition(addBaseInfo);
        addBaseInfo = Utils.addEnterer(addBaseInfo);
        addBaseInfo = Utils.addAudit(addBaseInfo, refInfo);
        let inheritRefIds = addBaseInfo.ref_id;

        // 服务器注释
        let addSectionBase = await Apis.createSectionBasic(addBaseInfo);
        if (addSectionBase.error) {
          Modal.error({
            title: "This is an error message",
            content: addSectionBase.error
          });
        } else if (addSectionBase.result) {
          let baseId = addSectionBase.result.section_id;
          let baseurl = "/section/geography?";
          let refIdUrl = inheritRefIds ? "refId=" + inheritRefIds : "";
          let baseidUrl = baseId ? "&baseId=" + baseId : "";
          let url = `${baseurl}${refIdUrl}${baseidUrl}`;

          //         let queryParam = { searchParams: searchResult,type: this.state.currentKey}
          // let url = '/reference/result/' + this.state.currentKey
          // this.props.history.push({ pathname: url, query: queryParam })

          this.props.history.push(url);
        }
      }
    });
  };

  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };

  onRef = ref => {
    this.child = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showDetailForm: true
    });
  };
  changeAccess = async value => {
    if (value === "2") {
      let uid = Storage.getItem("gbdbid");
      let params = {
        uid
      };
      let myGroups = await groupApis.getGroups(params);
      if (myGroups.error) {
        const modal = Modal.error({
          title: "This is an error message",
          content: myGroups.error
        });
        setTimeout(() => {
          modal.destroy();
        }, 3000);
      } else {
        if (myGroups.result.length === 0) {
          this.props.form.setFieldsValue({
            accessibility: "1"
          });
        }
        this.setState({
          groupList: myGroups.result,
          isGroup: true
        });
      }
    } else {
      this.setState({
        isGroup: false,
        groupList: []
      });
    }
  };

  childError = allChildError => {
    this.setState({
      allChildError
    });
  };

  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);
        
        this.setState(
          {
            refSearch: true,
            //refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues } = this.state;

              this.child.searchReferenceDatas(searchParams, searchValues);
            }, 1000);
          }
        );
      }
    });
  };
  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
    Modal.destroyAll();
  };
  detailFormOk = () => {
    this.setState({
      showDetailForm: false
    });
  };
  detailFormCancel = () => {
    this.setState({
      showDetailForm: false
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let { isGroup, groupList, inheritRefIds, refInfo } = this.state;
    let { searchParams } = this.state;
    return (
      <div id="sectionBase" className="section-common">
        <div className="sectionBase-mian">
          <div className="section-common-title form-common-title">
            Add Basic Info
          </div>
          <div className="sectionBase-form">
            <Form>
              <div className="referenceAdd-title">
                <Form.Item label="Section name">
                  {getFieldDecorator("section_name", {
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-comments">
                <Form.Item label="Notes" className="long-label">
                  {getFieldDecorator("section_notes", {
                    rules: [{}],
                    initialValue: ""
                  })(<TextArea rows={6}></TextArea>)}
                </Form.Item>
              </div>

              <div className="referenceAdd-authours">
                {
                  // <div className="referenceAdd-author ">
                  <div className="referenceAdd-author">
                    <FormItem label="Reference ID" className="">
                      {getFieldDecorator("ref_id", {
                        rules: [
                          {
                            type: "number",
                            required: true,
                            message: "Incorrect Reference"
                          }
                        ],

                        initialValue: inheritRefIds ? Number(inheritRefIds) : ""
                      })(
                        <InputNumber
                          placeholder=""
                          onFocus={this.showSearchRef}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </div>
                }

                {/* <div className="referenceAdd-author-btn">
                      <Button type="gbdb reference-btn-before"
                        onClick={(e) => this.addReferenceId(e)}
                      >Add</Button>
                      <Button type="gbdb"
                        onClick={(e) => this.deleteReferenceId(e)}
                      >Delete</Button>
                    </div> */}
              </div>

              <div className="referenceAdd-book">
                <Form.Item label="Project">
                  {getFieldDecorator("section_project", {
                    rules: [{}],
                    initialValue: "Default"
                  })(
                    <Select placeholder="Null">
                      <Option value="Null">Null</Option>
                      <Option value="Default">Default</Option>
                      <Option value="BGS digitization">BGS digitization</Option>
                      <Option value="ocean_data">Ocean Data</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>

              {/* <div className="referenceAdd-journal">
                <Form.Item label="Research group" >
                  {getFieldDecorator("group_id", {
                    rules: [
                      {
                        validator: (rule, value, cb) => {
                          !isNaN(value) ? cb() : cb(true);
                        },

                        type: "number",
                        message: "Please input the Group ID(Number)"
                      }
                    ],
                    initialValue: ""
                  })(<Input></Input>)}
                </Form.Item>
              </div> */}

              <div className="referenceAdd-type">
                <Form.Item label="Accessibility">
                  {getFieldDecorator("accessibility", {
                    rules: [
                      {
                        required: true,
                        message: "Please select the Accessibility"
                      }
                    ],
                    initialValue: "1"
                  })(
                    <Select onChange={this.changeAccess}>
                      <Option value="0">Open</Option>
                      <Option value="1">Private</Option>
                      {/* <Option value="2">
                        <span>Group</span>
                      </Option> */}
                    </Select>
                  )}
                </Form.Item>
              </div>
              {isGroup && groupList.length > 0 ? (
                <div className="referenceAdd-Accessibility">
                  <Form.Item label="Group">
                    {getFieldDecorator("group_id", {
                      rules: [
                        // {
                        //   required: true,
                        //   message: "Please select the Group ID"
                        // }
                      ]
                    })(
                      <Select mode="multiple">
                        {groupList.map((item, index) => {
                          return <Option value={item.id}>{item.name}</Option>;
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              ) : isGroup && groupList.length <= 0 ? (
                <div className="no-group">
                  <span>
                    No group was found,You cannot select the 'group' option
                  </span>
                </div>
              ) : null}
              <div className="referenceAdd-publication last-formItem">
                <Form.Item label="Release date">
                  {getFieldDecorator("release_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "Please select the release date"
                      }
                    ],
                    initialValue: moment()
                    // refInfo&&JSON.stringify(refInfo) !== "{}"&&refInfo.release_date
                    //     ? moment(refInfo.release_date, "YYYY-MM-DD")
                    //     : moment()
                  })(
                    <DatePicker 
                    format={"YYYY-MM-DD"}
                    allowClear={false}
                    disabledDate={Utils.disabledDate}></DatePicker>
                  )}
                </Form.Item>
              </div>
              <div className="referenceSearch-btns sectionBase-btns">
                <div>
                  <Button type="gbdb" onClick={() => this.baseGoGeoloGy()}>
                    <div>
                      <p> Save and</p>
                      <p> Continue</p>
                    </div>
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
        {/* <div>
          <div className="section-tip">
            <div>Tips:</div>
            <div>
              Must add Geography,Otherwise the base info will be deleted
            </div>
          </div>
        </div> */}
        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          <ReferenceOption
            childError={this.childError}
            ref="reference"
          ></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          <ReferenceTableResult
            searchParams={searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailFormOk}
          onCancel={this.detailFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
            searchSection={this.searchSection}
          ></ReferenceDetailForm>
        </Modal>
      </div>
    );
  }
}

SectionBase = Form.create({})(SectionBase);
export default SectionBase;
