import React, { Component } from "react";

import "src/js/global.js";

import Utils from "src/js/utils";
import dataApis from "src/js/apis/data";
import Apis from "src/js/apis/api";

import "./index.css";
import { withRouter } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Modal,
  DatePicker,
  InputNumber,
  AutoComplete
} from "antd";
const { Option } = Select;
const FormItem = Form.Item;
const { TextArea } = Input;

@withRouter
class TaxonomyOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizers: [],
      enterers: [],
      errorRef: false,
      searchAllValues: props.searchAllValues || {},
      nomenclators: [],

      allChildError: {},
      taxonTypes: [],
      treatises: [],
      fossilgroup: {},
      name1Opts: [],
      name2Opts: [],

      taxonfrom: props.searchAllValues ? props.searchAllValues.id_from : "",
      taxonto: props.searchAllValues ? props.searchAllValues.id_to : "",
      createdAtfrom: props.searchAllValues
        ? props.searchAllValues.createdAt_from
        : null,
      createdAtto: props.searchAllValues
        ? props.searchAllValues.createdAt_to
        : null
    };
  }
  componentDidMount = async () => {
    if (this.props.searchAllValues) {
      this.setState({
        searchAllValues: this.props.searchAllValues
      });
    }
    let fossilgroup = await dataApis.fossilgroup();
    let taxonTypes = await dataApis.getTaxontypes();
    let treatises = await dataApis.getTreatise();
    this.setState(
      {
        fossilgroup:
          fossilgroup && fossilgroup.result ? fossilgroup.result : {},
        taxonTypes: taxonTypes && taxonTypes.result ? taxonTypes.result : [],
        treatises: treatises && treatises.result ? treatises.result : []
      },
      () => {}
    );
  };
  componentWillReceiveProps(nextProps) {
    this.setState({
      searchAllValues: nextProps.searchAllValues
        ? nextProps.searchAllValues
        : {}
    });
  }
  publishYear = value => {};

  handleNomSearch = async (value, type) => {
    if (value) {
      let searchResult;
      if (type === "authorizers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteExperts(params);
      } else if (type === "enterers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteEnterer(params);
      } else if (type === "durations") {
        let params = {
          name: value
        };
        searchResult = await dataApis.getChronostratigraphics(params);
      }

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });

        return;
      } else if (searchResult.result) {
        this.setState(
          {
            [type]: searchResult.result
          },
          () => {}
        );
      }
    } else {
      this.setState({ [type]: [] });
    }
  };
  handleNomChange = (value, type) => {
    this.props.form.setFieldsValue({
      [type]: value
    });
  };
  handleName1Change = value => {
    this.props.form.setFieldsValue({
      taxName1: value
    });
  };
  handleName1Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name1"],
        taxon_name1: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name1Opts: [] });
        } else {
          this.setState(
            {
              name1Opts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ name1Opts: [] });
    }
  };

  handleName2Change = value => {
    this.props.form.setFieldsValue({
      taxName2: value
    });
  };
  handleName2Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name2"],
        taxon_name2: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name2Opts: [] });
        } else {
          this.setState(
            {
              name2Opts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ name2Opts: [] });
    }
  };
  onStartChange = value => {
    this.onTimeChange("startValue", value);
    this.sectionStartChange(value, "createdAt", "from");
  };
  onEndChange = value => {
    this.onTimeChange("endValue", value);
    this.sectionStartChange(value, "createdAt", "to");
  };
  onTimeChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  sectionStartChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  sectionEndChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  onSectionChange=()=>{}
  onSectionChange1 = (value, field, type) => {
    let stateType = field + type;
    let { allChildError } = this.state;
    this.setState(
      {
        [stateType]: value
      },
      () => {
        let compareFrom = field + "from";
        let compareTo = field + "to";

        let compareOne = this.state[compareFrom];
        let compareTwo = this.state[compareTo];

        let errorType = "error" + field;
        let helpType = "help" + field;
        if (
          (Utils.isEmpty(compareOne) && !Utils.isEmpty(compareTwo)) ||
          (Utils.isEmpty(compareTwo) && !Utils.isEmpty(compareOne))
        ) {
          this.setState(
            {
              [errorType]: true,
              [helpType]: "Start and end data need to be filled in"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
        } else if (compareTwo && compareOne && compareTwo < compareOne) {
          this.setState(
            {
              [errorType]: true,
              [helpType]: "The starting value is less than the ending value"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
        } else {
          this.setState(
            {
              [errorType]: false,
              [helpType]: ""
            },
            () => {
              if (allChildError[errorType]) {
                delete allChildError[errorType];
              }
            }
          );
        }
      }
    );
  };

  handleNomSearch = async (value, type) => {
    if (value) {
      let searchResult;
      if (type === "nomenclators") {
        let params = {
          attributes: ["nomenclator"],
          nomenclator: value
        };
        searchResult = await Apis.AutoCompleteTaxon(params);
      } else if (type === "authorizers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteExperts(params);
      } else if (type === "enterers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteEnterer(params);
      }

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });

        return;
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ [type]: [] });
        } else {
          this.setState(
            {
              [type]: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ [type]: [] });
    }
  };

  handleNomChange = (value, type) => {
    this.props.form.setFieldsValue({
      [type]: value
    });
  };
  render() {
    let { searchAllValues, treatises } = this.state;
    const { getFieldDecorator } = this.props.form;

    let { fossilgroup, taxonTypes } = this.state;
    const nomenclators = this.state.nomenclators.map((item, index) => (
      <Option key={item.nomenclator}>{item.nomenclator}</Option>
    ));
    const authorizers = this.state.authorizers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    const enterers = this.state.enterers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    const name1Opts = this.state.name1Opts.map((item, indx) => (
      <Option key={item.taxon_name1}>{item.taxon_name1}</Option>
    ));
    const name2Opts = this.state.name2Opts.map((item, indx) => (
      <Option key={item.taxon_name2}>{item.taxon_name2}</Option>
    ));

    return (
      <div id="main" className="reference-search-form commonsection-search">
        <div className="searchSection-main  reference-search-form search-common-form">
          <div className="reference-search-content reference-search-baseform searchSection-form">
            <Form>
              <div>
                <div className="referenceSearch-base">
                  <div className="referenceSearch-autor referenceSearch-line">
                    <div className="referenceAdd-author-title">Taxonomy ID</div>
                    <div className="referenceSearch-author searchReference-reference">
                      <span className="search-line-pre">
                        <FormItem
                          label=""
                          help={this.state.helptaxon}
                          validateStatus={
                            this.state.errortaxon ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("id_from", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.id_from
                              : ""
                          })(
                            <InputNumber
                              min={1}
                              onChange={value =>
                                this.sectionStartChange(value, "taxon", "from")
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      <span className="search-line">
                        <span className="iconfont icon-line1"></span>
                      </span>
                      <span>
                        <FormItem
                          label=""
                          help={this.state.helptaxon}
                          validateStatus={
                            this.state.errortaxon ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("id_to", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.id_to
                              : ""
                          })(
                            <InputNumber
                              min={1}
                              onChange={value =>
                                this.sectionEndChange(value, "taxon", "to")
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="referenceSearch-book">
                  <Form.Item label="Fossil group">
                    {getFieldDecorator("fossil_group", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.fossil_group
                        : ""
                    })(<Select>{Utils.fossilGroup(fossilgroup)}</Select>)}
                  </Form.Item>
                </div>

                <div className="referenceSearch-book searchTaxonomy-fossil">
                  <div className="form-group-title sectionGeography-tutude-title">
                    <span>Genus or Higher</span>
                    <span>Species</span>
                  </div>
                  <div className="referenceSearch-base referenceSearch-middles">
                    <div className="referenceSearch-autor referenceSearch-middle-autor">
                  

                      <div className="referenceSearch-author searchReference-reference">
                      <span className="sectionGeography-map-label searchOccurence-fossil searchOccurence-fossil-name">
                        Name
                      </span>
                        <span>
                          <FormItem
                            label={
                              <p className="h5-map-title">Genus or Higher：</p>
                            }
                            className="h5-genus"
                          >
                            {getFieldDecorator("taxon_name1", {
                              initialValue: searchAllValues
                                ? searchAllValues.taxon_name1
                                : ""
                            })(
                              <AutoComplete
                                onSearch={this.handleName1Search}
                                onChange={this.handleName1Change}
                                placeholder=""
                              >
                                {name1Opts}
                              </AutoComplete>
                            )}
                          </FormItem>
                        </span>

                        <span>
                          <FormItem
                            label={<p className="h5-map-title">Species：</p>}
                          >
                            {getFieldDecorator("taxon_name2", {
                              initialValue: searchAllValues
                                ? searchAllValues.taxon_name2
                                : ""
                            })(
                              <AutoComplete
                                onSearch={this.handleName2Search}
                                onChange={this.handleName2Change}
                                placeholder=""
                              >
                                {name2Opts}
                              </AutoComplete>
                            )}
                          </FormItem>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="referenceSearch-book">
                  <Form.Item label="Taxon type">
                    {getFieldDecorator("taxon_type", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.taxon_type
                        : ""
                    })(
                      <Select>
                        {taxonTypes.map((item, index) => {
                          return (
                            <Option value={item.type_name} key={item.type_name}>
                              {item.type_name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="referenceSearch-book">
                  <Form.Item label={<p>Collect from treatise?</p>}>
                    {getFieldDecorator("from_treatise", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.from_treatise
                        : ""
                    })(
                      <Select>
                        {treatises.map((item, index) => {
                          return (
                            <Option value={item.name} key={item.name}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="referenceSearch-book">
                  <Form.Item label={<p>Nomenclator</p>}>
                    {getFieldDecorator("nomenclator", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.nomenclator
                        : ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.handleNomSearch(value, "nomenclators")
                        }
                        onChange={value =>
                          this.handleNomChange(value, "nomenclator")
                        }
                        placeholder=""
                      >
                        {nomenclators}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>

                <div className="referenceSearch-base">
                  <div className="referenceSearch-autor referenceSearch-line">
                    <div className="referenceAdd-author-title">Input date</div>
                    <div className="referenceSearch-author searchReference-reference">
                      <span className="search-line-pre">
                        <FormItem
                          help={this.state.helpcreatedAt}
                          validateStatus={
                            this.state.errorcreatedAt ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("createdAt_from", {
                            initialValue: searchAllValues
                              ? searchAllValues.createdAt_from
                              : ""
                          })(
                            <DatePicker
                              disabledDate={this.disabledStartDate}
                              format="YYYY-MM-DD"
                              onChange={this.onStartChange}
                            ></DatePicker>
                          )}
                        </FormItem>
                      </span>
                      <span className="search-line">
                        <span className="iconfont icon-line1"></span>
                      </span>
                      <span>
                        <FormItem
                          label=""
                          help={this.state.helpcreatedAt}
                          validateStatus={
                            this.state.errorcreatedAt ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("createdAt_to", {
                            initialValue: searchAllValues
                              ? searchAllValues.createdAt_from
                              : ""
                          })(
                            <DatePicker
                              disabledDate={this.disabledEndDate}
                              format="YYYY-MM-DD"
                              onChange={this.onEndChange}
                            ></DatePicker>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="referenceSearch-numberProject referenceSearch-single">
                  <div className="Reference-number">
                    <Form.Item label="Authorizer">
                      {getFieldDecorator("authorizer_id", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.authorizer_id
                          : ""
                      })(
                        <AutoComplete
                          onSearch={value =>
                            this.handleNomSearch(value, "authorizers")
                          }
                          onChange={value =>
                            this.handleNomChange(value, "authorizer_id")
                          }
                          placeholder=""
                        >
                          {authorizers}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no Reference-number">
                    <Form.Item label="Enterer">
                      {getFieldDecorator("enterer", {
                        rules: [],
                        initialValue: searchAllValues
                          ? searchAllValues.enterer
                          : ""
                      })(
                        <AutoComplete
                          onSearch={value =>
                            this.handleNomSearch(value, "enterers")
                          }
                          onChange={value =>
                            this.handleNomChange(value, "enterer")
                          }
                          placeholder=""
                        >
                          {enterers}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceAdd-comments last-formItem">
                  <Form.Item label="Taxonomy notes" className="long-label">
                    {getFieldDecorator("notes", {
                      rules: [{}],
                      initialValue: searchAllValues ? searchAllValues.notes : ""
                    })(<TextArea rows={6}></TextArea>)}
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

TaxonomyOption = Form.create({})(TaxonomyOption);
export default TaxonomyOption;
