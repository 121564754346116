import asyncAxios from "src/js/asyncAxios";

export default {
  // reference
  // search
  searchAssets: async params => {
    let url = "/api/user/searchAssets";

    if(params.enterer) {
      params.uid=params.enterer
    }
    let getCenterRes = await asyncAxios.post(url, params);

    return getCenterRes;
  },
 assetsCount: async params => {
    let url = "/api/user/assetsCount";

    let getCenterRes = await asyncAxios.get(url, params);

    return getCenterRes;
  },
};
