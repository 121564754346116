import React, { Component } from "react";
import "./index.css";

import moment from "moment";
import YearPicker from "commons/YearPicker/index";
import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";
import dataApis from "src/js/apis/data";
import { Form, Input, Select, Button, AutoComplete, Modal } from "antd";

import Utils from "src/js/utils";
import Apis from "src/js/apis/api";
import Users from "src/js/users";
import Storage from "src/js/storage";
import "src/js/global.js";
const { Option} = Select;
const { TextArea } = Input;


class AddHigther extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name1Opts: [],
      name2Opts: [],
      clatorOpts: [],
      refSearch: false,
      treatises:[],
      refInfo:{},
      allChildError:{},
      yearValue: null
    };
  }
  isGbdbUser=async () => {
    let Inputerid=false
    let token = Storage.getItem("token");
     Inputerid = await Users.isGbdbInputer();
  
  
    if(token==undefined){
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    }
    else{
      if(Inputerid == false)	{
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title:'Sorry!!',
            content:'You do not have permission to add data'
          })
          this.props.history.push("/home");
        }
      }
      
  
    }
  
  }
  async componentDidMount() {
    this.isGbdbUser()
    let fossilgroup = await dataApis.fossilgroup();
    let treatises = await dataApis.getTreatise();
    this.setState({
      fossilgroup: fossilgroup&&fossilgroup.result?fossilgroup.result:{},
    
      treatises:treatises&&treatises.result?treatises.result:[],
    });
  }
  publishYear=(value)=>{
    this.setState({
      yearValue: value
    });
  }
  addHigther = () => {
    let {refInfo}=this.state
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let addHigTax = Utils.filterParams(values);
        addHigTax = Utils.filterCondition(addHigTax);
        addHigTax = Utils.addEnterer(addHigTax);
        addHigTax = Utils.addAudit(addHigTax,refInfo);
        let addHigher = await Apis.createOriginal(addHigTax);
        if (addHigher && addHigher.error) {
          Modal.error({
            title: "This is an error message",
            content: addHigher.error
          });
          return;
        } else {
          let that = this;
          Modal.success({
            title: "This is a successful message",
            content: "add success",
            onOk() {
              that.props.form.resetFields();
              that.setState({
                yearValue:null
              })
            }
          });
        }

        // let url = '/section/geography'
        // let queryParam = { name: 'geography', inheritRefIds }
        // this.props.history.push({ pathname: url, query: queryParam })
      } else {
      }
    });
  };

  handleName1Change = value => {
    this.props.form.setFieldsValue({
      taxName1: value
    });
  };
  handleName1Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name1"],
        taxon_name1: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);
      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name1Opts: [] });
        } else {
          this.setState(
            {
              name1Opts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ name1Opts: [] });
    }
  };

  handleName2Change = value => {
    
    this.props.form.setFieldsValue({
      taxName2: value
    });
  };
  handleName2Search = async value => {
    if (value) {
      let params = {
        attributes: ["taxon_name2"],
        taxon_name2: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);
      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ name2Opts: [] });
        } else {
          this.setState(
            {
              name2Opts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ name2Opts: [] });
    }
  };

  handleNomSearch = async value => {
    if (value) {
      let params = {
        attributes: ["nomenclator"],
        nomenclator: value
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);
      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });
      } else if (searchResult.result) {
        if (searchResult.result.length === 0) {
          this.setState({ clatorOpts: [] });
        } else {
          this.setState(
            {
              clatorOpts: searchResult.result
            },
            () => {}
          );
        }
      }
    } else {
      this.setState({ clatorOpts: [] });
    }
  };

  handleNomChange = value => {
    this.props.form.setFieldsValue({
      nomenclator: value
    });
  };

  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refSearch: true
    });
  };

  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };

  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };

  onRef = ref => {
    this.child = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true
    });

    //点击 detail 按扭执行代码
  };
  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  checkAgain = optionType => {
    // let { allChildError } = this.state;
 
    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
 
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            refSearch: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues } = this.state;
              this.child.searchReferenceDatas(searchParams, searchValues);
            }, 20);
          }
        );
      }
    });
  };
  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",
     
      // nomenclator: value.authorizer || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    
    let { fossilgroup,treatises } = this.state;
    const name1Opts = this.state.name1Opts.map((item, indx) => (
      <Option key={item.taxon_name1}>{item.taxon_name1}</Option>
    ));
    const name2Opts = this.state.name2Opts.map((item, indx) => (
      <Option key={item.taxon_name2}>{item.taxon_name2}</Option>
    ));
    const clatorOpts = this.state.clatorOpts.map((item, index) => (
      <Option key={index}>{item.nomenclator}</Option>
    ));
    return (
      <div id="addHigther" className="taxonomy-common">
        <div className="addHigther-title">
          <div className="taxonomy-common-title form-common-title">
            Add original sp.name
          </div>

          <div className="addHigther-main">
            <div className="reference-search-form addHigther-form">
              <Form>
                {/* <div className="referenceSearch-book">
                  <Form.Item label={
                    <p>Authorizer</p>
                  }>
                    {getFieldDecorator('authorizer_id', {
                      rules: [
                        {

                        },
                      ],
                      initialValue: ""
                    })(<Select>
                      {
                        authorizers.map((item, index) => {
                          return <Option value={item.key}>{item.name}</Option>
                        })
                      }
                    </Select>)}
                  </Form.Item>
                </div> */}

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<p>Fossil group</p>}>
                      {getFieldDecorator("fossil_group", {
                        rules: [{}],
                        initialValue: ""
                      })(<Select>{Utils.fossilGroup(fossilgroup)}</Select>)}
                    </Form.Item>
                  </div>

                  <div className="referenceSearch-no">
                    <Form.Item label={<p>Taxon type</p>}>
                      {getFieldDecorator("taxon_type", {
                        rules: [],
                        initialValue: "species-original"
                      })(
                        <Select>
                          <Option value="species-original">
                            species-original
                          </Option>
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <Form.Item label={<span>Taxon name1</span>}>
                    {getFieldDecorator("taxon_name1", {
                      rules: [
                        {
                          required: true,
                          message: "Please input the Taxon name"
                        }
                      ],

                      initialValue: ""
                    })(
                      <AutoComplete
                        onSearch={this.handleName1Search}
                        onChange={this.handleName1Change}
                        placeholder=""
                      >
                        {name1Opts}
                      </AutoComplete>
                    )}
                  </Form.Item>

                  <Form.Item label={<span>Taxon name2</span>}>
                    {getFieldDecorator("taxon_name2", {
                      rules: [
                        {
                          required: true,
                          message: 'Please input the Taxon name',
                        }
                      ],

                      initialValue: ""
                    })(
                      <AutoComplete
                        onSearch={this.handleName2Search}
                        onChange={this.handleName2Change}
                        placeholder=""
                      >
                        {name2Opts}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<p>Nomenclator</p>}>
                      {getFieldDecorator("nomenclator", {
                        rules: [{}],
                        initialValue: ""
                      })(
                        <AutoComplete
                          onSearch={this.handleNomSearch}
                          onChange={this.handleNomChange}
                          placeholder=""
                        >
                          {clatorOpts}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<p>Year</p>}>
                      {getFieldDecorator("year", {
                          rules: [
                       
                          ],
                        initialValue: ""
                      })(<YearPicker   
                        time={this.state.yearValue}
                        onChange={value => this.publishYear(value)}/>)}
                    </Form.Item>
                  </div>
                </div>
                <div className="referenceSearch-numberProject">
                <div className="referenceSearch-no">
                    <Form.Item label={<span>Reference</span>}>
                      {getFieldDecorator("ref_id", {
                        rules: [
                          {
                            required: true
                          }
                        ],
                        initialValue: "0"
                      })(<Input onFocus={this.showSearchRef} />)}
                    </Form.Item>
                  </div>
                  <div className="Reference-number">
                    <Form.Item label={<p>Collect from Treatise?</p>}>
                      {getFieldDecorator("from_treatise", {
                        rules: [{}],
                        initialValue: ""
                      })(
                        <Select>
                        {treatises.map((item, index) => {
                         return <Option value={item.name} key={item.name}>{item.name}</Option>
                        })}
                      </Select>
                      )}
                    </Form.Item>
                  </div>
           
                </div>

                <div className="referenceAdd-comments last-formItem">
                  <Form.Item label="Notes" className="long-label">
                    {getFieldDecorator("notes", {
                      rules: [{}],
                      initialValue: ""
                    })(<TextArea rows={6}></TextArea>)}
                  </Form.Item>
                </div>

                <div className="referenceSearch-btns taxonomy-btns">
                  <div>
                    <Button
                      type="gbdb"
                      onClick={() => {
                        this.addHigther();
                      }}
                    >
                      <p>Add taxon</p>
                    </Button>
                  </div>
                </div>

                <div className="taxonomy-tips">
                  <div>Tips:</div>
                  <div>
                    'Original name" means the name used while the taxon was
                    formally named. For exapmte. Climacograptus angustus Perner,
                    1895 is an original name, and Normalograptus angustus
                    (Perner. 1895) is a revised name, white the latter indicates
                    that the species has been moved from genus Climacograptus to
                    Normalograptus after formally named.
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>

        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          <ReferenceOption
           childError={this.childError}
           conditions={{}} ref="reference"></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          <ReferenceTableResult
            searchParams={this.state.searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showRefDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailRefFormOk}
          onCancel={this.detailRefFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
            // searchSection={this.searchSection}
            onlySeeRef={true}
          ></ReferenceDetailForm>
        </Modal>
      </div>
    );
  }
}

AddHigther = Form.create({})(AddHigther);
export default AddHigther;
