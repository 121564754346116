import React from 'react'
import {Form,Input,Button, message,Modal,Tooltip} from 'antd'
import axios from 'axios'
import moment from 'moment'

class AntdForm extends React.Component{
    state={
        data:{}
    }
    componentDidMount(){
        const {modalData}=this.props
        axios.get(`/api/user/byuserid?userid=${modalData.id}`).then(res=>{
            this.setState({
                data:res.data.result
            })
        })
    }
    status(n){
        if(n===0) return 'UnVerify';
        if(n===1) return 'Verifing';
        if(n===2) return 'Verified';
        if(n===3) return 'UnFinished';
        
    }
    showImg(path){
        Modal.info({
            content:<img style={{width:'600px'}} src={'/api'+path}></img>,
            width:700,
            // icon:'资料'
        })
    }
    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 7 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 17 },
            },
          };
          const {data}=this.state;
          
        return (
            <div>
            <div className='modalTitle'>Advanced Info</div>
            <div className='right'>
                <div className='icon'>
                    <Tooltip title='点击放大'>
                        <img onClick={this.showImg.bind(this,data.filePath)} src={data.filePath?'/api'+data.filePath : ''} alt='' ></img>
                    </Tooltip>
                </div>
                <div style={{color:'#3a94bb',textAlign:'center',fontSize:'16px'}}>{this.status(data.userType)}</div>
            </div>
            <div className='form'>
                <Form {...formItemLayout}>

                    <Form.Item label='First Name'>
                        <span>{data.firstname}</span>
                    </Form.Item>

                    <Form.Item label='Last Name'>
                        <span>{data.lastname}</span>
                    </Form.Item>
                
                    <Form.Item label='Degree'>
                        <span>{data.degree}</span>
                    </Form.Item>

                    <Form.Item label='Graduate from'>
                         <span>{data.graduate}</span>
                    </Form.Item>

                    <Form.Item label='Study Time'>
                        <span>
                            {(data.study_time_start && data.study_time_end) ?(
                            `${moment(data.study_time_start).format('YYYY/MM/DD')}~${moment(data.study_time_end).format('YYYY/MM/DD')}`
                            ):null
                            }</span>
                    </Form.Item>

                    <Form.Item label='Present Institute'>
                        <span>{data.preInstitute}</span>
                    </Form.Item>

                    <Form.Item label='Major in'>
                        <span>{data.major}</span>
                    </Form.Item>
                        {/* 专家 */}
                    <Form.Item label='Boss'>
                        <span>{data.boss}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>other Palaeobiogeography<br/>Database</div> }>
                        <span>{data.otherpddb}</span>
                    </Form.Item>
                        {/* 专家 */}
                    <Form.Item label='Paper List'>
                        <span>{data.paperList}</span>
                    </Form.Item>

                    <Form.Item label='Other Information'>
                        <span>{data.otherInfo}</span>
                    </Form.Item>


                </Form>

                <div className='foot'>
                    <Button  type='agbdb1' onClick={()=>{this.props.handle()}}>关闭</Button>
                </div>
            </div>
        </div>
        )
    }
}

const AdvancedInfo= Form.create()(AntdForm)
export default AdvancedInfo