import asyncAxios from "src/js/asyncAxios";

export default {
    createFeedback: async params => {
    let url = "/api/feedback/create";

    let create = await asyncAxios.post(url, params);

    return create;
  },
  getUserFeedback: async params => {
    let url = "/api/feedback/user/feedbacks";

    let list = await asyncAxios.get(url, params);

    return list;
  },
  getAllFeedback: async params => {
    let url = "/api/feedback/admin/feedbacks";

    let listAll = await asyncAxios.get(url, params);

    return listAll;
  },
  deleteUserMessage: async params => {
    let url = "/api/feedback/user/delete";

    let  deleteUser = await asyncAxios.delete(url, params);

    return  deleteUser;
  },
  deleteAdminMessage: async params => {
    let url = "/api/feedback/admin/delete";

    let  deleteAdmin = await asyncAxios.delete(url, params);

    return  deleteAdmin;
  },
};


