import React, { Component } from "react";
import "./index.css";
import { Button } from "antd";
import asyncAxios from "src/js/asyncAxios";
async function people() {
  let people = await asyncAxios.get("/api/assets/informations/people.json");

  return people;
}
export default class ResearchPeople extends Component {
  constructor(props) {
    super(props);
    this.state = {
      peopleList: [],
      showObj: {},
    };
  }
  async componentDidMount() {
    let that = this;

    let peopleList = await people();
    that.setState(
      {
        peopleList: peopleList,
      },
      () => {
        this.canShowMore();
      }
    );
    // console.log("props_state:"+this.props.location.state);
    if(this.props.location.state){
      let peopleName=this.props.location.state.peopleName;
      //console.log("peopleName:"+peopleName);
      if(peopleName && peopleName!=""){
        //console.log("success");
        let element=document.getElementById(peopleName);
        if(element){
          element.scrollIntoView();
        }
      }
    }
  }
  showDescription = (e) => {
    let descriptionNode = e.currentTarget.parentNode.previousSibling;
    
    if (!descriptionNode.classList.contains("disBlock")) {
      descriptionNode.classList.add("disBlock");
      e.currentTarget.innerText = "Fold";
    } else {
      if (descriptionNode.classList.contains("disBlock")) {
        descriptionNode.classList.remove("disBlock");
        e.currentTarget.innerText = "More";
      }
    }
  };
  canShowMore() {
    let { showObj,peopleList } = this.state;
    peopleList.forEach((item, index) => {
      if (item.value.hasOwnProperty("introduce")) {
        let dom = this.refs[`description${item.key}`];
       
        if (dom) {
          let cHeight = dom.clientHeight;
          let sHeight = dom.scrollHeight;
          if (sHeight > cHeight) {
            showObj[item.key] = item.key;
          }
        }
      }
      this.setState({
        showObj,
      },()=>{
      
      });
    });

    
  }
  render() {
    return (
      <div className="people">
        <div className="title">
          <p className="p-title">People</p>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Geobiodiversity Database (GBDB - www.geobiodiversity.com), an integrated system for the management and analysis of stratigraphic and paleontological information, was started in 2006
            and became available online in 2007. Its goal is to facilitate regional and global scientific collaborations focused on regional and global correlation, quantitative stratigraphy,
            systematics, biodiversity dynamics, paleogeography, and paleoecology. It is unique among global, public access databases in that it is a section-based online database system, incorporating
            data from a wide range of disciplines of stratigraphy and paleontology, with inherent interrelationship between different kinds of data sets. Several Windows-based visualization and
            analysis applications, either fully integrated with the database or supported by subset-export functions, have been developed to make the database more useful as a scientific and
            educational tool. The GBDB became the formal database of the International Commission on Stratigraphy (ICS) in August 2012 at the 34th International Geological Congress in Brisbane, and
            will produce comprehensive and authoritative web-based stratigraphic information service for global geoscientists, educators and the public.
          </div>
          <div className="contributors"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GBDB Contributors: Executive Committee (EC). Advisory Board (AB). Tech team (TEC). Data Entry (DE)</div>
        </div>

        <div className="people-content">
          {this.state.peopleList.map((item, index) => {
            return (
              <div id={item.value.name} className="people-tab clearfix" key={item.key}>
                <div className="photo">
                  <img src={item.value.img} alt=""></img>
                </div>
                <div className="people-text">
                  <span className="s1">{item.value.name}</span>
                  <span className="s2">{item.value.position} </span>
                  <div className="p">
                    <p className="p1">{item.value.content}</p>
                    <p className="p2">E-mail:{item.value.mail}</p>
                    {item.value.more == "" ? (
                      <div>
                        <p className="introduce-item" ref={`description${item.key}`}>
                          {item.value.introduce ? `Introduce: ${item.value.introduce}` : ""}
                        </p>
                        {
                          this.state.showObj.hasOwnProperty(item.key)?
                             <div className="resource-btn-more">
                          <Button size="small" onClick={(e) => this.showDescription(e)}>
                            More
                          </Button>
                        </div>
                        :
                        null
                        }
                     
                      </div>
                    ) : (
                      <a href={item.value.more} target="_blank">
                        More
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
