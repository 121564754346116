import React, { Component } from "react";
import "./index.css";
import asyncAxios from "src/js/asyncAxios";
import { Button } from "antd";
async function caseFun() {
  let caseList = await asyncAxios.get("/api/assets/informations/case.json");

  return caseList;
}
export default class ResearchCase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseList: [],
      showObj: {},
    };
  }
  async componentDidMount() {
    let that = this;

    let list = await caseFun();
    that.setState(
      {
        caseList: list,
      },
      () => {
        this.canShowMore();
      }
    );
  }
  canShowMore() {
    let { showObj, caseList } = this.state;
    caseList.forEach((item, index) => {
      if (item.hasOwnProperty("content")) {
        let dom = this.refs[`description${item.key}`];

        if (dom) {
          let cHeight = dom.clientHeight;
          let sHeight = dom.scrollHeight;
          if (sHeight > cHeight) {
            showObj[item.key] = item.key;
          }
        }
      }
      this.setState(
        {
          showObj,
        },
        () => {}
      );
    });
  }
  showDescription = (e) => {
    let descriptionNode = e.currentTarget.parentNode.previousSibling;
    if (!descriptionNode.classList.contains("disBlock")) {
      descriptionNode.classList.add("disBlock");
      e.currentTarget.innerText = "Fold";
    } else {
      if (descriptionNode.classList.contains("disBlock")) {
        descriptionNode.classList.remove("disBlock");
        e.currentTarget.innerText = "More";
      }
    }
  };

  render() {
    return (
      <div className="case">
        <div className="title">Research</div>
        <div className="case-content">
          {this.state.caseList.map((item, index) => {
            return (
              <div className="case-tab clearfix">
                <div className="photo no1">
                  <img src={item.img} alt=""></img>
                </div>

                <div className="tab-content">
                  <div className="tab-title">{item.title}</div>

                  <div className="p">
                    <p className="p1 pcontent" ref={`description${item.key}`} id={item.key}>
                      {item.content}
                    </p>
                    {this.state.showObj.hasOwnProperty(item.key) ? (
                      <div className="resource-btn-more">
                        <Button size="small" onClick={(e) => this.showDescription(e)}>
                          More
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
