import React from 'react'
import {Form,Button,Pagination,Modal,Input,message,Select,Tabs, Slider} from 'antd'
import moment from 'moment'
import axios from 'axios'
import echarts from 'echarts'

const {Option}=Select
const {TabPane}=Tabs

export default class GenDataForm extends React.Component{
    state={
        tableData:[],
        nowPage:1,
        defaultPageSize:1,
        list_nowPage:1,
        list_pageSize:50,
        modalData:{},
        typeData:[],
        chartData:[],
        nowType:null,
        tabNum:1,
        isReverse1:false,
        isReverse2:false,
        isLoading:true,
    }
    
    componentDidMount(){
        var sendData={
            assetstype:3,
            section_basic_id:this.props.sectionId
        }
        axios.get('/api/admin/searchAssets',{ //获取化学数据列表
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                var tableData=res.data.result
                this.setState({
                    tableData,
                },function(){
                    this.getGenData()
                    this.setState({
                        isLoading:false
                    })
                })
               
            }
        })
       
       
    }
    passgeochemical(){
        Modal.confirm({
            content:`点击“确定”后，Geochemical Data( 共 ${this.state.tableData.length} 条数据 )将通过审核！`,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var sendData={
                    section_basic_id:this.props.sectionId,
                    authState:1,
                    auth_time:moment().format('YYYY/MM/DD'),
                }
                axios.post('/api/admin/review/geochemicals',sendData).then(res=>{
                    if(res.data.error===null){
                        this.props.changeStatus();
                    }
                })
            }
        })
    }
    handleReject(){
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                var sendData={
                    section_basic_id:this.props.sectionId,
                    authState:2,
                    auth_time:moment().format('YYYY/MM/DD'),
                    auth_comment:str
                }
                axios.post('/api/admin/review/geochemicals',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已驳回！')
                        this.props.cancelShowInfo()
                    }
                })
               
            },
          
        })
    }
    changePage(page,pageSize){
        this.setState({
            nowPage:page
        },()=>{
            this.getGenData()
        })
    }
    list_changePage(page,pageSize){
        this.setState({
            list_nowPage:page
        })
    }
    getGenData(orderType,ordername,page=1){ //获取当前显示化学数据下分类
        const {nowPage,tableData,defaultPageSize}=this.state
        if(tableData.length>0){
            const sendData={
                geochemistryoccurrence_id:tableData[nowPage-1].id,
                page,
                pageSize:defaultPageSize,
            }
            if(orderType) sendData.orderType=orderType
            if(ordername) sendData.ordername=ordername

            
            axios.get('/api/basic/getGeochemistryTypes',{
                params:sendData
            }).then(res=>{
                if(res.data.error===null){
                    this.setState({
                        typeData:res.data.result,
                        chartData:[]
                    })
                }
                
            })
        }
    }
    selectType(type){
        const {tableData,nowPage,chartData}=this.state
        var sendData={
            geochemistryoccurrence_id:tableData[nowPage-1].id,
            geochemistry_type:type,
            // page:1,
            // pageSize:100,
            ordername:'depthfrom',
            orderType:1,
        }
        axios.get('/api/admin/getGeochemistryDatas',{
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                var data=res.data.result
                this.setState({
                    chartData:data,
                    nowType:type
                },()=>{
                    if(this.state.tabNum==='2' || this.state.tabNum==='3'){
                        this.createChart()
                    }
                })
               
            }
        })
    }
   
    createChart(){
        var {chartData,tabNum,isReverse1,isReverse2}=this.state;
        if(tabNum==='2'){
            if(isReverse1){
                chartData.sort((a,b)=> {return b.depthfrom-a.depthfrom} )
            }else{
                chartData.sort((a,b)=> {return a.depthfrom-b.depthfrom} )
            }
            var option={
                dataZoom: [
                    {
                        type:'slider',
                        show: true,
                        realtime: true,
                        start: 0,
                        end: 25
                    },
                ],
                grid: {
                    left: '1%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b}<br/>{c}'
                },
                xAxis:{
                    name:`Depth(${chartData[0].depth_unit})`,
                    nameLocation:'middle',
                    nameTextStyle:{
                        // padding:[30,0,0,0],
                    },
                    type:'category',
                    data:chartData.map(item=>item.depthfrom)
                },
                yAxis:{
                    name:`${chartData[0].geochemistry_type} ${chartData[0].value_unit}`,
                    type:'value'
                },
                series:[{
                    type:'line',
                    data:chartData.map(item=>item.value)
                }]
            }
            var myChart1=echarts.init(document.querySelector('#chart1'));
            myChart1.setOption(option)
        }
        if(tabNum==='3'){
            if(isReverse2){
                chartData.sort((a,b)=> {return b.depthfrom-a.depthfrom} )
            }else{
                chartData.sort((a,b)=> {return a.depthfrom-b.depthfrom} )
            }
            var option={
                dataZoom: [
                    {
                        type:'slider',
                        orient: 'vertical',
                        show: true,
                        realtime: true,
                        start: 0,
                        end: 25
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b}<br/>{c}'
                },
                xAxis:{
                    name:`${chartData[0].geochemistry_type} ${chartData[0].value_unit}`,
                    type:'value',
                },
                yAxis:{
                    data:chartData.map(item=>item.depthfrom),
                    name:`Depth(${chartData[0].depth_unit})`,
                    type:'category',
                },
                series:[{
                    type:'line',
                    data:chartData.map(item=>item.value)
                }]
            }
            var myChart2=echarts.init(document.querySelector('#chart2'));
            myChart2.setOption(option)
        }
    }
    changeTabs(tab){
       
        this.setState({
            tabNum:tab
        },function(){
            if(tab==='2' || tab==='3'){
                this.createChart()
            }
        })
    }
    reverse(){
        var {tabNum,isReverse1,isReverse2}=this.state
        if(tabNum==='2'){
            this.setState({
                isReverse1:!isReverse1
            },()=>{
                this.createChart()
            })
        }
        if(tabNum==='3'){
            this.setState({
                isReverse2:!isReverse2
            },()=>{
                this.createChart()
            })
        }
      
       
    }
    render(){
          const {list_nowPage,list_pageSize,tableData,nowPage,defaultPageSize,data,modalData,chartData,isLoading,typeData}=this.state
        return (
            <div>
                {
                    <div style={isLoading?{display:'block'}:{display:'none'}} className='tip1'>Loading...</div>
                }
                {
                    tableData.length===0 && !isLoading?
                    (<div>
                        <div className='tip'>暂无数据</div>
                        <div style={{textAlign:'center',height:'80px'}}><Button type='agbdb1'onClick={()=>{this.props.changeStatus()}}>继续</Button></div>
                    </div>)
                    :null}
                {
                    tableData.length>0?
                
                    (
                        <div  className='genDataForm'>
                            {
                                tableData.length>1?( <div className="tip">Geochemical Data共 <span>{tableData.length}</span> 条数据，页面下方可翻页！</div>):null
                            }
                            
                            <Form>
                                <Form.Item label='Geochemial data type'>
                                    <Select style={{width:400}} onSelect={(v)=>{this.selectType(v)}} placeholder='选择类型后显示数据'>
                                        {
                                            typeData.map((item,index)=>{
                                                return (
                                                    <Option key={index} value={item.geochemistry_type}>{item.geochemistry_type}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Form>
                            {
                                chartData.length>0 ?(
                                    <div className='tabs'>
                                    <Tabs type="card" onChange={this.changeTabs.bind(this)}>
                                        
                                        <TabPane tab="Show geochemical data(list)" key="1">
                                            <div className='table'>
                                                <table>
                                                    <tbody>
                                                    <tr className='head'>
                                                        <td>index</td>
                                                        <td>geochemistry type</td>
                                                        <td>depth</td>
                                                        <td>value</td>
                                                        <td>enterer</td>
                                                    </tr>
                                                    {
                                                        chartData.filter((item,index)=>{
                                                            return index>=(list_nowPage-1)*list_pageSize && index<list_nowPage*list_pageSize
                                                        }).map((item,index)=>{
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{(list_nowPage-1)*list_pageSize+index+1}</td>
                                                                    <td>{item.geochemistry_type}</td>
                                                                    <td>{item.depthfrom+item.depth_unit}</td>
                                                                    <td>{item.value+item.value_unit}</td>
                                                                    <td>{modalData.enterer_username}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr>
                                                        <td colSpan={5}>
                                                        <Pagination size='small' 
                                                            current={list_nowPage}
                                                            defaultPageSize={list_pageSize} 
                                                            total={chartData.length} 
                                                            onChange={this.list_changePage.bind(this)}
                                                        ></Pagination>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </TabPane>
                                        
                                        <TabPane forceRender={true} tab="Show figure(horizontal)" key="2">
                                            <div id='chart1' style={{height:500}}></div>
                                        </TabPane>
                
                                        <TabPane forceRender={true} tab="Show figure(vertical)" key="3">
                                            <div id='chart2' style={{height:700}}></div>
                                        </TabPane>
                
                                    </Tabs>
                                        <Button type="link" onClick={()=>{this.reverse()}}>Reverse the depth axis</Button>
                                    </div>
                                ):null
                            }
                        
                            {
                                tableData.length>0?(
                                    <div className='pages'>
                                        <Pagination size='small' 
                                        current={nowPage}
                                        defaultPageSize={defaultPageSize} 
                                        total={tableData.length} 
                                        // showTotal={showTotal.bind(this)}
                                        onChange={this.changePage.bind(this)}
                                        ></Pagination>
                                    </div>
                                ):null
                            }
                            <div className='foot'>
                                <Button  type='agbdb1' onClick={this.passgeochemical.bind(this)}>通过</Button>
                                <Button type='agbdb2' onClick={this.handleReject.bind(this)}>拒绝</Button>
                            </div>
                        </div>
                    ):null
                }
                
           
                
            </div>
        )
    }
}
