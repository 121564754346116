import React from "react";

import { Form, Input, Select, Button, Modal, InputNumber } from "antd";
import centerApis from "src/js/apis/center";
import datas from "commons/data.js";
import Utils from "src/js/utils";
import Storage from "src/js/storage";
import Apis from "src/js/apis/api";
import "./index.css";
import { withRouter } from "react-router-dom";

import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";

import SectionOption from "commons/AllOptions/Section/index.js";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";

import UnitTableResult from "commons/AllTableList/Section/Unit/index.js";
import FormationDetailForm from "commons/AllDetails/Section/Formation/index.js";

const { Option } = Select;

let {
  preservation,
  temporals,
  spatials,
  stattens,
  orientations,
  sediments,
  sortings,
  fragmentations,
  bioerosions
} = datas;
@withRouter
class CollectionDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      // reference , taxonomy , section,occurence,formation
      detailData: props.detailData || {},
      seeOnly: props.seeOnly === true ? true : false,
      seeGeographyFossil: props.seeGeographyFossil === true ? true : false,
      allChildError: {},
      isCenterUser:props.isCenterUser===true?true:false
    };
  }
  async componentDidMount() {
    let collId = this.state.detailData.coll_id;
   
    let sectionResult
    // let isCenter=(this.props.location.pathname==='/center')
  let {isCenterUser}=this.state
    if(!isCenterUser){
      let params = {
        coll_id: collId
      };
      sectionResult = await Apis.getCollections(params);
    }else{
      let params = {
        assetstype: 3,
        enterer: Storage.getItem("gbdbid"),
        coll_id: collId
      };
      sectionResult = await centerApis.searchAssets(params)
    }

    
    if (sectionResult.result) {
      this.setState({
        detailData: sectionResult.result[0] || {}
      });
    } else {
      Modal.error({
        content: sectionResult.error
      });
    }
  }

  // 修改

  modifyCollection = () => {
   
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        // let changeCollInfo = Utils.filterParams(values);
        let changeCollInfo = Utils.filterCondition(values);
        changeCollInfo = Utils.addEnterer(changeCollInfo);
        let params={}
        if(values.unit_id){
          params = {
            unit_id: values.unit_id
          };
        }
        let unitBack =  await Apis.getUnits(params)
        let unitInfo = {};
        if (unitBack.result && unitBack.result.length > 0) {
          unitInfo = unitBack.result[0];
        }
        
     

        changeCollInfo = Utils.addAudit(changeCollInfo, unitInfo);
        let { detailData } = this.state;
        let sectionId = detailData.coll_id;
        changeCollInfo.id = sectionId;
        changeCollInfo.authState = 0;
        // 服务器注释
        let changeCollResult = await Apis.changeCollectionById(changeCollInfo);
        if (changeCollResult.error) {
          Modal.error({
            title: "This is an error message",
            content: changeCollResult.error
          });
        } else if (changeCollResult.result) {
          if (this.props.modifyCollection) {
            this.props.modifyCollection(changeCollResult.result);
          }
        }
      }
    });
  };
  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };
  clickRefId = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };
  onRef = ref => {
    this.child = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true
    });

    //点击 detail 按扭执行代码
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  checkAgain = sectionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    let showType = "refSearch";

    this.refs[sectionType].validateFieldsAndScroll(
      async (err, searchValues) => {
        // if (sectionType === "reference") {
        //   isError = this.state.errorRef;
        // }
        if (sectionType === "reference") {
          showType = "refSearch";
        } else {
          showType = "geographySearch";
        }

        if (!err) {
          let searchParams = Utils.filterParams(searchValues);

          searchParams = Utils.filterCondition(searchParams);

          this.setState(
            {
              ResultVisible: false,
              sectionOptionVisible: false,
              [showType]: true,
              refCondition: false,
              searchParams,
              searchValues,
              sectionType
            },
            () => {
              setTimeout(() => {
                let { searchParams, searchValues, sectionType } = this.state;

                if (sectionType === "reference") {
                  this.child.searchReferenceDatas(searchParams, searchValues);
                } else {
                  this.geographyChild.searchSectionDatas(
                    searchParams,
                    searchValues,
                    sectionType
                  );
                }
              }, 20);
            }
          );
        }
      }
    );
  };
  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };
  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false
    });
  };

  showSearchGeography = (type, e) => {
    e.target.blur();
    this.setState(
      {
        sectionType: type
      },
      () => {
        this.setState({
          sectionOptionVisible: true
        });
      }
    );
  };

  // section
  // section
  onGeography = ref => {
    this.geographyChild = ref;
  };
  geographyDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showSectionDetailForm: true
    });
  };
  handFormOk = () => {
    this.setState({
      sectionOptionVisible: false
    });
  };
  handleFormCancel = () => {
    this.setState({
      sectionOptionVisible: false
    });
    Modal.destroyAll();
  };

  showSearchGeography = (type, e) => {
    e.target.blur();
    this.setState(
      {
        sectionType: type
      },
      () => {
        this.setState({
          sectionOptionVisible: true
        });
      }
    );
  };
  handleGeographyOk = () => {
    this.setState({
      geographySearch: false
    });
  };
  handleGeographyCancel = () => {
    this.setState({
      geographySearch: false
    });
  };
  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }));
  };
  clickGeographyId = (type, value) => {
    this.setState({
      baseInfo: value,
      geologyInfo: value,
      geographySearch: false,
      inheritRefIds: value.ref_id || "",
      baseId: value.section_basic_id || "",
      formationId: value.formation_id || "",
      early_age: value.early_age || "",
      late_age: value.late_age || ""
    });
    this.props.form.setFieldsValue({
      geology_id: value.geology_id || "",
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || "",
      formation_id: value.formation_id || "",
      early_age: value.early_age || "",
      late_age: value.late_age || ""
    });
  };

  checkUnitOne = value => {
    this.props.form.setFieldsValue({
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || "",
      geology_id: value.geology_id || "",
      formation_id: value.formation_id || "",
      early_age: value.early_age || "",
      late_age: value.late_age || "",
      unit_id: value.unit_id || ""
    });
    this.setState({
      inheritRefIds: value.ref_id,
      baseId: value.section_basic_id,
      geographyId: value.geology_id,
      formationId: value.formation_id,
      unitInfo: value,
      geographySearch: false,
      early_age: value.early_age || "",
      late_age: value.late_age || "",
      unitId: value.unit_id || ""
    });
  };
  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType
    });
  };
  detailSectionFormOk = () => {
    this.setState({
      showSectionDetailForm: false
      // sectionType: "baseinfo"
    });
  };
  detailSectionFormCancel = () => {
    this.setState({
      showSectionDetailForm: false
      // sectionType: "baseinfo"
    });
  };

  viewLithostratigraphy = async () => {
    let { detailData } = this.state;

    const { unit_id, ref_id } = detailData;
    let unitParams = {
      unit_id
    };
    let unitInfoBack = await Apis.getUnits(unitParams);

    if (unitInfoBack.result && unitInfoBack.result.length > 0) {
      let unitInfo = unitInfoBack.result[0];
      let url = "/references/allresult/section?";
      let section_basic_id = unitInfo.section_basic_id;
      let refId = ref_id ? "refId=" + ref_id : "";
      let formation_id = unitInfo.formation_id;
      let unit_id = unitInfo.unit_id;
      let baseIdUrl = section_basic_id ? "&baseId=" + section_basic_id : "";
      let formationId = unitInfo.formation_id
        ? "&formationId=" + formation_id
        : "";
      let unitId = unitInfo.unit_id ? "&unitId=" + unit_id : "";
      let params = `${url}${refId}${baseIdUrl}${formationId}${unitId}`;

      // this.props.history.push(params);
      const w=window.open('about:blank');
      w.location.href=params
    } else if(unitInfoBack.error){
      Modal.error({
        content:unitInfoBack.error
      })
    }else{}
    
  };

  render() {
    let { getFieldDecorator } = this.props.form;

    let { detailData, seeOnly, seeGeographyFossil } = this.state;
    let { enterer } = detailData;
    let userid = Storage.getItem("gbdbid");
    let isClick = false;
    let isCenter = this.props.location.pathname === "/center";
    if (enterer && userid && enterer === userid && isCenter) {
      isClick = true;
    }
    if (isClick === false) {
      getFieldDecorator = (...rest) => {
        return element => {
          let NewElement = React.cloneElement(element, {
            disabled: true
          });
          return this.props.form.getFieldDecorator(...rest)(NewElement);
        };
      };
    }

    return (
      <div id="detail_form">
        <div className=" collection-add-main">
          <div className="detailform-title">
            <span className="detailform-name">Collection</span>
          </div>
          <div className="reference-add-form collection-add-form" id="main">
            <Form>
              <div className="referenceAdd-title">
                <Form.Item label="Reference ID">
                  {getFieldDecorator("ref_id", {
                    rules: [
                      {
                        type: "number",
                        required: true,
                        message: "Please input the Reference"
                      }
                    ],
                    initialValue: Number(detailData.ref_id) || ""
                  })(<InputNumber onFocus={this.showSearchRef} disabled />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Unit ID">
                  {getFieldDecorator("unit_id", {
                    rules: [
                      {
                        required: true,
                        message: "Please input the Unit ID",
                        type: "number"
                      }
                    ],
                    initialValue: Number(detailData.unit_id) || ""
                  })(
                    <InputNumber
                      disabled
                      onFocus={e => this.showSearchGeography("unit", e)}
                    ></InputNumber>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Coll Number">
                  {getFieldDecorator("coll_no", {
                    rules: [
                      {
                        required: true,
                        message: "Please input the  Coll Number"
                      }
                    ],
                    initialValue: detailData.coll_no || ""
                  })(<Input  />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-title" style={{display:"flex"}}>
                  <span className="search-line-pre">
                  <Form.Item label="Thickness">
                    {getFieldDecorator("coll_depthlower", {
                      rules: [{
                        pattern: "^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$",
                        message: '请输入两位内小数'
                      }],
                      initialValue: detailData.coll_depthlower || null
                    })(<Input></Input>)}
                  </Form.Item>
                  </span>
                  <span className="search-line" style={{marginLeft:"14px",marginRight:"14px",marginTop:"8px"}}>
                    <span className="iconfont icon-line1"></span>
                  </span>
                  <span>
                    <Form.Item>
                      {getFieldDecorator("coll_depthupper", {
                        rules: [{
                        pattern: "^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$",
                        message: '请输入两位内小数'
                        }],
                        initialValue: detailData.coll_depthupper || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </span>
              </div>
              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Modes of preservation</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_preservation", {
                    rules: [{}],
                    initialValue: detailData.coll_preservation || ""
                  })(
                    <Select placeholder="">
                      {preservation.map((item, index) => {
                        return <Option value={item} key={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Original biominerals</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_biominerals", {
                    rules: [{}],
                    initialValue: detailData.coll_biominerals || ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Replacement Minernals</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_minerals", {
                    rules: [{}],
                    initialValue: detailData.coll_minerals || ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Special modes of occurrence</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_occurrence", {
                    rules: [{}],
                    initialValue: detailData.coll_occurrence || ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Temporal Resolution</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_tmporal", {
                    rules: [{}],
                    initialValue: detailData.coll_tmporal || ""
                  })(
                    <Select placeholder="">
                      {temporals.map((item, index) => {
                        return <Option value={item} key={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Spatial resolution</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_resolution", {
                    rules: [{}],
                    initialValue: detailData.coll_resolution || ""
                  })(
                    <Select placeholder="">
                      {spatials.map((item, index) => {
                        return <Option value={item}  key={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Lager statten type</p>}>
                  {getFieldDecorator("coll_type", {
                    rules: [{}],
                    initialValue: detailData.coll_type ||null
                  })(
                    <Select placeholder="">
                      {stattens.map((item, index) => {
                        return <Option value={item}  key={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Degree of Concentration</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_concentration", {
                    rules: [{}],
                    initialValue: detailData.coll_concentration || ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Spatial orientation</p>}>
                  {getFieldDecorator("coll_orientation", {
                    rules: [{}],
                    initialValue: detailData.coll_orientation || ""
                  })(
                    <Select placeholder="">
                      {orientations.map((item, index) => {
                        return <Option value={item}  key={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label={<p>Anatomical detail</p>}>
                  {getFieldDecorator("coll_detail", {
                    initialValue: detailData.coll_detail || ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Abundance in sediment</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_sediment", {
                    rules: [{}],
                    initialValue: detailData.coll_sediment || ""
                  })(
                    <Select placeholder="">
                      {sediments.map((item, index) => {
                        return <Option value={item}  key={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Size sorting</p>}>
                  {getFieldDecorator("coll_sorting", {
                    rules: [{}],
                    initialValue: detailData.coll_sorting || ""
                  })(
                    <Select placeholder="">
                      {sortings.map((item, index) => {
                        return <Option value={item}  key={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Fragmentation</p>}>
                  {getFieldDecorator("coll_fragmentation", {
                    rules: [{}],
                    initialValue: detailData.coll_fragmentation || ""
                  })(
                    <Select placeholder="">
                      {fragmentations.map((item, index) => {
                        return <Option value={item}  key={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Bioerpsion</p>}>
                  {getFieldDecorator("coll_Bioerosion", {
                    rules: [{}],
                    initialValue: detailData.coll_Bioerosion ||null
                  })(
                    <Select placeholder="">
                      {bioerosions.map((item, index) => {
                        return <Option value={item}  key={item}>{item}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Encrustation</p>}>
                  {getFieldDecorator("coll_Encrustation", {
                    initialValue: detailData.coll_Encrustation || ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item
                  label={<p className="lineNormal">Comments on preservation</p>}
                >
                  {getFieldDecorator("coll_preservations", {
                    initialValue: detailData.coll_preservations || ""
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label={<p>Size classes</p>}>
                  {getFieldDecorator("coll_classes", {
                    initialValue: detailData.coll_classes || ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item
                  label={<p className="lineNormal">Feeding/predation traces</p>}
                >
                  {getFieldDecorator("coll_traces", {
                    initialValue: detailData.coll_traces || ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-title collectionAdd-comments">
                <Form.Item
                  label={
                    <p className="lineNormal">
                      Comments on included components
                    </p>
                  }
                >
                  {getFieldDecorator("coll_components", {
                    initialValue: detailData.coll_components || ""
                  })(<Input />)}
                </Form.Item>
              </div>
              {detailData.auth_time !== null ? (
                <div className="referenceAdd-authours referenceAdd-editors">
                  <div className="authcomment-details">
                    {detailData.authState === 2 ? (
                      <div className="referenceAdd-author authcomment-detail-item">
                        <div className="referenceAdd-author-title authcomment-title">
                          Auth Comment
                        </div>
                        <div className="authcomment-content">
                          {detailData.auth_comment !== ""
                            ? detailData.auth_comment
                            : "No comment"}
                        </div>
                      </div>
                    ) : null}
                    {
                       detailData.authState !==0 ? (
                        <div className="referenceAdd-author authcomment-detail-item">
                        <div className="referenceAdd-author-title authcomment-title">
                          Auth Time
                        </div>
                        <div className="authcomment-content">
                          {detailData.auth_time
                            ? detailData.auth_time.split("T")[0]
                            : ""}
                        </div>
                      </div>
                       ):null
                    }
                 
                  </div>
                </div>
              ) : null}
            
            </Form>
          </div>

          <div className="referenceSearch-btns sectionBase-btns">
            {seeGeographyFossil === false ? null : (
              <div className="source-inner-btns">
                <Button
                  type="gbdb"
                  onClick={() => this.viewLithostratigraphy()}
                >
                  <div>
                    <p>Lithostratigraphy</p>
                    <p>& Fossils</p>
                  </div>
                </Button>

                {/* <Button type="gbdb" onClick={() => this.viewGeochemical()}>
                  <div>
                    <p>Geochemical</p>
                    <p> Data</p>
                  </div>
                </Button> */}
              </div>
            )}

            {/* <div className="formation-complete-btns"> */}
            <div className="referenceSearch-btns sectionBase-btns">
              <div
                className={`${
                  seeGeographyFossil === true
                    ? "source-inner-btns"
                    : "formation-complete-btns"
                }`}
              >
                {/* <Button type="gbdb" onClick={() => this.viewBase()}>
                  <div>
                    <p>View Base</p>
                  </div>
                </Button> */}
                {isClick && seeOnly === false ? (
                  <Button type="gbdb" onClick={() => this.modifyCollection()}>
                    
                      <p>Save modify</p>
                    
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}
          <ReferenceOption
            conditions={{}}
            ref="reference"
            childError={this.childError}
          ></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}

          <ReferenceTableResult
            searchParams={this.state.searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            onlySeeRef={true}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showRefDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailRefFormOk}
          onCancel={this.detailRefFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
            // searchSection={this.searchSection}
            onlySeeRef={true}
            isCenter={true}
          ></ReferenceDetailForm>
        </Modal>

        {/* section */}

        <Modal
          destroyOnClose
          className="searchResultModal"
          width="76%"
          visible={this.state.sectionOptionVisible}
          footer=""
          // closable={false}
          onOk={this.handFormOk}
          onCancel={this.handleFormCancel}
        >
          <SectionOption
            childError={this.childError}
            showGeography={this.state.sectionType === "geology" ? true : false}
            showUnit={this.state.sectionType === "unit" ? true : false}
            showBase={this.state.sectionType === "baseinfo" ? true : false}
            showFomation={this.state.sectionType === "formation" ? true : false}
            ref={this.state.sectionType}
          ></SectionOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain(this.state.sectionType);
                  }}
                >
                  {<p> Search</p>}
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.geographySearch}
          className="searchResultModal taxResultModal noBottomPadding tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleGeographyOk}
          onCancel={this.handleGeographyCancel}
        >
          <UnitTableResult
            searchParams={this.state.searchParams}
            searchValues={this.state.searchValues}
            onGeography={this.onGeography}
            changeCheckSelected={this.changeCheckSelected}
            getDetail={this.geographyDetail}
            checkOne={true}
            checkUnitOne={this.checkUnitOne}
          ></UnitTableResult>
        </Modal>
        <Modal
          title=""
          destroyOnClose
          visible={this.state.showSectionDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76%"
          footer={null}
          // header={null}
          onOk={this.detailSectionFormOk}
          onCancel={this.detailSectionFormCancel}
        >
          {this.state.sectionType === "baseinfo" ? (
            <BaseDetailForm
              detailData={this.state.detailData}
              viewGeography={this.viewGeography}
              seeOnly={true}
            ></BaseDetailForm>
          ) : this.state.sectionType === "geology" ? (
            <GeographyDetailForm
              seeOnly={true}
              detailData={this.state.detailData}
              viewBase={this.viewBase}
            ></GeographyDetailForm>
          ) : this.state.sectionType === "formation" ? (
            <FormationDetailForm
              seeOnly={true}
              detailData={this.state.detailData}
            ></FormationDetailForm>
          ) : null}
        </Modal>
      </div>
    );
  }
}
CollectionDetailForm = Form.create({})(CollectionDetailForm);
export default CollectionDetailForm;
