import React, { Component } from 'react';
import {Form,Input,DatePicker, Button,Pagination, Modal, message } from 'antd'
import './index.scss'
import UserInfoModal from './userInfoModal'
import ResetPasswordModal from './resetPasswordModal'
import axios from 'axios'
import moment from 'moment'

    const {RangePicker}=DatePicker

 class AntdForm extends Component {
    constructor(props){
        super(props);
        this.state={
            tableData:[],
            nowPage:1,
            defaultPageSize:10,
            isShowInfo:false,
            modalData:'',
            isResetPassword:false,
            isResponse:false,
        }
    }
    handleSubmit(page=1){
        this.props.form.validateFields((err, values) => {
            if (!err) {
                var sendData={
                    page,
                    pageSize:this.state.defaultPageSize
                }
                if(values.createTime){
                    sendData.dateFrom=moment(values.createTime[0]).format('YYYY/MM/DD');
                    sendData.dateTo=moment(values.createTime[1]).format('YYYY/MM/DD');
                }
                if(values.keywords){
                    sendData.keyword=values.keywords
                }
               
                axios.get('/api/user/gbdb/getusers',{
                    params:sendData
                }).then(res=>{
           
                    var data=res.data.result;
                    if(res.data.error===null){
                        this.setState({
                            tableData:data,
                            total:res.data.total,
                            isResponse:true,
                        })
                    }
                    if(data.length===0){
                        // message.warning('没有数据！')
                        // this.setState({
                        //     tableData:[]
                        // })
                    }
                }).catch(err=>{
                    message.error('出错了：',err)
                })
            }
        })
    }
    changePage(page,pageSize){
    
        this.setState({
            nowPage:page
        })
        this.handleSubmit(page)
    }
    showInfo(item){
        this.setState({
            modalData:item
        },()=>{
            this.setState({
                isShowInfo:true
            })
        })
        //modal
    }
    handleShowInfo(){
        this.setState({
            isShowInfo:false
        })
    }
    cancelShowInfo(){
        this.setState({
            isShowInfo:false
        })
    }
    resetPassword(item){
        this.setState({
            modalData:item
        },()=>{
            this.setState({
                isResetPassword:true
            })
        })
    }
    handleResetPassword(){
        this.setState({
            isResetPassword:false
        })
    }
    cancelResetPassword(){
        this.setState({
            isResetPassword:false
        })
    }
    render() {
        let {defaultPageSize,nowPage,isShowInfo,isResetPassword,total,modalData,isResponse} =this.state;
        const { getFieldDecorator} = this.props.form;
        const {tableData}=this.state;
        return (
            <div id='AUser'>
                <div className='title'>人员查询</div>
                <div className="form">
                    <Form layout='vertical'>

                        <Form.Item label='创建时间'>
                            {
                                getFieldDecorator('createTime',{

                                })(<RangePicker></RangePicker>)
                            }
                        </Form.Item>

                        
                        <Form.Item label='搜索名称'>
                            {
                                getFieldDecorator('keywords',{

                                })(<Input placeholder='搜索账号或用户名'></Input>)
                            }
                        </Form.Item>

                        <Form.Item className='foot'>
                           <Button type='primary' onClick={()=>{this.handleSubmit()}}>筛选</Button>
                        </Form.Item>

                    </Form>
                   
                </div>
                {
                    tableData.length>0?(
                        <div className='table'>
                        <table>
                            <tbody>
                            <tr className='head'>
                                <td>序号</td>
                                <td>账号</td>
                                <td>用户名</td>
                                <td>联系方式</td>
                                <td>操作</td>
                            </tr>
                            {
                                tableData.map((item,index)=>{
                                    return (
                                        <tr key={index} className={`tr ${index%2===0?'color':''}`}>
                                            <td>{(nowPage-1)*defaultPageSize+index+1}</td>
                                            <td>{item.account}</td>
                                            <td>{item.username}</td>
                                            <td>{item.email}</td>
                                            <td style={{width:'15%'}}>
                                                <Button type='link' onClick={this.showInfo.bind(this,item)}>查看</Button>
                                                <Button type='link' onClick={this.resetPassword.bind(this,item)}>改密</Button>
                                            </td>
                                        </tr>
                                    )
    
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    ):null
                }
                {
                    tableData.length>0?(
                        <div className='pages'>
                                    <Pagination size='small' 
                                    current={nowPage}
                                    defaultPageSize={defaultPageSize} 
                                    total={total} 
                                    // showTotal={showTotal.bind(this)}
                                    onChange={this.changePage.bind(this)}
                                    ></Pagination>
                                </div>
                    ):null
                }
               {tableData.length===0 && isResponse ? <div className='pageTip'>暂无数据</div> :null}

                {
                    isShowInfo?(
                        <UserInfoModal
                            visible={isShowInfo}
                            modalData={modalData}
                            cancelShowInfo={this.cancelShowInfo.bind(this)}
                        ></UserInfoModal>
                    ):null
                }
                {
                   isResetPassword?(
                       <ResetPasswordModal
                       visible={this.state.isResetPassword}
                       modalData={modalData}
                       handleResetPassword={this.handleResetPassword.bind(this)}
                       cancelResetPassword={this.cancelResetPassword.bind(this)}
                       ></ResetPasswordModal>
                   ) :null
                }
               
            
            </div>
        
            );
    }
}
const AUser = Form.create()(AntdForm);
export default AUser

