import GaodeMap from "./GaodeMap";
import axios from "axios";
// import navMap from "./navMap";
// import reconstructMap from "./reconstruct";
import ancientMap from "./ancientMap";
import * as d3 from 'd3';
import * as hammer from 'hammerjs';
import $ from 'jquery';

let timeScale = {};
let Mustache = {};
let currentPrevRequest = {};
let navMap = {};
// import reconstructMap from "./reconstruct";
let reconstructMap = {};


let map = window.map;

if ( !window.ancientMap )
{
    window.ancientMap = ancientMap;
}

var paleo_nav = (function() {

    // var dataUrl ="http://192.168.50.212:8058",
    //     serverUrl = "http://192.168.50.212:8058";
     var 
        // dataUrl ="http://120.55.164.80:8058",
        // serverUrl = "http://120.55.164.80:8058",
        stateUrl = "https://paleobiodb.org",
        dataService = "/assets/map/data";
 var dataUrl ="/api",
        serverUrl = "/api";
    let data = { id: 0, color: "#000000", name: "Geologic Time", children: [] },
        interval_hash = { 0: data };


    return {
            init: function (searchName,interval){
            var timeScaleSize;
            if (window.innerWidth > 1800) {
                timeScaleSize = 60;
            } else if (window.innerWidth > 1500) {
                timeScaleSize = 75;
            } else if (window.innerWidth > 1000) {
                timeScaleSize =  95;
            } else {
                timeScaleSize = 110;
            }
        //     // paleo_nav.initTimeColor();
        if(interval){
            this.changeMapsData(interval,searchName);
        }else{
            // if(ancientMap.visible){
            //      // 古地理地图初始化
            //      ancientMap.init(searchName,()=>{
            //         paleo_nav.hideLoading();
            //     });
            // }else{
            //     GaodeMap.init(()=>{
            //         paleo_nav.hideLoading();
                   
            //         // reconstructMap.init();
            //     },searchName);
            // }

            //现代地理地图初始化
            GaodeMap.init(()=>{
                paleo_nav.hideLoading();
               
                // reconstructMap.init();
            },searchName);
            //古地理地图初始化
            ancientMap.init(searchName,()=>{
                paleo_nav.hideLoading();
            });
        }
            
        },

        subsetInit: function (sectionIds){
            var timeScaleSize;
            if (window.innerWidth > 1800) {
                timeScaleSize = 60;
            } else if (window.innerWidth > 1500) {
                timeScaleSize = 75;
            } else if (window.innerWidth > 1000) {
                timeScaleSize =  95;
            } else {
                timeScaleSize = 110;
            }
            //     // paleo_nav.initTimeColor();
            if(sectionIds){
                GaodeMap.subsetInit(()=>{
                    paleo_nav.hideLoading();
                },sectionIds)
            }
            
        },

        initTimeColor:()=>{
            axios.get("/api/assets/map/data/time/timeList.json")
            .then((data)=>{
               
            })
            .catch((err)=>{
                
            })
        },

        initColor:function () {
            d3.json(paleo_nav.dataUrl + paleo_nav.dataService + "/intervals/list.json?scale=1&order=age.desc&max_ma=4000", function(error, result) {
                for(var i = 0; i < result.records.length; i++) {
                    var r = {
                        "id": result.records[i].oid.replace("int:",""),
                        "pid": result.records[i].pid?result.records[i].pid.replace("int:",""):0,
                        "level": result.records[i].lvl,
                        "color": result.records[i].col,
                        "name": result.records[i].nam,
                        "abbr": result.records[i].abr || result.records[i].nam.charAt(0),
                        "early_age": result.records[i].eag,
                        "late_age": result.records[i].lag,
                        "mid": parseInt((result.records[i].eag + result.records[i].lag) / 2),
                        "total": result.records[i].eag - result.records[i].lag,
                        "children": []
                    };
                    interval_hash[r.id] = r;
                    interval_hash[r.pid].children.push(r);
                }
            }); // End PaleoDB json callback
        },

        calculateSize: function(nofooter) {
            let header = d3.select(".common-header")[0][0];
            let footer = d3.select(".home-add-icons")[0][0];
         
            if(nofooter)
            {
                return {
                    width: d3.select("#home")[0][0].clientWidth,
                    height:window.innerHeight - ( header.clientHeight  )
                }
            }
            return {
                width: d3.select("#home")[0][0].clientWidth,
                height:window.innerHeight - ( header.clientHeight + footer.clientHeight )
            }
        },

        showLoading: function() {
            d3.select("#loading").style("display", "block");
        },

        hideLoading: function() {
            d3.select("#loading").style("display", "none");
        },

        // 古地理地图和现代地理地图切换
        toggleReconstructMap: function() {
            // paleo_nav.untoggleTaxa();
            // paleo_nav.untoggleUser();
            // paleo_nav.closeTaxaBrowser();

            if (ancientMap.visible)
            {
                d3.select("#reconstructMap").style("display","none");
                d3.select("#svgMap").style("display", "block");

                if (parseInt(d3.select("#map").style("display")) == 'none') {
                    d3.select("#map").style("display", "block");
                }

                ancientMap.visible = false

                return;
            }
            ancientMap.visible = true;

            // d3.select(".prevalence-row").style("display", "none");

            /*d3.select(".rotate")
            .style("box-shadow", "inset 3px 0 0 #ff992c")
            .style("color", "#ff992c");*/

            if (parseInt(d3.select("#map").style("height")) > 1) {
                d3.select("#map").style("display", "none");
            }

            d3.select("#svgMap").style("display", "none");
            d3.select("#reconstructMap").style("display","block");

            ancientMap.resize();
            // reconstructMap.resize();
            // reconstructMap.rotate();
            return;

        },

        closeReconstructMap: function() {
            navMap.refresh("reset");

            reconstructMap.visible = false;

            d3.select("#reconstructMap").style("display","none");
            timeScale.unhighlight();

            $(".zoom-in").hammer()
            .on("tap", function(event) {
                event.preventDefault();
                if (parseInt(d3.select("#map").style("height")) < 1) {
                    d3.select("#svgMap").style("display", "none");
                    d3.select("#map").style("height", function() {
                        return ((window.innerHeight * 0.70) - 70) + "px";
                    });
                    map.setView([7,0], 3, {animate:false});
                    navMap.refresh("reset");
                    map.invalidateSize();
                } else {
                    map.zoomIn();
                }
            })
            .css("color", "#000");

            // Handler for the zoom-out button
            $(".zoom-out").hammer()
            .on("tap", function(event) {
                event.preventDefault();
                map.zoomOut();
            })
            .css("color", "#000");

            d3.select(".prevalence-row").style("display", "block");
            paleo_nav.getPrevalence();

            // Show the time interval filter remove button
            d3.select("#selectedInterval")
            .select("button")
            .style("display", "block");

            d3.select(".rotate")
            .style("box-shadow", "")
            .style("color", "#000");

            d3.select(".time")
            .style("color", "#000");

            if(parseInt(d3.select("#map").style("height")) < 1) {
                d3.select("#svgMap").style("display", "block");
            } else {
                d3.select("#map").style("display", "block");
            }
            navMap.resize();
            map.invalidateSize();
        },

        // 根据地址年代显示对应的数据
        changeMapsData: function (interval,searchName) {
          
            if (!interval.name)
            {
                return;
            }
            paleo_nav.showLoading();
            // if(ancientMap.visible)
            //     ancientMap.update(searchName,interval,()=>{
            //         paleo_nav.hideLoading();
            //     });
            // else
            //     GaodeMap.update(searchName,interval,()=>{
            //         paleo_nav.hideLoading();
            //     });

            ancientMap.update(searchName,interval,()=>{
                paleo_nav.hideLoading();
            });

            GaodeMap.update(searchName,interval,()=>{
                paleo_nav.hideLoading();
            });
            // paleo_nav.hideLoading();
        },

        zoomIn: function (event) {
            // event.preventDefault();
            // navMap.zoomIn();
        },

        zoomOut: function (event) {
            // event.preventDefault();
            // navMap.zoomOut();
        },

        dataUrl: dataUrl,
        serverUrl: serverUrl,
        dataService: dataService,
        interval_hash:interval_hash
    }
})();

window.paleo_nav = paleo_nav;
// export default  paleo_nav;
export default  paleo_nav;