import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "antd";

import "./index.scss";
import "src/js/global.js";
import insectsApis from "src/js/apis/insects";
import Storage from "src/js/storage";

function InsectsDetail(props) {
  // console.log(">>>>>>props", props.location.query)
  const { location: { query } = ()=> ({}) } = props || {};

  const detailsObj = {
    class: undefined,
    key: undefined,
    id: undefined,
    subclass: {
      content: undefined,
      author: undefined,
      date: undefined
    },
    infraclass: {
      content: undefined,
      author: undefined,
      date: undefined
    },
    superorder: {
      content: undefined,
      author: undefined,
      date: undefined
    },
    order: {
      content: undefined,
      author: undefined,
      date: undefined
    },
    suborder: {
      content: undefined,
      author: undefined,
      date: undefined
    },
    infraorder: {
      content: undefined,
      author: undefined,
      date: undefined
    },
    superfamily: {
      content: undefined,
      author: undefined,
      date: undefined
    },
    family: {
      content: undefined,
      author: undefined,
      date: undefined
    },
    subfamily: {
      content: undefined,
      author: undefined,
      date: undefined
    },
    genericName: {
      content: undefined,
    },
    speciesName: {
      content: undefined,
    },
    tribe: {
      content: undefined,
      author: undefined,
      date: undefined
    },
    reference: {
      title: undefined,
      author: undefined,
      vol: undefined,
      pages: undefined,
      date: undefined,
      pub: undefined
    },
    lithostratigraphy: {
      lithostrat: undefined,
      member: undefined,
      formation: undefined,
      group: undefined
    },
    time: {
      stage: undefined,
      epoch: undefined,
      subPeriod: undefined,
      period: undefined,
      era: undefined,
      start: undefined,
      end: undefined
    },
    site: {
      siteName: undefined,
      town: undefined,
      county: undefined,
      state: undefined,
      country: undefined,
      continent: undefined
    },
    ... query || {}
  };

  /** 生成详情行 */
  function getDetailItem({
    textIndex= 50,
    order= 1,
    title= 'Title',
    values= [],
    itemStyle= {},
    splitStr=''
  } ={}) {
    let keyIndex = 0;
    const valuesLen= values.length;
    return (
      <div className="details-item" key={title}>
        <div className="item-title" style= { {"textIndent": `${textIndex * order}px`} }>{title}:</div>
        {
          values.map( valueItem => (
            <div className="item-val" key={keyIndex++} style= { {...itemStyle}}>
              {valueItem || '--'}
              {/* 后缀 splitStr */}
              {
                !!splitStr && (keyIndex !== valuesLen) && splitStr
              }
            </div>
          ))
        }
      </div>
    )
  }
  
  const {
    class: classVal,
    subclass,
    infraclass,
    superorder,
    order,
    suborder,
    infraorder,
    superfamily,
    family,
    subfamily,
    genericName,
    speciesName,

    tribe,
    reference,
    lithostratigraphy,
    time,
    site
  } = detailsObj;
  return (
    <div className="insects-details">
      <div className="details-title">Details</div>
      <div className="details-content">
        {getDetailItem({title: 'Class', values: [classVal]})} 
        {getDetailItem({title: 'Subclass', values: [subclass.content, subclass.author, subclass.date], order: 2})}
        {getDetailItem({title: 'Infraclass', values: [infraclass.content, infraclass.author, infraclass.date], order: 3})}
        {getDetailItem({title: 'Superorder', values: [superorder.content, superorder.author, superorder.date], order: 4})}
        {getDetailItem({title: 'Order', values: [order.content, order.author, order.date], order: 5})}
        {getDetailItem({title: 'Suborder', values: [suborder.content, suborder.author, suborder.date], order: 6})}
        {getDetailItem({title: 'Infraorder', values: [infraorder.content, infraorder.author, infraorder.date], order: 7})}
        {getDetailItem({title: 'Superfamily', values: [superfamily.content, superfamily.author, superfamily.date], order: 8})}
        {getDetailItem({title: 'Family', values: [family.content, family.author, family.date], order: 9})}
        {getDetailItem({title: 'Subfamily', values: [subfamily.content, subfamily.author, subfamily.date], order: 10})}
        {getDetailItem({title: 'GenericName', values: [genericName.content], order: 11})}
        {getDetailItem({title: 'SpeciesName', values: [genericName.content, speciesName.content], itemStyle: { fontStyle: 'italic'}, order: 12})}
        
        {getDetailItem({title: 'Tribe', values: [tribe.content, tribe.author, tribe.date], splitStr: ','})}
        {getDetailItem({title: 'Reference', values: [reference.title, tribe.author, reference.vol, reference.pages, reference.date, reference.pub], splitStr: ','})}
        {getDetailItem({title: 'Lithostratigraphy', values: [lithostratigraphy.lithostrat, lithostratigraphy.member, lithostratigraphy.formation, lithostratigraphy.group], splitStr: ','})}
        {getDetailItem({title: 'Time', values: [time.stage, time.epoch, time.subPeriod, time.period, time.era, `(${time.start}~${time.end} Ma)`], splitStr: '/'})}
        {getDetailItem({title: 'Site', values: [site.siteName, site.town, site.county, site.state, site.country, site.continent], splitStr: ','})}
      </div>
    </div>
  )
}
export default InsectsDetail;