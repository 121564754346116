import React, { Component } from "react";
import { Form, Input, Button, Modal ,InputNumber,DatePicker} from "antd";
import moment from 'moment'
import Utils from "src/js/utils";
import Apis from "src/js/apis/api";
import "./index.css";
import Storage from "src/js/storage";
import "src/js/global.js";
import Users from "src/js/users";

const FormItem = Form.Item;

class Huici extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameOpts: [],
      clatorOpts: [],
      refSearch: false
    };
  }

isGbdbUser=async () => {
  let Inputerid=false
  let token = Storage.getItem("token");
   Inputerid = await Users.isGbdbInputer();

 
  if(token==undefined){
    let urlName = this.props.location.pathname.split("/")[1];
    if (global.constants.notGoHome.includes(urlName) == false) {
      Modal.error({
        title: "Sorry!!",
        content: "There is no permission to view the page"
      });
      this.props.history.push("/home");
    }
  }
  else{
    if(Inputerid == false)	{
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.userInput.includes(urlName) == true) {
        Modal.error({
          title:'Sorry!!',
          content:'You do not have permission to add data'
        })
        this.props.history.push("/home");
      }
    }
    

  }

}
  componentDidMount=()=>{
    this.isGbdbUser()
  }
  addHuici = async() => {
        this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let searchResults = Utils.filterParams(values);
       
        let searchValues = Utils.filterCondition(searchResults);
        searchValues = Utils.addEnterer(searchValues);
    
        let huiciBack=await Apis.createRoundtrip(searchValues)
        if(huiciBack.result){
           Modal.success({
            title: "This is a success message",
            content:'Your information added successfully'
          });
          this.props.form.resetFields();
         
        }else{
                 Modal.error({
            title: "This is an error message",
            content: huiciBack.error
          });

        }
      
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="huici" className="taxonomy-common">
        <div className="taxonomy-common-title form-common-title">添加回次</div>
        <div className="huici-content">
          <div className="reference-search-form addHigther-form addHuici-form">
            <Form>
              <div className="referenceSearch-numberProject">
                <div className="Reference-number">
                  <Form.Item label={<span>井号</span>}>
                    {getFieldDecorator("JH", {
                      rules: [
                        {
                          required: true,
                          message: "wrong input"
                        }
                      ],
                      initialValue: ""
                    })(<Input></Input>)}
                  </Form.Item>
                </div>
                <div className="referenceSearch-no">
                  <Form.Item label={<span>回次</span>}>
                    {getFieldDecorator("QXCS", {
                      rules: [
                        {
                          required: true,
                          message: "wrong input",
                          type:'number'
                        }
                      ],
                      initialValue: ""
                    })(<InputNumber></InputNumber>)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceSearch-numberProject">
                <div className="Reference-number">
                  <Form.Item label={<span>起始深度</span>}>
                    {getFieldDecorator("QSSD", {
                      rules: [
                        {
                          required: true,
                          message: "wrong input",
                          type:'number'
                        }
                      ],
                      initialValue: ""
                    })(<InputNumber></InputNumber>)}
                  </Form.Item>
                </div>
                <div className="referenceSearch-no">
                  <Form.Item label={<span>井长</span>}>
                    {getFieldDecorator("JC", {
                      rules: [
                        {
                        
                          required: true,
                          message: "wrong input"
                        }
                      ],
                      initialValue: ""
                    })(<InputNumber></InputNumber>)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceSearch-numberProject">
                <div className="Reference-number">
                  <Form.Item label={<span>岩心长度</span>}>
                    {getFieldDecorator("XC", {
                      rules: [
                        {
                          required: true,
                           type: 'number',
                          message: "wrong input"
                        }
                      ],
                      initialValue: ""
                    })(<InputNumber></InputNumber>)}
                  </Form.Item>
                </div>
                <div className="referenceSearch-no">
                  <Form.Item label={<span>取芯率</span>}>
                    {getFieldDecorator("ZT", {
                      rules: [
                        {
                          required: true,
                      
                          message: "wrong input"
                        }
                      ],
                      initialValue: ""
                    })(<InputNumber></InputNumber>)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceSearch-numberProject">
                <div className="Reference-number">
                  <Form.Item label={<p>取芯日期</p>}>
                    {getFieldDecorator("QXRQ", {
                      rules: [{
                        type:'object'
                      }],
                      initialValue: null
                    })(
                      <DatePicker
                        
                      
                      format="YYYY-MM-DD"
                     
                    ></DatePicker>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceSearch-no">
                  <Form.Item label={<p>扫描日期</p>}>
                    {getFieldDecorator("SMRQ", {
                      rules: [{
                        type:'object'
                      }],
                      initialValue: null
                    })(
                      <DatePicker
                        
                      
                      format="YYYY-MM-DD"
                     
                    ></DatePicker>
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceSearch-btns taxonomy-btns">
                <div>
                  <FormItem>
                    <Button
                      type="gbdb"
                      onClick={() => {
                        this.addHuici();
                      }}
                    >
                      <p>提交</p>
                    </Button>
                  </FormItem>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
Huici = Form.create({})(Huici);
export default Huici;
