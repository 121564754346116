import React, { Component } from "react";
import { Upload, Modal } from "antd";
import { withRouter } from "react-router-dom";
import "./index.css";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
@withRouter
class BaseUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      successUpload: false,
      fileList: props.fileList || [],
      uploadFiles: props.uploadFiles || [],
      imageUrl:
        props.defaultImg || process.env.PUBLIC_URL + "/imgs/uploadFile.png",
      listType: props.listType || "text",
      bg: props.bg || "",
      showUploadList: props.showUploadList === true ? true : false,
      showImg: props.showImg || false,
      uploadTypes: props.uploadTypes,
      multiple: props.multiple === false ? false : true,
      hasFile: props.hasFile ? true : false,
      isCover: 0,
     
    };
  }

  componentDidMount() {
    
    
  }
  componentWillReceiveProps(nextProps) {
   
    

    if (nextProps.defaultImg !== this.props.defaultImg) {
      this.setState({
        imageUrl: nextProps.defaultImg
      });
    }

    if (nextProps.fileList&&nextProps.fileList.length>=0) {
      if (
        nextProps.fileList.length !== this.props.fileList.length
      ) {
        
        this.setState(
          {
            fileList: nextProps.fileList
          },
          () => {}
        );
      }
    }
    if (nextProps.uploadFiles&&nextProps.uploadFiles.length>=0) {
      if (
        nextProps.uploadFiles.length !== this.props.uploadFiles.length
      ) {
        this.setState(
          {
            uploadFiles: nextProps.uploadFiles
          },
          () => {}
        );
      }
    }
    if(nextProps.hasFile!==this.props.hasFile){
      this.setState({
        hasFile: nextProps.hasFile
      });
    }
  }
  uploaderProps = () => {
    const { fileList, hasFile } = this.state;

    let that = this;

    return {
      multiple: that.state.multiple,
      onRemove: file => {
      
        const index = fileList.indexOf(file);
        
 
        if(this.props.removeFile){
       
          this.props.removeFile(file,index);
        }
      },
      beforeUpload: file => {
       
        let { uploadTypes, fileList, multiple ,uploadFiles} = this.state;
        let fileName = file.name;
        var lastname = fileName.substring(
          fileName.lastIndexOf("."),
          fileName.length
        );
        if (uploadTypes) {
          let compareIndex = uploadTypes.includes(lastname);

          if (compareIndex === false) {
            Modal.error({
              title: "Upload Error",
              content: "Please check the format of the upload"
            });

            return false;
          }
        }

        if (multiple === false && uploadFiles.length ===1) {
          Modal.error({
            title: "Upload Error",
            content: "You can only upload one,Please delete the uploaded files first"
          });

          return false;
        }
        if (hasFile===true) {
         
          let that = this;
          // isCover 1：覆盖旧数据， 0:补充数据
          Modal.confirm({
            title: "Which operation do you want to perform",
            cancelText: "add new data",
            okText: "Supplementary Data",
            onOk() {
              that.setState(
                {
                  isCover: 0
                },
                () => {
                  let { isCover, showImg } = that.state;
                  if (showImg) {
                    getBase64(file, imageUrl =>
                      that.setState({
                        imageUrl,
                        loading: false
                      })
                    );
                  }

                  that.props.onChangeFile(file, isCover);
                }
              );
            },
            onCancel() {
              that.setState(
                {
                  isCover: 1
                },
                () => {
                  let { isCover, showImg } = that.state;
                  if (showImg) {
                    getBase64(file, imageUrl =>
                      that.setState({
                        imageUrl,
                        loading: false
                      })
                    );
                  }

                  that.props.onChangeFile(file, isCover);
                }
              );
            }
          });
        } else {
          let { isCover, showImg } = that.state;
          if (showImg) {
            getBase64(file, imageUrl =>
              this.setState({
                imageUrl,
                loading: false
              })
            );
          }

          this.props.onChangeFile(file, isCover);
        }

        return false;
      },
      onDownload:file=>{
      
      },
      fileList
    };
  };
  render() {
    let {  imageUrl, listType, showUploadList } = this.state;

    //     let imgs = require("src/assets/images/form/upload.png")

    let img = imageUrl;

    const uploadButton = (
      <div>
        <div className="ant-upload-text">
          <img src={img} alt="" />
        </div>
      </div>
    );

    return (
      <div className="baseupload-main">
        <Upload
          // 删除按钮不显示
          
          listType={listType}
          className="avatar-uploader"
         
          showUploadList={showUploadList}
           showDownloadIcon={false}
          {...this.uploaderProps()}
        >
          {imageUrl ? <img src={imageUrl} alt="avatar" /> : uploadButton}
        </Upload>
      </div>
    );
  }
}

export default BaseUpload;
