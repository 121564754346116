import asyncAxios from "src/js/asyncAxios";


export default {
  getNews: async params => {
    let url = "/api/mes/user/getNews";

    let NewsGet = await asyncAxios.get(url, params);
   
    return NewsGet;
  },
   getMessages: async params => {
    let url = "/api/mes/user/message";

    let MessagesGet = await asyncAxios.get(url, params);
   
    return MessagesGet;
  },
  getNewsById: async params => {
    let url = "/api/mes/getNewsById";

    let MessagesGet = await asyncAxios.get(url, params);
   
    return MessagesGet;
  },
};
