import React from 'react'
import {Modal,Form,Button,Input,DatePicker} from 'antd'
import './userInfoModal.scss'
import AdvancedInfo from './advancedInfo'


class AntdForm extends React.Component{
    constructor(props){
        super(props)
        this.state={
            isSelectBasic:true,
            isSelectAdvanced:false,
           
        }
    }
    handleSubmit(){
        //通过
        this.props.cancelShowInfo()
       
    }
    handleCancel(){
        //拒绝
        this.props.handleCancel()
    }
    selectBasicInfo(){
        this.setState({
            isSelectBasic:true,
            isSelectAdvanced:false,
        })
    }
    selectAdvancedInfo(){
        this.setState({
            isSelectBasic:false,
            isSelectAdvanced:true,
        })
    }
    render(){
        const {getFieldDecorator} =this.props.form
        const {isSelectBasic,isSelectAdvanced}=this.state;
        const {modalData}=this.props
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 },
            },
          };
       
        return (
            <div>
                <Modal 
                className='AModal userInfoModal'
                visible={this.props.visible}
                onOk={this.props.handleShowInfo}
                onCancel={this.props.cancelShowInfo}
                footer={null}
                >
                    <div className='topBtn'>
                        <Button type='agbdb1' className={`btn1 ${isSelectBasic?'btnFocus':'btnBlur'}`} onClick={this.selectBasicInfo.bind(this)}>Basic Info</Button>
                        <Button type='agbdb1' className={`btn2 ${isSelectAdvanced?'btnFocus':'btnBlur'}`} onClick={this.selectAdvancedInfo.bind(this)}>Advanced Info</Button>
                    </div>
                   
                    {
                        isSelectBasic?(
                        <div>
                            <div className='modalTitle'>Basic Info</div>
                            <div className='right'>
                                <div className='icon'>
                                    <img src={modalData.thumbnail ? '/api'+modalData.thumbnail : ''} alt='' ></img>
                                </div>
                            </div>
                            <div className='form'>
                                <Form {...formItemLayout}>
        
                                    <Form.Item label='Name to display'>
                                       <span>{modalData.username}</span>
                                    </Form.Item>
        
                                    <Form.Item label='Account'>
                                         <span>{modalData.account}</span>
                                    </Form.Item>
                                
                                    <Form.Item label='Email'>
                                        <span>{modalData.email}</span>
                                    </Form.Item>
        
                                    <Form.Item label='Tagline'>
                                        <span>{modalData.tagline}</span>
                                    </Form.Item>
        
                                </Form>
        
                                <div className='foot'>
                                    <Button  type='agbdb1' onClick={()=>{this.props.cancelShowInfo()}}>关闭</Button>
                                </div>
                            </div>
                        </div>
                           
                        ):(
                            <AdvancedInfo handle={this.props.cancelShowInfo} modalData={modalData}></AdvancedInfo>
                        )
                    }
                  
                </Modal>
        </div>
        )
      
    }
}
const AUserInfoModal = Form.create()(AntdForm);
export default AUserInfoModal