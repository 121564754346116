import React from 'react'
import {Modal,Form,Button,Input,message} from 'antd'
import axios from 'axios'
import moment from 'moment'
import UnitInfoModal from './unitInfoModal'

export default class CollectionModal extends React.Component{
    constructor(props){
        super(props)
        this.state={
            unitData:[],
            isShowUnitInfo:false,
            unitInfo:{},

        }
    }
    componentDidMount(){
        const {modalData}=this.props;
        var sendData={
            formation_id:modalData.formation_id,
            assetstype:5,
        }
        
        axios.get('/api/admin/searchAssets',{
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                this.setState({
                    unitData:res.data.result
                })
            }
        })
    }
 
    passStra(){
        Modal.confirm({
            content:`点击“确定”后，Statigraphy&Lithology及包含的Unit将通过审核!`,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                //请求通过
                var sendData={
                    assetstype:4,
                    id:this.props.modalData.formation_id,
                    authState:1,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                }

                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已通过!')
                        this.props.cancelShowInfo();
                        this.props.refreshList()
                    }
                })
              
            }
        })
    }
    handleReject(){
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                var sendData={
                    id:this.props.modalData.formation_id,
                    assetstype:4,
                    authState:2,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                    auth_comment:str
                }
                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已驳回！')
                        this.props.cancelShowInfo()
                        this.props.refreshList()
                    }
                })
               
            },
          
        })
    }
    showUnitInfo(item){
        this.setState({
            unitInfo:item
        },()=>{
            this.setState({
                isShowUnitInfo:true
            })
        })
    }
    closeUnitInfo(){
        this.setState({
            isShowUnitInfo:false
        })
    }
   
    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 5 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 19 },
            },
          };
          const {modalData}=this.props
          const {unitData,isShowUnitInfo}=this.state
        return (
            <div>
                <Modal 
                className='AModal straInfo'
                visible={this.props.visible}
                onCancel={this.props.cancelShowInfo}
                footer={null}
                >
                    <div className='form'>
                    <div className="tip1">Formation</div>
                    <Form {...formItemLayout}>
                        <Form.Item label='Reference ID'>
                            <span>{modalData.ref_id}</span>
                        </Form.Item>

                        <Form.Item label='Formation No'>
                            <span>{modalData.formation_no}</span>
                        </Form.Item>

                        <div className='line'>
                            <Form.Item className='lineItem' label='Group name'>
                                <span>{modalData.formation_group}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Member'>
                                <span>{modalData.formation_member}</span>
                            </Form.Item>
                        </div>

                        <div className='line'>
                            <Form.Item className='lineItem' label='Formation'>
                                <span>{modalData.formation_id}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Bed'>
                                <span>{modalData.formation_bed}</span>
                            </Form.Item>
                        </div>

                        <div className='line'>
                            <Form.Item className='lineItem' label='Early interval'>
                                <span>{modalData.early_interval}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Intage max'>
                                <span>{modalData.intage_max}</span>
                            </Form.Item>
                        </div>

                        <div className='line'>
                            <Form.Item className='lineItem' label='Early age'>
                                <span>{modalData.early_age}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Late age'>
                                <span>{modalData.late_age}</span>
                            </Form.Item>
                        </div>

                        <div className='line'>
                            <Form.Item className='lineItem lineHeight' label={<div>Paleogeograhy<br/>Latitude Decimal</div>}>
                                <span>{modalData.geo_latitude_decimal}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Longtitude Decimal'>
                                <span>{modalData.geo_longitude_decimal}</span>
                            </Form.Item>
                        </div>

                        <div className='line'>
                            <Form.Item className='lineItem' label='Overlying'>
                                <span>{modalData.formation_overlying}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Underline'>
                                <span>{modalData.formation_underline}</span>
                            </Form.Item>
                        </div>

                        <div className='line'>
                            <Form.Item className='lineItem' label='Color'>
                                <span>{modalData.formation_color}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Lithology'>
                                <span>{modalData.formation_lithology}</span>
                            </Form.Item>
                        </div>

                        <Form.Item label='Thickness'>
                            <span>{modalData.formation_thick?(modalData.formation_thick_sign+modalData.formation_thick+modalData.formation_thick_unit):0}</span>
                        </Form.Item>

                        <Form.Item label='Contact base'>
                            <span>{modalData.formation_conta_base}</span>
                        </Form.Item>

                        <Form.Item label='Paleoenvironment'>
                            <span>{modalData.formation_paleoenvironment}</span>
                        </Form.Item>
                    </Form>
                    {
                        unitData.length===0?(<div className='tip1'>暂无Unit数据</div>)
                        :
                        (
                            <div className='modalTable'>
                                <div className='tip1'>Unit数据</div>
                                <table>
                                    <tbody>
                                        <tr className='head'>
                                            <td>序号</td>
                                            <td>Reference ID</td>
                                            <td>Formation ID</td>
                                            <td>Unit ID</td>
                                            <td>操作</td>
                                        </tr>
                                        {
                                            unitData.map((item,index)=>{
                                                return (
                                                    <tr key={index} className='tr'>
                                                        <td>{index+1}</td>
                                                        <td>{item.ref_id}</td>
                                                        <td>{item.formation_id}</td>
                                                        <td>{item.unit_id}</td>
                                                        <td>{<Button type='link' onClick={this.showUnitInfo.bind(this,item)}>详情</Button>}</td>
                                                    </tr>
                                                )
                                            })
                                        }
        
                                    </tbody>
                                </table>
                                {
                                    isShowUnitInfo?(
                                        <UnitInfoModal
                                        visible={isShowUnitInfo}
                                        info={this.state.unitInfo}
                                        handleClose={this.closeUnitInfo.bind(this)}
                                        ></UnitInfoModal>
                                    ):null
                                }
                               

                            </div>
                        )
                    }
                  
                   
                    <div className='foot'>
                            <Button  type='agbdb1' onClick={this.passStra.bind(this)}>通过</Button>
                            <Button type='agbdb2' onClick={this.handleReject.bind(this)}>拒绝</Button>
                    </div>
                    </div>
                   
                    
                </Modal>
            </div>
        )
      
    }
}