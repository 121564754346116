import React, { Component } from "react";

import "./index.css";

import Utils from "src/js/utils";
import Apis from "src/js/apis/api";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Modal,
  InputNumber,
  AutoComplete,
  Radio,
  Tooltip,
  Checkbox,
  Row,Col
} from "antd";
import { withRouter } from "react-router-dom";
import dataApis from "src/js/apis/data";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

@withRouter
class DownloadOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchAllValues: props.searchAllValues || {},
      authorizers: [],
      enterers: [],
      durations: [],
      sectionType: "",
      Countrys: [],
      Provinces: [],
      Villages: [],
      Locations: [],
      Intervals: [],
      Intages: [],
      showBase: props.showBase === true ? true : false,
      showGeography: props.showGeography === true ? true : false,
      showUnit: props.showUnit === true ? true : false,
      showFomation: props.showFomation === true ? true : false,
      showOccurrence: props.showOccurrence === true ? true : false,
      showAlloption: props.showAlloption === true ? true : false,
      showSpecific:false,
      sinocorFlag:true,
      allChildError: {},
      reffrom: props.searchAllValues ? props.searchAllValues.ref_id_from : "",
      refto: props.searchAllValues ? props.searchAllValues.ref_id_to : "",
      selectDuration: { erathem_max: [], period_max: [], epoch_max: [], early_interval: [] },
      unitPartShow:
        props.location.pathname === "/search/section" ||
          props.location.pathname === "/searchs/result/section/base"
          ? true
          : false,
          hideAgeOption:
          props.location.pathname === "/search/section" ||
            props.location.pathname === "/searchs/result/section/base"
            ? true
            : false,

      // sectionfrom:props.searchAllValues?props.searchAllValues.section_id_from:'',
      // sectionto:props.searchAllValues?props.searchAllValues.section_id_to:'',

      sectionfrom: props.searchAllValues
        ? props.searchAllValues.section_basic_id_from
        : "",
      sectionto: props.searchAllValues
        ? props.searchAllValues.section_basic_id_to
        : "",

      taxonfrom: props.searchAllValues ? props.searchAllValues.id_from : "",
      taxonto: props.searchAllValues ? props.searchAllValues.id_to : "",

      createdAtfrom: props.searchAllValues ? props.searchAllValues.createdAt_from : null,
      createdAtto: props.searchAllValues ? props.searchAllValues.createdAt_to : null,
    };
  }

  async componentDidMount() {
    if (this.props.sectionOptionChild) {

      this.props.sectionOptionChild(this);
    }
  }
  changeDurationParent = (selectDuration) => {

    this.setState({
      selectDuration
    }, () => {

    })

  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      showBase: nextProps.showBase,
      showGeography: nextProps.showGeography,
      showUnit: nextProps.showUnit,
      showFomation: nextProps.showFomation,
      showOccurrence: nextProps.showOccurrence,
      searchAllValues: nextProps.searchAllValues
    });
  }

  handleNomSearch = async (value, type) => {
    if (value) {
      let searchResult;
      if (type === "authorizers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteExperts(params);
      } else if (type === "enterers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteEnterer(params);
      } else if (type === "durations") {
        let params = {
          name: value
        };
        searchResult = await dataApis.autoCompleteChronostratigraphics(params);
      }

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });

        return;
      } else if (searchResult.result) {
        this.setState(
          {
            [type]: searchResult.result
          },
          () => { }
        );
      }
    } else {
      this.setState({ [type]: [] });
    }
  };
  handleNomChange = (value, type) => {
    this.props.form.setFieldsValue(
      {
        [type]: value
      }

    );
  };
  handleDuration = (value, type, e) => {
    if (type === "unit_duration") {
      if (e.props && e.props.datavalue) {
        let { selectDuration } = this.state
        let level = Number(e.props.datavalue.level)
        if (level === 1) {
          selectDuration.erathem_max.push(value)
        } else if (level === 2) {
          selectDuration.period_max.push(value)
        } else if (level === 3) {
          selectDuration.epoch_max.push(value)
        } else if (level === 4) {
          selectDuration.early_interval.push(value)
        }
        this.setState({
          selectDuration
        }, () => {

          if (this.props.changeDuration) {


            this.props.changeDuration(this.state.selectDuration);
          }

          let searchType = this.props.form.getFieldValue("duration_search_type");
          let unitDuration = this.props.form.getFieldValue("unit_duration");
          if (

            unitDuration.length > 0 &&
            searchType === ""
          ) {
            this.props.form.setFieldsValue({
              duration_search_type: "or"
            });
          }


        })

      }

    }

  }
  handleonBlure = (value, type, e) => {
    let { selectDuration } = this.state
    if (e.props && e.props.datavalue) {
      

      let level = Number(e.props.datavalue.level)
      if (level === 1) {
        selectDuration.erathem_max.forEach(function (item, index, arr) {
          if (item == value) {
            selectDuration.erathem_max.splice(index, 1);
          }
        });

      } else if (level === 2) {
        selectDuration.period_max.forEach(function (item, index, arr) {
          if (item == value) {
            selectDuration.period_max.splice(index, 1);
          }
        });

      } else if (level === 3) {
        selectDuration.epoch_max.forEach(function (item, index, arr) {
          if (item === value) {
            selectDuration.epoch_max.splice(index, 1);
          }
        });

      } else if (level === 4) {
        selectDuration.early_interval.forEach(function (item, index, arr) {
          if (item === value) {
            selectDuration.early_interval.splice(index, 1);
          }
        });

      }
   

    }
    if(e.props && e.props.value){
      selectDuration.erathem_max.forEach(function (item, index, arr) {
        if (item === value) {
          selectDuration.erathem_max.splice(index, 1);
        }
      });
      selectDuration.period_max.forEach(function (item, index, arr) {
        if (item === value) {
          selectDuration.period_max.splice(index, 1);
        }
      });
      selectDuration.epoch_max.forEach(function (item, index, arr) {
        if (item === value) {
          selectDuration.epoch_max.splice(index, 1);
        }
      });
      selectDuration.early_interval.forEach(function (item, index, arr) {
        if (item === value) {
          selectDuration.early_interval.splice(index, 1);
        }
      });
    }
    this.setState({
      selectDuration
    }, () => {


      if (this.props.changeDuration) {
        this.props.changeDuration(this.state.selectDuration);
      }


    })
    setTimeout(() => {
      let searchType = this.props.form.getFieldValue("duration_search_type");
      let unitDuration = this.props.form.getFieldValue("unit_duration");


      if (

        unitDuration.length === 0 &&
        searchType !== ""
      ) {
        this.props.form.setFieldsValue({
          duration_search_type: ""
        });
      }
    }, 20)
  }

  sectionStartChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  sectionEndChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  onSectionChange = () => { }
  onSectionChange1 = (value, field, type) => {
    let stateType = field + type;
    let { allChildError } = this.state;
    this.setState(
      {
        [stateType]: value
      },
      () => {
        let compareFrom = field + "from";
        let compareTo = field + "to";

        let compareOne = this.state[compareFrom];
        let compareTwo = this.state[compareTo];

        let errorType = "error" + field;
        let helpType = "help" + field;
        if (
          (Utils.isEmpty(compareOne) && !Utils.isEmpty(compareTwo)) ||
          (Utils.isEmpty(compareTwo) && !Utils.isEmpty(compareOne))
        ) {
          this.setState(
            {
              [errorType]: true,
              [helpType]: "Start and end data need to be filled in"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
        } else if (compareTwo && compareOne && compareTwo < compareOne) {
          this.setState(
            {
              [errorType]: true,
              [helpType]: "The starting value is less than the ending value"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
        } else {
          this.setState(
            {
              [errorType]: false,
              [helpType]: ""
            },
            () => {
              if (allChildError[errorType]) {
                delete allChildError[errorType];
              }
            }
          );
        }
      }
    );
  };
  onStartChange = value => {
    this.onTimeChange("startValue", value);
    this.sectionStartChange(value, "createdAt", "from");
  };
  onEndChange = value => {
    this.onTimeChange("endValue", value);
    this.sectionStartChange(value, "createdAt", "to");
  };
  onTimeChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  geologySearch = async (value, type, key) => {
    let params = {
      attributes: [key],
      [key]: value
      // [key]: {
      //   condition: "includes",
      //   value
      // }
    };

    let geoSearchBack = await Apis.autoCompleteGeology(params);

    let searchReust = geoSearchBack.result || [];
    let resultArray = [];

    searchReust.map((item, index) => {
      resultArray.push(item[key]);
    });

    this.setState({
      [type]: resultArray
    });
  };
  geologyChange = (value, field) => {
    this.props.form.setFieldsValue({
      [field]: value
    });
  };
  IntervalChange = async (value, e, field) => {
    // let dataitem = e.props.dataitem;

    // if (dataitem) {
    //   let age =
    //     (parseFloat(dataitem.early_age) + parseFloat(dataitem.late_age)) / 2;
    //   this.changePaleogeography(age);
    //   this.props.form.setFieldsValue({
    //     early_age: parseFloat(dataitem.early_age),
    //     late_age: parseFloat(dataitem.late_age)
    //   });
    // }
    this.props.form.setFieldsValue({
      [field]: value
    });
  };
  IntervalSearch = async (value, type, key) => {
    let params = {
      keyword: value
    };

    let geoSearchBack = await dataApis.autoCompleteInterval(params);

    let searchReust = geoSearchBack.result || [];
    this.setState(
      {
        [type]: searchReust
      },
      () => { }
    );
  };
  changePaleogeography = async age => {
    let params = {
      age,
      latitude: this.state.latitude,
      longitude: this.state.longitude
    };
    let Paleogeography = await Apis.gpsConverts(params);

    if (Paleogeography.result) {
      this.props.form.setFieldsValue({
        geo_latitude_decimal: Paleogeography.result.geoLatitude,
        geo_longitude_decimal: Paleogeography.result.geoLongitude
      });
    } else {
      Modal.error({
        title: "This is a err3 message",
        content: Paleogeography.error
      });
    }
  };
  changeSpecific=(value)=>{
    // console.log("selectValue:"+value);
    if(value=="true"){
      this.setState({
        showSpecific:true
      })
    }else{
      this.setState({
        showSpecific:false
      })
    }
    // console.log("stateValue:"+this.state.showSpecific);
  };

  changeDownload=(e)=>{
    // console.log("event:"+e.target.value);
    let value=e.target.value
    if(value=="Sinocor"){
      this.setState({
        sinocorFlag:false
      })
    }else{
      this.setState({
        sinocorFlag:true
      })
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let {
      searchAllValues,

      showBase,
      showGeography,
      showUnit,
      showFomation,
      showOccurrence,
      Countrys,
      Provinces,
      Villages,
      Locations,
      Intervals,
      Intages,
      showAlloption,
      showSpecific,
      sinocorFlag
    } = this.state;

    const authorizers = this.state.authorizers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    const enterers = this.state.enterers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    const durations = this.state.durations.map((item, index) => (
      <Option key={item.name} datavalue={item}>{item.name}</Option>
    ));

    const intervals = Intervals.map((item, index) => (
      <Option
        value={item.interval_name}
        dataitem={item}
        key={item.interval_name}
      >
        {item.interval_name}
      </Option>
    ));
    const intages = Intages.map((item, index) => (
      <Option
        value={item.interval_name}
        dataitem={item}
        key={item.interval_name}
      >
        {item.interval_name}
      </Option>
    ));
    return (
      //   <div id="searchSection">
      <div id="main" className="reference-search-form commonsection-search">
        <div className="searchSection-main  reference-search-form search-common-form">
          <div className="reference-search-content reference-search-baseform searchSection-form">
            <Form>
              {showBase ? (
                <div>
                  <div className="referenceSearch-base">
                    <div className="referenceSearch-author searchReference-reference">
                      <div className="referenceAdd-author-title">
                        What do you want to download?
                      </div>
                      <div className="referenceSearch-autor section-duration">
                        <FormItem>
                            <div className="referenceSearch-author">
                              <span>                           
                                {getFieldDecorator("downloadType", {
                                  initialValue: "Occurrences"
                                })(
                                  <Radio.Group onChange={e=>{this.changeDownload(e)}}>
                                    <Radio value="Occurrences">Occurrences </Radio>
                                    <Radio value="Collections">Collections </Radio>
                                    <Radio value="Geological_strata">Geological strata </Radio>
                                    <Radio value="Sinocor">Sinocor </Radio>
                                  </Radio.Group>
                                )}
                              </span>
                            </div>
                          </FormItem>
                      </div>
                    </div>
                  </div>
                  
              {sinocorFlag ? (
                <div>
                  <div className="referenceSearch-base">
                    <div className="referenceSearch-autor referenceSearch-line">
                      <div className="referenceAdd-author-title">
                         In which file type do you wish the data be downloaded?
                      </div>
                      <div className="referenceSearch-author searchReference-reference">
                          <FormItem>
                            <div className="referenceSearch-author">
                              <span>                           
                                {getFieldDecorator("fileType", {
                                  initialValue: "csv"
                                })(
                                  <Radio.Group>
                                    <Radio value="csv">csv </Radio>
                                    <Radio value="xlsx">xlsx </Radio>
                                  </Radio.Group>
                                )}
                              </span>
                            </div>
                          </FormItem>
                      </div>
                    </div>
                  </div>

                  <div className="referenceSearch-base">
                    <p className="p-title">
                    What specific information do you need?
                    </p>
                  </div>
                  
                  <div className="referenceSearch-base">
                    <div>
                    < p className="p-title">
                    Select by taxonomy
                    </p>
                    </div>
                    <div style={{marginRight:"78%"}}>
                      <Tooltip title="Specify taxa or taxon you are interested in. Use semicolons to separate various entries.">
                        <span className="iconfont icon-question2"></span>
                      </Tooltip>
                    </div>
                  </div>

                
                  <div className="referenceSearch-book">
                    <Form.Item label="Taxon or taxa to include">
                      {getFieldDecorator("taxonOrTaxa", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.taxonOrTaxa
                          : ""
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>

                  <div className="referenceSearch-numberProject" style={{width:"40%"}}>
                    <div>
                    <Form.Item label="Taxonomic levels">
                      {getFieldDecorator("taxonomicLevels", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.taxonomicLevels
                          : "all"
                      })(
                        <Select placeholder="all">
                          <Option value="all">all</Option>
                          <Option value="species">species</Option>
                          <Option value="genus">genus</Option>
                          <Option value="family">family</Option>
                          <Option value="order">order</Option>
                        </Select>
                      )}
                    </Form.Item>
                    </div>
                    <div style={{marginRight:"20%"}}>
                      <Tooltip title="Select occurrences only include the taxonomic level you choose.">
                        <span className="iconfont icon-question2"></span>
                      </Tooltip>
                    </div>
                  </div>
                 
                  <div className="referenceSearch-book">
                    <Form.Item label="Modifiers">
                      {getFieldDecorator("modifiers", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.modifiers
                          : "false"
                      })(
                        
                        <Select placeholder="no filter" onChange={value=>{this.changeSpecific(value)}}>
                          <Option value="false">no filter</Option>
                          <Option value="true">select/exclude specific modifiers</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </div>

                  {showSpecific ? (
                    <div>
                      <div className="referenceSearch-base">
                        <div className="referenceSearch-autor section-duration" style={{width:"20%"}}>
                        <Form.Item label="Genus">
                          {getFieldDecorator("genusSE", {
                            rules: [{}],
                            initialValue: searchAllValues
                              ? searchAllValues.genusSE
                              : "select"
                          })(
                            <Select placeholder="select">
                            <Option value="select">select</Option>
                            <Option value="exclude">exclude</Option>
                          </Select>
                          )}
                      </Form.Item>
                        </div>
                        <div className="referenceSearch-autor section-duration" style={{marginLeft:"10%"}}>
                          <FormItem>
                            <div className="referenceSearch-author">
                              <span>
                                {getFieldDecorator("genus", {
                                  initialValue: ""
                                })(
                                  <Checkbox.Group>
                                  <Row>
                                    <Col span={6}>
                                      <Checkbox value="?">?</Checkbox>
                                    </Col>
                                    <Col span={6}>
                                      <Checkbox value='""'>""</Checkbox>
                                    </Col>
                                    <Col span={6}>
                                      <Checkbox value="cf.">cf.</Checkbox>
                                    </Col>
                                    <Col span={6}>
                                      <Checkbox value="aff.">aff.</Checkbox>
                                    </Col>
                                    <Col span={6}>
                                      <Checkbox value="Null">Null</Checkbox>
                                    </Col>
                                  </Row>
                                </Checkbox.Group>
                                )}
                              </span>
                            </div>
                          </FormItem>
                        </div>
                      </div>

                      <div className="referenceSearch-base">
                      <div className="referenceSearch-autor section-duration" style={{width:"20%"}}>
                      <Form.Item label="Species">
                        {getFieldDecorator("speciesSE", {
                          rules: [{}],
                          initialValue: searchAllValues
                            ? searchAllValues.speciesSE
                            : "select"
                        })(
                          <Select placeholder="select">
                          <Option value="select">select</Option>
                          <Option value="exclude">exclude</Option>
                        </Select>
                        )}
                    </Form.Item>
                      </div>
                      <div className="referenceSearch-autor section-duration" style={{marginLeft:"10%"}}>
                        <FormItem>
                          <div className="referenceSearch-author">
                            <span>
                              {getFieldDecorator("species", {
                                initialValue: ""
                              })(
                                <Checkbox.Group>
                                  <Row>
                                    <Col span={6}>
                                      <Checkbox value="'?'">?</Checkbox>
                                    </Col>
                                    <Col span={6}>
                                      <Checkbox value='""'>""</Checkbox>
                                    </Col>
                                    <Col span={6}>
                                      <Checkbox value="cf.">cf.</Checkbox>
                                    </Col>
                                    <Col span={6}>
                                      <Checkbox value="aff.">aff.</Checkbox>
                                    </Col>
                                    <Col span={6}>
                                      <Checkbox value='ex gr.'>ex gr.</Checkbox>
                                    </Col>
                                    <Col span={6}>
                                      <Checkbox value="sp.">sp.</Checkbox>
                                    </Col>
                                    <Col span={6}>
                                      <Checkbox value="var.">var.</Checkbox>
                                    </Col>
                                    <Col span={6}>
                                      <Checkbox value="Null">Null</Checkbox>
                                    </Col>
                                  </Row>
                                </Checkbox.Group>
                              )}
                            </span>
                          </div>
                        </FormItem>
                      </div>
                    </div>
                    </div>
                  ) : null}
                </div>
              ): null}

                  <div className="referenceSearch-base">
                    <div>
                    <p className="p-title">
                    Select by time
                    </p>
                    </div>
                    <div style={{marginRight:"83%"}}>
                      <Tooltip title="Select occurrences with targeted time interval. 
                      You can chose to use a single time period or a range of it. 
                      You can also specify numeric age in units of Ma.">
                        <span className="iconfont icon-question2"></span>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="referenceSearch-base">
                    <div className="referenceSearch-autor referenceSearch-line">
                      <div className="referenceAdd-author-title">
                         Interval of Ma range
                      </div>
                      <div className="referenceSearch-author searchReference-reference">
                        <span className="search-line-pre">
                        <Form.Item>
                          {getFieldDecorator("earlyAge", {
                            rules: [],
                            initialValue: searchAllValues
                              ? searchAllValues.earlyAge
                              : ""
                          })(<Input></Input>)}
                        </Form.Item>
                        </span>
                        <span className="search-line">
                          <span className="iconfont icon-line1"></span>
                        </span>
                        <span>
                          <Form.Item>
                            {getFieldDecorator("lateAge", {
                              rules: [],
                              initialValue: searchAllValues
                                ? searchAllValues.lateAge
                                : ""
                            })(<Input></Input>)}
                          </Form.Item>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="referenceSearch-base">
                    <p className="p-title">
                    Select by location
                    </p>
                  </div>

                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two">
                    <Form.Item label="">
                      {getFieldDecorator("countrySE", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.countrySE
                          : "select"
                      })(
                        <Select placeholder="select">
                          <Option value="select">select</Option>
                          <Option value="exclude">exclude</Option>
                        </Select>
                      )}
                    </Form.Item>
                    </div>
                    <div className="Reference-number search-two">
                    <Form.Item label="Country">
                      {getFieldDecorator("country", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.country
                          : ""
                      })(<Input></Input>)}
                    </Form.Item>
                    </div>
                  </div>

                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two">
                    <Form.Item label="">
                      {getFieldDecorator("stateSE", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.stateSE
                          : "select"
                      })(
                        <Select placeholder="select">
                          <Option value="select">select</Option>
                          <Option value="exclude">exclude</Option>
                        </Select>
                      )}
                    </Form.Item>
                    </div>
                    <div className="Reference-number search-two">
                    <Form.Item label="State/province">
                      {getFieldDecorator("state", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.state
                          : ""
                      })(<Input></Input>)}
                    </Form.Item>
                    </div>
                  </div>

                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two">
                    <Form.Item label="">
                      {getFieldDecorator("villageSE", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.villageSE
                          : "select"
                      })(
                        <Select placeholder="select" default-value="select">
                          <Option value="select">select</Option>
                          <Option value="exclude">exclude</Option>
                        </Select>
                      )}
                    </Form.Item>
                    </div>
                    <div className="Reference-number search-two">
                    <Form.Item label="County/village">
                      {getFieldDecorator("village", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.state
                          : ""
                      })(<Input></Input>)}
                    </Form.Item>
                    </div>
                  </div>

                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two" style={{width:"15%"}}>
                    <Form.Item label="Longitude Min">
                    {getFieldDecorator("longitudeMin", {
                            rules: [
                              {
                                // type: "number"
                                pattern:new RegExp('-[0-9]+(\\.[0-9]+)?|[0-9]+(\\.[0-9]+)?','g'),
                                message:'请输入数字'
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.longitudeMin
                              : ""
                          })(<Input></Input>)}
                    </Form.Item>
                    <Form.Item label="Max">
                    {getFieldDecorator("longitudeMax", {
                            rules: [
                              {
                                // type: "number"
                                pattern:new RegExp('-[0-9]+(\\.[0-9]+)?|[0-9]+(\\.[0-9]+)?','g'),
                                message:'请输入数字'
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.longitudeMax
                              : ""
                          })(<Input></Input>)}
                    </Form.Item>
                    </div>
                    <div className="Reference-number search-two" style={{width:"15%",marginRight:"22.5%"}}>
                    <Form.Item label="Latitude Min:">
                    {getFieldDecorator("latitudeMin", {
                            rules: [
                              {
                                // type: "number"
                                pattern:new RegExp('-[0-9]+(\\.[0-9]+)?|[0-9]+(\\.[0-9]+)?','g'),
                                message:'请输入数字'
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.latitudeMin
                              : ""
                          })(<Input></Input>)}
                    </Form.Item>
                    <Form.Item label="Max">
                    {getFieldDecorator("latitudeMax", {
                            rules: [
                              {
                                // type: "number"
                                pattern:new RegExp('-[0-9]+(\\.[0-9]+)?|[0-9]+(\\.[0-9]+)?','g'),
                                message:'请输入数字'
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.latitudeMax
                              : ""
                          })(<Input></Input>)}
                    </Form.Item>
                    </div>
                  </div>

                  <div className="referenceSearch-base">
                    <div>
                    <p className="p-title">
                    Select by geological context
                    </p>
                    </div>
                    <div style={{marginRight:"70%"}}>
                      <Tooltip title="Specify single or multiple sections or formations. Use semicolons to separate various entries.">
                        <span className="iconfont icon-question2"></span>
                      </Tooltip>
                    </div>
                  </div>
                  
                  <div className="referenceSearch-book" style={{width:"30%"}}>
                    <Form.Item label="Section name(s) to include">
                      {getFieldDecorator("sectionName", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.sectionName
                          : ""
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>

                  <div className="referenceSearch-book" style={{width:"30%"}}>
                    <Form.Item label="Formation name(s) to include">
                      {getFieldDecorator("formationName", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.formationName
                          : ""
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>

                  <div className="referenceSearch-numberProject">
                    <div className="Reference-number search-two">
                    <Form.Item label="">
                      {getFieldDecorator("lithologySE", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.lithologySE
                          : "select"
                      })(
                        <Select placeholder="select" default-value="select">
                          <Option value="select">select</Option>
                          <Option value="exclude">exclude</Option>
                        </Select>
                      )}
                    </Form.Item>
                    </div>
                    <div className="Reference-number search-two">
                    <Form.Item label="Lithology">
                    {getFieldDecorator("lithology", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.lithology
                          : ""
                      })(
                        <Select placeholder="">
                          <Option value=""></Option>
                          <Option value="carbonate">carbonate</Option>
                          <Option value="siliciclastic">siliciclastic</Option>
                          <Option value="mixed">mixed</Option>
                          <Option value="volcanic">volcanic</Option>
                          <Option value="metamorphic">metamorphic</Option>
                          <Option value="organic">organic</Option>
                          <Option value="evaporite">evaporite</Option>
                          <Option value="unknown">unknown</Option>
                        </Select>
                      )}
                    </Form.Item>
                    </div>
                  </div>

                  {/* <div className="referenceSearch-book" style={{width:"50%"}}>
                    <Form.Item label="Formation Paleoenvironment">
                      {getFieldDecorator("formationPaleoenvironment", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.formationPaleoenvironment
                          : ""
                      })(<Input></Input>)}
                    </Form.Item>
                  </div> */}

                  {/* <div className="referenceSearch-base">
                    <div className="referenceSearch-autor referenceSearch-line">
                      <div className="referenceAdd-author-title">
                        Formation Paleoenvironment
                      </div>
                      <div className="referenceSearch-author searchReference-reference">
                          <FormItem>
                            <div className="referenceSearch-author">
                              <span style={{width:'100%'}}>                           
                                {getFieldDecorator("formationPaleoenvironment", {
                                  initialValue: ""
                                })(
                                    <Checkbox.Group>
                                      <Row>
                                        <Col span={6}>
                                          <Checkbox value="marine">marine</Checkbox>
                                        </Col>
                                        <Col span={6}>
                                          <Checkbox value="terrestrial">terrestrial</Checkbox>
                                        </Col>
                                        <Col span={6}>
                                          <Checkbox value="volcanic">volcanic</Checkbox>
                                        </Col>
                                        <Col span={6}>
                                          <Checkbox value="transitional">transitional</Checkbox>
                                        </Col>
                                        <Col span={6}>
                                          <Checkbox value="unknown">unknown</Checkbox>
                                        </Col>
                                      </Row>
                                    </Checkbox.Group>
                                )}
                              </span>
                            </div>
                          </FormItem>
                      </div>
                    </div>
                  </div> */}

                  <div className="referenceSearch-book">
                      <FormItem label="Formation Paleoenvironment">
                        <div className="referenceSearch-author">
                          <span style={{width:'100%',display:'grid'}}>                           
                            {getFieldDecorator("formationPaleoenvironment", {
                              initialValue: ""
                            })(
                                <Checkbox.Group style={{width:'100%'}}>
                                  <Row>
                                    <Col span={3}>
                                      <Checkbox value="marine">marine</Checkbox>
                                    </Col>
                                    <Col span={3}>
                                      <Checkbox value="terrestrial">terrestrial</Checkbox>
                                    </Col>
                                    <Col span={3}>
                                      <Checkbox value="volcanic">volcanic</Checkbox>
                                    </Col>
                                    <Col span={3}>
                                      <Checkbox value="transitional">transitional</Checkbox>
                                    </Col>
                                    <Col span={3}>
                                      <Checkbox value="unknown">unknown</Checkbox>
                                    </Col>
                                  </Row>
                                </Checkbox.Group>
                            )}
                          </span>
                        </div>
                      </FormItem>
                  </div>
                  
                </div>
              ) : null}
              
             
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

DownloadOption = Form.create({})(DownloadOption);
export default DownloadOption;
