import React, { Component } from "react";
import { Button, Modal } from "antd";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";
import SubsetApis from "src/js/apis/subset";
import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";

import Storage from "src/js/storage";

import Utils from "src/js/utils";

import "./index.css";
import "src/js/global.js";
class ReferenceSearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResultVisible: false,

      detailData: {},
      showDetailForm: false,
      taxType: "Original",
      isCheckAll: false,
      checkedObj: {},
      resultLists: [],
      defaultCheckedList: [],
      allChildError: {},
      showBackIcon:false
    };
  }
  async componentDidMount() {
    let host = window.location.host;
    
    // let host2 = document.domain;
    let historyLength = this.props.history.length
    if (historyLength > 1) {
      this.setState({
        showBackIcon: true
      })
    }
    if (this.props.location.query) {
      const { searchParams, searchAllValues } = this.props.location.query;

      this.setState(
        {
          searchParams,
          searchAllValues
        },
        () => {
          let { searchParams, searchAllValues } = this.state;

          setTimeout(() => {
            this.child.searchReferenceDatas(searchParams, searchAllValues);
          }, 20);
        }
      );
    }
  }
  detailFormOk = () => {
    this.setState({
      showDetailForm: false
    });
  };
  detailFormCancel = () => {
    this.setState({
      showDetailForm: false
    });
  };
  showOptions = () => {
    this.setState({
      ResultVisible: true
    });
  };
  handFormOk = () => {
    this.setState({
      ResultVisible: false
    });
  };
  handleFormCancel = () => {
    this.setState({
      ResultVisible: false
    });
    Modal.destroyAll();
  };

  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }

    this.refs[optionType].validateFieldsAndScroll(
      async (err, searchAllValues) => {
        if (!err) {
          let searchParams = Utils.filterParams(searchAllValues);

          searchParams = Utils.filterCondition(searchParams);

          this.setState(
            {
              ResultVisible: false,
              searchParams,
              searchAllValues,
              optionType
            },
            () => {
              let { searchParams, searchAllValues } = this.state;

              setTimeout(() => {
                this.child.searchReferenceDatas(searchParams, searchAllValues);
              }, 20);
            }
          );
        }
      }
    );
  };
  showOptions = () => {
    this.setState({
      ResultVisible: true
    });
  };

  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showDetailForm: true
    });
  };
  getCite = () => {};
  searchSection = searchParams => {
    let { ref_id } = searchParams;
    this.setState(
      {
        showDetailForm: false
      },
      () => {
        let url = "/searchs/result/section/base?";
        let refId = ref_id ? "refId=" + ref_id : "";
        let params = `${url}${refId}`;
        // this.props.history.push(params);
        const w=window.open('about:blank');
        w.location.href=params
        // this.props.history.push({ pathname: url, query: {searchParams} });
      }
    );
  };
  searchOccurenceDatas = searchParams => {
    let { ref_id } = searchParams;
    this.setState(
      {
        showDetailForm: false
      },
      () => {
        let url = "/searchs/result/occurence?";
        let refId = ref_id ? "refId=" + ref_id : "";
        let params = `${url}${refId}`;
        const w=window.open('about:blank');
        w.location.href=params
        // this.props.history.push(params);
      }
    );
  };

  onRef = ref => {
    this.child = ref;
  };
  changeCheckSelected = checkedObj => {
    this.setState({
      checkedObj
    });
  };

  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  goHistoryBack = () => {
    this.props.history.push("/search/reference");
    // this.props.history.goBack();
  };
  render() {
    let { searchParams, searchAllValues } = this.state;

    return (
      <div id="SearchResult">
        <div className="SearchResult-main reference-search-form">
          <div className="head">
          {/* {
             this.state.showBackIcon?
             <span
             className="iconfont icon-back"
             onClick={this.goHistoryBack}
           ></span>
           :
           null
           } */}

            <span className="head-title">Reference Search Result</span>
          </div>

          <div className="option-btn">
            <Button type="gbdb">
              <div
                className="result-search-option"
                onClick={() => this.showOptions()}
              >
                Search option
              </div>

              <div id="searchModel">
                <Modal
                  className="searchResultModal"
                  width="76%"
                  visible={this.state.ResultVisible}
                  footer=""
                  // closable={false}
                  onOk={this.handFormOk}
                  onCancel={this.handleFormCancel}
                >
                  <ReferenceOption
                    childError={this.childError}
                    searchAllValues={searchAllValues}
                    ref="reference"
                  ></ReferenceOption>

                  {
                    <div className="referenceSearch-btns">
                      <div>
                        <Button
                          type="gbdb"
                          onClick={() => {
                            this.checkAgain("reference");
                          }}
                        >
                          <p> Search</p>
                        </Button>
                      </div>
                    </div>
                  }
                </Modal>
              </div>
            </Button>
          </div>

          <ReferenceTableResult
            searchParams={searchParams}
            searchAllValues={searchAllValues}
            onRef={this.onRef}
            getDetail={this.getDetail}
            changeCheckSelected={this.changeCheckSelected}
            showCite={true}
            showDeleteSubset={false}
            showAddSubset={true}
            showExportFile={true}
          ></ReferenceTableResult>
         
        </div>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailFormOk}
          onCancel={this.detailFormCancel}
        >
          <ReferenceDetailForm
            onlySeeRef={true}
            seeSection={true}
            seeOccurrences={true}
            detailData={this.state.detailData}
            searchSection={this.searchSection}
            searchOccurenceDatas={this.searchOccurenceDatas}
          ></ReferenceDetailForm>
        </Modal>
      </div>
    );
  }
}

export default ReferenceSearchResult;
