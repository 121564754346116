import React from 'react'
import { Modal, Form, Pagination, Table, message, Button } from 'antd'
import axios from 'axios'
import './infoModal.scss'
import InfoModal from './infoModal'


export default class FormationInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            page: 1,
            pageSize: 5,
            total: 0,
            isShowInfoModal: false,
            params:props.params,
            formation_id:null,
            formationLists: []
        }
    }
    

    getColumns = () => {

        return [
            {
                title: "index",
                dataIndex: "updatedAt",
                key: "a-index",
                render: (text, record, index) => {
                    return index + 1;
                }
            },
            {
                title: "section id",
                dataIndex: "section_basic_id",
                key: "a-section_basic_id"
            },
            {
                title: "section name",
                dataIndex: "section_name",
                key: "a-section_name"
            },
            {
                title: "formation id",
                dataIndex: "formation_id",
                key: "a-formation_id",
                render:(text, record, index)=>{
                    return text+"("+record.formation_no+")"
                }
            },
            /* {
                title: "formation no",
                dataIndex: "formation_no",
                key: "a-formation_no"
            }, */
            {
                title: "formation Name",
                dataIndex: "formation_name",
                key: "a-formation_name"
            },
            {
                title: "formation Thickness",
                dataIndex: "formation_thick",
                key: "a-formation_thick",
                render: (text, record, index) => {
                    return record.formation_thick_sign +" "+ record.formation_thick +record.formation_thick_unit;
                }
            },
            {
                title: "Early Age",
                dataIndex: "early_age",
                key: "a-early_age",
                render: (text, record, index) => {
                    return text +" Ma";
                }
            },
            {
                title: "Late Age",
                dataIndex: "late_age",
                key: "a-late_age",
                render: (text, record, index) => {
                    return text +" Ma";
                }
            },
            {
                title: "",
                dataIndex: "formation_id",
                key: "a-edit",
                render: (text, record, index) => {
                    return  <Button type='agbdb1' onClick={this.showFormation.bind(this, text)}>details</Button>
                }
            },
        ]
    }

    getformations = (page) => {
        var {formation_ids} = this.state.params;
        page = page || this.state.page;

        let ids = formation_ids.split(",");

      
        axios.get(`/api/search/map/searchFormationByIds?ids=${JSON.stringify(ids)}&page=${page}&pageSize=${this.state.pageSize}`).then(res => {
            if (res.data.error === null) {
                let {result,page,total} = res.data;
                this.setState({
                    formationLists: result,page,total
                })
            }
        }).catch(err => {
            message.error('出错啦：', err)
        })

    }

    componentDidMount() {
        this.getformations(1)
    }

    showCollection=(id) =>{
        this.setState({
            params: {unit_id:id,formation_id:window.formation_id},
        },()=>{
            this.setState({ isShowCollection: true })
        })
    }

    showFormation = (id)=>{

        this.setState({
            formation_id:id
        },()=>{
            this.setState({ isShowInfoModal: true })
        })
    }

    cancelShowInfoModal() {
        this.setState({
            isShowInfoModal: false
        })
    }

    changePage = (page) => {
        this.getformations(page);
    }

    render() {
        const formItemLayout = {
            // layout:"inline",
            /* labelCol: {
              xs: { span: 24 },
              sm: { span: 4 },
              md: { span: 24 },
              lg: { span: 6 },
            }, */
            wrapperCol: {
                xs: { span: 15 },
                sm: { span: 15 },
                md: { span: 15 },
                lg: { span: 16 },
            },
        };
        const { info, isShowCollection } = this.state

        let paginationInfo = {
            current: Number(this.state.page),
            defaultCurrent: Number(this.state.page),
            pageSize: Number(this.state.pageSize),
            total: Number(this.state.total),
            onChange: this.changePage
        };

        return (
            <Modal
                width='1100px'
                className='AModal infoModal infoModal-formation'
                title="Formation Lists"
                visible={this.props.visible}
                onCancel={this.props.cancelShowInfoModal}
                footer={null}
            >
                <div>
                    { 1?(<div className="table-list modalTable">
                        <Table
                            dataSource={this.state.formationLists}
                            columns={this.getColumns()}
                            rowKey="id"
                            pagination={false}
                        />
                        {this.state.formationLists.length > 0 ? (
                            <div className="pagination-wrap unit-pages clearfix">
                                <Pagination {...paginationInfo}></Pagination>
                            </div>
                        ) : null}
                    </div>):<p className="no-units">No formations</p>}
                     {
                    this.state.isShowInfoModal ? (
                        <InfoModal
                        formation_id={this.state.formation_id}
                        visible={this.state.isShowInfoModal}
                        cancelShowInfoModal={this.cancelShowInfoModal.bind(this)}
                        ></InfoModal>) : null
                    }
                </div>

            </Modal>
        )
    }
}