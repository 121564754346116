import React, { Component } from "react";
import { Input, Button, Modal, Select } from "antd";

import "./index.css";
import "src/js/global.js";
import groupApis from "src/js/apis/group";
import Storage from "src/js/storage";
const { Option } = Select;
const { Search } = Input;

export default class GroupSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serachGroups: [],
      currentGroupId: "",
      groupShare: true,
      searchValue: "",
      currentGroup: {},
    };
  }
  setGroupType = () => {
    let { groupShare } = this.state;
    groupShare = !groupShare;
    this.setState({
      groupShare,
      searchValue: "",
    });
  };
  isGbdbUser = () => {
    let token = Storage.getItem("token");

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page",
        });
        this.props.history.push("/home");
      }
    }
  };
  componentDidMount() {
    this.isGbdbUser();
  }
  serachGroup = async (value) => {
    if (value) {
      let { groupShare } = this.state;
      let params = {
        name: value,
        isShare: groupShare ? 1 : 0,
      };
      let searchResult = await groupApis.getGroups(params);
      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error,
        });
      } else {
        this.setState(
          {
            serachGroups: searchResult.result,
          },
          () => {
            // setTimeout(() => {
            //   if (document.querySelector(".group-search-input input")) {
            //     document.querySelector(".group-search-input input").value = "";
            //   }
            // }, 2000);
          }
        );
      }
    } else {
      Modal.error({
        content: "please input folder name",
      });
    }
  };
  clickGroup = (item) => {
    this.setState({
      currentGroupId: item.id,
      currentGroupCreator: item.uid,
    });
  };
  applyGroup = async (item) => {
    let { currentGroupId, currentGroupCreator } = this.state;
    if (Storage.getItem("gbdbid")) {
      let params = {
        creator: currentGroupCreator,
        uid: Storage.getItem("gbdbid"),
        groupid: currentGroupId,
      };
      let groupApply = await groupApis.applyAddGroup(params);
      if (groupApply.error) {
        Modal.error({
          title: "This is an error message",
          content: groupApply.error,
        });
      } else {
        Modal.success({
          content: "The application has been submitted successfully",
        });
      }
      this.setState({
        currentGroupId: "",
        searchValue:''
      });
    }
  };
  searchChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };
  handleChange = (value) => {
    let groupShare = false;
    if (value == "public") {
      groupShare = true;
    } else if (value == "private") {
      groupShare = false;
    }

    this.setState({
      groupShare,
      // searchValue: "",
    });
  };
  render() {
    let { serachGroups, currentGroupId, groupShare, currentGroupCreator } = this.state;

    return (
      <div className="group-search group-search-contents">
        <div className="group-search-item">
          <span className="entrance-search">
            <Search
              // addonBefore={groupShare ? "Public" : "Private"}
              addonBefore={
                <Select defaultValue="public" className="select-before" onChange={this.handleChange}>
                  <Option value="public">public</Option>
                  <Option value="private">private</Option>
                </Select>
              }
              defaultValue="Public"
              placeholder={groupShare ? `Search Public Group....` : `Search Private Group....`}
              className="group-search-input"
              onSearch={(value) => this.serachGroup(value)}
              value={this.state.searchValue}
              onChange={this.searchChange}
            ></Search>
          </span>
          {/* <span className="entrance-search-btn">
            <Button placeholder="" type="gbdb">
              Search group
            </Button>
          </span> */}
          {/* <div className="search-private-group">
            <Button placeholder="" type="gbdb" onClick={this.setGroupType}>
              Change group type
            </Button>
          </div> */}
        </div>

        <div></div>

        <div className="search-result-content">
          {serachGroups.length > 0 ? <p className="search-result-title">Select label to see apply</p> : null}

          <div className="search-result-list clearfix">
            {serachGroups.length > 0 ? (
              serachGroups.map((item, index) => {
                return (
                  <span
                    title={item.uid == Storage.getItem("gbdbid") ? "You are the founder of the group" : item.name}
                    key={item.id}
                    className={`group-result-item ${currentGroupId === item.id ? "selected" : null}`}
                    onClick={(e) => this.clickGroup(item, e)}
                  >
                    <span>{item.name}</span>
                  </span>
                );
              })
            ) : (
              <span className="search-result-tips">No Data</span>
            )}
          </div>

          <div className="search-result-apply">
            <Button placeholder="" type="gbdb" onClick={this.applyGroup} disabled={currentGroupId === "" || currentGroupCreator == Storage.getItem("gbdbid") ? true : false}>
              Apply
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
