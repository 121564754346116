import React, { Component } from "react";
import { Form, Input, Button, Modal, InputNumber, DatePicker } from "antd";
import BaseUpload from "commons/BaseUpload/index";
import Storage from "src/js/storage";
import moment from "moment";
import Utils from "src/js/utils";
import Apis from "src/js/apis/api";
import "./index.css";
import { withRouter } from "react-router-dom";
import centerApis from "src/js/apis/center";
const { TextArea } = Input;
@withRouter
class OceanicDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameOpts: [],
      clatorOpts: [],
      refSearch: false,
      detailData: props.detailData || {},
      oceanicType: props.oceanicType || "roundtrip",
      onlySee: props.onlySee === true ? true : false,
      uploadFiles: [],
     
      isCenterUser: props.isCenterUser === true ? true : false
    };
  }
  async componentDidMount() {
    let HUICIId = this.state.detailData.id;
    let oceanicBack = {};
    let { isCenterUser } = this.state;

    // let isCenter = (this.props.location.pathname === "/center");
    let { oceanicType } = this.state;
    if (!isCenterUser) {
      let params = {
        id: HUICIId
      };

      if (oceanicType === "roundtrip") {
        oceanicBack = await Apis.searchRoundtrip(params);
      } else if (oceanicType === "coreimage") {
        oceanicBack = await Apis.searchCoreimage(params);
      } else if (oceanicType === "lithology_section") {
        oceanicBack = await Apis.searchLithologySection(params);
      } else if (oceanicType === "lithology_description") {
        oceanicBack = await Apis.searchLithologyDesc(params);
      }
    } else {
      let params = {};
      if (oceanicType === "roundtrip") {
        params = {
          assetstype: 7,
          enterer: Storage.getItem("gbdbid"),
          id: HUICIId
        };
      } else if (oceanicType === "coreimage") {
        params = {
          assetstype: 8,
          enterer: Storage.getItem("gbdbid"),
          id: HUICIId
        };
      } else if (oceanicType === "lithology_section") {
        params = {
          assetstype: 9,
          enterer: Storage.getItem("gbdbid"),
          id: HUICIId
        };
      } else if (oceanicType === "lithology_description") {
        params = {
          assetstype: 10,
          enterer: Storage.getItem("gbdbid"),
          id: HUICIId
        };
      }
      oceanicBack = await centerApis.searchAssets(params);
    }
  

    if (oceanicBack.result) {
      this.setState({
        detailData: oceanicBack.result[0] || {}
      },()=>{
        let {detailData}=this.state
        if(detailData.ZQM!==null){
          this.setState({
            uploadFiles:[
              {
                uid: -1,
                name: detailData.ZQM,
                status: "done",
                url: `/api` +detailData.ZQM,
                linkProps: {"download":  ''}
              }
            ]
          })
        }
      });
    } else {
      Modal.error({
        content: oceanicBack.error
      });
    }
  }
  modifyHuici = () => {};
  onChangeFile = file => {
    let newest = file;
    
    this.setState(
      {
        uploadFiles: [newest]
      },
      () => {}
    );
  };
  removeFile = (file, index) => {

    let that=this
    Modal.confirm({
      title: "Do you want to delete the file?",
      content: "Unrecoverable after deletion!!!",
      async onOk() {
        let { uploadFiles } = that.state;
        uploadFiles.splice(index, 1);
        that.setState({
          uploadFiles
        });
        that.props.form.setFieldsValue({
          ZQM: null
        });
      },
      onCancel() {}
    });
    
  };
  modifyCoreimage = async searchValues => {
    const formData = new FormData();
    
    if(searchValues.ZQM===undefined){
      delete searchValues.ZQM
    }
    for (let [key, value] of Object.entries(searchValues)) {
      formData.append(key, value);
    }
  
    let { uploadFiles } = this.state;
   

    if (uploadFiles.length > 0) {
      uploadFiles.map((item, index) => {
        formData.append("files", item);
      });
    }
    let coreimageBack = await Apis.changeCoreimageById(formData);
    return coreimageBack;
  };
  modifyOceanic = async () => {
    let { oceanicType } = this.state;
    this.props.form.validateFieldsAndScroll(async (err, values) => {
   
      if (!err) {
        // let searchResults = Utils.filterParams(values);

        let searchValues = Utils.filterCondition(values);
        searchValues = Utils.addEnterer(searchValues);
        searchValues.id = this.state.detailData.id;
        // searchValues.authState = 0;
        let huiciBack = {};
        if (oceanicType === "roundtrip") {
          huiciBack = await Apis.changeRoundtripById(searchValues);
        } else if (oceanicType === "coreimage") {
          huiciBack = await this.modifyCoreimage(searchValues);
        } else if (oceanicType === "lithology_section") {
          huiciBack = await Apis.changeLithologySectionById(searchValues);
        } else if (oceanicType === "lithology_description") {
          huiciBack = await Apis.changeLithologyDescById(searchValues);
        }
      

        if (huiciBack.result) {
          
          if (this.props.modifyOceanic) {
            this.props.modifyOceanic(huiciBack.result);
          }
        } else {
          Modal.error({
            title: "This is an error message",
            content: huiciBack.error
          });
        }
      }
    });
  };
  render() {
    let { getFieldDecorator } = this.props.form;
    let { detailData, oceanicType } = this.state;
    let { enterer } = detailData;
    let userid = Storage.getItem("gbdbid");
    let isClick = false;
   
    let isCenter = this.props.location.pathname === "/center";
    if (enterer && userid && enterer === userid && isCenter) {
      isClick = true;
    }
    if (isClick === false) {
      getFieldDecorator = (...rest) => {
        return element => {
          let NewElement = React.cloneElement(element, {
            disabled: true
          });
          return this.props.form.getFieldDecorator(...rest)(NewElement);
        };
      };
    }

    return (
      <div id="huici" className="taxonomy-common">
        <div className="detailform-title">
          <span className="detailform-name">
            {oceanicType === "roundtrip"
              ? "回次"
              : oceanicType === "coreimage"
              ? "岩心图像"
              : oceanicType === "lithology_section"
              ? "岩心剖面"
              : oceanicType === "lithology_description"
              ? "岩性描述"
              : ""}
          </span>
        </div>

        <div className="huici-content">
          <div
            className="reference-search-form addHigther-form addHuici-form"
            id="main"
          >
            {oceanicType === "roundtrip" ? (
              <Form>
                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<span>井号</span>}>
                      {getFieldDecorator("JH", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData?detailData.JH : null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>回次</span>}>
                      {getFieldDecorator("QXCS", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input",
                            type: "number"
                          }
                        ],
                        initialValue: detailData?detailData.QXCS : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<span>起始深度</span>}>
                      {getFieldDecorator("QSSD", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input",
                            type: "number"
                          }
                        ],
                        initialValue: detailData?detailData.QSSD : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>井长</span>}>
                      {getFieldDecorator("JC", {
                        rules: [
                          {
                            type: "number",
                            required: true,
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData?detailData.JC : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<span>岩心长度</span>}>
                      {getFieldDecorator("XC", {
                        rules: [
                          {
                            required: true,
                            type: "number",
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData?detailData.XC : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>取芯率</span>}>
                      {getFieldDecorator("ZT", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData?detailData.ZT : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<p>取芯日期</p>}>
                      {getFieldDecorator("QXRQ", {
                        rules: [
                          {
                            type: "object"
                          }
                        ],

                        initialValue: detailData.QXRQ
                          ? moment(detailData.QXRQ)
                          : null
                      })(<DatePicker format="YYYY-MM-DD"></DatePicker>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<p>扫描日期</p>}>
                      {getFieldDecorator("SMRQ", {
                        rules: [
                          {
                            type: "object"
                          }
                        ],
                        initialValue: detailData.SMRQ
                          ? moment(detailData.SMRQ)
                          : null
                      })(<DatePicker format="YYYY-MM-DD"></DatePicker>)}
                    </Form.Item>
                  </div>
                </div>
                {detailData.auth_time !== null ? (
                  <div className="referenceAdd-authours referenceAdd-editors">
                    <div className="authcomment-details">
                      {detailData.authState === 2 ? (
                        <div className="referenceAdd-author authcomment-detail-item">
                          <div className="referenceAdd-author-title authcomment-title">
                            Auth Comment
                          </div>
                          <div className="authcomment-content">
                            {detailData.auth_comment !== ""
                              ? detailData.auth_comment
                              : "No comment"}
                          </div>
                        </div>
                      ) : null}
                      {detailData.authState !== 0 ? (
                        <div className="referenceAdd-author authcomment-detail-item">
                          <div className="referenceAdd-author-title authcomment-title">
                            Auth Time
                          </div>
                          <div className="authcomment-content">
                            {detailData.auth_time
                              ? detailData.auth_time.split("T")[0]
                              : ""}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </Form>
            ) : oceanicType === "coreimage" ? (
              <Form>
                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<span>井号</span>}>
                      {getFieldDecorator("JH", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData.JH || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>回次</span>}>
                      {getFieldDecorator("QXCS", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input",
                            type: "number"
                          }
                        ],
                        initialValue: detailData?detailData.QXCS : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<span>起始深度</span>}>
                      {getFieldDecorator("QSSD", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input",
                            type: "number"
                          }
                        ],
                        initialValue: detailData?detailData.QSSD : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>终止深度</span>}>
                      {getFieldDecorator("ZZSD", {
                        rules: [
                          {
                            required: true,
                            type: "number",
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData?detailData.ZZSD : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<span>井长</span>}>
                      {getFieldDecorator("JC", {
                        rules: [
                          {
                            type: "number",
                            required: true,
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData?detailData.JC : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>岩心长度</span>}>
                      {getFieldDecorator("SMXC", {
                        rules: [
                          {
                            type: "number",
                            required: true,
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData?detailData.SMXC : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<p>CW</p>}>
                      {getFieldDecorator("CW", {
                        // rules: [{ type: "number" }],
                        initialValue: detailData.CW || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<p>外表面</p>}>
                      {getFieldDecorator("WBM", {
                        // rules: [{ type: "number" }],
                        initialValue: detailData.WBM || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                </div>
                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<span>纵切面</span>}>
                      {getFieldDecorator("vertical_section", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData.vertical_section || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>FZJD</span>}>
                      {getFieldDecorator("FZJD", {
                        // rules: [{ type: "number" }],
                        initialValue: detailData.FZJD || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                </div>
                <div className="referenceAdd-upload">
                  <Form.Item label="ZQM" extra="" className="long-label">
                    {getFieldDecorator("ZQM", {
                      // initialValue: this.state.uploadFiles
                    })(
                      <BaseUpload
                    uploadFiles={this.state.uploadFiles}
                    fileList={this.state.uploadFiles}
                      multiple={false}
                      showImg={false}
                      listType="text"
                      showUploadList={true}
                      onChangeFile={file => this.onChangeFile(file)}
                      removeFile={(file, index) => this.removeFile(file, index)}
                    ></BaseUpload>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceAdd-comments">
                  <Form.Item label="备注" className="long-label">
                    {getFieldDecorator("BZ", {
                      rules: [{}],
                      initialValue: detailData.BZ || null
                    })(<TextArea rows={6}></TextArea>)}
                  </Form.Item>
                </div>
                {detailData.auth_time !== null ? (
                  <div className="referenceAdd-authours referenceAdd-editors">
                    <div className="authcomment-details">
                      {detailData.authState === 2 ? (
                        <div className="referenceAdd-author authcomment-detail-item">
                          <div className="referenceAdd-author-title authcomment-title">
                            Auth Comment
                          </div>
                          <div className="authcomment-content">
                            {detailData.auth_comment !== ""
                              ? detailData.auth_comment
                              : "No comment"}
                          </div>
                        </div>
                      ) : null}
                      {detailData.authState !== 0 ? (
                        <div className="referenceAdd-author authcomment-detail-item">
                          <div className="referenceAdd-author-title authcomment-title">
                            Auth Time
                          </div>
                          <div className="authcomment-content">
                            {detailData.auth_time
                              ? detailData.auth_time.split("T")[0]
                              : ""}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </Form>
            ) : oceanicType === "lithology_section" ? (
              <Form>
                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<span>井号</span>}>
                      {getFieldDecorator("JH", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData.JH || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>回次</span>}>
                      {getFieldDecorator("QXCS", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input",
                            type: "number"
                          }
                        ],
                        initialValue: detailData?detailData.QXCS : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<span>起始深度</span>}>
                      {getFieldDecorator("QSSD", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input",
                            type: "number"
                          }
                        ],
                        initialValue: detailData?detailData.QSSD : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                  <div className="Reference-number">
                    <Form.Item label={<span>终止深度</span>}>
                      {getFieldDecorator("ZZSD", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input",
                            type: "number"
                          }
                        ],
                        initialValue: detailData?detailData.ZZSD : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<p>HYJB</p>}>
                      {getFieldDecorator("HYJB", {
                        rules: [{}],
                        initialValue: detailData.HYJB || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>岩心</span>}>
                      {getFieldDecorator("YX", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData.YX || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                </div>
                <div className="referenceSearch-numberProject">
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>颜色</span>}>
                      {getFieldDecorator("YS", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData.YS || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                </div>
                {detailData.auth_time !== null ? (
                  <div className="referenceAdd-authours referenceAdd-editors">
                    <div className="authcomment-details">
                      {detailData.authState === 2 ? (
                        <div className="referenceAdd-author authcomment-detail-item">
                          <div className="referenceAdd-author-title authcomment-title">
                            Auth Comment
                          </div>
                          <div className="authcomment-content">
                            {detailData.auth_comment !== ""
                              ? detailData.auth_comment
                              : "No comment"}
                          </div>
                        </div>
                      ) : null}
                      {detailData.authState !== 0 ? (
                        <div className="referenceAdd-author authcomment-detail-item">
                          <div className="referenceAdd-author-title authcomment-title">
                            Auth Time
                          </div>
                          <div className="authcomment-content">
                            {detailData.auth_time
                              ? detailData.auth_time.split("T")[0]
                              : ""}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </Form>
            ) : oceanicType === "lithology_description" ? (
              <Form>
                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<span>井号</span>}>
                      {getFieldDecorator("JH", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input"
                          }
                        ],
                        initialValue: detailData.JH || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>起始深度</span>}>
                      {getFieldDecorator("QSSD", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input",
                            type: "number"
                          }
                        ],
                        initialValue: detailData?detailData.QSSD : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number">
                    <Form.Item label={<span>终止深度</span>}>
                      {getFieldDecorator("ZZSD", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input",
                            type: "number"
                          }
                        ],
                        initialValue:detailData? detailData.ZZSD : null
                      })(<InputNumber></InputNumber>)}
                    </Form.Item>
                  </div>
                  <div className="referenceSearch-no">
                    <Form.Item label={<span>岩心描述</span>}>
                      {getFieldDecorator("YXMS", {
                        rules: [
                          {
                            required: true,
                            message: "wrong input",
                         
                          }
                        ],
                        initialValue: detailData.YXMS || null
                      })(<Input></Input>)}
                    </Form.Item>
                  </div>
                </div>
                {detailData.auth_time !== null ? (
                  <div className="referenceAdd-authours referenceAdd-editors">
                    <div className="authcomment-details">
                      {detailData.authState === 2 ? (
                        <div className="referenceAdd-author authcomment-detail-item">
                          <div className="referenceAdd-author-title authcomment-title">
                            Auth Comment
                          </div>
                          <div className="authcomment-content">
                            {detailData.auth_comment !== ""
                              ? detailData.auth_comment
                              : "No comment"}
                          </div>
                        </div>
                      ) : null}
                      {detailData.authState !== 0 ? (
                        <div className="referenceAdd-author authcomment-detail-item">
                          <div className="referenceAdd-author-title authcomment-title">
                            Auth Time
                          </div>
                          <div className="authcomment-content">
                            {detailData.auth_time
                              ? detailData.auth_time.split("T")[0]
                              : ""}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </Form>
            ) : null}
            {isClick && this.state.onlySee === false ? (
              <div className="referenceSearch-btns sectionBase-btns">
                <div className="formation-complete-btns">
                  <Button type="gbdb" onClick={this.modifyOceanic}>
                   
                      <p>Save modify</p>
                    
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

OceanicDetailForm = Form.create({})(OceanicDetailForm);
export default OceanicDetailForm;
