import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";
import FormationDetailForm from "commons/AllDetails/Section/Formation/index.js";
import UnitDetailForm from "commons/AllDetails/Section/Unit/index.js";
import CollectionDetailForm from "commons/AllDetails/Collection/index.js";
import { Button, Spin, Modal, Input, Form, message } from "antd";
import Utils from "src/js/utils";
import Users from "src/js/users";
import Apis from "src/js/apis/api";
import SubsetApis from "src/js/apis/subset";
import axios from "axios";
import userApis from "src/js/apis/user";
import "./index.css";
import "src/js/global.js";
import Storage from "src/js/storage";
import TaxonViewPort from "commons/AllTaxonViewport/index.js";
import TaxonomyTableResult from "commons/AllTableList/Taxonomy/index.js";
import TaxonomyDetailForm from "commons/AllDetails/Taxonomy/index.js";
import InfiniteScroll from "react-infinite-scroller";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";

// 列表显示
//  reference
let referenceHasShow = referenceThis => {
  let that = referenceThis;
  let {
    referenceData,
    referenceActive,
    currentReferenceId,
    referencePage,
    referenceLoading,
    referenceHasMore
  } = that.state;

  return (
    <div className="search-infinite-container">
      <InfiniteScroll
        initialLoad={false}
        pageStart={referencePage}
        loadMore={that.handleInfiniteOnLoad}
        hasMore={!referenceLoading && referenceHasMore}
        useWindow={false}
      >
        {referenceData.map((item, index) => {
          return (
            <div key={item.ref_id}>
              <div
                className="referennce-result-itemTitle"
                onClick={e => that.referenceCallback(item.ref_id, e)}
              >
                <span className="search-result-index">{index + 1}</span>
                <span className="search-author">
                  {currentReferenceId === item.ref_id && referenceActive ? (
                    <span className="iconfont icon-caret-down"></span>
                  ) : (
                      <span className="iconfont icon-caret-right"></span>
                    )}

                  <span className="search-item-property search-all-property">
                    Reference:
                  </span>
                  <span className="search-item-detail">{item.ref_id}</span>
                </span>

                <span
                  title="view details"
                  className="iconfont icon-xiangqing"
                  onClick={e => that.showReferenceDetail(item, e)}
                ></span>
                <span
                  title={
                    that.state.isInputer === true
                      ? "add new reference"
                      : "sorry!!You do not have permission to add data"
                  }
                  className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                  onClick={e =>
                    that.addNewReference(item, e)
                  }
                ></span>
              </div>
              <div className="search-result-item">
                <div className="search-item-detail">
                  <p>
                    <span className="search-author">
                      <span className="search-item-property">
                        First author：
                      </span>
                      {item.ref_first_author}
                    </span>
                  </p>
                  <p>
                    <span className="search-author">
                      <span className="search-item-property">
                        Other authors：
                      </span>
                      {item.ref_authors ? item.ref_authors.indexOf(',') !== -1 ? item.ref_authors.substring(item.ref_authors.indexOf(',') + 1) : '' : ''}
                    </span>
                  </p>
                  <p>
                    <span className="search-title" title={item.ref_title}>
                      <span className="search-item-property">Title：</span>
                      {item.ref_title}
                    </span>
                  </p>
                  <p>
                    <span className="search-book">
                      <span className="search-item-property">
                        The title of a magazine or book：
                      </span>
                      {item.ref_journal_booktitle}
                    </span>
                  </p>
                  <p>
                    <span className="search-rollup">
                      <span className="search-item-property">Volume no：</span>
                      {item.ref_Journal_volume}
                    </span>
                    <span className="search-page-begin">
                      <span className="search-item-property">
                        Page Number：
                      </span>
                      {item.ref_page_number_begin}
                    </span>
                    <span>~</span>
                    <span className="">{item.ref_page_number_end}</span>
                  </p>
                  <p>
                    <span className="search-enterer">
                      <span className="search-item-property">Enterer：</span>
                      {item.enterer_username}
                    </span>
                  </p>
                  <p>
                    <span className="search-author">
                      <span className="search-item-property">
                        Authorize by：
                      </span>
                      {item.authorizer}
                    </span>
                  </p>
                </div>
              </div>
              {that.state.showSection &&
                that.state.sectionData.length > 0 &&
                item.ref_id === that.state.currentReferenceId
                ? sectionHasShow(that)
                : that.state.showSection &&
                  that.state.sectionData.length === 0 &&
                  item.ref_id === that.state.currentReferenceId
                  ? sectionNoShow(that)
                  : null}

              {referenceLoading && referenceHasMore && (
                <div className="demo-loading-container">
                  <Spin />
                </div>
              )}
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

let referenceNoShow = referenceThis => {
  let that = referenceThis;
  return (
    <div className="search-no-resource referennce-section-itemTitle">
      <span className="search-item-property search-all-property">
        {that.state.referenceLoading
          ? "Reference:loading..."
          : " Reference:null"}
      </span>
    </div>
  );
};
let sectionHasShow = sectionThis => {
  let that = sectionThis;
  let {
    currentSectionId,
    sectionData,
    sectionPage,
    sectionActive,
    sectionLoading,
    sectionHasMore
  } = that.state;

  return (
    <div className="search-results-section search-results-parts">
      <InfiniteScroll
        initialLoad={false}
        pageStart={sectionPage}
        loadMore={() => that.handleData("section")}
        hasMore={!sectionLoading && sectionHasMore}
        useWindow={false}
      >
        {sectionData.map((sectionItem, index) => {
          let degreeLat = 0;
          let minuteLat = 0;
          let secondLat = 0;
          let degreeLng = 0;
          let minuteLng = 0;
          let secondLng = 0;
          let directionLat = "";
          let directionLng = "";

          if (sectionItem.geology_latitude_decimal) {
            let a = sectionItem.geology_latitude_decimal;
            degreeLat = parseInt(a);
            minuteLat = parseInt((a - degreeLat) * 60);
            secondLat = parseFloat(
              (a - degreeLat) * 3600 - minuteLat * 60
            ).toFixed(0);
          }
          if (sectionItem.geology_longitude_decimal) {
            let a = sectionItem.geology_longitude_decimal;
            degreeLng = parseInt(a);
            minuteLng = parseInt((a - degreeLng) * 60);
            secondLng = parseFloat(
              (a - degreeLng) * 3600 - minuteLng * 60
            ).toFixed(0);
          }
          if (sectionItem.geology_latitude_direction) {
            directionLat = sectionItem.geology_latitude_direction[0];
          }
          if (sectionItem.geology_longitude_direction) {
            directionLng = sectionItem.geology_longitude_direction[0];
          }

          return (
            <div key={sectionItem.section_basic_id}>
              <div
                className="referennce-result-itemTitle referennce-section-itemTitle"
                onClick={e =>
                  that.sectionCallback(sectionItem.section_basic_id, e)
                }
              >
                {currentSectionId === sectionItem.section_basic_id &&
                  sectionActive ? (
                    <span className="iconfont icon-caret-down"></span>
                  ) : (
                    <span className="iconfont icon-caret-right"></span>
                  )}
                <span className="search-author">
                  <span className="search-item-property search-all-property">
                    Section ID:
                  </span>

                  <span>{sectionItem.section_basic_id}</span>
                  <span>{sectionItem.section_name}</span>
                  <span className="search-formation-thicksign">
                    {`${degreeLat}°${Math.abs(minuteLat)}'${Math.abs(secondLat)}"${directionLat}`}
                  </span>
                  <span className="search-formation-thicksign">
                    {`${degreeLng}°${Math.abs(minuteLng)}'${Math.abs(secondLng)}"${directionLng}`}
                  </span>
                  <span
                    title="view details"
                    className="iconfont icon-xiangqing"
                    onClick={e => that.showSectionDetail(sectionItem, e)}
                  ></span>
                  {/* <span
                    title="see unit"
                    className="iconfont icon-special"
                    onClick={e =>
                      that.sectionSeeUnitCallback(
                        sectionItem.section_basic_id,
                        e
                      )
                    }
                  ></span> */}
                  <span
                    title={
                      that.state.isInputer === true
                        ? "add new section"
                        : "sorry!!You do not have permission to add data"
                    }

                    className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                    onClick={e => that.addNewSection(e)}
                  ></span>
                </span>
              </div>

              {that.state.showFormation &&
                that.state.formationData.length > 0 &&
                sectionItem.section_basic_id === that.state.currentSectionId
                ? formationHasShow(that)
                : that.state.showFormation &&
                  that.state.formationData.length === 0 &&
                  sectionItem.section_basic_id === that.state.currentSectionId
                  ? formationNoShow(that)
                  : null}
              {that.state.showUnit &&
                that.state.unitData.length > 0 &&
                that.state.sectionSeeUnitActive &&
                sectionItem.section_basic_id === that.state.currentSectionId
                ? UnitHasShow(that)
                : that.state.showUnit &&
                  that.state.unitData.length === 0 &&
                  that.state.sectionSeeUnitActive &&
                  sectionItem.section_basic_id === that.state.currentSectionId
                  ? UnitNoShow(that)
                  : null}
              {sectionLoading && sectionHasMore && (
                <div className="demo-loading-container">
                  <Spin />
                </div>
              )}
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};
let sectionNoShow = sectionThis => {
  let that = sectionThis;
  return (
    <div className="search-no-resource referennce-section-itemTitle">
      <span className="search-item-property search-all-property">
        {that.state.sectionLoading ? " Section:loading..." : "Section:null"}
      </span>
      <span
        title={
          that.state.isInputer === true
            ? "add new section"
            : "sorry!!You do not have permission to add data"
        }
        className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
        onClick={e => that.addNewSection(e)}
      ></span>
    </div>
  );
};
let formationHasShow = formationThis => {
  let that = formationThis;
  let {
    formationPage,
    formationData,
    currentFormationId,
    formationActive,
    formationLoading,
    formationHasMore
  } = that.state;
  return (
    <div className="search-results-section search-results-parts">
      <InfiniteScroll
        initialLoad={false}
        pageStart={formationPage}
        loadMore={() => that.handleData("formation")}
        hasMore={!formationLoading && formationHasMore}
        useWindow={false}
      >
        {formationData.map((fitem, index) => {
          let gdegreeLat = 0;
          let gminuteLat = 0;
          let gsecondLat = 0;
          let gdegreeLng = 0;
          let gminuteLng = 0;
          let gsecondLng = 0;

          if (fitem.geo_latitude_decimal) {
            let a = fitem.geo_latitude_decimal;
            gdegreeLat = parseInt(a);
            gminuteLat = parseInt((a - gdegreeLat) * 60);
            gsecondLat = parseFloat(
              (a - gdegreeLat) * 3600 - gminuteLat * 60
            ).toFixed(0);
          }
          if (fitem.geo_longitude_decimal) {
            let a = fitem.geo_longitude_decimal;
            gdegreeLng = parseInt(a);
            gminuteLng = parseInt((a - gdegreeLng) * 60);
            gsecondLng = parseFloat(
              (a - gdegreeLng) * 3600 - gminuteLng * 60
            ).toFixed(0);
          }

          return (
            <div key={fitem.formation_id}>
              <div
                className="referennce-result-itemTitle referennce-formation-itemTitle"
                onClick={e => that.formationCallback(fitem.formation_id, e)}
              >
                {currentFormationId === fitem.formation_id &&
                  formationActive ? (
                    <span className="iconfont icon-caret-down"></span>
                  ) : (
                    <span className="iconfont icon-caret-right"></span>
                  )}
                <span className="search-author">
                  <span className="search-item-property search-all-property">
                    Formation ID:
                  </span>
                </span>

                <span>{fitem.formation_id}</span>
                <span className="">
                  {fitem.formation_name ? `${fitem.formation_name}` : ""}
                </span>
                <span className="search-formation-thicksign">
                  {fitem.formation_thick_sign}
                </span>
                <span className="search-formation-thick">
                  {fitem.formation_thick}
                </span>
                <span className="search-formation-thickunit">
                  {fitem.formation_thick_unit}
                </span>
                <span className="search-formation-thickunit">
                  {`${gdegreeLat}°${Math.abs(gminuteLat)}'${Math.abs(gsecondLat)}"`}
                </span>
                <span className="search-formation-thickunit">
                  {`${gdegreeLng}°${Math.abs(gminuteLng)}'${Math.abs(gsecondLng)}"`}
                </span>
                <span
                  title="view details"
                  className="iconfont icon-xiangqing"
                  onClick={e => that.showFormationDetail(fitem, e)}
                ></span>
                <span
                  title={
                    that.state.isInputer === true
                      ? "add new formation"
                      : "sorry!!You do not have permission to add data"
                  }
                  className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                  onClick={e => that.addNewFormation(e)}
                ></span>
              </div>
              {that.state.showUnit &&
                that.state.unitData.length > 0 &&
                !that.state.sectionSeeUnitActive &&
                fitem.formation_id === that.state.currentFormationId
                ? UnitHasShow(that)
                : that.state.showUnit &&
                  !that.state.sectionSeeUnitActive &&
                  that.state.unitData.length === 0 &&
                  fitem.formation_id === that.state.currentFormationId
                  ? UnitNoShow(that)
                  : null}
              {formationLoading && formationHasMore && (
                <div className="demo-loading-container">
                  <Spin />
                </div>
              )}
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};
let formationNoShow = formationThis => {
  let that = formationThis;
  return (
    <div className="search-no-resource referennce-formation-itemTitle">
      <span className="search-item-property search-all-property">
        {that.state.formationLoading
          ? "Formation:loading..."
          : " Formation:null"}
      </span>
      <span
        title={
          that.state.isInputer === true
            ? "add new formation"
            : "sorry!!You do not have permission to add data"
        }
        className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
        onClick={e => that.addNewFormation(e)}
      ></span>
    </div>
  );
};

let UnitHasShow = unitThis => {
  let that = unitThis;
  let {
    unitPage,
    unitData,
    currentUnitId,
    unitActive,
    unitHasMore,
    unitLoading,
    sectionSeeUnitActive
  } = that.state;

  return (
    <div className="search-units search-results-parts">
      <InfiniteScroll
        initialLoad={false}
        pageStart={unitPage}
        loadMore={() => that.handleData("unit")}
        hasMore={!unitLoading && unitHasMore}
        useWindow={false}
      >
        {unitData.map((unitItem, index) => {
          return (
            <div key={unitItem.unit_id}>
              <div
                className="referennce-result-itemTitle referennce-unit-itemTitle"
                onClick={e => that.unitCallbak(unitItem.unit_id, e)}
              >
                {currentUnitId === unitItem.unit_id && unitActive ? (
                  <span className="iconfont icon-caret-down"></span>
                ) : (
                    <span className="iconfont icon-caret-right"></span>
                  )}
                <span className="search-author">
                  <span className="search-item-property search-all-property">
                    Unit ID:
                  </span>
                  {unitItem.unit_id}
                </span>
                <span>{unitItem.unit_name}</span>
                <span>{unitItem.unit_color1a}</span>
                <span>{unitItem.unit_lithology1a}</span>

                <span className="search-formation-thicksign">
                  {unitItem.unit_thickness_sign}
                </span>
                <span className="search-formation-thick">
                  {unitItem.unit_thickness}
                </span>
                <span className="search-formation-thickunit">
                  {unitItem.unit_thickness_unit}
                </span>
                <span
                  title="view details"
                  className="iconfont icon-xiangqing"
                  onClick={e => that.showUnitDetail(unitItem, e)}
                ></span>
                <span
                  title={
                    that.state.isInputer === true
                      ? "add new unit"
                      : "sorry!!You do not have permission to add data"
                  }
                  className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                  onClick={e => that.addNewUnit(e)}
                ></span>
              </div>
              {that.state.showCollection &&
                that.state.collectionData.length > 0 &&
                unitItem.unit_id === that.state.currentUnitId
                ? collectionHasShow(that)
                : that.state.showCollection &&
                  that.state.collectionData.length === 0 &&
                  unitItem.unit_id === that.state.currentUnitId
                  ? collectionNoShow(that)
                  : null}
              {unitLoading && unitHasMore && (
                <div className="demo-loading-container">
                  <Spin />
                </div>
              )}
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};
let UnitNoShow = unitThis => {
  let that = unitThis;

  return (
    <div className="search-no-resource referennce-unit-itemTitle">
      <span className="search-item-property search-all-property">
        {that.state.unitLoading ? "Unit:loading..." : "Unit:null"}
      </span>
      <span
        title={
          that.state.isInputer === true
            ? "add new unit"
            : "sorry!!You do not have permission to add data"
        }
        className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
        onClick={e => that.addNewUnit(e)}
      ></span>
    </div>
  );
};
let collectionHasShow = collectionThis => {
  let that = collectionThis;
  let {
    unitPage,
    collectionData,
    currentCollectionId,
    collectionActive,
    collectionLoading,
    collectionHasMore
  } = that.state;
  return (
    <div className="search-units search-results-parts">
      <InfiniteScroll
        initialLoad={false}
        pageStart={unitPage}
        loadMore={() => that.handleData("collection")}
        hasMore={!collectionLoading && collectionHasMore}
        useWindow={false}
      >
        {collectionData.map((cItem, index) => {
          return (
            <div key={cItem.coll_id}>
              <div
                className="referennce-result-itemTitle referennce-collection-itemTitle"
                onClick={e => that.CollectionCallback(cItem.coll_id, e)}
              >
                {currentCollectionId === cItem.coll_id && collectionActive ? (
                  <span className="iconfont icon-caret-down"></span>
                ) : (
                    <span className="iconfont icon-caret-right"></span>
                  )}
                <span className="search-author">
                  <span className="search-item-property search-all-property">
                    Collection ID:
                  </span>
                  <span>{cItem.coll_id}</span>
                  <span>{cItem.coll_no}</span>
                </span>

                <span
                  title="view details"
                  className="iconfont icon-xiangqing"
                  onClick={e => that.showCollectionDetail(cItem, e)}
                ></span>
                <span
                  title={
                    that.state.isInputer === true
                      ? "add new collection"
                      : "sorry!!You do not have permission to add data"
                  }
                  className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                  onClick={e => that.addNewCollection(e)}
                ></span>
                {Storage.getItem("gbdbid") ? (
                  <span
                    title={
                      that.state.isInputer === true
                        ? "add to subset"
                        : "sorry!!You do not have permission to collect"
                    }
                    className={`iconfont icon-shoucang ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                    onClick={e => that.addcollectionSubset(cItem, e)}
                  ></span>
                ) : null}
              </div>
              {that.state.showFossil &&
                that.state.fossilData.length > 0 &&
                cItem.coll_id === that.state.currentCollectionId
                ? fossilHasShow(that)
                : that.state.showFossil &&
                  that.state.fossilData.length === 0 &&
                  cItem.coll_id === that.state.currentCollectionId
                  ? fossilNoShow(that)
                  : null}

              {collectionLoading && collectionHasMore && (
                <div className="demo-loading-container">
                  <Spin />
                </div>
              )}
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};
let collectionNoShow = collectionThis => {
  let that = collectionThis;
  return (
    <div className="search-no-resource referennce-collection-itemTitle">
      <span className="search-item-property search-all-property">
        {that.state.collectionLoading
          ? "Collection:loading..."
          : "Collection:null"}
      </span>
      <span
        title={
          that.state.isInputer === true
            ? "add new collection"
            : "sorry!!You do not have permission to add data"
        }
        className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
        onClick={e => that.addNewCollection(e)}
      ></span>
    </div>
  );
};
let fossilHasShow = fossilThis => {
  let that = fossilThis;
  let { fossilPage, fossilData, fossilLoading, fossilHasMore } = that.state;
  return (
    <div className="search-results-section search-results-parts">
      <InfiniteScroll
        initialLoad={false}
        pageStart={fossilPage}
        loadMore={() => that.handleData("fossil")}
        hasMore={!fossilLoading && fossilHasMore}
        useWindow={false}
      >
        {fossilData.map((fossilItem, index) => {
          let taxonName1 = fossilItem.taxon_name1;
          let taxonName2 = fossilItem.taxon_name2;
          let genusPrefix = fossilItem.genus_prefix;
          let spPrefix1 = fossilItem.sp_prefix1;
          let spPrefix2 = fossilItem.sp_prefix2;
          let spNameDict = fossilItem.sp_name_dict;
          let spSuffix = fossilItem.sp_suffix;
          let Name = Utils.fullName(
            taxonName1,
            taxonName2,
            genusPrefix,
            spPrefix1,
            spPrefix2,
            spNameDict,
            spSuffix
          );
          let { currentFossilId, fossilActive } = that.state;

          return (
            <div key={fossilItem.id}>
              <div
                className="referennce-result-itemTitle referennce-fossil-itemTitle"
                onClick={e => that.FossilCallback(fossilItem.id, e)}
              >
                {currentFossilId === fossilItem.id && fossilActive ? (
                  <span className="iconfont icon-caret-down"></span>
                ) : (
                    <span className="iconfont icon-caret-right"></span>
                  )}
                <span className="search-author">
                  <span className="search-item-property search-all-property">
                    Fossil:
                  </span>
                  <span>{fossilItem.id}</span>
                  <span onClick={e => that.showFossilRef(fossilItem.ref_id, e)}>
                    {
                      fossilItem.ref_id ? `(ref:${fossilItem.ref_id})` : ''
                    }
                  </span>
                  {/* <span
                    className="iconfont icon-plus1"
                    onClick={e => that.showOptionModal(fossilItem, e)}
                  ></span> */}
                  <span className="search-fossils-title">
                    {fossilItem.informal_name ? fossilItem.informal_name : Name}
                  </span>
                  <span className="search-fossils-title">
                    {fossilItem.fossil_group
                      ? `[${fossilItem.fossil_group}]`
                      : ""}
                  </span>
                  <span className="search-fossils-title" title={fossilItem.comment}>
                    {fossilItem.comment
                      ? `(${fossilItem.comment})`
                      : ""}
                  </span>
                </span>
                <span
                  title={
                    that.state.isInputer === true
                      ? "add new fossil"
                      : "sorry!!You do not have permission to add data"
                  }
                  className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                  onClick={e => that.addNewFossil(e)}
                ></span>
                <span
                  title={
                    that.state.isInputer === true
                      ? "add new fossilOption"
                      : "sorry!!You do not have permission to add data"
                  }
                  className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
                  onClick={e => that.showOptionModal(fossilItem, e)}
                ></span>

                <span
                  title="view taxonomy"
                  className="iconfont icon-xiangqing"
                  onClick={e => that.showTaxonomyDetail(fossilItem.taxonmixed_id, e)}
                ></span>
                <span
                  title="view taxonomy parent"
                  className={`iconfont icon-parent-fill ${fossilItem.parentCount===0?"parent-noresource":''}`}
                  onClick={e => that.showTaxparentDetail(fossilItem.taxonmixed_id,fossilItem.parentCount, e)}
                ></span>
                <span
                  title="view taxonomy siblings"
                  className={`iconfont icon-list-alt ${fossilItem.parentCount===0?"parent-noresource":''}`}
                  onClick={e => that.showTaxonomySiblings(fossilItem.taxonmixed_id,fossilItem.parentCount, e)}
                ></span>
              </div>
              {that.state.showfossilOption &&
                (that.state.fossilOptionData.length > 0 ||
                  that.state.newViewPortsArray.length > 0) &&
                fossilItem.id === that.state.currentFossilId
                ? fossilOptionHasShow(that, fossilItem)
                : that.state.showfossilOption &&
                  that.state.fossilOptionData.length === 0 &&
                  that.state.newViewPortsArray.length == 0 &&
                  fossilItem.id === that.state.currentFossilId
                  ? fossilOptionNoShow(that)
                  : null}
              {fossilLoading && fossilHasMore && (
                <div className="demo-loading-container">
                  <Spin />
                </div>
              )}
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};
let fossilNoShow = fossilThis => {
  let that = fossilThis;
  return (
    <div className="search-no-resource referennce-fossil-itemTitle">
      <span className="search-item-property search-all-property">
        {that.state.fossilLoading ? "Fossil:loading..." : "Fossil:null"}
      </span>
      <span
        title={
          that.state.isInputer === true
            ? "add new fossil"
            : "sorry!!You do not have permission to add data"
        }
        className={`iconfont icon-tianjia ${that.state.isInputer === true ? "" : "icon-tianjia-disabled"}`}
        onClick={e => that.addNewFossil(e)}
      ></span>
    </div>
  );
};
let fossilOptionHasShow = (fossilOptionThis, fossilItem) => {
  let that = fossilOptionThis;
  let {
    fossilOptionPage,
    fossilOptionData,
    newViewPortsArray,
    fossilOptionLoading,
    fossilOptionHasMore
  } = that.state;

  return (
    <div className="search-results-section search-results-parts">
      <InfiniteScroll
        initialLoad={false}
        pageStart={fossilOptionPage}
        loadMore={() => that.handleData("fossilOption")}
        hasMore={!fossilOptionLoading && fossilOptionHasMore}
        useWindow={false}
      >
        {fossilOptionData.map((fossilOptionItem, index) => {
          // let taxonName1 = fossilOptionItem.taxon_name1;
          // let taxonName2 = fossilOptionItem.taxon_name2;
          // let genusPrefix = fossilOptionItem.genus_prefix;
          // let spPrefix1 = fossilOptionItem.sp_prefix1;
          // let spPrefix2 = fossilOptionItem.sp_prefix2;
          // let spNameDict = fossilOptionItem.sp_name_dict;
          // let spSuffix = fossilOptionItem.sp_suffix;
          // let Name = Utils.fullName(
          //   taxonName1,
          //   taxonName2,
          //   genusPrefix,
          //   spPrefix1,
          //   spPrefix2,
          //   spNameDict,
          //   spSuffix
          // );
          return (
            // <div
            //   key={fossilOptionItem.id}
            //
            // ></div>

            <div
              className="referennce-result-itemTitle referennce-fossilOption-itemTitle"
              key={"old" + index}
            >
              <span className="search-author">
                <span className="search-item-property search-all-property">

                  Option{`${index + 1}`}:
                </span>
                <span className="option-refid" >
                  {
                    fossilOptionItem.id ? fossilOptionItem.id : ''
                  }
                </span>
                <span className="option-refid" onClick={e => that.showFossilRef(fossilOptionItem.ref_id, e)}>
                  {
                    fossilOptionItem.ref_id ? `(ref:${fossilOptionItem.ref_id})` : ''
                  }
                </span>

                <span
                  className="search-fossils-title"
                  title={
                    fossilOptionItem.fullfossil_name !== null
                      ? fossilOptionItem.fullfossil_name
                      : fossilOptionItem.informal_name !== null
                        ? fossilOptionItem.informal_name
                        : ""
                  }
                >
                  {fossilOptionItem.fullfossil_name !== null
                    ? fossilOptionItem.fullfossil_name
                    : ""}
                  {fossilOptionItem.informal_name !== null
                    ? `${fossilOptionItem.informal_name}(informal name)`
                    : ""}

                </span>
                <span className="search-fossils-title" title={fossilOptionItem.comment}>
                {(fossilOptionItem.comment !== null&&fossilOptionItem.comment !== "")
                    ? `(${fossilOptionItem.comment})`
                    : ""}
                </span>
              </span>
            </div>
          );
        })}
        {newViewPortsArray.length > 0 ? (
          <div className="referennce-result-itemTitle referennce-fossilOption-itemTitle">
            {newViewPortsArray.map((item, index) => {
              return (
                <div className="new-fossil" key={"new" + index}>
                  <span className="search-author">
                    <span className="search-item-property search-all-property">

                      Option{`(${index + 1})`}:
                    </span>

                    <span className="search-fossils-title">
                      {that.state.nameType !== "informal"
                        ? item.fullfossil_name
                        : item.informal_name}
                      {/* {`[${item.fossil_group?item.fossil_group:''}]`} */}
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
        ) : null}
        {fossilOptionLoading && fossilOptionHasMore && (
          <div className="demo-loading-container">
            <Spin />
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

let fossilOptionNoShow = fossilOptionThis => {
  let that = fossilOptionThis;
  return (
    <div className="search-no-resource referennce-fossilOption-itemTitle">
      <span className="search-item-property search-all-property">
        {that.state.fossilOptionLoading ? "Option:loading..." : "Option:null"}
      </span>
    </div>
  );
};

class ReferenceTableResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taxonOpModal: false,
      addTaxonModal: false,
      resultModal: false,
      currentData: {},
      currentType: "reference",
      currentId: "",
      classifyType: "reference",
      pageSize: 20,
      // ref
      referencePage: 1,
      referenceTotal: 0,
      referenceData: [],
      currentReferenceId: "",
      referenceLoading: false,
      referenceHasMore: true,
      referenceActive: false,
      //  SECTION
      sectionPage: 1,
      sectionData: [],
      showSection: false,
      sectionLoading: false,
      sectionHasMore: true,
      sectionTotal: 0,
      currentSectionId: "",
      currentgeographyId: "",
      sectionType: "baseinfo",
      sectionActive: false,
      // formation
      formationPage: 1,
      formationData: [],
      showFormation: false,
      formationLoading: false,
      formationHasMore: true,
      formationTotal: 0,
      currentFormationId: "",
      formationActive: false,

      //

      // unit
      unitPage: 1,
      unitData: [],
      showUnit: false,
      unitLoading: false,
      unitHasMore: true,
      unitTotal: 0,
      currentUnitId: "",
      sectionSeeUnitActive: false,
      unitActive: false,
      // collection
      collectionPage: 1,
      collectionData: [],
      showCollection: false,
      collectionLoading: false,
      collectionHasMore: true,
      collectionTotal: 0,
      currentCollectionId: "",
      collectionActive: false,
      // fossil

      fossilPage: 1,
      fossilData: [],
      showFossil: false,
      fossilLoading: false,
      fossilHasMore: true,
      fossilTotal: 0,
      currentFossilId: "",
      fossilActive: false,
      currentFossilItem: {},
      // fossilOption

      fossilOptionPage: 1,
      fossilOptionData: [],
      showfossilOption: false,
      fossilOptionLoading: false,
      fossilOptionHasMore: true,
      fossilOptionTotal: 0,
      currentfossilOptionId: "",
      nameType: "formal",
      fossilOptionActive: false,
      // 添加观点
      showSearchTaxon: false,
      newViewPortsObj: {},
      oldViewPortsObj: {},
      newViewPortsArray: [],

      // 当前操作数据
      currentOperateData: {},

      // 搜索条件
      refSerachConditions: {},
      sectionSerachConditions: {},
      formationSerachConditions: {},
      unitSerachConditions: {},
      sectionUnitSerachConditions: {},
      collectionSerachConditions: {},
      fossillistSerachConditions: {},
      fossilOptionSerachConditions: {},

      // 是否可以输入
      isInputer: false,
      showBackIcon: false
    };
  }
  isGbdbisInputer = async () => {
    let isInputer = false;

    isInputer = await Users.isGbdbInputer();

    this.setState({
      isInputer
    });
  };
  componentWillReceiveProps(nextProps) {
    

  }
  async componentDidMount() {

    this.isGbdbisInputer();
    if (this.props.location.query) {
      let { refSerachConditions } = this.state;
      let queryParams = {};
      refSerachConditions = this.props.location.query.searchParams;
      Object.assign(queryParams, refSerachConditions);
      this.setState({
        queryParams,
        refSerachConditions
      });
      this.showReferenceData(queryParams);
    } else if (this.props.location.search) {
      let inheritRefIds =
        Utils.getSearch(this.props.location.search, "refId") || "";
      let baseId = Utils.getSearch(this.props.location.search, "baseId") || "";
      let formationId =
        Utils.getSearch(this.props.location.search, "formationId") || "";
      let unitId = Utils.getSearch(this.props.location.search, "unitId") || "";
      let collId = Utils.getSearch(this.props.location.search, "collId") || "";
      let fossilId =
        Utils.getSearch(this.props.location.search, "fossilId") || "";
      if (inheritRefIds) {
        inheritRefIds = Number(inheritRefIds);

        let queryParams = {};
        this.setState(
          {
            currentReferenceId: inheritRefIds,
            refSerachConditions: { ref_id: inheritRefIds }
          },
          async () => {
            let { refSerachConditions } = this.state;
            Object.assign(queryParams, refSerachConditions);
            await this.showReferenceData(queryParams);
          }
        );
      }
      if (baseId) {
        let { sectionSerachConditions } = this.state;
        baseId = Number(baseId);
        this.setState(
          {
            showSection: true,
            currentSectionId: baseId
          },
          async () => {
            let searchParams = {};
            sectionSerachConditions = {
              // ref_id: inheritRefIds,
              section_basic_id: baseId
            };
            this.setState({
              sectionSerachConditions,
              referenceActive: true
            });
            Object.assign(searchParams, sectionSerachConditions);
            await this.showSectionData(searchParams);
            if (formationId !== "") {
              formationId = Number(formationId);
              let { formationSerachConditions } = this.state;
              let searchParams = {};
              formationSerachConditions = {
                // ref_id: inheritRefIds,
                // section_basic_id: baseId,
                formation_id: formationId
              };
              this.setState(
                {
                  showFormation: true,
                  showUnit: false,
                  sectionSeeUnitActive: false,
                  currentFormationId: formationId,
                  formationSerachConditions,

                  sectionActive: true
                },
                async () => {
                  let { formationSerachConditions } = this.state;
                  Object.assign(searchParams, formationSerachConditions);
                  await this.showFormationData(searchParams);
                  if (unitId !== "") {
                    unitId = Number(unitId);
                    let searchParams = {};
                    let {
                      unitSerachConditions,
                      sectionUnitSerachConditions
                    } = this.state;
                    unitSerachConditions = {
                      // ref_id: inheritRefIds,
                      // section_basic_id: baseId,
                      // formation_id: formationId,
                      unit_id: unitId
                    };
                    sectionUnitSerachConditions = {
                      // ref_id: inheritRefIds,
                      // section_basic_id: baseId,
                      unit_id: unitId
                    };
                    this.setState(
                      {
                        showFormation: true,
                        showUnit: true,
                        currentUnitId: unitId,
                        sectionSeeUnitActive: false,
                        unitSerachConditions,
                        sectionUnitSerachConditions,
                        formationActive: true
                      },
                      async () => {
                        let { unitSerachConditions } = this.state;
                        Object.assign(searchParams, unitSerachConditions);
                        await this.showUnitData(searchParams);
                        if (collId !== "") {
                          collId = Number(collId);
                          let searchParams = {};
                          let { collectionSerachConditions } = this.state;
                          collectionSerachConditions = {
                            // ref_id: inheritRefIds,
                            coll_id: collId
                          };
                          this.setState(
                            {
                              showCollection: true,
                              currentCollectionId: collId,

                              collectionSerachConditions,

                              unitActive: true
                            },
                            async () => {
                              let { collectionSerachConditions } = this.state;
                              Object.assign(
                                searchParams,
                                collectionSerachConditions
                              );

                              await this.showCollectionData(searchParams);

                              if (fossilId !== "") {
                                fossilId = Number(fossilId);
                                let searchParams = {};
                                let {
                                  fossilOptionSerachConditions
                                } = this.state;
                                fossilOptionSerachConditions = {
                                  id: Number(fossilId)
                                  // collection_id: collId
                                };
                                this.setState(
                                  {
                                    fossilOptionSerachConditions,
                                    showFossil: true,
                                    currentFossilId: fossilId,
                                    currentCollectionId: collId,
                                    collectionActive: true
                                  },
                                  async () => {
                                    let {
                                      fossilOptionSerachConditions
                                    } = this.state;
                                    Object.assign(
                                      searchParams,
                                      fossilOptionSerachConditions
                                    );

                                    await this.showFossilData(searchParams);
                                    setTimeout(async () => {

                                      let allParams = {};
                                      let pageParams = {
                                        pageSize: 1000,
                                        page: 1
                                      };
                                      let params = {
                                        fossillist_id: Number(fossilId)
                                      }
                                      Object.assign(allParams, pageParams, params);
                                      await this.showFossilOptionData(allParams)
                                      this.setState({
                                        showfossilOption: true,
                                        fossilActive: true
                                      })
                                    }, 20)
                                  }
                                );
                              }
                            }
                          );
                        }
                      }
                    );
                  }
                }
              );
            }
            if (formationId === "" && unitId) {
              let { sectionUnitSerachConditions } = this.state;
              unitId = Number(unitId);
              let searchParams = {};
              sectionUnitSerachConditions = {
                // ref_id: inheritRefIds,
                // section_basic_id: baseId,
                unit_id: unitId
              };
              this.setState(
                {
                  showFormation: false,
                  showUnit: true,
                  currentUnitId: unitId,
                  referenceActive: true,
                  sectionUnitSerachConditions
                },
                async () => {
                  let { sectionUnitSerachConditions } = this.state;
                  Object.assign(searchParams, sectionUnitSerachConditions);
                  await this.sectionSeeUnit(searchParams);
                  if (collId !== "") {
                    collId = Number(collId);
                    let searchParams = {};
                    let { collectionSerachConditions } = this.state;
                    collectionSerachConditions = {
                      // ref_id: inheritRefIds,
                      coll_id: collId
                    };
                    this.setState(
                      {
                        showCollection: true,
                        currentCollectionId: collId,

                        collectionSerachConditions,

                        sectionSeeUnitActive: true
                      },
                      async () => {
                        let { collectionSerachConditions } = this.state;
                        Object.assign(searchParams, collectionSerachConditions);

                        await this.showCollectionData(searchParams);
                        if (fossilId !== "") {
                          fossilId = Number(fossilId);
                          let searchParams = {};
                          let { fossilOptionSerachConditions } = this.state;
                          fossilOptionSerachConditions = {
                            // collection_id: collId,
                            id: Number(fossilId)
                          };
                          this.setState(
                            {
                              fossilOptionSerachConditions,
                              showFossil: true,
                              currentFossilId: fossilId,
                              currentCollectionId: collId,
                              collectionActive: true
                            },
                            async () => {
                              let { fossilOptionSerachConditions } = this.state;
                              Object.assign(
                                searchParams,
                                fossilOptionSerachConditions
                              );

                              await this.showFossilData(searchParams);
                              setTimeout(async () => {

                                let allParams = {};
                                let pageParams = {
                                  pageSize: 1000,
                                  page: 1
                                };
                                let params = {
                                  fossillist_id: Number(fossilId)
                                }
                                Object.assign(allParams, pageParams, params);
                                await this.showFossilOptionData(allParams)
                                this.setState({
                                  showfossilOption: true,
                                  fossilActive: true
                                })
                              }, 20)
                            }
                          );
                        }
                      }
                    );
                  }
                }
              );
            }
          }
        );
      }
    } else {
      await this.showReferenceData({});
    }
  }

  // ref
  handleInfiniteOnLoad = () => {
    let { referenceData } = this.state;
    this.setState({
      referenceLoading: true
    });
    let { referenceTotal } = this.state;

    if (referenceData.length >= referenceTotal) {
      message.warning("Reference data loaded all");
      this.setState({
        referenceHasMore: false,
        referenceLoading: false
      });
      return;
    }
    let { pageSize, referencePage, queryParams } = this.state;
    this.setState(
      {
        referencePage: referencePage + 1
      },
      () => {
        let searchParams = {
          pageSize,
          page: this.state.referencePage,
          ...queryParams
        };
        this.getreferenceData(searchParams, res => {
          referenceData = referenceData.concat(res.result);
          this.setState({
            referenceData,
            referenceLoading: false
          });
        });
      }
    );
  };

  getreferenceData = async (condition, callback) => {
    this.setState(
      {
        referenceLoading: true
      },
      async () => {
        let searchResult = await Apis.searchReference(condition);

        if (searchResult.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: searchResult.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 5000);
        } else {
          callback(searchResult);
        }
      }
    );
  };
  getsectionData = async (condition, callback) => {
    this.setState(
      {
        sectionLoading: true
      },
      async () => {
        let searchResult = await Apis.searchSection(condition);

        if (searchResult.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: searchResult.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 5000);
        } else {
          callback(searchResult);
        }
      }
    );
  };
  getformationData = async (condition, callback) => {
    this.setState(
      {
        formationLoading: true
      },
      async () => {
        let searchResult = await Apis.getFormations(condition);

        if (searchResult.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: searchResult.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 5000);
        } else {
          callback(searchResult);
        }
      }
    );
  };

  getunitData = async (condition, callback) => {
    this.setState(
      {
        unitLoading: true
      },
      async () => {
        let searchResult = await Apis.getUnits(condition);

        if (searchResult.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: searchResult.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 5000);
        } else {
          callback(searchResult);
        }
      }
    );
  };
  getcollectionData = async (condition, callback) => {
    this.setState(
      {
        collectionLoading: true
      },
      async () => {
        let searchResult = await Apis.getCollections(condition);

        if (searchResult.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: searchResult.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 5000);
        } else {
          callback(searchResult);
        }
      }
    );
  };
  getfossilData = async (condition, callback) => {

    this.setState(
      {
        fossilLoading: true
      },
      async () => {
        let searchResult = await Apis.searchAllFossilByOccurrence(condition);

        if (searchResult.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: searchResult.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 5000);
        } else {
          callback(searchResult);
        }
      }
    );
  };
  getfossiloptionData = async (condition, callback) => {
    this.setState(
      {
        fossilOptionLoading: true
      },
      async () => {

        let searchResult = await Apis.getFossilOpinionItems(condition);

        if (searchResult.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: searchResult.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 5000);
        } else {
          callback(searchResult);
        }
      }
    );
  };
  showReferenceData = async (searchParams, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState(
      {
        currentReferenceId: Number(searchParams.ref_id)
      },
      async () => {
        let { pageSize, referencePage } = this.state;

        let allParams = {};
        let pageParams = {
          pageSize,
          page: referencePage
        };
        // let searchParams = {
        //   ref_id: currentReferenceId
        // };
        Object.assign(allParams, pageParams, searchParams);
        await this.getreferenceData(allParams, async res => {
          this.setState(
            {
              referenceData: res.result,
              referenceTotal: res.total,
              referenceLoading: false
            },
            () => {
              let { referenceData, referenceTotal } = this.state;

              if (referenceData.length < referenceTotal) {
                this.setState({
                  referenceHasMore: true
                });
              }
            }
          );
        });
      }
    );
  };
  showSectionData = async sectionItem => {
    this.setState(
      {
        sectionData: []
      },
      async () => {
        let { currentReferenceId, pageSize, sectionPage } = this.state;
        if (currentReferenceId) {
          let allParams = {};
          let pageParams = {
            pageSize,
            page: sectionPage
          };
          let searchParams = sectionItem;

          Object.assign(allParams, pageParams, searchParams);
          this.getsectionData(allParams, async res => {
            this.setState(
              {
                sectionData: res.result,
                sectionTotal: res.total,
                sectionLoading: false
              },
              () => {
                let { sectionData, sectionTotal } = this.state;

                if (sectionData.length < sectionTotal) {
                  this.setState({
                    sectionHasMore: true
                  });
                }
              }
            );
          });
        }
      }
    );
  };

  showFormationData = async (sectionItem, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.setState(
      {
        formationData: []
      },
      async () => {
        let { currentSectionId, pageSize, formationPage } = this.state;
        if (currentSectionId) {
          let allParams = {};
          let pageParams = {
            pageSize,
            page: formationPage
          };
          let searchParams = sectionItem;
          Object.assign(allParams, pageParams, searchParams);

          this.getformationData(allParams, async res => {
            this.setState(
              {
                formationData: res.result,
                formationTotal: res.total,
                formationLoading: false
              },
              () => {
                let { formationData, formationTotal } = this.state;

                if (formationData.length < formationTotal) {
                  this.setState({
                    formationHasMore: true
                  });
                }
              }
            );
          });
        }
      }
    );
  };
  showUnitData = (FormationItem, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.setState(
      {
        unitData: []
      },
      async () => {
        let { pageSize, unitPage } = this.state;

        let allParams = {};
        let pageParams = {
          pageSize,
          page: unitPage
        };
        let searchParams = FormationItem;
        Object.assign(allParams, pageParams, searchParams);
        await this.getunitData(allParams, async res => {
          this.setState(
            {
              unitData: res.result,
              unitTotal: res.total,
              unitLoading: false
            },
            () => {
              let { unitData, unitTotal } = this.state;

              if (unitData.length < unitTotal) {
                this.setState({
                  unitHasMore: true
                });
              }
            }
          );
        });
      }
    );
  };
  sectionSeeUnit = (sectionItem, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.setState(
      {
        unitData: []
      },
      async () => {
        let { currentSectionId, pageSize, unitPage } = this.state;
        if (currentSectionId) {
          let allParams = {};
          let pageParams = {
            pageSize,
            page: unitPage
          };
          let searchParams = sectionItem;
          Object.assign(allParams, pageParams, searchParams);
          await this.getunitData(allParams, async res => {
            this.setState(
              {
                unitData: res.result,
                unitTotal: res.total,
                unitLoading: false
              },
              () => {
                let { unitData, unitTotal } = this.state;

                if (unitData.length <= unitTotal) {
                  this.setState({
                    unitHasMore: true
                  });
                }
              }
            );
          });
        }
      }
    );
  };
  showCollectionData = (UnitItem, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.setState(
      {
        collectionData: []
      },
      async () => {
        let { currentUnitId, pageSize, collectionPage } = this.state;
        if (currentUnitId) {
          let allParams = {};
          let pageParams = {
            pageSize,
            page: collectionPage
          };
          let searchParams = UnitItem;
          Object.assign(allParams, pageParams, searchParams);
          this.getcollectionData(allParams, async res => {
            this.setState(
              {
                collectionData: res.result,
                collectionTotal: res.total,
                collectionLoading: false
              },
              () => {
                let { collectionData, collectionTotal } = this.state;

                if (collectionData.length < collectionTotal) {
                  this.setState({
                    collectionHasMore: true
                  });
                }
              }
            );
          });
        }
      }
    );
  };
  showOptionModal = (fossilItem, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.state.isInputer === false) {
      return;
    }
    this.setState({
      showSearchTaxon: true,
      currentFossilId: fossilItem.id,
      currentFossilItem: fossilItem
    });
  };
  showFossilData = (CollectionItem, e) => {

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.setState(
      {
        fossilData: [],

        showFossil: true
      },
      async () => {
        let { currentCollectionId, pageSize, fossilPage } = this.state;
        if (currentCollectionId) {
          let allParams = {};
          let pageParams = {
            pageSize,
            page: fossilPage
          };
          let searchParams = CollectionItem;
          Object.assign(allParams, pageParams, searchParams);
          this.getfossilData(allParams, async res => {
            this.setState(
              {
                fossilData: res.result,
                fossilTotal: res.total,
                fossilLoading: false
              },
              () => {
                let { fossilData, fossilTotal } = this.state;

                if (fossilData.length < fossilTotal) {
                  this.setState({
                    fossilHasMore: true
                  });
                }
              }
            );
          });
        }
      }
    );
  };
  showFossilOptionData = (searchOptionParams, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.setState(
      {
        fossilOptionData: []
      },
      async () => {
        let {
          currentFossilId,
          pageSize,
          fossilPage,
          oldViewPortsObj
        } = this.state;
        if (currentFossilId) {
          let allParams = {};
          let pageParams = {
            pageSize: 1000,
            page: fossilPage
          };

          let searchParams = searchOptionParams;
          Object.assign(allParams, pageParams, searchParams);
          this.getfossiloptionData(allParams, async res => {
            let oldFossils = res.result;
            oldFossils.map((item, index) => {
              oldViewPortsObj[item.id] = item;
            });

            this.setState(
              {
                oldViewPortsObj,
                fossilOptionData: oldFossils,
                fossilOptionTotal: res.total,
                fossilOptionLoading: false
              },
              () => {
                let { fossilOptionData, fossilOptionTotal } = this.state;

                if (fossilOptionData.length < fossilOptionTotal) {
                  this.setState({
                    fossilOptionHasMore: true
                  });
                }
              }
            );
          });
        }
      }
    );
  };

  handleData = type => {
    let {
      currentReferenceId,
      currentSectionId,
      currentFormationId,
      currentUnitId,
      currentCollectionId,
      currentFossilId
    } = this.state;
    let allParams = {};
    if (type === "section") {
      allParams = {
        ref_id: currentReferenceId
      };
    } else if (type === "formation") {
      allParams = {
        section_basic_id: currentSectionId
      };
    } else if (type === "unit") {
      allParams = {
        formation_id: currentFormationId
      };
    } else if (type === "collection") {
      allParams = {
        unit_id: currentUnitId
      };
    } else if (type === "fossil") {
      allParams = {
        collection_id: currentCollectionId
      };
    } else if (type === "fossiloption") {
      allParams = {
        fossillist_id: currentFossilId
      };
    }

    let dataType = type + "Data";
    let loadingType = type + "Loading";
    let totalType = type + "Total";
    let hasMoreType = type + "HasMore";
    let pageType = type + "Page";

    // 方法
    let getDataType = `get${type}Data`;
    // 数据名称
    let DataName = `${type}Data`;
    //数据
    let dataResult = this.state[dataType];
    // 总数
    let totalResult = this.state[totalType];
    this.setState({
      [loadingType]: true
    });

    if (dataResult.length >= totalResult) {
      message.warning(`${type} data loaded all`);
      this.setState({
        [hasMoreType]: false,
        [loadingType]: false
      });
      return;
    }

    let currentPage = this.state[pageType];
    let queryParams = allParams;
    let pageSize = this.state.pageSize;

    this.setState(
      {
        [pageType]: currentPage + 1
      },
      () => {
        let searchParams = {
          pageSize,
          page: this.state[pageType],
          ...queryParams
        };

        let dataFun = this[getDataType];

        dataFun(searchParams, res => {
          totalResult = dataResult.concat(res.result);

          this.setState({
            [DataName]: totalResult,
            [loadingType]: false
          });
        });
      }
    );
  };

  openTaxonModal = () => {
    this.setState({
      taxonOpModal: true
    });
  };
  closeTaxonModal = () => {
    this.setState({
      taxonOpModal: false
    });
  };
  addTaxModal = () => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        // let addResult = await addTaxon(values);
        let addResult = [];
        if (addResult.result) {
          let that = this;
          Modal.success({
            title: "This is a successful message",
            content: "化石添加成功",
            onOk() {
              that.props.form.resetFields();
            }
          });
        } else {
          Modal.error({
            title: "This is a error message",
            content: addResult.error
          });
        }
      }
    });
  };
  cancelTaxonModal = () => {
    this.setState({
      addTaxonModal: false
    });
  };
  openAddTaxon = () => {
    this.setState({
      addTaxonModal: true
    });
  };

  goTypeAdd = () => {
    let type = this.props.match.params.type;
    if (type === "reference") {
      let url = "/reference/add";
      const w = window.open("about:blank");
      w.location.href = url;
      // this.props.history.push("/reference/add");
    } else if (type === "section") {
      let url = "/section/baseinfo";
      const w = window.open("about:blank");
      w.location.href = url;
      // this.props.history.push("/section/baseinfo");
    } else if (type === "collection") {
      let url = "/collection/add";
      const w = window.open("about:blank");
      w.location.href = url;
      // this.props.history.push("/collection/add");
    } else if (type === "oceanic") {
      let url = "/oceanic/add/huici";
      const w = window.open("about:blank");
      w.location.href = url;
      // this.props.history.push("/oceanic/add/huici");
    } else {
      let url = "/taxonomy/addTaxon";
      const w = window.open("about:blank");
      w.location.href = url;
      // this.props.history.push("/taxonomy/addHigther");
    }
  };
  //
  referenceReset = () => {
    this.setState({
      referenceLoading: false,
      referenceHasMore: true,

      //  SECTION
      sectionPage: 1,
      sectionData: [],
      showSection: false,
      sectionLoading: false,
      sectionHasMore: true,
      sectionTotal: 0,
      currentSectionId: "",
      currentgeographyId: "",
      sectionType: "baseinfo",
      sectionActive: false,
      // formation
      formationPage: 1,
      formationData: [],
      showFormation: false,
      formationLoading: false,
      formationHasMore: true,
      formationTotal: 0,
      currentFormationId: "",
      formationActive: false,

      //

      // unit
      unitPage: 1,
      unitData: [],
      showUnit: false,
      unitLoading: false,
      unitHasMore: true,
      unitTotal: 0,
      currentUnitId: "",
      sectionSeeUnitActive: false,
      unitActive: false,
      // collection
      collectionPage: 1,
      collectionData: [],
      showCollection: false,
      collectionLoading: false,
      collectionHasMore: true,
      collectionTotal: 0,
      currentCollectionId: "",
      collectionActive: false,
      // fossil

      fossilPage: 1,
      fossilData: [],
      showFossil: false,
      fossilLoading: false,
      fossilHasMore: true,
      fossilTotal: 0,
      currentFossilId: "",
      fossilActive: false,
      currentFossilItem: {},
      // fossilOption

      fossilOptionPage: 1,
      fossilOptionData: [],
      showfossilOption: false,
      fossilOptionLoading: false,
      fossilOptionHasMore: true,
      fossilOptionTotal: 0,
      currentfossilOptionId: "",
      nameType: "formal",
      fossilOptionActive: false,

      newViewPortsObj: {},
      oldViewPortsObj: {},
      newViewPortsArray: [],
    });
  };
  sectionReset = () => {
    this.setState({
      //  SECTION

      sectionLoading: false,
      sectionHasMore: true,

      sectionType: "baseinfo",

      // formation
      formationPage: 1,
      formationData: [],
      showFormation: false,
      formationLoading: false,
      formationHasMore: true,
      formationTotal: 0,
      currentFormationId: "",
      formationActive: false,

      //

      // unit
      unitPage: 1,
      unitData: [],
      showUnit: false,
      unitLoading: false,
      unitHasMore: true,
      unitTotal: 0,
      currentUnitId: "",
      sectionSeeUnitActive: false,
      unitActive: false,
      // collection
      collectionPage: 1,
      collectionData: [],
      showCollection: false,
      collectionLoading: false,
      collectionHasMore: true,
      collectionTotal: 0,
      currentCollectionId: "",
      collectionActive: false,
      // fossil

      fossilPage: 1,
      fossilData: [],
      showFossil: false,
      fossilLoading: false,
      fossilHasMore: true,
      fossilTotal: 0,
      currentFossilId: "",
      fossilActive: false,
      currentFossilItem: {},
      // fossilOption

      fossilOptionPage: 1,
      fossilOptionData: [],
      showfossilOption: false,
      fossilOptionLoading: false,
      fossilOptionHasMore: true,
      fossilOptionTotal: 0,
      currentfossilOptionId: "",
      nameType: "formal",
      fossilOptionActive: false,

      newViewPortsObj: {},
      oldViewPortsObj: {},
      newViewPortsArray: [],
    });
  };
  formationReset = () => {
    this.setState({
      // formation

      formationLoading: false,
      formationHasMore: true,

      //

      // unit
      unitPage: 1,
      unitData: [],
      showUnit: false,
      unitLoading: false,
      unitHasMore: true,
      unitTotal: 0,
      currentUnitId: "",
      sectionSeeUnitActive: false,
      unitActive: false,
      // collection
      collectionPage: 1,
      collectionData: [],
      showCollection: false,
      collectionLoading: false,
      collectionHasMore: true,
      collectionTotal: 0,
      currentCollectionId: "",
      collectionActive: false,
      // fossil

      fossilPage: 1,
      fossilData: [],
      showFossil: false,
      fossilLoading: false,
      fossilHasMore: true,
      fossilTotal: 0,
      currentFossilId: "",
      fossilActive: false,
      currentFossilItem: {},
      // fossilOption

      fossilOptionPage: 1,
      fossilOptionData: [],
      showfossilOption: false,
      fossilOptionLoading: false,
      fossilOptionHasMore: true,
      fossilOptionTotal: 0,
      currentfossilOptionId: "",
      nameType: "formal",
      fossilOptionActive: false,

      newViewPortsObj: {},
      oldViewPortsObj: {},
      newViewPortsArray: [],
    });
  };
  unitReset = () => {
    this.setState({
      // unit

      unitLoading: false,
      unitHasMore: true,

      // collection
      collectionPage: 1,
      collectionData: [],
      showCollection: false,
      collectionLoading: false,
      collectionHasMore: true,
      collectionTotal: 0,
      currentCollectionId: "",
      collectionActive: false,
      // fossil

      fossilPage: 1,
      fossilData: [],
      showFossil: false,
      fossilLoading: false,
      fossilHasMore: true,
      fossilTotal: 0,
      currentFossilId: "",
      fossilActive: false,
      currentFossilItem: {},
      // fossilOption

      fossilOptionPage: 1,
      fossilOptionData: [],
      showfossilOption: false,
      fossilOptionLoading: false,
      fossilOptionHasMore: true,
      fossilOptionTotal: 0,
      currentfossilOptionId: "",
      nameType: "formal",
      fossilOptionActive: false,

      newViewPortsObj: {},
      oldViewPortsObj: {},
      newViewPortsArray: [],
    });
  };
  collectionReset = () => {
    this.setState({
      // collection

      collectionLoading: false,
      collectionHasMore: true,

      // fossil

      fossilPage: 1,
      fossilData: [],
      showFossil: false,
      fossilLoading: false,
      fossilHasMore: true,
      fossilTotal: 0,
      currentFossilId: "",
      fossilActive: false,
      currentFossilItem: {},
      // fossilOption

      fossilOptionPage: 1,
      fossilOptionData: [],
      showfossilOption: false,
      fossilOptionLoading: false,
      fossilOptionHasMore: true,
      fossilOptionTotal: 0,
      currentfossilOptionId: "",
      nameType: "formal",
      fossilOptionActive: false,

      newViewPortsObj: {},
      oldViewPortsObj: {},
      newViewPortsArray: [],
    });
  };
  fossilReset = () => {
    this.setState({
      // fossil

      fossilLoading: false,
      fossilHasMore: true,

      currentFossilItem: {},
      // fossilOption

      fossilOptionPage: 1,
      fossilOptionData: [],
      showfossilOption: false,
      fossilOptionLoading: false,
      fossilOptionHasMore: true,
      fossilOptionTotal: 0,
      currentfossilOptionId: "",
      nameType: "formal",
      fossilOptionActive: false,

      newViewPortsObj: {},
      oldViewPortsObj: {},
      newViewPortsArray: [],
    });
  };
  fossilOptionReset = () => {
    this.setState({
      // fossil

      // fossilOption

      fossilOptionPage: 1,

      fossilOptionLoading: false,
      fossilOptionHasMore: true,

      nameType: "formal"
    });
  };
  referenceCallback = (key, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    let allParams = {};

    let item = {
      ref_id: key
    };
    let { sectionSerachConditions } = this.state;
    Object.assign(allParams, item, sectionSerachConditions);

    let { referenceActive, currentReferenceId } = this.state;
    if (currentReferenceId === key) {
      referenceActive = !referenceActive;
    } else {
      referenceActive = true;
    }
    this.referenceReset();
    this.setState(
      {
        referenceActive,
        currentReferenceId: Number(key)
      },
      () => {
        let { referenceActive } = this.state;
        if (referenceActive === true) {
          this.setState(
            {
              showSection: referenceActive
            },
            () => {
              this.showSectionData(allParams);
            }
          );
        } else {
          this.setState({
            showSection: referenceActive
          });
        }
      }
    );
  };

  sectionCallback = async (key, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    let allParams = {};
    let item = {
      section_basic_id: key
    };
    let { formationSerachConditions } = this.state;
    Object.assign(allParams, item, formationSerachConditions);

    let { sectionActive, currentSectionId, showUnit } = this.state;
    if (showUnit) {
      currentSectionId = "";
    }

    if (currentSectionId === key) {
      sectionActive = !sectionActive;
    } else {
      sectionActive = true;
    }
    this.sectionReset();
    this.setState(
      {
        sectionActive,
        currentSectionId: Number(key),
        formationPage: 1
      },
      () => {
        let { sectionActive } = this.state;
        if (sectionActive === true) {
          this.setState(
            {
              showFormation: sectionActive,
              showUnit: false,
              sectionSeeUnitActive: false
            },
            async () => {
              await this.showFormationData(allParams);
            }
          );
        } else {
          this.setState({
            showFormation: sectionActive,
            showUnit: false,
            currentSectionId: "",
            sectionSeeUnitActive: false
          });
        }
      }
    );
  };
  sectionSeeUnitCallback = (key, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    let allParams = {};
    let item = {
      section_basic_id: key
    };
    let { sectionUnitSerachConditions } = this.state;
    Object.assign(allParams, item, sectionUnitSerachConditions);

    let { sectionSeeUnitActive } = this.state;
    sectionSeeUnitActive = !sectionSeeUnitActive;
    this.sectionReset();
    this.setState(
      {
        sectionSeeUnitActive,
        currentSectionId: Number(key)
      },
      () => {
        let { sectionSeeUnitActive } = this.state;

        if (sectionSeeUnitActive === true) {
          this.setState(
            {
              showFormation: false,
              showUnit: sectionSeeUnitActive
            },
            async () => {
              await this.sectionSeeUnit(allParams);
            }
          );
        } else {
          this.setState({
            showFormation: false,
            showUnit: sectionSeeUnitActive,
            currentSectionId: ""
          });
        }
      }
    );
  };
  formationCallback = (key, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    let allParams = {};
    let item = {
      formation_id: key
    };
    let { unitSerachConditions } = this.state;
    Object.assign(allParams, item, unitSerachConditions);

    let { formationActive, currentFormationId } = this.state;

    if (currentFormationId === key) {
      formationActive = !formationActive;
    } else {
      formationActive = true;
    }
    this.formationReset();
    this.setState(
      {
        formationActive,
        collectionPage: 1,
        currentFormationId: Number(key),
        sectionSeeUnitActive: false,
        showUnit: formationActive
      },
      () => {
        let { formationActive } = this.state;
        if (formationActive === true) {
          this.setState(
            {
              showUnit: formationActive
            },
            async () => {
              await this.showUnitData(allParams);
            }
          );
        } else {
          this.setState({
            showUnit: formationActive
          });
        }
      }
    );
  };

  unitCallbak = (key, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    let allParams = {};
    let item = {
      unit_id: key
    };
    let { collectionSerachConditions } = this.state;
    Object.assign(allParams, item, collectionSerachConditions);

    let { unitActive, currentUnitId } = this.state;
    if (currentUnitId === key) {
      unitActive = !unitActive;
    } else {
      unitActive = true;
    }
    this.unitReset();
    this.setState(
      {
        unitActive,
        currentUnitId: Number(key)
      },
      () => {
        let { unitActive } = this.state;
        if (unitActive === true) {
          this.setState(
            {
              showCollection: unitActive
            },
            async () => {
              await this.showCollectionData(allParams);
            }
          );
        } else {
          this.setState({
            showCollection: unitActive
          });
        }
      }
    );
  };
  CollectionCallback = (key, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    let allParams = {};
    let item = {
      // coll_id: key,
      collection_id: key
    };
    let { fossilOptionSerachConditions } = this.state;
    Object.assign(allParams, item, fossilOptionSerachConditions);

    let { collectionActive, currentCollectionId } = this.state;
    if (currentCollectionId === key) {
      collectionActive = !collectionActive;
    } else {
      collectionActive = true;
    }
    this.collectionReset();
    this.setState(
      {
        collectionActive,
        currentCollectionId: Number(key)
      },
      () => {
        let { collectionActive } = this.state;
        if (collectionActive === true) {
          this.setState(
            {
              showFossil: collectionActive
            },
            async () => {
              await this.showFossilData(allParams);
            }
          );
        } else {
          this.setState({
            showFossil: collectionActive
          });
        }
      }
    );
  };
  FossilCallback = (key, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    let allParams = {};
    let item = {
      fossillist_id: key
    };
    Object.assign(allParams, item);

    let { fossilActive, currentFossilId } = this.state;
    if (currentFossilId === key) {
      fossilActive = !fossilActive;
    } else {
      fossilActive = true;
    }
    this.fossilReset();
    this.setState(
      {
        fossilActive,
        currentFossilId: Number(key)
      },
      () => {
        let { fossilActive } = this.state;
        if (fossilActive === true) {
          this.setState(
            {
              showfossilOption: fossilActive
            },
            async () => {
              await this.showFossilOptionData(allParams);
            }
          );
        } else {
          this.setState({
            showfossilOption: fossilActive
          });
        }
      }
    );
  };
  // 观点相关
  searchTaxonCancel = () => {
    this.setState({
      showSearchTaxon: false
    });
  };

  addViewPort = (newItem, nameType) => {
    this.setState(
      {
        showSearchTaxon: false
      },
      () => {
        // let { newViewPortsArray, fossilOptionData } = this.state;

        // newViewPortsArray.unshift(newItem);
        // // fossilOptionData.unshift(newItem);
        // this.setState(
        //   {
        //     newViewPortsArray,
        //     nameType,
        //     fossilOptionData
        //   },
        //   () => { }
        // );
        let { fossilOptionData } = this.state;


        fossilOptionData.unshift(newItem);
        this.setState(
          {

            fossilOptionData
          },
          () => {

          }
        );
      }
    );
  };
  showReferenceDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showDetailForm: true,
      referenceDetail: item
    });
  };
  showFossilRef = async (refid, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let condition = {
      ref_id: refid
    }
    let searchResult = await Apis.searchReference(condition);
    if (searchResult.result && searchResult.result.length > 0) {
      this.setState({
        showDetailForm: true,
        referenceDetail: searchResult.result[0]
      });
    } else {
      if (searchResult.error) {
        Modal.error({
          content: searchResult.error
        })
      }
    }

  }
  addNewReference = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.state.isInputer === false) {
      return;
    }


    let url = "/reference/add";

    let params = `${url}`;

    const w = window.open("about:blank");
    w.location.href = params;
    // this.props.history.push(params);
  };
  searchSection = () => {
    this.setState(
      {
        showDetailForm: false
      },
      () => {
        let { referenceDetail } = this.state;
        let searchParams = {
          ref_id: referenceDetail.ref_id
        };
        let url = "/searchs/result/section/base";
        this.props.history.push({ pathname: url, query: { searchParams } });
      }
    );
  };
  detailReferenceOk = () => {
    this.setState({
      showDetailForm: false
    });
  };
  detailReferenceCancel = () => {
    this.setState({
      showDetailForm: false
    });
  };

  // section
  showSectionDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showSectionForm: true,
      sectionDetail: item,
      sectionType: "baseinfo"
    });
  };
  detailSectionFormOk = () => {
    let { sectionType } = this.state;
    this.setState(
      {
        showSectionForm: false
      },
      () => {
        if (sectionType == "geology") {
          this.setState({
            sectionType: "baseinfo"
          });
        }
      }
    );
  };
  detailSectionCancel = () => {
    let { sectionType } = this.state;
    this.setState(
      {
        showSectionForm: false
      },
      () => {
        if (sectionType == "geology") {
          this.setState({
            sectionType: "baseinfo"
          });
        }
      }
    );
  };
  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionForm: true,
      sectionType
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionForm: true,
      sectionType
    });
  };

  // formation
  showFormationDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showSectionForm: true,
      sectionDetail: item,
      sectionType: "formation"
    });
  };

  // unit
  showUnitDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showSectionForm: true,
      sectionDetail: item,
      sectionType: "unit"
    });
  };

  // collection
  showCollectionDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showSectionForm: true,
      sectionDetail: item,
      sectionType: "collection"
    });
  };
  showCollectionDetail = (item, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      showSectionForm: true,
      sectionDetail: item,
      sectionType: "collection"
    });
  };
  showTaxonomyDetail = async (taxonmixed, e) => {
 
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let params = {
      id: taxonmixed
    };
    ;
    let searchResult = await Apis.getTaxons(params);
    if (searchResult.result && searchResult.result.length > 0) {
      this.setState({
        sectionDetail: searchResult.result[0],
        sectionType: "taxonomy",
        showSectionForm: true,
      });
    } else {
      if (searchResult.error) {
        Modal.error({
          content: searchResult.error
        })
      }
    }

  };
  addNewSection = e => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.state.isInputer === false) {
      return;
    }
    let { currentReferenceId } = this.state;

    let url = "/section/baseinfo?";
    let refUrl = currentReferenceId ? "refId=" + currentReferenceId : "";

    let params = `${url}${refUrl}`;

    const w = window.open("about:blank");
    w.location.href = params;
    // this.props.history.push(params);
  };
  addNewFormation = async e => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.state.isInputer === false) {
      return;
    }
    let { currentReferenceId, currentSectionId } = this.state;

    let params = {
      section_id: currentSectionId
    };
    let sectionSearchBack = await Apis.sectionBase(params);

    if (sectionSearchBack.result && sectionSearchBack.result.length > 0) {
      this.setState(
        {
          currentSectionData: sectionSearchBack.result[0],
          currentgeographyId: sectionSearchBack.result[0].geology_id
        },
        () => {
          let currentgeographyId = this.state.currentgeographyId;
          let url = "/section/formation?";
          let refUrl = currentReferenceId ? "refId=" + currentReferenceId : "";
          let baseIdUrl = currentSectionId ? "&baseId=" + currentSectionId : "";
          let geographyIdUrl = currentgeographyId
            ? "&geographyId=" + currentgeographyId
            : "";
          let params = `${url}${refUrl}${baseIdUrl}${geographyIdUrl}`;

          // this.props.history.push(params);
          const w = window.open("about:blank");
          w.location.href = params;
        }
      );
    } else {
      Modal.error({
        content: "no section info"
      });
    }
  };
  addNewUnit = async e => {
    let {
      currentFormationId,
      currentReferenceId,
      currentSectionId
    } = this.state;

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.state.isInputer === false) {
      return;
    }
    let params = {
      formation_id: currentFormationId
    };
    let sectionFormationBack = await Apis.getFormations(params);
    if (sectionFormationBack.result && sectionFormationBack.result.length > 0) {
      let currentgeographyId = this.state.currentgeographyId;

      let url = "/section/unit?";
      let refUrl = currentReferenceId ? "refId=" + currentReferenceId : "";
      let baseIdUrl = currentSectionId ? "&baseId=" + currentSectionId : "";
      let geographyIdUrl = currentgeographyId
        ? "&geographyId=" + currentgeographyId
        : "";
      let formationIdUrl = currentFormationId
        ? "&formationId=" + currentFormationId
        : "";

      let params = `${url}${refUrl}${baseIdUrl}${geographyIdUrl}${formationIdUrl}`;

      // this.props.history.push(params);
      const w = window.open("about:blank");
      w.location.href = params;
    } else {
      Modal.error({
        content: "no formation info"
      });
    }
  };
  addNewCollection = async e => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.state.isInputer === false) {
      return;
    }
    let { currentReferenceId, currentUnitId } = this.state;

    let params = {
      unit_id: currentUnitId
    };

    let sectionUnitBack = await Apis.getUnits(params);
    if (sectionUnitBack.result && sectionUnitBack.result.length > 0) {
      let url = "/collection/add?";
      let refUrl = currentReferenceId ? "refId=" + currentReferenceId : "";

      let UnitIdUrl = currentUnitId ? "&unitId=" + currentUnitId : "";

      let params = `${url}${refUrl}${UnitIdUrl}`;

      // this.props.history.push(params);
      const w = window.open("about:blank");
      w.location.href = params;
    } else {
      Modal.error({
        content: "no unit info"
      });
    }
  };
  addcollectionSubset = async (cItem, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.state.isInputer === false) {
      return;
    }
    let uid = Storage.getItem("gbdbid");

    let params = {
      uid,
      assetstype: 3,

      ids: [cItem.coll_id]
    };
    let collectBack = await SubsetApis.collectAssets(params);
    if (collectBack.result) {
      Modal.success({
        title: "This is an successful message",
        content: "successfully added "
      });
    } else {
      Modal.error({
        title: "This is an error message",
        content: collectBack.error
      });
    }
  };

  addNewFossil = async e => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.state.isInputer === false) {
      return;
    }
    let { currentCollectionId, currentUnitId, currentReferenceId } = this.state;
    let url = "/collection/addTaxonomies?";
    let refUrl = currentReferenceId ? "refId=" + currentReferenceId : "";

    let UnitIdUrl = currentUnitId ? "&unitId=" + currentUnitId : "";
    let collIdUrl = currentCollectionId ? "&collId=" + currentCollectionId : "";
    let params = `${url}${refUrl}${UnitIdUrl}${collIdUrl}`;

    // this.props.history.push(params);
    const w = window.open("about:blank");
    w.location.href = params;
  };
  goHistoryBack = () => {
    this.props.history.goBack();
  };

  resultTaxonCancel = () => {
    this.setState({
      showTaxonResult: false
    });
  };
  resultTaxonOk = () => {
    this.setState({
      showTaxonResult: false
    });
  };
  onTaxonomy = ref => {
    this.taxonomyChild = ref;
  };
  showTaxonomyDetail = async (taxonmixed, e) => {

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let params = {
      id: taxonmixed
    };
    ;
    let searchResult = await Apis.getTaxons(params);
    if (searchResult.result && searchResult.result.length > 0) {
      this.setState({
        sectionDetail: searchResult.result[0],
        sectionType: "taxonomy",
        showSectionForm: true,
      });
    } else {
      if (searchResult.error) {
        Modal.error({
          content: searchResult.error
        })
      }
    }

  };
  showTaxparentDetail = async (taxonmixed,parentCount, e) => {

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if(parentCount===0){
      return
    }
    let params = {
      child_id: taxonmixed
    }

    let searchResult = await Apis.searchTaxonParent(params);
    if (searchResult.result && searchResult.result.length > 0) {
      this.setState({
        sectionDetail: searchResult.result[0],
        sectionType: "taxonomy",
        showSectionForm: true,
      });
    } else {
      if (searchResult.error) {
        Modal.error({
          content: searchResult.error
        })
      }
      if (searchResult.result && searchResult.result.length == 0) {
        Modal.info({
          content: 'No parent'
        })
      }
    }

  };
  showTaxonomySiblings = async (taxonmixed,parentCount, e) => {
    // 这里是父亲的taxonmixed，需要修改


    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    let params = {
      id: taxonmixed
    };
    if(parentCount===0){
      return
    }
    this.setState({
      showTaxonResult: true
    }, () => {
      setTimeout(() => {
        this.taxonomyChild.searchTaxonomyDatas(params)

      }, 20);
    })


  };
  TaxonomyDetail = (itemValue) => {
    this.setState({
      sectionDetail: itemValue,
      sectionType: "taxonomy",
      showSectionForm: true,
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      // ref
      referenceTotal,
      // 观点
      showSearchTaxon
      // 添加观点
    } = this.state;

    let nameType = this.props.match.params.type
    let showName = this.props.match.params.type.replace(nameType[0], nameType[0].toUpperCase())

    return (
      <div className="search-results referneceall-search-results">
        <div className="searchBase-title collectionAdd-title">
          <div className="reference-search-operate">
            <p className="title">{showName} Check Search Result</p>
          </div>
          <div className="common-back common-back-hide">
            {
              this.state.showBackIcon ?
                <span
                  className="iconfont icon-back"
                  onClick={this.goHistoryBack}
                ></span>
                :
                null
            }
          </div>
        </div>
        <div className="search-results-content">
          <p className="search-results-title">
            Search {`${this.props.match.params.type}`} matched
            <span className="search-reult-num">{referenceTotal}</span>references
          </p>

          {this.state.referenceData.length > 0
            ? referenceHasShow(this)
            : referenceNoShow(this)}
          {this.state.referenceData.length === 0 &&
            this.state.sectionData.length > 0
            ? sectionHasShow(this)
            : this.state.referenceData.length === 0 &&
              this.state.sectionData.length == 0 &&
              this.state.sectionActive == true
              ? sectionNoShow(this)
              : null}
          {this.state.sectionData.length === 0 &&
            this.state.formationData.length > 0
            ? formationHasShow(this)
            : this.state.sectionData.length === 0 &&
              this.state.formationData.length == 0 &&
              this.state.formationActive == true
              ? formationNoShow(this)
              : null}
          {this.state.sectionData.length === 0 &&
            this.state.sectionSeeUnitActive &&
            this.state.unitData.length > 0
            ? UnitHasShow(this)
            : this.state.sectionData.length === 0 &&
              this.state.unitData.length === 0 &&
              this.state.sectionSeeUnitActive &&
              this.state.unitActive == true
              ? UnitNoShow(this)
              : null}
          {this.state.formationData.length == 0 &&
            !this.state.sectionSeeUnitActive &&
            this.state.unitData.length > 0
            ? UnitHasShow(this)
            : this.state.formationData.length == 0 &&
              !this.state.sectionSeeUnitActive &&
              this.state.unitData.length == 0 &&
              this.state.unitActive == true
              ? UnitNoShow(this)
              : null}

          {this.state.unitData.length == 0 &&
            this.state.collectionData.length > 0
            ? collectionHasShow(this)
            : this.state.unitData.length == 0 &&
              this.state.collectionData.length === 0 &&
              this.state.collectionActive == true
              ? collectionNoShow(this)
              : null}
          {this.state.collectionData.length === 0 &&
            this.state.fossilData.length > 0
            ? fossilHasShow(this)
            : this.state.collectionData.length === 0 &&
              this.state.fossilData.length === 0 &&
              this.state.fossilActive == true
              ? fossilNoShow(this)
              : null}
          {this.state.fossilData.length === 0 &&
            (this.state.fossilOptionData.length > 0 ||
              this.state.newViewPortsArray.length > 0)
            ? fossilOptionHasShow(this)
            : this.state.fossilData.length === 0 &&
              this.state.fossilOptionData.length === 0 &&
              this.state.newViewPortsArray.length == 0 &&
              this.state.fossilOptionActive == true
              ? fossilOptionNoShow(this)
              : null}

          {/* 添加化石的选择模态框 */}
          {/* {!referenceLoading && !referenceHasMore && ( */}
          {/* ///////////////////////////// */}

          {/* <div className="reference-to-add submit-btnDiv">
            <Button
              onClick={() => {
                this.goTypeAdd();
              }}
              type="gbdb"
              className="submit-lastButton"
              disabled={this.state.isInputer === true ? false : true}
              title={
                this.state.isInputer === true
                  ? ""
                  : "sorry!!You do not have permission to add data"
              }
            >{`Add ${this.props.match.params.type}`}</Button>
          </div> */}
          {/* )} */}
          <Modal
            destroyOnClose
            visible={this.state.taxonOpModal}
            className="taxon-op-modal taxon-ops-modal"
            width="48%"
            title={null}
            footer={null}
            onOk={this.openTaxonModal}
            onCancel={this.closeTaxonModal}
          >
            <div className="taxon-all-operates">
              <Button type="gbdb" onClick={() => this.openAddTaxon()}>
                Add Option
              </Button>
              <Button type="gbdb">Taxon Detail</Button>
              <Button type="gbdb">
                <p>Show highter</p>
                <p>Taxon</p>
              </Button>
            </div>
          </Modal>

          <Modal
            destroyOnClose
            visible={this.state.addTaxonModal}
            className="taxon-op-modal taxon-add-modal"
            width="53%"
            title={null}
            // footer={null}
            onOk={this.addTaxModal}
            onCancel={this.cancelTaxonModal}
          >
            <div className="taxon-all-operates taxon-add-operates">
              <Form layout="inline" className="addtax-modal-form">
                <Form.Item label="Taxon ID">
                  {getFieldDecorator("taxon_id", {
                    initialValue: ""
                  })(<Input></Input>)}
                </Form.Item>
                <Form.Item label="Taxon name">
                  {getFieldDecorator("taxon_name", {
                    initialValue: ""
                  })(<Input></Input>)}
                </Form.Item>

                <Form.Item label="Reference ID">
                  {getFieldDecorator("taxon_ref_id", {
                    initialValue: ""
                  })(<Input></Input>)}
                </Form.Item>
                <Form.Item label="View">
                  {getFieldDecorator("taxon_view", {
                    initialValue: ""
                  })(
                    // <div >

                    <Input className="add-taxon-view"></Input>
                    // </div>
                  )}
                </Form.Item>
              </Form>
            </div>
          </Modal>
          {/* 观点modal */}
          <Modal
            className="TaxonSearch-all-modal"
            width="76.8%"
            title=""
            visible={showSearchTaxon}
            onCancel={this.searchTaxonCancel}
            destroyOnClose={true}
            footer={null}
          >
            <div className="">
              <TaxonViewPort
                collection_id={this.state.currentCollectionId}
                addViewPort={this.addViewPort}
                fossillist_id={this.state.currentFossilId}
                ref_id={this.state.currentReferenceId}
                fossilInfo={this.state.currentFossilItem}
              ></TaxonViewPort>
            </div>
          </Modal>

          {/* 详情 */}
          <Modal
            title=""
            destroyOnClose
            visible={this.state.showDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76.8%"
            footer={null}
            // header={null}
            onOk={this.detailReferenceOk}
            onCancel={this.detailReferenceCancel}
          >
            <ReferenceDetailForm
              detailData={this.state.referenceDetail}
              searchSection={this.searchSection}
              onlySeeRef={true}
            ></ReferenceDetailForm>
          </Modal>

          <Modal
            title=""
            destroyOnClose
            visible={this.state.showSectionForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76.8%"
            footer={null}
            // header={null}
            onOk={this.detailSectionFormOk}
            onCancel={this.detailSectionCancel}
          >
            {this.state.sectionType === "baseinfo" ? (
              <BaseDetailForm
                detailData={this.state.sectionDetail}
                viewGeography={this.viewGeography}
                seeOnly={true}
              ></BaseDetailForm>
            ) : this.state.sectionType === "geology" ? (
              <GeographyDetailForm
                seeOnly={true}
                detailData={this.state.sectionDetail}
                viewBase={this.viewBase}
                seeOnly={true}
              ></GeographyDetailForm>
            ) : this.state.sectionType === "formation" ? (
              <FormationDetailForm
                seeOnly={true}
                detailData={this.state.sectionDetail}
              ></FormationDetailForm>
            ) : this.state.sectionType === "unit" ? (
              <UnitDetailForm
                seeOnly={true}
                detailData={this.state.sectionDetail}
              ></UnitDetailForm>
            ) : this.state.sectionType === "collection" ? (
              <CollectionDetailForm
                seeOnly={true}
                detailData={this.state.sectionDetail}
              ></CollectionDetailForm>
            ) : this.state.sectionType === "taxonomy" ? (
              <TaxonomyDetailForm
                detailData={this.state.sectionDetail}
                onlySeeRef={true}
                seeOnly={true}
              ></TaxonomyDetailForm>
            ) :
                        null}}
          </Modal>

          {/* 化石列表 */}
          <Modal
            destroyOnClose
            visible={this.state.showTaxonResult}
            className="searchResultModal taxResultModal noBottomPadding tableListModal"
            width="76%"
            footer={null}
            header={null}
            onOk={this.resultTaxonOk}
            onCancel={this.resultTaxonCancel}
          >
            <TaxonomyTableResult
              searchParams={this.state.searchParams}
              searchValues={this.state.searchValues}
              onTaxonomy={this.onTaxonomy}
              changeCheckSelected={this.changeCheckSelected}
              getDetail={this.TaxonomyDetail}
              checkOne={true}
              checkTaxonomyOne={this.checkTaxonomyOne}
              showSiblings={true}
            ></TaxonomyTableResult>
          </Modal>
        </div>
      </div>
    );
  }
}
ReferenceTableResult = Form.create({})(ReferenceTableResult);
export default ReferenceTableResult;
