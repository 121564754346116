import React, { Component } from 'react';
import {Form,Input,DatePicker, Button,Pagination, message } from 'antd'
import './index.scss'
import ShowInfoModal from './identityModal'
import axios from 'axios'
import moment from 'moment'

    const {RangePicker}=DatePicker

 class AntdForm extends Component {
    constructor(props){

        super(props);
        this.state={
            tableData:[],
            nowPage:1,
            defaultPageSize:15,
            isShowInfo:false,
            modalData:'',
            nowSelect:'',
            isSelecetAll:true,
            isSelecetKeyboarder:false,
            isSelecetExpert:false,
            total:0,
            isResponse:false,

        }
    }
    
    handleSubmit(page=1, isCer=0){
        const {isSelecetAll,isSelecetExpert,isSelecetKeyboarder,defaultPageSize}=this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
              
                var sendData={
                    page,
                    pageSize:defaultPageSize
                }
                if(values.createTime){
                    sendData.dateFrom=moment(values.createTime[0]).format('YYYY/MM/DD');
                    sendData.dateTo=moment(values.createTime[1]).format('YYYY/MM/DD');
                }
               
                if(isSelecetKeyboarder){
                    sendData.authStates=[1]
                    if (isCer) {
                        sendData.userType=1;
                    }
                }
                else if(isSelecetExpert){
                    sendData.authStates=[2]
                    if (isCer) {
                        sendData.userType=2;
                    }
                }
                else
                {
                    sendData.authStates=[1,2];
                }
                if (isCer) {
                    sendData.authStates=[3]
                }

          
               
                axios.get('/api/admin/getverifyusers',{
                    params:sendData
                }).then(res=>{
                    if(res.data.error===null){
                        this.setState({
                            tableData:res.data.result,
                            total:res.data.total,
                            isResponse:true
                        })
                        
                    }else{
                        message.error(res.data.error)
                    }
                    if(res.data.result.length===0){
                        // message.warning('没有数据！')
                    }
                }).catch(err=>{
                    message.error('this an error message~')
                })
            }
        })
    }
    changePage(page,pageSize){
  
        this.setState({
            nowPage:page
        })
        this.handleSubmit(page)
    }
    showInfo(item){
        this.setState({
            modalData:item
        },()=>{
            this.setState({
                isShowInfo:true
            })
        })
        //modal
    }
    handleShowInfo(){
        this.setState({
            isShowInfo:false
        })
    }
    cancelShowInfo(){
        this.setState({
            isShowInfo:false
        })
    }
    selectAll(){
        this.setState({
            isSelecetAll:true,
            isSelecetKeyboarder:false,
            isSelecetExpert:false,
        })
    }
    selectKeyboarder(){
        this.setState({
            isSelecetAll:false,
            isSelecetKeyboarder:true,
            isSelecetExpert:false,
        })
    }
    selectExpert(){
        this.setState({
            isSelecetAll:false,
            isSelecetKeyboarder:false,
            isSelecetExpert:true,
        })
    }
    time(t){
        t=t.replace('.000Z','');
        t=t.replace('T',' ');
        return t
    }
    render() {
        let {defaultPageSize,nowPage,isShowInfo,total,isResponse} =this.state;
        function showTotal(total) {
            return `每页显示\xa0${defaultPageSize}\xa0条\xa0\xa0\xa0  共 ${total} 条记录`;
          }
        const { getFieldDecorator} = this.props.form;
        const {tableData,isSelecetAll,isSelecetExpert,isSelecetKeyboarder}=this.state;
        return (
            <div id='AIdentity'>
                <div className='title'>身份认证</div>
                <div className="form">
                    <Form layout='vertical'>

                        <Form.Item label='创建时间'>
                            {
                                getFieldDecorator('createTime',{

                                })(<RangePicker></RangePicker>)
                            }
                        </Form.Item>
                        
                        <Form.Item label='类型'>
                            <Button type='default' 
                                className={isSelecetAll?'btnActive':null} 
                                onClick={this.selectAll.bind(this)}>全部</Button>
                            <Button type='default'
                                className={`btnmargin ${isSelecetKeyboarder?'btnActive':null}`} 
                                onClick={this.selectKeyboarder.bind(this)}>录入员</Button>
                            <Button type='default' 
                                className={`btnmargin ${isSelecetExpert?'btnActive':null}`} 
                                onClick={this.selectExpert.bind(this)}>专家</Button>
                        </Form.Item>

                        <Form.Item className='foot' style={{paddingLeft:'160px;'}}>
                           <Button type='primary' style={{marginRight:'10px'}} onClick={()=>{this.handleSubmit(1)}}>待审核</Button>
                           <Button type='primary' onClick={()=>{this.handleSubmit(1,1)}}>已认证</Button>
                        </Form.Item>

                    </Form>
                   
                </div>
                {
                    tableData.length>0?(
                        <div className='table'>
                        <table>
                            <tbody>
                            <tr className='head'>
                                <td>序号</td>
                                <td>审核类型</td>
                                <td>姓名</td>
                                <td>学历</td>
                                <td>申请时间</td>
                                <td>状态</td>
                                <td>操作</td>
                            </tr>
                            {
                                tableData.map((item,index)=>{
                                    return (
                                        <tr key={index} className={`tr ${index%2===0?'color':''}`}>
                                            <td>{(nowPage-1)*defaultPageSize+index+1}</td>
                                            <td>{item.authState<3? (item.authState===1?'录入员':'专家'):"已审核"}</td>
                                            <td>{item.username}</td>
                                            <td>{item.degree}</td>
                                            <td>{moment((item.auth_time||item.updatedAt)).format('YYYY/MM/DD')}</td>
                                            <td>{
                                                item.authState<3?'未审核' :(item.authState===3?(item.userType===1?"录入员":"专家"):"认证失败")}</td>
                                            <td><Button type='link' onClick={this.showInfo.bind(this,item)}>查看</Button></td>
                                        </tr>
                                    )
    
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    ):null
                }
                {
                    tableData.length>0?(
                        <div className='pages'>
                                    <Pagination size='small' 
                                    current={nowPage}
                                    defaultPageSize={defaultPageSize} 
                                    total={total} 
                                    // showTotal={showTotal.bind(this)}
                                    onChange={this.changePage.bind(this)}
                                    ></Pagination>
                                </div>
                    ):null
                }
               {tableData.length===0 && isResponse ? <div className='pageTip'>暂无数据</div> :null}

                {
                    isShowInfo?(
                        <ShowInfoModal
                            visible={isShowInfo}
                            modalData={this.state.modalData}
                            reflash={this.handleSubmit.bind(this)}
                            handleOk={this.handleShowInfo.bind(this)}
                            handleCancel={this.cancelShowInfo.bind(this)}
                        ></ShowInfoModal>
                    ):null
                }
               
            
            </div>
        
            );
    }
}
const AIdentity = Form.create()(AntdForm);
export default AIdentity

