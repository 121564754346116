import React, { Component } from "react";

import Utils from "src/js/utils";
import Apis from "src/js/apis/api";
import "src/js/global.js";
import Storage from "src/js/storage";
import "./index.css";
import {
  Form,
  Input,
  Select,
  Button,
  Modal,
  InputNumber,
  AutoComplete
} from "antd";

import GeologyAllTableResult from "commons/AllTableList/Section/Geography/index.js";
// import WebMap from "components/Map/index.js";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";
import Users from "src/js/users";
import AMap from "AMap";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
var gaodeMap;
class SectionGeography extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inheritRefIds: "",
      baseId: "",
      Countrys: [],
      Provinces: [],
      Villages: [],
      Locations: [],
      geographySearch: false,
      resultLists: [],
      showFullMap: false,
      isFull: false,
      currentLng: "",
      currentLat: "",
      currentItem: {},
      LngLats: {},
      sectionData: {},
      sectionType: "baseinfo",
      checkedObj: {},
      geologyInfo: {}
    };
  }
  initMap = gaodeMap => {
    let that = this;
    let { currentLng, currentLat } = that.state;

    if (currentLng && currentLat) {
      gaodeMap.setCenter([currentLng, currentLat]);
      let marker = new AMap.Marker({
        showLabel: false,
        position: new AMap.LngLat(currentLng, currentLat)
      });

      gaodeMap.add(marker);
    }
    setTimeout(() => {
      that.initMarker();
    }, 100);
    gaodeMap.on("click", function (e) {
      that.setState({
        currentLng: e.lnglat.lng,
        currentLat: e.lnglat.lat,
        currentItem: {}
      });
      gaodeMap.setCenter([e.lnglat.lng, e.lnglat.lat]);

      setTimeout(() => {
        that.initMarker();
        let lng = e.lnglat.lng;
        let lat = e.lnglat.lat;
        let lngObj = Utils.cacuLonLat(lng);
        let latObj = Utils.cacuLonLat(lat);

        that.props.form.setFieldsValue({
          geology_latitude_degree: latObj.degree,
          geology_latitude_minute: latObj.minute,
          geology_latitude_second: latObj.second,
          geology_latitude_decimal: lat,
          geology_longitude_degree: lngObj.degree,
          geology_longitude_minute: lngObj.minute,
          geology_longitude_second: lngObj.second,
          geology_longitude_decimal: lng
        });
      }, 100);
    });
  };
  initMarker = () => {
    let { currentLng, currentLat } = this.state;

    if (gaodeMap) {
      gaodeMap.clearMap();
      let marker = new AMap.Marker({
        showLabel: false,
        position: new AMap.LngLat(currentLng, currentLat)
      });
      marker.on("mouseover", function () {
        marker.setIcon(
          "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png"
        );
        marker.setLabel({
          offset: new AMap.Pixel(12, 25), //设置文本标注偏移量
          content: `<div class="location-detail-span">
           <p><span>Longitude:</span><span>${
            currentLng ? currentLng : ""
            } </span></p>
          <p><span>Latitude:</span><span>${
            currentLat ? currentLat : ""
            }</span></p>
           
          </div>`, //设置文本标注内容
          direction: "right" //设置文本标注方位，
        });
      });
      marker.on("mouseout", function (e) {
        marker.setIcon(
          "https://webapi.amap.com/theme/v1.3/markers/n/mark_bs.png"
        );
        let hasLabel = document.querySelector(".amap-marker-label");

        setTimeout(() => {
          if (hasLabel && hasLabel.parentNode) {
            hasLabel.parentNode.removeChild(hasLabel);
          }
        }, 20);
      });
      gaodeMap.add(marker);
      gaodeMap.setCenter([currentLng, currentLat]);
    }
  };
  isGbdbUser = async () => {
    let Inputerid = false
    let token = Storage.getItem("token");
    Inputerid = await Users.isGbdbInputer();


    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    } else {
      if (Inputerid == false) {
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title: "Sorry!!",
            content: "You do not have permission to add data"
          });
          this.props.history.push("/home");
        }
      }
    }
  };
  async componentDidMount() {
    this.isGbdbUser();
    // let that = this;
    // window.onresize = function() {
    //   if (!Utils.isFullScreen()) {
    //     that.setState({
    //       isFull: false,
    //       showFullMap: false
    //     });
    //   }
    // };

    if (this.props.location.search) {
      let inheritRefIds = Utils.getSearch(this.props.location.search, "refId");
      let baseId = Utils.getSearch(this.props.location.search, "baseId");

      this.setState(
        {
          inheritRefIds,
          baseId
        },
        async () => {
          let { baseId } = this.state;

          if ((baseId !== null) && (baseId !== "")) {
            let params = {
              section_basic_id: Number(baseId)
            };
            let geologyBack = await Apis.getGeology(params);
            if (geologyBack.result && geologyBack.result.length > 0) {
              Modal.info({
                content: "This section already has geographic data. You can  fill in a new section or go to the personal center to modify"
              });
              let geologyBackInfo = geologyBack.result[0]
              this.setState({
                geologyInfo: geologyBackInfo
              })
              if (geologyBackInfo.geology_latitude_decimal !== "") {
                this.setState({
                  currentLat: geologyBackInfo.geology_latitude_decimal
                })
              }
              if (geologyBackInfo.geology_longitude_decimal !== "") {
                this.setState({
                  currentLng: geologyBackInfo.geology_longitude_decimal
                })
              }


            }
          }
        }
      );
    } else {
      this.props.history.push("/section/baseinfo");
    }
    setTimeout(() => {
      if (document.querySelector("#fullMap")) {
        gaodeMap = new AMap.Map("fullMap", {
          zoom: 5,
          lang: "en",
          resizeEnable: true
        });
        this.initMap(gaodeMap);
      }
    }, 100);
  }
  // showMap = () => {
  //   this.setState(
  //     {
  //       showFullMap: true
  //     },
  //     () => {
  //       setTimeout(() => {
  //         Utils.full(document.querySelector("#fullMap"));
  //         this.setState({
  //           isFull: true
  //         });
  //       }, 1000);
  //     }
  //   );
  // };
  // exitFull = () => {
  //   Utils.exitFullscreen();
  //   this.setState({
  //     showFullMap: false,
  //     isFull: false
  //   });
  // };
  // confirmFullLocation = () => {
  //   Utils.exitFullscreen();
  //   this.setState(
  //     {
  //       showFullMap: false,
  //       isFull: false
  //     },
  //     () => {
  //       setTimeout(() => {
  //         let { currentLng, currentLat } = this.state;
  //         this.initMarker();
  //       }, 1000);
  //     }
  //   );
  // };
  geographyContinueOperate = (addSectionGeo, type, hasone) => {
    let { inheritRefIds, geologyid, baseId } = this.state;
    if (type === "complete") {
      if (!hasone) {
        Modal.success({
          title: "This is a successful message",
          content: "successfully added"
        });
      }

      this.props.form.resetFields();
      this.props.form.setFieldsValue({
        geology_latitude_degree: 0,
        geology_latitude_minute: 0,
        geology_latitude_second: 0,
        geology_latitude_decimal: 0,
        geology_longitude_degree: 0,
        geology_longitude_minute: 0,
        geology_longitude_second: 0,
        geology_longitude_decimal: 0
      });
      // this.setState({
      //   geologyInfo:{}
      // })
    } else {
      let that = this;
      let url;
      let geologyid = addSectionGeo.result.geology_id;
      let refId = inheritRefIds ? "refId=" + inheritRefIds : "";
      let geographyId = geologyid ? "&geographyId=" + geologyid : "";
      let baseIdUrl = baseId ? "&baseId=" + baseId : "";
      Modal.confirm({
        title: "Choose the next step",
        content: "Do you want to Add Geochemical Data",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          url = "/section/geochemical?";
          let params = `${url}${refId}${baseIdUrl}${geographyId}`;

          that.props.history.push(params);
        },
        onCancel() {

          Modal.confirm({
            title: "Choose the next step",
            content: "Do you want to Add Formation Data",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
              url = "/section/formation?";
              let geologyid = addSectionGeo.result.geology_id;
              let refId = inheritRefIds ? "refId=" + inheritRefIds : "";
              let geographyId = geologyid ? "&geographyId=" + geologyid : "";
              let params = `${url}${refId}${baseIdUrl}${geographyId}`;

              that.props.history.push(params);
            },
            onCancel() {
              let { inheritRefIds, baseId, geographyId } = that.state;
              url = "/section/unit?";
              let refId = inheritRefIds ? "refId=" + inheritRefIds : "";
              let baseIdUrl = baseId ? "&baseId=" + baseId : "";
              let geographyUrl = geographyId ? "&geographyId=" + geographyId : "";
              let params = `${url}${refId}${baseIdUrl}${geographyUrl}`;
              that.props.history.push(params);
            }
          });

        }
      });
    }
  };
  geographyContinue = async type => {
    let that = this;
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let addGeography = Utils.filterParams(values);
        addGeography = Utils.filterCondition(addGeography);
    
        if(addGeography.geology_altitude !== "" && addGeography.geology_altitude !== null && addGeography.geology_altitude !== undefined){
          if(addGeography.geology_altitude_unit==="inches"){
            
            let value=parseFloat(addGeography.geology_altitude)*0.0254
            addGeography.geology_altitude=parseFloat(value.toFixed(4))
            addGeography.geology_altitude_unit="meters"

          }
        }

        let baseId = this.state.baseId;
        addGeography.section_basic_id = baseId;
        let searchParams = {
          section_id: baseId
        };
        let sectionBase = await Apis.sectionBase(searchParams);
        let sectionData = {};
        if (sectionBase.result && sectionBase.result.length > 0) {
          sectionData = sectionBase.result[0];
        }
        addGeography = Utils.addEnterer(addGeography);
        addGeography = Utils.addAudit(addGeography, sectionData);
        // 注释服务器
        let addSectionGeo = await Apis.createGeology(addGeography);
        if (addSectionGeo.error) {
          if (addSectionGeo.state === 201) {
            Modal.info({
              title: "Prompt message",
              content: addSectionGeo.error,
              okText: "I see",
              okType: "danger",

              onOk() {
                that.geographyContinueOperate(addSectionGeo, type, 'hasone');
              },

            });
            // Modal.warn({
            //   title: "Prompt Message",
            //   content: addSectionGeo.error
            // });

          } else {
            Modal.error({
              title: "This is an error message",
              content: addSectionGeo.error
            });
          }
        }
        // 服务器测试修改
        else if (addSectionGeo.result) {
          this.geographyContinueOperate(addSectionGeo, type);
        }
      }
    });
  };

  getMapInfo = value => {
    this.setState(
      {
        currentLng: value.currentLng,
        currentLat: value.currentLat,
        center: [value.currentLng, value.currentLat]
      },
      () => { }
    );
    let lngObj = Utils.cacuLonLat(value.currentLng);
    let latObj = Utils.cacuLonLat(value.currentLat);

    this.props.form.setFieldsValue({
      geology_latitude_degree: latObj.degree,
      geology_latitude_minute: latObj.minute,
      geology_latitude_second: latObj.second,
      geology_latitude_decimal: value.currentLat,
      geology_longitude_degree: lngObj.degree,
      geology_longitude_minute: lngObj.minute,
      geology_longitude_second: lngObj.second,
      geology_longitude_decimal: value.currentLng
    });
  };

  changeDecimal = (e, part, type) => {
    if (
      Utils.isNumber(e.target.value) === true ||
      e.target.value == "" ||
      e.target.value == null
    ) {
      let value;
      if (e.target.value == "" || e.target.value == null) {
        value = 0;
      } else {
        value = e.target.value ? e.target.value : 0;
      }

      const { getFieldValue } = this.props.form;

      let mapType;

      if (type === "lng") {
        mapType = "longitude";
      } else {
        mapType = "latitude";
      }
      let decimalName = "geology_" + mapType + "_decimal";
      let degreeName = "geology_" + mapType + "_degree";
      let minuteName = "geology_" + mapType + "_minute";
      let secondName = "geology_" + mapType + "_second";
      let decimalVlaue;
      if (part === "degree") {
        let minute = getFieldValue(minuteName) ? getFieldValue(minuteName) : 0;
        let second = getFieldValue(secondName) ? getFieldValue(secondName) : 0;

        decimalVlaue =
          parseFloat(value) +
          parseFloat(minute) / 60 +
          parseFloat(second) / 3600;
        this.props.form.setFieldsValue({
          [degreeName]: parseFloat(value)
        });
      } else if (part === "minute") {
        let degree = getFieldValue(degreeName) ? getFieldValue(degreeName) : 0;
        let second = getFieldValue(secondName) ? getFieldValue(secondName) : 0;
        decimalVlaue =
          parseFloat(degree) +
          parseFloat(value) / 60 +
          parseFloat(second) / 3600;
        this.props.form.setFieldsValue({
          [minuteName]: parseFloat(value)
        });
      } else if (part === "second") {
        let degree = getFieldValue(degreeName) ? getFieldValue(degreeName) : 0;
        let minute = getFieldValue(minuteName) ? getFieldValue(minuteName) : 0;
        decimalVlaue =
          parseFloat(degree) +
          parseFloat(minute) / 60 +
          parseFloat(value) / 3600;
        this.props.form.setFieldsValue({
          [secondName]: parseFloat(value)
        });
      } else if (part === "decimal") {
        let degree = parseInt(parseFloat(value));
        let minute = parseInt((parseFloat(value) - degree) * 60);
        let second = parseInt(
          (parseFloat(value) - degree) * 3600 - minute * 60
        );
        decimalVlaue = parseFloat(value);
        this.props.form.setFieldsValue({
          [degreeName]: degree,
          [minuteName]: minute,
          [secondName]: second
        });
      }

      this.props.form.setFieldsValue({
        [decimalName]: decimalVlaue ? decimalVlaue : 0
      });

      let currentLat = getFieldValue("geology_latitude_decimal")
        ? getFieldValue("geology_latitude_decimal")
        : 0;
      let currentLng = getFieldValue("geology_longitude_decimal")
        ? getFieldValue("geology_longitude_decimal")
        : 0;

      this.setState(
        {
          center: [parseFloat(currentLat), parseFloat(currentLng)],
          currentLat,
          currentLng
        },
        () => {
          setTimeout(() => {
            let { currentLng, currentLat } = this.state;
            if (gaodeMap) {
              this.initMarker();
            }
          }, 1000);
        }
      );
    }
  };

  geologySearch = async (value, type, key) => {
    let params = {
      attributes: [key],
      [key]: value
    };

    let geoSearchBack = await Apis.autoCompleteGeology(params);

    let searchReust = geoSearchBack.result || [];
    let resultArray = [];

    searchReust.map((item, index) => {
      resultArray.push(item[key]);
    });

    this.setState({
      [type]: resultArray
    });
  };
  geologyChange = (value, field) => {
    this.props.form.setFieldsValue({
      [field]: value
    });
  };

  // 新加
  // searchGeography = async () => {
  //   let searchCondition = this.props.form.getFieldsValue([
  //     "geology_country",
  //     "geology_province",
  //     "geology_village",
  //     "geology_location"
  //   ]);
  //   let condition = {};
  //   // let attributes = [];
  //   for (let i in searchCondition) {
  //     if (searchCondition[i]) {
  //       // attributes.push(i);
  //       condition[i] = {
  //         condition: "includes",
  //         value: searchCondition[i]
  //       };
  //     }
  //   }
  //   let params = {};
  //   params = Object.assign(params, condition);
  //   let geologyBack = await Apis.getGeology(params);
  //   if (geologyBack.result) {
  //     this.setState({
  //       geographySearch: true,
  //       resultLists: geologyBack.result
  //     });
  //   } else {
  //     Modal.error({
  //       title: "This is an error message",
  //       content: geologyBack.error
  //     });
  //   }
  // };

  searchGeography = async () => {
    let searchCondition = this.props.form.getFieldsValue([
      "geology_country",
      "geology_province",
      "geology_village",
      "geology_location"
    ]);
    let condition = {};
    // let attributes = [];
    for (let i in searchCondition) {
      if (searchCondition[i]) {
        // attributes.push(i);
        condition[i] = {
          condition: "includes",
          value: searchCondition[i]
        };
      }
    }
    let params = {};
    params = Object.assign(params, condition);

    this.setState(
      {
        searchParams: params,
        searchValues: params,
        geographySearch: true
        // optionType
      },
      () => {
        let { searchParams, searchAllValues, sectionType } = this.state;

        setTimeout(() => {
          this.child.searchSectionDatas(
            searchParams,
            searchAllValues,
            "geology"
          );
        }, 20);
      }
    );
  };

  handleGeographyOk = () => {
    this.setState({
      geographySearch: false
    });
  };
  handleGeographyCancel = () => {
    this.setState({
      geographySearch: false
    });
  };
  clickGeographyId = (type, value) => {
    if (value) {
      if (type === "old") {
        if (value.geology_longitude_decimal && value.geology_latitude_decimal) {
          this.setState(
            {
              center: [
                value.geology_longitude_decimal,
                value.geology_latitude_decimal
              ],
              currentLng: value.geology_longitude_decimal,
              currentLat: value.geology_latitude_decimal,
              LngLats: {}
            },
            () => {
              setTimeout(() => {
                if (document.querySelector("#fullMap")) {
                  gaodeMap = new AMap.Map("fullMap", {
                    zoom: 5,
                    lang: "en",
                    resizeEnable: true
                  });
                  this.initMap(gaodeMap);
                }
              }, 100);
            }
          );
        }

        [
          "geology_latitude_degree",
          "geology_latitude_minute",
          "geology_latitude_second",
          "geology_latitude_decimal",
          "geology_longitude_degree",
          "geology_longitude_minute",
          "geology_longitude_second",
          "geology_longitude_decimal"
        ].map(item => {
          this.props.form.setFieldsValue({
            [item]: value[item] !== "" ? value[item] : 0
          });
        });
        [
          "geology_country",
          "geology_province",
          "geology_village",
          "geology_location",

          "geology_longitude_direction",
          "geology_latitude_direction"
        ].map(item => {
          this.props.form.setFieldsValue({
            [item]: value[item] !== "" ? value[item] : ""
          });
        });
        this.setState({
          geographySearch: false
        });
      } else {
        this.setState(
          {
            currentLng: value.currentLng,
            currentLat: value.currentLat,
            geographySearch: false,
            LngLats: {}
          },
          () => {
            setTimeout(() => {
              // 改变输入框
              if (value) {
                this.getMapInfo(value);
              }

              if (document.querySelector("#fullMap")) {
                gaodeMap = new AMap.Map("fullMap", {
                  zoom: 5,
                  lang: "en",
                  resizeEnable: true
                });
                this.initMap(gaodeMap);
              }
            }, 100);
          }
        );
      }
    }
    this.setState({
      geographySearch: false
    });
  };

  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showDetailForm: true,
      sectionType
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showDetailForm: true,
      sectionType
    });
  };
  viewGeochemical = () => {
    let url = "/searchs/result/section/geochemical";
    let queryParam = {};
    this.props.history.push({ pathname: url, query: queryParam });
  };

  changeCheckSelected = (checkedObj, resultLists, LngLats, currentItem) => {
    this.setState({
      checkedObj,
      resultLists,
      LngLats,
      currentItem
    });
  };
  onGeography = ref => {
    this.child = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      sectionType: "geology",
      showDetailForm: true
    });

    //点击 detail 按扭执行代码
  };

  showDetailIcons = LngLats => {
    let that = this;
    if (LngLats && JSON.stringify(LngLats) !== "{}") {
      let key = Object.keys(LngLats)[0];

      let firstlng = LngLats[key].lng;
      let firstlat = LngLats[key].lat;
      if (firstlng && firstlat) {
        gaodeMap.setCenter([firstlng, firstlat]);
      }

      for (let item in LngLats) {
        if (item && LngLats[item].lng && LngLats[item].lat) {
          let itemDetail = LngLats[item].detail;
          let marker = new AMap.Marker({
            showLabel: false,
            position: new AMap.LngLat(LngLats[item].lng, LngLats[item].lat)
          });
          marker.on("mouseover", function () {
            marker.setIcon(
              "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png"
            );
            marker.setLabel({
              offset: new AMap.Pixel(12, 25), //设置文本标注偏移量
              content: `<div class="location-detail-span">
             <p><span>lnglat:</span><span>${itemDetail.geology_longitude_decimal} ${itemDetail.geology_latitude_decimal}</span></p>
            <p><span>country:</span><span>${itemDetail.geology_country}</span></p>
             <p><span>province:</span><span>${itemDetail.geology_province}</span></p>
               <p><span>village:</span><span>${itemDetail.geology_village}</span></p>
            </div>`, //设置文本标注内容
              direction: "right" //设置文本标注方位，
            });
          });
          marker.on("mouseout", function (e) {
            marker.setIcon(
              "https://webapi.amap.com/theme/v1.3/markers/n/mark_bs.png"
            );
            let hasLabel = document.querySelector(".amap-marker-label");

            setTimeout(() => {
              if (hasLabel && hasLabel.parentNode) {
                hasLabel.parentNode.removeChild(hasLabel);
              }
            }, 20);
          });
          marker.on("click", function (e) {
            marker.setIcon(
              "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png"
            );

            that.setState({
              currentLng: e.lnglat.lng,
              currentLat: e.lnglat.lat,
              currentItem: itemDetail
            });
            gaodeMap.setCenter([e.lnglat.lng, e.lnglat.lat]);
          });
          gaodeMap.on("click", function (e) {
            let marker = new AMap.Marker({
              showLabel: false,
              position: new AMap.LngLat(e.lnglat.lng, e.lnglat.lat)
            });
            gaodeMap.add(marker);
            that.setState({
              currentLng: e.lnglat.lng,
              currentLat: e.lnglat.lat,
              currentItem: {}
            });
            gaodeMap.setCenter([e.lnglat.lng, e.lnglat.lat]);
          });
          gaodeMap.add(marker);
          gaodeMap.setFitView();
        }
      }
    }
  };
  showOnMap = type => {
    let that = this;
    this.setState(
      {
        showMap: true
      },
      () => {
        setTimeout(() => {
          if (document.querySelector("#geographyMap")) {
            gaodeMap = new AMap.Map("geographyMap", {
              zoom: 5,
              lang: "en",
              resizeEnable: true
            });
            let { LngLats } = that.state;
            if (type == "world") {
              let { currentLng, currentLat } = this.state;

              this.initMap(gaodeMap);
            } else {
              this.showDetailIcons(LngLats);
            }
          }
        }, 1000);
      }
    );
  };
  handleMapOk = () => {
    this.setState(
      {
        showMap: false
      },
      () => {
        setTimeout(() => {
          if (document.querySelector("#fullMap")) {
            gaodeMap = new AMap.Map("fullMap", {
              zoom: 5,
              lang: "en",
              resizeEnable: true
            });
            this.initMap(gaodeMap);
          }
        }, 100);
      }
    );
  };
  handleMapCancel = () => {
    this.setState(
      {
        showMap: false
      },
      () => {
        setTimeout(() => {
          if (document.querySelector("#fullMap")) {
            gaodeMap = new AMap.Map("fullMap", {
              zoom: 5,
              lang: "en",
              resizeEnable: true
            });
            this.initMap(gaodeMap);
          }
        }, 100);
      }
    );
  };
  geologyMapLocation = () => {
    let { currentItem } = this.state;

    if (JSON.stringify(currentItem) !== "{}") {
      this.setState(
        {
          showMap: false
        },
        () => {
          this.clickGeographyId("old", currentItem);
        }
      );
    } else {
      let { currentLng, currentLat } = this.state;
      let lnglat = { currentLng, currentLat };
      this.setState(
        {
          showMap: false
        },
        () => {
          this.clickGeographyId("new", lnglat);
        }
      );
    }
  };

  conformOldGeogry = () => {
    let { checkedObj, currentItem } = this.state;
    if (Object.keys(checkedObj).length === 1) {
      this.clickGeographyId("old", currentItem);
    } else {
      Modal.error({
        title: "This is an error message",
        content: "please select one"
      });
    }
  };
  detailFormOk = () => {
    this.setState({
      showDetailForm: false
    });
  };
  detailFormCancel = () => {
    this.setState({
      showDetailForm: false
    });
  };
  render() {
    let {
      inheritRefIds,
      currentLng,
      currentLat,
      Countrys,
      Provinces,
      Villages,
      Locations,

      baseId,

      searchParams,

      searchValues,
      sectionType,
      geologyInfo
    } = this.state;

    let Lndegree = 0;
    let Lnminute = 0;
    let Lnsecond = 0;
    let Ladegree = 0;
    let Laminute = 0;
    let Lasecond = 0;
    if (currentLng) {
      Lndegree = parseInt(currentLng);
      Lnminute = parseInt((currentLng - Lndegree) * 60);
      Lnsecond = (currentLng - Lndegree) * 3600 - Lnminute * 60;
    } else {
      currentLng = 0;
    }
    if (currentLat) {
      Ladegree = parseInt(currentLat);
      Laminute = parseInt((currentLat - Ladegree) * 60);
      Lasecond = (currentLat - Ladegree) * 3600 - Laminute * 60;
    } else {
      currentLat = 0;
    }

    const { getFieldDecorator } = this.props.form;
    return (
      <div id="sectionGeography" className="section-common">
        <div className="sectionGeography-mian">
          <div className="section-common-title form-common-title">
            Add Geography
          </div>

          <div className="sectionGeography-form">
            <Form>
              <div className="referenceAdd-title reference-inherit">
                {/* 可能会继承多个 */}

                {
                  // <span className="geography-ref-item" style={{ width: `${singleWidth}%` }}>
                  <span className="geography-ref-item">
                    <Form.Item label="Reference ID">
                      {getFieldDecorator(`ref_id`, {
                        rules: [
                          {
                            type: "number"
                          }
                          // {
                          //   required: true,
                          //   message: "Please input the Reference"
                          // }
                        ],
                        initialValue: inheritRefIds ? Number(inheritRefIds) : ""
                      })(
                        <InputNumber
                          className="inherit-input"
                          disabled
                        ></InputNumber>
                      )}
                    </Form.Item>
                  </span>
                }
              </div>
              <div className="referenceAdd-title reference-inherit">
                {/* 可能会继承多个 */}

                {
                  // <span className="geography-ref-item" style={{ width: `${singleWidth}%` }}>
                  <span className="geography-ref-item">
                    <Form.Item label="Section ID">
                      {getFieldDecorator(`section_basic_id`, {
                        rules: [
                          {
                            type: "number"
                          }
                          // {
                          //   required: true,
                          //   message: "Please input the Reference"
                          // }
                        ],
                        initialValue: baseId ? Number(baseId) : ""
                      })(
                        <InputNumber
                          className="inherit-input"
                          disabled
                        ></InputNumber>
                      )}
                    </Form.Item>
                  </span>
                }
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Country/Continent">
                    {getFieldDecorator("geology_country", {
                      rules: [
                        {
                          required: true,
                          message: "Please input the Country"
                        }
                      ],
                      initialValue: geologyInfo.geology_country ? geologyInfo.geology_country : ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Countrys",
                            "geology_country"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_country")
                        }
                      >
                        {Countrys.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Province/State">
                    {getFieldDecorator("geology_province", {
                      rules: [
                        // {
                        //   required: true,
                        //   message: "Please input the Province/State"
                        // }
                      ],
                      initialValue: geologyInfo.geology_province ? geologyInfo.geology_province : ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Provinces",
                            "geology_province"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_province")
                        }
                        placeholder=""
                      >
                        {Provinces.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="City/Village">
                    {getFieldDecorator("geology_village", {
                      rules: [
                        // {
                        //   required: true,
                        //   message: "Please input the City/Village"
                        // }
                      ],
                      initialValue: geologyInfo.geology_village ? geologyInfo.geology_village : ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Villages",
                            "geology_village"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_village")
                        }
                        placeholder=""
                      >
                        {Villages.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Location">
                    {getFieldDecorator("geology_location", {
                      rules: [{}],
                      initialValue: geologyInfo.geology_location ? geologyInfo.geology_location : ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Locations",
                            "geology_location"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_location")
                        }
                        placeholder=""
                      >
                        {Locations.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
              </div>

              <div
                className="geography-search-button"
             
              >
                <Button type="gbdb"    onClick={this.searchGeography}>Search Geography</Button>
              </div>

              <div className="sectionGeography-map">
                <div className="sectionGeography-map-border"></div>
                <div className="sectionGeography-tutude sectionGeography-tutude-title">
                  <span>Degrees</span>
                  <span>Minutes</span>
                  <span>Seconds</span>
                  <span>Declmal</span>
                  <span>Direction</span>
                </div>

                <div className="sectionGeography-tutude">
                  <span className="sectionGeography-map-label">Latitude*</span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Degrees：</p>}>
                      {getFieldDecorator(
                        "geology_latitude_degree",

                        {
                          initialValue: Ladegree,
                          rules: [
                            {
                              type: "number"
                              // pattern: "/d|[1-9]d|1[0-7]d/",
                              // message: "Wrong degree"
                            }
                          ]
                        }
                      )(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "degree", "lat")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>

                  <span>
                    <FormItem label={<p className="h5-map-title">Minutes：</p>}>
                      {getFieldDecorator("geology_latitude_minute", {
                        initialValue: Laminute,
                        rules: [
                          {
                            type: "number"
                            // message: "Wrong minute"
                            // pattern: "/d|[0-5]d/",
                            // message: "error value"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "minute", "lat")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Seconds：</p>}>
                      {getFieldDecorator("geology_latitude_second", {
                        initialValue: Lasecond,
                        rules: [
                          {
                            type: "number"
                            // message: "Wrong second"
                            // pattern: "(d|[0-5]d)(.d{1,2})",
                            // message: "error value"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "second", "lat")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Declmal：</p>}>
                      {getFieldDecorator("geology_latitude_decimal", {
                        initialValue: currentLat,
                        rules: [
                          {
                            type: "number"
                            // message: "Wrong decimal"
                            // pattern: "/^[-+]?([0-8]?d{1}.d{1,5}|90.0{1,5})$/",
                            // message: "error value"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "decimal", "lat")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Direction：</p>}
                    >
                      {getFieldDecorator("geology_latitude_direction", {
                        initialValue: geologyInfo.geology_latitude_direction ? geologyInfo.geology_latitude_direction : "North"
                      })(
                        <Select placeholder="">
                          <Option value="North">North</Option>
                          <Option value="East">East</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>

                <div className="sectionGeography-tutude">
                  <span className="sectionGeography-map-label">Longitude*</span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Degrees：</p>}>
                      {getFieldDecorator("geology_longitude_degree", {
                        initialValue: Lndegree,
                        rules: [
                          {
                            type: "number"
                            // message: "Wrong degree"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "degree", "lng")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>

                  <span>
                    <FormItem label={<p className="h5-map-title">Minutes：</p>}>
                      {getFieldDecorator("geology_longitude_minute", {
                        initialValue: Lnminute,
                        rules: [
                          {
                            type: "number"
                            // message: "Wrong minute"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "minute", "lng")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Seconds：</p>}>
                      {getFieldDecorator("geology_longitude_second", {
                        initialValue: Lnsecond,
                        rules: [
                          {
                            type: "number"
                            // message: "Wrong second"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "second", "lng")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Declmal：</p>}>
                      {getFieldDecorator("geology_longitude_decimal", {
                        initialValue: currentLng,
                        rules: [
                          {
                            type: "number"
                            // message: "Wrong decimal"
                            // pattern: "/^[-+]?(0?d{1,2}.d{1,5}|1[0-7]?d{1}.d{1,5}|180.0{1,5})$/",
                            // message: "error value"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "decimal", "lng")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Direction：</p>}
                    >
                      {getFieldDecorator("geology_longitude_direction", {
                        initialValue: geologyInfo.geology_longitude_direction ? geologyInfo.geology_longitude_direction : "East"
                      })(
                        <Select placeholder="">
                          <Option value="East">East</Option>
                          <Option value="West">West</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>

                <FormItem label="">
                  <div className="sectionGeography-tutude sectionGeography-earth">
                    <span className="full-map-icon" title="Map zoom">
                      <img
                        src={require("src/assets/images/home/hworld.png")}
                        // onClick={() => this.showMap()}
                        onClick={() => this.showOnMap("world")}
                      />
                      <div id="fullMap">
                        {/* {this.state.isFull ? (
                          <div className="exit-full-screen">
                            <span
                              className="iconfont icon-tuichuquanping1"
                              onClick={this.exitFull}
                            ></span>
                            <Button
                              type="gbdb"
                              className="confirm-location-btn"
                              onClick={this.confirmFullLocation}
                            >
                              Select the location
                            </Button>
                          </div>
                        ) : null} */}
                      </div>
                    </span>
                  </div>
                </FormItem>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    // <div className="sectionGeography-scale">
                    <p>Scale of geograhic resolution</p>
                    // </div>
                  }
                >
                  {getFieldDecorator("geology_geographic_scale", {
                    rules: [{}],
                    initialValue: geologyInfo.geology_geographic_scale ? geologyInfo.geology_geographic_scale : ""
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-no">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p>Basis of Corrdiante</p>
                    </div>
                  }
                >
                  {getFieldDecorator("geology_coordinates", {
                    rules: [{}],

                    initialValue: geologyInfo.geology_coordinates ? geologyInfo.geology_coordinates : "unknown"
                  })(
                    <Select>
                      <Option value="unknown">unknown</Option>
                      <Option value="precise GPS value">
                        precise GPS value
                      </Option>
                      <Option value="based on nearby location">
                        based on nearby location
                      </Option>
                      <Option value="based on political unit">
                        based on political unit
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="referenceSearch-authours referenceSearch-editors  referenceSearch-Altitude">
                <div>
                  <div className="referenceSearch-author">
                    <div className="referenceAdd-author-title">Altitude</div>
                    <span className="geography-altitude">
                      <FormItem label="">
                        {getFieldDecorator("geology_altitude", {
                          rules: [
                          
                            {
                              type: "number"
                            }
                          ],
                          initialValue: geologyInfo.hasOwnProperty("geology_altitude") ? geologyInfo.geology_altitude : ""
                        })(<InputNumber min={0}></InputNumber>)}
                      </FormItem>
                    </span>
                    <span>
                      <FormItem label="">
                        {getFieldDecorator("geology_altitude_unit", {
                          rules: [{}],
                          initialValue: geologyInfo.geology_altitude_unit ? geologyInfo.geology_altitude_unit : "meters"
                        })(
                          <Select placeholder="meters">
                            <Option value="meters">meters</Option>
                            <Option value="inches">inches</Option>
                          </Select>
                        )}
                      </FormItem>
                    </span>
                  </div>
                </div>
              </div>

              {/* <div className="referenceAdd-book">
                <Form.Item label="Location">
                  {getFieldDecorator('geology_location', {
                    rules: [
                      {

                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div> */}
              <div className="referenceAdd-book">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale" title="">
                      <p>Location Details</p>
                    </div>
                  }
                >
                  {getFieldDecorator("geology_details", {
                    rules: [{}],
                    initialValue: geologyInfo.geology_details ? geologyInfo.geology_details : ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-comments">
                <Form.Item label="Comments" className="long-label">
                  {getFieldDecorator("geology_comments", {
                    rules: [{}],
                    initialValue: geologyInfo.geology_comments ? geologyInfo.geology_comments : ""
                  })(<TextArea rows={6}></TextArea>)}
                </Form.Item>
              </div>

              <div className="referenceSearch-btns sectionBase-btns last-formItem">
                <div className="section-geography-buttons">
                  <Button
                    type="gbdb"
                    title={JSON.stringify(this.state.geologyInfo) !== "{}" ? "This section already has geographic data" : ""}
                    onClick={() => this.geographyContinue("continue")}
                    disabled={JSON.stringify(this.state.geologyInfo) !== "{}"}
                  >
                    <div>
                      <p> Save and</p>
                      <p> Continue</p>
                    </div>
                  </Button>
                  <Button
                    type="gbdb"
                    title={JSON.stringify(this.state.geologyInfo) !== "{}" ? "This section already has geographic data" : ""}
                    disabled={JSON.stringify(this.state.geologyInfo) !== "{}"}
                    onClick={() => this.geographyContinue("complete")}
                  >
                    <div>
                      <p> Complete</p>
                    </div>
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <Modal
          destroyOnClose
          visible={this.state.geographySearch}
          className="searchResultModal taxResultModal noBottomPadding"
          width="76.8%"
          footer={null}
          header={null}
          onOk={this.handleGeographyOk}
          onCancel={this.handleGeographyCancel}
        >
          {this.state.resultLists.length > 0 ? (
            <div className="geography-showmap-btn">
              <Button
                disabled={
                  JSON.stringify(this.state.checkedObj) === "{}" ? true : false
                }
                type="gbdb"
                onClick={this.showOnMap}
              >
                Show on the map
              </Button>
              <Button
                disabled={
                  JSON.stringify(this.state.checkedObj) === "{}" ? true : false
                }
                type="gbdb"
                onClick={this.conformOldGeogry}
              >
                Select the location
              </Button>
            </div>
          ) : null}

          <GeologyAllTableResult
            searchParams={searchParams}
            searchValues={searchValues}
            onGeography={this.onGeography}
            changeCheckSelected={this.changeCheckSelected}
            getDetail={this.getDetail}
          ></GeologyAllTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76%"
          footer={null}
          // header={null}
          onOk={this.detailFormOk}
          onCancel={this.detailFormCancel}
        >
          {sectionType === "baseinfo" ? (
            <BaseDetailForm
              detailData={this.state.detailData}
              viewGeography={this.viewGeography}
            ></BaseDetailForm>
          ) : (
              <GeographyDetailForm
                detailData={this.state.detailData}
                viewBase={this.viewBase}
                seeOnly={true}
              ></GeographyDetailForm>
            )}
        </Modal>
        <Modal
          destroyOnClose
          visible={this.state.showMap}
          className="searchResultModal geographyModal noBottomPadding"
          width="76.8%"
          footer={null}
          header={null}
          onOk={this.handleMapOk}
          onCancel={this.handleMapCancel}
          style={{ zIndex: 100 }}
        >
          <div>
            <p className="current-select">
              <span>当前选择:</span>
              {this.state.currentLng ? (
                <span>Lng:{this.state.currentLng}</span>
              ) : null}
              {this.state.currentLat ? (
                <span>Lat:{this.state.currentLat}</span>
              ) : null}
            </p>
            <div id="geographyMap"></div>
            <div
              className="geography-map-confirm"
              onClick={this.geologyMapLocation}
            >
              <Button
                type="gbdb"
                disabled={
                  this.state.currentLng == "" && this.state.currentLat == ""
                    ? true
                    : false
                }
              >
                Confirm the location
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
SectionGeography = Form.create({})(SectionGeography);
export default SectionGeography;
