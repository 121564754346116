import React from 'react'
import {Modal,Form,Button,Input, message} from 'antd'
import './resetPasswordModal.scss'
import axios from 'axios'


class AntdForm extends React.Component{
    constructor(props){
        super(props)
        this.state={
            confirmDirty: false,
        }
    }
    handleSubmit(){
        //保存密码
        const {modalData}=this.props
        this.props.form.validateFields((err, values) => {
            if (!err) {
                axios.get(`/api/user/changePassword`,{
                    params:{
                        password:values.newPassword2,
                        id:modalData.id,
                    }
                   
                }).then(res=>{
                    if(res.data.error===null){
                        message.success('保存成功！')
                    }
                }).catch(err=>{
                    message.error('出错啦~',err)
                })
                this.props.handleResetPassword()
            }
          });
       
       
    }
    handleCancel(){
        //取消
        this.props.cancelResetPassword()
    }
    selectBasicInfo(){
        this.setState({
            isSelectBasic:true,
            isSelectAdvanced:false,
        })
    }
    selectAdvancedInfo(){
        this.setState({
            isSelectBasic:false,
            isSelectAdvanced:true,
        })
    }
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('newPassword')) {
            callback('两次输入的密码不一致');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['newPassword'], { force: true });
        }
        callback();
    };
    handleConfirmBlur = e => {
      
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
      };
    render(){
        const { getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span:6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 },
            },
          };
        return (
            <div>
                <Modal 
                className='AModal resetPassword'
                visible={this.props.visible}
                onOk={this.props.handleResetPassword}
                onCancel={this.props.cancelResetPassword}
                footer={null}
                >
                  
                    <div className='form'>
                    <Form {...formItemLayout} >

                        {/* <Form.Item label='旧密码'>
                            {getFieldDecorator('oldPassword',{
                                rules:[{required:true}]
                                })(<Input.Password></Input.Password>)
                            }
                        </Form.Item> */}
                       
                        <Form.Item label='新密码'>
                            {getFieldDecorator('newPassword',{
                                rules:[{required:true},
                                    {validator:this.validateToNextPassword}]
                                })(<Input.Password></Input.Password>)
                            }
                        </Form.Item>
                        <Form.Item label='重复新密码' className='newPassword2'>
                            {getFieldDecorator('newPassword2',{
                                rules:[{required:true,message:'请重复新密码'},
                                    { validator: this.compareToFirstPassword,}]
                                })(<Input.Password onBlur={this.handleConfirmBlur}></Input.Password>)
                            }
                        </Form.Item>

                    </Form>
                    <div className='foot'>
                           <Button  type='agbdb1' onClick={this.handleSubmit.bind(this)}>保存</Button>
                           <Button  type='agbdb2' onClick={this.handleCancel.bind(this)}>取消</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
      
    }
}
const AresetPassword = Form.create()(AntdForm);
export default AresetPassword