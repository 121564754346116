import React, { Component } from "react";
import Utils from "src/js/utils";
import centerApis from "src/js/apis/center";
import BaseUpload from "commons/BaseUpload/index";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import Apis from "src/js/apis/api";
import Storage from "src/js/storage";
import AddGeochemicalForm from "components/Section/geochemical/addGeochemicalForm.js";
import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";

import SectionOption from "commons/AllOptions/Section/index.js";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";

import SectionAllTableResult from "commons/AllTableList/Section/SectionAll/index.js";
import "./index.css";
import { Form, Input, Button, DatePicker, Modal, InputNumber, List, message } from "antd";
import moment from "moment";
@withRouter
class GeochemicalDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inheritRefIds: "",

      uploadFileModal: false,
      addFormModal: false,
      ShowType: "list",
      refSearch: false,
      formationCondition: false,
      geologyInfo: {},

      baseId: "",
      geographyId: "",

      refCondition: false,
      geographySearch: false,
      sectionOptionVisible: false,
      sectionType: "baseinfo",
      uploadPath: "",

      GeochemBase: {},
      isCover: 1,
      allChildError: {},
      //
      detailData: props.detailData || {},
      onlySee: props.onlySee === true ? true : false,
      dataList: [],
      // 分页
      loading: false,
      hasMore: true,
      pageSize: 10,
      currentPage: 1,
      dataTotal: 0,
      showGeochemicalData: false,

      isCenterUser: props.isCenterUser === true ? true : false,

      allFiles: [],
      defaultFiles: [],
      uploadFiles: [],
    };
  }
  async componentDidMount() {
    let { detailData } = this.state;
    let GeochemicalsResult;
    let id = detailData.id;
    let section_basic_id = detailData.section_basic_id;
    let { isCenterUser } = this.state;
    // let isCenter = this.props.location.pathname === "/center";
    if (!isCenterUser) {
      let params = {
        id,
      };
      GeochemicalsResult = await Apis.getGeochemicals(params);
    } else {
      let params = {
        assetstype: 6,
        enterer: Storage.getItem("gbdbid"),
        id,
      };
      GeochemicalsResult = await centerApis.searchAssets(params);
    }

    if (GeochemicalsResult.result) {
      let haveUploaded = GeochemicalsResult.result[0].upload_path || "";
    
      this.setState(
        {
          detailData: GeochemicalsResult.result[0] || {},
          geochemistryoccurrenceId: GeochemicalsResult.result[0].id || "",
          uploadPath: haveUploaded,
          OccurrenceId: GeochemicalsResult.result[0].id || "",
        },
        () => {
          if (haveUploaded !== "") {
            this.setState({
              allFiles: [
                {
                  uid: -1,
                  name: haveUploaded,
                  status: "done",
                  url: `/api` + haveUploaded,
                  linkProps: { download: "" },
                },
              ],
            });
          }
        }
      );
    } else {
      Modal.error({
        content: GeochemicalsResult.error,
      });
    }
    if (section_basic_id) {
      let getGeologyBack;
      if (!isCenterUser) {
        let sectionParams = {
          section_basic_id,
        };
        getGeologyBack = await Apis.getGeology(sectionParams);
      } else {
        // let params = {
        //   assetstype: 13,
        //   enterer: Storage.getItem("gbdbid"),
        //   section_basic_id: section_basic_id
        // };
        // getGeologyBack = await centerApis.searchAssets(params);
        let sectionParams = {
          section_basic_id,
        };
        getGeologyBack = await Apis.getGeology(sectionParams);
      }

      if (getGeologyBack.result) {
        this.setState({
          geologyInfo: getGeologyBack.result[0],
          baseId: section_basic_id,
        });
      } else {
        this.setState({
          geologyInfo: {},
        });
      }
    }
  }
  deleteGeochemFile = async () => {
    let that = this;
    let { detailData } = this.state;
    Modal.confirm({
      title: "Do you want to delete the file?",
      content: "Unrecoverable after deletion!!!",
      async onOk() {
        let params = {
          id: detailData.id,
          upload_path: detailData.upload_path,
        };
        let deleteFileBack = await Apis.deleteGeochemFile(params);

        if (deleteFileBack.result) {
          let { allFiles } = that.state;

          allFiles.splice(0, 1);
          that.setState({
            allFiles,
            uploadPath: "",
          });
        } else {
          if (deleteFileBack.error) {
            Modal.error({
              content: deleteFileBack.error,
            });
          }
        }
      },
      onCancel() {},
    });
  };
  removeFile = async (file, index) => {
    let that = this;
    let { detailData } = this.state;
    Modal.confirm({
      title: "Do you want to delete the file?",
      content: "Unrecoverable after deletion!!!",
      async onOk() {
        if (file.uid === -1) {
          let params = {
            id: detailData.id,
            upload_path: detailData.upload_path,
          };
          let deleteFileBack = await Apis.deleteGeochemFile(params);

          if (deleteFileBack.result) {
            let { allFiles } = that.state;

            allFiles.splice(index, 1);
            that.setState({
              allFiles,
              uploadPath: "",
            });
          } else {
            if (deleteFileBack.error) {
              Modal.error({
                content: deleteFileBack.error,
              });
            }
          }
        } else {
          let { allFiles, uploadFiles } = that.state;

          allFiles.splice(index, 1);
          uploadFiles.splice(index, 1);
          that.setState({
            allFiles,
            uploadFiles,
            uploadPath: "",
          });
        }
      },
      onCancel() {},
    });
  };

  onChangeFile = (file, isCover) => {
    let { uploadFiles } = this.state;
    let newest = file;


    this.setState(
      {
        uploadFiles: [newest],
        isCover,
      },
      () => {
        this.setState({
          allFiles: this.state.uploadFiles,
        });
      }
    );
  };
  confirmUploadFile = async () => {
    let { geochemistryoccurrenceId } = this.state;
    const formData = new FormData();
    formData.append("geochemistryoccurrence_id", geochemistryoccurrenceId);
    let { uploadFiles } = this.state;

    if (uploadFiles.length > 0) {
      uploadFiles.map((item, index) => {
        formData.append("files", item);
      });
    }
    let enterer = Storage.getItem("gbdbid");
    if (this.state.isCover === 1) {
      formData.append("isCover", this.state.isCover);
    }
    if (enterer) {
      formData.append("enterer", enterer);
    }
    let uploadBack = await Apis.uploadGeochemData(formData);

    if (uploadBack.error) {
      Modal.error({
        title: "This is an error message",
        content: uploadBack.error,
      });
    } else {
      const modal = Modal.success({
        title: "This is a success message",
        content: "successfully upload ",
      });
      setTimeout(() => {
        modal.destroy();
        this.setState({
          uploadFileModal: false,
          uploadFiles: [],
          // uploadPath:'',
          // allFiles:[]

          uploadPath: uploadBack.upload_path,
        });
      }, 1000);
    }
  };
  modifyGeochemicalBase = async () => {
    let { geochemistryoccurrenceId } = this.state;
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let createGeochemOccurance = Utils.filterCondition(values);
        createGeochemOccurance = Utils.addEnterer(createGeochemOccurance);
        let searchParams = {
          section_id: values.section_basic_id,
        };
        let sectionBase = await Apis.sectionBase(searchParams);
        let sectionData = {};
        if (sectionBase.result && sectionBase.result.length > 0) {
          sectionData = sectionBase.result[0];
        }
        createGeochemOccurance = Utils.addAudit(createGeochemOccurance, sectionData);
        createGeochemOccurance.id = geochemistryoccurrenceId;
        let modifyGeochemicalBack = await Apis.changeGeochemicalById(createGeochemOccurance);

        if (modifyGeochemicalBack.result) {
          this.setState(
            {
              geochemistryoccurrenceId: modifyGeochemicalBack.result.id,
              GeochemBase: modifyGeochemicalBack.result,
              allChildError: {},
            },
            () => {
              this.props.modifyGeochemical(modifyGeochemicalBack.result);
            }
          );
        } else if (modifyGeochemicalBack.error) {
          Modal.error({
            title: "This is an error message",
            content: modifyGeochemicalBack.error,
          });
          this.setState({
            geochemistryoccurrenceId: "",
            GeochemBase: {},
          });
        }
      }
    });
  };
  showSearchRef = (e) => {
    e.target.blur();
    this.setState({
      refCondition: true,
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false,
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false,
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false,
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false,
    });
  };
  clickRefId = (value) => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value,
    });
  };
  onRef = (ref) => {
    this.child = ref;
  };
  onGeography = (ref) => {
    this.geographyChild = ref;
  };
  getDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true,
    });

    //点击 detail 按扭执行代码
  };
  geographyDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showSectionDetailForm: true,
    });
  };
  handFormOk = () => {
    this.setState({
      sectionOptionVisible: false,
    });
  };
  handleFormCancel = () => {
    this.setState({
      sectionOptionVisible: false,
    });
    Modal.destroyAll();
  };

  checkAgain = (optionType) => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    let showType = "refSearch";

    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      // if (optionType === "reference") {
      //   isError = this.state.errorRef;
      // }
      if (optionType === "reference") {
        showType = "refSearch";
      } else {
        showType = "geographySearch";
      }

      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            sectionOptionVisible: false,
            [showType]: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType,
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues, sectionType } = this.state;

              if (optionType === "reference") {
                this.child.searchReferenceDatas(searchParams, searchValues);
              } else {
                this.geographyChild.searchSectionDatas(searchParams, searchValues, sectionType);
              }
            }, 20);
          }
        );
      }
    });
  };
  showSearchGeography = (type, e) => {
    e.target.blur();

    this.setState(
      {
        sectionType: type,
      },
      () => {
        this.setState({
          sectionOptionVisible: true,
        });
      }
    );
  };
  handleGeographyOk = () => {
    this.setState({
      geographySearch: false,
    });
  };
  handleGeographyCancel = () => {
    this.setState({
      geographySearch: false,
    });
  };
  handleFormChange = (changedFields) => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields },
    }));
  };
  clickGeographyId = (type, value) => {
    this.setState({
      geologyInfo: value,
      geographySearch: false,
    });
    this.props.form.setFieldsValue({
      geology_id: value.geology_id || "",
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || "",
    });
  };
  clickSectionId = (value) => {
    this.setState({
      baseInfo: value,
      geographySearch: false,
    });
    this.props.form.setFieldsValue({
      geology_id: value.geology_id || "",
      section_basic_id: value.section_id || "",
      ref_id: value.ref_id || "",
    });
  };
  checkRefOne = (value) => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value,
    });
  };

  checkGeographyOne = (value) => {
    this.props.form.setFieldsValue({
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || "",
      geology_id: value.geology_id || "",
    });
    this.setState(
      {
        inheritRefIds: value.ref_id,
        baseId: value.section_basic_id,
        geographyId: value.geology_id,
        geologyInfo: value,
        geographySearch: false,
      },
      () => {
        // 去获取数据
      }
    );
  };
  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType,
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType,
    });
  };

  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false,
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false,
    });
  };
  detailSectionFormOk = () => {
    this.setState({
      showSectionDetailForm: false,
      // sectionType: "baseinfo"
    });
  };
  detailSectionFormCancel = () => {
    this.setState({
      showSectionDetailForm: false,
      // sectionType: "baseinfo"
    });
  };

  viewGeochemicalData = () => {
    let { detailData } = this.state;
    let geochemialId = detailData.id;
    let refId = detailData.ref_id;
    let baseId = detailData.section_basic_id;
    let url = "/views/geochemical?";

    let refUrl = refId ? "refId=" + refId : "";

    let baseIdUrl = baseId ? "&baseId=" + baseId : "";
    let geochemialUrl = geochemialId ? "&geochemialId=" + geochemialId : "";

    let params = `${url}${refUrl}${baseIdUrl}${geochemialUrl}`;
    const w = window.open("about:blank");
    w.location.href = params;
    // this.props.history.push(params);
  };
  childError = (allChildError) => {
    this.setState({
      allChildError,
    });
  };
  getDatas = async (pageParams, callback) => {
    let { geochemialType, OccurrenceId } = this.state;
    let params = {
      geochemistry_type: geochemialType,
      geochemistryoccurrence_id: OccurrenceId,
    };
    let userParams = {};
    let enterer = Storage.getItem("gbdbid");

    let isCenter = this.props.location.pathname === "/center";
    if (enterer && isCenter) {
      userParams = {
        enterer,
      };
    }
    let searchParams = {};
    Object.assign(searchParams, params, pageParams, userParams);

    let getGeochemDataList = await Apis.getGeochemDataList(searchParams);

    //获取Select data的接口
    if (getGeochemDataList.result) {
      callback(getGeochemDataList);
    } else {
      Modal.error({
        title: "This is an error message",
        content: getGeochemDataList.error,
      });
    }
  };
  initData = async (pageParams) => {
    let { geochemialType, OccurrenceId } = this.state;
    let params = {
      geochemistry_type: geochemialType,
      geochemistryoccurrence_id: OccurrenceId,
    };
    let userParams = {};
    let enterer = Storage.getItem("gbdbid");

    let isCenter = this.props.location.pathname === "/center";
    if (enterer && isCenter) {
      userParams = {
        enterer,
      };
    }

    let searchParams = {};
    Object.assign(searchParams, params, pageParams, userParams);

    let getGeochemDataList = await Apis.getGeochemDataList(searchParams);

    //获取Select data的接口
    if (getGeochemDataList.result) {
      return getGeochemDataList;
    } else {
      Modal.error({
        title: "This is an error message",
        content: getGeochemDataList.error,
      });
    }
  };
  showGeochemicalDatalist = async () => {
    let pageParams = {
      pageSize: this.state.pageSize,
      // page: this.state.currentPage
      page: 1,
      // enterer:Storage.getItem("gbdbid"),
    };

    let getGeochemDataList = await this.initData(pageParams);
    if (getGeochemDataList.result) {
      this.setState({
        dataList: getGeochemDataList.result,
        dataTotal: getGeochemDataList.total,
      });
    }
    this.setState({
      showGeochemicalData: true,
    });
  };
  handleGeochemicalData = () => {
    this.setState({
      showGeochemicalData: false,
      currentPage: 1,
    });
  };
  cancelGeochemicalData = () => {
    this.setState({
      showGeochemicalData: false,
      currentPage: 1,
    });
  };
  getGeochemicalDataDetail = async (item, index) => {
    let { id } = item;

    this.setState({
      addFormModal: true,
      currentDataIndex: index,
      geochemistryoccurrenceItemId: id,
    });
  };
  deleteGeochemicalData = async (item, index) => {
    let that = this;
    Modal.confirm({
      title: "prompt for action!!!",
      content: "Unrecoverable after deletion",
      async onOk() {
        let params = {
          id: item.id,
        };
        let deleteDataById = await Apis.deleteDataById(params);
        if (deleteDataById.result) {
          Modal.success({
            content: "successfully delete ",
          });
          let { dataList, dataTotal, currentPage } = that.state;
          dataTotal = dataTotal - 1;
          dataList.splice(index, 1);

          that.setState(
            {
              dataList,
              dataTotal,
            },
            () => {
              let { currentPage, dataTotal, dataList, pageSize } = that.state;
              if (dataList.length < dataTotal) {
                let pageParams = {
                  pageSize: 1,
                  page: currentPage * pageSize,
                };
                that.getDatas(pageParams, (res) => {
                  dataList = dataList.concat(res.result);
                  that.setState({
                    dataList,
                    loading: false,
                  });
                });
              }
            }
          );
        }
      },
      onCancel() {
        
      },
    });
  };
  handleInfiniteOnLoad = () => {
    let { dataList, dataTotal } = this.state;

    this.setState({
      loading: true,
    });

    if (dataList.length >= dataTotal) {
      message.warning("Infinite List loaded all");
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }
    let { pageSize, currentPage } = this.state;
    this.setState(
      {
        currentPage: currentPage + 1,
      },
      () => {
        let pageParams = {
          pageSize,
          page: this.state.currentPage,
        };
        this.getDatas(pageParams, (res) => {
          dataList = dataList.concat(res.result);
          this.setState({
            dataList,
            loading: false,
          });
        });
      }
    );
  };
  uploadGeochemForm = () => {
    this.setState({
      addFormModal: false,
    });
  };
  cancelUploadForm = () => {
    this.setState({
      addFormModal: false,
    });
  };
  changeGeochemicalData = (item) => {
    let { currentDataIndex, dataList } = this.state;
    dataList[currentDataIndex] = item;
    this.setState({
      dataList,
      addFormModal: false,
    });
  };
  showUpload = async () => {
    let param = {
      id: this.state.geochemistryoccurrenceId,
    };
    let createGeochemBack = await Apis.getGeochemicals(param);
    if (createGeochemBack.result) {
      let uploadPath = createGeochemBack.result[0].upload_path;
      if (uploadPath) {
        let oldFile = {};
        oldFile = {
          uid: -1,
          name: uploadPath,
          status: "done",
          url: `/api` + uploadPath,
          data: `/api` + uploadPath,
          linkProps: { download: "" },
        };
        this.setState({
          uploadPath,
          // uploadFiles:[oldFile],
          defaultFiles: [oldFile],
          // allFiles: [oldFile]
        });
      }
    }
    this.setState({
      uploadFileModal: true,
      uploadFiles: [],
    });
  };
  uploadGeochem = () => {
    this.setState({
      uploadFileModal: false,
    });
  };

  cancelUpload = () => {
    this.setState({
      uploadFileModal: false,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let { geologyInfo, geochemistryoccurrenceId, uploadPath, detailData, dataList } = this.state;
    let latitude = geologyInfo ? geologyInfo.geology_latitude_decimal : null;
    let longitude = geologyInfo ? geologyInfo.geology_longitude_decimal : null;

    return (
      <div id="detail_form" className="section-common">
        <div className="detailform-title">
          <span className="detailform-name">Geochemical</span>
        </div>
        <div className="reference-add-form geochemical-modify-form" id="main">
          <Form>
            <div className="referenceAdd-title">
              <Form.Item label="Reference ID">
                {getFieldDecorator("ref_id", {
                  rules: [{ type: "number" }],
                  initialValue: detailData ? Number(detailData.ref_id) : "",
                })(<InputNumber disabled onFocus={this.showSearchRef}></InputNumber>)}
              </Form.Item>
            </div>
            <div className="referenceAdd-title">
              <Form.Item label="Section ID">
                {getFieldDecorator("section_basic_id", {
                  rules: [{ type: "number" }],
                  initialValue: detailData ? Number(detailData.section_basic_id) : "",
                })(<InputNumber disabled className="inherit-input" onFocus={(e) => this.showSearchGeography("baseinfo", e)}></InputNumber>)}
              </Form.Item>
            </div>

            <div className="referenceAdd-volumeNo">
              <div className="referenceAdd-volume geochemical-country">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p>Latitude Decimal</p>
                    </div>
                  }
                >
                  {getFieldDecorator("latitude_decimal", {
                    rules: [{ type: "number" }],
                    initialValue: latitude,
                  })(<InputNumber disabled={true}></InputNumber>)}
                </Form.Item>
              </div>
              <div className="referenceAdd-no geochemical-country">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p>Longtitude Decimal</p>
                    </div>
                  }
                >
                  {getFieldDecorator("longitude_decimal", {
                    rules: [{ type: "number" }],
                    initialValue: longitude,
                  })(<InputNumber disabled={true}></InputNumber>)}
                </Form.Item>
              </div>
            </div>

            <div className="referenceAdd-volumeNo">
              <div className="referenceAdd-volume geochemical-country">
                <Form.Item label="Country/Continent">
                  {getFieldDecorator("country", {
                    rules: [
                      // {
                      //   required: true,
                      //   message: "Please input the Country/Continent"
                      // }
                    ],
                    initialValue: longitude,
                  })(<Input disabled={true} />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-no geochemical-country">
                <Form.Item label="Province/State">
                  {getFieldDecorator("province", {
                    rules: [
                      {
                        // required: true,
                        // message: "Please input the Province/State"
                      },
                    ],
                    initialValue: geologyInfo ? geologyInfo.geology_province : "",
                  })(<Input disabled={true} />)}
                </Form.Item>
              </div>
            </div>
            <div className="referenceAdd-publication">
              <Form.Item label="Release Date">
                {getFieldDecorator("release_date", {
                  rules: [{ type: "object", required: true }],
                  initialValue: detailData ? moment(detailData.release_date, "YYYY-MM-DD") : null,
                })(<DatePicker allowClear={false} disabledDate={Utils.disabledDate}></DatePicker>)}
              </Form.Item>
            </div>
            {uploadPath !== "" ? (
              <div className="referenceAdd-authours referenceAdd-editors">
                <div className="authcomment-details">
                  <div className="referenceAdd-author authcomment-detail-item">
                    <div className="referenceAdd-author-title authcomment-title">Uploaded file</div>
                    <div className="authcomment-content">
                      <a href={`/api/${uploadPath}`} download="geochemicalFile">
                        {uploadPath}
                      </a>
                      <div className="geochemicalFile-icons">
                        <span className="iconfont  icon-cloudupload" onClick={() => this.showUpload()}></span>
                        <span className="iconfont  icon-delete" onClick={() => this.deleteGeochemFile()}></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="referenceAdd-authours referenceAdd-editors">
                <div className="authcomment-details">
                  <div className="referenceAdd-author authcomment-detail-item">
                    <div className="referenceAdd-author-title authcomment-title">Uploaded file</div>
                    <div className="authcomment-content">
                      <a>No file</a>
                      <div className="geochemicalFile-icons">
                        <span className="iconfont  icon-cloudupload" onClick={() => this.showUpload()}></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {detailData.auth_time !== null ? (
              <div className="referenceAdd-authours referenceAdd-editors">
                <div className="authcomment-details">
                  {detailData.authState === 2 ? (
                    <div className="referenceAdd-author authcomment-detail-item">
                      <div className="referenceAdd-author-title authcomment-title">Auth Comment</div>
                      <div className="authcomment-content">{detailData.auth_comment !== "" ? detailData.auth_comment : "No comment"}</div>
                    </div>
                  ) : null}
                  {detailData.authState !== 0 ? (
                    <div className="referenceAdd-author authcomment-detail-item">
                      <div className="referenceAdd-author-title authcomment-title">Auth Time</div>
                      <div className="authcomment-content">{detailData.auth_time ? detailData.auth_time.split("T")[0] : ""}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            <div className="add-geochemical-occurrence">
              <div className="referenceAdd-volume">
                <div className="geochemical-search detail-geochemical-search">
                  <Button type="gbdb" disabled={this.state.baseId ? false : true} onClick={() => this.modifyGeochemicalBase()}>
                    <p>Save modify</p>
                  </Button>
                  <Button type="gbdb" disabled={this.state.baseId ? false : true} onClick={() => this.showGeochemicalDatalist()}>
                    <p>Show geochemical datalist</p>
                  </Button>
                  {this.state.baseId ? null : <p className="geochemical-uploadtip">input section info first</p>}
                </div>
              </div>
            </div>
          </Form>

          <div className="formation-complete-btns">
            <Button type="gbdb" onClick={() => this.viewGeochemicalData()}>
              <p>View geochemical</p>
            </Button>
          </div>
        </div>

        <div className="geochemical-upload-file">
          <Modal
            destroyOnClose
            className="searchResultModal taxResultModal geochemical-upload-modal"
            width="78.6%"
            title={null}
            footer={null}
            visible={this.state.uploadFileModal}
            onOk={this.uploadGeochem}
            onCancel={this.cancelUpload}
          >
            <div className="geochemical-upload-content">
              <p className="geochemical-upload-title">Geochemical data type supported</p>

              <div className="geochemical-upload-icon">
                <BaseUpload
                  fileList={this.state.allFiles}
                  uploadFiles={this.state.uploadFiles}
                  multiple={false}
                  uploadTypes={[".xls", ".xlsx"]}
                  showImg={false}
                  listType="text"
                  showUploadList={true}
                  hasFile={this.state.uploadPath ? true : false}
                  onChangeFile={(file, isCover) => this.onChangeFile(file, isCover)}
                  removeFile={(file, index) => this.removeFile(file, index)}
                ></BaseUpload>
              </div>

              <div className="geochem-upload-btn">
                <Button type="gbdb" disabled={this.state.uploadFiles.length > 0 ? false : true} onClick={() => this.confirmUploadFile()}>
                  <div>
                    <p> Upload</p>
                  </div>
                </Button>
                {this.state.uploadFiles.length <= 0 ? <p className="upload-csv-tip">Please upload a file</p> : null}
              </div>

              <div className="geochem-upload-tips">
                <p>Tip: Only supoort excel files.</p>
              </div>
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            destroyOnClose
            visible={this.state.refCondition}
            className="searchResultModal taxResultModal"
            width="76%"
            footer={null}
            header={null}
            onOk={this.handlerefConditionOk}
            onCancel={this.handlerefConditionCancel}
          >
            {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}
            <ReferenceOption conditions={{}} ref="reference"></ReferenceOption>

            {
              <div className="referenceSearch-btns">
                <div>
                  <Button
                    type="gbdb"
                    onClick={() => {
                      this.checkAgain("reference");
                    }}
                  >
                    <p> Search</p>
                  </Button>
                </div>
              </div>
            }
          </Modal>

          <Modal
            destroyOnClose
            visible={this.state.refSearch}
            className="searchResultModal taxResultModal searchListModal tableListModal"
            width="76%"
            footer={null}
            header={null}
            onOk={this.handleRefOk}
            onCancel={this.handleRefCancel}
          >
            <ReferenceTableResult
              searchParams={this.state.searchParams}
              searchValues={{}}
              onRef={this.onRef}
              checkOne={true}
              checkRefOne={this.checkRefOne}
              getDetail={this.getDetail}
              onlySeeRef={true}
              // changeCheckSelected={this.changeCheckSelected}
            ></ReferenceTableResult>
          </Modal>

          <Modal
            title=""
            destroyOnClose
            visible={this.state.showRefDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76.8%"
            footer={null}
            // header={null}
            onOk={this.detailRefFormOk}
            onCancel={this.detailRefFormCancel}
          >
            <ReferenceDetailForm
              onlySeeRef={true}
              detailData={this.state.detailData}
              // searchSection={this.searchSection}
            ></ReferenceDetailForm>
          </Modal>

          {/* section */}

          <Modal
            destroyOnClose
            className="searchResultModal"
            width="76%"
            visible={this.state.sectionOptionVisible}
            footer=""
            // closable={false}
            onOk={this.handFormOk}
            onCancel={this.handleFormCancel}
          >
            <SectionOption
              childError={this.childError}
              showGeography={this.state.sectionType === "geology" ? true : false}
              showBase={this.state.sectionType === "baseinfo" ? true : false}
              showUnit={false}
              ref="baseinfo"
            ></SectionOption>

            {
              <div className="referenceSearch-btns">
                <div>
                  <Button
                    type="gbdb"
                    onClick={() => {
                      this.checkAgain("baseinfo");
                    }}
                  >
                    {<p> Search</p>}
                  </Button>
                </div>
              </div>
            }
          </Modal>

          <Modal
            destroyOnClose
            visible={this.state.geographySearch}
            className="searchResultModal taxResultModal noBottomPadding tableListModal"
            width="76%"
            footer={null}
            header={null}
            onOk={this.handleGeographyOk}
            onCancel={this.handleGeographyCancel}
          >
            <SectionAllTableResult
              searchParams={this.state.searchParams}
              searchValues={this.state.searchValues}
              onGeography={this.onGeography}
              changeCheckSelected={this.changeCheckSelected}
              getDetail={this.geographyDetail}
              checkOne={true}
              checkGeographyOne={this.checkGeographyOne}
            ></SectionAllTableResult>
          </Modal>
          <Modal
            title=""
            destroyOnClose
            visible={this.state.showSectionDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76%"
            footer={null}
            // header={null}
            onOk={this.detailSectionFormOk}
            onCancel={this.detailSectionFormCancel}
          >
            {this.state.sectionType === "baseinfo" ? (
              <BaseDetailForm detailData={this.state.detailData} viewGeography={this.viewGeography} seeOnly={true}></BaseDetailForm>
            ) : this.state.ectionType === "geology" ? (
              <GeographyDetailForm seeOnly={true} detailData={this.state.detailData} viewBase={this.viewBase}></GeographyDetailForm>
            ) : null}
          </Modal>
        </div>

        <Modal
          destroyOnClose
          visible={this.state.showGeochemicalData}
          className="searchResultModal taxResultModal geochemistry-showclist-modal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleGeochemicalData}
          onCancel={this.cancelGeochemicalData}
        >
          <div className="sectionBase-show-charts">
            <div className="show-list-data">
              <InfiniteScroll initialLoad={false} pageStart={0} loadMore={this.handleInfiniteOnLoad} hasMore={!this.state.loading && this.state.hasMore} useWindow={false}>
                <List
                  header={
                    <div className="show-list-item">
                      <span className="datalist-serial">Index</span>
                      <span className="datalist-serial">Id</span>
                      <span>Geochemistry type</span>
                      <span>Depth</span>
                      <span>Value</span>
                      <span>Enterer</span>
                      <span>Detail</span>
                      <span></span>
                    </div>
                  }
                >
                  {dataList.map((item, index) => {
                    return (
                      <List.Item key={index}>
                        <div className="show-list-item center-geochemistrylist-item">
                          <span className="datalist-serial">{index + 1}</span>
                          <span className="datalist-serial">{item.id}</span>
                          <span>{item && item.geochemistry_type ? item.geochemistry_type : ""}</span>
                          <span>
                            {item && item.depthfrom !== undefined ? item.depthfrom : ""}
                            {(item && item.depthfrom !== undefined) || (item && item.depthto !== undefined) ? "~" : ""}
                            {item && item.depthto !== undefined ? item.depthto : ""}
                            {item && item.depth_unit !== undefined ? item.depth_unit : ""}
                          </span>
                          <span>
                            {item && item.value ? item.value : ""}
                            {item && item.value_unit ? item.value_unit : ""}
                            {"±0"}
                          </span>
                          <span>{item && item.enterer_username ? item.enterer_username : ""}</span>
                          <span>
                            <Button type="gbdb" onClick={() => this.getGeochemicalDataDetail(item, index)}>
                              <div>Details</div>
                            </Button>
                          </span>
                          <span>
                            <span onClick={(e) => this.deleteGeochemicalData(item, index)} className="iconfont icon-delete"></span>
                          </span>
                        </div>
                      </List.Item>
                    );
                  })}
                </List>
              </InfiniteScroll>
            </div>
          </div>
        </Modal>
        <Modal
          destroyOnClose
          className="searchResultModal taxResultModal"
          width="78.6%"
          title={null}
          footer={null}
          visible={this.state.addFormModal}
          onOk={this.uploadGeochemForm}
          onCancel={this.cancelUploadForm}
        >
          {/* <div className="reference-search-form search-common-form geochemical-upload-form"> */}
          <div className="reference-search-content reference-search-baseform">
            <AddGeochemicalForm
              inheritRefIds={this.state.inheritRefIds}
              geochemistryoccurrenceItemId={this.state.geochemistryoccurrenceItemId}
              geochemistryoccurrenceId={this.state.OccurrenceId}
              changeGeochemicalData={this.changeGeochemicalData}
              canModify={true}
            ></AddGeochemicalForm>
          </div>
          {/* </div> */}
        </Modal>
      </div>
    );
  }
}

GeochemicalDetailForm = Form.create({})(GeochemicalDetailForm);
export default GeochemicalDetailForm;
