import React, { Component } from 'react';
import {Form,Input,DatePicker, Button,Pagination, Modal, message,Select } from 'antd'
import './index.scss'
import ShowInfoModal from './sectionModal'
import axios from 'axios'
import moment from 'moment'

const {RangePicker}=DatePicker
const {Option} = Select

 class AntdForm extends Component {
    constructor(props){
        super(props);
        this.state={
            tableData:[],
            nowPage:1,
            defaultPageSize:10,
            isShowInfo:false,
            sendData:{},
            basicInfo:{},
            isAddAuthor:false,
            ispublic:false,
            sectionId:null,
            userList:[],
            isResponse:false
        }
    }
    componentDidMount(){
        var id=JSON.parse(localStorage.getItem('gbdbid')).value;
        axios.get(`/api/admin/review/enterers?authorizer_id=${id}`).then(res=>{
            if(res.data.error===null){
                this.setState({
                    userList:res.data.result
                })
            }
          
        })
    }
    getSection(sendData){
        axios.get('/api/admin/searchAssets',{
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                this.setState({
                    tableData:res.data.result,
                    total:res.data.total,
                    sendData,
                    isResponse:true
                })
            }
            if(res.data.result.length===0){
                // message.warning('没有数据！')

            }
            
        }).catch(err=>{
            message.error('出错啦!',err)
        })
    }
    handleSubmit(ispublic,page=1){
        this.props.form.validateFields((err, values) => {
            if (!err) {
                
                this.setState({
                    nowPage:page,
                    ispublic,
                })
                var authorizer_id=JSON.parse(localStorage.getItem('gbdbid')).value //当前登录用户id
                var sendData={
                    page,
                    pageSize:this.state.defaultPageSize,
                    assetstype:1,
                }
                if(!ispublic){
                    sendData.authorizer_id=authorizer_id
                }else{
                    sendData.public=true;
                }
                if(values.referenceId){
                    sendData.ref_id=values.referenceId
                }
                if(values.creator){
                    sendData.enterer=values.creator
                }
                if(values.sectionName){
                    sendData.section_name=values.sectionName
                }
                if(values.createTime){
                    sendData.dateFrom=moment(values.createTime[0]).format('YYYY/MM/DD');
                    sendData.dateTo=moment(values.createTime[1]).format('YYYY/MM/DD');
                }
              
                this.getSection(sendData)
               
            }
        })
    }
    changePage(page,pageSize){
        this.setState({
            nowPage:page
        })
        var {ispublic}=this.state
       this.handleSubmit(ispublic,page)
    }
    showInfo(item){
        
        this.setState({
            basicInfo:item,
            sectionId:item.section_id,
        },()=>{
            this.setState({
                isShowInfo:true
            })
        })
        //modal
    }
    refreshList(){
        var {ispublic,nowPage}=this.state
        this.handleSubmit(ispublic,nowPage)
    }
    cancelShowInfo(){
        this.refreshList.bind(this)()
      this.setState({
            isShowInfo:false
        })
    
       
       
    }
    time(t){
        var arr=t.split('T');
        t=arr[0];
        return t
    }
    addAuditor(id){
        
        var authorizer_id=JSON.parse(localStorage.getItem('gbdbid')).value //当前登录用户id
        var sendData={
            id,
            authorizer_id,
            assetstype:1,
        }
        //添加审核者请求
        Modal.confirm({
            content:'点击“确定”后在筛选列表下审核相关信息！',
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                axios.get('/api/admin/verify/assets',{
                    params:sendData
                }).then(res=>{
                    if(res.data.error===null){
                        this.handleSubmit(true,this.state.page)
                    }
                }).catch(err=>{
                    message.error('this is an error message!')
                })
            }
        })

    }
    render() {
        let {defaultPageSize,nowPage,isShowInfo,total,sectionId,basicInfo,ispublic,userList} =this.state;
        function showTotal(total) {
            return `每页显示\xa0${defaultPageSize}\xa0条\xa0\xa0\xa0  共 ${total} 条记录`;
          }
        const { getFieldDecorator} = this.props.form;
        const {tableData,isResponse}=this.state;
        return (
            <div id='ASection'>
                <div className='title'>Section</div>
                <div className="form">
                    <Form layout='vertical'>


                        <Form.Item label='Section name'>
                            {
                                getFieldDecorator('sectionname',{
                                })(<Input></Input>)
                            }
                        </Form.Item>

                        <Form.Item label='创建人'>
                            {
                                getFieldDecorator('creator',{
                                })(<Select placeholder="请选择">
                                    <Option value=''>请选择</Option>
                                    {userList.map(item=>{
                                    return ( <Option key={item.id} value={item.id}>{item.username}</Option>)
                                    })}
                                   
                                </Select>)
                            }
                        </Form.Item>

                        <Form.Item label='创建时间'>
                            {
                                getFieldDecorator('createTime',{

                                })(<RangePicker></RangePicker>)
                            }
                        </Form.Item>

                        
                        <Form.Item label='Reference ID'>
                            {
                                getFieldDecorator('referenceId',{

                                })(<Input></Input>)
                            }
                        </Form.Item>

                        <Form.Item className='foot'>
                           <Button type='primary' onClick={()=>{this.handleSubmit(false)}}>筛选</Button>
                           <Button className='btn2' type='primary' onClick={()=>{this.handleSubmit(true)}}>公共池</Button>
                        </Form.Item>

                    </Form>
                   
                </div>
                {
                    tableData.length>0?(
                        <div className='table'>
                        <table>
                            <tbody>
                            <tr className='head'>
                                <td>序号</td>
                                <td>录入者</td>
                                <td>Section Name</td>
                                <td>Reference ID</td>
                                <td>创建时间</td>
                                <td>状态</td>
                                <td>操作</td>
                            </tr>
                            {
                                tableData.map((item,index)=>{
                                    return (
                                        <tr key={index} className={`tr ${index%2===0?'color':''}`}>
                                            <td>{(nowPage-1)*defaultPageSize+index+1}</td>
                                            <td>{item.enterer_username}</td>
                                            <td>{item.section_name}</td>
                                            <td>{item.ref_id}</td>
                                            <td>{this.time(item.createdAt)}</td>
                                            <td>未审核</td>
                                            {
                                                ispublic?
                                                (<td><Button type='link' onClick={this.addAuditor.bind(this,item.section_id)}>我来审核</Button></td>):
                                                (<td><Button type='link' onClick={this.showInfo.bind(this,item)}>查看</Button></td>)
                                            }
                                            
                                        </tr>
                                    )
    
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    ):null
                }
                {tableData.length===0 && isResponse ? <div className='pageTip'>暂无数据</div> :null}
                {
                    tableData.length>0?(
                        <div className='pages'>
                            <Pagination size='small' 
                            current={nowPage}
                            defaultPageSize={defaultPageSize} 
                            total={total} 
                            // showTotal={showTotal.bind(this)}
                            onChange={this.changePage.bind(this)}
                            ></Pagination>
                        </div>
                    ):null
                }
                {
                    isShowInfo?(
                        <ShowInfoModal
                            sectionId={sectionId}
                            basicInfo={basicInfo}
                            visible={isShowInfo}
                            cancelShowInfo={this.cancelShowInfo.bind(this)}
                        ></ShowInfoModal>
                    ):null
                }
               
            
            </div>
        
            );
    }
}
const ASection = Form.create()(AntdForm);
export default ASection

