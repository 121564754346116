import asyncAxios from "src/js/asyncAxios";


export default {
  collectAssets: async params => {
    let url = "/api/subset/createSubset";

    let collectResult = await asyncAxios.post(url, params);

    return collectResult;
  },
  deleteAssets: async params => {
    let url = "/api/subset/deleteSubset";

    let deleteAssets = await asyncAxios.post(url, params);

    return deleteAssets;
  },
  getAssetsInfolder: async params => {
    let url = "/api/subset/folder/assets";

    let getAssetsInfolder = await asyncAxios.get(url, params);

    return getAssetsInfolder;
  },
    moveAssetsFolder: async params => {
    let url = "/api/subset/assets/moveFolder";

    let moveAssets = await asyncAxios.post(url, params);

    return moveAssets;
  } ,

  // subset共享
 
  getGroupShareFolder: async params => {
    let url = "/api/subset/group/shareFolders";

    let ShareFolder = await asyncAxios.get(url, params);

    return ShareFolder;
  },

  getSubsetPoint:async params =>{
    let url = "/api/search/map/subsetPoint";
    let result = await asyncAxios.get(url, params);
    return result;
  },

  exportSinocor:async params =>{
    let url = "/tsc/subset/export/sinocor";
    let result = await asyncAxios.post(url, params);
    return result;
  }
};
