import React, { Component } from "react";
import { DatePicker } from "antd";
import moment from "moment";
class YearPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isopen: false,
      time: props.time ? props.time : null
    };
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.time !== this.props.time) {
      this.setState({
        time: nextProps.time
      });
    }
  };
  handlePanelChange = value => {
    this.setState(
      {
        time: value.format("YYYY"),
        isopen: false
      },
      () => {
       

        this.props.onChange(value);
      }
    );
  };

  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  clearValue = value => {
    this.setState(
      {
        time: null
      },
      () => {
        
        this.props.onChange(value);
      }
    );
  };

  render() {
    return (
      <div>
        <DatePicker
          disabled={this.props.disabled}
          value={
            this.state.time !== null ? moment(this.state.time, "YYYY") : null
          }
          open={this.state.isopen}
          mode="year"
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          format="YYYY"
          onOpenChange={this.handleOpenChange}
          onPanelChange={this.handlePanelChange}
          onChange={this.clearValue}
        />
      </div>
    );
  }
}
export default YearPicker;
