import React, { Component } from 'react';
import './index.css';
import asyncAxios from "src/js/asyncAxios";
async function fundingsFun() {
  let fundingList = await asyncAxios.get("/api/assets/informations/funding.json")
 
  return fundingList

}
export default class ResearchFund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fundingList: []
    };
  }
  async componentDidMount() {
    let that = this

    let funding = await fundingsFun()
    that.setState({
      fundingList: funding
    }, () => {
      
    })


  }
  render() {
    return (
      <div className="fund">
        <div className="title">Funding</div>
        <div className="fund-content">
          {
            this.state.fundingList.map((item, index) => {
              return (
                <div className="funding-tab" key={item.key}>


                  <div className="">

                    <div className="p">
                      <p className="p1">{item.value}</p>

                    </div>
                  </div>
                </div>
              )

            })
          }



        </div>
      </div>
    );
  }
}
