import React, { Component } from "react";
import { Button, Modal } from "antd";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";
import GeochemicalTableResult from "commons/AllTableList/Section/Geochemical/index.js";
import SubsetApis from "src/js/apis/subset";
import Storage from "src/js/storage";
import { NavLink } from "react-router-dom";

import Apis from "src/js/apis/api";

import Utils from "src/js/utils";

import "./index.css";
import "src/js/global.js";
class GeochemicalSearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResultVisible: false,

      pageSize: 16,
      totalNumbers: 0,
      AllPages: 0,
      currentPage: 1,

      conditions: {},
      detailData: {},
      showDetailForm: false,
      taxType: "Original",
      isCheckAll: false,
      checkedObj: {},
      resultLists: [],
      defaultCheckedList: [],

      sectionType: "baseinfo",
      allChildError: {},
      showBackIcon:false
    };
  }

  detailFormOk = () => {
    this.setState({
      showDetailForm: false
    });
  };
  detailFormCancel = () => {
    this.setState({
      showDetailForm: false
    });
  };
  showOptions = () => {
    this.setState({
      ResultVisible: true
    });
  };
  handFormOk = () => {
    this.setState({
      ResultVisible: false
    });
  };
  handleFormCancel = () => {
    this.setState({
      ResultVisible: false
    });
    Modal.destroyAll();
  };

  async componentDidMount() {
    let host = window.location.host;
    
    // let host2 = document.domain;
    let historyLength = this.props.history.length
    if (historyLength > 1) {
      this.setState({
        showBackIcon: true
      })
    }
    if (this.props.location.query) {
      const { searchParams, searchAllValues } = this.props.location.query;
      this.setState(
        {
          searchParams,
          searchAllValues
        },
        () => {
          setTimeout(() => {
            let { searchParams, searchAllValues, sectionType } = this.state;

            this.geochemialChild.searchSectionDatas(
              searchParams,
              searchAllValues,
              sectionType
            );
          }, 20);
        }
      );
    } 
     else  if (this.props.location.search) {
      let baseId =
        Utils.getSearch(this.props.location.search, "baseId") || "";
      let searchParams = {
        section_basic_id: baseId,
        uid:Storage.getItem("gbdbid"),
      };
      let searchAllValues = {
        // section_basic_id_from: Number(baseId),
        // section_basic_id_to: Number(baseId)
      };
      this.setState(
        {
          searchParams,
          searchAllValues
        },
        () => {
          let { searchParams, searchAllValues, sectionType } = this.state;

          this.geochemialChild.searchSectionDatas(
            searchParams,
            searchAllValues,
            sectionType
          );
        }
      );
    }
  }

  checkAgain = () => {
    this.refs.section.validateFieldsAndScroll(async (err, searchValues) => {
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            searchParams,
            searchValues
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchAllValues, sectionType } = this.state;

              this.geochemialChild.searchSectionDatas(
                searchParams,
                searchAllValues,
                sectionType
              );
            }, 20);
          }
        );
      }
    });
  };
  onGeochemial = ref => {
    this.geochemialChild = ref;
  };
  showOptions = () => {
    this.setState({
      ResultVisible: true
    });
  };

  getDetail = itemValue => {
    this.setState(
      {
        detailData: itemValue
        // showDetailForm: true
      },
      () => {
        // 化学页面跳页面
        let inheritRefIds = itemValue.ref_id ? itemValue.ref_id : "";
        let baseId = itemValue.section_basic_id
          ? itemValue.section_basic_id
          : "";
        let geochemialId = itemValue.id ? itemValue.id : "";

        let url = "/views/geochemical?";

        let refUrl = inheritRefIds ? "refId=" + inheritRefIds : "";

        let baseIdUrl = baseId ? "&baseId=" + baseId : "";
        let geochemialUrl = geochemialId ? "&geochemialId=" + geochemialId : "";

        let params = `${url}${refUrl}${baseIdUrl}${geochemialUrl}`;

        // this.props.history.push(params);
        const w=window.open('about:blank');
        w.location.href=params
      }
    );

    //点击 detail 按扭执行代码
  };
  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showDetailForm: true,
      sectionType
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showDetailForm: true,
      sectionType
    });
  };

  viewGeochemical = () => {};
  changeCheckSelected = checkedObj => {
    this.setState(
      {
        checkedObj
      },
      () => {}
    );
  };

  goHistoryBack = () => {
    // this.props.history.goBack();
  };
  render() {
    return (
      // <div id="SearchResult">
      //   <div className="SearchResult-main">
      <div id="SearchResult">
        <div className="SearchResult-main reference-search-form">
          <div className="head">
          {/* {
             this.state.showBackIcon?
             <span
             className="iconfont icon-back common-back-hide"
             onClick={this.goHistoryBack}
           ></span>
           :
           null
           } */}
            <span className="head-title">Geochemical Search Result</span>
          </div>
          {/* 
          <div className="option-btn">
            <Button type="gbdb">
              <div onClick={() => this.showOptions()}>Search option</div>
              <div id="searchModel">
                <Modal
                  className="searchResultModal"
                  width="76%"
                  visible={this.state.ResultVisible}
                  footer=""
                  // closable={false}
                  onOk={this.handFormOk}
                  onCancel={this.handleFormCancel}
                >
                  <SectionOption
                    conditions={conditions}
                    ref="section"
                  ></SectionOption>

                  {
                    <div className="referenceSearch-btns">
                      <div>
                        <Button
                          type="gbdb"
                          onClick={() => {
                            this.checkAgain();
                          }}
                        >
                          <p> Search section</p>
                        
                        </Button>
                      </div>
                    </div>
                  }
                </Modal>
              </div>
            </Button>
          </div> */}
          <GeochemicalTableResult
            searchParams={this.state.searchParams}
            searchValues={this.state.searchValues}
            onGeochemial={this.onGeochemial}
            changeCheckSelected={this.changeCheckSelected}
            getDetail={this.getDetail}
            showDeleteSubset={false}
            showAddSubset={true}
            showExportFile={true}
            checkOne={false}
          ></GeochemicalTableResult>

      
        </div>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailFormOk}
          onCancel={this.detailFormCancel}
        >
          <GeographyDetailForm
            viewGeochemical={this.viewGeochemical}
            detailData={this.state.detailData}
            viewBase={this.viewBase}
          ></GeographyDetailForm>
        </Modal>
      </div>
    );
  }
}

export default GeochemicalSearchResult;
