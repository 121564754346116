import React, { Component } from "react";
import { Button, Checkbox, Input, Modal, Radio, Spin,InputNumber ,message} from "antd";
import { withRouter } from "react-router-dom";
import Apis from "src/js/apis/api";
import Utils from "src/js/utils";
import Storage from "src/js/storage";
import SubsetApis from "src/js/apis/subset";
import AllFolders  from 'commons/AllFolders/index'
import "./index.css";

@withRouter
class GeochemicalTableResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResultVisible: false,

      detailData: {},
      showDetailForm: false,
      taxType: "Original",
      isCheckAll: false,
      checkedObj: {},

      defaultCheckedList: [],

      resultLists: [],
      pageSize: 16,
      AllPages: 0,
      checkOne: props.checkOne === false ? false : true,
      // askLoading:false,

      currentPage: 1,
      totalNumbers: 0,
      showAddSubset: props.showAddSubset === true ? true : false,
      showDeleteSubset: props.showDeleteSubset === true ? true : false,
      showDetail: props.showDetail === false ? false : true,
      downLoadFileLoading: false,
      showExportFile: props.showExportFile === true ? true : false,
      folderOperateShow:false
    };
  }
  componentDidMount() {
    if (this.props.onGeochemial) {
      this.props.onGeochemial(this);
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      searchParams: nextProps.searchParams,
      searchAllValues: nextProps.searchAllValues
    });

    if (nextProps.showDeleteSubset !== this.props.showDeleteSubset) {
      this.setState({
        showDeleteSubset: nextProps.showDeleteSubset
      });
    }
    if (nextProps.showAddSubset !== this.props.showAddSubset) {
      this.setState({
        showAddSubset: nextProps.showAddSubset
      });
    }
  }

  gotoPage = e => {
    if (e.keyCode === 13) {
      var page = parseInt(e.target.value);
      let { AllPages } = this.state;

      if (page < 1 || page > AllPages) {
         message.warning("Please enter the correct page number");
        return;
      } else {
        this.setState(
          {
            currentPage: page
          },
          () => {
            this.changePage(this.state.currentPage);
          }
        );
      }
    }
  };
  gotoPages=()=>{
    let pageDom=document.querySelector('.searchResult-select input')
    if(pageDom){
      let pageValue=pageDom.value
      if(pageValue){
        let  page= parseInt(pageValue);
        let { AllPages } = this.state;
        if (page < 1 || page > AllPages) {
           message.warning("Please enter the correct page number");
          return;
        } else {
          this.setState(
            {
              currentPage: page
            },
            () => {
              this.changePage(this.state.currentPage);
            }
          );
        }

      }
    }

  }
  toFirst = e => {
    this.setState(
      {
        currentPage: 1
      },
      () => {
        this.changePage(this.state.currentPage);
      }
    );
  };

  toPre = e => {
    if (this.state.currentPage === 1) {
      return;
    } else {
      this.setState(
        {
          currentPage: this.state.currentPage - 1
        },
        () => {
          this.changePage(this.state.currentPage);
        }
      );
    }
  };

  changePage = value => {
    this.setState(
      {
        currentPage: value,
        isCheckAll: false,
        askLoading: undefined,
        checkedObj: {},
        resultLists: []
      },
      () => {
        let { searchParams, values, sectionType } = this.state;
        let pathname = this.props.location.pathname;
        if (pathname === "/subset") {
          this.searchSubset(searchParams, values);
        } else {
          this.searchSectionDatas(searchParams, values, sectionType,'',this.state.currentPage);
        }
      }
    );
  };
  searchSectionDatas = async (searchParams, searchAllValues,parameter1,parameter2,page) => {
    if(page==undefined){
      this.setState({
        currentPage:1
      })
      
    }
    this.setState(
      {
        askLoading: true
      },
      async () => {
        let searchResult = [];
        let searchCondition = {};
        let pageParams = {
          page: this.state.currentPage,
          pageSize: this.state.pageSize
        };
        let searchParamsCopy = Utils.deepObjCopy(searchParams);

        Object.assign(searchCondition, searchParamsCopy, pageParams);

        searchResult = await Apis.searchGeochemicals(searchCondition);

        if (searchResult.error) {
          this.setState({
            askLoading: undefined
          });
          Modal.error({
            title: "This is an error message",
            content: searchResult.error
          });
        } else if (searchResult.result) {
          let result = searchResult.result;
          let totalNumbers = searchResult.total;
          let { pageSize } = this.state;
          let AllPages = Math.ceil(totalNumbers / pageSize);
          this.setState(
            {
              resultLists: result,
              totalNumbers,
              AllPages,
              conditions: searchAllValues,
              askLoading: false
            },
            () => {}
          );
        }
      }
    );
  };
  toNext = e => {
    if (this.state.currentPage === this.state.AllPages) {
      return;
    } else {
      this.setState(
        {
          currentPage: this.state.currentPage + 1
        },
        () => {
          this.changePage(this.state.currentPage);
        }
      );
    }
  };

  CheckAll = e => {
    let { resultLists, checkedObj } = this.state;
    if (e.target.checked === true) {
      this.setState(
        {
          isCheckAll: true
        },
        () => {
          resultLists.map((item, index) => {
            checkedObj[item.id] = {
              item,
              index
            };
          });
          this.setState(
            {
              checkedObj
            },
            () => {
              let { checkedObj, resultLists } = this.state;
              if (this.props.changeCheckSelected) {
                this.props.changeCheckSelected(checkedObj, resultLists);
              }
            }
          );
        }
      );
    } else {
      this.setState(
        {
          isCheckAll: false
        },
        () => {
          this.setState(
            {
              checkedObj: {}
            },
            () => {
              let { checkedObj, resultLists } = this.state;
              this.props.changeCheckSelected(checkedObj, resultLists);
            }
          );
        }
      );
    }
  };

  itemCheckbox = (item, index, event) => {
    let { resultLists, checkedObj } = this.state;
    let nameId = Number(event.target.name);
    if (event.target.checked === true) {
      checkedObj[nameId] = {
        item,
        index
      };
    } else if (event.target.checked === false && checkedObj[nameId]) {
      delete checkedObj[nameId];
    }

    let checkedLength = Object.keys(checkedObj).length;

    this.setState(
      {
        isCheckAll: checkedLength === resultLists.length
      },
      () => {
        if (this.props.checkOne) {
          if (this.props.checkGeochemistryOne) {
            this.props.checkGeochemistryOne(item);
          }
        } else {
          let { checkedObj, resultLists } = this.state;
          this.props.changeCheckSelected(checkedObj, resultLists);
        }
      }
    );
  };

  getDetail = detailItem => {
    if (this.props.getDetail) {
      this.props.getDetail(detailItem);
    }
  };
  searchSubset = async searchParams => {
    this.setState(
      {
        askLoading: true
      },
      async () => {
        let pageSize = 11;
        let searchCondition = {};
        let pageParams = {
          page: this.state.currentPage,
          pageSize
        };
        Object.assign(searchCondition, searchParams, pageParams);
        let folderResBack = await SubsetApis.getAssetsInfolder(searchCondition);
        if (folderResBack.error) {
          this.setState({
            askLoading: undefined
          });
          const modal = Modal.error({
            title: "This is an error message",
            content: folderResBack.error
          });
          setTimeout(() => {
            modal.destroy();
          }, 3000);
        } else {
          let result = folderResBack.result;
          let totalNumbers = folderResBack.total;

          let AllPages = Math.ceil(totalNumbers / pageSize);
          this.setState(
            {
              resultLists: result,
              totalNumbers,
              AllPages,
              askLoading: false
              // conditions: searchAllValues
            },
            () => {}
          );
        }
      }
    );
  };
  deleteFun = async () => {
    let { checkedObj, resultLists } = this.state;
    let rankValue = Object.values(checkedObj).sort(
      Utils.objComparison("index")
    );
    let deleteArrays = [];
    rankValue.map((item, index) => {
      deleteArrays.push(item.item.id);
    });

    // let params = {
    //   uid,
    //   assetstype: 6,
    //   ids: deleteArrays
    // };

    deleteArrays.map((item, index) => {
      resultLists.splice(checkedObj[item].index, 1);
      delete checkedObj[item];
    });
    if (resultLists.length < 11) {
      if (this.state.currentPage == this.state.AllPages) {
        if (resultLists.length == 0) {
          this.toPre();
        }
      } else if (this.state.currentPage == 1) {
        this.toFirst();
      } else {
        this.toPre();
      }
    }
    this.setState(
      {
        checkedObj,
        resultLists,
        isCheckAll: false
      },
      () => {
        this.props.changeCheckSelected(checkedObj, resultLists);
      }
    );
  };

  deleteSubset = async () => {
    let that = this;
    Modal.confirm({
      title: "please choose",
      content: "Do you want to delete the subsets?",
      async onOk() {
        let { checkedObj, resultLists } = that.state;
        let rankValue = Object.values(checkedObj).sort(
          Utils.objComparison("index")
        );
        let deleteArrays = [];

        rankValue.map((item, index) => {
          deleteArrays.push(item.item.id);
        });

        let uid = Storage.getItem("gbdbid");

        let params = {
          uid,
          assetstype: 6,
          ids: deleteArrays
        };

        let deleteAssetsBack = await SubsetApis.deleteAssets(params);
        if (deleteAssetsBack.result) {
          deleteArrays.map((item, index) => {
            resultLists.splice(checkedObj[item].index, 1);
            delete checkedObj[item];
          });
          if (resultLists.length < 11) {
            if (that.state.currentPage == that.state.AllPages) {
              if (resultLists.length == 0) {
                that.toPre();
              } else {
              }
            } else if (that.state.currentPage == 1) {
              that.toFirst();
            } else {
              that.toPre();
            }
          }
          that.setState(
            {
              checkedObj,
              resultLists,
              isCheckAll: false
            },
            () => {
              if (that.props.changeCheckSelected) {
                that.props.changeCheckSelected(checkedObj, resultLists);
              }
            }
          );
        } else {
          Modal.error({
            title: "This is an error message",
            content: deleteAssetsBack.error
          });
        }
      },
      onCancel() {
        
      }
    });
  };
  handlefolderOk = () => {
    this.setState({
      folderOperateShow: false
    });
  };
  handlefolderCancel = () => {
    this.setState({
      folderOperateShow: false
    });
  };
  addSubset = async () => {
    if(Storage.getItem("token") == undefined){
      Modal.error({
        title:"Please login first",
        content:"Only after the login can collect data!!!"
      })
      return
    }
    this.setState({
      folderOperateShow:true
    },()=>{

    })
    // let { checkedObj } = this.state;

    // let uid = Storage.getItem("gbdbid");

    // let params = {
    //   uid,
    //   assetstype: 6,

    //   ids: Object.keys(checkedObj)
    // };
    // let collectBack = await SubsetApis.collectAssets(params);
    // if (collectBack.result) {
    //   Modal.success({
    //     title: "This is an successful message",
    //     content: "successfully added "
    //   });
    // } else {
    //   Modal.error({
    //     title: "This is an error message",
    //     content: collectBack.error
    //   });
    // }
  };
  downloadFile = file => {
    let href = "/api" + file;
    let idx = file.lastIndexOf("/");

    let name = file.substring(idx + 1, file.length);
    var a = document.createElement("a");
    a.setAttribute("href", href);
    a.setAttribute("download", name);
    a.setAttribute("id", "downloadFileA");
    a.setAttribute("name", name);
    // 防止反复添加
    if (document.getElementById("downloadFileA")) {
      document.body.removeChild(document.getElementById("downloadFileA"));
    }
    document.body.appendChild(a);

    a.click();
  };
  downLodaConop = async () => {
    let { checkedObj } = this.state;
    let geoche_ids = Object.keys(checkedObj);
    let params = {
      geoche_ids
      // type:'geochemistry'
    };
    this.setState(
      {
        downLoadFileLoading: true
      },
      async () => {
        let downBack = await Apis.exportGeochemistry(params);
        if (downBack.result) {
          this.setState(
            {
              conopUrl: downBack.result,
              downLoadFileLoading: false
            },
            () => {
              this.downloadFile(downBack.result);
            }
          );
        } else {
          Modal.error({
            content: "download failed"
          });
        }
      }
    );
  };
  resetCheckData = () => {
    this.setState({
      currentPage: 1,
      isCheckAll: false,
      askLoading: undefined,
      checkedObj: {}
    });
  };
  render() {
    let { resultLists } = this.state;

    let {
      currentPage,
      checkOne,
      AllPages,
      isCheckAll,
      checkedObj
    } = this.state;

    return (
      <div id="SearchResult" className="SearchResult-refsearch">
        {/*  taxResult-main */}
        <div className="">
          <div className="content">
            {resultLists.length > 0 && this.state.askLoading == false ? (
              <table>
                <tbody>
                  <tr className="head">
                    {checkOne ? (
                      <td></td>
                    ) : (
                      <td className="check">
                        <Checkbox
                          onChange={e => this.CheckAll(e, "id")}
                          checked={isCheckAll}
                        ></Checkbox>
                      </td>
                    )}
                    <td>Geochemistry ID</td>
                    <td>Ref ID</td>
                    <td className="ref-id">Section ID</td>

                    {/* <td>Comment</td> */}
                    <td>Upload path</td>
                    <td>Enterer</td>

                    {this.state.showDetail === true ? <td>Details</td> : null}
                  </tr>

                  {resultLists.map((item, index) => {
                    return (
                      <tr key={item.id} className="row">
                        {checkOne ? (
                          <td className="check">
                            <Radio
                              name={item.id.toString()}
                              onChange={event =>
                                this.itemCheckbox(item, index, event)
                              }
                              checked={checkedObj.hasOwnProperty(item.id)}
                            ></Radio>
                          </td>
                        ) : (
                          <td className="check">
                            <Checkbox
                              name={item.id.toString()}
                              onChange={event =>
                                this.itemCheckbox(item, index, event)
                              }
                              checked={checkedObj.hasOwnProperty(item.id)}
                            ></Checkbox>
                          </td>
                        )}
                        <td>{item.id}</td>
                        <td>{item.ref_id}</td>

                        <td>{item.section_basic_id}</td>
                        {/* <td>{item.auth_comment}</td> */}
                        <td>
                          
                          <a
                            href={`/api${item.upload_path}`}
                            download={`/api${item.upload_path}`}
                          >
                            {item.upload_path}
                          </a>
                        </td>
                        <td>{item.enterer_username}</td>

                        {this.state.showDetail === true ? (
                          <td className="btn">
                            <Button
                              type="gbdb"
                              onClick={() => this.getDetail(item)}
                            >
                              <div>Details</div>
                            </Button>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : this.state.askLoading == true ? (
              <div className="tablelist-no-resource">
                 <div id="allResult">
                      <div className="allLoader">
                        <div className="loader-inner ball-spin-fade-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <span>Loading...</span>
                      </div>
                    </div>
              </div>
            ) : resultLists.length === 0 && this.state.askLoading == false ? (
              <div className="tablelist-no-resource">No Resource</div>
            ) : (
              <div className="tablelist-no-resource"></div>
            )}
          </div>
          {resultLists.length > 0 ? (
            <div className="footer">
              <div className="first" onClick={this.toFirst}>
                First
              </div>
              <div className="pre" onClick={this.toPre}>
                Pre
              </div>
              <div className="pages">{`${currentPage}/${AllPages}pages`}</div>
              <div className="next" onClick={this.toNext}>
                Next
              </div>

              <div className="page-input">
              <InputNumber
                  className="searchResult-select"
                  onKeyDown={this.gotoPage}
                  min={1}
                ></InputNumber>
                 <span className="goto" onClick={this.gotoPages}>Goto</span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="subset-file-btns">
          {this.state.showExportFile === true ? (
            <Button
              type="gbdb"
              onClick={this.downLodaConop}
              disabled={JSON.stringify(this.state.checkedObj) === "{}"}
            >
              <p title="Please select the file first"> Export to CONOP files</p>
            </Button>
          ) : null}

          {
          // Storage.getItem("token") !== undefined &&
          this.state.showAddSubset === true ? (
            <Button
              type="gbdb"
              disabled={Object.keys(checkedObj).length <= 0}
              onClick={this.addSubset}
            >
              Add to data subsets
            </Button>
          ) : null}
          {Storage.getItem("token") !== undefined &&
          this.state.showDeleteSubset === true ? (
            <Button
              type="gbdb"
              onClick={this.deleteSubset}
              disabled={Object.keys(checkedObj).length <= 0}
            >
              Delete subset
            </Button>
          ) : null}
        </div>
        {//   this.state.downLoadFileLoading
        this.state.downLoadFileLoading && (
          <div className="geochemical-download-loading file-downloading">
            <Spin />
            <div className="download-tips">
              File downloading, please wait...
            </div>
            <div className="download-tips-blocked">
              Please confirm that the file download function is not blocked
            </div>
          </div>
        )}
         <Modal
          title="Folder"
          destroyOnClose
          visible={this.state.folderOperateShow}
          className="subset-movemodal folder-common-modal clearfix"
          width="30%"
          onOk={this.handlefolderOk}
          onCancel={this.handlefolderCancel}
          footer={null}
        >
          <AllFolders
           folderType="geochemical"
           checkedObj={this.state.checkedObj}
           closeCommonFolder={this.handlefolderOk}
          >
          </AllFolders>
        </Modal>
      </div>
    );
  }
}

export default GeochemicalTableResult;
