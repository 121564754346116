import React, { Component } from "react";
import { Modal, Form } from "antd";

import messageApis from "src/js/apis/message";
import Utils from "src/js/utils";

import "./index.css";

class NewDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasMore: true,
      messageLists: [],
      isRange: false,
      mesTotal: 0,
      currentNew: {},
      loading: false
    };
  }

  async componentDidMount() {
    if (this.props.location.search) {
      let id = Utils.getSearch(this.props.location.search, "id") || "";
      let params = {
        id
      };
      this.setState(
        {
          loading: true
        },
        async () => {
          let MessagesBack = await messageApis.getNewsById(params);
          if (MessagesBack.error) {
            const modal = Modal.error({
              title: "This is an error message",
              content: MessagesBack.error
            });
            setTimeout(() => {
              modal.destroy();
            }, 3000);
          } else {
           
            this.setState({
              currentNew: MessagesBack.result,
              loading: false
            },()=>{
              if(MessagesBack.result){
                this.getContent(MessagesBack.result.content);
              }
              
            });
          }
        }
      );

      // if (this.props.location.query) {
      //   const { queryParam } = this.props.location.query;

      //   this.setState({
      //     currentNew: queryParam
      //   });
      // } else {
      //   this.props.history.push("/news/list");
      // }
    }
  }
  getNews = async callback => {
    let params = {};
    let MessagesBack = await messageApis.getNews(params);
    if (MessagesBack.error) {
      const modal = Modal.error({
        title: "This is an error message",
        content: MessagesBack.error
      });
      setTimeout(() => {
        modal.destroy();
      }, 3000);
    } else {
      callback(MessagesBack);
    }
  };
  goNewsList = () => {
    this.props.history.push("/news/list");
  };
  getContent = content => {
    var ret = document.getElementById("news_content");
    
    if (ret) {
      document.getElementById('news_content').innerHTML = content
    }
  };
  render() {
    let { currentNew } = this.state;
    return (
      <div id="message">
        {JSON.stringify(currentNew) !== "{}" && this.state.loading === false ? (
          <div className="message-content">
            <div className="reference-search-operate">
              <p className="group-news-title">
                <span
                  className="iconfont icon-back"
                  onClick={() => this.goNewsList()}
                ></span>
               <span className="itemnews-title"> {currentNew.title ? currentNew.title : ""}</span>
              </p>
            </div>
            <div className="new-content">
              <div className="new-detail-time">
                <span>
                  <span className="new-detail-title">time:</span>
                  {currentNew.release_date
                    ? currentNew.release_date.split("T")[0]
                    : ""}
                </span>
                {/* <span><span className="new-detail-title">publisher:</span>{currentNew.username?currentNew.username:''}</span> */}
              </div>
              <div className="new-main-content" id="news_content">
                {/* {
                currentNew.content?this.getContent(currentNew.content):''
              } */}
              </div>
            </div>
          </div>
        ) : JSON.stringify(currentNew) == "{}" &&
          this.state.loading === true ? (
          <div className="message-content">
            <div className="new-nodetail-content">Loading...</div>
          </div>
        ) : (
          <div className="message-content">
            <div className="new-nodetail-content">Sorry,There is no news!!</div>
          </div>
        )}
      </div>
    );
  }
}
NewDetail = Form.create({})(NewDetail);
export default NewDetail;
