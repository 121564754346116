import asyncAxios from "src/js/asyncAxios";



export default {
  // reference
  // search
  createFolder: async params => {
    let url = "/api/subset/createFolder";

    let folderAdd = await asyncAxios.post(url, params);

    return folderAdd;
  },
  getFolders: async params => {
    let url = "/api/subset/folders";

    let foldersResult = await asyncAxios.get(url, params);

    return foldersResult;
  },
    deleteFolder: async params => {
    let url = "/api/subset/folder";

    let  deleteResult = await asyncAxios.delete(url, params);

    return  deleteResult;
  },
 
  shareFolder: async params => {
    let url = "/api/subset/share/folder";

    let folderShare = await asyncAxios.post(url, params);
   
    return folderShare;
  },
  getSecondFolders: async params => {
    let url = "/api/subset/second/folders";

    let folderShare = await asyncAxios.get(url, params);
   
    return folderShare;
  },
};
