import React, { Component } from 'react';
import './index.css';

export default class ResearchTransform extends Component {
  constructor(props) {
    super(props);

  }
  async componentDidMount() {

  }

  render() {
    return (
      <div className="vis">
        {/* <div className= "vis-content">

        </div> */}
        <iframe src="http://www.geobiodiversity.com:5000/" id="spatiotemporalVis" name="spatiotemporalVis" scrolling="yes">
          <p>Your browser does not support iframes.</p>
        </iframe>
      </div>
    );
  }
}

