import React from 'react'
import {Form,Button,Pagination, message} from 'antd'
import axios from 'axios'


export default class ListForm extends React.Component{
    state={
        tableData:[],
        nowPage:1,
        defaultPageSize:10,
        total:0
    }
    getList(page=1){
        const {modalData}=this.props
        var sendData={
            collection_id:modalData.coll_id,
            page,
            pageSize:this.state.defaultPageSize
        }
        axios.get(`/api/admin/searchFossillist`,{
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                this.setState({
                    tableData:res.data.result,
                    total:res.data.total,
    
                })
            }else{
                message.error(res.data.error)
            }
        }).catch(err=>{
            message.error(err)
        })
    }
    componentDidMount(){
      this.getList()
    }
    handleSubmit(){
        this.props.handleShowInfo()
    }
    handleCancel(){
        this.props.cancelShowInfo()
    }
    changePage(page,pageSize){
        this.setState({
            nowPage:page
        })
       this.getList(page)
    }
    fullName(
        taxonName1,
        taxonName2,
        genusPrefix,
        spPrefix1,
        spPrefix2,
        spNameDict,
        spSuffix
      ) {
    
        let fullTaxonName = "";
        let genusName = "";
        let speciesName = "";
        genusName = taxonName1?taxonName1:'';
        speciesName = taxonName2?taxonName2:'';
     
        if (genusPrefix.trim() != "" && genusPrefix.trim() != "Null") {
          switch (genusPrefix.trim()) {
            case '""':
              genusName = '"' + genusName + '"';
              break;
            case '""cf.':
              genusName = 'cf. "' + genusName + '"';
              break;
            case '?""cf.':
              genusName = '? cf. "' + genusName + '"';
              break;
            default:
              genusName = genusPrefix.trim() + " " + genusName;
              break;
          }
        }
        if (spPrefix1.trim() != "" && spPrefix1.trim() != "Null") {
          switch (spPrefix1.trim()) {
            case '""':
              speciesName = '"' + speciesName + '"';
              break;
            case '?""':
              speciesName = '? "' + speciesName + '"';
              break;
            default:
              speciesName = spPrefix1.trim() + " " + speciesName;
              break;
          }
        }
    
        if (spPrefix2.trim() != "" && spPrefix2.trim() != "Null") {
          speciesName = spPrefix2.trim() + " " + speciesName;
        }
    
        if (spNameDict.trim() != "" && spNameDict.trim() != "Null") {
          //speciesName = spNameDict.trim() + " " + speciesName;
          speciesName = speciesName + " " + spNameDict.trim();
        }
    
        if (spSuffix.trim() != "" && spSuffix.trim() != "Null") {
          speciesName = speciesName + " " + spSuffix.trim();
        }
    
        fullTaxonName = genusName + " " + speciesName;
    
        return fullTaxonName;
      }
    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 5 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 19 },
            },
          };
          const {tableData,nowPage,defaultPageSize,total}=this.state
        return (
            <div>
                <div className='modalTable'>
                    <table>
                        <tbody>
                            <tr className='head'>
                                <td>序号</td>
                                <td>Taxon ID</td>
                                <td>Fossil group</td>
                                <td>Taxon type</td>
                                <td>Taxon name</td>
                                <td>Collect from Treatise?</td>
                            </tr>
                            {
                                tableData.map((item,index)=>{
                                    return (
                                        <tr className="tr" key={item.id}>
                                            <td>{defaultPageSize*(nowPage-1)+index+1}</td>
                                            <td>{item.id}</td>
                                            <td>{item.fossil_group}</td>
                                            <td>{item.taxon_type}</td>
                                            <td>{this.fullName(item.taxon_name1,item.taxon_name2,item.genus_prefix,item.sp_prefix1,item.sp_prefix2,item.sp_name_dict,item.sp_suffix)}</td>
                                            <td>{item.from_treatise}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {
                    tableData.length>0?(
                        <div className='pages'>
                            <Pagination size='small' 
                                current={nowPage}
                                defaultPageSize={defaultPageSize} 
                                total={total} 
                                // showTotal={showTotal.bind(this)}
                                onChange={this.changePage.bind(this)}
                            ></Pagination>
                        </div>
                    ):null
                }
                <div className='foot'>
                    {/* <Button  type='agbdb1' onClick={this.handleSubmit.bind(this)}>通过发布</Button>
                    <Button type='agbdb2' onClick={this.handleCancel.bind(this)}>拒绝发布</Button> */}
                </div>
            </div>
        )
    }
}