import React, { Component } from 'react';
import {Form,Input,DatePicker, Button,Pagination, Modal, message,Select } from 'antd'
import './index.scss'
import ShowInfoModal from './showColInfoModal'
import axios from 'axios'
import moment from 'moment'

const {RangePicker}=DatePicker
const {Option}=Select

 class AntdForm extends Component {
    constructor(props){
        super(props);
        this.state={
            tableData:[],
            nowPage:1,
            defaultPageSize:10,
            isShowInfo:false,
            modalData:{},
            userList:[],
            isResponse:false
        }
    }
    componentDidMount(){
        var id=JSON.parse(localStorage.getItem('gbdbid')).value;
        axios.get(`/api/admin/review/enterers?authorizer_id=${id}`).then(res=>{
            if(res.data.error===null){
                this.setState({
                    userList:res.data.result
                })
            }
          
        })
    }
    getFossilList(sendData){
        axios.get(`/api/admin/later/searchFossillist`,{
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                this.setState({
                    tableData:res.data.result,
                    total:res.data.total,
                    isResponse:true
                })
            }else{
                message.error(res.data.error)
            }
            if(res.data.result.length===0){
                // message.warning('暂无数据!')
            }
        }).catch(err=>{
            message.error(err)
        })
    }
    handleSubmit(page=1){
        this.props.form.validateFields((err, values) => {
            if (!err) {
               
                this.setState({
                    nowPage:page,
                })
                var authorizer_id=JSON.parse(localStorage.getItem('gbdbid')).value //当前登录用户id
                var sendData={
                    page,
                    pageSize:this.state.defaultPageSize,
                    authorizer_id,
                }
               
                if(values.creator){
                    sendData.enterer=values.creator
                }
                
                if(values.createTime){
                    sendData.dateFrom=moment(values.createTime[0]).format('YYYY/MM/DD');
                    sendData.dateTo=moment(values.createTime[1]).format('YYYY/MM/DD');
                }
              
                    this.getFossilList(sendData)
               
            }
        })
    }
    changePage(page,pageSize){
        this.setState({
            nowPage:page
        })
       this.handleSubmit(page)
    }
   
    handlePass(item){
        Modal.confirm({
            content:'点击"确定"后,本条信息将通过审核!',
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var sendData={
                    id:item.id,
                    assetstype:7,  //?
                    authState:1,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                }
                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                       message.success('审核通过!')
                       const {nowPage}=this.state
                       this.handleSubmit(nowPage);
                    }else{
                        message.error(res.data.error)
                    }
                }).catch(err=>{
                    message.error(err)
                })
            }

        })
    }
    handleReject(item){
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                var sendData={
                    id:item.id,
                    assetstype:7, //?
                    authState:2,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                    auth_comment:str
                }
               
                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                        const {nowPage}=this.state
                        message.success('已驳回！')
                        this.handleSubmit(nowPage);
                    }
                })
               
            },
          
        })
    }
    showCollInfo(item){
        //获取父级collection数据
        axios.get(`/api/search/getCollectionByOccurrence`,{
            params:{
                fossiloccurrence_id:item.fossiloccurrence_id
            }
        }).then(res=>{
            this.setState({
                modalData:res.data.result[0]
            },function(){
                this.setState({
                    isShowInfo:true,
                })
            })
        })
        
    }
    cancelShowInfo(){
        this.setState({
            isShowInfo:false
        })
    }
   
    fullName(
        taxonName1,
        taxonName2,
        genusPrefix,
        spPrefix1,
        spPrefix2,
        spNameDict,
        spSuffix
      ) {
    
        let fullTaxonName = "";
        let genusName = "";
        let speciesName = "";
        genusName = taxonName1?taxonName1:'';
        speciesName = taxonName2?taxonName2:'';
     
        if (genusPrefix.trim() != "" && genusPrefix.trim() != "Null") {
          switch (genusPrefix.trim()) {
            case '""':
              genusName = '"' + genusName + '"';
              break;
            case '""cf.':
              genusName = 'cf. "' + genusName + '"';
              break;
            case '?""cf.':
              genusName = '? cf. "' + genusName + '"';
              break;
            default:
              genusName = genusPrefix.trim() + " " + genusName;
              break;
          }
        }
        if (spPrefix1.trim() != "" && spPrefix1.trim() != "Null") {
          switch (spPrefix1.trim()) {
            case '""':
              speciesName = '"' + speciesName + '"';
              break;
            case '?""':
              speciesName = '? "' + speciesName + '"';
              break;
            default:
              speciesName = spPrefix1.trim() + " " + speciesName;
              break;
          }
        }
    
        if (spPrefix2.trim() != "" && spPrefix2.trim() != "Null") {
          speciesName = spPrefix2.trim() + " " + speciesName;
        }
    
        if (spNameDict.trim() != "" && spNameDict.trim() != "Null") {
          //speciesName = spNameDict.trim() + " " + speciesName;
          speciesName = speciesName + " " + spNameDict.trim();
        }
    
        if (spSuffix.trim() != "" && spSuffix.trim() != "Null") {
          speciesName = speciesName + " " + spSuffix.trim();
        }
    
        fullTaxonName = genusName + " " + speciesName;
    
        return fullTaxonName;
      }
    render() {
        let {defaultPageSize,nowPage,isShowInfo,total,userList,modalData,isResponse} =this.state;
        function showTotal(total) {
            return `每页显示\xa0${defaultPageSize}\xa0条\xa0\xa0\xa0  共 ${total} 条记录`;
          }
        const { getFieldDecorator} = this.props.form;
        const {tableData}=this.state;
        return (
            <div id='ASection'>
                <div className='title'>FossilList</div>
                <div className="form">
                    <Form layout='vertical'>

                        <Form.Item label='创建人'>
                            {
                                getFieldDecorator('creator',{
                                })(<Select placeholder="请选择">
                                    <Option value=''>请选择</Option>
                                    {userList.map(item=>{
                                    return ( <Option key={item.id} value={item.id}>{item.username}</Option>)
                                    })}
                                   
                                </Select>)
                            }
                        </Form.Item>

                        <Form.Item label='创建时间'>
                            {
                                getFieldDecorator('createTime',{

                                })(<RangePicker></RangePicker>)
                            }
                        </Form.Item>

                        <Form.Item className='foot'>
                           <Button type='primary' onClick={()=>{this.handleSubmit()}}>筛选</Button>
                        </Form.Item>

                    </Form>
                   
                </div>
                {
                    tableData.length>0?(
                        <div className='modalTable'>
                        <table>
                            <tbody>
                                <tr className='head'>
                                    <td>序号</td>
                                    <td>创建人</td>
                                    <td>Taxon ID</td>
                                    <td>Fossil no</td>
                                    <td>Fossil group</td>
                                    <td>Taxon type</td>
                                    <td>Taxon name</td>
                                    <td>Collect from Treatise?</td>
                                    <td>操作</td>
                                </tr>
                                {
                                    tableData.map((item,index)=>{
                                        return (
                                            <tr className="tr" key={item.id}>
                                                <td>{defaultPageSize*(nowPage-1)+index+1}</td>
                                                <td>{item.enterer_username}</td>
                                                <td>{item.id}</td>
                                                <td>{item.fossil_no}</td>
                                                <td>{item.fossil_group}</td>
                                                <td>{item.taxon_type}</td>
                                                <td>{this.fullName(item.taxon_name1,item.taxon_name2,item.genus_prefix,item.sp_prefix1,item.sp_prefix2,item.sp_name_dict,item.sp_suffix)}</td>
                                                <td>{item.from_treatise}</td>
                                                <td style={{width:'18%'}}>
                                                    <Button type='link' onClick={this.handlePass.bind(this,item)}>通过</Button>
                                                    <Button type='link' onClick={this.handleReject.bind(this,item)}>拒绝</Button>
                                                    <Button type='link' onClick={this.showCollInfo.bind(this,item)}>查看collection</Button>
                                                </td>
                                               
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                   
                        <div className='pages'>
                            <Pagination size='small' 
                                current={nowPage}
                                defaultPageSize={defaultPageSize} 
                                total={total} 
                                // showTotal={showTotal.bind(this)}
                                onChange={this.changePage.bind(this)}
                            ></Pagination>
                        </div>
                    </div>
                    ):null
                }
               {tableData.length===0 && isResponse ? <div className='pageTip'>暂无数据</div> :null}
                 {
                    isShowInfo?(
                        <ShowInfoModal
                            modalData={modalData}
                            visible={isShowInfo}
                            cancelShowInfo={this.cancelShowInfo.bind(this)}
                        ></ShowInfoModal>
                    ):null
                } 
               
            
            </div>
        
            );
    }
}
const AUnit = Form.create()(AntdForm);
export default AUnit

