import React, { Component } from 'react';
import { Upload, Icon, message, Button, InputNumber } from 'antd';
import './index.css';
import templeExel from '../../../assets/excel/template.xlsx';

import axios from 'axios';

const { Dragger } = Upload;

function doLonChange(index, singleObj, keyName, keyVal, ) {
  console.log(">>>>>>>>>>>>value",index, singleObj, keyName, keyVal,)
  const obj = {
    ...singleObj,
    [keyName]: keyVal,
  }
  const resObj = this.state.dataList.map( (item, itemIndex)=> {
    if ( itemIndex === index) {
    // 改变值
      return {
        ...obj
      }
    } else {
      return item;
    }
});
  this.setState({
    dataList: resObj
  }) 
}

async function doTransform() {
  
  this.setState({
    resList: []
  }) 
  const resArr = [];
  for(let [itemIndex, dataItem] of this.state.dataList.entries()) {
    if(!dataItem.lon) {
      message.error(`Longitude in Line ${itemIndex + 1} is a must`)
      break;
    }
    if(!dataItem.lat) {
      message.error(`Latitude in Line ${itemIndex + 1} is a must`)
      break;
    }
    if(!dataItem.age) {
      message.error(`Age in Line ${itemIndex + 1}  is a must`)
      break;
    }
    
    const apiRes =  await axios.post('/api/transform/single', dataItem)
    apiRes&& (apiRes.code == '200') && ( resArr.push( apiRes.data.data ));
    console.log(">>>>>>>>>>>>>apiRes", apiRes)

  }

  this.setState({
    resList: [
      ...resArr,
    ]
  }) 
}

function doAddSingleTrans() {
  this.setState({
    dataList: [
      ...this.state.dataList,
      {
        "lon": undefined,
        "lat": undefined,
        "age": undefined
      }]
  }) 
}

function  doMinusSingleTrans(index) {
  this.setState({
    dataList: this.state.dataList.filter( (arrItem, arrIndex) => arrIndex !== index )
  })
}

export default class ResearchTransform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fundingList: []
      dataList: [],
      resList: [],
      operateId: undefined,
      
      uploadProps: {
        name: 'file',
        accept: '.xlsx,.xls',
        // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        action: '/api/transform/batch',
        multiple: false,
        showUploadList: false,
        onChange: info=> {
          this.setState({
            operateId: undefined,
          })
          const { status, response } = info.file;
          if (status !== 'uploading') {
            // console.log(info.file, info.fileList);
            console.log(">>>>>>>>>>>>>>>>loading")
          }
          if (status === 'done' && response.code == '200') {
            console.log(">>>>>>>>>>>>>response", response)
            
            const { success: flag, msg, data: { operateId}} = response;
            if(flag) {
              message.success(`Success：${msg}`);
              this.setState({
                operateId
              })
            }else {
              message.error(`Error：${msg}`);
            }
            // message.success('uploaded successfully !');
          } else if (status === 'error') {
            message.error('uploaded error !');
          }
        },
      },

    };
  }
  async componentDidMount() {
    this.setState({
      dataList: [{
        "lon": undefined,
        "lat": undefined,
        "age": undefined
      }],
      resList: [],
    }) 
  }

  render() {
    return (
      <div className= "transform">
        <div className= "transform-content">
          <div className= "content-left">
            {/* 批量上传 */}
            <div className= "transform-title">
              Bulk upload
            </div>
            {/* 上传 */}
            <div className= "upload-area">
              <Dragger {...this.state.uploadProps}>
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Only a single Excel file is supported. 
                  Please fill in the Excel content according to the template requirements.
                </p>
              </Dragger>
            </div>
            {/* 按钮区域 */}
            <div className= "btn-area">
              <Button type="link" href= {templeExel} download name= "template.xlsx">Get the template</Button>
              <Button type="primary" disabled= {!this.state.operateId}
                      href= {`/api/transform/batchResult/${this.state.operateId}`}>Export</Button>
            </div>
          </div>
          <div className= "content-right">
            {/* 单个上传 */}
            <div className= "transform-title">
              Single upload
            </div>
            <div className= "right-area">
              <div className= "area-input">
                <div className= "signle-item">
                  <span className="item-title">Longitude</span>
                  <span className="item-title">Latitude</span>
                  <span className="item-title">Age</span>
                  <span className="item-title operate-title"></span>
                </div>
                {
                  this.state.dataList.map( (singleItem, singleKey) => (
                    <div className= "signle-item"
                          key= {singleKey}>
                      <InputNumber placeholder= "Longitude" value= {singleItem.lon} onChange= {doLonChange.bind(this, singleKey, singleItem, 'lon')} />
                      <InputNumber placeholder= "Latitude" value= {singleItem.lat}  onChange= {doLonChange.bind(this, singleKey, singleItem, 'lat')} />
                      <InputNumber placeholder= "Age" precision= {0} value= {singleItem.age} 
                                    onChange= {doLonChange.bind(this, singleKey, singleItem, 'age')}/>
                      {
                        singleKey === 0 && (<Icon type="plus" className= "icon-btn" onClick= {doAddSingleTrans.bind(this)}/>)
                      }{
                        singleKey !== 0 && (<Icon type="minus" className= "icon-btn" onClick= {doMinusSingleTrans.bind(this, singleKey)}/>)
                      }
                    </div>
                    )
                  )
                }
              </div>
              <div className= "area-transform" onClick= {doTransform.bind(this)}>
                <Icon type="retweet" />
              </div>
              <div className= "area-show" >
                {/* longitude	latitude	age	geoLongitude	geoLatitude */}
                <div className= "show-item ">
                  <span className= "item-title">Longitude</span>
                  <span className= "item-title">Latitude</span>
                  <span className= "item-title item-age">Age</span>
                  <span className= "item-title item-icon"></span>
                  <span className= "item-title">GeoLongitude</span>
                  <span className= "item-title">GeoLatitude</span>
                </div>
                {
                  this.state.resList.length === 0 && (
                    <div className= "no-data">Result Area</div>
                  )
                }
                {
                  this.state.resList.map( (showItem, showIndex) => (
                    <div key= {showIndex} className= "show-item ">
                      <span className= "item-label">{showItem.lon}</span>
                      <span className= "item-label">{showItem.lat}</span>
                      <span className= "item-label item-age">{showItem.age}</span>
                      <span className= "item-label item-icon"><Icon type="swap-right" /></span>
                      <span className= "item-label">{showItem.geo_lon}</span>
                      <span className= "item-label">{showItem.geo_lat}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

