import React, { Component } from 'react';
import './index.scss'
import {Button, Checkbox,Icon} from 'antd'

export default class Customer extends Component {
  render() {
    return (
      <div id='AMember'>
            <div className='title'> 用户组设置 </div>
            <div className='table'>
                <table>
                    <tbody>
                        <tr className='head'>
                            <td>用户</td>
                            <td>数据管理</td>
                            <td>积分管理</td>
                            <td>认证管理</td>
                            <td>账号管理</td>
                            <td>新闻管理</td>
                            <td>系统管理</td>
                        </tr>
                        <tr className='tr color'>
                            <td>普通用户</td>
                            <td><Checkbox></Checkbox></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr className='tr'>
                            <td>专家</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr className='tr color'>
                            <td>数据录入员</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='foot'><Button type='primary'>确认</Button></div>
      </div>
    );
  }
}
