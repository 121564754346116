import React from 'react'
import {Form,Button,message,Modal,Input,Pagination} from 'antd'
import axios from 'axios'
import moment from 'moment'

export default class BasicInfoForm extends React.Component{
    state={
        tableData:[],
        nowPage:1,
        defaultPageSize:10,
        total:0,
    }
    componentDidMount(){
        this.getList()
      }
    getList(page=1){
        const {modalData}=this.props
        var sendData={
            collection_id:modalData.coll_id,
            page,
            pageSize:this.state.defaultPageSize
        }
        axios.get(`/api/admin/searchFossillist`,{
            params:sendData
        }).then(res=>{
            if(res.data.error===null){
                this.setState({
                    tableData:res.data.result,
                    total:res.data.total,
    
                })
            }else{
                message.error(res.data.error)
            }
        }).catch(err=>{
            message.error(err)
        })
    }
    handlePass(){
        Modal.confirm({
            content:'点击"确定"后,Collection及相关的FossilList都将通过审核!',
            okText:'确定',
            onCancel:'取消',
            onOk:()=>{
                const {modalData}=this.props;
                var sendData={
                    id:modalData.coll_id,
                    assetstype:6,
                    authState:1,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                }
                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                       message.success('审核通过!')
                       this.props.cancelShowInfo()
                       this.props.refreshList()
                    }else{
                        message.error(res.data.error)
                    }
                }).catch(err=>{
                    message.error(err)
                })
            }
        })
       
    }
    handleReject(){
        const {modalData}=this.props;
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                var sendData={
                    id:modalData.coll_id,
                    assetstype:6,
                    authState:2,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                    auth_comment:str
                }
                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已驳回！')
                        this.props.cancelShowInfo()
                        this.props.refreshList()
                    }
                })
               
            },
          
        })
    }
    changePage(page,pageSize){
        this.setState({
            nowPage:page
        })
       this.getList(page)
    }
    fullName(
        taxonName1,
        taxonName2,
        genusPrefix,
        spPrefix1,
        spPrefix2,
        spNameDict,
        spSuffix
      ) {
    
        let fullTaxonName = "";
        let genusName = "";
        let speciesName = "";
        genusName = taxonName1?taxonName1:'';
        speciesName = taxonName2?taxonName2:'';
     
        if (genusPrefix.trim() !== "" && genusPrefix.trim() !== "Null") {
          switch (genusPrefix.trim()) {
            case '""':
              genusName = '"' + genusName + '"';
              break;
            case '""cf.':
              genusName = 'cf. "' + genusName + '"';
              break;
            case '?""cf.':
              genusName = '? cf. "' + genusName + '"';
              break;
            default:
              genusName = genusPrefix.trim() + " " + genusName;
              break;
          }
        }
        if (spPrefix1.trim() !== "" && spPrefix1.trim() !== "Null") {
          switch (spPrefix1.trim()) {
            case '""':
              speciesName = '"' + speciesName + '"';
              break;
            case '?""':
              speciesName = '? "' + speciesName + '"';
              break;
            default:
              speciesName = spPrefix1.trim() + " " + speciesName;
              break;
          }
        }
    
        if (spPrefix2.trim() !== "" && spPrefix2.trim() !== "Null") {
          speciesName = spPrefix2.trim() + " " + speciesName;
        }
    
        if (spNameDict.trim() !== "" && spNameDict.trim() !== "Null") {
          //speciesName = spNameDict.trim() + " " + speciesName;
          speciesName = speciesName + " " + spNameDict.trim();
        }
    
        if (spSuffix.trim() !== "" && spSuffix.trim() !== "Null") {
          speciesName = speciesName + " " + spSuffix.trim();
        }
    
        fullTaxonName = genusName + " " + speciesName;
    
        return fullTaxonName;
      }
    render(){
       
        const {modalData}=this.props;
        const {nowPage,defaultPageSize,tableData,total}=this.state;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 5 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 19 },
            },
          };
        return (
            <div>
                <div className='tip1'>Collection Info</div>
                <Form {...formItemLayout}>
                    <Form.Item label='Reference ID'>
                        <span>{modalData.ref_id}</span>
                    </Form.Item>

                    <Form.Item label='Unit ID'>
                        <span>{modalData.unit_id}</span>
                    </Form.Item>

                    <Form.Item label='Collection no'>
                        <span>{modalData.coll_no}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Modes of<br/>preservation</div>}>
                        <span>{modalData.coll_preservation}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Original<br/>biominerals</div>}>
                        <span>{modalData.coll_biominerals}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Replacement<br/>Minerals</div>}>
                        <span>{modalData.coll_minerals}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Special modes<br/>of occurrence</div>}>
                        <span>{modalData.coll_occurrence}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Temporal<br/>Resolution</div>}>
                        <span>{modalData.coll_tmporal}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Spatial<br/>resoution</div>}>
                        <span>{modalData.coll_resolution}</span>
                    </Form.Item>

                    <Form.Item label='Lager statten type'>
                        <span>{modalData.coll_type}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Degree of<br/>concentration</div>}>
                        <span>{modalData.coll_concentration}</span>
                    </Form.Item>

                    <Form.Item label='Spatial orientation'>
                        <span>{modalData.coll_orientation}</span>
                    </Form.Item>

                    <Form.Item label='Anatomical detail'>
                        <span>{modalData.coll_detail}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Abundance in<br/>sediment</div>}>
                        <span>{modalData.coll_sediment}</span>
                    </Form.Item>

                    <Form.Item label='Size sorting'>
                        <span>{modalData.coll_sorting}</span>
                    </Form.Item>

                    <Form.Item label='Fragmentation'>
                        <span>{modalData.coll_fragmentation}</span>
                    </Form.Item>

                    <Form.Item label='Bioerpsion'>
                        <span>{modalData.coll_Bioerosion}</span>
                    </Form.Item>

                    <Form.Item label='Encrustation'>
                        <span>{modalData.coll_Encrustation}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Comments on<br/>preservation</div>}>
                        <span>{modalData.coll_preservations}</span>
                    </Form.Item>

                    <Form.Item label='Size classes'>
                        <span>{modalData.coll_classes}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Feeding/predation<br/>traces</div>}>
                        <span>{modalData.coll_traces}</span>
                    </Form.Item>

                    <Form.Item className='lineHeight' label={<div>Comments on<br/>included components</div>}>
                        <span>{modalData.coll_components}</span>
                    </Form.Item>

                </Form>
                {
                    tableData.length===0?(<div className='tip1'>暂无fossilList数据</div>):
                    (
                        <div>
                            <div className='tip1'>FossilList</div>
                            <div className='modalTable'>
                                <table>
                                    <tbody>
                                        <tr className='head'>
                                            <td>序号</td>
                                            <td>创建人</td>
                                            <td>Taxon ID</td>
                                            <td>Fossil no</td>
                                            <td>Fossil group</td>
                                            <td>Taxon type</td>
                                            <td>Taxon name</td>
                                            <td>Collect from Treatise?</td>
                                        </tr>
                                        {
                                            tableData.map((item,index)=>{
                                                return (
                                                    <tr className="tr" key={item.id}>
                                                        <td>{defaultPageSize*(nowPage-1)+index+1}</td>
                                                        <td>{item.enterer_username}</td>
                                                        <td>{item.id}</td>
                                                        <td>{item.fossil_no}</td>
                                                        <td>{item.fossil_group}</td>
                                                        <td>{item.taxon_type}</td>
                                                        <td>{this.fullName(item.taxon_name1,item.taxon_name2,item.genus_prefix,item.sp_prefix1,item.sp_prefix2,item.sp_name_dict,item.sp_suffix)}</td>
                                                        <td>{item.from_treatise}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            tableData.length>0?(
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className='pages' style={{marginTop:'20px'}}>
                                                            <Pagination size='small' 
                                                                current={nowPage}
                                                                defaultPageSize={defaultPageSize} 
                                                                total={total} 
                                                                // showTotal={showTotal.bind(this)}
                                                                onChange={this.changePage.bind(this)}
                                                            ></Pagination>
                                                        </div>
                                                    </td>
                                                </tr>
                                            
                                            ):null
                                        }
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                }
               
               
                <div className='foot'>
                    <Button  type='agbdb1' onClick={this.handlePass.bind(this)}>通过</Button>
                    <Button type='agbdb2' onClick={this.handleReject.bind(this)}>拒绝</Button>
                </div>
            </div>
        )
    }
}