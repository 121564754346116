import React, { Component } from "react";

import Utils from "src/js/utils";
import "src/js/global.js";
import Apis from "src/js/apis/api";
import dataApis from "src/js/apis/data";
import Storage from "src/js/storage";
import "./index.css";
import { Form, Input, Select, Button, Modal, InputNumber, AutoComplete, message } from "antd";
import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";

import SectionOption from "commons/AllOptions/Section/index.js";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";

import GeologyAllTableResult from "commons/AllTableList/Section/Geography/index.js";
import Users from "src/js/users";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

class SectionFormation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inheritRefIds: "",
      baseId: "",
      geographyId: "",
      latitude: "",
      longitude: "",
      refSearch: false,
      Intervals: [],
      Intages: [],
      geologyInfo: {},
      isSearch: false,

      refCondition: false,
      geographySearch: false,
      sectionOptionVisible: false,
      sectionType: "baseinfo",
      allChildError: {},
      intervalDataitem: {},
    };
  }
  isGbdbUser = async () => {
    let Inputerid = false;
    let token = Storage.getItem("token");
    Inputerid = await Users.isGbdbInputer();

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page",
        });
        this.props.history.push("/home");
      }
    } else {
      if (Inputerid == false) {
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title: "Sorry!!",
            content: "You do not have permission to add data",
          });
          this.props.history.push("/home");
        }
      }
    }
  };
  async componentDidMount() {
    this.isGbdbUser();
    if (this.props.location.search) {
      let inheritRefIds = Utils.getSearch(this.props.location.search, "refId") || "";
      let baseId = Utils.getSearch(this.props.location.search, "baseId") || "";
      let geographyId = Utils.getSearch(this.props.location.search, "geographyId") || "";

      this.setState(
        {
          inheritRefIds,
          geographyId,
          isSearch: true,
          baseId,
        },
        async () => {
          let params = {
            geology_id: geographyId,
          };
          let getGeologyBack = await Apis.getGeology(params);

          if (getGeologyBack.result) {
            this.setState(
              {
                geologyInfo: getGeologyBack.result.length > 0 ? getGeologyBack.result[0] : {},
                latitude: getGeologyBack.result.length > 0 ? getGeologyBack.result[0].geology_latitude_decimal : "",
                longitude: getGeologyBack.result.length > 0 ? getGeologyBack.result[0].geology_longitude_decimal : "",
              },
              () => {}
            );
          } else {
            Modal.error({
              title: "This is an error message",
              content: getGeologyBack.error,
            });
          }
        }
      );
    } else {
      this.setState({
        isSearch: false,
      });
    }
  }

  conformGoUnit = async (addFormation, type) => {
    let { inheritRefIds, baseId, geographyId } = this.state;
    let addForResult = await Apis.createFormation(addFormation);

    if (addForResult.error) {
      Modal.error({
        title: "This is an error message",
        content: addForResult.error,
      });
    } else {
      let url = "/section/unit?";

      let refId = inheritRefIds ? "refId=" + inheritRefIds : "";

      let baseIdUrl = baseId ? "&baseId=" + baseId : "";
      let geographyUrl = geographyId ? "&geographyId=" + geographyId : "";

      let formationId = addForResult.result.formation_id;

      let formationIdUrl = formationId ? "&formationId=" + formationId : "";

      let params = `${url}${refId}${baseIdUrl}${geographyUrl}${formationIdUrl}`;

      if (type === "complete") {
        Modal.success({
          title: "This is a successful message",
          content: "Add successfully",
        });
        this.props.form.resetFields();
        this.setState({
          intervalDataitem:{}
        })
      } else if (type === "continue") {
        setTimeout(() => {
          this.props.history.push(params);
        }, 20);
      }
    }
  };
  calculateMax = () => {};
  FormationGoUnit = (type) => {
    let that = this;
    if (type === "pass") {
      let { inheritRefIds, baseId, geographyId } = this.state;
      let url = "/section/unit?";

      let refId = inheritRefIds ? "refId=" + inheritRefIds : "";

      let baseIdUrl = baseId ? "&baseId=" + baseId : "";
      let geographyUrl = geographyId ? "&geographyId=" + geographyId : "";
      let params = `${url}${refId}${baseIdUrl}${geographyUrl}`;

      this.props.history.push(params);
    } else {
      this.props.form.validateFieldsAndScroll(async (err, values) => {
        let { geologyInfo } = this.state;

        if (!err) {
          let earlyValue = this.props.form.getFieldValue("early_age");
          let lateValue = this.props.form.getFieldValue("late_age");

          if (!isNaN(earlyValue) && !isNaN(lateValue)) {
            if (parseFloat(earlyValue) < parseFloat(lateValue)) {
              message.error("early_age must be greater than late_age");

              return;
            }
          }

          // 单位转换存储

          if (values.formation_thick !== "" && values.formation_thick !== null && values.formation_thick !== undefined) {
            if (values.formation_thick_unit === "cm") {
              let value = parseFloat(values.formation_thick) / 100;
              values.formation_thick = value;
              values.formation_thick_unit = "meters";
            } else if (values.formation_thick_unit === "feet") {
              let value = parseFloat(values.formation_thick) * 0.3048;
              values.formation_thick = value;
              values.formation_thick_unit = "meters";
            }
          }

          let addFormation = Utils.filterParams(values);
          addFormation = Utils.filterCondition(addFormation);
          addFormation = Utils.addEnterer(addFormation);
          addFormation = Utils.addAudit(addFormation, geologyInfo);
        // 地质年代最后的填充
          let isCalculate = true;
          if ((earlyValue === "" || earlyValue === null || earlyValue === undefined) && (lateValue === "" || lateValue === null || lateValue === undefined)) {
            isCalculate = false;
          }
          // 如果early_age和late_age不为空
      
          let earlyInterval = this.props.form.getFieldValue("early_interval");
          if (isCalculate) {
            let params = {
              early_age: earlyValue,
              late_age: lateValue,
            };
            let TimeLinesById = await Apis.getEarlyInterval(params);
            if (TimeLinesById && TimeLinesById.result) {
              let TimeLines = TimeLinesById.result;
              
              if (TimeLines) {
                let erathem_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.erathem_max ? TimeLines.ages.erathem_max.name : "";
                let period_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.period_max ? TimeLines.ages.period_max.name : "";
                let epoch_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.epoch_max ? TimeLines.ages.epoch_max.name : "";
                let early_interval = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.early_interval ? TimeLines.ages.early_interval.name : "";
               

                if (erathem_max !== "") {
                  addFormation.erathem_max = erathem_max;
                  addFormation.age_color = TimeLines.ages.erathem_max.color;
                }
                if (period_max !== "") {
                  addFormation.period_max = period_max;
                  addFormation.age_color = TimeLines.ages.period_max.color;
                }
                if (epoch_max !== "") {
                  addFormation.epoch_max = epoch_max;
                  addFormation.age_color = TimeLines.ages.epoch_max.color;
                }
                if (early_interval !== "") {
                  if (earlyInterval !== "" && earlyInterval !== null && earlyInterval !== undefined) {
                    if (early_interval == earlyInterval) {
                      addFormation.age_color = TimeLines.ages.early_interval.color;
                    }
                  } else {
                    addFormation.early_interval = early_interval;
                    addFormation.age_color = TimeLines.ages.early_interval.color;
                  }
                }
              }
            } else {
              if (TimeLinesById.error) {
                Modal.error({
                  content: TimeLinesById.error,
                });
              }
            }

        
            that.conformGoUnit(addFormation, type);
          } else {
            let { intervalDataitem } = this.state;
            if (JSON.stringify(intervalDataitem) !== "{}") {
              let params = { id: intervalDataitem.chronostratigraphy_id };
              let TimeLinesById = await Apis.getParentTimeLinesById(params);
              if (TimeLinesById && TimeLinesById.result) {
                let TimeLines = TimeLinesById.result;

                if (TimeLines) {
                  let erathem_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.erathem_max ? TimeLines.ages.erathem_max.name : "";
                  let period_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.period_max ? TimeLines.ages.period_max.name : "";
                  let epoch_max = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.epoch_max ? TimeLines.ages.epoch_max.name : "";
                  let early_interval = JSON.stringify(TimeLines) !== "{}" && TimeLines.ages.early_interval ? TimeLines.ages.early_interval.name : "";

                  if (erathem_max !== "") {
                    addFormation.erathem_max = erathem_max;
                    addFormation.age_color = TimeLines.ages.erathem_max.color;
                  }
                  if (period_max !== "") {
                    addFormation.period_max = period_max;
                    addFormation.age_color = TimeLines.ages.period_max.color;
                  }
                  if (epoch_max !== "") {
                    addFormation.epoch_max = epoch_max;
                    addFormation.age_color = TimeLines.ages.epoch_max.color;
                  }
                  if (early_interval !== "") {
                    if (early_interval == earlyInterval) {
                      addFormation.age_color = TimeLines.ages.early_interval.color;
                    }
                  }

                  // 颜色处理
                }
              } else {
                if (TimeLinesById.error) {
                  Modal.error({
                    content: TimeLinesById.error,
                  });
                }
              }
            }
            that.conformGoUnit(addFormation, type);
          }

         
        }
      });
    }
  };

  changePaleogeography = async (age) => {
    if (
      this.state.latitude === "" ||
      this.state.latitude === null ||
      this.state.latitude === undefined ||
      this.state.longitude === "" ||
      this.state.longitude === null ||
      this.state.longitude === undefined ||
      age === "" ||
      age === null ||
      age === undefined
    ) {
      return;
    } else {
      let params = {
        age,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
      };
      let Paleogeography = await Apis.gpsConverts(params);

      if (Paleogeography.result) {
        this.props.form.setFieldsValue({
          geo_latitude_decimal: Paleogeography.result.geoLatitude,
          geo_longitude_decimal: Paleogeography.result.geoLongitude,
        });
      } else {
        if (Paleogeography.error) {
          Modal.error({
            title: "This is a error message",
            content: Paleogeography.error,
          });
        }
      }
    }
  };
  IntervalChange = async (value, e, field) => {
    let dataitem = e.props.dataitem;

    if (dataitem) {
      let age = (parseFloat(dataitem.early_age) + parseFloat(dataitem.late_age)) / 2;
      this.changePaleogeography(age);
      this.props.form.setFieldsValue({
        early_age: parseFloat(dataitem.early_age),
        late_age: parseFloat(dataitem.late_age),
      });
      this.setState(
        {
          intervalDataitem: dataitem,
        },
        () => {
          
        }
      );
    } else {
      this.setState(
        {
          intervalDataitem: {},
        },
        () => {}
      );
    }

    this.props.form.setFieldsValue({
      [field]: value,
    });
  };
  IntervalSearch = async (value, type, key) => {
    let params = {
      keyword: value,
    };

    let geoSearchBack = await dataApis.autoCompleteInterval(params);

    let searchReust = geoSearchBack.result || [];
    this.setState(
      {
        [type]: searchReust,
      },
      () => {}
    );
  };
  Calculate = async (e) => {
    let value = e.target.value;
    let type = e.target.name;
    let earlyValue = this.props.form.getFieldValue("early_age");
    let lateValue = this.props.form.getFieldValue("late_age");

    if (!isNaN(earlyValue) && !isNaN(lateValue)) {
      if (parseFloat(earlyValue) < parseFloat(lateValue)) {
        message.error("early_age must be greater than late_age");
        this.props.form.setFieldsValue({
          geo_latitude_decimal: null,
          geo_longitude_decimal: null,
        });

        return;
      }
    }

    if (e.target.value) {
      if (type === "early_age") {
        let lateAge = this.props.form.getFieldValue("late_age");
        if (lateAge) {
          let age = (parseFloat(value) + parseFloat(lateAge)) / 2;
          this.changePaleogeography(age);
        }
      } else if (type === "late_age") {
        let earlyAge = this.props.form.getFieldValue("early_age");
        if (earlyAge) {
          let age = (parseFloat(value) + parseFloat(earlyAge)) / 2;
          this.changePaleogeography(age);
        }
      }
    }
  };

  showSearchRef = (e) => {
    e.target.blur();
    this.setState({
      refCondition: true,
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false,
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false,
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false,
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false,
    });
  };
  clickRefId = (value) => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value,
    });
  };
  onRef = (ref) => {
    this.child = ref;
  };
  onGeography = (ref) => {
    this.geographyChild = ref;
  };
  getDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true,
    });

    //点击 detail 按扭执行代码
  };
  geographyDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showSectionDetailForm: true,
    });
  };
  handFormOk = () => {
    this.setState({
      sectionOptionVisible: false,
    });
  };
  handleFormCancel = () => {
    this.setState({
      sectionOptionVisible: false,
    });
    Modal.destroyAll();
  };
  childError = (allChildError) => {
    this.setState({
      allChildError,
    });
  };
  checkAgain = (optionType) => {
    let { allChildError } = this.state;

    if (JSON.stringify(allChildError) !== "{}") {
      Modal.warn({
        content: "Please check the query form",
      });
      return;
    }
    let showType = "refSearch";

    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (optionType === "reference") {
        showType = "refSearch";
      } else {
        showType = "geographySearch";
      }

      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            sectionOptionVisible: false,
            [showType]: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType,
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues, sectionType } = this.state;

              if (optionType === "reference") {
                this.child.searchReferenceDatas(searchParams, searchValues);
              } else {
                this.geographyChild.searchSectionDatas(searchParams, searchValues, sectionType);
              }
            }, 20);
          }
        );
      }
    });
  };
  showSearchGeography = (type, e) => {
    e.target.blur();

    this.setState(
      {
        sectionType: type,
      },
      () => {
        this.setState({
          sectionOptionVisible: true,
        });
      }
    );
  };
  handleGeographyOk = () => {
    this.setState({
      geographySearch: false,
    });
  };
  handleGeographyCancel = () => {
    this.setState({
      geographySearch: false,
    });
  };
  handleFormChange = (changedFields) => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields },
    }));
  };
  clickGeographyId = (type, value) => {
    this.setState({
      geologyInfo: value,
      geographySearch: false,
    });
    this.props.form.setFieldsValue({
      geology_id: value.geology_id || "",
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || "",
    });
  };
  clickSectionId = (value) => {
    this.setState({
      baseInfo: value,
      geographySearch: false,
    });
    this.props.form.setFieldsValue({
      geology_id: value.geology_id || "",
      section_basic_id: value.section_id || "",
      ref_id: value.ref_id || "",
    });
  };
  checkRefOne = (value) => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value,
    });
  };

  checkGeographyOne = (value) => {
    this.props.form.setFieldsValue({
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || "",
      geology_id: value.geology_id || "",
    });
    this.setState(
      {
        inheritRefIds: value.ref_id,
        baseId: value.section_basic_id,
        geographyId: value.geology_id,
        geologyInfo: value,
        geographySearch: false,
        latitude: value.geology_latitude_decimal,
        longitude: value.geology_longitude_decimal,
      },
      () => {
        let earlyValue = this.props.form.getFieldValue("early_age");
        let lateValue = this.props.form.getFieldValue("late_age");
        if (!isNaN(earlyValue) && !isNaN(lateValue)) {
          if (parseFloat(earlyValue) >= parseFloat(lateValue)) {
            let age = (parseFloat(earlyValue) + parseFloat(lateValue)) / 2;
            this.changePaleogeography(age);
          }
        }
      }
    );
  };
  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType,
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType,
    });
  };

  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false,
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false,
    });
  };
  detailSectionFormOk = () => {
    this.setState({
      showSectionDetailForm: false,
      // sectionType: "baseinfo"
    });
  };
  detailSectionFormCancel = () => {
    this.setState({
      showSectionDetailForm: false,
      // sectionType: "baseinfo"
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let {
      inheritRefIds,
      Intervals,
      Intages,
      baseId,
      geographyId,

      searchParams,
      sectionOptionVisible,
      sectionType,
      searchValues,
    } = this.state;
    const intervals = Intervals.map((item, index) => (
      <Option value={item.interval_name} dataitem={item} key={item.interval_name}>
        {item.interval_name}
      </Option>
    ));
    const intages = Intages.map((item, index) => (
      <Option value={item.interval_name} dataitem={item} key={item.interval_name}>
        {item.interval_name}
      </Option>
    ));
    return (
      <div id="sectionFormation" className="section-common">
        <div className="sectionFormation-mian">
          <div className="section-common-title form-common-title">Add Formation</div>

          <div className="sectionFormation-form">
            <Form>
              <div className="referenceAdd-title reference-inherit">
                {
                  // <span className="geography-ref-item" style={{ width: `${singleWidth}%` }}>
                  <span className="geography-ref-item">
                    <Form.Item label="Reference ID">
                      {getFieldDecorator(`ref_id`, {
                        rules: [
                          {
                            type: "number",
                            required: true,
                            message: "Please input the Reference",
                          },
                        ],
                        initialValue: inheritRefIds ? Number(inheritRefIds) : "",
                      })(<InputNumber className="inherit-input" onFocus={this.showSearchRef} disabled={true}></InputNumber>)}
                    </Form.Item>
                  </span>
                }
              </div>
              <div className="referenceAdd-title reference-inherit">
                {
                  // <span className="geography-ref-item" style={{ width: `${singleWidth}%` }}>
                  <span className="geography-ref-item">
                    <Form.Item label="Section ID">
                      {getFieldDecorator(`section_basic_id`, {
                        rules: [
                          {
                            required: true,
                            message: "Please input the Section Basic ID",
                          },
                        ],
                        initialValue: baseId,
                      })(<Input className="inherit-input" disabled={true} onFocus={(e) => this.showSearchGeography("baseinfo", e)}></Input>)}
                    </Form.Item>
                  </span>
                }
              </div>
              <div className="referenceAdd-title reference-inherit">
                {
                  // <span className="geography-ref-item" style={{ width: `${singleWidth}%` }}>
                  <span className="geography-ref-item">
                    <Form.Item label="Geography ID">
                      {getFieldDecorator(`geology_id`, {
                        rules: [
                          {
                            required: true,
                            message: "Please input the Section Geography ID",
                          },
                        ],
                        initialValue: geographyId,
                      })(<Input className="inherit-input" onFocus={(e) => this.showSearchGeography("geology", e)}></Input>)}
                    </Form.Item>
                  </span>
                }
              </div>
              {/* <div className="referenceAdd-title">
                <Form.Item label="Formation No">
                  {getFieldDecorator("formation_no", {
                    rules: [
                      {
                        validator: (rule, value, cb) => {
                          !isNaN(value) ? cb() : cb(true);
                        },

                        required: true,
                        message: "Please input the correct Formation No"
                      }
                    ],
                    initialValue: ""
                  })(<Input></Input>)}
                </Form.Item>
              </div> */}
              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Group">
                    {getFieldDecorator("formation_group", {
                      initialValue: "",
                    })(<Input />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Bed">
                    {getFieldDecorator("formation_bed", {
                      rules: [{}],
                      initialValue: "",
                    })(<Input />)}
                  </Form.Item>
                </div>
              </div>
              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Formation">
                    {getFieldDecorator("formation_name", {
                      rules: [
                        {
                          required: true,
                          message: "Please input the Formation",
                        },
                      ],
                      initialValue: "",
                    })(<Input />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Member">
                    {getFieldDecorator("formation_member", {
                      initialValue: "",
                    })(<Input />)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Early interval">
                    {getFieldDecorator("early_interval", {
                      initialValue: "",
                    })(
                      <AutoComplete
                        onSearch={(value) => this.IntervalSearch(value, "Intervals", "early_interval")}
                        onChange={(value, e) => this.IntervalChange(value, e, "early_interval")}
                        placeholder=""
                      >
                        {intervals}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Intage max">
                    {getFieldDecorator("intage_max", {
                      rules: [{}],
                      initialValue: "",
                    })(
                      <AutoComplete onSearch={(value) => this.IntervalSearch(value, "Intages", "intage_max")} onChange={(value, e) => this.IntervalChange(value, e, "intage_max")} placeholder="">
                        {intages}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Early age">
                    {getFieldDecorator("early_age", {
                      initialValue: "",
                      rules: [
                        {
                          // required: true,
                          type: "number",
                        },
                      ],
                    })(
                      <InputNumber
                        className="age-format-unit"
                        // formatter={value => value ? `${value}Ma` : ""}
                        // parser={value => value.replace('Ma', '')}
                        name="early_age"
                        step={0.01}
                        min={0}
                        onBlur={(e) => this.Calculate(e)}
                      ></InputNumber>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Late age">
                    {getFieldDecorator("late_age", {
                      rules: [
                        {
                          // required: true,
                          type: "number",
                        },
                      ],
                      initialValue: "",
                    })(
                      <InputNumber
                        className="age-format-unit"
                        // formatter={value => value ? `${value}Ma` : ""}
                        // parser={value => value.replace('Ma', '')}
                        name="late_age"
                        step={0.01}
                        min={0}
                        onBlur={(e) => this.Calculate(e)}
                      ></InputNumber>
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item
                    label={
                      <div className="sectionGeography-scale">
                        <p>Paleogeography Latitude Decimal</p>
                      </div>
                    }
                  >
                    {getFieldDecorator("geo_latitude_decimal", {
                      rules: [{ type: "number" }],
                    })(<InputNumber disabled={true}></InputNumber>)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item
                    label={
                      <div className="sectionGeography-scale">
                        <p>Paleogeography Latitude Decimal</p>
                      </div>
                    }
                  >
                    {getFieldDecorator("geo_longitude_decimal", {
                      rules: [{ type: "number" }],
                    })(<InputNumber disabled={true}></InputNumber>)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Overlying">
                    {getFieldDecorator("formation_overlying", {
                      initialValue: "",
                    })(<Input />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Underline">
                    {getFieldDecorator("formation_underline", {
                      rules: [{}],
                      initialValue: "",
                    })(<Input />)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Color">
                    {getFieldDecorator("formation_color", {
                      initialValue: "",
                    })(<Input />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Main Lithology">
                    {getFieldDecorator("formation_lithology", {
                      rules: [{}],
                      initialValue: "",
                    })(<Input />)}
                  </Form.Item>
                </div>
              </div>

              <div className="sectionGeography-map sectionGeography-map-nopadding">
                <div className="sectionGeography-tutude">
                  <span className="sectionFormation-thickness">
                    <FormItem label="Thickness">
                      {getFieldDecorator(
                        "formation_thick_sign",
                        {}
                      )(
                        <Select>
                          <Option value="=">=</Option>
                          <Option value="<">&lt;</Option>
                          <Option value=">">&gt;</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>

                  <span>
                    <FormItem label="">
                      {getFieldDecorator("formation_thick", {
                        rules: [
                          {
                            type: "number",
                            // message: "Please input the Late age"
                          },
                        ],
                      })(<InputNumber placeholder="formation thick" min={0}></InputNumber>)}
                    </FormItem>
                  </span>

                  <span>
                    <FormItem label="">
                      {getFieldDecorator("formation_thick_unit", { initialValue: "meters" })(
                        <Select placeholder="formation thick unit">
                          <Option value="meters">Meter</Option>
                          <Option value="cm">Cm</Option>
                          <Option value="feet">Feet</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item label="Contact base">
                  {getFieldDecorator("formation_conta_base", {
                    rules: [{}],
                    initialValue: "",
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-comments last-formItem">
                <Form.Item label={<p>Paleoenviroment</p>} className="long-label">
                  {getFieldDecorator("formation_paleoenvironment", {
                    rules: [{}],
                    initialValue: "",
                  })(<TextArea rows={6}></TextArea>)}
                </Form.Item>
              </div>

              <div className="referenceSearch-btns sectionBase-btns">
                <div className="formation-complete-btns">
                  <Button type="gbdb" onClick={() => this.FormationGoUnit("continue")}>
                    <div>
                      <p> Save and</p>
                      <p> Continue</p>
                    </div>
                  </Button>
                  <Button type="gbdb" onClick={() => this.FormationGoUnit("complete")}>
                    <div>
                      <p> Complete</p>
                    </div>
                  </Button>
                  <Button type="gbdb" onClick={() => this.FormationGoUnit("pass")}>
                    <div>
                      <p>Pass</p>
                    </div>
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}
          <ReferenceOption childError={this.childError} conditions={{}} ref="reference"></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}

          <ReferenceTableResult
            searchParams={searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            onlySeeRef={true}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showRefDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailRefFormOk}
          onCancel={this.detailRefFormCancel}
        >
          <ReferenceDetailForm detailData={this.state.detailData} searchSection={this.searchSection} onlySeeRef={true}></ReferenceDetailForm>
        </Modal>

        {/* section */}

        <Modal
          className="searchResultModal"
          width="76%"
          visible={sectionOptionVisible}
          footer=""
          // closable={false}
          onOk={this.handFormOk}
          onCancel={this.handleFormCancel}
        >
          <SectionOption
            childError={this.childError}
            showGeography={sectionType === "geology" ? true : false}
            showBase={sectionType === "baseinfo" ? true : false}
            showUnit={false}
            ref="baseinfo"
          ></SectionOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("baseinfo");
                  }}
                >
                  {<p> Search</p>}
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.geographySearch}
          className="searchResultModal taxResultModal noBottomPadding tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleGeographyOk}
          onCancel={this.handleGeographyCancel}
        >
          <GeologyAllTableResult
            searchParams={searchParams}
            searchValues={searchValues}
            onGeography={this.onGeography}
            changeCheckSelected={this.changeCheckSelected}
            getDetail={this.geographyDetail}
            checkOne={true}
            checkGeographyOne={this.checkGeographyOne}
          ></GeologyAllTableResult>
        </Modal>
        <Modal
          title=""
          destroyOnClose
          visible={this.state.showSectionDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76%"
          footer={null}
          // header={null}
          onOk={this.detailSectionFormOk}
          onCancel={this.detailSectionFormCancel}
        >
          {sectionType === "baseinfo" ? (
            <BaseDetailForm detailData={this.state.detailData} viewGeography={this.viewGeography} seeOnly={true}></BaseDetailForm>
          ) : sectionType === "geology" ? (
            <GeographyDetailForm seeOnly={true} detailData={this.state.detailData} viewBase={this.viewBase}></GeographyDetailForm>
          ) : null}
        </Modal>
      </div>
    );
  }
}
SectionFormation = Form.create({})(SectionFormation);
export default SectionFormation;
