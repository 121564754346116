import React, { Component } from "react";
import echarts from "echarts";
import "./index.css";
// // 引入 ECharts 主模块
// import echarts from 'echarts/lib/echarts';
// // 引入柱状图
// import  'echarts/lib/chart/bar';
// // 引入提示框和标题组件
// import 'echarts/lib/component/tooltip';
// import 'echarts/lib/component/title';

class Echarts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canvasSize: { height: "320px", width: "320px" },
      isFull: false
    };
  }

  componentDidMount() {
    
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    let that = this;
    //put 官网实例option前面同级代码

    let option = this.props.data.option ? this.props.data.option : {};
   
    // 基于准备好的dom，初始化echarts实例
    this.myChart = echarts.init(document.getElementById(this.props.id));
    // 绘制图表
    this.myChart.setOption(option);

    this.myChart.on("click", function(params) {
      that.props.clickEchart(params);
    });
    window.addEventListener("resize", this.resize);
  }

  resize = () => {
    this.myChart.resize();
  };

  onFull = () => {
   
    let height = window.innerHeight;
    let width = window.innerWidth;
 
    this.setState(
      {
        canvasSize: { height: height * 0.8 + "px", width: width + "px" },
        isFull: true
      },
      () => {
        this.myChart.resize();
      }
    );
    
  };
  closeFull = () => {
    this.setState(
      {
        canvasSize: { height: "320px", width: "320px" },
        isFull: false
      },
      () => {
        this.myChart.resize();
      }
    );
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  componentWillReceiveProps(nextProps) {
    
    
if(nextProps.id!==this.props.id){
  
  let that = this;
  //put 官网实例option前面同级代码

  let option = nextProps.data.option ? nextProps.data.option : {};
 
  // 基于准备好的dom，初始化echarts实例
  setTimeout(()=>{
    this.myChart = echarts.init(document.getElementById(nextProps.id));
    // 绘制图表
    this.myChart.setOption(option);

    this.myChart.on("click", function(params) {
      that.props.clickEchart(params);
    });
    window.addEventListener("resize", this.resize);
  },1000)
 
}
     
    
  }
  render() {
    return (
      <div className={`${this.state.isFull ? "canvas-full" : ""}`}>
        {this.state.isFull ? (
          <span
            className="iconfont icon-baseline-close-px"
            onClick={() => this.closeFull()}
          ></span>
        ) : null}

        <div
          className={`${
            this.state.isFull ? "canvas-isfull" : "chart-component"
          }`}
          id={this.props.id}
          data={this.props.data}
          style={
            this.props.chartSize ? this.props.chartSize : this.state.canvasSize
          }
        ></div>
      </div>
      //  <div id="main" style={{ width: 400, height: 400 }}></div>
    );
  }
}

export default Echarts;
