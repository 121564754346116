/* eslint-disable  */
import React from "react";
import { Select } from "antd";
const { Option, OptGroup } = Select;

export default {
  referenceType: [
    " ",
    "journal article",
    "book",
    "edit book",
    "book chapter",
    "Ph. D. dissertation",
    "master thesis",
    "paper presented at meeting",
    "abstract",
    "technical report"
  ],
  languages: [
    "Arabic",
    "Arabic with English summary",
    "Arabic with English abstract",
    "Chinese",
    "Chinese with English summary",
    "Chinese with English abstract",
    "English",
    "French",
    "French with English summary",
    "French with English abstract",
    "German",
    "German with English summary",
    "German with English abstract",
    "Japanese",
    "Japanese with English summary",
    "Japanese with English abstract",
    "Portuguese",
    "Portuguese with English summary",
    "Portuguese with English abstract",
    "Russian",
    "Russian with English summary",
    "Russian with English abstract",
    "Spanish",
    "Spanish with English summary",
    "Spanish with English abstract",
    "Other languages"
  ],
  authorizers: [
    {
      key: "1",
      name: "lin1"
    },
    {
      key: "2",
      name: "lin2"
    },
    {
      key: "3",
      name: "lin3"
    }
  ],

  preservation: [
    "",
    "mold/impression",
    "adpression/compression",
    "trace",
    "concretion",
    "soft parts",
    "recrytalized",
    "permineralized",
    "dissolution traces",
    "charcoalification",
    "coalified"
  ],
  temporals: ["","snapshot", "time-averaged", "condensed"],
  spatials: ["","autochthonous", "parautochthonous", "allochthonous"],

  stattens: ["","conservation", "concentration"],
  orientations: ["","random", "life position", "prefered", "Excellent"],
  sediments: ["","abundent", "common", "few", "rare"],
  sortings: ["","very poor", "poor", "medium", "well", "Very Well"],
  fragmentations: ["none", "occational", "frequent", "extreme"],

  treatises: [
    "null",
    "Part A Introduction",
    "Part B Charophyta 2005",
    "Part C Sarcodina v1 1964",
    "Part C Sarcodina v2 1964",
    "Part D Protozoa 1954",
    "Part E Archaeocyatha 2nd edition 1972",
    "Part E Archaeocyatha and Porifera 1955",
    "Part E Porifera revised v1 2003",
    "Part E Porifera revised v2 2004",
    "Part F Coelenterata 1956",
    "Part F Rugosa and Tabulata v1 1981",
    "Part F Rugosa and Tabulata v2 1981",
    "Part G Bryozoa 1953",
    "Part G Bryozoa revised 1983",
    "Part H Brachiopoda v1 1965",
    "Part H Brachiopoda v2 1965",
    "Part H Brachiopoda revised v1 1997",
    "Part H Brachiopoda revised v2 2000",
    "Part H Brachiopoda revised v3 2000",
    "Part H Brachiopoda revised v4 2002",
    "Part H Brachiopoda revised v5 2006",
    "Part H Brachiopoda revised v6 2007",
    "Part I Mollusca 1 1960",
    "Part K Mollusca 3 1964",
    "Part L Mollusca 4 1957",
    "Part L Mollusca 4 revised v2 2009",
    "Part L Mollusca 4 revised v4 1996",
    "Part N Bivalvia v1 1969",
    "Part N Bivalvia v2 1969",
    "Part N Bivalvia v3 1971",
    "Part O Arthropoda 1 1959",
    "Part O Trilobita revised v1 1997",
    "Part P Arthropoda 2 1955",
    "Part Q Arthropoda 3 1961",
    "Part R Arthropoda 4 v1 1969",
    "Part R Arthropoda 4 v2 1969",
    "Part R Arthropoda 4 v3 1992",
    "Part R Arthropoda 4 v4 1992",
    "Part S Echinodermata 1 v1 1967",
    "Part S Echinodermata 1 v2 1967",
    "Part T Echinodermata 2 v1 1978",
    "Part T Echinodermata 2 v2 1978",
    "Part T Echinodermata 2 v3 1978",
    "Part U Echinodermata 3 v1 1966",
    "Part U Echinodermata 3 v2 1966",
    "Part V Graptolithina 1st edition 1955",
    "Part V Graptolithina 2nd edition 1970",
    "Part W Miscellanea 1962",
    "Part W Miscellanea supplement1 1975",
    "Part W Miscellanea supplement2 1981"
  ],
  fossilGroups: [
    {
      key: "null",
      children: []
    },
    {
      key: "Invertebrates",
      children: [
        {
          value: 0,
          key: "Acanthocephala"
        },
        {
          value: 1,
          key: "Annelida"
        },
        {
          value: 2,
          key: "Anthozoa (Cnidaria)"
        },
        {
          value: 3,
          key: "Aplacophora (Mollusca)"
        },
        {
          value: 4,
          key: "Archaeocyatha"
        },
        {
          value: 5,
          key: "Asterozoa (Echinodermata)"
        },
        {
          value: 6,
          key: "Bivalvia (Mollusca)"
        },
        {
          value: 7,
          key: "Blastozoa (Echinodermata)"
        },
        {
          value: 8,
          key: "Brachiopoda"
        },
        {
          value: 9,
          key: "Bryozoa/Ectoprocta"
        },
        {
          value: 10,
          key: "Carpozoa (Echinodermata)"
        },
        {
          value: 11,
          key: "Cephalopoda (Mollusca)"
        },
        {
          value: 12,
          key: "Chaetognatha"
        },
        {
          value: 13,
          key: "Chelicerata (Arthropoda)"
        },
        {
          value: 14,
          key: "Crinozoa (Echinodermata)"
        },
        {
          value: 15,
          key: "Crustacea (Arthropoda)"
        },
        {
          value: 16,
          key: "Ctenophora"
        },
        {
          value: 17,
          key: "Cubozoa (Cnidaria)"
        },
        {
          value: 18,
          key: "Cycliophora"
        },
        {
          value: 19,
          key: "Echinozoa (Echinodermata)"
        },
        {
          value: 20,
          key: "Echiura"
        },
        {
          value: 21,
          key: "Enteropneusta (Hemichordata)"
        },
        {
          value: 22,
          key: "Entoprocta"
        },
        {
          value: 23,
          key: "Gastropoda (Mollusca)"
        },
        {
          value: 24,
          key: "Gastrotrichia"
        },
        {
          value: 25,
          key: "Gnathostomulida"
        },
        {
          value: 26,
          key: "Graptolithina (Hemichordata)"
        },
        {
          value: 27,
          key: "Hexapoda (Arthropoda)"
        },
        {
          value: 28,
          key: "Hydrozoa (Cnidaria)"
        },
        {
          value: 29,
          key: "Hyolitha (Mollusca)"
        },
        {
          value: 30,
          key: "Kinorhyncha"
        },
        {
          value: 31,
          key: "Loricifera"
        },
        {
          value: 32,
          key: "Mesozoa"
        },
        {
          value: 33,
          key: "Micrognathozoa"
        },
        {
          value: 34,
          key: "Monoplacophora (Mollusca)"
        },
        {
          value: 35,
          key: "Myriapoda (Arthropoda)"
        },
        {
          value: 36,
          key: "Nematoda"
        },
        {
          value: 37,
          key: "Nematomorpha"
        },
        {
          value: 38,
          key: "Nemertea"
        },
        {
          value: 39,
          key: "Onychophora"
        },
        {
          value: 40,
          key: "Pentostomida"
        },
        {
          value: 41,
          key: "Phoronida"
        },
        {
          value: 42,
          key: "Placozoa"
        },
        {
          value: 43,
          key: "Platyhelminthes"
        },
        {
          value: 44,
          key: "Pogonophora"
        },
        {
          value: 45,
          key: "Polyplacophora (Mollusca)"
        },
        {
          value: 46,
          key: "Porifera"
        },
        {
          value: 47,
          key: "Priapulida"
        },
        {
          value: 48,
          key: "Pterbranchia (Hemichordata)"
        },
        {
          value: 49,
          key: "Rostroconchia (Mollusca)"
        },
        {
          value: 50,
          key: "Rotifera"
        },
        {
          value: 51,
          key: "Scaphopoda (Mollusca)"
        },
        {
          value: 52,
          key: "Scyphozoa (Cnidaria)"
        },
        {
          value: 53,
          key: "Sipunculida"
        },
        {
          value: 54,
          key: "Staurozoa (Cnidaria)"
        },
        {
          value: 55,
          key: "Tardigrada"
        },
        {
          value: 56,
          key: "Tardipolypoda"
        },
        {
          value: 57,
          key: "Tentaculita (Mollusca)"
        },
        {
          value: 58,
          key: "Trilobitomorpha (Arthropoda)"
        },
        {
          value: 59,
          key: "Vetulicolia"
        },
        {
          value: 60,
          key: "Xenoturbellida"
        }
      ]
    },
    {
      key: "Vertebrates ",
      children: [
        {
          value: 61,
          key: "Acanthodii (Vertebrata)"
        },
        {
          value: 62,
          key: "Agnatha (Vertebrata)"
        },
        {
          value: 63,
          key: "Amphibia (Vertebrata)"
        },
        {
          value: 64,
          key: "Aves (Vertebrata)"
        },
        {
          value: 65,
          key: "Cephalochordata (Chordata)"
        },
        {
          value: 66,
          key: "Chondrichthyes (Vertebrata)"
        },
        {
          value: 67,
          key: "Mammalia (Vertebrata)"
        },
        {
          value: 68,
          key: "Osteichthyes (Vertebrata)"
        },
        {
          value: 69,
          key: "Placodermi (Vertebrata)"
        },
        {
          value: 70,
          key: "Reptilia (Vertebrata)"
        },
        {
          value: 71,
          key: "Urochordata (Chordata)"
        }
      ]
    },
    {
      key: "Plants",
      children: [
        {
          value: 72,
          key: "Anthocerotophyta"
        },
        {
          value: 73,
          key: "Bryophyta"
        },
        {
          value: 74,
          key: "Coniferophyta"
        },
        {
          value: 75,
          key: "Cycadophyta"
        },
        {
          value: 76,
          key: "Equisetophyta"
        },
        {
          value: 77,
          key: "Filicophyta"
        },
        {
          value: 78,
          key: "Ginkgophyta"
        },
        {
          value: 79,
          key: "Gnetophyta"
        },
        {
          value: 80,
          key: "Lycopodiophyta"
        },
        {
          value: 81,
          key: "Magnoliophyta"
        },
        {
          value: 82,
          key: "Marchantiophyta"
        },
        {
          value: 83,
          key: "Psilotophyta"
        }
      ]
    },
    {
      key: "Microfossils and others",
      children: [
        {
          value: 84,
          key: "Acritarchs"
        },
        {
          value: 85,
          key: "Amoebozoa"
        },
        {
          value: 86,
          key: "Apicomplexa"
        },
        {
          value: 87,
          key: "Bacillariophyta/Diatoms"
        },
        {
          value: 88,
          key: "Charophyta"
        },
        {
          value: 89,
          key: "Chitinozoa"
        },
        {
          value: 90,
          key: "Chlorophyta"
        },
        {
          value: 91,
          key: "Chrysophyta"
        },
        {
          value: 92,
          key: "Ciliophora"
        },
        {
          value: 93,
          key: "Coccoliths"
        },
        {
          value: 94,
          key: "Conodonta"
        },
        {
          value: 95,
          key: "Cryptophyta"
        },
        {
          value: 96,
          key: "Cyanophyta/Stromatolites"
        },
        {
          value: 97,
          key: "Dinoflagellata"
        },
        {
          value: 98,
          key: "Euglenophyta"
        },
        {
          value: 99,
          key: "Foraminifera"
        },
        {
          value: 100,
          key: "Microspora"
        },
        {
          value: 101,
          key: "Mycetozoa"
        },
        {
          value: 102,
          key: "Phaeophyta"
        },
        {
          value: 103,
          key: "Pollenites"
        },
        {
          value: 104,
          key: "Prochlorophyta"
        },
        {
          value: 105,
          key: "Radiolaria"
        },
        {
          value: 106,
          key: "Rhodophyta"
        },
        {
          value: 107,
          key: "Sarcomastigophora"
        },
        {
          value: 108,
          key: "Silioflagellates"
        },
        {
          value: 109,
          key: "Sporites"
        },
        {
          value: 110,
          key: "Stromatoporoids"
        },
        {
          value: 111,
          key: "Trace fossils"
        },
        {
          value: 112,
          key: "Xanthophyceae"
        }
      ]
    },
    {
      key: "Invertebrates",
      children: [
        {
          value: 113,
          key: "small shelly fossil"
        }
      ]
    },
    {
      key: "Unknown",
      children: {
        value: 114,
        key: "unknown"
      }
    }
  ],
  taxonTypes: [
    "domain (superkingdom)",
    "kingdom",
    "phylum (division)",
    "subphylum",
    "superclass",
    "class",
    "subclass",
    "infraclass",
    "cohort",
    "superorder",
    "order",
    "suborder",
    "infraorder",
    "superfamily",
    "family",
    "subfamily",
    "tribe",
    "subtribe",
    "genus",
    "subgenus",
    "section",
    "subsection",
    "series",
    "subseries",
    "Group incertae sedis",
    "unknown"
  ],
  refTypes: [
    " ",
    "journal article",
    "book",
    "edit book",
    "book chapter",
    "Ph. D. dissertation",
    "master thesis",
    "paper presented at meeting",
    "abstract",
    "technical report"
  ],

  taxonTypes: [
    "domain (superkingdom)",
    "kingdom",
    "phylum (division)",
    "subphylum",
    "superclass",
    "class",
    "subclass",
    "infraclass",
    "cohort",
    "superorder",
    "order",
    "suborder",
    "infraorder",
    "superfamily",
    "family",
    "subfamily",
    "tribe",
    "subtribe",
    "genus",
    "subgenus",
    "section",
    "subsection",
    "series",
    "subseries",
    "Group incertae sedis",
    "unknown"
  ],
    bioerosions: ["none", "occational", "frequent", "extreme"],
  fossilGroup: value => {
    let optgrops = [];
    if (value) {
      value.map((item, index) => {
        optgrops.push(
          <OptGroup label={item.key}>
            {item.children && item.children.length > 0 ? (
              item.children.map(childItem => {
                let ops = [];
                ops.push(
                  <Option value={childItem.value}>{childItem.key}</Option>
                );

                return ops;
              })
            ) : (
              <Option value={item.key}>{item.key}</Option>
            )}
          </OptGroup>
        );
      });
    }

    return optgrops;
  },
 geochemicalData :[
    {
      key: "Stable isotope proxy",
      children: [
        {
          key: "1",
          value: "δ¹¹B"
        },
        {
          key: "2",
          value: "δ¹³Ccarb"
        },
        {
          key: "3",
          value: "δ¹³Corg"
        },
  
        {
          key: "19",
          value: "δ¹⁸Ocarb"
        },
        {
          key: "5",
          value: "δ¹⁸O"
        },
        {
          key: "6",
          value: "δ³⁴S"
        },
        {
          key: "7",
          value: "⁸⁷Sr/⁸⁶Sr"
        },
        {
          key: "8",
          value: "δ⁹⁸Mo"
        },
        {
          key: "9",
          value: "δ¹¹B"
        },
        {
          key: "10",
          value: "¹⁸⁷Os/¹⁸⁸Os"
        },
        {
          key: "11",
          value: "¹⁸⁷Os/¹⁸⁶Os"
        },
        {
          key: "4",
          value: "δ¹⁸N"
        }
      ]
    },
    {
      key: "Element proxy",
      children: [
        {
          key: "12",
          value: "TOC"
        },
        {
          key: "13",
          value: "Al"
        },
        {
          key: "14",
          value: "TOC"
        },
        {
          key: "15",
          value: "V"
        },
        {
          key: "16",
          value: "Cr"
        },
        {
          key: "17",
          value: "V/Cr"
        },
        {
          key: "18",
          value: "CrEF"
        }
      ]
    }
  ],
 GeochemicalOps:value => {
    let optgrops = [];
  
    value.map((item, index) => {
      optgrops.push(
        <OptGroup label={item.key}>
          {item.children ? (
            item.children.map(childItem => {
              let ops = [];
              ops.push(<Option value={childItem.key}>{childItem.value}</Option>);
  
              return ops;
            })
          ) : (
            <Option value={item.key}>{item.key}</Option>
          )}
        </OptGroup>
      );
    });
  
    return optgrops;
  },
  
  contactBase: [
    "",
    "conformity",
    "unconformity",
    "disconformity",
    "by fault ",
    "unknow"
  ]
};
