import React, { Component } from "react";
import "./index.css";


class About extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div class="box">
        <p> The Geobiodiversity Database (GBDB - www.geobiodiversity.com), an integrated system for the management and
            analysis of stratigraphic and paleontological information,
            was started in 2006 and became available online in 2007. </p>
        <p>Its goal is to facilitate regional and global scientific collaborations focused on regional and global
            correlation,quantitative stratigraphy,systematics, biodiversity dynamics,paleogeography, and paleoecology.
        </p>
        <p>It is unique among global, public access databases in that it is a section-based online database
            system,incorporating data from a wide range of disciplines of stratigraphy and paleontology, with inherent
            interrelationship between different kinds of data sets.</p>
        <p>Several Windows-based visualization and analysis applications,either fully integrated with the database or
            supported by subset-export functions,
            have been developed to make the database more useful as a scientific and educational tool. </p>
        <p>The GBDB became the formal database of the International Commission on Stratigraphy (ICS) in August 2012 at
            the 34th International Geological Congress in Brisbane,
            and will produce comprehensive and authoritative web-based stratigraphic information service for global
            geoscientists,educators and the public. </p>
        </div>
    );
  }
}
export default About;
