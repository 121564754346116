import React, { Component } from "react";
import userApis from "src/js/apis/user";
import { Input, Modal, Form, Button } from "antd";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (this.props.onRegister) {
      this.props.onRegister(this);
    }
  }
  submitRegister = async () => {
    let that = this;
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      let { username, password, email } = values;
      if (!err) {
        let params = {
          username,
          password,
          email,
          loginby: 1
        };
        let registerBack = await userApis.register(params);
        if (registerBack.error) {
          if (registerBack.state !== undefined && registerBack.state === 1) {
            Modal.confirm({
              title: registerBack.error,
              content: "Whether to log in or not",
              onOk() {
                that.props.registerGoLogin(email, password);
              },
              onCancel() {
             
              }
            });
          } else {
            Modal.error({
              title: "This is an error message",
              content: registerBack.error
            });
          }
        } else {
          this.props.registerSuccess();
        }
      }
    });
  };
  goRegister = e => {
    if (e.keyCode === 13) {
      this.submitRegister();
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="">
        <Form layout="vertical">
          <Form.Item label="User Name：">
            {getFieldDecorator("username", {
              rules: [
                {
                  required: true,
                  message: "Please input your Name"
                }
              ],
              initialValue: ""
            })(<Input placeholder="" onKeyDown={this.goRegister} />)}
          </Form.Item>
          <Form.Item label="Email：">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "The input is not valid E-mail!"
                },
                {
                  required: true,
                  message: "Please input your E-mail!"
                }
              ],
              initialValue: ""
            })(<Input placeholder="" onKeyDown={this.goRegister} />)}
          </Form.Item>
          <Form.Item label={<span>Password:</span>}>
            {getFieldDecorator("password", {
              initialValue: "",
              rules: [
                {
                  required: true,
                  message: "Please enter the correct password!",
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
                }
              ]
            })(<Input.Password onKeyDown={this.goRegister} />)}
          </Form.Item>
        </Form>
        <div>
          <p className="passwod-tip">
            At least 8 characters and must contain 1 uppercase letter, 1
            lowercase letter and 1 digit, not including special characters.
          </p>
        </div>
        <div className="user-login-opt">
          <Button type="gbdb" onClick={this.submitRegister}>
            Sign up
          </Button>
        </div>
      </div>
    );
  }
}

Register = Form.create({})(Register);
export default Register;
