import React, { Component } from 'react';
import {Form,Input,DatePicker, Button, Radio, InputNumber, message } from 'antd'
import './index.scss'
import axios from 'axios'

const {RangePicker}=DatePicker

 class AntdForm extends Component {
    constructor(props){
        super(props);
        this.state={
            upload:0,
            verify:0,
            enable_upload:2,
            enable_verify:2,
            id:null
        }
    }
    getIntegral(){
        axios.get('/api/admin/integral').then(res=>{
            if(res.data.error===null){
                if(res.data.result[0]){
                    var data=res.data.result[0]
                    this.setState({
                        upload:data.upload,
                        verify:data.verify,
                        enable_upload:data.enable_upload,
                        enable_verify:data.enable_verify,
                        id:data.id
                    })
                }
            } 
        })
    }
    componentDidMount(){
       this.getIntegral()
    }
    handleSubmit(){
        this.props.form.validateFields((err, values) => {
            if (!err) {
                
                var sendData={
                    id:this.state.id,
                    upload:values.upload,
                    verify:values.verify,
                    enable_upload:values.enable_upload,
                    enable_verify:values.enable_verify,
                }
               
                axios.post('/api/admin/change/integral',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('保存成功！')
                        this.getIntegral()
                        
                    }else{

                    }
                }).catch(err=>{
                    message.error('this an error message~')
                })
            }
        })
    }
    changePage(page,pageSize){
     
        this.setState({
            nowPage:page
        })
    }
    render() {
        const { getFieldDecorator} = this.props.form;
        return (
            <div id='ARule'>
                <div className='title'>积分规则</div>
                <div className="form">
                    <Form  layout='vertical'>
                        <div>
                            <Form.Item label='上传数据一次' className='rule'>
                                {
                                    getFieldDecorator('upload',{
                                        initialValue:this.state.upload
                                    })(<InputNumber></InputNumber>)
                                }
                                <span className='fen'>分</span>
                            
                            
                            </Form.Item>

                            <Form.Item className='radio'>
                                {
                                    getFieldDecorator('enable_upload',{
                                        initialValue:this.state.enable_upload
                                    })(<Radio.Group>
                                        <Radio value={1}>启用</Radio>
                                        <Radio className='secondRadio' value={0}>不启用</Radio>
                                    </Radio.Group>)
                                }
                                
                            </Form.Item>
                        </div>
                       
                        <div>
                            <Form.Item label='完成审核一次'  className='rule'>
                                {
                                    getFieldDecorator('verify',{
                                        initialValue:this.state.verify
                                    })(<InputNumber></InputNumber>)
                                }
                                <span className='fen'>分</span>
                            </Form.Item>
                            
                            <Form.Item className='radio'>
                                {
                                    getFieldDecorator('enable_verify',{

                                        initialValue:this.state.enable_verify
                                    })(<Radio.Group>
                                        <Radio value={1}>启用</Radio>
                                        <Radio className='secondRadio' value={0}>不启用</Radio>
                                    </Radio.Group>)
                                }
                                
                            </Form.Item>
                        </div>
                       

                        <Form.Item className='foot'>
                           <Button type='primary' onClick={this.handleSubmit.bind(this)}>保存</Button>
                        </Form.Item>

                    </Form>
                   
                </div>
            
            </div>
        
            );
    }
}
const ASection = Form.create()(AntdForm);
export default ASection

