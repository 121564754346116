import React from 'react'
import {Form,Button,Modal} from 'antd'

export default class UnitInfoModal extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        const formItemLayout = {
            labelCol: {
            //   xs: { span: 24 },
              sm: { span: 5 },
            },
            wrapperCol: {
            //   xs: { span: 24 },
              sm: { span: 19 },
            },
          };
          const {info}=this.props
       
        return (
                <Modal
                 footer={null}
                 className="AModal unitInfoModal"
                 visible={this.props.visible}
                 onCancel={this.props.handleClose}
                >
                    <div className='form'>
                    <Form {...formItemLayout}>

                        <Form.Item label='Reference ID'>
                            <span>{info.ref_id}</span>
                        </Form.Item>

                        <Form.Item label='Formation ID'>
                            <span>{info.formation_id}</span>
                        </Form.Item>

                        <Form.Item label='Unit ID'>
                            <span>{info.unit_id}</span>
                        </Form.Item>

                        <div className='line'>
                            <Form.Item className='lineItem' label='Early interval'>
                                <span>{info.early_interval}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Intage max'>
                                <span>{info.intage_max}</span>
                            </Form.Item>
                        </div>

                        <div className='line'>
                            <Form.Item className='lineItem' label='Early age'>
                                <span>{info.early_age}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Late age'>
                                <span>{info.late_age}</span>
                            </Form.Item>
                        </div>

                        <div className='table'>
                            <table>
                                <tbody>
                                <tr>
                                    <td>Color1</td>
                                    <td>Color2</td>
                                    <td>Lithology1</td>
                                    <td>Lithology2</td>
                                    <td>Relation</td>
                                </tr>
                                <tr>
                                    <td>{info.unit_color1a}</td>
                                    <td>{info.unit_color2a}</td>
                                    <td>{info.unit_lithology1a}</td>
                                    <td>{info.unit_lithology2a}</td>
                                    <td>{info.unit_relationa}</td>
                                </tr>
                                <tr>
                                    <td>{info.unit_color1b}</td>
                                    <td>{info.unit_color2b}</td>
                                    <td>{info.unit_lithology1b}</td>
                                    <td>{info.unit_lithology2b}</td>
                                    <td>{info.unit_relationb}</td>
                                </tr>
                                <tr>
                                    <td>{info.unit_color1c}</td>
                                    <td>{info.unit_color2c}</td>
                                    <td>{info.unit_lithology1c}</td>
                                    <td>{info.unit_lithology2c}</td>
                                    <td>{info.unit_relationc}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <Form.Item label='Thickness'>
                            <span>{info.unit_thickness?(info.unit_thickness_sign+info.unit_thickness+info.unit_thickness_unit):0}</span>
                        </Form.Item>

                        <Form.Item label='Altitude'>
                            <span>{info.unit_sum}</span>
                        </Form.Item>

                        <Form.Item label='Contact base'>
                            <span>{info.unit_con_base}</span>
                        </Form.Item>

                        <Form.Item label='Sediment'>
                            <span>{info.unit_sediment}</span>
                        </Form.Item>

                        <Form.Item label='Facies'>
                            <span>{info.unit_facies}</span>
                        </Form.Item>

                        <Form.Item label='Paleoenvironment'>
                            <span>{info.unit_paleoenvironment}</span>
                        </Form.Item>

                    </Form>
                    <div className='foot'>
                        <Button  type='agbdb1' onClick={this.props.handleClose.bind(this)}>关闭</Button>
                    </div>
                    </div>
                </Modal>
           
        )
    }
}
