import React, { Component } from "react";

import { NavLink } from "react-router-dom";
import "./index.css";
import { Form, Input, Select, Button, Modal, InputNumber } from "antd";

import Apis from "src/js/apis/api";
import Utils from "src/js/utils";
import datas from "commons/data.js";
import Storage from "src/js/storage";

import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";

import SectionOption from "commons/AllOptions/Section/index.js";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";
// import SectionAllTableResult from "commons/AllTableList/Section/SectionAll/index.js";
import UnitTableResult from "commons/AllTableList/Section/Unit/index.js";
import FormationDetailForm from "commons/AllDetails/Section/Formation/index.js";
import Users from "src/js/users";
const { Option } = Select;
let {
  preservation,
  temporals,
  spatials,
  stattens,
  orientations,
  sediments,
  sortings,
  fragmentations,
  bioerosions
} = datas;
class CollectionAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitSearch: false,
      inheritRefIds: "",
      unitId: "",
      unitInfo: {},
      MaxMaxCollNo: 1,
      allChildError: {}
    };
  }
  isGbdbUser = async () => {
    let Inputerid=false
    let token = Storage.getItem("token");
     Inputerid = await Users.isGbdbInputer();

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    } else {
      if (Inputerid == false) {
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title: "Sorry!!",
            content: "You do not have permission to add data"
          });
          this.props.history.push("/home");
        }
      }
    }
  };
  async componentDidMount() {
   
    this.isGbdbUser();
    let MaxMaxCollNo = await Apis.getMaxCollNo({});

    if (MaxMaxCollNo.result) {
      this.setState({
        MaxMaxCollNo: MaxMaxCollNo.result
      });
    } else {
      Modal.error({
        title: "This is an error message",
        content: MaxMaxCollNo.error
      });
    }

    if (this.props.location.search) {
      let inheritRefIds =
        Utils.getSearch(this.props.location.search, "refId") || "";
      let unitId = Utils.getSearch(this.props.location.search, "unitId") || "";
      this.setState(
        {
          inheritRefIds,
          unitId
        },
        async () => {
          let params = {
            unit_id: unitId
          };
          let getUnitsBack = await Apis.getUnits(params);
          if (getUnitsBack.result) {
            this.setState(
              {
                unitInfo: getUnitsBack.result[0]
              },
              async () => {
                let { unitInfo } = this.state;
                let sectionid = unitInfo.section_basic_id;

                let params = {
                  section_id: sectionid
                };

                let sectionSearchBack = await Apis.sectionBase(params);
                this.setState({
                  sectionInfo: sectionSearchBack.result[0]
                });
              }
            );
          } else {
            Modal.error({
              title: "This is an error message",
              content: "No information found"
            });
            this.setState({
              unitInfo: {}
            });
          }
        }
      );
    }
  }

  clickUnitId = value => {
    this.props.form.setFieldsValue({
      unit_id: value.unit_id || "",
      ref_id: value.ref_id || ""
    });
    this.setState(
      {
        unitInfo: {},
        unitSearch: false,
        unitId: value.unit_id || "",
        inheritRefIds: value.ref_id || ""
      },
      async () => {}
    );
  };

  collectionGoTax = type => {
    let { unitInfo } = this.state;
    if (type === "list") {
      let url = "/collection/addTaxonomies?";

      this.props.history.push(url);
    }
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let collection = Utils.filterParams(values);
        let collections = Utils.filterCondition(collection);
        collections = Utils.addEnterer(collections);
        collections = Utils.addAudit(collection, unitInfo);
        // 化石查询
        let searchResult = await Apis.createCollection(collections);
        if (searchResult.error) {
          Modal.error({
            title: "This is an error message",
            content: searchResult.error
          });
        } else if (searchResult.result) {
          let collectionId = searchResult.result
            ? searchResult.result.coll_id
            : "";
          if (collectionId) {
            let { sectionInfo } = this.state;
        
            let params = {};
            let addParams = {
              collection_id: collectionId,
              geology_id: sectionInfo.geology_id,
              lithostratigraphy_level: "LithostratigraphyCollection",
              enterer: Storage.getItem("gbdbid")
            };
           
            let collectionInfo = searchResult.result;

            addParams = Utils.addAudit(addParams, collectionInfo);
          

            // collections
            Object.assign(params, addParams);
            let fossilOccurrenceBack = await Apis.createFossilOccurrence(
              params
            );
            if (fossilOccurrenceBack.result) {
              if (type === "complete") {
                Modal.success({
                  title: "This is a successful message",
                  content: "successfully added "
                });
                this.props.form.resetFields();
              } else if (type === "continue") {
                let { inheritRefIds, unitId } = this.state;
                let url = "/collection/addTaxonomies?";

                let refUrl = "refId=" + inheritRefIds || "";
                let unitUrl = "&unitId=" + unitId || "";
                let collectUrl = "&collId=" + collectionId || "";
                let params = `${url}${refUrl}${unitUrl}${collectUrl}`;
               
                this.props.history.push(params);
              }
             
            }
          }

          // this.props.history.push({ pathname: url, query: queryParam });
        }
      }
    });
  };

  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };
  clickRefId = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };
  onRef = ref => {
    this.child = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true
    });

    //点击 detail 按扭执行代码
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  checkAgain = sectionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    let showType = "refSearch";

    this.refs[sectionType].validateFieldsAndScroll(
      async (err, searchValues) => {
        if (sectionType === "reference") {
          showType = "refSearch";
        } else {
          showType = "geographySearch";
        }

        if (!err) {
          let searchParams = Utils.filterParams(searchValues);

          searchParams = Utils.filterCondition(searchParams);

          this.setState(
            {
              ResultVisible: false,
              sectionOptionVisible: false,
              [showType]: true,
              refCondition: false,
              searchParams,
              searchValues,
              sectionType
            },
            () => {
              setTimeout(() => {
                let { searchParams, searchValues, sectionType } = this.state;

                if (sectionType === "reference") {
                  this.child.searchReferenceDatas(searchParams, searchValues);
                } else {
                  this.geographyChild.searchSectionDatas(
                    searchParams,
                    searchValues,
                    sectionType
                  );
                }
              }, 20);
            }
          );
        }
      }
    );
  };
  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };
  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false
    });
  };

  // section
  showSearchGeography = (type, e) => {
    e.target.blur();
    this.setState(
      {
        sectionType: type
      },
      () => {
        this.setState({
          sectionOptionVisible: true
        });
      }
    );
  };

  // section
  onGeography = ref => {
    this.geographyChild = ref;
  };
  geographyDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showSectionDetailForm: true
    });
  };
  handFormOk = () => {
    this.setState({
      sectionOptionVisible: false
    });
  };
  handleFormCancel = () => {
    this.setState({
      sectionOptionVisible: false
    });
    Modal.destroyAll();
  };

  showSearchGeography = (type, e) => {
    e.target.blur();
    this.setState(
      {
        sectionType: type
      },
      () => {
        this.setState({
          sectionOptionVisible: true
        });
      }
    );
  };
  handleGeographyOk = () => {
    this.setState({
      geographySearch: false
    });
  };
  handleGeographyCancel = () => {
    this.setState({
      geographySearch: false
    });
  };
  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }));
  };
  clickGeographyId = (type, value) => {
    this.setState({
      baseInfo: value,
      geologyInfo: value,
      geographySearch: false,
      inheritRefIds: value.ref_id || "",
      baseId: value.section_basic_id || "",
      formationId: value.formation_id || "",
      early_age: value.early_age || "",
      late_age: value.late_age || ""
    });
    this.props.form.setFieldsValue({
      geology_id: value.geology_id || "",
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || "",
      formation_id: value.formation_id || "",
      early_age: value.early_age || "",
      late_age: value.late_age || ""
    });
  };

  checkUnitOne = value => {
    this.setState(
      {
        geographySearch: false
      },
      async () => {
        this.props.form.setFieldsValue({
          section_basic_id: value.section_basic_id || "",
          ref_id: value.ref_id || "",
          geology_id: value.geology_id || "",
          formation_id: value.formation_id || "",
          early_age: value.early_age || "",
          late_age: value.late_age || "",
          unit_id: value.unit_id || ""
        });
        this.setState(
          {
            inheritRefIds: value.ref_id,
            baseId: value.section_basic_id,
            geographyId: value.geology_id,
            formationId: value.formation_id,

            early_age: value.early_age || "",
            late_age: value.late_age || "",
            unitId: value.unit_id || "",
            unitInfo: value
          },
          async () => {
            let { unitInfo } = this.state;
            let sectionid = unitInfo.section_basic_id;
            let params = {
              section_id: sectionid
            };
            let sectionSearchBack = await Apis.sectionBase(params);
            if (
              sectionSearchBack.result &&
              sectionSearchBack.result.length > 0
            ) {
              this.setState({
                sectionInfo: sectionSearchBack.result[0]
              });
            } else {
              this.setState({
                sectionInfo: {}
              });
            }
          }
        );
      }
    );
  };
  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType
    });
  };
  detailSectionFormOk = () => {
    this.setState({
      showSectionDetailForm: false
      // sectionType: "baseinfo"
    });
  };
  detailSectionFormCancel = () => {
    this.setState({
      showSectionDetailForm: false
      // sectionType: "baseinfo"
    });
  };
  goHistoryBack = () => {
    this.props.history.goBack();
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let {
      inheritRefIds,
      unitId,
      MaxMaxCollNo,

      searchParams,
      sectionOptionVisible,
      sectionType,
      searchValues
    } = this.state;
    return (
      <div id="collectionAdd" className="">
        <div className="searchBase-title collectionAdd-title">
          <div className="reference-search-operate">
            <p>Add Collection</p>
          </div>
          <div className="common-back">
            <NavLink to="/references/search/collection">
              <span
                className="iconfont icon-back"
                //  onClick={this.goHistoryBack}
              ></span>
            </NavLink>
          </div>
        </div>
        <div className="reference-add-main collection-add-main">
          <div className="reference-add-form collection-add-form">
            <Form>
              <div className="referenceAdd-title">
                <Form.Item label="Reference ID">
                  {getFieldDecorator("ref_id", {
                    rules: [
                      {
                        type: "number",
                        required: true,
                        message: "Please input the Reference"
                      }
                    ],
                    initialValue: Number(inheritRefIds) || ""
                  })(<InputNumber onFocus={this.showSearchRef} disabled />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Unit ID">
                  {getFieldDecorator("unit_id", {
                    rules: [
                      {
                        required: true,
                        message: "Please input the Unit ID"
                      }
                    ],
                    initialValue: unitId || ""
                  })(
                    <InputNumber
                      onFocus={e => this.showSearchGeography("unit", e)}
                    ></InputNumber>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Coll Number">
                  {getFieldDecorator("coll_no", {
                    rules: [
                      {
                        required: true,
                        message: "Please input Coll Number"
                      }
                    ],
                    // initialValue: MaxMaxCollNo
                    initialValue: ''
                  })(<Input  />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-title" style={{display:"flex"}}>
                  <span className="search-line-pre">
                  <Form.Item label="Thickness">
                    {getFieldDecorator("coll_depthlower", {
                      rules: [{
                        pattern: "^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$",
                        message: '请输入两位内小数'
                      }],
                      initialValue: ""
                    })(<Input></Input>)}
                  </Form.Item>
                  </span>
                  <span className="search-line" style={{marginLeft:"14px",marginRight:"14px",marginTop:"8px"}}>
                    <span className="iconfont icon-line1"></span>
                  </span>
                  <span>
                    <Form.Item>
                      {getFieldDecorator("coll_depthupper", {
                        rules: [{
                        pattern: "^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$",
                        message: '请输入两位内小数'
                        }],
                        initialValue: ""
                      })(<Input></Input>)}
                    </Form.Item>
                  </span>
              </div>
              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Modes of preservation</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_preservation", {
                    rules: [{}],
                    initialValue: preservation[0]
                  })(
                    <Select placeholder="">
                      {preservation.map((item, index) => {
                        return (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Original biominerals</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_biominerals", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Replacement Minernals</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_minerals", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Special modes of occurrence</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_occurrence", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Temporal Resolution</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_tmporal", {
                    rules: [{}],
                    initialValue: temporals[0]
                  })(
                    <Select placeholder="">
                      {temporals.map((item, index) => {
                        return (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Spatial resolution</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_resolution", {
                    rules: [{}],
                    initialValue: spatials[0]
                  })(
                    <Select placeholder="">
                      {spatials.map((item, index) => {
                        return (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Lager statten type</p>}>
                  {getFieldDecorator("coll_type", {
                    rules: [{}],
                    initialValue: stattens[0]
                  })(
                    <Select placeholder="">
                      {stattens.map((item, index) => {
                        return (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Degree of Concentration</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_concentration", {
                    rules: [{}],
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Spatial orientation</p>}>
                  {getFieldDecorator("coll_orientation", {
                    rules: [{}],
                    initialValue: orientations[0]
                  })(
                    <Select placeholder="">
                      {orientations.map((item, index) => {
                        return (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label={<p>Anatomical detail</p>}>
                  {getFieldDecorator("coll_detail", {
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p className="lineNormal">Abundance in sediment</p>
                    </div>
                  }
                >
                  {getFieldDecorator("coll_sediment", {
                    rules: [{}],
                    initialValue: sediments[0]
                  })(
                    <Select placeholder="">
                      {sediments.map((item, index) => {
                        return (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Size sorting</p>}>
                  {getFieldDecorator("coll_sorting", {
                    rules: [{}],
                    initialValue: sortings[0]
                  })(
                    <Select placeholder="">
                      {sortings.map((item, index) => {
                        return (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Fragmentation</p>}>
                  {getFieldDecorator("coll_fragmentation", {
                    rules: [{}],
                    initialValue: fragmentations[0]
                  })(
                    <Select placeholder="">
                      {fragmentations.map((item, index) => {
                        return (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Bioerpsion</p>}>
                  {getFieldDecorator("coll_Bioerosion", {
                    rules: [{}],
                    initialValue: bioerosions[0]
                  })(
                    <Select placeholder="">
                      {bioerosions.map((item, index) => {
                        return (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item label={<p>Encrustation</p>}>
                  {getFieldDecorator("coll_Encrustation", {
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item
                  label={<p className="lineNormal">Comments on preservation</p>}
                >
                  {getFieldDecorator("coll_preservations", {
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label={<p>Size classes</p>}>
                  {getFieldDecorator("coll_classes", {
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-title">
                <Form.Item
                  label={<p className="lineNormal">Feeding/predation traces</p>}
                >
                  {getFieldDecorator("coll_traces", {
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-title collectionAdd-comments">
                <Form.Item
                  label={
                    <p className="lineNormal">
                      Comments on included components
                    </p>
                  }
                >
                  {getFieldDecorator("coll_components", {
                    initialValue: ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceSearch-btns">
                <div className="formation-complete-btns collection-complete-btns">
                  <Button
                    type="gbdb"
                    onClick={() => {
                      this.collectionGoTax("continue");
                    }}
                  >
                   
                      <p> Save and</p>
                      <p> Continue</p>
                  
                  </Button>
                  <Button
                    type="gbdb"
                    onClick={() => {
                      this.collectionGoTax("complete");
                    }}
                  >
                    <p> Complete </p>
                  </Button>
                 
                </div>
                <div className="formation-complete-btns collection-complete-btns">
                 
                  <Button
                    title="Add collection list"
                    type="gbdb"
                    onClick={() => {
                      this.collectionGoTax("list");
                    }}
                  >
                    <p>Add Collection List</p>
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>

        {/*  */}
        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}
          <ReferenceOption
            childError={this.childError}
            conditions={{}}
            ref="reference"
          ></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}

          <ReferenceTableResult
            searchParams={searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            onlySeeRef={true}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showRefDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailRefFormOk}
          onCancel={this.detailRefFormCancel}
        >
          <ReferenceDetailForm
            detailData={this.state.detailData}
            // searchSection={this.searchSection}
            onlySeeRef={true}
          ></ReferenceDetailForm>
        </Modal>

        {/* section */}

        <Modal
          destroyOnClose
          className="searchResultModal"
          width="76%"
          visible={sectionOptionVisible}
          footer=""
          // closable={false}
          onOk={this.handFormOk}
          onCancel={this.handleFormCancel}
        >
          <SectionOption
            // conditions={conditions}
            showGeography={sectionType === "geology" ? true : false}
            showUnit={sectionType === "unit" ? true : false}
            showBase={sectionType === "baseinfo" ? true : false}
            showFomation={sectionType === "formation" ? true : false}
            ref={sectionType}
          ></SectionOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain(sectionType);
                  }}
                >
                  {<p> Search</p>}
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.geographySearch}
          className="searchResultModal taxResultModal noBottomPadding tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleGeographyOk}
          onCancel={this.handleGeographyCancel}
        >
          <UnitTableResult
            searchParams={searchParams}
            searchValues={searchValues}
            onGeography={this.onGeography}
            changeCheckSelected={this.changeCheckSelected}
            getDetail={this.geographyDetail}
            checkOne={true}
            checkUnitOne={this.checkUnitOne}
          ></UnitTableResult>
        </Modal>
        <Modal
          title=""
          destroyOnClose
          visible={this.state.showSectionDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76%"
          footer={null}
          // header={null}
          onOk={this.detailSectionFormOk}
          onCancel={this.detailSectionFormCancel}
        >
          {sectionType === "baseinfo" ? (
            <BaseDetailForm
              detailData={this.state.detailData}
              viewGeography={this.viewGeography}
              seeOnly={true}
            ></BaseDetailForm>
          ) : sectionType === "geology" ? (
            <GeographyDetailForm
              seeOnly={true}
              detailData={this.state.detailData}
              viewBase={this.viewBase}
            ></GeographyDetailForm>
          ) : sectionType === "formation" ? (
            <FormationDetailForm
              seeOnly={true}
              detailData={this.state.detailData}
            ></FormationDetailForm>
          ) : null}
        </Modal>
      </div>
    );
  }
}

CollectionAdd = Form.create({})(CollectionAdd);
export default CollectionAdd;
