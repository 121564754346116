import React from 'react'
import {Modal,Form,Button, message,Tooltip,Input} from 'antd'
import './identityModal.scss'
import moment from 'moment'
import axios from 'axios'


class AntdForm extends React.Component{
    constructor(props){
        super(props)
        this.state={
          
        }

        
    }
    handleSubmit(){
        Modal.confirm({
            content:<div>确定通过吗？</div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                const {modalData}=this.props
                //通过
                axios.post('/api/admin/userVerify',{
                    authState:3,//3:通过
                    userType:modalData.authState,//专家、录入员
                    uid:modalData.id,
                }).then(res=>{
                  
                    if(res.data.error===null){
                        this.props.reflash()
                        message.success('已通过~')
                    }
                }).catch(err=>{
                    message.error('this an error message~')
                })
                this.props.handleCancel()
            }
        })
    }
    handleReject(){
        //拒绝
        Modal.confirm({
            content:<div>
                        <div>请输入驳回原因：</div>
                        <Input.TextArea rows={4} className='minput'></Input.TextArea>
                    </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                const {modalData}=this.props
                var sendData={
                    authState:4,//4:拒绝
                    userType:modalData.authState,//专家、录入员
                    uid:modalData.id,
                    auth_comment:str
                }
             
                axios.post('/api/admin/userVerify',sendData).then(res=>{
                    if(res.data.error===null){
                        this.props.reflash()
                        message.success('已拒绝~')
                    }
                }).catch(err=>{
                   
                })
                this.props.handleCancel()
            }
        })
      
    }
    showImg(path){
        Modal.info({
            content:<img style={{width:'600px'}} src={'/api'+path}></img>,
            width:700,
            icon:'资料'
        })
    }
   
    render(){
        const {modalData}=this.props
      
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 },
            },
          };
        return (

            <div>
                <Modal 
                className='identityModal'
                visible={this.props.visible}
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
                footer={null}
                >
                    <div className='modalTitle'>
                    {modalData.authState<3 ? (modalData.authState===1?'申请成为录入员':'申请成为专家'):"用户信息"}
                    </div>
                    <div className='icon'>
                        <Tooltip title='点击放大'>
                        <img onClick={this.showImg.bind(this,modalData.filePath)} src={modalData.filePath ? '/api'+modalData.filePath : ''} alt='' ></img>
                        </Tooltip>
                        <p className="user-authState">{modalData.authState<3 ? "verifying":(modalData.authState >3 ? "verifyFaild":(modalData.userType===1?"Data compiler":"Expert"))}</p>
                    </div>
                    {/* <img className='bigImg' src={'/api'+modalData.filePath} alt='' ></img> */}

                    <div className='form'>
                    <Form {...formItemLayout}>
                        <div className='line'>
                            <Form.Item className='lineItem' label='First Name'>
                                <span>{modalData.firstname}</span>
                            </Form.Item>

                            <Form.Item className='lineItem' label='Last Name'>
                                <span>{modalData.lastname}</span>
                            </Form.Item>
                        </div>

                        <Form.Item label='Degree'>
                            <span>{modalData.degree}</span>
                        </Form.Item>

                        <Form.Item label='Graduate from'>
                            <span>{modalData.graduate}</span>
                        </Form.Item>

                        <Form.Item label='Study Time'>
                            <span>{(modalData.study_time_start && modalData.study_time_end) ?( `${moment(modalData.study_time_start).format('YYYY/MM/DD')}
                                ~${moment(modalData.study_time_end).format('YYYY/MM/DD')}`):null}</span>
                        </Form.Item>

                        <Form.Item label='Present Institute'>
                            <span>{modalData.preInstitute}</span>
                        </Form.Item>

                        <Form.Item label='Major in'>
                            <span>{modalData.major}</span>
                        </Form.Item>

                        <Form.Item className='lineHeight' label={<div>Other Palaeobiogeography<br/>Datebase</div>}>
                            <span>{modalData.otherpddb}</span>
                        </Form.Item>

                        {
                            modalData.authState===1?null:(
                                <Form.Item label='Paper list'>
                                <span>{modalData.paperList}</span>
                            </Form.Item>
                            )
                        }

                        {
                            modalData.authState===1?null:(
                                <Form.Item label='Boss'>
                                <span>{modalData.boss}</span>
                            </Form.Item>
                            )
                        }

                        <Form.Item label='Other information'>
                             <span>{modalData.otherInfo}</span>
                        </Form.Item>

                    </Form>
                    {modalData.authState<3 ? (
                    <div className="foot">
                           <Button  type='agbdb1' onClick={this.handleSubmit.bind(this)}>通过</Button>
                           <Button type='agbdb2' onClick={this.handleReject.bind(this)}>拒绝</Button>
                        </div>
                    ):""}
                    </div>
                </Modal>
            </div>
        )
      
    }
}
const ASectionShowInfo = Form.create()(AntdForm);
export default ASectionShowInfo