import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { hot } from "react-hot-loader";
// views
import Home from "views/Home/index";
// Insects 古昆虫
import Insects from "views/Insects/Index/index";
import InsectsSearch from "views/Insects/Search/index";
import InsectsList from "views/Insects/List/index";
import InsectsDetail from "views/Insects/Details/index";

// commons
// section公用的头部
import AllFolders from "commons/AllFolders/index";
import ScrollToTop from "commons/ScrollToTop/index";
import Main from "commons/Main/index";
import YearPicker from "commons/YearPicker/index";
import ErrorBoundary from "commons/ErrorBoundary/index";

import GeographySearchResult from "commons/AllSearchResult/Section/Geography/index.js";
import ReferenceSearchResult from "commons/AllSearchResult/Reference/index.js";
import GeochemicalSearchResult from "commons/AllSearchResult/Section/Geochemical/index.js";
import TaxonomySearchResult from "commons/AllSearchResult/Taxonomy/index.js";
import OccurenceSearchResult from "commons/AllSearchResult/Occurence/index.js";
import AllResultList from "commons/AllResultList/index.js";

// components
import ReferenceAdd from "components/Reference/add/index";
import ReferenceSearch from "components/Reference/search/index";
import ReferenceTableResult from "components/Reference/searchResult/index";

import SectionTitle from "components/Section/SectionTitle/index";
import SectionBase from "components/Section/base/index";
import SectionFormation from "components/Section/formation/index";

import SectionGeochemical from "components/Section/geochemical/index";
import SectionViewGeochemical from "components/Section/viewGeochemical/index";

import SectionGeography from "components/Section/geography/index";

import SectionUnit from "components/Section/unit/index";

import CollectionAdd from "components/Collection/add/index";
import AddTaxonomies from "components/Collection/addTaxonomies/index";
import Taxonomy from "components/Taxonomy/index/index";
import AddHigther from "components/Taxonomy/addHigther/index";
import AddIncomplete from "components/Taxonomy/addIncomplete/index";
import AddOriginal from "components/Taxonomy/addOriginal/index";
import AddRelationship from "components/Taxonomy/addRelationship/index";
import AddRevised from "components/Taxonomy/addRevised/index";
import AddTaxon from "components/Taxonomy/addTaxon/index";

import SearchBase from "components/Search/Index/index";
import SearchOccurence from "components/Search/Occurrence/index";
import SearchReference from "components/Search/Reference/index";
import SearchSection from "components/Search/Section/index";
import SearchTaxonomy from "components/Search/Taxonomy/index";
// import SearchResult from "components/Search/Result/index";

// oceanic
import Oceanic from "components/Oceanic/Index/index";
import Huici from "components/Oceanic/Huici/index";
import YanXin from "components/Oceanic/Yanxin/index";
import YanXing from "components/Oceanic/Yanxing/index";
import YanPao from "components/Oceanic/Yanpao/index";

// Profile
import Basic from "views/Profile/Basic/index";
import Advance from "views/Profile/Advance/Index/index";
import Compiler from "views/Profile/Advance/Compiler/index";
import Expert from "views/Profile/Advance/Expert/index";

// group
import Group from "views/Group/Index/index";
import GroupList from "views/Group/List/index";
import GroupSearch from "views/Group/Search/index";
import GroupCreate from "views/Group/Create/index";
import GroupApply from "views/Group/Apply/index";

import Center from "views/Center/index";
import Subset from "views/Subset/index";
import Message from "views/Message/index";
import NewsList from "views/News/List/index";
import NewsDetail from "views/News/Detail/index";
import Feedback from "views/Feedback/index";

// research
import Research from "views/Research/Index/index";
import ResearchCase from "views/Research/Case/index";
import ResearchPublic from "views/Research/Publication/index";
import ResearchFund from "views/Research/Funding/index";
import ResearchPeople from "views/Research/People/index";
import ResearchTransform from "views/Research/Transform/index";
import ResearchVis from "views/Research/vis/index";
import ResearchDownload from "views/Research/download/index";

// Confirm
import Confirm from "views/Confirm/index";
// Admin
import AdminIndex from "views/Admin/Index/index";
// 数据管理
import ACollection from "views/Admin/AdminData/Collection/index";
import AFossilList from "views/Admin/AdminData/FossilList/index";
import ASection from "views/Admin/AdminData/Section/index";
import AGeochemical from "views/Admin/AdminData/Geochemical/index";
import AStratigraphy from "views/Admin/AdminData/Stratigraphy/index";
import AUnit from "views/Admin/AdminData/Unit/index";
import ATaxonomy from "views/Admin/AdminData/taxonomy/index";
import AOceanic from "views/Admin/AdminData/oceanic/index";
// 积分管理
import Record from "views/Admin/Integral/Record/index";
import Rule from "views/Admin/Integral/Rule/index";

// 认证
import Identity from "views/Admin/Authenticate/Identity/index";

// 账号
import Member from "views/Admin/Account/Member/index";
import User from "views/Admin/Account/User/index";
import Manage from "views/Admin/Account/Manage/index";

// 新闻
import Redact from "views/Admin/News/Redact/index";
import Publish from "views/Admin/News/Publish/index";

// 系统

import System from "views/Admin/System/index";

// 问题反馈
import AdminFeedBack from "views/Admin/Feedback/index";

// 组件路由
import REchart from "components/Echarts/index";

// 关于
import About from "views/about/index"

// 联系
import Contact from "views/Contact/index"

import SubMap from "views/SubMap/index";

// 下载
import Download from "views/Download/Index/index";
import DownloadMain from "../views/Download/Main/index";

//相关软件
import RelatedSoftware from "views/RelatedSoftware/index";

// 审核
import AuditIndex from "views/Audit/Index/index";
// 审核数据管理
import ADCollection from "views/Audit/AdminData/Collection/index";
import ADFossilList from "views/Audit/AdminData/FossilList/index";
import ADSection from "views/Audit/AdminData/Section/index";
import ADGeochemical from "views/Audit/AdminData/Geochemical/index";
import ADStratigraphy from "views/Audit/AdminData/Stratigraphy/index";
import ADUnit from "views/Audit/AdminData/Unit/index";
import ADTaxonomy from "views/Audit/AdminData/taxonomy/index";


// aboutPage
import AboutIndex from "views/about/Index/index";
import AboutResearch from "views/about/Case/index";
import AboutPublic from "views/about/Publication/index";
import AboutFund from "views/about/Funding/index";
import AboutPeople from "views/about/People/index";
import AboutTransform from "views/about/Transform/index";
import AboutVis from "views/about/vis/index";

const Router = ({ component: Component, children, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Component {...props}>
        <Switch>{children}</Switch>
      </Component>
    )}
  />
);

const Root = () => (
  <BrowserRouter>
    <ErrorBoundary>
      <ScrollToTop>
        <div className="router-content">
          <Switch>
          <Router exact path="/AllFolders" component={AllFolders} />
            <Router exact path="/yearPicker" component={YearPicker} />
            <Router exact path="/confirm" component={Confirm} />
            {/* <Router exact path="/BaseUpload" component={BaseUpload} /> */}
            <Router path="/subMap" component={SubMap}>
              {/* <Router
                  exact
                  path="/subMap"
                  component={SubMap}
                /> */}
            </Router>
            {/* Admin */}
            <Router path="/admin" component={AdminIndex}>
              {/* 数据管理 */}
              <Router
                exact
                path="/admin/datas/collection"
                component={ACollection}
              />
              <Router
                exact
                path="/admin/datas/fossilList"
                component={AFossilList}
              />
              <Router exact path="/admin/datas/section" component={ASection} />
              <Router
                exact
                path="/admin/datas/geochemical"
                component={AGeochemical}
              />
              <Router
                exact
                path="/admin/datas/stratigraphy"
                component={AStratigraphy}
              />
              <Router exact path="/admin/datas/unit" component={AUnit} />
              <Router
                exact
                path="/admin/datas/taxonomy"
                component={ATaxonomy}
              />
              <Router exact path="/admin/datas/oceanic" component={AOceanic} />

              {/* 积分管理 */}
              <Router exact path="/admin/integral/record" component={Record} />
              <Router exact path="/admin/integral/rule" component={Rule} />

              {/* 认证管理 */}
              <Router
                exact
                path="/admin/authenticate/identity"
                component={Identity}
              />

              {/* 账号管理 */}
              <Router exact path="/admin/account/member" component={Member} />
              <Router exact path="/admin/account/user" component={User} />
              <Router exact path="/admin/account/manage" component={Manage} />

              {/* 新闻管理 */}
              <Router exact path="/admin/news/redact" component={Redact} />
              <Router exact path="/admin/news/publish" component={Publish} />

              {/* 系统管理 */}
              <Router exact path="/admin/system" component={System} />
              {/* 问题反馈 */}
              <Router exact path="/admin/feedback" component={AdminFeedBack} />
            </Router>
            <Router path="/" component={Main}>
              <Router exact path="/home" component={Home} />
              
              {/* Insects 古昆虫 */}
              <Router path="/insects" component={Insects}>
                <Router exact path="/insects/search" component={InsectsSearch} />
                <Router exact path="/insects/list" component={InsectsList} />
                <Router exact path="/insects/details" component={InsectsDetail} />
              </Router>
              <Router
                exact
                path="/views/geochemical"
                component={SectionViewGeochemical}
              />
              {/* reference */}
              <Router
                exact
                path="/references/search/:type"
                component={ReferenceSearch}
              />
                 <Router
                exact
                path="/references/allresult/:type"
                component={AllResultList}
              />
              <Router
                // exact
                path="/references/result/:type"
                component={ReferenceTableResult}
              />
              <Router exact path="/reference/add" component={ReferenceAdd} />
              {/* section */}
              <Router path="/section" component={SectionTitle}>
                <Router path="/section/baseinfo" component={SectionBase} />
                <Router
                  path="/section/geography"
                  component={SectionGeography}
                />
                <Router path="/section/unit" component={SectionUnit} />
                <Router
                  path="/section/formation"
                  component={SectionFormation}
                />
                <Router
                  path="/section/geochemical"
                  component={SectionGeochemical}
                />
              </Router>
              {/* collection */}
              <Router path="/collection/add" component={CollectionAdd} />
              <Router
                path="/collection/addTaxonomies"
                component={AddTaxonomies}
              />
              {/* Taxonomy */}
              <Router path="/taxonomy" component={Taxonomy}>
                <Router
                  exact
                  path="/taxonomy/addTaxon"
                  component={AddTaxon}
                />
                <Router
                  exact
                  path="/taxonomy/addHigther"
                  component={AddHigther}
                />
                <Router
                  exact
                  path="/taxonomy/addIncomplete"
                  component={AddIncomplete}
                />
                <Router
                  exact
                  path="/taxonomy/addOriginal"
                  component={AddOriginal}
                />
                <Router
                  exact
                  path="/taxonomy/addRelationship"
                  component={AddRelationship}
                />
                <Router
                  exact
                  path="/taxonomy/addRevised"
                  component={AddRevised}
                />
              </Router>

              {/* Oceanic */}
              <Router path="/oceanic/add" component={Oceanic}>
                <Router exact path="/oceanic/add/huici" component={Huici} />
                <Router exact path="/oceanic/add/yanxin" component={YanXin} />
                <Router exact path="/oceanic/add/yanpao" component={YanPao} />
                <Router
                  exact
                  path="/oceanic/add/lithology"
                  component={YanXing}
                />
              </Router>

              {/* Search */}
              <Router path="/search" component={SearchBase}>
                <Router
                  exact
                  path="/search/reference"
                  component={SearchReference}
                />
                <Router
                  exact
                  path="/search/section"
                  component={SearchSection}
                />
                <Router
                  exact
                  path="/search/taxonomy"
                  component={SearchTaxonomy}
                />

                <Router
                  exact
                  path="/search/occurence"
                  component={SearchOccurence}
                />
              </Router>

              <Router exact path="/echart" component={REchart} />
              
              {/* About 临时添加，后面需要替换掉 */}
              {/* <Router exact path="/about" component={About} /> */}
              <Router path="/about" component={AboutIndex}>
                <Router exact path="/about/research" component={AboutResearch} />
                <Router
                  exact
                  path="/about/publication"
                  component={AboutPublic}
                />
                <Router
                  exact
                  path="/about/funding"
                  component={AboutFund}
                />
                <Router
                  exact
                  path="/about/people"
                  component={AboutPeople}
                />
                <Router
                  exact
                  path="/about/transform"
                  component={AboutTransform}
                />
                <Router
                  exact
                  path="/about/vis"
                  component={AboutVis}
                />
              </Router>

              <Router exact path="/contact" component={Contact} />

              <Router path="/download" component={Download}>
                <Router exact path="/download/main" component={DownloadMain} />
              </Router>

              {/* group */}
              <Router path="/group" component={Group}>
                <Router exact path="/group/list" component={GroupList} />
                <Router exact path="/group/create" component={GroupCreate} />
                <Router exact path="/group/apply" component={GroupApply} />
                <Router exact path="/group/search" component={GroupSearch} />
              </Router>

              {/* profile */}

              <Router exact path="/profile/basic" component={Basic} />

              <Router path="/profile/advance" component={Advance}>
                <Router
                  exact
                  path="/profile/advance/compiler"
                  component={Compiler}
                />

                <Router
                  exact
                  path="/profile/advance/expert"
                  component={Expert}
                />
              </Router>
              
              {/* 相关软件 */}
              <Router exact path="/relatedSoftware" component={RelatedSoftware} />

              {/* Center */}
              <Router exact path="/center" component={Center} />
              <Router path="/subset" component={Subset} />
              <Router exact path="/message" component={Message} />
              <Router exact path="/news/list" component={NewsList} />
              <Router path="/news/detail" component={NewsDetail} />
              <Router exact path="/feedback" component={Feedback} />
              <Router exact path="/audit" component={AuditIndex} />
              {/* <Router path="/audit" component={AuditIndex}>
                <Router
                    exact
                    path="/audit/datas/section"
                    component={ADSection}
                  />
                  <Router
                    exact
                    path="/audit/datas/geochemical"
                    component={ADGeochemical}
                  />
                  <Router
                    exact
                    path="/audit/datas/stratigraphy"
                    component={ADStratigraphy}
                  />
                  <Router
                    exact
                    path="/audit/datas/unit"
                    component={ADUnit}
                  />
                  <Router
                    exact
                    path="/audit/datas/collection"
                    component={ADCollection}
                  />
                  <Router
                    exact
                    path="/audit/datas/fossilList"
                    component={ADFossilList}
                  />
                  <Router
                    exact
                    path="/audit/datas/taxonomy"
                    component={ADTaxonomy}
                  />
              </Router> */}
              <Router path="/audit" component={AuditIndex}>
                {/* 数据管理 */}
                <Router
                  exact
                  path="/audit/datas/collection"
                  component={ADCollection}
                />
                <Router
                  exact
                  path="/audit/datas/fossilList"
                  component={ADFossilList}
                />
                <Router exact path="/audit/datas/section" component={ADSection} />
                <Router
                  exact
                  path="/audit/datas/geochemical"
                  component={ADGeochemical}
                />
                <Router
                  exact
                  path="/audit/datas/stratigraphy"
                  component={ADStratigraphy}
                />
                <Router exact path="/audit/datas/unit" component={ADUnit} />
                <Router
                  exact
                  path="/audit/datas/taxonomy"
                  component={ADTaxonomy}
                />
            </Router>

              {/* research */}
              <Router path="/research" component={Research}>
                <Router exact path="/research/case" component={ResearchCase} />
                <Router
                  exact
                  path="/research/publication"
                  component={ResearchPublic}
                />
                <Router
                  exact
                  path="/research/funding"
                  component={ResearchFund}
                />
                <Router
                  exact
                  path="/research/people"
                  component={ResearchPeople}
                />
                <Router
                  exact
                  path="/research/transform"
                  component={ResearchTransform}
                />
                <Router
                  exact
                  path="/research/vis"
                  component={ResearchVis}
                />
                <Router
                  exact
                  path="/research/download"
                  component={ResearchDownload}
                />
              </Router>

              {/* 结果列表 */}
              <Router
                // exact
                path="/searchs/result/reference"
                component={ReferenceSearchResult}
              />
              <Router
                exact
                path="/searchs/result/section/base"
                component={GeographySearchResult}
              />
              <Router
                exact
                path="/searchs/result/section/geochemical"
                component={GeochemicalSearchResult}
              />

              <Router
                exact
                path="/searchs/result/taxonomy"
                component={TaxonomySearchResult}
              />
              <Router
                exact
                path="/searchs/result/occurence"
                component={OccurenceSearchResult}
              />
              {/* <Router exact path="/mapDetail" component={MapDetail} /> */}
              <Route path="*" component={Home} />
            </Router>
          </Switch>
        </div>
      </ScrollToTop>
    </ErrorBoundary>
  </BrowserRouter>
);

export default hot(module)(Root);
