import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu, ConfigProvider, message } from "antd";
import "./index.scss";
import "../common/common.scss";
import axios from "axios";

const { SubMenu } = Menu;

export default class AdminIndex extends Component {
  constructor(props) {
    super(props);
    var arr = this.props.location.pathname.split("/");
    var openKeys = arr.length > 2 ? [arr[arr.length - 2]] : [""];
    var selectKeys = arr.length > 3 ? [arr[arr.length - 1]] : [""];

    this.state = {
      openKeys,
      selectKeys,
      userName: "",
      filePath: "",
      check_code: "",
      account: 0, //账号管理
      authentication: 0, //认证管理
      data: 0, //数据管理
      integral: 0, //积分管理
      news: 0, //新闻管理
      system: 0, //系统管理
      feedback: 0, //问题反馈
    };
  }

  rootSubmenuKeys = ["datas", "integral", "authenticate", "account", "news"];
  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };
  componentDidMount() {
    var data = localStorage.getItem("gbdbid");
    var l = localStorage.getItem("gbdblevel");

    if (l) {
      var level = JSON.parse(l).value;
      if (level === 0) {
        this.props.history.push("/home");
      }
      axios
        .get("/api/admin/authority")
        .then((res) => {
          if (res.data.error === null) {
            var data = res.data.result;
            var limit = data.filter((item) => {
              return item.id === level;
            })[0];
            if (limit) {
              this.setState(
                {
                  account: limit.account, //账号管理
                  authentication: limit.authentication, //认证管理
                  data: limit.data, //数据管理
                  integral: limit.integral, //积分管理
                  news: limit.news, //新闻管理
                  system: limit.system, //系统管理
                  feedback: limit.feedback,
                },
                () => {}
              );
            }
          }
        })
        .catch((err) => {
          message.error("this an error message~");
        });
    }

    if (data) {
      let token = JSON.parse(localStorage.getItem("token")).value;
      if (token) {
        axios.get(`/api/user/refresh?token=${token}&loginby=1`).then((res) => {
          if (res.data.error === null) {
            var data = res.data.result;
            this.setState({
              username: data.username,
              check_code: data.check_code,
              filePath: data.thumbnail ? "/api" + data.thumbnail : "",
            });
          } else {
            this.props.history.push("/home");
          }
        });
      }
    } else {
      this.props.history.push("/home");
    }
  }
  render() {
    const { account, authentication, data, integral, system, news, feedback, check_code } = this.state;
    return (
      <ConfigProvider autoInsertSpaceInButton={false}>
        <div id="Amain">
          <div className="container">
            <div className="sider">
              <div className="checkcode">{check_code ? `我的审核码：${check_code}` : null}</div>
              <Menu mode="inline" openKeys={this.state.openKeys} onOpenChange={this.onOpenChange.bind(this)} defaultSelectedKeys={this.state.selectKeys}>
                {data === 1 ? (
                  <SubMenu
                    key="datas"
                    title={
                      <span>
                        <svg t="1576917659106" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5216" width="20" height="20">
                          <path
                            id="svgcolor"
                            d="M923.71 718.26l0.65-0.8 0.52-0.66 0.68-0.91 0.42-0.59q0.36-0.52 0.7-1l0.31-0.49q0.37-0.59 0.73-1.2l0.21-0.37q0.38-0.67 0.73-1.35l0.13-0.26q0.38-0.74 0.72-1.5l0.07-0.15q0.36-0.8 0.69-1.62v-0.08q0.33-0.84 0.63-1.7 0.3-0.87 0.56-1.75v-0.06q0.25-0.87 0.47-1.75v-0.13c0.13-0.56 0.26-1.13 0.37-1.7v-0.26c0.1-0.53 0.19-1.06 0.27-1.59 0-0.16 0-0.32 0.06-0.49 0.06-0.46 0.12-0.93 0.17-1.4 0-0.29 0-0.58 0.07-0.87s0.06-0.7 0.08-1.06V122A113.63 113.63 0 0 0 819.64 8.52H217.45A113.63 113.63 0 0 0 104 122v780a113.63 113.63 0 0 0 113.5 113.5h407.34a39.8 39.8 0 0 0 19.41-5h0.06q0.82-0.46 1.61-0.95l0.32-0.21c0.44-0.28 0.87-0.56 1.3-0.86l0.5-0.35c0.36-0.26 0.72-0.52 1.07-0.79l0.6-0.47 0.92-0.75 0.65-0.56 0.83-0.75 0.64-0.61 0.78-0.78 0.35-0.35 268.3-283 0.28-0.31 0.65-0.72zM217.45 935.48A33.54 33.54 0 0 1 184 902V122a33.54 33.54 0 0 1 33.5-33.5h602.14a33.54 33.54 0 0 1 33.5 33.5v530.47h-154.8A113.63 113.63 0 0 0 584.84 766v169.48z m582.65-203L664.84 875.14V766a33.54 33.54 0 0 1 33.5-33.5z"
                            fill="#979797"
                            p-id="5217"
                          ></path>
                          <path
                            id="svgcolor"
                            d="M315 286.51h358.53a40 40 0 0 0 0-80H315a40 40 0 0 0 0 80zM713.53 416.84a40 40 0 0 0-40-40H315a40 40 0 0 0 0 80h358.53a40 40 0 0 0 40-40zM523.53 547.17H315a40 40 0 0 0 0 80h208.53a40 40 0 0 0 0-80zM538.45 874.41H344a15 15 0 0 0 0 30h194.45a15 15 0 0 0 0-30z"
                            fill="#979797"
                            p-id="5218"
                          ></path>
                          <path d="M288.51 889.41m-15 0a15 15 0 1 0 30 0 15 15 0 1 0-30 0Z" fill="#979797" p-id="5219"></path>
                        </svg>
                        <span>数据管理</span>
                      </span>
                    }
                  >
                    <Menu.Item key="section">
                      <Link to={"/audit/datas/section"}>Section</Link>
                    </Menu.Item>
                    <Menu.Item className="sonMenu" key="geochemical">
                      <Link to={"/audit/datas/geochemical"}>Geochemical</Link>
                    </Menu.Item>
                    <Menu.Item className="sonMenu" key="stratigraphy">
                      <Link to={"/audit/datas/stratigraphy"}>Stratigraphy</Link>
                    </Menu.Item>
                    <Menu.Item className="sonMenu" key="unit">
                      <Link to={"/audit/datas/unit"}>Unit</Link>
                    </Menu.Item>
                    <Menu.Item key="collection">
                      <Link to={"/audit/datas/collection"}>Collection</Link>
                    </Menu.Item>
                    <Menu.Item className="sonMenu" key="fossilList">
                      <Link to={"/audit/datas/fossilList"}>FossilList</Link>
                    </Menu.Item>
                    <Menu.Item key="taxonomy">
                      <Link to={"/audit/datas/taxonomy"}>Taxonomy</Link>
                    </Menu.Item>
                    {/* <Menu.Item key="oceanic"><Link to={'/admin/datas/oceanic'}>Oceanic data</Link></Menu.Item> */}
                  </SubMenu>
                ) : null}
              </Menu>
            </div>

            <div className="rightContent">
              <div className="content">{this.props.children}</div>
            </div>
          </div>
        </div>
      </ConfigProvider>
    );
  }
}
