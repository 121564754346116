import React, { Component } from "react";
import groupApis from "src/js/apis/group";
import Storage from "src/js/storage";
import { Modal, Form, Input, Button, Select } from "antd";
import "./index.css";
import "src/js/global.js";
let allNumber = 6;
const { confirm } = Modal;

const { Option } = Select;

class GroupCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myGroups: [],
      newGroupShow: false,
      groupNames: {},
    };
  }
  isGbdbUser = () => {
    let token = Storage.getItem("token");

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page",
        });
        this.props.history.push("/home");
      }
    }
  };
  async componentDidMount() {
    this.isGbdbUser();
    let uid = Storage.getItem("gbdbid");
    if (uid) {
      let params = {
        uid,
      };
      let myGroups = await groupApis.getGroups(params);
      if (myGroups.error) {
        Modal.error({
          title: "This is an error message",
          content: myGroups.error,
        });
      } else {
        this.setState({
          myGroups: myGroups.result,
        });
      }
    }
  }
  confirmAddGroup = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let params = {
          name: values.name,
          uid: Storage.getItem("gbdbid"),
          isShare: values.isShare,
        };
        let newGroup = await groupApis.createGroup(params);
        if (newGroup.error) {
          Modal.error({
            title: "This is an error message",
            content: newGroup.error,
          });
        } else {
          let { myGroups } = this.state;
          const modal = Modal.success({
            title: "This is an successful message",
            content: "Successfully adding",
          });

          setTimeout(() => {
            modal.destroy();
          }, 5000);
          this.props.form.resetFields();
          this.setState({
            newGroupShow: false,
          });
          this.setState(
            {
              myGroups: [newGroup.result, ...myGroups],
            },
            () => {}
          );
        }
      }
    });
  };
  deleteGroup = (item, index, e) => {
    let that = this;
    confirm({
      title: "Are you sure delete this group?",
      content: "All relevant content will be deleted",
      async onOk() {
        let params = {
          id: item.id,
        };
        let groups = await groupApis.deleteGroup(params);
        if (groups.error) {
          Modal.error({
            title: "This is an error message",
            content: groups.error,
          });
        } else {
          let { myGroups, groupNames } = that.state;
          Modal.success({
            title: "This is an successful message",
            content: "Successfully delete",
          });

          myGroups.splice(index, 1);
          delete groupNames[item.name];
          that.setState({
            myGroups,
          });
        }
      },
      onCancel() {},
    });
  };
  addNewGroup = () => {
    this.setState({
      newGroupShow: true,
    });
  };
  handelNewGroup = () => {
    this.setState({
      newGroupShow: false,
    });
  };
  cancelNewGroup = () => {
    this.setState({
      newGroupShow: false,
    });
  };

  render() {
    let { myGroups, newGroupShow, groupNames } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="group-create">
        <div className="group-create-content">
          <div className="group-create-title">
            My group list (<span>{myGroups.length}</span> /{allNumber} )
          </div>
          <div className="group-create-list">
            {myGroups.length > 0
              ? myGroups.map((item, index) => {
                  groupNames[item.name] = item.name;
                  return (
                    <div className="group-create-btn" key={item.name}>
                      <span className="group-create-add ant-form-item-required" title={`${item.name}(${item.isShare == 1 ? "public" : "private"})`}>
                        {item.name}
                      </span>
                      <span className="group-delete-btn iconfont icon-Recyclebintrashgarbage" onClick={(e) => this.deleteGroup(item, index, e)}></span>
                    </div>
                  );
                })
              : null}
          </div>

          <div className="group-create-btn">
            <Button className="group-create-add" onClick={this.addNewGroup} disabled={myGroups.length >= allNumber ? true : false}>
              <span className="iconfont icon-plus1"></span>
            </Button>

            {myGroups.length >= allNumber ? <p className="total-tips">You can add up to three</p> : null}
          </div>
        </div>

        <Modal className="group-add-modal" width="50%" visible={newGroupShow} onOk={this.handelNewGroup} onCancel={this.cancelNewGroup} footer={null} header={null}>
          <div className="addgroup-content">
            <div className="addgroup-form-title">
              <p>Add group</p>
            </div>
            <Form layout="vertical" className="addgroup-form">
              <Form.Item label="Group">
                {getFieldDecorator("name", {
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        try {
                          if (!value) {
                            callback("Please input your Group!");
                          } else if (groupNames.hasOwnProperty(value)) {
                            callback("Group repetition");
                          } else {
                            callback();
                          }
                        } catch (err) {
                          callback(err);
                        }
                      },
                      required: true,
                    },
                  ],
                })(<Input placeholder="group name" />)}
              </Form.Item>
              <Form.Item label="Whether the private">
                {getFieldDecorator("isShare", {
                  rules: [
                    {
                      required: true,
                    },
                  ],
                  initialValue: "0",
                })(
                  <Select placeholder="Whether the public">
                    <Option key="0">Private</Option>
                    <Option key="1">Public</Option>
                  </Select>
                )}
              </Form.Item>
            </Form>

            <div className="addgroup-form-operate">
              <Button type="gbdb" onClick={this.confirmAddGroup} disabled={myGroups.length >= allNumber ? true : false}>
                Confirm
              </Button>
              {myGroups.length >= allNumber ? <p className="total-tips">You can add up to three</p> : null}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

GroupCreate = Form.create({})(GroupCreate);
export default GroupCreate;
