import React from 'react'
import {Form,Button,Modal,message,Input} from 'antd'
import axios from 'axios'
import moment from 'moment'

class AntdForm extends React.Component{
    constructor(props){
        super(props)
        this.state={
           
        }
    }
    componentDidMount(){
        axios.get()
    }
    handlePass(){
        Modal.confirm({
            content:`点击“确定”后，本条数据将通过审核!`,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                //请求通过
                var sendData={
                    assetstype:9,
                    id:this.props.modalData.id,
                    authState:1,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                }
              

                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已通过!')
                        this.props.cancelShowInfo();
                        this.props.refreshList()
                    }
                })
              
            }
        })
        //通过
       
    }
    handleReject(){
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                var sendData={
                    id:this.props.modalData.id,
                    assetstype:9,
                    authState:2,
                    auth_time:moment().format('YYYY/MM/DD HH:mm:ss'),
                    auth_comment:str
                }
                axios.post('/api/admin/review/assets',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已驳回！')
                        this.props.cancelShowInfo()
                        this.props.refreshList()
                    }
                })
               
            },
          
        })
        //拒绝
    }
   
    render(){
        const {modalData}=this.props
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 },
            },
          };

        return (
            <div>
                <div>
                    <div className='modalTitle'>Taxonomy revised</div>
                    <div className='form'>
                    <Form {...formItemLayout}>
                           
                           <Form.Item label='Fossil group'>
                               <span>{modalData.fossil_group}</span>
                           </Form.Item>

                           <Form.Item label='Taxon type'>
                                   <span>{modalData.taxon_type}</span>
                           </Form.Item>
                       
                           <Form.Item label='Taxon name1'>
                               <span>{modalData.taxon_name1}</span>
                           </Form.Item>
                           <Form.Item label='Taxon name2'>
                               <span>{modalData.taxon_name2}</span>
                           </Form.Item>

                           <Form.Item label='Nomenclator'>
                               <span>{modalData.nomenclator}</span>
                           </Form.Item>

                           <Form.Item label='Year'>
                               <span>{modalData.year}</span>
                           </Form.Item>

                           <Form.Item label='Collect form Treatise'>
                               <span>{modalData.from_treatise}</span>
                           </Form.Item>

                           <Form.Item label='Reference'>
                               <span>{modalData.ref_id}</span>
                           </Form.Item>

                           <Form.Item label='Notes'>
                               <span>{modalData.invalid_notes}</span>
                           </Form.Item>

                       </Form>

                        <div className='foot'>
                            <Button  type='agbdb1' onClick={()=>{this.handlePass()}}>通过</Button>
                            <Button  type='agbdb2' onClick={()=>{this.handleReject()}}>拒绝</Button>
                        </div>
                    </div>
                </div>
        </div>
        )
      
    }
}
const Revised = Form.create()(AntdForm);
export default Revised