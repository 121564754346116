/* eslint-disable  */
import React, { Component } from "react";
import userApis from "src/js/apis/user";
import Storage from "src/js/storage";
import { Button, message, Modal, Icon } from "antd";
import "./index.css";





class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      token: "",
      tokenSuccess: false
    };
  }
  async componentDidMount() {
    
    let { tokenSuccess } = this.state;
    let search = this.props.location.search;

    if (search) {
      let use = search.split("?")[1];

      // let username = use.split("&")[0].split("=")[1];
      let token = use.split("=")[1];

      this.setState(
        {
          // username,
          token
        },
        async () => {
          let { token } = this.state;
          let params = {
            token,
            loginby: 1
          };
          let verifyBack = await userApis.verifyToken(params);
          if (verifyBack.error) {
            Modal.error({
              title: "verifyToken   Result",
              content: verifyBack.error
            });
          } else {
            this.setState({
              tokenSuccess: true
            });
          }
        }
      );
    }
  }

  confirmEmail = () => {
  };
  goLogin = async () => {
    let search = this.props.location.search;

    let use = search.split("?")[1];
    let token = use.split("=")[1];
    let params = {
      token,
      loginby: 1
    };

    let initRefreshBack = await  userApis.initUser(params);
    
    if (initRefreshBack.error) {
      Modal.error({
        title: "Wrong Information",
        content: initRefreshBack.error
      });
      //  this.props.history.push("/home");
    } else if (initRefreshBack.result) {
      let token = initRefreshBack.token;

      Storage.setItem({
        name: "token",
        value: token,
        expires: 60 * 60 * 24
      });
      this.props.history.push("/home");
    }
  };
  goRevalidate = async () => {
    let search = this.props.location.search;
    let use = search.split("?")[1];
    let token = use.split("=")[1];
    let params = {
      token,
      loginby: 1
    };
    let verifyEmailBack = await userApis.verifyEmail(params);
    if (verifyEmailBack.result) {
      const modal = Modal.success({
        title: "Send Message  Result",
        content: verifyEmailBack.result,
        okText: "Ok",
        icon: <Icon type="smile" theme="twoTone" twoToneColor="#52c41a"></Icon>
      });

      setTimeout(() => {
        modal.destroy();
      }, 2000);
    } else {
      if (verifyEmailBack.state === "404") {
        Modal.error({
          title: "This is an error message",
          content: "Email error"
        });
      } else if (verifyEmailBack.error) {
        Modal.error({
          title: "Send Message  Result",
          content: "Please check the network and try again,"
        });
      }

      setTimeout(() => {
        modal.destroy();
      }, 2000);
    }
  };
  render() {
    return (
      <div>
        <div className="confirm-page">
          <div className="confirm-container">
            {this.state.tokenSuccess === true ? (
              <div className="confirm-content">
                <div className="confirm-header">
                  <img src={require("src/assets/images/register/header.png")} />
                </div>
                <div className="confirm-result-content">
                  <p className="confirm-content-title">Congratulations!</p>
                  <p className="confirm-content-detail">
                  Congratulations on your successful activation,Do you want to
                    enter gbdb?
                  </p>
                </div>

                <div className="confirm-results">
                  <Button
                    size="large"
                    type="gbdb"
                    className="confirm-email"
                    onClick={e => this.goLogin(e)}
                  >
                    Go to login
                  </Button>
                </div>
              </div>
            ) : (
              <div className="confirm-content">
                <div className="confirm-header">
                  <img src={require("src/assets/images/register/header.png")} />
                </div>
                <div className="confirm-result-content">
                  <p className="confirm-content-title">Sorry</p>
                  <p className="confirm-content-detail">
                    Do you want to revalidate?
                  </p>
                </div>
                <div className="confirm-results">
                  <Button
                    size="large"
                    type="gbdb"
                    className="confirm-email"
                    onClick={e => this.goRevalidate(e)}
                  >
                    Go to revalidate
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Confirm;
