/* eslint-disable  */

import axios from 'axios'
// const qs = require('qs')
const downloadAxios = {
  async get (url, data) {
    
    try {
      
      let res = await axios.get(url, {params: data})
      return new Promise((resolve) => {
        if (res.result) {
          resolve(res)
        } else {
          resolve(res)
        }
      })
    } catch (err) {
       
      // alert('服务器出错')
    
    }
  },
    async delete (url, data) {
    
    try {
      
      let res = await axios.delete(url, {params: data})
      return new Promise((resolve) => {
        if (res.result) {
          resolve(res)
        } else {
          resolve(res)
        }
      })
    } catch (err) {
       
      // alert('服务器出错')
    
    }
  },
  async post (url, data) {
    try {
      let res = await axios.post(url, data)
      return new Promise((resolve, reject) => {
        if (res.result) {
          resolve(res)
        } else {
          resolve(res)
        }
      })
    } catch (err) {
       alert(err)
      return 
      // alert('服务器出错')
  
    }
  },
}
export default downloadAxios

