import React, { Component } from "react";
import { Modal, Button, } from "antd";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";
import SectionAllTableResult from "commons/AllTableList/Section/SectionAll/index.js";

import SectionOption from "commons/AllOptions/Section/index.js";
import Storage from "src/js/storage";
import { NavLink } from "react-router-dom";
import SubsetApis from "src/js/apis/subset";
import Apis from "src/js/apis/api";
import Utils from "src/js/utils";
import dataApis from "src/js/apis/data";
import "./index.css";
import "src/js/global.js";
const nomenclaturesOptions = [
  "question mark (?)",
  'quotes ("")',
  "cf.",
  "aff.",
  "ex gr.",
  "sp.",
  "ssp.",
  "subsp.",
  "sp. nov.",
  // "sp. 1, sp. A, sp. i, etc.",
  "spp."
];



class GeographySearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResultVisible: false,

      searchAllValues: {},
      detailData: {},
      showDetailForm: false,
      taxType: "Original",
      isCheckAll: false,
      checkedObj: {},
      resultLists: [],
      defaultCheckedList: [],
      // errorsection:false,
      //  errorage:false,
      //  errorref:false,
      sectionType: "baseinfo",
      allChildError: {},
      conopUrl: "",
      fossilgroup: {},
      showBackIcon: false,
      selectDuration: { erathem_max: [], period_max: [], epoch_max: [], early_interval: [] },



      checkedList: [],
      currentFossilGroups: [],
      currentFossilGroupType: "",
      currentNomenclature: "",
      checkNomenclaturesAll: false,
    };
  }

  componentDidMount() {

    let host = window.location.host;

    // let host2 = document.domain;
    let historyLength = this.props.history.length
    if (historyLength > 1) {
      this.setState({
        showBackIcon: true
      })
    }
    if (this.props.location.search) {
      let inheritRefIds =
        Utils.getSearch(this.props.location.search, "refId") || "";
      let searchParams = {
        ref_id: inheritRefIds
      };
      let searchAllValues = {
        ref_id_from: Number(inheritRefIds),
        ref_id_to: Number(inheritRefIds)
      };
      this.setState(
        {
          searchParams,
          searchAllValues
        },
        () => {
          let { searchParams, searchAllValues, sectionType } = this.state;

          setTimeout(() => {
            this.child.searchSectionDatas(
              searchParams,
              searchAllValues,
              sectionType
            );
          }, 20);
        }
      );
    }
    if (this.props.location.query) {
      const {
        searchParams,
        searchAllValues,
        sectionType,
        selectDuration
      } = this.props.location.query;
      
      this.setState(
        {
          searchParams,
          searchAllValues,
          sectionType,
          selectDuration
        },
        () => {
          let { searchParams, searchAllValues, sectionType } = this.state;
          setTimeout(() => {
            this.child.searchSectionDatas(
              searchParams,
              searchAllValues,
              sectionType
            );
          }, 20);
        }
      );
      if(!sectionType){
        this.setState({
          sectionType: "baseinfo",
        })
      }
    }
  }

  detailFormOk = () => {
    this.setState({
      showDetailForm: false,
      sectionType: "baseinfo",
    });
  };
  detailFormCancel = () => {
    this.setState({
      showDetailForm: false,
      sectionType: "baseinfo",
    });
  };

  handFormOk = () => {
    this.setState({
      ResultVisible: false,
      sectionType: "baseinfo",
    });
  };
  handleFormCancel = () => {
    this.setState({
      ResultVisible: false,
      sectionType: "baseinfo",
    });

  };

  checkAgain = () => {
    // let { allChildError } = this.state;
    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs.section.validateFieldsAndScroll(async (err, searchValues) => {
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);
        let { selectDuration } = this.state
        if (selectDuration.erathem_max.length > 0) {
          searchParams.erathem_max = selectDuration.erathem_max
        }
        if (selectDuration.period_max.length > 0) {
          searchParams.period_max = selectDuration.period_max
        }
        if (selectDuration.epoch_max.length > 0) {
          searchParams.epoch_max = selectDuration.epoch_max
        }
        if (selectDuration.early_interval.length > 0) {
          searchParams.early_interval = selectDuration.early_interval
        }

        this.setState(
          {
            ResultVisible: false,
            searchParams,
            searchValues
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchAllValues, sectionType } = this.state;
              this.child.searchSectionDatas(
                searchParams,
                searchAllValues,
                sectionType
              );
            }, 20);
          }
        );
      }
    });
  };
  showOptions = () => {
    this.setState({
      ResultVisible: true,
      sectionType: "baseinfo",
    }, () => {
      setTimeout(() => {
        if (this.sectionOptionChild.changeDurationParent) {
          this.sectionOptionChild.changeDurationParent(this.state.selectDuration)
        }
      }, 20);

    });
  };

  getDetail = (itemValue, index) => {
    this.setState({
      detailData: itemValue,
      showDetailForm: true,
      sectionType: "baseinfo",
      detailIndex: index
    });

    //点击 detail 按扭执行代码
  };
  modifyBase = modifyValue => {
    // this.setState({
    //   detailData: itemValue,
    //   showDetailForm: true,
    //   sectionType:'baseinfo',
    //   detailIndex:index
    // });
  };
  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showDetailForm: true,
      sectionType
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showDetailForm: true,
      sectionType: "baseinfo"
    });
  };
  viewGeochemical = () => {
    let url = "/searchs/result/section/geochemical";
    let queryParam = {};
    this.props.history.push({ pathname: url, query: queryParam });
  };
  changeCheckSelected = checkedObj => {
    this.setState(
      {
        checkedObj
      },
      () => { }
    );
  };
  onGeography = ref => {
    this.child = ref;
  };
  addSubset = async () => {
    let { checkedObj } = this.state;

    let uid = Storage.getItem("gbdbid");

    let params = {
      uid,
      assetstype: 2,

      ids: Object.keys(checkedObj)
    };
    let collectBack = await SubsetApis.collectAssets(params);
    if (collectBack.result) {
      Modal.success({
        title: "This is an successful message",
        content: "successfully added "
      });
    } else {
      Modal.error({
        title: "This is an error message",
        content: collectBack.error
      });
    }
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  changeDownLoadCondition = value => {

    this.setState({
      LoadConditionType: value,



      checkedList: [],
      currentFossilGroups: [],
      currentFossilGroupType: "",
      currentNomenclature: "",
      checkNomenclaturesAll: false,
    });
  };
  downloadFile = file => {
    let href = "/api" + file;
    let idx = file.lastIndexOf("/");

    let name = file.substring(idx + 1, file.length);
    var a = document.createElement("a");
    a.setAttribute("href", href);
    a.setAttribute("download", name);
    a.setAttribute("id", "downloadFileA");
    a.setAttribute("name", "name");
    // 防止反复添加
    if (document.getElementById("downloadFileA")) {
      document.body.removeChild(document.getElementById("downloadFileA"));
    }
    document.body.appendChild(a);

    a.click();
  };
  downLodaConop = () => {
    this.setState({
      downloadModal: true
    });
  };
  godownloadSection = async () => {
    let {
      currentFossilGroupType,
      currentFossilGroups,
      LoadConditionType,
      currentNomenclature,
      checkedList
    } = this.state;
    if (LoadConditionType === "fossilType") {
      let params = {};
      let allParams = {};
      if (currentFossilGroupType === "all") {
        params = {
          allfossilGroups: 1
        };
      } else if (currentFossilGroupType === "only") {
        params = {
          allfossilGroups: 0,
          include_fossilGroups: currentFossilGroups
        };
      } else if (currentFossilGroupType === "ignore") {
        params = {
          allfossilGroups: 0,
          ignore_fossilGroups: currentFossilGroups
        };
      }
      let { checkedObj } = this.state;
      let section_ids = Object.keys(checkedObj);

      let sectionparams = {
        section_ids
      };
      Object.assign(allParams, params, sectionparams);
      let downBack = await Apis.downConop(allParams);
      if (downBack.result) {
        if("error"==downBack.result){
          Modal.error({
            content: downBack.error
          });
        }else{
          this.setState(
            {
              conopUrl: downBack.result
            },
            () => {
              this.downloadFile(downBack.result);
            }
          );
        }
        // this.setState(
        //   {
        //     conopUrl: downBack.result
        //   },
        //   () => {
        //     this.downloadFile(downBack.result);
        //   }
        // );
      } else {
        Modal.error({
          content: "download failed"
        });
      }
    } else {
      let params = {};
      let allParams = {};
      if (currentNomenclature === "allnome") {
        params = {
          allnomenclatures: 1
        };
      } else if (currentNomenclature === "ignorenome") {
        params = {
          allnomenclatures: 0,
          ignore_nomenclatures: checkedList
        };
      }
      let { checkedObj } = this.state;
      let section_ids = Object.keys(checkedObj);

      let sectionparams = {
        section_ids
      };
      Object.assign(allParams, params, sectionparams);
      let downBack = await Apis.downConop(allParams);
      if (downBack.result) {
        if("error"==downBack.result){
          Modal.error({
            content: downBack.error
          });
        }else{
          this.setState(
            {
              conopUrl: downBack.result
            },
            () => {
              this.downloadFile(downBack.result);
            }
          );
        }
      } else {
        Modal.error({
          content: "download failed"
        });
      }
    }
  };
  downloadModalOk = () => {
    this.setState({
      downloadModal: false
    });
  };
  downloadModalCancel = () => {
    this.setState({
      downloadModal: false,

      checkedList: [],
      currentFossilGroups: [],
      currentFossilGroupType: "",
      currentNomenclature: "",
      checkNomenclaturesAll: false,
      LoadConditionType: "fossilType",
    });
  };
  changeJournalType = value => {
    this.setState({
      currentJournalType: value
    });
  };
  changetFossilGroupType = e => {
    this.setState(
      {
        currentFossilGroupType: e.target.value,
        currentFossilGroups: [],
        allfossilGroups: this.state.currentFossilGroupType === "all" ? 1 : 0
      },
      async () => {
        let { currentFossilGroupType } = this.state;
        if (
          currentFossilGroupType === "only" ||
          currentFossilGroupType === "ignore"
        ) {
          let fossilgroup = await dataApis.fossilgroup();
          this.setState({
            fossilgroup:
              fossilgroup && fossilgroup.result ? fossilgroup.result : {}
          });
        }
      }
    );
  };
  ChangeSectionFossilGroup = value => {
    this.setState({
      currentFossilGroups: value
    });
  };

  changetNomenclatureType = e => {
    this.setState(
      {
        currentNomenclature: e.target.value
      },
      () => {
        if (this.state.currentNomenclature === "allnome") {
          this.setState({
            checkedList: [],
            checkNomenclaturesAll: false,


          });
        }
      }
    );
  };
  onCheckAllNomenclatures = e => {
    this.setState(
      {
        checkedList: e.target.checked ? nomenclaturesOptions : [],
        currentNomenclature: "ignorenome",
        checkNomenclaturesAll: e.target.checked
      },
      () => { }
    );
  };
  onNomenclatureChange = checkedList => {
    this.setState({
      checkedList,
      checkNomenclaturesAll: checkedList.length === nomenclaturesOptions.length,
      currentNomenclature: "ignorenome"
    });
  };
  goHistoryBack = () => {
    this.props.history.push("/search/section");
    // this.props.history.goBack();
  };
  sectionOptionChild = ref => {
    this.sectionOptionChild = ref;
  };
  changeDuration = selectDuration => {

    this.setState({
      selectDuration
    }, () => {

    });
  };
  render() {
    let {
      searchParams,
      searchAllValues,
      searchValues,
      sectionType
    } = this.state;


    return (
      // <div id="SearchResult">
      //   <div className="SearchResult-main">
      <div id="SearchResult">
        <div className="SearchResult-main reference-search-form">
          <div className="head">
            {/* {
              this.state.showBackIcon ?
                <span
                  className="iconfont icon-back"
                  onClick={this.goHistoryBack}
                ></span>
                :
                null
            } */}
            <span className="head-title">Section Search Result</span>
          </div>

          <div className="option-btn">
            <Button type="gbdb">
              <div
                className="result-search-option"
                onClick={() => this.showOptions()}
              >
                Search option
              </div>
              <div id="searchModel">
                <Modal
                  className="searchResultModal newSectionModal"
                  width="76%"
                  visible={this.state.ResultVisible}
                  footer=""
                  // closable={false}
                  onOk={this.handFormOk}
                  onCancel={this.handleFormCancel}
                >
                  <SectionOption
                    searchAllValues={searchAllValues}
                    ref="section"
                    showGeography={true}
                    showUnit={true}
                    showBase={true}
                    showFomation={false}
                    childError={this.childError}
                    showAlloption={true}
                    changeDuration={this.changeDuration}
                    sectionOptionChild={this.sectionOptionChild}
                  ></SectionOption>

                  {
                    <div className="referenceSearch-btns">
                      <div>
                        <Button
                          type="gbdb"
                          onClick={() => {
                            this.checkAgain();
                          }}
                        >
                          {<p> Search</p>}
                        </Button>
                      </div>
                    </div>
                  }
                </Modal>
              </div>
            </Button>
          </div>
          <SectionAllTableResult
            searchParams={searchParams}
            searchValues={searchValues}
            onGeography={this.onGeography}
            changeCheckSelected={this.changeCheckSelected}
            getDetail={this.getDetail}
            modifyBase={this.modifyBase}
            showDeleteSubset={false}
            showAddSubset={true}
            showExportFile={true}
          ></SectionAllTableResult>

        </div>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailFormOk}
          onCancel={this.detailFormCancel}
        >
          {sectionType === "baseinfo" ? (
            <BaseDetailForm
              detailData={this.state.detailData}
              viewGeography={this.viewGeography}
              seeGeographyFossil={true}
            ></BaseDetailForm>
          ) : (
              <GeographyDetailForm
                detailData={this.state.detailData}
                viewBase={this.viewBase}
                seeGeographyFossil={true}
              ></GeographyDetailForm>
            )}
        </Modal>

      </div>
    );
  }
}

export default GeographySearchResult;
