import asyncAxios from "src/js/asyncAxios";

export default {
  // reference
  // search
  createGroup: async params => {
    let url = "/api/user/group/add";

    let groupsCreate = await asyncAxios.post(url, params);

    return groupsCreate;
  },
  updateGroups: async params => {
    let url = "/api/user/group/change";

    let groupsApdate = await asyncAxios.post(url, params);

    return groupsApdate;
  },
  deleteGroup: async params => {
    let url = "/api/user/group";

    let groupsDelete = await asyncAxios.delete(url, params);

    return groupsDelete;
  },
  getGroups: async params => {
    let url = "/api/user/groups";

    let groupsGet = await asyncAxios.get(url, params);

    return groupsGet;
  },
  getPersonInGroup: async params => {
    let url = "/api/user/group/persons";

    let groupsPersons = await asyncAxios.get(url, params);

    return groupsPersons;
  },
  //申请
  applyAddGroup: async params => {
    let url = "/api/user/person/apply";

    let groupsAdd = await asyncAxios.post(url, params);

    return groupsAdd;
  },
  //同意
  permitAddGroup: async params => {
    let url = "/api/user/person/permit";

    let groupsPermit = await asyncAxios.post(url, params);

    return groupsPermit;
  },
  //驳回
  rejectApply: async params => {
    let url = "/api/user/person/reject";

    let groupsReject = await asyncAxios.post(url, params);

    return groupsReject;
  },
  //退出
  exitGroups: async params => {
    let url = "/api/user/person/exit";

    let groupsQuit = await asyncAxios.get(url, params);

    return groupsQuit;
  },

  //删除

  deletePerson: async params => {
    let url = "/api/user/person";

    let personDelete = await asyncAxios.delete(url, params);

    return personDelete;
  },
  getPersonsGroups: async params => {
    let url = "/api/user/group/persons";

    let PersonsGroups = await asyncAxios.get(url, params);

    return PersonsGroups;
  },
};
