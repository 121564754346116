import React from 'react'
import {Form,Button,message,Modal,Input} from 'antd'
import axios from 'axios'
import moment from 'moment'

export default class BasicForm extends React.Component{
    constructor(props){
        super(props)
    }
    handleSubmit(){
        this.props.changeStatus()
    }

    handleReject(){
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                var str=document.querySelector('.minput').value
                var sendData={
                    section_basic_id:this.props.basicInfo.section_id,
                    authState:2,
                    auth_time:moment().format('YYYY/MM/DD'),
                    auth_comment:str
                }
               
                axios.post('/api/admin/review/section',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('已驳回！')
                        this.props.cancelShowInfo()
                    }
                })
               
            },
          
        })
    }
    accessibility(n){
        if(n===0) return 'Open'
        if(n===1) return 'Private'
        if(n===2) return 'Group'
    }
    time(t){
        var arr=t.split('T');
        t=arr[0];
        return t
    }
    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 5 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 19 },
            },
          };
          const {basicInfo}=this.props
      
        return (
            <div>
            <Form {...formItemLayout}>
                <Form.Item label='Section name'>
                   <span>{basicInfo.section_name}</span>
                </Form.Item>

                <Form.Item label='Notes'>
                    <span>{basicInfo.section_notes}</span>
                </Form.Item>

                <Form.Item label='Reference ID'>
                    <span>{basicInfo.ref_id}</span>
                </Form.Item>
               
                <Form.Item label='Project'>
                    <span>{basicInfo.section_project}</span>
                </Form.Item>

                <Form.Item label='Accessibility'>
                    <span>{this.accessibility(basicInfo.accessibility)}</span>
                </Form.Item>

                <Form.Item label='Release date'>
                    <span>{this.time(basicInfo.release_date)}</span>
                </Form.Item>

            </Form>
            <div className='foot'>
                    <Button  type='agbdb1' onClick={this.handleSubmit.bind(this)}>继续</Button>
                    <Button type='agbdb2' onClick={this.handleReject.bind(this)}>拒绝</Button>
                </div>
            </div>
        )
    }
}