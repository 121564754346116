import React, { Component } from "react";
import userApis from "src/js/apis/user";
import { Input, Modal, Form, Button, Icon, Radio } from "antd";
import axios from "axios";
import Storage from "src/js/storage";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (this.props.onLogin) {
      this.props.onLogin(this);
    }
  }
  goRevalidate = async token => {
    let params = {
      token,
      loginby: 1
    };
    let verifyEmailBack = await userApis.verifyEmail(params);
    if (verifyEmailBack.result) {
      const modal = Modal.success({
        title: "Send Message  Result",
        content: verifyEmailBack.result,
        okText: "Ok",
        icon: <Icon type="smile" theme="twoTone" twoToneColor="#52c41a"></Icon>
      });

      setTimeout(() => {
        modal.destroy();
      }, 2000);
    } else {
      if (verifyEmailBack.state === "404") {
        Modal.error({
          title: "This is an error message",
          content: "Email error"
        });
      } else if (verifyEmailBack.error) {
        Modal.error({
          title: "Send Message  Result",
          content: "Please check the network and try again,"
        });
      }
    }
  };
  loginSubmit = async () => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let that = this;
        let values = this.props.form.getFieldsValue(["password", "email","admin_login"]);
        
        let { password, email, admin_login } = values;
        let params = {
          password,
          email,
          loginby: 1
        };
        let loginBack;
        if(admin_login =="true"){
          loginBack = await userApis.adminLogin(params);
        }else{
          loginBack = await userApis.login(params);
        }
        
        if (loginBack.error) {
          if (loginBack.state !== undefined && loginBack.state === 0) {
            Modal.confirm({
              title: loginBack.error,
              content: "Resend the validation message",
              onOk() {
                that.goRevalidate(loginBack.token);
              },
              onCancel() {
                
              }
            });
          } else {
            Modal.error({
              title: "This is an error message",
              content: loginBack.error
            });
          }
        } else {
          let token = loginBack.token;
          axios.defaults.headers.token = token || "";
          Storage.setItem({
            name: "token",
            value: token
          });
          Storage.setItem({
            name: "fossilid",
            value: loginBack.result.id
          });
          this.props.fossilSuccess(loginBack);
        }
      }
    });
  };
  goLogin = e => {
    if (e.keyCode === 13) {
      this.loginSubmit();
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="">
        <Form layout="vertical">
          <Form.Item label="Email：">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "The input is not valid E-mail!"
                },
                {
                  required: true,
                  message: "Please input your E-mail!"
                }
              ],
              initialValue: ""
            })(<Input onKeyDown={this.goLogin} placeholder="" />)}
          </Form.Item>
          <Form.Item
            label={
              <span className="clearfix login-password-label">
                <span className="">Password:</span>
                {/* <span className="fl">Password:</span> */}
                {/* <span className="fr login-forget-psd">Lost password</span> */}
              </span>
            }
          >
            {getFieldDecorator("password", {
              initialValue: "",
              rules: [
                {
                  required: true,
                  message: "Please input your password!"
                },
                {
                  validator: this.compareToFirstPassword
                }
              ]
            })(<Input.Password onKeyDown={this.goLogin} />)}
          </Form.Item>

          <Form.Item label="Admin Login:">
            <span>                           
              {getFieldDecorator("admin_login", {
                initialValue: "false"
              })(
                <Radio.Group>
                  <Radio value="true">Yes </Radio>
                  <Radio value="false">No </Radio>
                </Radio.Group>
              )}
            </span>
          </Form.Item>

          {/* <FormItem label="Admin Login">
            <div className="">
              <span style={{width:'100%',display:'grid'}}>                           
                {getFieldDecorator("admin_login", {
                  initialValue: ""
                })(
                    <Checkbox.Group style={{width:'100%'}}>
                      
                      <Checkbox value="marine">marine</Checkbox>
                      
                    </Checkbox.Group>
                )}
              </span>
            </div>
          </FormItem> */}
        </Form>
        <div>
          <p className="passwod-tip">
            If you are the old member, please send your login credentials and
            new email address to the administrator  (fossilontology@163.com)
          </p>
        </div>
        <div className="user-login-opt" onClick={this.loginSubmit}>
          <Button type="gbdb">Sign in</Button>
        </div>
      </div>
    );
  }
}

Login = Form.create({})(Login);
export default Login;
