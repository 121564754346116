import React, { Component } from 'react';
import {Form,Input,DatePicker, Button,Pagination, message, Modal } from 'antd'
import './index.scss'
import moment from 'moment'
import axios from 'axios';

const {RangePicker}=DatePicker

 class AntdForm extends Component {
    constructor(props){
        super(props);
        this.state={
            tableData:[],
            nowPage:1,
            defaultPageSize:8,
            total:0,
            isResponse:false,
        }
    }
    handleSubmit(page=1){
        this.props.form.validateFields((err, values) => {
            if (!err) {
                
                var sendData={
                    page,
                    pageSize:this.state.defaultPageSize
                }
                if(values.createTime){
                   sendData.dateFrom=moment(values.createTime[0]).format('YYYY/MM/DD');
                   sendData.dateTo=moment(values.createTime[1]).format('YYYY/MM/DD');
                }
       
                axios.get('/api/admin/history/integral',{
                    params:sendData
                }).then(res=>{
                    if(res.data.error===null){
                        this.setState({
                            tableData:res.data.result,
                            total:res.data.total
                        })
                    }
                    if(res.data.result.length===0){
                        // message.warning('没有数据！')
                        this.setState({
                            tableData:[],
                            isResponse:true
                        })
                    }
                   
                }).catch(err=>{
                    message.error('this an error message~')
                })
            }
        })
    }
    changePage(page,pageSize){
        this.setState({
            nowPage:page
        })
        this.handleSubmit(page)
    }
    deleteRecord(id){
        Modal.confirm({
            content:'确定删除吗？',
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                axios.delete(`/api/admin/integral?id=${id}`).then(res=>{
                    if(res.data.error===null){
                        message.success('已删除！')
                        this.handleSubmit(this.state.nowPage)
                    }else{
                        message.error('删除失败：',res.data.error)
                    }
                }).catch(err=>{
                   message.error('this an error message~')
                })
            }
        })
      
    }
    render() {
        let {defaultPageSize,nowPage,total,isResponse} =this.state;
        function showTotal(total) {
            return `每页显示\xa0${defaultPageSize}\xa0条\xa0\xa0\xa0  共 ${total} 条记录`;
          }
        const { getFieldDecorator} = this.props.form;
        const {tableData}=this.state;
        return (
            <div id='ARecord'>
                <div className='title'>积分记录</div>
                <div className="form">
                    <Form layout='vertical'>

                        <Form.Item label='创建时间'>
                            {
                                getFieldDecorator('createTime',{

                                })(<RangePicker></RangePicker>)
                            }
                        </Form.Item>
                        
                        <Form.Item className='foot'>
                           <Button type='primary' onClick={()=>{this.handleSubmit()}}>筛选</Button>
                        </Form.Item>

                    </Form>
                   
                </div>
                {
                    tableData.length>0?(
                        <div className='table'>
                        <table>
                            <tbody>
                            <tr className='head'>
                                <td>序号</td>
                                <td>积分项</td>
                                <td>积分规则</td>
                                <td>创建时间</td>
                                <td>操作</td>
                            </tr>
                            </tbody>
                        
                            {
                                tableData.map((item,index)=>{
                                    return (
                                        <tbody key={index}>
                                            <tr className={`tr ${index%2===0?'color':''}`}>
                                                    <td rowSpan={2}>{defaultPageSize*(nowPage-1)+index+1}</td>
                                                    <td>上传数据一次</td>
                                                    <td>固定积分，上传一次 {item.upload} 分</td>
                                                    <td rowSpan={2}>{moment(item.createdAt).format('YYYY/MM/DD HH:mm:ss')}</td>
                                                    <td rowSpan={2}><Button type='link' onClick={this.deleteRecord.bind(this,item.id)}>删除</Button></td>
                                            </tr>
                                            <tr className={`tr ${index%2===0?'color':''}`}>
                                                    <td>完成审核一次</td>
                                                    <td>固定积分，审核一次 {item.verify} 分</td>
                                            </tr>
                                        </tbody>
                                      
                                    )
    
                                })
                            }
                             
                        </table>
                    </div>
                    ):null
                }
                {
                    tableData.length>0?(
                        <div className='pages'>
                            <Pagination size='small' 
                            current={nowPage}
                            defaultPageSize={defaultPageSize} 
                            total={total} 
                            // showTotal={showTotal.bind(this)}
                            onChange={this.changePage.bind(this)}
                            ></Pagination>
                        </div>
                    ):null
                }
               {tableData.length===0 && isResponse ? <div className='pageTip'>暂无数据</div> :null}

            
            </div>
        
            );
    }
}
const ARecord = Form.create()(AntdForm);
export default ARecord

