import React from "react";

import centerApis from "src/js/apis/center";

import {
  Form,
  Input,
  Select,
  Button,
  Modal,
  AutoComplete,
  InputNumber
} from "antd";

import "./index.css";
import Storage from "src/js/storage";
import Apis from "src/js/apis/api";
import { withRouter } from "react-router-dom";
import Utils from "src/js/utils";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
@withRouter
class GeographyDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      // reference , taxonomy , section,occurence,formation
      detailData: props.detailData || {},
      seeOnly: props.seeOnly === true ? true : false,
      seeGeographyFossil: props.seeGeographyFossil === true ? true : false,

      // 修改相关
      Countrys: [],
      Provinces: [],
      Villages: [],
      Locations: [],
      isCenterUser: props.isCenterUser === true ? true : false,
      refId: ""
    };
  }
  async componentDidMount() {
    let { detailData } = this.state;

    let sectionId = "";
    let refId = "";
    if (detailData && detailData.ref_id !== undefined) {
      refId = detailData.ref_id;
    }

    if (detailData && detailData.section_id !== undefined) {
      sectionId = detailData.section_id;
    }
    if (detailData && detailData.section_basic_id !== undefined) {
      sectionId = detailData.section_basic_id;
    }
    this.setState(
      {
        refId,
        sectionId
      },
      () => {}
    );
    let { isCenterUser } = this.state;

    // let isCenter=(this.props.location.pathname==='/center')
    let sectionResult;
    if (!isCenterUser) {
      let params = {
        section_basic_id: sectionId
      };
      sectionResult = await Apis.getGeology(params);
    } else {
      let params = {
        assetstype: 13,
        enterer: Storage.getItem("gbdbid"),
        section_basic_id: sectionId
      };
      sectionResult = await centerApis.searchAssets(params);
    }

    if (sectionResult.result) {
      if (sectionResult.result.length > 0) {
        this.setState({
          detailData: sectionResult.result[0]
        });
      } else {
        this.setState({
          detailData: {}
        });
      }
    } else {
      Modal.error({
        content: sectionResult.error
      });
    }
  }
  viewBase = () => {
    let { detailData } = this.state;
    let sectionId = detailData.section_basic_id;
    this.props.viewBase(sectionId, "baseinfo");
  };
  supplementaryGeography = () => {
    let { refId, sectionId } = this.state;

    let url = "/section/geography?";
    let refUrl = refId ? "refId=" + refId : "";
    let baseIdUrl = sectionId ? "&baseId=" + sectionId : "";
    let params = `${url}${refUrl}${baseIdUrl}`;
    const w = window.open("about:blank");
    w.location.href = params;
  };
  modifyGeography = () => {
    let { detailData } = this.state;
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        // let addGeography = Utils.filterParams(values);
        let addGeography = Utils.filterCondition(values);
   

          if(addGeography.geology_altitude !== "" && addGeography.geology_altitude !== null && addGeography.geology_altitude !== undefined){
          if(addGeography.geology_altitude_unit==="inches"){
            
            let value=parseFloat(addGeography.geology_altitude)*0.0254
            addGeography.geology_altitude=parseFloat(value.toFixed(4))
            addGeography.geology_altitude_unit="meters"

          }
        }
        addGeography.id = detailData.geology_id;
        let searchParams = {
          section_id: values.section_basic_id
        };
        let sectionBase = await Apis.sectionBase(searchParams);
        let sectionData = {};
        if (sectionBase.result && sectionBase.result.length > 0) {
          sectionData = sectionBase.result[0];
        }
        
        addGeography = Utils.addEnterer(addGeography);
       
        addGeography = Utils.addAudit(addGeography, sectionData);
        // 修改调整审核的状态
        addGeography.authState = 0;
        // 注释服务器
        
        let addSectionGeo = await Apis.changeGeologyById(addGeography);

        // 服务器测试修改
        if (addSectionGeo.result) {
          //    Modal.success({
          //   title: "This is a success message",
          //   content: 'modify success'
          // });
          if (this.props.modifyGeography) {
            this.props.modifyGeography(addSectionGeo.result);
          }
        } else {
          Modal.confirm({
            title: "Error prompt",
            content: addSectionGeo.error
          });
        }
      }
    });
  };

  viewGeochemical = () => {
    let { detailData } = this.state;


    let baseId = detailData.section_basic_id;
    let url = "/searchs/result/section/geochemical?";
    let baseUrl = baseId ? "baseId=" + baseId : "";
    let params = `${url}${baseUrl}`;
    const w = window.open("about:blank");
    w.location.href = params;
  };
  viewCollection = () => {
    let { detailData } = this.state;

    let sectionId = detailData.section_basic_id;
    let url = "/searchs/result/collection";
    let searchParams = {
      section_basic_id: sectionId
    };
    let searchAllValues = {};

    this.props.history.push({
      pathname: url,
      query: { searchParams, searchAllValues }
    });
  };
  viewFossils = () => {};
  viewFormation = () => {};
  viewLithostratigraphy = () => {
    let { detailData } = this.state;

    const { section_basic_id, ref_id } = detailData;

    let url = "/references/allresult/section?";

    let refId = ref_id ? "refId=" + ref_id : "";

    let baseIdUrl = section_basic_id ? "&baseId=" + section_basic_id : "";

    let params = `${url}${refId}${baseIdUrl}`;
    const w = window.open("about:blank");
    w.location.href = params;
    // this.props.history.push(params);
  };
  // 修改相关
  geologySearch = async (value, type, key) => {
    let params = {
      attributes: [key],
      [key]: value
    };

    let geoSearchBack = await Apis.autoCompleteGeology(params);

    let searchReust = geoSearchBack.result || [];
    let resultArray = [];

    searchReust.map((item, index) => {
      resultArray.push(item[key]);
    });

    this.setState({
      [type]: resultArray
    });
  };
  geologyChange = (value, field) => {
    this.props.form.setFieldsValue({
      [field]: value
    });
  };
  changeDecimal = (e, part, type) => {
    if (
      Utils.isNumber(e.target.value) === true ||
      e.target.value == "" ||
      e.target.value == null
    ) {
      let value;
      if (e.target.value == "" || e.target.value == null) {
        value = 0;
      } else {
        value = e.target.value ? e.target.value : 0;
      }
      const { getFieldValue } = this.props.form;

      let mapType;

      if (type === "lng") {
        mapType = "longitude";
      } else {
        mapType = "latitude";
      }
      let decimalName = "geology_" + mapType + "_decimal";
      let degreeName = "geology_" + mapType + "_degree";
      let minuteName = "geology_" + mapType + "_minute";
      let secondName = "geology_" + mapType + "_second";
      let decimalVlaue;
      if (part === "degree") {
        let minute = getFieldValue(minuteName) ? getFieldValue(minuteName) : 0;
        let second = getFieldValue(secondName) ? getFieldValue(secondName) : 0;

        decimalVlaue =
          parseFloat(value) +
          parseFloat(minute) / 60 +
          parseFloat(second) / 3600;
        this.props.form.setFieldsValue({
          [degreeName]: parseFloat(value)
        });
      } else if (part === "minute") {
        let degree = getFieldValue(degreeName) ? getFieldValue(degreeName) : 0;
        let second = getFieldValue(secondName) ? getFieldValue(secondName) : 0;
        decimalVlaue =
          parseFloat(degree) +
          parseFloat(value) / 60 +
          parseFloat(second) / 3600;
        this.props.form.setFieldsValue({
          [minuteName]: parseFloat(value)
        });
      } else if (part === "second") {
        let degree = getFieldValue(degreeName) ? getFieldValue(degreeName) : 0;
        let minute = getFieldValue(minuteName) ? getFieldValue(minuteName) : 0;
        decimalVlaue =
          parseFloat(degree) +
          parseFloat(minute) / 60 +
          parseFloat(value) / 3600;
        this.props.form.setFieldsValue({
          [secondName]: parseFloat(value)
        });
      } else if (part === "decimal") {
        let degree = parseInt(parseFloat(value));
        let minute = parseInt((parseFloat(value) - degree) * 60);
        let second = parseInt(
          (parseFloat(value) - degree) * 3600 - minute * 60
        );
        decimalVlaue = parseFloat(value);
        this.props.form.setFieldsValue({
          [degreeName]: degree,
          [minuteName]: minute,
          [secondName]: second
        });
      }

      this.props.form.setFieldsValue({
        [decimalName]: decimalVlaue ? decimalVlaue : 0
      });

      let currentLat = getFieldValue("geology_latitude_decimal")
        ? getFieldValue("geology_latitude_decimal")
        : 0;
      let currentLng = getFieldValue("geology_longitude_decimal")
        ? getFieldValue("geology_longitude_decimal")
        : 0;

      this.setState(
        {
          center: [parseFloat(currentLat), parseFloat(currentLng)],
          currentLat,
          currentLng
        },
        () => {}
      );
    }
  };
  convertUnit=(e)=>{
    const { getFieldValue } = this.props.form;
    let resultAltitude,resultUnit
    let altitude=getFieldValue("geology_altitude")
    let unit=getFieldValue("geology_altitude_unit")
    if(altitude !== "" && altitude !== null &&altitude !== undefined){
      if(unit=='meters'){
        let altitudess=parseFloat(altitude)*39.370079
        resultAltitude=parseFloat(altitudess.toFixed(4))
        resultUnit='inches'
      }else if(unit=='inches'){
        let altitudess=parseFloat(altitude)*0.0254
        resultAltitude=parseFloat(altitudess.toFixed(4))
        resultUnit='meters'
      }
      this.props.form.setFieldsValue({
        'geology_altitude': resultAltitude,
        'geology_altitude_unit': resultUnit
      });
    
    }

  }
  render() {
    let { getFieldDecorator } = this.props.form;

    let { detailData, seeOnly, seeGeographyFossil } = this.state;
    let { enterer } = detailData;
    let userid = Storage.getItem("gbdbid");
    let isClick = false;
    let isCenter = this.props.location.pathname === "/center";
    if (enterer && userid && enterer === userid && isCenter) {
      isClick = true;
    }
    if (isClick === false) {
      getFieldDecorator = (...rest) => {
        return element => {
          let NewElement = React.cloneElement(element, {
            disabled: true
          });
          return this.props.form.getFieldDecorator(...rest)(NewElement);
        };
      };
    }

    // 修改相关
    let { Countrys, Provinces, Villages, Locations } = this.state;
    let { currentLng, currentLat } = this.state;
    let Lndegree = 0;
    let Lnminute = 0;
    let Lnsecond = 0;
    let Ladegree = 0;
    let Laminute = 0;
    let Lasecond = 0;
    if (currentLng) {
      Lndegree = parseInt(currentLng);
      Lnminute = parseInt((currentLng - Lndegree) * 60);
      Lnsecond = (currentLng - Lndegree) * 3600 - Lnminute * 60;
    } else {
      currentLng = 0;
    }
    if (currentLat) {
      Ladegree = parseInt(currentLat);
      Laminute = parseInt((currentLat - Ladegree) * 60);
      Lasecond = (currentLat - Ladegree) * 3600 - Laminute * 60;
    } else {
      currentLat = 0;
    }
    return (
      <div id="detail_form">
        <div className="">
          <div className="detailform-title">
            <span className="detailform-name">Geography</span>
          </div>

          <div className="sectionGeography-form" id="main">
            <Form>
              <div className="referenceAdd-title reference-inherit">
                {/* 可能会继承多个 */}

                {
                  <span className="geography-ref-item">
                    <Form.Item label="Reference ID">
                      {getFieldDecorator(`ref_id`, {
                        rules: [
                          {
                            required: true,
                            message: "incorrect value",
                            type: "number"
                          }
                        ],
                        initialValue: Number(detailData.ref_id) || ""
                      })(
                        <InputNumber
                          className="inherit-input"
                          disabled
                        ></InputNumber>
                      )}
                    </Form.Item>
                  </span>
                }
              </div>

              <div className="referenceAdd-title reference-inherit">
                {/* 可能会继承多个 */}

                {
                  <span className="geography-ref-item">
                    <Form.Item label="Section ID">
                      {getFieldDecorator(`section_basic_id`, {
                        rules: [
                          {
                            type: "number"
                          }
                        ],
                        initialValue: Number(detailData.section_basic_id) || ""
                      })(
                        <InputNumber
                          className="inherit-input"
                          disabled
                        ></InputNumber>
                      )}
                    </Form.Item>
                  </span>
                }
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="Country/Continent">
                    {getFieldDecorator("geology_country", {
                      rules: [
                        {
                          required: true,
                          message: "must fill in "
                        }
                      ],
                      initialValue: detailData.geology_country || ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Countrys",
                            "geology_country"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_country")
                        }
                        placeholder=""
                      >
                        {Countrys.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Province/State">
                    {getFieldDecorator("geology_province", {
                      rules: [{}],
                      initialValue: detailData.geology_province || ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Provinces",
                            "geology_province"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_province")
                        }
                        placeholder=""
                      >
                        {Provinces.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume">
                  <Form.Item label="City/Village">
                    {getFieldDecorator("geology_village", {
                      rules: [{}],
                      initialValue: detailData.geology_village || ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Villages",
                            "geology_village"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_village")
                        }
                        placeholder=""
                      >
                        {Villages.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no">
                  <Form.Item label="Location">
                    {getFieldDecorator("geology_location", {
                      rules: [{}],
                      initialValue: detailData.geology_location || ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Locations",
                            "geology_location"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_location")
                        }
                        placeholder=""
                      >
                        {Locations.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="sectionGeography-map">
                <div className="sectionGeography-map-border"></div>
                <div className="sectionGeography-tutude sectionGeography-tutude-title">
                  <span>Degrees</span>
                  <span>Minutes</span>
                  <span>Seconds</span>
                  <span>Declmal</span>
                  <span>Direction</span>
                </div>

                <div className="sectionGeography-tutude">
                  <span className="sectionGeography-map-label">Latitude*</span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Degrees：</p>}>
                      {getFieldDecorator(
                        "geology_latitude_degree",

                        {
                          initialValue:
                            detailData.geology_latitude_degree !== null
                              ? detailData.geology_latitude_degree
                              : null,
                          rules: [
                            {
                              type: "number"
                            }
                          ]
                        }
                      )(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "degree", "lat")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>

                  <span>
                    <FormItem label={<p className="h5-map-title">Minutes：</p>}>
                      {getFieldDecorator("geology_latitude_minute", {
                        initialValue:
                          detailData.geology_latitude_minute !== null
                            ? detailData.geology_latitude_minute
                            : null,
                        rules: [
                          {
                            type: "number"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "minute", "lat")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Seconds：</p>}>
                      {getFieldDecorator("geology_latitude_second", {
                        initialValue:
                          detailData.geology_latitude_second !== null
                            ? detailData.geology_latitude_second
                            : null,
                        rules: [
                          {
                            type: "number"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "second", "lat")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Declmal：</p>}>
                      {getFieldDecorator("geology_latitude_decimal", {
                        initialValue:
                          detailData.geology_latitude_decimal !== null
                            ? detailData.geology_latitude_decimal
                            : null,
                        rules: [
                          {
                            type: "number"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "decimal", "lat")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Direction：</p>}
                    >
                      {getFieldDecorator("geology_latitude_direction", {
                        initialValue:
                          detailData.geology_latitude_direction || "North"
                      })(
                        <Select placeholder="">
                          <Option value="North">North</Option>
                          <Option value="East">East</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>

                <div className="sectionGeography-tutude">
                  <span className="sectionGeography-map-label">Longitude*</span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Degrees：</p>}>
                      {getFieldDecorator("geology_longitude_degree", {
                        initialValue:
                          detailData.geology_longitude_degree !== null
                            ? detailData.geology_longitude_degree
                            : null,
                        rules: [
                          {
                            type: "number"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "degree", "lng")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>

                  <span>
                    <FormItem label={<p className="h5-map-title">Minutes：</p>}>
                      {getFieldDecorator("geology_longitude_minute", {
                        initialValue:
                          detailData.geology_longitude_minute !== null
                            ? detailData.geology_longitude_minute
                            : null,
                        rules: [
                          {
                            type: "number"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "minute", "lng")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Seconds：</p>}>
                      {getFieldDecorator("geology_longitude_second", {
                        initialValue:
                          detailData.geology_longitude_minute !== null
                            ? detailData.geology_longitude_minute
                            : null,
                        rules: [
                          {
                            type: "number"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "second", "lng")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem label={<p className="h5-map-title">Declmal：</p>}>
                      {getFieldDecorator("geology_longitude_decimal", {
                        initialValue:
                          detailData.geology_longitude_decimal !== null
                            ? detailData.geology_longitude_decimal
                            : null,
                        rules: [
                          {
                            type: "number"
                          }
                        ]
                      })(
                        <InputNumber
                          placeholder=""
                          onBlur={e => this.changeDecimal(e, "decimal", "lng")}
                        ></InputNumber>
                      )}
                    </FormItem>
                  </span>
                  <span>
                    <FormItem
                      label={<p className="h5-map-title">Direction：</p>}
                    >
                      {getFieldDecorator("geology_longitude_direction", {
                        initialValue:
                          detailData.geology_longitude_direction || "East"
                      })(
                        <Select placeholder="">
                          <Option value="East">East</Option>
                          <Option value="West">West</Option>
                        </Select>
                      )}
                    </FormItem>
                  </span>
                </div>
              </div>

              <div className="referenceAdd-journal">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p>Scale of geograhic resolution</p>
                    </div>
                  }
                >
                  {getFieldDecorator("geology_geographic_scale", {
                    rules: [{}],
                    initialValue: detailData.geology_geographic_scale || ""
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="referenceAdd-no">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p>Basis of Corrdinate</p>
                    </div>
                  }
                >
                  {getFieldDecorator("geology_coordinates", {
                    rules: [{}],

                    initialValue: detailData.geology_coordinates || ""
                  })(
                    <Select>
                      <Option value="unknown">unknown</Option>
                      <Option value="precise GPS value">
                        precise GPS value
                      </Option>
                      <Option value="based on nearby location">
                        based on nearby location
                      </Option>
                      <Option value="based on political unit">
                        based on political unit
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="referenceSearch-authours referenceSearch-editors">
                <div>
                  <div className="referenceSearch-author">
                    <span>
                      <FormItem label="Altitude">
                        {getFieldDecorator("geology_altitude", {
                          rules: [
                            {
                              type: "number"
                            }
                          ],
                          initialValue: detailData.hasOwnProperty("geology_altitude") ?detailData.geology_altitude:null
                        })(<InputNumber min={0}></InputNumber>)}
                      </FormItem>
                    </span>
                    <span>
                      <FormItem label="">
                        {getFieldDecorator("geology_altitude_unit", {
                          rules: [{}],
                          initialValue: detailData.geology_altitude_unit || "meters"
                        })(
                          <Select placeholder="">
                            <Option value="meters">Meter</Option>
                            <Option value="inches">Inch</Option>
                          </Select>
                        )}
                      </FormItem>
                    </span>
                    <span 
                    className="iconfont icon-shuaxin result-convert-unit" 
                    title="convert unit"
                    onClick={(e)=>this.convertUnit(e)}
                    >

                    </span>
                  </div>
                </div>
              </div>

              {/* <div className="referenceAdd-book">
                <Form.Item label="Location">
                  {getFieldDecorator('geology_location', {
                    rules: [
                      {

                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div> */}
              <div className="referenceAdd-book">
                <Form.Item
                  label={
                    <div className="sectionGeography-scale">
                      <p>Location Details</p>
                    </div>
                  }
                >
                  {getFieldDecorator("geology_details", {
                    rules: [{}],
                    initialValue: detailData.geology_details || ""
                  })(<Input />)}
                </Form.Item>
              </div>

              <div className="referenceAdd-comments">
                <Form.Item label="Comments" className="long-label">
                  {getFieldDecorator("geology_comments", {
                    rules: [{}],
                    initialValue: detailData.geology_comments || ""
                  })(<TextArea rows={6}></TextArea>)}
                </Form.Item>
              </div>

              {/* <div className="referenceSearch-btns sectionBase-btns">
                  <div>
                    <FormItem>
                      <Button type="gbdb" onClick={() => this.viewFormation()}>
                        <div>
                          <p>View Formation</p>
                        </div>
                      </Button>
                    </FormItem>
                  </div>
                </div> */}
              {detailData.auth_time !== null ? (
                <div className="referenceAdd-authours referenceAdd-editors">
                  <div className="authcomment-details">
                    {detailData.authState === 2 ? (
                      <div className="referenceAdd-author authcomment-detail-item">
                        <div className="referenceAdd-author-title authcomment-title">
                          Auth Comment
                        </div>
                        <div className="authcomment-content">
                          {detailData.auth_comment !== ""
                            ? detailData.auth_comment
                            : "No comment"}
                        </div>
                      </div>
                    ) : null}
                    {detailData.authState !== 0 ? (
                      <div className="referenceAdd-author authcomment-detail-item">
                        <div className="referenceAdd-author-title authcomment-title">
                          Auth Time
                        </div>
                        <div className="authcomment-content">
                          {detailData.auth_time
                            ? detailData.auth_time.split("T")[0]
                            : "Unfilled time"}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {this.state.isCenterUser &&
              JSON.stringify(this.state.detailData) === "{}" ? (
                <div className="referenceAdd-authours referenceAdd-editors">
                  <div className="authcomment-details">
                    <div className="referenceAdd-author authcomment-detail-item supplementary-warning">
                      <div className="referenceAdd-author-title authcomment-title ">
                        Warning
                      </div>
                      <div className="authcomment-content">
                        This section has no geographic data,you can click the button with the word supplement to add geographic data
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </Form>
          </div>
        </div>
        {/* <Button type="gbdb" onClick={() => this.viewBase()}>
              <div>
                <p>Bio-/Chrono-</p>
                <p>Stratigrahpy</p>
              </div>
            </Button> */}
        <div className="referenceSearch-btns sectionBase-btns">
          {seeGeographyFossil === false ? null : (
            <div className="source-inner-btns">
              <Button type="gbdb" onClick={() => this.viewLithostratigraphy()}>
                <div>
                  <p>Lithostratigraphy</p>
                  <p>& Fossils</p>
                </div>
              </Button>

              <Button type="gbdb" onClick={() => this.viewGeochemical()}>
                <div>
                  <p>Geochemical</p>
                  <p> Data</p>
                </div>
              </Button>
              {/* <Button type="gbdb" onClick={() => this.viewCollection()}>
                <div>
                  <p>Collection</p>
                  <p>Data</p>
                  
                </div>
              </Button> */}
            </div>
          )}

          {/* <div className="formation-complete-btns"> */}
          <div className="referenceSearch-btns sectionBase-btns">
            <div
              className={`${
                seeGeographyFossil === true
                  ? "source-inner-btns"
                  : "formation-complete-btns"
              }`}
            >
              <Button type="gbdb" onClick={() => this.viewBase()}>
                <p>View Base</p>
              </Button>
              {this.state.isCenterUser &&
              JSON.stringify(this.state.detailData) === "{}" ? (
                <Button
                  type="gbdb"
                  title="This section has no geographic data"
                  onClick={() => this.supplementaryGeography()}
                >
                  <p>Supplementary</p>
                </Button>
              ) : null}
              {isClick && seeOnly === false ? (
                <Button type="gbdb" onClick={() => this.modifyGeography()}>
                  <p>Save modify</p>
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
GeographyDetailForm = Form.create({})(GeographyDetailForm);
export default GeographyDetailForm;
