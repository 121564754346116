import React, { Component } from 'react';
import './index.scss'
import {Button, Checkbox,Icon, message} from 'antd'
import axios from 'axios';

export default class Customer extends Component {
    state={
        tableData:[],
        data1:0, //数据管理
        data2:0,
        data3:0,
        data4:0,
        integral1:0,  //积分管理
        integral2:0,
        integral3:0,
        integral4:0,
        authentication1:0,  //认证管理
        authentication2:0,
        authentication3:0,
        authentication4:0,
        account1:0,  //账号管理
        account2:0,
        account3:0,
        account4:0,
        // system1:0,  //系统管理
        // system2:0,
        // system3:0,
        // system4:0,
        news1:0,  //新闻管理
        news2:0,
        news3:0,
        news4:0,
        feedback1:0,
        feedback2:0,
        feedback3:0,
        feedback4:0,
    }
    handleSubmit(){
        const {data1,integral1,authentication1,account1,system1,news1,feedback1}=this.state
        const {data2,integral2,authentication2,account2,system2,news2,feedback2}=this.state
        const {data3,integral3,authentication3,account3,system3,news3,feedback3}=this.state
        const {data4,integral4,authentication4,account4,system4,news4,feedback4}=this.state
        var sendData1={
            id:1,
            data:data1,
            integral:integral1,
            authentication:authentication1,
            account:account1,
            // system:system1,
            news:news1,
            feedback:feedback1
        }
        var sendData2={
            id:2,
            data:data2,
            integral:integral2,
            authentication:authentication2,
            account:account2,
            // system:system2,
            news:news2,
            feedback:feedback2
        }
        var sendData3={
            id:3,
            data:data3,
            integral:integral3,
            authentication:authentication3,
            account:account3,
            // system:system3,
            news:news3,
            feedback:feedback3
        }
        var sendData4={
            id:4,
            data:data4,
            integral:integral4,
            authentication:authentication4,
            account:account4,
            // system:system4,
            news:news4,
            feedback:feedback4
        }
        var change1=()=>{
            return  axios.post('/api/admin/change/authority',sendData1)
        }
        var change2=()=>{
            return  axios.post('/api/admin/change/authority',sendData2)
        }
        var change3=()=>{
            return  axios.post('/api/admin/change/authority',sendData3)
        }
        var change4=()=>{
            return  axios.post('/api/admin/change/authority',sendData4)
        }
        axios.all([change1(),change2(),change3(),change4()])
            .then(axios.spread((change1,change2,change3,change4)=>{
                message.success('保存成功！')
            })).catch(err=>{
                message.error('this an error message~')
            })
    }
    componentDidMount(){
        axios.get('/api/admin/authority').then(res=>{
            if(res.data.error===null){
                var data=res.data.result
                this.setState({
                    tableData:data,
                    data1:data[0].data,
                    data2:data[1].data,
                    data3:data[2].data,
                    data4:data[3].data,
                    integral1:data[0].integral,
                    integral2:data[1].integral,
                    integral3:data[2].integral,
                    integral4:data[3].integral,
                    authentication1:data[0].authentication,
                    authentication2:data[1].authentication,
                    authentication3:data[2].authentication,
                    authentication4:data[3].authentication,
                    account1:data[0].account,
                    account2:data[1].account,
                    account3:data[2].account,
                    account4:data[3].account,
                    // system1:data[0].system,
                    // system2:data[1].system,
                    // system3:data[2].system,
                    // system4:data[3].system,
                    news1:data[0].news,
                    news2:data[1].news,
                    news3:data[2].news,
                    news4:data[3].news,
                    feedback1:data[0].feedback,
                    feedback2:data[1].feedback,
                    feedback3:data[2].feedback,
                    feedback4:data[3].feedback,
                })
            }
        }).catch(err=>{
            message.error('出错啦~')
        })
    }
    changeLimit(e,id,type){
        var checked=e.target.checked
        var name=type+id
            this.setState({
                [name]:checked?1:0
            })
    }
    render() {
        const {tableData}=this.state
        return (
            <div id='AManage'>
                <div className='title'> 管理员组设置 </div>
                <div className='table'>
                    <table>
                        <tbody>
                            <tr className='head'>
                                <td>用户</td>
                                <td>数据管理</td>
                                <td>积分管理</td>
                                <td>认证管理</td>
                                <td>账号管理</td>
                                <td>新闻管理</td>
                                <td>问题反馈</td>
                            </tr>
                            {
                              tableData.map((item,index)=>{
                                  return (
                                    <tr key={index} className={`tr ${index%2===0?'color':''}`}>
                                        <td>{item.name}</td>
                                        <td><Checkbox onChange={(e)=>{this.changeLimit(e,item.id,'data')}} defaultChecked={item.data===1?true:false}></Checkbox></td>
                                        <td><Checkbox onChange={(e)=>{this.changeLimit(e,item.id,'integral')}} defaultChecked={item.integral===1?true:false}></Checkbox></td>
                                        <td><Checkbox onChange={(e)=>{this.changeLimit(e,item.id,'authentication')}} defaultChecked={item.authentication===1?true:false}></Checkbox></td>
                                        <td><Checkbox onChange={(e)=>{this.changeLimit(e,item.id,'account')}} defaultChecked={item.account===1?true:false}></Checkbox></td>
                                        <td><Checkbox onChange={(e)=>{this.changeLimit(e,item.id,'news')}} defaultChecked={item.news===1?true:false}></Checkbox></td>
                                        <td><Checkbox onChange={(e)=>{this.changeLimit(e,item.id,'feedback')}} defaultChecked={item.feedback===1?true:false}></Checkbox></td>
                                    </tr>
                                  )
                                
                              })  
                            }
                           
                        </tbody>
                    </table>
                </div>
                <div className='foot'><Button type='primary' onClick={this.handleSubmit.bind(this)}>保存</Button></div>
            </div>
        );
    }
}
