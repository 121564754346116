import React, { Component } from "react";
import { Form, Input, Button, Radio, Select, Modal, InputNumber, AutoComplete } from "antd";
import { NavLink } from "react-router-dom";
import TaxonomyDetailForm from "commons/AllDetails/Taxonomy/index.js";
import TaxonomyTableResult from "commons/AllTableList/Taxonomy/index.js";
import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";
import dataApis from "src/js/apis/data";
import Apis from "src/js/apis/api";
import Utils from "src/js/utils";
import "./index.css";
import Storage from "src/js/storage";
const { Option } = Select;

class TaxonViewPort extends Component {
  constructor(props) {
    super(props);

    this.state = {
      genPrefix: [],
      spPrefix1: [],
      spPrefix2: [],
      spSuffix: [],
      name1Opts: [],
      name2Opts: [],
      nameType: "formal",
      showTaxonResult: false,
      resultList: [],
      genusPrefixV: "",
      spPrefix1V: "",
      spSuffixV: "",
      spDictV: "",
      spPrefix2V: "",
      combinationName: "",
      collectInfo: props.collectInfo || {},
      fossilInfo: props.fossilInfo || {},
      fossilOccurrence: {},
      taxonInfo: {},
      taxon_name1: "",
      taxon_name2: "",
      refSearch: false,
      refCondition: false,

      collection_id: props.collection_id || "",
      fossillist_id: props.fossillist_id || "",
      inheritRefIds: props.ref_id || "",
      allChildError: {},
    };
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.collection_id !== this.props.collection_id) {
      this.setState({
        collection_id: nextprops.collection_id,
      });
    }
    if (nextprops.fossillist_id !== this.props.fossillist_id) {
      this.setState({
        fossillist_id: nextprops.fossillist_id,
      });
    }
    if (nextprops.ref_id !== this.props.ref_id) {
      this.setState({
        inheritRefIds: nextprops.ref_id,
      });
    }
  }
  async componentDidMount() {
    let genPrefix = await dataApis.getAssLabels({ label_type: 1 });
    let spPrefix1 = await dataApis.getAssLabels({ label_type: 2 });
    let spPrefix2 = await dataApis.getAssLabels({ label_type: 3 });
    let spSuffix = await dataApis.getAssLabels({ label_type: 4 });
    this.setState(
      {
        genPrefix: genPrefix.result || [],
        spPrefix1: spPrefix1.result || [],
        spPrefix2: spPrefix2.result || [],
        spSuffix: spSuffix.result || [],
      },
      async () => {
        let { collectInfo } = this.state;
        let params = {
          collection_id: collectInfo.coll_id,
        };
        let fossilOccurrenceBack = await Apis.searchAllFossilOccurrence(params);

        if (fossilOccurrenceBack.result) {
          this.setState({
            fossilOccurrence: fossilOccurrenceBack.result[0],
          });
        } else {
          Modal.error({
            title: "This is an error message",
            content: fossilOccurrenceBack.error,
          });
        }
      }
    );
  }
  searchTaxons = async () => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      let searchConditions = Utils.filterParams(values);

      if (!err) {
        let taxonName1 = searchConditions.taxon_name1;
        let taxonName2 = searchConditions.taxon_name2;

        if (taxonName1 === undefined && taxonName2 === undefined) {
          Modal.error({
            title: "This is an error message",
            content: "Please enter query information",
          });
        } else {
          let spPrefix2V = "";

          let genusPrefixV = searchConditions.genus_prefix;

          let spPrefix1V = searchConditions.sp_prefix1;
          let spDictV = searchConditions.sp_name_dict;
          let spSuffixV = searchConditions.sp_suffix;

          if (spDictV === "cf." || spDictV === "aff." || spDictV === "ex gr.") {
            spPrefix2V = spDictV;
          }

          // 获取值

          this.setState({
            genusPrefixV: genusPrefixV === "Null" ? "" : genusPrefixV,
            spPrefix1V: spPrefix1V === "Null" ? "" : spPrefix1V,
            spSuffixV: spSuffixV === "Null" ? "" : spSuffixV,
            spDictV: spDictV === "Null" ? "" : spDictV,
            spPrefix2V: spPrefix2V === "Null" ? "" : spPrefix2V,
          });
          let params = {
            taxon_name1: searchConditions.taxon_name1,
            taxon_name2: searchConditions.taxon_name2,
          };
          this.setState(
            {
              searchParams: params,
              searchValues: searchConditions,
              showTaxonResult: true,
            },
            () => {
              setTimeout(() => {
                let { searchParams, searchValues } = this.state;
                let embellish = {
                  genusPrefixV,
                  spPrefix1V,
                  spSuffixV,
                  spDictV,
                  spPrefix2V,
                };
                this.taxonomyChild.searchTaxonomyDatas(
                  searchParams,
                  searchValues,

                  embellish
                );
              }, 20);
            }
          );
        }
      }
    });
  };
  taxonName1Search = async (value, type) => {
    if (value) {
      let params = {
        [type]: value,
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error,
        });
      } else if (searchResult.result) {
        if (type === "taxon_name1") {
          this.setState({ name1Opts: searchResult.result });
        } else if (type === "taxon_name2") {
          this.setState({ name2Opts: searchResult.result });
        }
      }
    } else {
      this.setState({ name1Opts: [], name2Opts: [] });
    }
  };
  taxonNameChange = (value, type) => {
    this.setState(
      {
        combinationName: "",
        taxonInfo: {},
      },
      () => {
        this.props.form.setFieldsValue({
          [type]: value,
        });
      }
    );
  };
  nameTypeChange = (e) => {
    this.setState({
      nameType: e.target.value,
    });
  };

  checkTaxonomyOne = (value, combinationName) => {
    this.setState({
      showTaxonResult: false,
      combinationName,
      taxonInfo: value,
    });
  };

  searchTaxonCancel = () => {
    this.setState({
      showTaxonResult: false,
    });
  };
  onTaxonomy = (ref) => {
    this.taxonomyChild = ref;
  };
  handleTaxonOk = () => {
    this.setState({
      showTaxonResult: false,
    });
  };
  geographyDetail = () => {};
  detailTaxonomyFormOk = () => {
    this.setState({
      showTaxonomyDetailForm: false,
    });
  };
  detailTaxonomyFormCancel = () => {
    this.setState({
      showTaxonomyDetailForm: false,
    });
  };

  TaxonomyDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showTaxonomyDetailForm: true,
    });
  };

  checkAgain = (optionType) => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            refSearch: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType,
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues } = this.state;
              this.child.searchReferenceDatas(searchParams, searchValues);
            }, 20);
          }
        );
      }
    });
  };

  showSearchRef = (e) => {
    e.target.blur();
    this.setState({
      refCondition: true,
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false,
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false,
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false,
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false,
    });
  };
  clickRefId = (value) => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value,
    });
  };
  onRef = (ref) => {
    this.child = ref;
  };
  getDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showDetailForm: true,
    });
  };
  checkRefOne = (value) => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || "",
    });
    this.setState(
      {
        refSearch: false,
        inheritRefIds: value.ref_id,
        refInfo: value,
      },
      () => {}
    );
  };
  saveOpinion = async (type) => {
    let { inheritRefIds, combinationName } = this.state;
    if (inheritRefIds === "") {
      Modal.warn({
        content: "please input reference id",
      });
      return;
    }
    if (type !== "informal") {
      if (combinationName === "") {
        Modal.warn({
          content: "please select taxon id",
        });
        return;
      }
    }

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let optionInfo = Utils.filterParams(values);
        optionInfo = Utils.filterCondition(optionInfo);
        optionInfo = Utils.addEnterer(optionInfo);
        // optionInfo = Utils.addAudit(optionInfo,this.state.fossilInfo);
        let refID = this.state.inheritRefIds;
        let { taxonInfo, nameType } = this.state;
        let lastParams = {};
        let params = {
          collection_id: this.state.collection_id,
          fossillist_id: this.state.fossillist_id,
          ref_id: refID,
          enterer: Storage.getItem("gbdb"),
          taxonmixed_id: taxonInfo.id,
          fullfossil_name: this.state.combinationName,
        };
        Object.assign(lastParams, params, optionInfo);

        let createFossilOpinionBack = await Apis.createFossilOpinionItem(lastParams);

        if (createFossilOpinionBack.result) {
          let lastResult = createFossilOpinionBack.result;

          this.props.addViewPort(lastResult, nameType);
        } else {
          Modal.error({
            content: "View add failed",
          });
        }
      }
    });
  };
  childError = (allChildError) => {
    this.setState({
      allChildError,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const name1Opts = this.state.name1Opts.map((item, indx) => <Option key={item.taxon_name1}>{item.taxon_name1}</Option>);
    const name2Opts = this.state.name2Opts.map((item, indx) => <Option key={item.taxon_name2}>{item.taxon_name2}</Option>);
    let {
      genPrefix,
      spPrefix1,
      spPrefix2,
      spSuffix,

      nameType,

      combinationName,
      inheritRefIds,
      searchParams,
      searchValues,
    } = this.state;

    return (
      <div className="search-taxons-comp">
        <div className="search-taxons-title">Taxon Name</div>

        <div className="taxon-viewport-input">
          <span className="taxon-viewport-reference">Reference</span>
          <InputNumber placeholder="Reference" onFocus={this.showSearchRef} value={inheritRefIds}></InputNumber>
        </div>
        <div className="name-type-change">
          <Radio.Group onChange={this.nameTypeChange} value={nameType}>
            <Radio value={"formal"}>Formal name</Radio>
            <Radio value={"informal"}>Informal name </Radio>
          </Radio.Group>
        </div>
        {nameType === "formal" ? (
          <div>
            <Form layout="vertical" className="taxonSearch-form">
              <div className="taxonSearch-form-div">
                <Form.Item label="">
                  {getFieldDecorator(
                    "genus_prefix",
                    {}
                  )(
                    <Select placeholder="genus prefix">
                      {genPrefix.map((item, index) => {
                        return (
                          <Option value={item.label_name} key={item.label_name}>
                            {item.label_name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="genus or higher">
                  {getFieldDecorator(
                    "taxon_name1",
                    {}
                  )(
                    <AutoComplete placeholder="taxon name1" onSearch={(value) => this.taxonName1Search(value, "taxon_name1")} onChange={(value) => this.taxonNameChange(value, "taxon_name1")}>
                      {name1Opts}
                    </AutoComplete>
                  )}
                </Form.Item>
              </div>

              <div className="taxonSearch-form-div">
                <Form.Item label="">
                  {getFieldDecorator(
                    "sp_prefix1",
                    {}
                  )(
                    <Select placeholder="sp prefix1">
                      {spPrefix1.map((item, index) => {
                        return (
                          <Option value={item.label_name} key={item.label_name}>
                            {item.label_name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="">
                  {getFieldDecorator(
                    "sp_name_dict",
                    {}
                  )(
                    <Select id="dict" placeholder="sp_name dict">
                      {spPrefix2.map((item, index) => {
                        return (
                          <Option value={item.label_name} key={item.label_name}>
                            {item.label_name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Species">
                  {getFieldDecorator(
                    "taxon_name2",
                    {}
                  )(
                    <AutoComplete placeholder="taxon name2" onSearch={(value) => this.taxonName1Search(value, "taxon_name2")} onChange={(value) => this.taxonNameChange(value, "taxon_name2")}>
                      {name2Opts}
                    </AutoComplete>
                  )}
                </Form.Item>
                <Form.Item label="">
                  {getFieldDecorator(
                    "sp_suffix",
                    {}
                  )(
                    <Select placeholder="sp suffix">
                      {spSuffix.map((item, index) => {
                        return (
                          <Option value={item.label_name} key={item.label_name}>
                            {item.label_name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="taxonSearch-btns ">
                <Button type="gbdb" onClick={this.searchTaxons}>
                  Search
                </Button>
                <Button type="gbdb">
                  <NavLink to="/taxonomy/addTaxon" target="_blank">
                    Add new taxon
                  </NavLink>
                </Button>
              </div>
              <div className="combinationName">
                <span>
                  Taxon Fullname:
                  {combinationName ? <span>{combinationName}</span> : <span className="organ-tips">please select taxon first</span>}
                </span>
              </div>

              <div className="taxonSearch-form-div">
                <Form.Item label="Organ or form**:	">{getFieldDecorator("organor_form", {})(<Input placeholder="organor form"></Input>)}</Form.Item>
                <p className="organ-tips">**Organ: necessary for fossil plants; form: such as forma A, wide form.</p>
              </div>
              <div>
                <Form.Item label="Comment" className="taxonName-search-view">
                  {getFieldDecorator("comment", {})(<Input placeholder="The maximum length is 26"></Input>)}
                </Form.Item>
              </div>

              <div className="taxonSearch-btns viewport-taxonSearch-btns">
                <Button type="gbdb" onClick={this.saveOpinion}>
                  Save Opinion
                </Button>
                {/* <Button type="gbdb">
                  <NavLink to="/taxonomy/addOriginal">Add new taxon</NavLink>
                </Button> */}
              </div>
            </Form>

            <Modal
              destroyOnClose
              visible={this.state.showTaxonResult}
              className="searchResultModal taxResultModal noBottomPadding tableListModal"
              width="76%"
              footer={null}
              header={null}
              onOk={this.handleTaxonOk}
              onCancel={this.searchTaxonCancel}
            >
              <TaxonomyTableResult
                searchParams={searchParams}
                searchValues={searchValues}
                onTaxonomy={this.onTaxonomy}
                changeCheckSelected={this.changeCheckSelected}
                getDetail={this.TaxonomyDetail}
                checkOne={true}
                checkTaxonomyOne={this.checkTaxonomyOne}
              ></TaxonomyTableResult>
            </Modal>
          </div>
        ) : (
          <div>
            <Form layout="vertical" className="taxonSearch-form">
              <div className="taxonSearch-form-div">
                <Form.Item label="Informal name*:	">{getFieldDecorator("informal_name", {})(<Input></Input>)}</Form.Item>
                <p className="organ-tips">*Such as diplograptid</p>
              </div>
            </Form>
            <div className="taxonSearch-add-fossil">
              <Button type="gbdb" onClick={() => this.saveOpinion("informal")}>
                Save Opinion
              </Button>
            </div>
          </div>
        )}
        <Modal
          destroyOnClose
          visible={this.state.refCondition}
          className="searchResultModal taxResultModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handlerefConditionOk}
          onCancel={this.handlerefConditionCancel}
        >
          <ReferenceOption childError={this.childError} conditions={{}} ref="reference"></ReferenceOption>

          {
            <div className="referenceSearch-btns">
              <div>
                <Button
                  type="gbdb"
                  onClick={() => {
                    this.checkAgain("reference");
                  }}
                >
                  <p> Search</p>
                </Button>
              </div>
            </div>
          }
        </Modal>

        <Modal
          destroyOnClose
          visible={this.state.refSearch}
          className="searchResultModal taxResultModal searchListModal tableListModal"
          width="76%"
          footer={null}
          header={null}
          onOk={this.handleRefOk}
          onCancel={this.handleRefCancel}
        >
          {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}

          <ReferenceTableResult
            searchParams={searchParams}
            searchValues={{}}
            onRef={this.onRef}
            checkOne={true}
            checkRefOne={this.checkRefOne}
            getDetail={this.getDetail}
            // changeCheckSelected={this.changeCheckSelected}
          ></ReferenceTableResult>
        </Modal>

        <Modal
          title=""
          destroyOnClose
          visible={this.state.showDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailFormOk}
          onCancel={this.detailFormCancel}
        >
          <ReferenceDetailForm detailData={this.state.detailData} searchSection={this.searchSection}></ReferenceDetailForm>
        </Modal>
        <Modal
          title=""
          destroyOnClose
          visible={this.state.showTaxonomyDetailForm}
          className="searchResultModal taxResultModal DetailModal"
          width="76.8%"
          footer={null}
          // header={null}
          onOk={this.detailTaxonomyFormOk}
          onCancel={this.detailTaxonomyFormCancel}
        >
          <TaxonomyDetailForm detailData={this.state.detailData} onlySeeRef={true} seeOnly={true}></TaxonomyDetailForm>
        </Modal>
      </div>
    );
  }
}
TaxonViewPort = Form.create({})(TaxonViewPort);
export default TaxonViewPort;
