import React, { Component } from "react";
import {Menu } from "antd";
import { NavLink } from "react-router-dom";
import "./index.css";
import Storage from "src/js/storage";
import "src/js/global.js";

export default class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey:
        this.props.location.pathname.split("/")[
          this.props.location.pathname.split("/").length - 1
        ] || "reference"
    };
  }

  changeGroup = e => {
    this.setState(
      {
        currentKey: e.key
      },
      () => {
      }
    );
  };
  isGbdbUser=()=>{
    let token = Storage.getItem("token");
   
    if(token==undefined){
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        this.props.history.push("/home");
      }
    }
  
  }
  componentDidMount = () => {
    // this.isGbdbUser()
    let url = this.props.location;
    if (url) {
      let pathname = url.pathname;
      let all = pathname.split("/");
      let keyValue = all[all.length - 1];
      this.setState(
        {
          currentKey: keyValue
        },
        () => {}
      );
    }
  };
  render() {
    let { currentKey } = this.state;
    return (
      <div className="group-index form-contents">
        <div className="reference-search-operate">
          <p className="group-index-title">Group</p>
        </div>
        <div className="reference-header" id="referenceMenu">
          <Menu
            defaultSelectedKeys={currentKey}
            mode="horizontal"
            onClick={e => this.changeGroup(e)}
            className="reference-search-menu reference-menu"
            selectedKeys={currentKey}
          >
            <Menu.Item key="list">
              <div>
                <NavLink to="/group/list">My group</NavLink>
              </div>
            </Menu.Item>
            <Menu.Item key="search">
              <div>
                <NavLink to="/group/search"> Search group </NavLink>
              </div>
            </Menu.Item>

            <Menu.Item key="create">
              <div>
                <NavLink to="/group/create">Create group</NavLink>
              </div>
            </Menu.Item>

            <Menu.Item key="apply">
              <div>
                <NavLink to="/group/apply"> Apply box </NavLink>
              </div>
            </Menu.Item>
          </Menu>
        </div>

        <div className="form-mian-content group-index-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}
