import React, { Component } from 'react';
import {Form,Input,DatePicker, Button,Pagination, Modal, message } from 'antd'
import './index.scss'
import axios from 'axios'
import OceanicModal from './oceanicModal.js'
import moment from 'moment'

const {RangePicker}=DatePicker

 class AntdForm extends Component {
    constructor(props){
        super(props);
        this.state={
            tableData:[],
            nowPage:1,
            defaultPageSize:10,
            isShowInfo:false,
            sendData:{},
            basicInfo:{}
        }
    }
    handleSubmit(page=1){
        this.props.form.validateFields((err, values) => {
            if (!err) {
               
                var authorizer_id=JSON.parse(localStorage.getItem('gbdbid')).value //当前登录用户id
                var sendData={
                    page,
                    pageSize:this.state.defaultPageSize,
                    assetstype:1,
                    authorizer_id,
                }
                if(values.referenceId){
                    sendData.section_ref_id=values.referenceId
                }
                if(values.sectionName){
                    sendData.section_name=values.sectionName
                }
                if(values.createTime){
                    sendData.dateFrom=moment(values.createTime[0]).format('YYYY/MM/DD');
                    sendData.dateTo=moment(values.createTime[1]).format('YYYY/MM/DD');
                }
               
                axios.get('/api/admin/searchAssets',{
                    params:sendData
                }).then(res=>{
                    if(res.data.error===null){
                        this.setState({
                            tableData:res.data.result,
                            total:res.data.total,
                            sendData,
                        })
                    }
                    if(res.data.result.length===0){
                        message.warning('没有数据！')
                    }
                    
                }).catch(err=>{
                    message.error('出错啦：',err)
                })
            }
        })
    }
    changePage(page,pageSize){
     
        this.setState({
            nowPage:page
        })
        this.handleSubmit(page)
    }
    showInfo(item){
        this.setState({
            basicInfo:item
        },()=>{
            this.setState({
                isShowInfo:true
            })
        })
        //modal
    }
    handleShowInfo(){
        this.setState({
            isShowInfo:false
        })
    }
    cancelShowInfo(){
      this.setState({
            isShowInfo:false
        })
       
    }
    handleReject(){
        Modal.confirm({
            content:<div>
                <div>请输入驳回原因：</div>
                <Input.TextArea rows={4} className='minput'></Input.TextArea>
            </div>,
            okText:'确定',
            cancelText:'取消',
            onOk:()=>{
                message.success('已驳回！')
                this.setState({
                    isShowInfo:false
                })
            },
          
        })
    }
    time(t){
        var arr=t.split('T');
        t=arr[0];
        return t
    }
    render() {
        let {defaultPageSize,nowPage,isShowInfo,total,sendData,basicInfo} =this.state;
        const { getFieldDecorator} = this.props.form;
        const {tableData}=this.state;
        return (
            <div id='ASection'>
                <div className='title'>Oceanic data</div>
                <div className="form">
                    <Form layout='vertical'>
                        <Form.Item label='Section name'>
                            {
                                getFieldDecorator('sectionName',{
                                })(<Input></Input>)
                            }
                        </Form.Item>

                        <Form.Item label='创建时间'>
                            {
                                getFieldDecorator('createTime',{

                                })(<RangePicker></RangePicker>)
                            }
                        </Form.Item>

                        
                        <Form.Item label='Reference ID'>
                            {
                                getFieldDecorator('referenceId',{

                                })(<Input></Input>)
                            }
                        </Form.Item>

                        <Form.Item className='foot'>
                           <Button type='primary' onClick={()=>{this.handleSubmit()}}>筛选</Button>
                        </Form.Item>

                    </Form>
                   
                </div>
                {
                    tableData.length>0?(
                        <div className='table'>
                        <table>
                            <tbody>
                            <tr className='head'>
                                <td>序号</td>
                                <td>Section Title</td>
                                <td>Reference ID</td>
                                <td>创建时间</td>
                                <td>状态</td>
                                <td>操作</td>
                            </tr>
                            {
                                tableData.map((item,index)=>{
                                    return (
                                        <tr key={index} className={`tr ${index%2===0?'color':''}`}>
                                            <td>{(nowPage-1)*defaultPageSize+index+1}</td>
                                            <td>{item.section_name}</td>
                                            <td>{item.section_ref_id}</td>
                                            <td>{this.time(item.release_date)}</td>
                                            <td>未审核</td>
                                            <td><Button type='link' onClick={this.showInfo.bind(this,item)}>查看</Button></td>
                                        </tr>
                                    )
    
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    ):null
                }
                {
                    tableData.length>0?(
                        <div className='pages'>
                                    <Pagination size='small' 
                                    current={nowPage}
                                    defaultPageSize={defaultPageSize} 
                                    total={total} 
                                    // showTotal={showTotal.bind(this)}
                                    onChange={this.changePage.bind(this)}
                                    ></Pagination>
                                </div>
                    ):null
                }
                {
                    isShowInfo?(
                        <OceanicModal
                            basicInfo={basicInfo}
                            sendData={sendData}
                            visible={isShowInfo}
                            handleShowInfo={this.handleShowInfo.bind(this)}
                            handleCancel={this.cancelShowInfo.bind(this)}
                            handleReject={this.handleReject.bind(this)}
                        ></OceanicModal>
                    ):null
                }
               
            
            </div>
        
            );
    }
}
const AOceanic = Form.create()(AntdForm);
export default AOceanic

