import React from 'react'
import {Modal,Form} from 'antd'

export default class TaxonomyModal extends React.Component{
    render(){
        return (
            <div>

            </div>
        )
    }
}