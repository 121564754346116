import React, { Component } from "react";
import Utils from "src/js/utils";

import BaseUpload from "commons/BaseUpload/index";

import Apis from "src/js/apis/api";
import Storage from "src/js/storage";
import AddGeochemicalForm from "components/Section/geochemical/addGeochemicalForm.js";
import "./index.css";
import { Form, Input, Button, DatePicker, Modal, InputNumber } from "antd";
import moment from "moment";

import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";

import GeochemicalOption from "commons/AllOptions/Geochemical/index.js";
import SectionOption from "commons/AllOptions/Section/index.js";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";
import GeochemicalTableResult from "commons/AllTableList/Section/Geochemical/index.js";
import Users from "src/js/users";
import BaseTableResult from "commons/AllTableList/Section/Base/index.js";

class SectionFormation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inheritRefIds: "",

      uploadFileModal: false,
      addFormModal: false,
      ShowType: "list",
      refSearch: false,
      formationCondition: false,
      geologyInfo: {},

      
      baseId: "",
      geographyId: "",

      refCondition: false,
      geographySearch: false,
      sectionOptionVisible: false,
      geochemistryVisible: false,
      sectionType: "baseinfo",
      uploadPath: "",

      GeochemBase: {},
      isCover: 1,
      geochemistryoccurrenceId: "",
      geochemistryoccurrenceData: {},
      allChildError: {},
     
      allFiles: [],
      defaultFiles:[],
      uploadFiles: [],
    };
  }
  isGbdbUser = async () => {
    let Inputerid=false
    let token = Storage.getItem("token");
     Inputerid = await Users.isGbdbInputer();

    if (token == undefined) {
      let urlName = this.props.location.pathname.split("/")[1];
      if (global.constants.notGoHome.includes(urlName) == false) {
        Modal.error({
          title: "Sorry!!",
          content: "There is no permission to view the page"
        });
        this.props.history.push("/home");
      }
    } else {
      if (Inputerid == false) {
        let urlName = this.props.location.pathname.split("/")[1];
        if (global.constants.userInput.includes(urlName) == true) {
          Modal.error({
            title: "Sorry!!",
            content: "You do not have permission to add data"
          });
          this.props.history.push("/home");
        }
      }
    }
  };

  async componentDidMount() {
    this.isGbdbUser();
    if (this.props.location.search) {
      let inheritRefIds =
        Utils.getSearch(this.props.location.search, "refId") || "";
      let baseId = Utils.getSearch(this.props.location.search, "baseId") || "";
      let geographyId =
        Utils.getSearch(this.props.location.search, "geographyId") || "";

      this.setState(
        {
          inheritRefIds: inheritRefIds ? Number(inheritRefIds) : null,
          geographyId: geographyId ? Number(geographyId) : null,
          isSearch: true,
          baseId: baseId ? Number(baseId) : null
        },
        async () => {
          if (geographyId) {
            let params = {
              geology_id: geographyId
            };
            this.getGeologyInfo(params);
          } else {
            this.setState(
              {
                geologyInfo: {}
              },
              () => {}
            );
          }
        }
      );
    } else {
      this.setState({
        isSearch: false
      });
    }
  }
  AddgeochemicalBase = () => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if(!err){
        let createGeochemOccurance = Utils.filterParams(values);

        createGeochemOccurance = Utils.filterCondition(createGeochemOccurance);
        createGeochemOccurance = Utils.addEnterer(createGeochemOccurance);
        createGeochemOccurance = Utils.addAudit(
          createGeochemOccurance,
          this.state.geologyInfo
        );
        let createGeochemBack = await Apis.createGeochem(createGeochemOccurance);
  
        if (createGeochemBack.result) {
          const modal = Modal.success({
            title: "This is a success message",
            content: "add successfully"
          });
          this.setState({
            geochemistryoccurrenceId: createGeochemBack.result.id,
            GeochemBase: createGeochemBack.result,
            geochemistryoccurrenceData: createGeochemBack.result
          });
          setTimeout(() => {
            modal.destroy();
          }, 2000);
        } else if (createGeochemBack.error) {
          Modal.error({
            title: "This is an error message",
            content: createGeochemBack.error
          });
          this.setState({
            geochemistryoccurrenceId: "",
            GeochemBase: {}
          });
        }
        
      }
   
    });
  };
  removeFile = async (file, index) => {
    let that = this;
    let { geochemistryoccurrenceId,uploadPath } = that.state;
   
    Modal.confirm({
      title: "Do you want to delete the file?",
      content: "Unrecoverable after deletion!!!",
      async onOk() {
        if (file.uid == -1) {
          let params = {
            id: geochemistryoccurrenceId,
            upload_path: uploadPath
          };
          let deleteFileBack = await Apis.deleteGeochemFile(params);

          if (deleteFileBack.result) {
            let { allFiles } = that.state;

            allFiles.splice(index, 1);
            that.setState({
              allFiles,
              uploadPath:''
            });
          } else {
            if (deleteFileBack.error) {
              Modal.error({
                content: deleteFileBack.error
              });
            }
          }
        } else {
          let { allFiles, uploadFiles } = that.state;

          allFiles.splice(index, 1);
          uploadFiles.splice(index, 1);
          that.setState({
            allFiles,
            uploadFiles,
            uploadPath:''
          });
        }
      },
      onCancel() {}
    });
  };

  onChangeFile = (file, isCover) => {
    
    let { uploadFiles } = this.state;
    let newest = file;

    this.setState(
      {
        uploadFiles: [newest],
        isCover
      },
      () => {
        this.setState({
          allFiles:this.state.uploadFiles,
          
        })
      }
    );
  };
  confirmUploadFile = async () => {
    let { geochemistryoccurrenceId } = this.state;
    const formData = new FormData();
    formData.append("geochemistryoccurrence_id", geochemistryoccurrenceId);
    let { uploadFiles } = this.state;
    if (uploadFiles.length > 0) {
      uploadFiles.map((item, index) => {
        formData.append("files", item);
      });
    }
    let enterer = Storage.getItem("gbdbid");
    if (this.state.isCover === 1) {
      formData.append("isCover", this.state.isCover);
    }
    if (enterer) {
      formData.append("enterer", enterer);
    }
    let uploadBack = await Apis.uploadGeochemData(formData);
    if(uploadBack.error){
      Modal.error({
        title: "This is an error message",
        content: uploadBack.error
      });
    }else{
      const modal = Modal.success({
        title: "This is a success message",
        content: "successfully upload "
      });
      setTimeout(() => {
        modal.destroy();
        this.setState({
          uploadFileModal: false,
          uploadFiles: [],
          uploadPath:'',
          allFiles:[]
        });
      }, 1000);

    }
   
   
  };
  showUpload = async () => {
    let param = {
      id: this.state.geochemistryoccurrenceId
    };
    let createGeochemBack = await Apis.getGeochemicals(param);
    if (createGeochemBack.result) {
      let uploadPath = createGeochemBack.result[0].upload_path;
      if (uploadPath) {
        let oldFile = {};
        oldFile = {
          uid: -1,
          name: uploadPath,
          status: "done",
          url: `/api` + uploadPath,
          data: `/api` + uploadPath,
          linkProps: {"download":  ''}
        };
        this.setState({
          uploadPath,
          // uploadFiles:[oldFile],
          defaultFiles: [oldFile],
          // allFiles: [oldFile]
        });
      }
    }
    this.setState({
      uploadFileModal: true,
      uploadFiles: []
    });
  };
  uploadGeochem = () => {
    this.setState({
      uploadFileModal: false
    });
  };

  cancelUpload = () => {
    this.setState({
      uploadFileModal: false
    });
  };

  goFormation = () => {
    let { inheritRefIds, geographyId, baseId } = this.state;
    let url = "/section/formation?";
    let refUrl = inheritRefIds ? "refId=" + inheritRefIds : "";
    let geographyUrl = geographyId ? "&geographyId=" + geographyId : "";
    let baseIdUrl = baseId ? "&baseId=" + baseId : "";

    let params = `${url}${refUrl}${baseIdUrl}${geographyUrl}`;

    this.props.history.push(params);
  };
  viewGeochemical = () => {
    let url = "/views/geochemical";
    this.props.history.push(url);
  };
  showUploadForm = () => {
    this.setState({
      addFormModal: true
    });
  };

  uploadGeochemForm = () => {
    this.setState({
      addFormModal: false
    });
  };
  cancelUploadForm = () => {
    this.setState({
      addFormModal: false
    });
  };

  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };
  clickRefId = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };
  onRef = ref => {
    this.child = ref;
  };
  onBase = ref => {
    this.baseChild = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true
    });

    //点击 detail 按扭执行代码
  };
  geographyDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showSectionDetailForm: true
    });
  };
  handFormOk = () => {
    this.setState({
      sectionOptionVisible: false
    });
  };
  handleFormCancel = () => {
    this.setState({
      sectionOptionVisible: false
    });
    Modal.destroyAll();
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  checkAgain = optionType => {
    // let { allChildError } = this.state;

    // if (JSON.stringify(allChildError) !== "{}") {
    //   Modal.warn({
    //     content: "Please check the query form"
    //   });
    //   return;
    // }
    let showType = "refSearch";

    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (optionType === "reference") {
        showType = "refSearch";
      } else if (optionType === "geochemistry") {
        showType = "geochemistrySearch";
      } else {
        showType = "geographySearch";
      }

      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            sectionOptionVisible: false,
            geochemistryVisible: false,
            [showType]: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues, sectionType } = this.state;

              if (optionType === "reference") {
                this.child.searchReferenceDatas(searchParams, searchValues);
              } else if (optionType === "geochemistry") {
                this.geochemialChild.searchSectionDatas(searchParams, {});
              } else {
                this.baseChild.searchSectionDatas(
                  searchParams,
                  searchValues,
                  sectionType
                );
              }
            }, 20);
          }
        );
      }
    });
  };
  showSearchGeography = (type, e) => {
    e.target.blur();

    this.setState(
      {
        sectionType: type
      },
      () => {
        this.setState({
          sectionOptionVisible: true
        });
      }
    );
  };
  handleGeographyOk = () => {
    this.setState({
      geographySearch: false
    });
  };
  handleGeographyCancel = () => {
    this.setState({
      geographySearch: false
    });
  };
  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }));
  };
  clickGeographyId = (type, value) => {
    this.setState({
      geologyInfo: value,
      geographySearch: false
    });
    this.props.form.setFieldsValue({
      geology_id: value.geology_id || "",
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || ""
    });
  };
  clickSectionId = value => {
    this.setState({
      baseInfo: value,
      geographySearch: false
    });
    this.props.form.setFieldsValue({
      geology_id: value.geology_id || "",
      section_basic_id: value.section_id || "",
      ref_id: value.ref_id || ""
    });
  };
  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };

  checkBaseOne = value => {
    this.props.form.setFieldsValue({
      section_basic_id: value.section_id || "",
      ref_id: value.ref_id || "",
      geology_id: value.geology_id || ""
    });
    this.setState(
      {
        inheritRefIds: value.ref_id,
        baseId: value.section_id,
        geographyId: value.geology_id,

        geographySearch: false
      },
      async () => {
        let { geographyId } = this.state;

        if (geographyId) {
          let params = {
            geology_id: geographyId
          };
          this.getGeologyInfo(params);
        } else {
          this.setState(
            {
              geologyInfo: {}
            },
            () => {}
          );
        }
      }
    );
  };
  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType
    });
  };

  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailSectionFormOk = () => {
    this.setState({
      showSectionDetailForm: false
      // sectionType: "baseinfo"
    });
  };
  detailSectionFormCancel = () => {
    this.setState({
      showSectionDetailForm: false
      // sectionType: "baseinfo"
    });
  };
  geochemistryFormOk = () => {
    this.setState({
      geochemistryVisible: false
    });
  };
  geochemistryCancel = () => {
    this.setState({
      geochemistryVisible: false
    });
  };
  geochemistryShow = () => {
    this.setState({
      geochemistryVisible: true
    });
  };
  onGeochemial = ref => {
    this.geochemialChild = ref;
  };
  handleGeochemistryOk = () => {
    this.setState({
      geochemistrySearch: false
    });
  };
  handleGeochemistryCancel = () => {
    this.setState({
      geochemistrySearch: false
    });
  };
  getGeologyInfo = async params => {
    let getGeologyBack = await Apis.getGeology(params);

    if (getGeologyBack.result && getGeologyBack.result.length > 0) {
      this.setState(
        {
          geologyInfo: getGeologyBack.result ? getGeologyBack.result[0] : {}
        },
        () => {}
      );
    } else {
      if (getGeologyBack.error) {
        Modal.error({
          title: "This is an error message",
          content: getGeologyBack.error
        });
      }
    }
  };
  checkGeochemistryOne = geochemistry => {

    this.setState(
      {
        baseId: geochemistry.section_basic_id,
        geochemistryoccurrenceId: geochemistry.id,
        inheritRefIds: geochemistry.ref_id,
     
        geochemistrySearch: false
      },
      () => {
        this.props.form.setFieldsValue({
          section_basic_id: geochemistry.section_basic_id || ""
        });
        let geologyInfo = {
          geology_latitude_decimal: geochemistry.geology_latitude_decimal,
          geology_longitude_decimal: geochemistry.geology_longitude_decimal,
          geology_country: geochemistry.geology_country,
          geology_province: geochemistry.geology_province
        };
        this.setState({
          geologyInfo
        });
      }
    );
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let {
      inheritRefIds,
      geologyInfo,
      baseId,

      geochemistryoccurrenceId,
      geochemistryoccurrenceData,
      searchParams,
      sectionOptionVisible,
      sectionType,
      searchValues,
      geochemistryVisible
    } = this.state;

    return (
      <div id="sectionGeochemical" className="section-common">
        <div className="sectionFormation-mian">
          <div className="section-common-title form-common-title">
            Add Geochemical Data
          </div>

          <div className="sectionFormation-form">
            <Form>
              <div className="referenceAdd-title">
                <Form.Item label="Reference ID">
                  {getFieldDecorator("ref_id", {
                    rules: [{ type: "number" }],
                    initialValue:
                      inheritRefIds !== "" ? Number(inheritRefIds) : ""
                  })(
                    <InputNumber
                      onFocus={this.showSearchRef}
                      disabled
                    ></InputNumber>
                  )}
                </Form.Item>
              </div>
              <div className="referenceAdd-title">
                <Form.Item label="Section ID">
                  {getFieldDecorator("section_basic_id", {
                    rules: [{ type: "number" }],
                    initialValue: baseId !== "" ? Number(baseId) : ""
                  })(
                    <InputNumber
                      // className="inherit-input"
                      onFocus={e => this.showSearchGeography("baseinfo", e)}
                    ></InputNumber>
                  )}
                </Form.Item>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume geochemical-country">
                  <Form.Item
                    label={
                      <div className="sectionGeography-scale">
                        <p>Latitude Decimal</p>
                      </div>
                    }
                  >
                    {getFieldDecorator("geology_latitude_decimal", {
                      rules: [{ type: "number" }],
                      initialValue: geologyInfo
                        ? geologyInfo.geology_latitude_decimal
                        : ""
                    })(<InputNumber disabled={true}></InputNumber>)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no geochemical-country">
                  <Form.Item
                    label={
                      <div className="sectionGeography-scale">
                        <p>Longtitude Decimal</p>
                      </div>
                    }
                  >
                    {getFieldDecorator("geology_longitude_decimal", {
                      rules: [{ type: "number" }],
                      initialValue: geologyInfo
                        ? geologyInfo.geology_longitude_decimal
                        : ""
                    })(<InputNumber disabled={true}></InputNumber>)}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceAdd-volumeNo">
                <div className="referenceAdd-volume geochemical-country">
                  <Form.Item label="Country/Continent">
                    {getFieldDecorator("country", {
                      rules: [
                        // {
                        //   required: true,
                        //   message: "Please input the Country/Continent"
                        // }
                      ],
                      initialValue: geologyInfo
                        ? geologyInfo.geology_country
                        : ""
                    })(<Input disabled={true} />)}
                  </Form.Item>
                </div>
                <div className="referenceAdd-no geochemical-country">
                  <Form.Item label="Province/State">
                    {getFieldDecorator("province", {
                      rules: [
                        {
                          // required: true,
                          // message: "Please input the Province/State"
                        }
                      ],
                      initialValue: geologyInfo
                        ? geologyInfo.geology_province
                        : ""
                    })(<Input disabled={true} />)}
                  </Form.Item>
                </div>
              </div>
              <div className="referenceAdd-publication">
                <Form.Item label="Release Date">
                  {getFieldDecorator("release_date", {
                    rules: [{ type: "object" ,required: true}],
                    initialValue: moment()
                    // initialValue: null
                  })(
                    <DatePicker 
                    format={"YYYY-MM-DD"}
                    allowClear={false}
                    disabledDate={Utils.disabledDate}></DatePicker>
                  )}
                </Form.Item>
              </div>
              <div className=" referenceAdd-volumeNo  geochemical-upload-btns">
                <div className="referenceAdd-no">
                  <div className="geochemical-search">
                    <Button
                      type="gbdb"
                      title="search existing geochemical occurrence"
                      onClick={() => this.geochemistryShow()}
                    >
                      <p>search existing geochemical occurrence </p>
                    </Button>
                  </div>
                </div>
                <div className="referenceAdd-volume">
                  <div className="geochemical-search">
                    <Button
                      type="gbdb"
                      disabled={this.state.baseId ? false : true}
                      onClick={() => this.AddgeochemicalBase()}
                    >
                      <p>Add new geochemical occurrence</p>
                    </Button>
                    {this.state.baseId ? null : (
                      <p className="geochemical-uploadtip">
                        input geochemistry first
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="referenceAdd-volumeNo  geochemical-upload-btns">
                <div className="referenceAdd-volume">
                  <div className="geochemical-search">
                    <Button
                      type="gbdb"
                      disabled={geochemistryoccurrenceId ? false : true}
                      onClick={() => this.showUpload()}
                    >
                      <p>Upload geochemical</p>
                    </Button>
                    <p className="geochemical-uploadtip">
                      {geochemistryoccurrenceId
                        ? ""
                        : "input geochemistry  first"}
                    </p>
                  </div>
                </div>
                <div className="referenceAdd-no">
                  <div className="geochemical-search">
                    <Button
                      type="gbdb"
                      disabled={geochemistryoccurrenceId ? false : true}
                      onClick={() => this.showUploadForm()}
                    >
                      <p>Upload geochemical manually</p>
                    </Button>
                    <p className="geochemical-uploadtip">
                      {geochemistryoccurrenceId
                        ? ""
                        : "input geochemistry  first"}
                    </p>
                  </div>
                </div>
              </div>

              {/* 删掉的view */}

              <div className="referenceSearch-btns sectionBase-btns">
                <div className="formation-complete-btns">
                  {/* <Button type="gbdb" onClick={() => this.viewGeochemical()}>
                    <div>
                      <p> View Data</p>
                    </div>
                  </Button> */}
                  <Button type="gbdb" onClick={() => this.goFormation()}>
                    <div>
                      <p> Continue</p>
                    </div>
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <div className="geochemical-upload-file">
          <Modal
            destroyOnClose
            className="searchResultModal taxResultModal geochemical-upload-modal"
            width="78.6%"
            title={null}
            footer={null}
            visible={this.state.uploadFileModal}
            onOk={this.uploadGeochem}
            onCancel={this.cancelUpload}
          >
            <div className="geochemical-upload-content">
              <p className="geochemical-upload-title">
                Geochemical data type supported
              </p>

              <div className="geochemical-upload-icon">
                <BaseUpload
                  fileList={this.state.allFiles}
                  uploadFiles={this.state.uploadFiles}
                  multiple={false}
                  uploadTypes={[".xls", ".xlsx"]}
                  showImg={false}
                  listType="text"
                  showUploadList={true}
                  hasFile={this.state.uploadPath ? true : false}
                
                  onChangeFile={(file, isCover) =>
                    this.onChangeFile(file, isCover)
                  }
                  removeFile={(file, index) => this.removeFile(file, index)}
                ></BaseUpload>
              </div>
         

              <div className="geochem-upload-btn">
                <Button
                  type="gbdb"
                  disabled={this.state.uploadFiles.length > 0 ? false : true}
                  onClick={() => this.confirmUploadFile()}
                >
                  <div>
                    <p> Upload</p>
                  </div>
                </Button>
                {this.state.uploadFiles.length <= 0 ? (
                  <p className="upload-csv-tip">Please upload a file</p>
                ) : null}
              </div>

              <div className="geochem-upload-tips">
                <p>Tip: Only supoort excel files.</p>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            destroyOnClose
            className="searchResultModal taxResultModal"
            width="78.6%"
            title={null}
            footer={null}
            visible={this.state.addFormModal}
            // visible={true}
            onOk={this.uploadGeochemForm}
            onCancel={this.cancelUploadForm}
          >
            {/* <div className="reference-search-form search-common-form geochemical-upload-form"> */}
            <div className="reference-search-content reference-search-baseform">
              <AddGeochemicalForm
                isAdd={true}
                sectionId={baseId}
                inheritRefIds={inheritRefIds}
                geochemistryoccurrenceData={geochemistryoccurrenceData}
                geochemistryoccurrenceId={geochemistryoccurrenceId}
              ></AddGeochemicalForm>
            </div>
            {/* </div> */}
          </Modal>
        </div>

        <div>
          <Modal
            destroyOnClose
            visible={this.state.refCondition}
            className="searchResultModal taxResultModal"
            width="76%"
            footer={null}
            header={null}
            onOk={this.handlerefConditionOk}
            onCancel={this.handlerefConditionCancel}
          >
            {/* <RefSearch clickRefId={this.clickRefId}></RefSearch> */}
            <ReferenceOption
              childError={this.childError}
              conditions={{}}
              ref="reference"
            ></ReferenceOption>

            {
              <div className="referenceSearch-btns">
                <div>
                  <Button
                    type="gbdb"
                    onClick={() => {
                      this.checkAgain("reference");
                    }}
                  >
                    <p> Search</p>
                  </Button>
                </div>
              </div>
            }
          </Modal>

          <Modal
            destroyOnClose
            visible={this.state.refSearch}
            className="searchResultModal taxResultModal searchListModal"
            width="76%"
            footer={null}
            header={null}
            onOk={this.handleRefOk}
            onCancel={this.handleRefCancel}
          >
            <ReferenceTableResult
              searchParams={searchParams}
              searchValues={{}}
              onRef={this.onRef}
              checkOne={true}
              checkRefOne={this.checkRefOne}
              getDetail={this.getDetail}
              onlySeeRef={true}
              // changeCheckSelected={this.changeCheckSelected}
            ></ReferenceTableResult>
          </Modal>

          <Modal
            title=""
            destroyOnClose
            visible={this.state.showRefDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76.8%"
            footer={null}
            // header={null}
            onOk={this.detailRefFormOk}
            onCancel={this.detailRefFormCancel}
          >
            <ReferenceDetailForm
              detailData={this.state.detailData}
              // searchSection={this.searchSection}
              onlySeeRef={true}
            ></ReferenceDetailForm>
          </Modal>

          {/* section */}

          <Modal
            className="searchResultModal"
            width="76%"
            visible={sectionOptionVisible}
            footer=""
            // closable={false}
            onOk={this.handFormOk}
            onCancel={this.handleFormCancel}
          >
            <SectionOption
              // conditions={conditions}
              showGeography={sectionType === "geology" ? true : false}
              showBase={sectionType === "baseinfo" ? true : false}
              showUnit={false}
              ref="baseinfo"
              childError={this.childError}
            ></SectionOption>

            {
              <div className="referenceSearch-btns">
                <div>
                  <Button
                    type="gbdb"
                    onClick={() => {
                      this.checkAgain("baseinfo");
                    }}
                  >
                    {<p> Search</p>}
                  </Button>
                </div>
              </div>
            }
          </Modal>
          <Modal
            className="searchResultModal"
            width="76%"
            visible={geochemistryVisible}
            footer=""
            // closable={false}
            onOk={this.geochemistryFormOk}
            onCancel={this.geochemistryCancel}
          >
            <GeochemicalOption
              // conditions={conditions}

              ref="geochemistry"
              childError={this.childError}
            ></GeochemicalOption>

            {
              <div className="referenceSearch-btns">
                <div>
                  <Button
                    type="gbdb"
                    onClick={() => {
                      this.checkAgain("geochemistry");
                    }}
                  >
                    <p> Search </p>
                  </Button>
                </div>
              </div>
            }
          </Modal>
          <Modal
            destroyOnClose
            visible={this.state.geochemistrySearch}
            className="searchResultModal taxResultModal noBottomPadding"
            width="76%"
            footer={null}
            header={null}
            onOk={this.handleGeochemistryOk}
            onCancel={this.handleGeochemistryCancel}
          >
            <GeochemicalTableResult
              searchParams={this.state.searchParams}
              searchValues={this.state.searchValues}
              onGeochemial={this.onGeochemial}
              changeCheckSelected={this.changeCheckSelected}
              checkGeochemistryOne={this.checkGeochemistryOne}
              checkOne={true}
              showDetail={false}
            ></GeochemicalTableResult>
          </Modal>
          <Modal
            destroyOnClose
            visible={this.state.geographySearch}
            className="searchResultModal taxResultModal noBottomPadding"
            width="76%"
            footer={null}
            header={null}
            onOk={this.handleGeographyOk}
            onCancel={this.handleGeographyCancel}
          >
            <BaseTableResult
              searchParams={searchParams}
              searchValues={searchValues}
              onBase={this.onBase}
              changeCheckSelected={this.changeCheckSelected}
              getDetail={this.geographyDetail}
              checkOne={true}
              checkBaseOne={this.checkBaseOne}
            ></BaseTableResult>
          </Modal>
          <Modal
            title=""
            destroyOnClose
            visible={this.state.showSectionDetailForm}
            className="searchResultModal taxResultModal DetailModal"
            width="76%"
            footer={null}
            // header={null}
            onOk={this.detailSectionFormOk}
            onCancel={this.detailSectionFormCancel}
          >
            {sectionType === "baseinfo" ? (
              <BaseDetailForm
                detailData={this.state.detailData}
                viewGeography={this.viewGeography}
                seeOnly={true}
              ></BaseDetailForm>
            ) : sectionType === "geology" ? (
              <GeographyDetailForm
                seeOnly={true}
                detailData={this.state.detailData}
                viewBase={this.viewBase}
              ></GeographyDetailForm>
            ) : null}
          </Modal>
        </div>
      </div>
    );
  }
}
SectionFormation = Form.create({})(SectionFormation);
export default SectionFormation;
