import React, { Component } from "react";
import { Menu, Form, Modal, Button, Input, Select } from "antd";
import Utils from "src/js/utils";
import folderApis from "src/js/apis/folder";
import groupApis from "src/js/apis/group";
import SubsetApis from "src/js/apis/subset";
import Storage from "src/js/storage";
import { NavLink, withRouter } from "react-router-dom";

import "./index.css";

const { SubMenu } = Menu;
const { confirm } = Modal;
const { Option } = Select;
@withRouter
class AllFolders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folderCondition: props.folderCondition || {},
      allFolders: [],
      childrenFolders: [],
      currentFolderKey: 0,
      folderType: props.folderType || "section",
      folderLevel: props.folderLevel || 1,
      parentFoldersObj: {},
      childrenFoldersObj: {},
      currentParentFolderName: "Default",
      currentParentIndex: 0,
      openKeys: [],
      openMoveKeys: [],
      rootSubmenuKeys: {},
      folderNames: {},
      myGroups: [],
      folderModal: false,
      isShare: 0,
      groupids: [],
      checkedObj: props.checkedObj || [],
    };
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.checkedObj) {
      this.setState({
        checkedObj: nextProps.checkedObj,
      });
    }
    if (nextProps.folderType !== this.props.folderType) {
      this.setState({
        folderType: nextProps.folderType,
      });
    }
    if (nextProps.folderLevel !== this.props.folderLevel) {
      this.setState({
        folderLevel: nextProps.folderLevel,
      });
    }
  };
  initFolders = async (params) => {
    this.setState(
      {
        askLoading: true,
      },
      async () => {
        let foldersBack = await folderApis.getFolders(params);
        if (foldersBack.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: foldersBack.error,
          });
          this.setState({
            askLoading: undefined,
          });
          setTimeout(() => {
            modal.destroy();
          }, 3000);
        } else {
          this.setState({
            askLoading: false,
            allFolders: foldersBack.result,
          });
        }
      }
    );
  };
  handFolderleOk = () => {
    this.setState({
      folderModal: false,
    });
  };
  handleFolderCancel = () => {
    this.setState({
      folderModal: false,
    });
  };
  showAddFolder = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let { folderLevel } = this.state;

    this.setState(
      {
        folderLevel,
        folderModal: true,
        isShare: 0,
      },
      () => {}
    );
  };
  createFolderOperate = (needAddLevel, createFolderBack) => {
    let createBack = createFolderBack.result;
    let createResult = {};
    let otherParams = {
      folder_second_count: 0,
      folder_level: needAddLevel,
      assetsCount: 0,
    };
    Object.assign(createResult, createBack, otherParams);
    let {
      allFolders,
      currentFolderKey,

      childrenFolders,
    } = this.state;

    if (needAddLevel === 2) {
      childrenFolders = [...childrenFolders, createResult];
      //为了获取操作的一级文件夹

      let currentFolder = this.state.parentFoldersObj[currentFolderKey];
      let deleteIndex = currentFolder.index;

      allFolders[deleteIndex].folder_second_count += 1;
      allFolders[deleteIndex].folder_level = 1;

      this.setState(
        {
          currentFolderKey: createBack.id,
          folderLevel: 2,
          childrenFolders,
          folderModal: false,
          openKeys: [currentFolderKey + "parent"],
          allFolders: allFolders,
          myGroups: [],
          isShare: 0,
          currentParentFolderName: this.state.currentParentFolderName,
        },
        () => {}
      );
    } else {
      // 如果添加的为一级文件夹
      allFolders = [...allFolders, createResult];
      this.setState(
        {
          folderLevel: 1,
          currentFolderKey: createBack.id,
          childrenFolders,
          folderModal: false,
          allFolders,
          myGroups: [],
          isShare: 0,
          currentParentFolderName: createBack.folder_name,
        },
        () => {}
      );
    }
  };
  confirmAddFolder = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let {
          currentFolderKey,
          folderLevel,

          currentParentFolderName,
        } = this.state;

        let uid = Storage.getItem("gbdbid");
        if (!uid) {
          this.props.history.push("/home");
        }
        let allParams = {};
        let parentParams = {};
        let baseParams = {
          uid,
          folder_type: Utils.folderTypeFun(this.state.folderType),
          folder_name: values.folder_name,
        };
        let needAddLevel = 1;
        let needAddParentkey = "";
        if (folderLevel === 1) {
          if (currentParentFolderName === "Default") {
            needAddLevel = 1;
            needAddParentkey = "";
          } else {
            needAddLevel = 2;
            needAddParentkey = currentFolderKey;
          }
        }
        if (needAddLevel == 2) {
          parentParams = { parent: needAddParentkey };
        } else {
          parentParams = {};
        }
        let levelParams = {
          folder_level: needAddLevel,
        };

        Object.assign(allParams, baseParams, parentParams, levelParams);

        let createFolderBack = await folderApis.createFolder(allParams);

        if (createFolderBack.error) {
          const modal = Modal.error({
            title: "This is an error message",
            content: createFolderBack.error,
          });
          setTimeout(() => {
            modal.destroy();
          }, 3000);
        } else {
          // 共享创建的文件夹
          // 如果是共享
          let lastParams = {};
          let { groupids } = this.state;
          let initParams = {
            uid: Storage.getItem("gbdbid"),
            folder_level: needAddLevel,
            folder: createFolderBack.result.id,
            groupids,
          };

          if (this.state.isShare === 1) {
            let shareBack = await folderApis.shareFolder(initParams);

            if (shareBack.result) {
              // 如果添加的为一级文件夹
              this.createFolderOperate(needAddLevel, createFolderBack);
            } else {
              Modal.error({
                content: shareBack.error,
              });
            }
          } else {
            this.createFolderOperate(needAddLevel, createFolderBack);
          }
        }
      }
    });
  };
  deleteFolderOperate = async (params) => {
    let { currentFolderKey, childrenFoldersObj, parentFoldersObj, allFolders, folderLevel, childrenFolders, currentParentIndex } = this.state;
    let deleteBack = await folderApis.deleteFolder(params);

    if (deleteBack.result) {
      if (folderLevel === 2) {
        let currentFolder = childrenFoldersObj[currentFolderKey];
        let deleteIndex = currentFolder.index;

        let parentOrder = currentParentIndex;

        --allFolders[parentOrder].folder_second_count;

        childrenFolders.splice(deleteIndex, 1);

        this.setState(
          {
            childrenFolders,
            allFolders,
            // 设置当前的文件夹为删除文件夹的父级
            folderLevel: 1,
            currentFolderKey: allFolders[parentOrder].id,
          },
          () => {}
        );
      } else if (folderLevel === 1) {
        let currentFolder = parentFoldersObj[currentFolderKey];
        let deleteIndex = currentFolder.index;
        allFolders.splice(deleteIndex, 1);

        this.setState(
          {
            allFolders,
            currentFolderKey: 0,
            currentParentFolderName: "Default",
            currentParentIndex: 0,
          },
          () => {
            // 删除后重新请求展示的的资源
          }
        );
      }
    } else {
      Modal.error({
        title: "This is an error message",
        content: deleteBack.error,
      });
    }
  };
  deleteFolder = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let that = this;
    let {
      currentFolderKey,

      folderLevel,
    } = that.state;

    confirm({
      title: "Are you sure delete this folder?",
      content: "",
      okText: "Yes",
      okType: "danger",
      okButtonProps: {
        //
      },
      cancelText: "No",
      // flag=1 保留资源
      onOk() {
        confirm({
          title: "Please read the instruction",
          content: "Do you want to delete the files under the folder?",
          okText: "Yes",
          okType: "danger",
          okButtonProps: {},
          cancelText: "No",
          async onOk() {
            let params = {
              id: currentFolderKey,
              flag: 0,
              folder_level: folderLevel,
            };
            that.deleteFolderOperate(params);
          },
          async onCancel() {
            let { currentFolderKey, folderObjs, allFolders } = that.state;
            let params = {
              id: currentFolderKey,
              flag: 1,
              folder_level: folderLevel,
            };

            that.deleteFolderOperate(params);
          },
        });
      },
      onCancel() {},
    });
  };
  clickParentFolder = async (e, type, itemValue, index) => {
    if (e) {
      e.domEvent.stopPropagation();
      e.domEvent.preventDefault();
    }

    if (type === "nochildren") {
      this.setState({
        openKeys: [],
      });
    }

    this.setState(
      {
        currentFolderKey: e.key.split("parent")[0],
        folderLevel: 1,
        currentParentFolderName: itemValue.folder_name,
        currentParentIndex: index,
        childrenFolders: [],
        childrenFoldersObj: {},
      },
      async () => {
        if (!Storage.getItem("gbdbid")) {
          this.props.history.push("/home");
        }
        let params = {
          parent: this.state.currentFolderKey,
          uid: Storage.getItem("gbdbid"),
          folder_type: Utils.folderTypeFun(this.state.folderType),
        };
        let getSecondBack = await folderApis.getSecondFolders(params);

        if (getSecondBack.result) {
          const childrenFolderLists = getSecondBack.result;

          this.setState(
            {
              childrenFolders: childrenFolderLists,
            },
            () => {
              if (this.props.currentOperateFolader) {
                this.props.currentOperateFolader(itemValue);
              }
            }
          );
        } else {
          Modal.error({
            content: "Folder fetch failed",
          });
        }
      }
    );
  };
  clickChildFolder = (e, itemValue) => {
    if (e) {
      e.domEvent.stopPropagation();
      e.domEvent.preventDefault();
    }
    this.setState(
      {
        currentFolderKey: e.key,
        folderLevel: 2,
      },
      () => {
        if (this.props.currentOperateFolader) {
          this.props.currentOperateFolader(itemValue);
        }
      }
    );
  };
  //目前展开则点击后关闭，没有的话，则展开
  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => this.state.openKeys.indexOf(key) === -1);
    this.setState(
      {
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      },
      () => {}
    );
  };
  clickMenu = async (e) => {

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!this.state.isVisit) {
      await this.setState(
        {
          currentFolderKey: 0,
          folderLevel: 1,
          openKeys: [],
          currentParentFolderName: "Default",
          currentParentIndex: 0,
        },
        () => {}
      );
    }
  };
  changeGroups = (value) => {
    this.setState(
      {
        groupids: value,
      },
      () => {}
    );
  };
  componentDidMount = () => {
    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }
    let uid = Storage.getItem("gbdbid");
    let params = {
      uid,
      folder_type: Utils.folderTypeFun(this.state.folderType),
      folder_level: this.state.folderLevel,
    };
    this.setState(
      {
        folderCondition: params,
      },
      () => {
        this.initFolders(this.state.folderCondition);
      }
    );
  };
  changeFolderType = (value) => {
    this.setState(
      {
        isShare: Number(value),
      },
      () => {
        this.getGroups();
      }
    );
  };
  getGroups = async () => {
    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }
    let uid = Storage.getItem("gbdbid");
    if (uid) {
      let params = {
        uid,
      };
      let myGroups = await groupApis.getGroups(params);

      if (myGroups.error) {
        Modal.error({
          title: "This is an error message",
          content: myGroups.error,
        });
      } else {
        this.setState({
          myGroups: myGroups.result,
        });
      }
    }
  };
  goGroupPage = () => {
    let url = "/group/create";
    const w = window.open("about:blank");
    w.location.href = url;
  };
  addSubsetToFoder = async (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let { checkedObj, currentFolderKey, folderLevel } = this.state;

    if (!Storage.getItem("gbdbid")) {
      this.props.history.push("/home");
    }
    let uid = Storage.getItem("gbdbid");
    let params = {
      assetstype: Utils.folderTypeFun(this.state.folderType),
      uid,
      ids: Object.keys(checkedObj),
      folder: currentFolderKey,
      folder_level: folderLevel,
    };
    let collectBack = await SubsetApis.collectAssets(params);
    if (collectBack.result) {
      if (this.props.closeCommonFolder) {
        this.props.closeCommonFolder();
      }
      Modal.success({
        title: "This is an successful message",
        content: "successfully added ",
      });
    } else {
      Modal.error({
        title: "This is an error message",
        content: collectBack.error,
      });
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;

    let {
      currentFolderKey,

      allFolders,

      childrenFolders,

      rootSubmenuKeys,
      parentFoldersObj,
      childrenFoldersObj,
      folderLevel,

      folderNames,
      myGroups,
    } = this.state;

    let value = "";
    if (folderLevel === 1) {
      value = currentFolderKey.toString() + "parent";
    } else {
      value = currentFolderKey.toString();
    }
    let selectedKeys = [value];

    return (
      <div className="all-common-folder">
        <div className="subset-folder" onClick={this.clickMenu}>
          <Menu className="subset-folder-menu" mode="inline" openKeys={this.state.openKeys} onOpenChange={this.onOpenChange} style={{ width: 256 }} selectedKeys={selectedKeys}>
            {allFolders.length > 0 && this.state.askLoading == false ? (
              allFolders.map((item, index) => {
                parentFoldersObj[item.id] = {
                  item: item,
                  index: index,
                };

                if (item.folder_level === 1 && item.folder_second_count !== 0) {
                  rootSubmenuKeys[item.id] = {
                    item,
                  };
                  return (
                    <SubMenu
                      key={item.id + "parent"}
                      datavalue={item}
                      title={
                        <span>
                          <span>{item.folder_name}</span>
                        </span>
                      }
                      onTitleClick={(e) => this.clickParentFolder(e, "", item, index)}
                    >
                      {childrenFolders.map((childrenItem, childrenIndex) => {
                        childrenFoldersObj[childrenItem.id] = {
                          item: childrenItem,
                          index: childrenIndex,
                          // parent: item,
                          // parentIndex: index
                        };
                        return (
                          <Menu.Item key={childrenItem.id.toString()} onClick={(e) => this.clickChildFolder(e, childrenItem)}>
                            {childrenItem.folder_name}
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  );
                } else {
                  return (
                    <Menu.Item key={item.id.toString() + "parent"} onClick={(e) => this.clickParentFolder(e, "nochildren", item, index)}>
                      {item.folder_name}
                    </Menu.Item>
                  );
                }
              })
            ) : this.state.askLoading == true ? (
              <div className="tablelist-no-resource subset-folder-color">Loading...</div>
            ) : allFolders.length === 0 && this.state.askLoading == false ? (
              <div className="tablelist-no-resource subset-folder-color">No Resource</div>
            ) : (
              <div className="tablelist-no-resource subset-folder-color"></div>
            )}
          </Menu>

          <div className="folder-operate-icons clearfix">
            <button
              className="iconfont icon-plus31 fl"
              title={folderLevel === 2 ? "The maximum folder level is two" : "add folder"}
              onClick={this.showAddFolder}
              disabled={folderLevel === 2 ? true : false}
            ></button>
            <button
              className="iconfont icon-minus_bordered fr"
              title={JSON.stringify(currentFolderKey) === "0" ? "The default folder cannot be deleted" : "delete folder"}
              disabled={JSON.stringify(currentFolderKey) === "0" || allFolders.length < 0 ? true : false}
              onClick={this.deleteFolder}
            ></button>
          </div>
          <div className="common-folder-footer">
            <Button
              type="gbdb"
              title={Object.keys(this.state.checkedObj).length == 0 ? "please select resource and folder" : ""}
              // ||this.state.currentFolderKey
              disabled={Object.keys(this.state.checkedObj).length == 0}
              onClick={this.addSubsetToFoder}
            >
              Add to subset
            </Button>
          </div>
        </div>
        <Modal destroyOnClose title="" visible={this.state.folderModal} onOk={this.handFolderleOk} onCancel={this.handleFolderCancel} footer={null} header={null}>
          <div>
            <Form layout="vertical" className="addfolder-form">
              <Form.Item label="Folder Name">
                {getFieldDecorator("folder_name", {
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        try {
                          if (!value) {
                            callback("Please input your Folder Name!");
                          } else if (folderNames.hasOwnProperty(value) || value === "Default") {
                            callback("Folder name repetition");
                          } else {
                            callback();
                          }
                        } catch (err) {
                          callback(err);
                        }
                      },
                      required: true,
                    },
                  ],
                })(<Input placeholder="Folder Name" />)}
              </Form.Item>
              <Form.Item label="Whether the public">
                {getFieldDecorator("isShare", {
                  rules: [
                    {
                      required: true,
                    },
                  ],
                  initialValue: "0",
                })(
                  <Select placeholder="Whether the public" onChange={this.changeFolderType}>
                    <Option key="0">Private</Option>
                    <Option key="1">Public</Option>
                  </Select>
                )}
              </Form.Item>
              {this.state.isShare === 1 && myGroups.length > 0 ? (
                <Form.Item label="Choose group to share">
                  {getFieldDecorator("groupid", {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(
                    <Select mode="multiple" style={{ width: "100%" }} placeholder="Please select" onChange={this.changeGroups}>
                      {myGroups.map((item, index) => {
                        return <Option key={item.id}>{item.name}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              ) : this.state.isShare === 1 && myGroups.length === 0 ? (
                <div>
                  You don't have a group,
                  <span className="folder-go-page" onClick={this.goGroupPage}>
                    Please create
                  </span>
                  {/* <NavLink to="/group/create"></NavLink> */}
                </div>
              ) : null}
            </Form>

            <div className="addgroup-form-operate">
              <Button type="gbdb" onClick={() => this.confirmAddFolder()} disabled={this.state.isShare === 1 && myGroups.length === 0}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

AllFolders = Form.create({})(AllFolders);
export default AllFolders;
