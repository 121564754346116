import * as d3 from 'd3';
import echarts from 'echarts'
import axios from 'axios'

let ancientMap = (function() {

    // let width = window.innerWidth-17,
    //     height =  window.innerHeight-60;
    
    

    let paleo_nav = {};

    // let projection = d3.geoEquirectangular()//设定投影函数 
    // //   .center([108.0, 37.5])// 函数是用于设定地图的中心位置，[107,31] 指的是经度和纬度。
    // //   .scale(200)//函数用于设定放大的比例。
    //   .translate([width/2, height/2]);//函数用于设定平移。

    let myChart = new Object();

    // var color = d3.schemeCategory10;
    // let padding = 0;

    let baseUrl = "";

    return {

       init:(searchName,callback)=>{

            paleo_nav = window.paleo_nav;
            if(document.getElementById('reconstructMap')){

                myChart = echarts.init(document.getElementById('reconstructMap'));
                myChart.on('click',function(params){
                    if(params.data){
                        window.formation_id=params.data.formation_id;
                        //调用Home组件中的方法，显示Modal
                        window.showInfoModal(params.data)
                       
                    }
                
                });
                myChart.on('mouseover',function(params){
                    if(params.data){
                        
                        window.setTotal(params.data.collection_count,params.data.fossilCount)
                        
                    }
                })
                myChart.on('mouseout',function(params){
                    if(params.data){
                        
                        window.clearTotal()
                    }
                })
                
                window.addEventListener('resize', myChart.resize);

                baseUrl = paleo_nav.serverUrl  + '/assets/map';

            d3.json(baseUrl + "/geoMaps/" + '230' + ".json")
                .then(function(geoJson) {
                    ancientMap.getPointData(searchName,geoJson,"","","",callback);
                    // ancientMap.drawPoint(geoJson,[])
                
                    // ancientMap.drawPoint(geoJson, [{data:testData,color:"rgba(154, 217, 221,.6)"},{data:testData2,color:"rgba(239, 67, 39,.6)"}])
                })  
                .catch((error)=>{
                   
                })
            }
        },
        getPointData(searchName,geoJson,age_from,age_to,time_name,callback){
          
            var makeColor=(c)=>{
                c=c.split('/').join(',');
                return `rgba(${c},0.7)`
            }
            //点数据处理
            var makeData=function(data){   
               
                //过滤无效数据
                var arr= data
                /*.filter(item=>{
                    return item.geo_latitude_decimal && item.geo_longitude_decimal
                })*/
                .map(item=>{
                    return {
                        name:`section No(${item.section_basic_id}) ${item.section_name?item.section_name:''}`,
                        value:[item.geo_longitude_decimal,item.geo_latitude_decimal],
                        id:item.formation_id,
                        color:makeColor(item.age_color),
                        collection_count:item.collection_count,
                        fossilCount:item.fossilCount
                    }
                })
                // 把点数据处理成echart格式
                var map = {};
                var pointData = [];
                for(var i = 0; i < arr.length; i++){
                    var ai = arr[i];
                    if(!map[ai.color]){
                       map[ai.color]=[]
                       map[ai.color].push(ai)
                    }else{
                       map[ai.color].push(ai)
                    }
                }
              
                var color=Object.keys(map)
                color.forEach(c=>{
                    pointData.push({color:c,data:map[c]})
                })

               
                return pointData
            }
           
            if(age_from && age_to){
                var sendData={
                    age_from,
                    age_to
                }
                // axios.get('/api/search/geologyPoints/now',{
                axios.get('/api/assets/map/points/ancient/'+time_name+'.json',{
                    params:sendData
                }).then(res=>{
                    // if(res.data.error===null){
                    if(res){
                        // 数据进行了二次加工后，不再需要makeData处理
                        // var pointData=makeData(res.data.result);
                        // let pointData = res.data;
                        
                        var pointData=[];
                        if(searchName){
                            console.log("searchName:"+searchName);
                            var searchNames=searchName.split(',')
                            console.log("searchNames:"+searchNames);
                            res.data.map(item=>{
                                var searchData=[];
                                item.data.map(data=>{
                                    var dataFlag=false;
                                    searchNames.map(searchName=>{
                                        if(data.name.match(searchName)){
                                            dataFlag=true;
                                        }
                                    })
                                    if(dataFlag){
                                        searchData.push(data);
                                    }
                                })
                               item.data=searchData;
                               pointData.push(item);
                            })
                        }else{
                            pointData=res.data;
                        }
                      
                       ancientMap.drawPoint(geoJson,pointData,callback);
                    }
                })
                .catch((error)=>{
                    alert("No data find!")
                    if(callback) callback(error)
                })
            }else{
                // axios.get('/api/search/geologyPoints/now').then(res=>{
                // axios.get('/api/assets/map/points/Phanerozoic.json').then(res=>{
                axios.get('/api/assets/map/points/ancient/Phanerozoic.json').then(res=>{
                    // if(res.data.error===null){
                    if(res){
                    // 数据进行了二次加工后，不再需要makeData处理
                        // var pointData=makeData(res.data.result)
                        // let pointData = res.data;

                        var pointData=[];
                        if(searchName){
                            // res.data.map(item=>{
                            //     var searchData=[];
                            //     item.data.map(data=>{
                            //         if(data.name.match(searchName)){
                            //             searchData.push(data);
                            //         }
                            //     })
                            //    item.data=searchData;
                            //    pointData.push(item);
                            // })
                            var searchNames=searchName.split(',')
                            res.data.map(item=>{
                                var searchData=[];
                                item.data.map(data=>{
                                    var dataFlag=false;
                                    searchNames.map(searchName=>{
                                        if(data.name.match(searchName)){
                                            dataFlag=true;
                                        }
                                    })
                                    if(dataFlag){
                                        searchData.push(data);
                                    }
                                })
                               item.data=searchData;
                               pointData.push(item);
                            })
                        }else{
                            pointData=res.data;
                        }

                       ancientMap.drawPoint(geoJson,pointData,callback)
                    }
                })
                .catch((error)=>{
                    alert("No data find!")
                    if(callback) callback(error)
                })
            }
        },
        
        // 古地理地图 地图渲染 geoJson：地图数据， points：点集
        drawPoint(geoJson,pointData,callback){
            myChart.clear();

              // let mapName = 'GBDB_'+ new Date().getTime();
              let mapName = 'GBDB';

            myChart.resize();
  
              echarts.registerMap(mapName, geoJson); //绘制古地理地图
  
            //   let series = ancientMap.getPointSeries(pointData);  //处理点数据

              let series = pointData;  //服务器处理后不再需要二次处理点数据
              let option = {
                  geo:{
                      name: 'GBDB PopEstimates',
                      roam: true,
                    //   center:[110, 29.71],
                      map: mapName,
                      scaleLimit:{
                        min:0.3,
                        max:60,
                      },
                      layoutSize:100,
                      itemStyle: {
                          areaColor: '#fff',
                          borderWidth:1,
                      },
                      emphasis:{  //高亮状态
                          itemStyle: {
                              borderWidth:1,
                              areaColor: '#eee',
                              color:'rgba(128, 128, 128, 0.5)',
                          }
                      }
                  },
                  tooltip : {
                    show:true,
                    formatter: function (params, ticket, callback) {
                        return params.data.name
                    },
                    trigger: 'item'
                    },
                  series: series
              };
           
              myChart.setOption(option,true);
              if(myChart.resize) myChart.resize()
              if(callback) callback()
            //   paleo_nav.hideLoading();
        },
       
         // 处理点
        getPointSeries:( pointData )=>{

            let series = [];

            for ( let item of pointData )
            {
                let {color,data} = item;
                let one = { // 散点配置
                    name: '数量',
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    data,
                    symbolSize: 12,
                    showEffectOn: 'emphasis',
                    rippleEffect: {
                        brushType: 'stroke'
                    },
                    hoverAnimation: true,
                    label: {
                        normal: {
                            formatter: '{b}',
                            position: 'right',
                            show: false
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    itemStyle: {
                        normal: {
                            color,
                        }
                    }
                };
                series.push(one);
            }
            return series;
        },
    
        resize:()=>{
            if(myChart.resize){
                myChart.resize();
            }
            
        },

        // 更新显示的点集 查询地图数据
        update: (searchName, data, callback )=>{
            if(myChart.resize) myChart.resize()

            let age = ancientMap.getAge(data.age_mid); 

           

            // 根据年代查询地图数据
            d3.json(baseUrl + "/geoMaps/" + age + ".json")
            .then(function(geoJson) {
               ancientMap.getPointData(searchName,geoJson,data.age_from,data.age_to,data.name,callback);
            })  
            .catch((error)=>{
              
                alert("地图数据加载失败！")
                paleo_nav.hideLoading();
                if(callback)callback(error)
            })
            

        },
       
        
        // 获取详细的年代
        getAge:( age )=>{

            if (age < 240) age = 230;
            if (age >= 240 && age < 255) age = 250;

            if (age >= 255 && age < 270) age = 260;
            if (age >= 270 && age < 290) age = 280;
            if (age >= 290 && age < 310) age = 300;
            if (age >= 310 && age < 330) age = 320;
            if (age >= 330 && age < 350) age = 340;
        
            if (age >= 350 && age < 370) age = 360;
            if (age >= 370 && age < 390) age = 380;
            if (age >= 390 && age < 415) age = 400;
            if (age >= 415 && age < 435) age = 430;
            if (age >= 435 && age < 442.5) age = 440;
            if (age >= 442.5 && age < 452.5) age = 445;
            if (age >= 452.5 && age < 470) age = 460;
            if (age >= 470 && age < 490) age = 480;
            if (age >= 490 && age < 510) age = 500;
            if (age >= 510 && age < 530) age = 520;
            if (age >= 530) age = 540;

            return age;
        },

        visible:false
    }

})()

export default ancientMap;
