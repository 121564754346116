import React, { Component } from "react";
import { Form, Input, Button, Radio, Select, Modal, InputNumber, AutoComplete } from "antd";
import Storage from "src/js/storage";
import { NavLink } from "react-router-dom";
import TaxonomyTableResult from "commons/AllTableList/Taxonomy/index.js";
import TaxonomyDetailForm from "commons/AllDetails/Taxonomy/index.js";
import dataApis from "src/js/apis/data";
import Apis from "src/js/apis/api";
import Utils from "src/js/utils";
import "./index.css";
const { Option } = Select;

class TaxonSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      genPrefix: [],
      spPrefix1: [],
      spPrefix2: [],
      spSuffix: [],
      name1Opts: [],
      name2Opts: [],
      nameType: "formal",
      showTaxonResult: false,
      resultList: [],
      genusPrefixV: "",
      spPrefix1V: "",
      spSuffixV: "",
      spDictV: "",
      spPrefix2V: "",
      combinationName: "",
      collectInfo: props.collectInfo || {},
      fossilOccurrence: {},
      taxonInfo: {},
      taxon_name1: "",
      taxon_name2: "",
      fossilistObj: props.fossilistObj || {},
    };
  }
  async componentDidMount() {
    let genPrefix = await dataApis.getAssLabels({ label_type: 1 });
    let spPrefix1 = await dataApis.getAssLabels({ label_type: 2 });
    let spPrefix2 = await dataApis.getAssLabels({ label_type: 3 });
    let spSuffix = await dataApis.getAssLabels({ label_type: 4 });
    this.setState(
      {
        genPrefix: genPrefix.result || [],
        spPrefix1: spPrefix1.result || [],
        spPrefix2: spPrefix2.result || [],
        spSuffix: spSuffix.result || [],
      },
      async () => {
        let { collectInfo } = this.state;
        let params = {
          collection_id: collectInfo.coll_id,
        };
        let fossilOccurrenceBack = await Apis.searchAllFossilOccurrence(params);

        if (fossilOccurrenceBack.result) {
          this.setState({
            fossilOccurrence: fossilOccurrenceBack.result[0],
          });
        } else {
          Modal.error({
            title: "This is an error message",
            content: fossilOccurrenceBack.error,
          });
        }
      }
    );
  }
  searchTaxons = async () => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      let searchConditions = Utils.filterParams(values);

      if (!err) {
        let taxonName1 = searchConditions.taxon_name1;
        let taxonName2 = searchConditions.taxon_name2;

        if (taxonName1 === undefined && taxonName2 === undefined) {
          Modal.error({
            title: "This is an error message",
            content: "Please enter query information",
          });
        } else {
          let spPrefix2V = "";

          let genusPrefixV = searchConditions.genus_prefix;

          let spPrefix1V = searchConditions.sp_prefix1;
          let spDictV = searchConditions.sp_name_dict;
          let spSuffixV = searchConditions.sp_suffix;

          if (spDictV === "cf." || spDictV === "aff." || spDictV === "ex gr.") {
            spPrefix2V = spDictV;
          }

          // 获取值

          this.setState({
            genusPrefixV: genusPrefixV === "Null" ? "" : genusPrefixV,
            spPrefix1V: spPrefix1V === "Null" ? "" : spPrefix1V,
            spSuffixV: spSuffixV === "Null" ? "" : spSuffixV,
            spDictV: spDictV === "Null" ? "" : spDictV,
            spPrefix2V: spPrefix2V === "Null" ? "" : spPrefix2V,
          });
          let params = {
            taxon_name1: searchConditions.taxon_name1,
            taxon_name2: searchConditions.taxon_name2,
          };
          this.setState(
            {
              searchParams: params,
              searchValues: searchConditions,
              showTaxonResult: true,
            },
            () => {
              setTimeout(() => {
                let { searchParams, searchValues } = this.state;
                let embellish = {
                  genusPrefixV,
                  spPrefix1V,
                  spSuffixV,
                  spDictV,
                  spPrefix2V,
                };
                this.taxonomyChild.searchTaxonomyDatas(
                  searchParams,
                  searchValues,

                  embellish
                );
              }, 20);
            }
          );
        }
      }
    });
  };
  taxonName1Search = async (value, type) => {
    if (value) {
      let params = {
        [type]: value,
      };
      let searchResult = await Apis.AutoCompleteTaxon(params);

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error,
        });
      } else if (searchResult.result) {
        if (type === "taxon_name1") {
          this.setState({ name1Opts: searchResult.result });
        } else if (type === "taxon_name2") {
          this.setState({ name2Opts: searchResult.result });
        }
      }
    } else {
      this.setState({ name1Opts: [], name2Opts: [] });
    }
  };
  taxonNameChange = (value, type) => {
    this.setState(
      {
        combinationName: "",
        taxonInfo: {},
      },
      () => {
        this.props.form.setFieldsValue({
          [type]: value,
        });
      }
    );
  };
  nameTypeChange = (e) => {
    this.setState({
      nameType: e.target.value,
    });
  };

  checkTaxonomyOne = (value, combinationName) => {
    this.setState({
      showTaxonResult: false,
      combinationName,
      taxonInfo: value,
      taxon_name1: value.taxon_name1,
      taxon_name2: value.taxon_name2,
    });
  };

  addFossil = async (type) => {
    let { genusPrefixV, spPrefix1V, spSuffixV, spDictV, spPrefix2V, collectInfo, fossilOccurrence, taxonInfo } = this.state;
    let params = {};
    if (type === "informal") {
      let values = this.props.form.getFieldsValue(["informal_name"]);
      if (values.informal_name) {
        params.informal_name = values.informal_name;
      }
    } else {
      let values = this.props.form.getFieldsValue(["comment", "organor_form", "numberof_specimen", "precision"]);
      if (taxonInfo && taxonInfo.taxon_name1) {
        params.taxon_name1 = taxonInfo.taxon_name1;
      }
      if (taxonInfo && taxonInfo.taxon_name2) {
        params.taxon_name2 = taxonInfo.taxon_name2;
      }
      if (genusPrefixV) {
        params.genus_prefix = genusPrefixV;
      }
      if (spPrefix1V) {
        params.sp_prefix1 = spPrefix1V;
      }
      if (spPrefix2V) {
        params.sp_prefix2 = spPrefix2V;
      }
      if (spDictV) {
        params.sp_name_dict = spDictV;
      }
      if (spSuffixV) {
        params.sp_suffix = spSuffixV;
      }

      for (let i in values) {
        if (values[i]) {
          params[i] = values[i];
        }
      }

      params.taxonmixed_id = taxonInfo.id;
    }

    let occurrenceId = fossilOccurrence ? fossilOccurrence.id : "";

    let collectionId = fossilOccurrence ? fossilOccurrence.collection_id : "";
    let geologyId = fossilOccurrence ? fossilOccurrence.geology_id : "";
    let { combinationName } = this.state;
    if (occurrenceId) {
      let param = {};

      let addParam = {
        collection_id: collectionId,
        geology_id: geologyId,
        fossiloccurrence_id: occurrenceId,
        ref_id: collectInfo.ref_id,
        //enterer: collectInfo.enterer,
        enterer: Storage.getItem("gbdbid"),
        fullfossil_name: combinationName,
      };
      addParam = Utils.addAudit(addParam, fossilOccurrence);
      let section_basic_id = "";

      if (geologyId !== "" && geologyId !== null && geologyId !== undefined) {
        let getGeologyBack = await Apis.getGeology({ geology_id: geologyId });

        if (getGeologyBack.result && getGeologyBack.result.length > 0) {
          section_basic_id = getGeologyBack.result[0].section_basic_id;
        } else {
          if (getGeologyBack.error) {
            Modal.error({
              title: "This is an error message",
              content: getGeologyBack.error,
            });
          }
        }
      }
      let sectionIdParams = {};
      if (section_basic_id) {
        sectionIdParams = {
          section_basic_id,
        };
      }

      Object.assign(param, params, addParam, sectionIdParams);

      let createFossilBack = await Apis.createFossil(param);

      if (createFossilBack.result) {
        Modal.success({
          title: "This is a successful message",
          content: "add successfully",
        });
        let itemFossil = {};
        let names = {
          taxon_name1: this.state.taxon_name1,
          taxon_name2: this.state.taxon_name2,
        };

        let result = createFossilBack.result;
        Object.assign(itemFossil, names, result);

        this.props.AddTaxonomies(itemFossil, combinationName);
      } else {
        Modal.error({
          title: "This is an error message",
          content: createFossilBack.error,
        });
      }
    } else {
      Modal.error({
        title: "This is an error message",
        content: "No fossil occurrence found ",
      });
    }
  };

  searchTaxonCancel = () => {
    this.setState({
      showTaxonResult: false,
    });
  };
  onTaxonomy = (ref) => {
    this.taxonomyChild = ref;
  };
  handleTaxonOk = () => {
    this.setState({
      showTaxonResult: false,
    });
  };
  detailTaxonomyFormOk = () => {
    this.setState({
      showTaxonomyDetailForm: false,
    });
  };
  detailTaxonomyFormCancel = () => {
    this.setState({
      showTaxonomyDetailForm: false,
    });
  };

  TaxonomyDetail = (itemValue) => {
    this.setState({
      detailData: itemValue,
      showTaxonomyDetailForm: true,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const name1Opts = this.state.name1Opts.map((item, indx) => <Option key={item.taxon_name1}>{item.taxon_name1}</Option>);
    const name2Opts = this.state.name2Opts.map((item, indx) => <Option key={item.taxon_name2}>{item.taxon_name2}</Option>);
    let {
      genPrefix,
      spPrefix1,
      spPrefix2,
      spSuffix,

      nameType,

      combinationName,

      searchParams,
      searchValues,
    } = this.state;

    return (
      <div className="search-taxons-comp">
        <div className="search-taxons-title">Taxon Name</div>
        <div className="name-type-change">
          <Radio.Group onChange={this.nameTypeChange} value={nameType}>
            <Radio value={"formal"}>Formal name</Radio>
            <Radio value={"informal"}>Informal name </Radio>
          </Radio.Group>
        </div>
        {nameType === "formal" ? (
          <div>
            <Form layout="vertical" className="taxonSearch-form">
              <div className="taxonSearch-form-div">
                <Form.Item label="">
                  {getFieldDecorator(
                    "genus_prefix",
                    {}
                  )(
                    <Select placeholder="genus prefix">
                      {genPrefix.map((item, index) => {
                        return (
                          <Option value={item.label_name} key={item.label_name}>
                            {item.label_name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="genus or higher">
                  {getFieldDecorator(
                    "taxon_name1",
                    {}
                  )(
                    <AutoComplete placeholder="taxon name1" onSearch={(value) => this.taxonName1Search(value, "taxon_name1")} onChange={(value) => this.taxonNameChange(value, "taxon_name1")}>
                      {name1Opts}
                    </AutoComplete>
                  )}
                </Form.Item>
              </div>

              <div className="taxonSearch-form-div">
                <Form.Item label="">
                  {getFieldDecorator(
                    "sp_prefix1",
                    {}
                  )(
                    <Select placeholder="sp prefix1">
                      {spPrefix1.map((item, index) => {
                        return (
                          <Option value={item.label_name} key={item.label_name}>
                            {item.label_name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="">
                  {getFieldDecorator(
                    "sp_name_dict",
                    {}
                  )(
                    <Select id="dict" placeholder="sp_name dict">
                      {spPrefix2.map((item, index) => {
                        return (
                          <Option value={item.label_name} key={item.label_name}>
                            {item.label_name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Species">
                  {getFieldDecorator(
                    "taxon_name2",
                    {}
                  )(
                    <AutoComplete placeholder="taxon name2" onSearch={(value) => this.taxonName1Search(value, "taxon_name2")} onChange={(value) => this.taxonNameChange(value, "taxon_name2")}>
                      {name2Opts}
                    </AutoComplete>
                  )}
                </Form.Item>
                <Form.Item label="">
                  {getFieldDecorator(
                    "sp_suffix",
                    {}
                  )(
                    <Select placeholder="sp suffix">
                      {spSuffix.map((item, index) => {
                        return (
                          <Option value={item.label_name} key={item.label_name}>
                            {item.label_name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="taxonSearch-btns">
                <Button type="gbdb" onClick={this.searchTaxons}>
                  Search
                </Button>
                <Button type="gbdb">
                  <NavLink to="/taxonomy/addTaxon" target="_blank">
                    Add new taxon
                  </NavLink>
                </Button>
              </div>

              <div className="combinationName">
                <span>
                  Taxon Fullname:
                  {combinationName ? <span>{combinationName}</span> : <span className="organ-tips">please select taxon first</span>}
                </span>
              </div>

              <div className="taxonSearch-form-div">
                <Form.Item label="Organ or form**:	">{getFieldDecorator("organor_form", {})(<Input placeholder="organor form"></Input>)}</Form.Item>
                <p className="organ-tips">**Organ: necessary for fossil plants; form: such as forma A, wide form.</p>
              </div>

              <div className="taxonSearch-form-div">
                <Form.Item label="Number of specimens:">
                  {getFieldDecorator("numberof_specimen", {
                    //  rules: [{ type: "number", message: "number" }],
                  })(<InputNumber placeholder="numberof specimen" min={0}></InputNumber>)}
                </Form.Item>
                <Form.Item label="Precision">
                  {getFieldDecorator(
                    "precision",
                    {}
                  )(
                    <Select placeholder="precision">
                      <Option value="low">Low</Option>
                      <Option value="high">High</Option>
                      <Option value="unkonw">Unkonw</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div>
                <Form.Item label="Comment" className="taxonName-search-view">
                  {getFieldDecorator("comment", {})(<Input placeholder="The maximum length is 26"></Input>)}
                </Form.Item>
              </div>
            </Form>
            <div className="taxonSearch-add-fossil">
              <Button type="gbdb" onClick={() => this.addFossil("formal")} disabled={combinationName !== "" ? false : true}>
                Add fossil and close
              </Button>
            </div>

            <Modal
              destroyOnClose
              visible={this.state.showTaxonResult}
              className="searchResultModal taxResultModal noBottomPadding tableListModal"
              width="76%"
              footer={null}
              header={null}
              onOk={this.handleTaxonOk}
              onCancel={this.searchTaxonCancel}
            >
              <TaxonomyTableResult
                searchParams={searchParams}
                searchValues={searchValues}
                onTaxonomy={this.onTaxonomy}
                changeCheckSelected={this.changeCheckSelected}
                getDetail={this.TaxonomyDetail}
                checkOne={true}
                checkTaxonomyOne={this.checkTaxonomyOne}
                fossilistObj={this.state.fossilistObj}
              ></TaxonomyTableResult>
            </Modal>
            <Modal
              title=""
              destroyOnClose
              visible={this.state.showTaxonomyDetailForm}
              className="searchResultModal taxResultModal DetailModal"
              width="76.8%"
              footer={null}
              // header={null}
              onOk={this.detailTaxonomyFormOk}
              onCancel={this.detailTaxonomyFormCancel}
            >
              <TaxonomyDetailForm detailData={this.state.detailData} onlySeeRef={true} seeOnly={true}></TaxonomyDetailForm>
            </Modal>
          </div>
        ) : (
          <div>
            <Form layout="vertical" className="taxonSearch-form">
              <div className="taxonSearch-form-div">
                <Form.Item label="Informal name*:	">{getFieldDecorator("informal_name", {})(<Input placeholder="informal name"></Input>)}</Form.Item>
                <p className="organ-tips">*Such as diplograptid</p>
              </div>
            </Form>
            <div className="taxonSearch-add-fossil">
              <Button type="gbdb" onClick={() => this.addFossil("informal")}>
                Add fossil and close
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
TaxonSearch = Form.create({})(TaxonSearch);
export default TaxonSearch;
