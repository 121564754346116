import React, { Component } from "react";
import Utils from "src/js/utils";



import Apis from "src/js/apis/api";
import dataApis from "src/js/apis/data";
import Storage from "src/js/storage";
import AddGeochemicalForm from "components/Section/geochemical/addGeochemicalForm.js";
import "./index.css";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Modal,
  InputNumber,
  AutoComplete,
  Radio
} from "antd";
import moment from "moment";

import ReferenceOption from "commons/AllOptions/Reference/index.js";
import ReferenceTableResult from "commons/AllTableList/Reference/index.js";
import ReferenceDetailForm from "commons/AllDetails/Reference/index.js";
import { withRouter } from "react-router-dom";
import SectionOption from "commons/AllOptions/Section/index.js";
import BaseDetailForm from "commons/AllDetails/Section/Base/index.js";
import GeographyDetailForm from "commons/AllDetails/Section/Geography/index.js";

import BaseTableResult from "commons/AllTableList/Section/Base/index.js";
const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
@withRouter
class GeochemicalOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inheritRefIds: "",

      geologyInfo: {},

      baseId: "",
      geographyId: "",

      geochemistryoccurrenceId: "",
      geochemistryoccurrenceData: {},
      allChildError: {},

      authorizers: [],
      enterers: [],
      durations: [],
      Countrys: [],
      Provinces: [],
      Villages: [],
      Locations: [],
      Intervals: [],
      Intages: [],

      searchAllValues: props.searchAllValues || {},
      createdAtfrom: props.searchAllValues ? props.searchAllValues.createdAt_from : null,
      createdAtto: props.searchAllValues ? props.searchAllValues.createdAt_to : null,

      reffrom: props.searchAllValues ? props.searchAllValues.ref_id_from : "",
      refto: props.searchAllValues ? props.searchAllValues.ref_id_to : "",

      latitudefrom: props.searchAllValues ? props.searchAllValues.geology_latitude_decimal_from : "",
      latitudeto: props.searchAllValues ? props.searchAllValues.geology_latitude_decimal_to : "",

      longitudefrom: props.searchAllValues ? props.searchAllValues.geology_longitude_decimal_from : "",
      longitudeto: props.searchAllValues ? props.searchAllValues.geology_longitude_decimal_to : "",

      sectionfrom: props.searchAllValues
      ? props.searchAllValues.section_basic_id_from
      : "",
    sectionto: props.searchAllValues
      ? props.searchAllValues.section_basic_id_to
      : "",
    };
  }
  componentDidMount = () => {};
  showSearchRef = e => {
    e.target.blur();
    this.setState({
      refCondition: true
    });
  };

  handlerefConditionOk = () => {
    this.setState({
      refCondition: false
    });
  };

  handlerefConditionCancel = () => {
    this.setState({
      refCondition: false
    });
  };
  handleRefOk = () => {
    this.setState({
      refSearch: false
    });
  };

  handleRefCancel = () => {
    this.setState({
      refSearch: false
    });
  };
  clickRefId = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };
  onRef = ref => {
    this.child = ref;
  };
  onBase = ref => {
    this.baseChild = ref;
  };
  getDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showRefDetailForm: true
    });

    //点击 detail 按扭执行代码
  };
  geographyDetail = itemValue => {
    this.setState({
      detailData: itemValue,
      showSectionDetailForm: true
    });
  };
  handFormOk = () => {
    this.setState({
      sectionOptionVisible: false
    });
  };
  handleFormCancel = () => {
    this.setState({
      sectionOptionVisible: false
    });
    Modal.destroyAll();
  };
  childError = allChildError => {
    this.setState({
      allChildError
    });
  };
  checkAgain = optionType => {
    let { allChildError } = this.state;

    if (JSON.stringify(allChildError) !== "{}") {
      Modal.warn({
        content: "Please check the query form"
      });
      return;
    }
    let showType = "refSearch";

    this.refs[optionType].validateFieldsAndScroll(async (err, searchValues) => {
      if (optionType === "reference") {
        showType = "refSearch";
      } else {
        showType = "geographySearch";
      }

      if (!err) {
        let searchParams = Utils.filterParams(searchValues);

        searchParams = Utils.filterCondition(searchParams);

        this.setState(
          {
            ResultVisible: false,
            sectionOptionVisible: false,
            [showType]: true,
            refCondition: false,
            searchParams,
            searchValues,
            optionType
          },
          () => {
            setTimeout(() => {
              let { searchParams, searchValues, sectionType } = this.state;

              if (optionType === "reference") {
                this.child.searchReferenceDatas(searchParams, searchValues);
              } else {
                this.baseChild.searchSectionDatas(
                  searchParams,
                  searchValues,
                  sectionType
                );
              }
            }, 20);
          }
        );
      }
    });
  };
  showSearchGeography = (type, e) => {
    e.target.blur();

    this.setState(
      {
        sectionType: type
      },
      () => {
        this.setState({
          sectionOptionVisible: true
        });
      }
    );
  };
  handleGeographyOk = () => {
    this.setState({
      geographySearch: false
    });
  };
  handleGeographyCancel = () => {
    this.setState({
      geographySearch: false
    });
  };
  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }));
  };
  clickGeographyId = (type, value) => {
    this.setState({
      geologyInfo: value,
      geographySearch: false
    });
    this.props.form.setFieldsValue({
      geology_id: value.geology_id || "",
      section_basic_id: value.section_basic_id || "",
      ref_id: value.ref_id || ""
    });
  };
  clickSectionId = value => {
    this.setState({
      baseInfo: value,
      geographySearch: false
    });
    this.props.form.setFieldsValue({
      geology_id: value.geology_id || "",
      section_basic_id: value.section_id || "",
      ref_id: value.ref_id || ""
    });
  };
  checkRefOne = value => {
    this.props.form.setFieldsValue({
      ref_id: value.ref_id || ""
    });
    this.setState({
      refSearch: false,
      inheritRefIds: value.ref_id,
      refInfo: value
    });
  };

  checkBaseOne = value => {

    this.props.form.setFieldsValue({
      section_basic_id: value.section_id || "",
      ref_id: value.ref_id || "",
      geology_id: value.geology_id || ""
    });
    this.setState(
      {
        inheritRefIds: value.ref_id,
        baseId: value.section_id,
        geographyId: value.geology_id,

        geographySearch: false
      },
      async () => {
        let { geographyId } = this.state;

        if (geographyId) {
          let params = {
            geology_id: geographyId
          };
          let getGeologyBack = await Apis.getGeology(params);

          if (getGeologyBack.result) {
            this.setState(
              {
                geologyInfo: getGeologyBack.result
                  ? getGeologyBack.result[0]
                  : {}
              },
              () => {}
            );
          } else {
            Modal.error({
              title: "This is an error message",
              content: getGeologyBack.error
            });
          }
        } else {
          this.setState(
            {
              geologyInfo: {}
            },
            () => {}
          );
        }
      }
    );
  };
  viewGeography = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType
    });
  };
  viewBase = (sectionId, sectionType) => {
    this.setState({
      sectionId: sectionId,
      showSectionDetailForm: true,
      sectionType
    });
  };

  detailRefFormOk = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailRefFormCancel = () => {
    this.setState({
      showRefDetailForm: false
    });
  };
  detailSectionFormOk = () => {
    this.setState({
      showSectionDetailForm: false
      // sectionType: "baseinfo"
    });
  };
  detailSectionFormCancel = () => {
    this.setState({
      showSectionDetailForm: false
      // sectionType: "baseinfo"
    });
  };

  handleNomSearch = async (value, type) => {
    if (value) {
      let searchResult;
      if (type === "authorizers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteExperts(params);
      } else if (type === "enterers") {
        let params = {
          username: value
        };
        searchResult = await dataApis.autoCompleteEnterer(params);
      } else if (type === "durations") {
        let params = {
          name: value
        };
        searchResult = await dataApis.autoCompleteChronostratigraphics(params);
      }

      if (searchResult.error) {
        Modal.error({
          title: "This is an error message",
          content: searchResult.error
        });

        return;
      } else if (searchResult.result) {
        this.setState(
          {
            [type]: searchResult.result
          },
          () => {}
        );
      }
    } else {
      this.setState({ [type]: [] });
    }
  };
  handleNomChange = (value, type) => {
    this.props.form.setFieldsValue(
      {
        [type]: value
      },
      () => {
        let searchType = this.props.form.getFieldValue("duration_search_type");
        let unitDuration = this.props.form.getFieldValue("unit_duration");

        if (
          type == "unit_duration" &&
          unitDuration !== "" &&
          searchType == ""
        ) {
          this.props.form.setFieldsValue({
            duration_search_type: "or"
          });
        }
        if (
          type == "unit_duration" &&
          unitDuration == "" &&
          searchType !== ""
        ) {
          this.props.form.setFieldsValue({
            duration_search_type: ""
          });
        }
      }
    );
  };

  sectionStartChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  sectionEndChange = (value, field, type) => {
    this.onSectionChange(value, field, type);
  };
  onSectionChange=()=>{}
  onSectionChange1 = (value, field, type) => {
    
    let stateType = field + type;
    let { allChildError } = this.state;
    this.setState(
      {
        [stateType]: value
      },
      () => {
        let compareFrom = field + "from";
        let compareTo = field + "to";

        let compareOne = this.state[compareFrom];
        let compareTwo = this.state[compareTo];

        let errorType = "error" + field;
        let helpType = "help" + field;
   

        if (
          (Utils.isEmpty(compareOne) && !Utils.isEmpty(compareTwo)) ||
          (Utils.isEmpty(compareTwo) && !Utils.isEmpty(compareOne))
        ) {
          this.setState(
            {
              [errorType]: true,
              [helpType]: "Start and end data need to be filled in"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
        } else if (compareTwo && compareOne && compareTwo < compareOne) {
          this.setState(
            {
              [errorType]: true,
              [helpType]: "The starting value is less than the ending value"
            },
            () => {
              allChildError[errorType] = errorType;
              if (this.props.childError) {
                this.props.childError(allChildError);
              }
            }
          );
         
        } else {
          this.setState(
            {
              [errorType]: false,
              [helpType]: ""
            },
            () => {
              if (allChildError[errorType]) {
                delete allChildError[errorType];
              }
            }
          );
        }
      }
    );
  };
  onStartChange = value => {
    this.onTimeChange("startValue", value);
    this.sectionStartChange(value, "createdAt", "from");
  };
  onEndChange = value => {
    this.onTimeChange("endValue", value);
    this.sectionStartChange(value, "createdAt", "to");
  };
  onTimeChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  geologySearch = async (value, type, key) => {
    let params = {
      attributes: [key],
      [key]: value
      // [key]: {
      //   condition: "includes",
      //   value
      // }
    };

    let geoSearchBack = await Apis.autoCompleteGeology(params);

    let searchReust = geoSearchBack.result || [];
    let resultArray = [];

    searchReust.map((item, index) => {
      resultArray.push(item[key]);
    });

    this.setState({
      [type]: resultArray
    });
  };
  geologyChange = (value, field) => {
    this.props.form.setFieldsValue({
      [field]: value
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let {

      searchAllValues,

      Countrys,
      Provinces,
      Villages,
      Locations,
      Intervals,
      Intages
    } = this.state;
    const authorizers = this.state.authorizers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    const enterers = this.state.enterers.map((item, index) => (
      <Option key={item.id}>{item.username}</Option>
    ));
    const durations = this.state.durations.map((item, index) => (
      <Option key={item.name}>{item.name}</Option>
    ));

    const intervals = Intervals.map((item, index) => (
      <Option
        value={item.interval_name}
        dataitem={item}
        key={item.interval_name}
      >
        {item.interval_name}
      </Option>
    ));
    const intages = Intages.map((item, index) => (
      <Option
        value={item.interval_name}
        dataitem={item}
        key={item.interval_name}
      >
        {item.interval_name}
      </Option>
    ));
    return (
      <div id="main" className="reference-search-form commonsection-search">
        <div className="searchSection-main  reference-search-form search-common-form">
          <div className="reference-search-content reference-search-baseform searchSection-form">
            <Form>
              <div>
                <div className="referenceSearch-base">
                  <div className="referenceSearch-autor referenceSearch-line">
                    <div className="referenceAdd-author-title">
                      Reference ID
                    </div>
                    <div className="referenceSearch-author searchReference-reference">
                      <span className="search-line-pre">
                        <FormItem
                          label=""
                          help={this.state.helpref}
                          validateStatus={
                            this.state.errorref ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("ref_id_from", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.ref_id_from
                              : ""
                          })(
                            <InputNumber
                              min={1}
                              placeholder=""
                              onChange={value =>
                                this.sectionStartChange(value, "ref", "from")
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      <span className="search-line">
                        <span className="iconfont icon-line1"></span>
                      </span>
                      <span>
                        <FormItem
                          label=""
                          help={this.state.helpref}
                          validateStatus={
                            this.state.errorref ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("ref_id_to", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.ref_id_to
                              : ""
                          })(
                            <InputNumber
                              min={1}
                              onChange={value =>
                                this.sectionEndChange(value, "ref", "to")
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="referenceSearch-base">
                  <div className="referenceSearch-autor referenceSearch-line">
                    <div className="referenceAdd-author-title">Section ID</div>
                    <div className="referenceSearch-author searchReference-reference">
                      <span className="search-line-pre">
                        <FormItem
                          label=""
                          help={this.state.helpsection}
                          validateStatus={
                            this.state.errorsection ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("section_basic_id_from", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.section_basic_id_from
                              : ""
                          })(
                            <InputNumber
                              min={1}
                              placeholder=""
                              onChange={value =>
                                this.sectionStartChange(
                                  value,
                                  "section",
                                  "from"
                                )
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                      <span className="search-line">
                        <span className="iconfont icon-line1"></span>
                      </span>
                      <span>
                        <FormItem
                          label=""
                          help={this.state.helpsection}
                          validateStatus={
                            this.state.errorsection ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("section_basic_id_to", {
                            rules: [
                              {
                                type: "number"
                              }
                            ],
                            initialValue: searchAllValues
                              ? searchAllValues.section_basic_id_to
                              : ""
                          })(
                            <InputNumber
                              min={1}
                              onChange={value =>
                                this.sectionEndChange(value, "section", "to")
                              }
                            ></InputNumber>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="referenceSearch-base">
                  <div className="referenceSearch-autor referenceSearch-line">
                    <div className="referenceAdd-author-title">Input date</div>
                    <div className="referenceSearch-author searchReference-reference">
                      <span className="search-line-pre">
                        <FormItem
                          label=""
                          help={this.state.helpcreatedAt}
                          validateStatus={
                            this.state.errorcreatedAt ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("createdAt_from", {
                            rules: [{ type: "object", message: "" }],
                            initialValue: searchAllValues
                              ? searchAllValues.createdAt_from
                              : null
                          })(
                            <DatePicker
                              disabledDate={this.disabledStartDate}
                              format="YYYY-MM-DD"
                              onChange={this.onStartChange}
                            ></DatePicker>
                          )}
                        </FormItem>
                      </span>
                      <span className="search-line">
                        <span className="iconfont icon-line1"></span>
                      </span>
                      <span>
                        <FormItem
                          label=""
                          help={this.state.helpcreatedAt}
                          validateStatus={
                            this.state.errorcreatedAt ? "error" : "success"
                          }
                        >
                          {getFieldDecorator("createdAt_to", {
                            rules: [{ type: "object", message: "" }],

                            initialValue: searchAllValues
                              ? searchAllValues.createdAt_to
                              : null
                          })(
                            <DatePicker
                              disabledDate={this.disabledEndDate}
                              format="YYYY-MM-DD"
                              onChange={this.onEndChange}
                            ></DatePicker>
                          )}
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="referenceSearch-book">
                  <Form.Item label="Project">
                    {getFieldDecorator("section_project", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.section_project
                        : ""
                    })(
                      <Select placeholder="Default">
                        <Option value="Null">Null</Option>
                        <Option value="Default">Default</Option>
                        <Option value="BGS digitization">
                          BGS digitization
                        </Option>
                        <Option value="ocean_data">Ocean Data</Option>
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="referenceSearch-book">
                  <Form.Item label="Section Name">
                    {getFieldDecorator("section_name", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.section_name
                        : ""
                    })(<Input></Input>)}
                  </Form.Item>
                </div>

                <div className="referenceSearch-numberProject">
                  <div className="Reference-number search-two">
                    <Form.Item label="Authorizer">
                      {getFieldDecorator("authorizer_id", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.authorizer_id
                          : ""
                      })(
                        <AutoComplete
                          onSearch={value =>
                            this.handleNomSearch(value, "authorizers")
                          }
                          onChange={value =>
                            this.handleNomChange(value, "authorizer_id")
                          }
                          placeholder=""
                        >
                          {authorizers}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  </div>
                  <div className="Reference-number search-two">
                    <Form.Item label="Enterer">
                      {getFieldDecorator("enterer", {
                        rules: [{}],
                        initialValue: searchAllValues
                          ? searchAllValues.enterer
                          : ""
                      })(
                        <AutoComplete
                          onSearch={value =>
                            this.handleNomSearch(value, "enterers")
                          }
                          onChange={value =>
                            this.handleNomChange(value, "enterer")
                          }
                          placeholder=""
                        >
                          {enterers}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  </div>
                </div>
              </div>

              {/* <div> */}
              <div className="referenceSearch-numberProject referenceSearch-location">
                <div className="Reference-number">
                  <Form.Item label="Country / Continent">
                    {getFieldDecorator("geology_country", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.geology_country
                        : ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Countrys",
                            "geology_country"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_country")
                        }
                        placeholder=""
                      >
                        {Countrys.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceSearch-no">
                  <Form.Item label="Province / State">
                    {getFieldDecorator("geology_province", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.geology_province
                        : ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Provinces",
                            "geology_province"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_province")
                        }
                        placeholder=""
                      >
                        {Provinces.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceSearch-numberProject referenceSearch-location">
                <div className="Reference-number">
                  <Form.Item label="City / Village">
                    {getFieldDecorator("geology_village", {
                      rules: [{}],
                      initialValue: searchAllValues
                        ? searchAllValues.geology_village
                        : ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Villages",
                            "geology_village"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_village")
                        }
                        placeholder=""
                      >
                        {Villages.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
                <div className="referenceSearch-no">
                  <Form.Item label="Location">
                    {getFieldDecorator("geology_location", {
                      initialValue: searchAllValues
                        ? searchAllValues.geology_location
                        : ""
                    })(
                      <AutoComplete
                        onSearch={value =>
                          this.geologySearch(
                            value,
                            "Locations",
                            "geology_location"
                          )
                        }
                        onChange={value =>
                          this.geologyChange(value, "geology_location")
                        }
                        placeholder=""
                      >
                        {Locations.map((item, index) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="referenceSearch-base">
                <div className="referenceSearch-autor referenceSearch-line">
                  <div className="referenceAdd-author-title">
                    Latitude Decimal
                  </div>
                  <div className="referenceSearch-author searchReference-reference">
                    <span className="search-line-pre">
                      <FormItem
                        label=""
                        help={this.state.helplatitude}
                        validateStatus={
                          this.state.errorlatitude ? "error" : "success"
                        }
                      >
                        {getFieldDecorator("geology_latitude_decimal_from", {
                          rules: [{ type: "number" }],
                          initialValue: searchAllValues
                            ? searchAllValues.geology_latitude_decimal_from
                            : ""
                        })(
                          <InputNumber
                            
                            placeholder=""
                            onChange={value =>
                              this.sectionStartChange(value, "latitude", "from")
                            }
                          ></InputNumber>
                        )}
                      </FormItem>
                    </span>
                    <span className="search-line">
                      <span className="iconfont icon-line1"></span>
                    </span>
                    <span>
                      <FormItem
                        label=""
                        help={this.state.helplatitude}
                        validateStatus={
                          this.state.errorlatitude ? "error" : "success"
                        }
                      >
                        {getFieldDecorator("geology_latitude_decimal_to", {
                          rules: [{ type: "number" }],
                          initialValue: searchAllValues
                            ? searchAllValues.geology_latitude_decimal_to
                            : ""
                        })(
                          <InputNumber
                           
                            onChange={value =>
                              this.sectionEndChange(value, "latitude", "to")
                            }
                          ></InputNumber>
                        )}
                      </FormItem>
                    </span>
                  </div>
                </div>
              </div>

              <div className="referenceSearch-base">
                <div className="referenceSearch-autor referenceSearch-line">
                  <div className="referenceAdd-author-title">
                    Longitude Decimal
                  </div>
                  <div className="referenceSearch-author searchReference-reference">
                    <span className="search-line-pre">
                      <FormItem
                        label=""
                        help={this.state.helplongitude}
                        validateStatus={
                          this.state.errorlongitude ? "error" : "success"
                        }
                      >
                        {getFieldDecorator("geology_longitude_decimal_from", {
                          rules: [{ type: "number" }],
                          initialValue: searchAllValues
                            ? searchAllValues.geology_longitude_decimal_from
                            : ""
                        })(
                          <InputNumber
                            min={1}
                            placeholder=""
                            onChange={value =>
                              this.sectionStartChange(
                                value,
                                "longitude",
                                "from"
                              )
                            }
                          ></InputNumber>
                        )}
                      </FormItem>
                    </span>
                    <span className="search-line">
                      <span className="iconfont icon-line1"></span>
                    </span>
                    <span>
                      <FormItem
                        label=""
                        help={this.state.helplongitude}
                        validateStatus={
                          this.state.errorlongitude ? "error" : "success"
                        }
                      >
                        {getFieldDecorator("geology_longitude_decimal_to", {
                          rules: [{ type: "number" }],
                          initialValue: searchAllValues
                            ? searchAllValues.geology_longitude_decimal_to
                            : ""
                        })(
                          <InputNumber
                            min={1}
                            onChange={value =>
                              this.sectionEndChange(value, "longitude", "to")
                            }
                          ></InputNumber>
                        )}
                      </FormItem>
                    </span>
                  </div>
                </div>
              </div>

              {/* </div> */}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

GeochemicalOption = Form.create({})(GeochemicalOption);
export default GeochemicalOption;
