import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { Form, Row, Col, Input, Button, } from "antd";


import "./index.scss";
import "src/js/global.js";
import insectsApis from "src/js/apis/insects";
import Storage from "src/js/storage";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function InsectsSearch() {
  const history = useHistory();
  /** 生成表单单个元素 */
  function getInput ({
    name,
    label = "Name",
    span = 8,
    rules,
    placeholder
  } = {}) {
    return (
      <Col span={span}>
        <Form.Item
          name={name || label.toLowerCase()}
          label={label}
          rules={rules}
        >
          <Input placeholder={placeholder} />
        </Form.Item>
      </Col>
    )
  };

  /** 执行搜索 */
  function doSearch() {
    console.log(">>>>>>>>>.执行搜索")
    history.push("/insects/list");
  }

  return (
    <div className="insects-search">
      <div className="search-title">Taxonomy Search</div>
      <div className="search-content">
        <Form {...layout}>
          <Row gutter={24}>
            {getInput({ label: 'GenericName' })}
            {getInput({ label: 'SpeciesName' })}
          </Row>
          <Row gutter={24}>
            {getInput({ label: 'Subfamily' })}
            {getInput({ label: 'Family' })}
            {getInput({ label: 'Superfamily' })}
          </Row>
          <Row gutter={24}>
            {getInput({ label: 'InfraOrder' })}
          </Row>
          <Row gutter={24}>
            {getInput({ label: 'SubOrder' })}
            {getInput({ label: 'Order' })}
            {getInput({ label: 'SuperOrder' })}
          </Row>
          <Row gutter={24}>
            {getInput({ label: 'InfraClass' })}
          </Row>
          <Row gutter={24}>
            {getInput({ label: 'SubClass' })}
            {getInput({ label: 'Class' })}
          </Row>
          <Form.Item wrapperCol= {{ span: 24 }} className= "search-line">
            <Button
              className= "search-btn"
              type="gbdb"
              onClick={() => {
                doSearch();
              }}
            >
              <p> Search</p>
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default InsectsSearch;
