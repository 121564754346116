import React, { Component } from 'react';
import {Form,Input,DatePicker,Button,message, Modal} from 'antd'
import moment from 'moment'
import axios from 'axios'
import './index.scss'

class AntdForm extends Component {
    constructor(props){
        super(props)
    }
    disabledDate(current){
       return current<moment().subtract(1,'days')
    }
    handleSubmit(){
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
               
                var uid=JSON.parse(localStorage.getItem('gbdbid')).value;
                var sendData={
                    uid,
                    title:values.newsTitle,
                    content:values.newsContent,
                    release_date:moment(values.date).format('YYYY/MM/DD HH:mm:ss')
                }
              
                
                axios.post('/api/mes/createNews',sendData).then(res=>{
                    if(res.data.error===null){
                        message.success('发布成功！')
                        this.props.form.resetFields()
                    }else{
                        Modal.warning({
                            content:res.data.error,
                        })
                    }
                }).catch(err=>{
                    message.error('this an error message~')
                })
            }
        })
       
    }
    render() {
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 5 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 19 },
            },
          };
        const { getFieldDecorator} = this.props.form;
        return (
            <div id='APublish'>
                <div className='title'>发布新闻</div>
                <div className='form'>
                    <Form layout="vertical">
                        <Form.Item label="标题">
                            {getFieldDecorator('newsTitle',{
                                rules:[{required:true,message:'请输入新闻标题'}]
                            })(<Input style={{width:480}}></Input>)}
                        </Form.Item>
                        
                        <Form.Item label="内容">
                            {getFieldDecorator('newsContent',{
                                rules:[{required:true,message:'请输入新闻内容'},{max:20000,message:'内容过长'}]
                            })(<Input.TextArea 
                            className='area' 
                            style={{width:480}} 
                            placeholder="最多可输入20000个字符"
                            ></Input.TextArea>)}
                        </Form.Item>

                        <Form.Item label="发布时间">
                            {getFieldDecorator('date',{
                                initialValue:moment()

                            })(<DatePicker  disabledDate={this.disabledDate} style={{width:480}}></DatePicker>)}
                        </Form.Item>

                    </Form>
                    <div className='foot'>
                        <Button type='primary' onClick={this.handleSubmit.bind(this)}>发布</Button>
                    </div>
                </div>
            </div>
        );
    }
}
const APublish = Form.create()(AntdForm);
export default APublish
