import asyncAxios from "src/js/asyncAxios";


export default {
  register: async params => {
    let url = "/api/user/register";

    let registerResult = await asyncAxios.post(url, params);

    return registerResult;
  },
  login:async params => {
    let url = "/api/user/login";
    // console.log("url:"+url);
    let loginResult = await asyncAxios.post(url, params);

    return loginResult;
  },
  adminLogin:async params => {
    let url = "/api/user/adminLogin";
    // console.log("url:"+url);
    let loginResult = await asyncAxios.post(url, params);

    return loginResult;
  },
    verifyToken: async params => {
    let url = "/api/user/verifyToken";

    let tokenResult = await asyncAxios.get(url, params);

    return tokenResult;
  },
  verifyEmail: async params => {
    let url = "/api/user/verifyEmail";

    let emailResult = await asyncAxios.get(url, params);

    return emailResult;
  },
  initUser:async (params)=> {
  let url = "/api/user/refresh";
  let initResult = await asyncAxios.get(url, params)
  return initResult
},
gbdbUser:async (params)=> {
  let url = "/api/user/byuserid";
  let GuserResult = await asyncAxios.get(url, params)
  return GuserResult
},
allUserData:async (params)=> {
  
  let url = "/api/user/refresh";
  let initResult = await asyncAxios.get(url, params)
  return initResult
},

changePassword:async (params)=> {
  
  let url = "/api/user/changePassword";
  let pwdResult = await asyncAxios.post(url, params)
  return pwdResult
},
updateUserInfoById:async (params)=> {
  
  let url = "/api/user/updateUserInfoById";
  let updateUser = await asyncAxios.post(url, params)
  return updateUser
},

updateUserVerInfo:async (params)=> {
  
  let url = "/api/user/updateUserVerInfo";
  let updateUser = await asyncAxios.post(url, params)
  return updateUser
},
userAuthCompiler:async (params)=> {
  
  let url = "/api/user/auth/compiler";
  let userCompiler = await asyncAxios.post(url, params)
  return userCompiler
},
userAuthExpert:async (params)=> {
  
  let url = "/api/user/auth/Expert";
  let userExpert = await asyncAxios.post(url, params)
  return userExpert
},
verifyCheckCode:async (params)=> {
  
  let url = "/api/user/verifyCheckCode";
  let verifyCheckCode = await asyncAxios.get(url, params)
  return verifyCheckCode
},
updateCheckCode:async (params)=> {
  
  let url = "/api/user/updateCheckCode";
  let updateCheckCodeBack = await asyncAxios.post(url, params)
  return updateCheckCodeBack
},
getMessages:async (params)=> {
  
  let url = "/api/mes/user/message";
  let getMes = await asyncAxios.get(url, params)
  return getMes
},
gbdbGetUserByIds:async (params)=> {
  
  let url = "/api/user/gbdb/getUserByIds";
  let Users = await asyncAxios.post(url, params)
  return Users
},


};




